import React, { useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { createAction } from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import { useHistory, useLocation } from 'react-router-dom';
import { PermissionCodes } from '../../../config/PermissionCodes';
import CustomListComponent from '../../../components/base/CustomListComponent';
import AuthButton from '../../../components/base/AuthButton';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import ThemedSectionOverviewTableSection from '../../../components/themedSection/ThemedSectionOverviewTableSection';

function ThemedSection({
  themeSections,
  languages,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const themeSection = themeSections?.[0]?.node || {};

  useEffect(() => {
    dispatch(
      createAction('themeSection/getAllList')({}),
    );
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    return () => dispatch({ type: 'themeSection/clearData' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTabForLanguage = (language) => {
    return [
      <ThemedSectionOverviewTableSection
        themeSection={themeSection}
        language={language}
      />,
      <MembershipInfoCard
        data={themeSection}
        fields={[
          [{ title: 'Campaign tag (optional)', field: 'campaignTagsName' }],
        ]}
      />,
    ]
  };

  const tabs = [
    {
      name: 'Detail',
      content: (
        <div className="scroll-container-common">
          <ContentSections
            languageTabContent={{
              containers: languages?.map((item) => ({
                container: renderTabForLanguage(item.code),
                key: item.code,
                title: item.sourceName,
              })),
            }}
            hidePreview={true}
          />
        </div>
      ),
    },
  ];
  
  const buttons = [
    <AuthButton
      title="Edit"
      action={() => history.push(`${location.pathname}/${themeSection.pk}/edit/`)}
      requires={PermissionCodes.changeThemedSection}
    />
  ]

  return (
    <CustomListComponent
      caution={{
        detail: '',
        title: 'Hub',
      }}
      hideTab
      breadcrumb={
        <CustomBreadcrumb />
      }
      buttons={buttons}
      tabs={tabs}
    />
  );
}

const mapPropsToState = (state) => ({
  themeSections: state.themeSection.notPagedAllList,
  languages: state.language.allList,
})

export default connect(mapPropsToState)(ThemedSection)
