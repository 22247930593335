import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackWithOutTempButtons } from '../../../components/base/BottomStepComponent';
import { useDispatch, connect } from 'react-redux';
import { APIStatus } from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';
import CarparkVendorSection from '../../../components/carpark/CarparkVendorSection';
import CarparkGeneralSection from '../../../components/carpark/CarparkGeneralSection';

const CreateCarParkStepOne = ({ createStatus, languages }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const { getValues, trigger } = useFormContext();

  useEffect(() => {
    dispatch({ type: 'language/getList', payload: { isSelectorLoad: true } });
  }, []);

  return (
    <>
      {createStatus === APIStatus.calling ? (
        <Loading />
      ) : (
        <div className="scroll-container-common">
        <ContentSections 
          languageTabContent={{
            containers: languages?.map((item) => ({
              container: [<CarparkGeneralSection language={item.code} />],
              key: item.code,
              title: item.sourceName,
            })),
          }}
          hidePreview={true} 
        />
        <ContentSections 
          sections={[<CarparkVendorSection />]}
          hidePreview={true}
        />
        </div>
      )}
      <SaveAndBackWithOutTempButtons
        backAction={() => {
          history.push('/carparks');
        }}
        saveText="Save"
        saveAction={async () => {
          let isValid = await trigger(['chargerLocation'], { shouldFocus: true });

          if (isValid && params.id) {
            let uploadData = {
              values: getValues(),
              carparkID: params.id,
            };
            
            dispatch({
              type: 'carpark/updateCarPark',
              payload: uploadData,
            });
          }
        }}
      />
    </>
  );
};

const mapPropsToState = (state) => ({
  carpark: state.carpark.detail,
  createStatus: state.carpark.createStatus,
  apiStatus: state.carpark.apiStatus,
  hasUpdatedDefaultValues: state.carpark.hasUpdatedDefaultValues,
  formHasSubmitted: state.carpark.formHasSubmitted,
  languages: state.language.allList,
});

export default connect(mapPropsToState)(CreateCarParkStepOne);
