import {
    UpdateDistrictMallMappingError
} from '../../../models/DistrictMallMappingModel'

  function firstStepValidate({ getValues, setError }) {
    const nameField = `name`;
    const mall1 = getValues('favouriteMall1').value?.pk
    const mall2 = getValues('favouriteMall2').value?.pk
    const mall3 = getValues('favouriteMall3').value?.pk

    if (
      (mall1 !== undefined && mall1 === mall2)
      || (mall2 !== undefined && mall2 === mall3) 
      || (mall3 !== undefined && mall1 === mall3)) 
      {
      setError(nameField,
        {
          type: 'require',
          message: UpdateDistrictMallMappingError.mallLimit.message,
        });
      return false
    }
    return true;
  }

export function validate({ getValues, setError, clearErrors, step }) {
    console.log('@71 update district mall mapping:', getValues());
    clearErrors();
    switch (step) {
      default:
      case 0:
        const validStepOne = firstStepValidate({ getValues, setError });
        return validStepOne;
    }
  }