import React, { useState, useEffect } from 'react';
import './App.scss';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import { withResizeDetector } from 'react-resize-detector';
import SideMenuNav from './components/bars/SideMenuNav';
import Header from './components/bars/Header';
import routes from './config/Routes';
import Login from './containers/user/Login';
import { useSelector } from 'react-redux';
import SaveDiscardToast from './components/base/SaveDiscardToast';
import { saveToSessionStorage } from './utils';
import { FIRST_TIME_ENTER_CAMPAIGN } from './models/CampaignListModel';
import SamlLogin from './containers/user/SamlLogin';
function PageRoute({ width, height }) {
  const [openMenu, setOpenMenu] = useState(false);
  const user = useSelector((state) => state.users);
  const [appRoutes, setAppRoutes] = useState();
  const location = useLocation();
  useEffect(() => {
    setAppRoutes(routes(user));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.isSuperuser, user.userPermissions]);

  useEffect(() => {
    const pathname = location.pathname;
    if (!pathname.includes('estamp_campaigns')) {
      saveToSessionStorage(FIRST_TIME_ENTER_CAMPAIGN.eStampResume, false);
    } else if (!pathname.includes('emission_campaigns')) {
      saveToSessionStorage(FIRST_TIME_ENTER_CAMPAIGN.eMissionResume, false);
    } else if (!pathname.includes('campaigns')) {
      saveToSessionStorage(FIRST_TIME_ENTER_CAMPAIGN.resume, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (width > 1024 && openMenu) {
      setOpenMenu(false);
    }
  }, [width, openMenu]);

  if (user.isLogin) {
    const useSideMenu = width <= 1024;
    return (
      <div className="main-container">
        <div className="main-content">
          {useSideMenu ? (
            <Menu
              isOpen={openMenu}
              onStateChange={(state) => {
                setOpenMenu(state.isOpen);
              }}
              width={240}
              styles={{
                bmCross: {
                  height: 25.6,
                },
                bmCrossButton: {
                  right: 0,
                  top: 14,
                  width: 32,
                  height: 32,
                  left: openMenu ? 254 : 0,
                },
              }}
            >
              <div className="sidebar-container">
                <SideMenuNav />
              </div>
            </Menu>
          ) : null}
          <Header />
          <Switch>{appRoutes}</Switch>
        </div>
        {useSideMenu ? null : (
          <div className="sidebar-container">
            <SideMenuNav />
          </div>
        )}
      </div>
    );
  } else {
    return <Redirect to="/login" />;
  }
}

const SizeDetectPageRoute = withResizeDetector(PageRoute);

function App() {
  return (
    <BrowserRouter>
      <SaveDiscardToast />
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/saml_login">
          <SamlLogin />
        </Route>
        <Route>
          <SizeDetectPageRoute />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
