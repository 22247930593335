import {
  getEarningRulesWithType,
  deleteEarningRules,
} from '../services/EarningRuleHelper';
import { assembleCouponTemplates } from './CouponUtil';
import { EarningRuleType, EarningRuleRewardType, BonusRewardsRule } from '../config/CustomEnums';
import { apiWithResponseHandle, loading } from './LoadingUtil';
import { convertCursorToNumber, convertNumberToCursor, delay } from '../utils';
import { getDisplayDate } from '../utils/TimeFormatUtil';
const getInitialState = () => ({
  earningRuleList: [],
  earningRuleTypeList: [],
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  currentLastCursor: '',
  currentPage: 0,
  totalPage: 0,
  totalCount: 0,
  checkedEarningRules: [],
  checkedList: [],
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Name', fieldName: 'name', orderField: 'name'},
    { displayName: 'Linked Campaign', fieldName: 'linkedCampaign' },
    { displayName: 'Reward Type', fieldName: 'rewardType' },
    { displayName: 'Bonus', fieldName: 'bonus' },
    { displayName: 'Create at', fieldName: 'displayCreated', orderField: "creationDate" },
    { displayName: 'Last Modified', fieldName: 'displayModified', orderField: "lastModifiedDate" },
  ],
});

export const switchCouponTemplateWithEarningRule = (earningRule) => {
  const type = earningRule.type;
  const couponTemplateData = {};
  switch (type) {
    case EarningRuleType.generalPurchase:
      couponTemplateData.node =
        earningRule.generalPurchaseTypeCouponRewardTypeCouponTemplate;
      break;
    case EarningRuleType.memberReferral:
      couponTemplateData.node =
        earningRule.memberReferralTypeCouponRewardTypeCouponTemplate;
      break;
    case EarningRuleType.newMember:
      couponTemplateData.node =
        earningRule.newMemberTypeCouponRewardTypeCouponTemplate;
      break;
    case EarningRuleType.birthday:
      couponTemplateData.node =
        earningRule.birthdayTypeCouponRewardTypeCouponTemplate;
      break;
    case EarningRuleType.qrCodeScanning:
      couponTemplateData.node =
        earningRule.qrCodeScanningTypeCouponRewardTypeCouponTemplate;
      break;
    case EarningRuleType.gpsCheckIn:
      couponTemplateData.node =
        earningRule.gpsCheckInTypeCouponRewardTypeCouponTemplate;
      break;
    case EarningRuleType.fillingForm:
      couponTemplateData.node =
        earningRule.fillingFormTypeCouponRewardTypeCouponTemplate;
        break;
    default:
      break;
  }
  const templates = [];
  if (couponTemplateData.node) {
    templates.push(couponTemplateData);
  }
  return assembleCouponTemplates(templates)[0] || {};
}

const assembleEarningRules = (earningRules) =>
  earningRules.map((earningRule) => {
    const ruleNode = earningRule.node;

    return {
      id: ruleNode.id,
      name: ruleNode.name,
      pk: ruleNode.pk,
      couponTemplate: switchCouponTemplateWithEarningRule(ruleNode),
      ...ruleNode,
    };
  });

const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

const getRewardType = (earnRule) => {
  const key = getKeyByValue(EarningRuleType, earnRule.type);
  const rewardType = earnRule[`${key}TypeRewardType`];

  if (rewardType == EarningRuleRewardType.points) {
    if (earnRule.type === EarningRuleType.generalPurchase) {
      const point =
        earnRule.generalPurchaseTypePointsRewardTypePointsPerXDollarsSpent;
      const x = earnRule.generalPurchaseTypePointsRewardTypeX;
      return `${rewardType}\r\n$${x} = ${point}pts`;
    }
    const rewardPointValue = earnRule[`${key}TypePointsRewardTypePoints`];
    return `${rewardType}\r\n${rewardPointValue}pts`;
  } else if (rewardType == 'COUPON') {
    const rewardCouponTemplate =
      earnRule[`${key}TypeCouponRewardTypeCouponTemplate`]['name'];

    return `${rewardType}: ${rewardCouponTemplate}`;
  } else if (rewardType === EarningRuleRewardType.stamp) {
    if (earnRule.type === EarningRuleType.generalPurchase) {
      const stamp =
        earnRule.generalPurchaseTypeStampRewardTypeStampsPerXDollarsSpent;
      const x = earnRule.generalPurchaseTypeStampRewardTypeX;
      return `${rewardType}\r\n$${x} = ${stamp}stamps`;
    }
    const rewardPointValue = earnRule[`${key}TypeStampRewardTypeQuantity`];
    return `${rewardType}\r\n${rewardPointValue}stamps`;
  } else if (rewardType === EarningRuleRewardType.badge) {
    if (earnRule.type === EarningRuleType.generalPurchase) {
      const badge =
        earnRule.generalPurchaseTypeBadgeRewardTypeBadgesPerXDollarsSpent;
      const x = earnRule.generalPurchaseTypeBadgeRewardTypeX;
      return `${rewardType}\r\n$${x} = ${badge}badges`;
    }
    const rewardPointValue = earnRule[`${key}TypeBadgeRewardTypeQuantity`];
    return `${rewardType}\r\n${rewardPointValue}badges`;
  }

  return `${rewardType}`;
};

const getLinkedCampaign = (earnRule) => {
  const campaigns = earnRule.campaigns.edges;
  let linkedCampaign = [];
  campaigns.map((campaign) => {
    linkedCampaign.push(campaign.node.name);
  });

  return linkedCampaign.join(', ');
};

const getBonus = (earnRule) => {
  let result = null;
  if (
    earnRule.generalPurchaseTypeRewardType === EarningRuleRewardType.stamp &&
    earnRule.generalPurchaseTypeStampRewardTypeBonus
  ) {
    if (earnRule.generalPurchaseTypeStampRewardTypeBonusType === BonusRewardsRule.Multiplier) {
      result = `x ${earnRule.generalPurchaseTypeStampRewardTypeBonusMultiplier}`
    } else if (earnRule.generalPurchaseTypeStampRewardTypeBonusType === BonusRewardsRule.Additional) {
      result = `+ ${earnRule.generalPurchaseTypeStampRewardTypeBonusAdditional}`
    }
  }
  return result;
};

const parseEarningRule = (item) => {
  return {
    ...item,
    linkedCampaign: getLinkedCampaign(item),
    rewardType: getRewardType(item),
    bonus: getBonus(item),
    displayCreated: getDisplayDate(item.creationDate),
    displayModified: getDisplayDate(item.lastModifiedDate),
  };
};

export default {
  namespace: 'earningRuleList',
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },

    assembleEarningRuleList(state, { payload }) {
      const { templateList, page } = payload;
      const earningRuleList = assembleEarningRules(templateList.edges);
      return {
        ...state,
        earningRuleList:
          page > 1
            ? [...state.earningRuleList, ...earningRuleList]
            : earningRuleList,
      };
    },

    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },

  effects: {
    getEarningRuleListWithTypes: [
      function* ({ payload }, { call, all, put }) {
        const page = payload.page;
        const pageCursor = payload.page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const serviceArgs = [
          getEarningRulesWithType,
          pageCursor,
          payload.reverse,
          payload.type || 'all',
          payload.search,
          payload,
        ];
        function* onSuccess(data) {
          console.log('@@115: ', data);
          const pageInfo = data?.earningRules?.pageInfo;

          const currentLastCursor = pageInfo?.endCursor;
          const totalCount = data?.earningRules?.totalCount;
          yield all([
            put({
              type: 'updateState',
              payload: {
                earningRuleTypeList: data?.earningRules?.edges.map((item) =>
                  parseEarningRule(item.node),
                ),
                pageInfo: {
                  startCursor: convertCursorToNumber(pageInfo.startCursor) + 1 || 0,
                  endCursor: convertCursorToNumber(pageInfo.endCursor) + 1 || 0,
                },
                currentLastCursor,
                totalCount,
                totalPage: Math.ceil(totalCount / 20),
              },
            }),
            put({
              type: 'assembleEarningRuleList',
              payload: { templateList: data?.earningRules, page: page },
            }),
          ]);
        }
        function* onFailed(data) {
          console.log('@@122: ', data);
        }

        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    delete: [
      function* ({ payload }, { select, put, all }) {
        const { checkedEarningRules } = yield select((state) => ({
          checkedEarningRules: state.earningRuleList.checkedList,
        }));

        let pks = [];
        let deleteCampaignIds = [];
        checkedEarningRules.forEach((item) => {
          pks.push(item.pk);

          item.campaigns.edges.forEach((campaign) => {
            deleteCampaignIds.push(campaign.node.pk);
          });
        });

        const serviceArgs = [deleteEarningRules, pks];
        const afterAction = payload.afterAction || (() => {});
        function* onSuccess(data) {
          console.log('@@153: ', data);
          yield all([
            // yield put({
            //   type: 'createCampaign/deleteCampaigns',
            //   payload: { campaignPks: deleteCampaignIds },
            // }),
            // yield put({
            //   type: 'deleteCampaignTranslations',
            //   payload: { campaignPks: deleteCampaignIds },
            // }),
            put({
              type: 'earningRuleList/updateState',
              payload: { checkedList: [] },
            }),
          ]);
          yield delay(1000);
          afterAction();
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
