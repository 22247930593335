import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import {
  LanguageConfig,
  APIStatus,
  BANNER_CONTENT_TYPE,
} from '../../../config/CustomEnums';
import { createAction, firstError } from '../../../utils';
import './CreateBanner.scss';
import { PermissionCodes } from '../../../config/PermissionCodes';
import CreateBannerStepOne from './CreateBannerStepOne';
import BaseForm from '../../../components/base/v2/BaseForm';


const CreateBanner = ({
  errorFields,
  banner,
  createStatus,
  hasUpdatedDefaultValues,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    // dispatch({
    //   type: 'bannerList/getProductList',
    //   payload: {}
    // });
    const bannerId = params.id;
    if (bannerId) {
      dispatch({
        type: 'bannerList/getOneBanner',
        payload: { id: bannerId }
      });
    } else {
      dispatch({
        type: 'bannerList/loadFromSessionStorage',
      });
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    return () => {
      dispatch({ type: 'bannerList/clearData' });
      dispatch({ type: 'campaignList/clearData' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (createStatus === APIStatus.success && params.id) {
      history.push('/banners');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus]);

  useEffect(() => {
    firstError(`${errorFields[0]}-${LanguageConfig.english}`, errorFields);
  }, [errorFields]);

  const stepContent = [<CreateBannerStepOne />];
  if (banner?.contentType == null) {
    banner.contentType = BANNER_CONTENT_TYPE.CAMPAIGN;
  }

  return (
    <div className="create-banner">
      <BaseForm
        defaultValues={banner}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={createStatus === APIStatus.success}
        tempSave={(save, getValues) => {
          if (save) {
            dispatch({
              type: 'bannerList/saveBannerToSessionStorage',
              payload: {save, values: getValues()},
            });
          } else {
            dispatch({
              type: 'bannerList/removeBannerFromSessionStorage',
            });
          }
        }}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Banner is successfully created.',
          steps: null,
          buttons: [
            {
              text: 'Back to Banner list',
              action: () => history.push('/banners'),
              requires: PermissionCodes.changeBanner,
            },
          ],
        }}
        showFinishPop={createStatus === APIStatus.success && !params.id}
        content={stepContent}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb name={`${params.id ? 'Edit Banner' : 'Create Banner'}`} />
        }
        caution={{
          detail: '',
          title: `${params.id ? 'Edit Banner' : 'Create Banner'}`,
        }}
      />
    </div>
  );
};

const mapPropsToState = (state) => ({
  errorFields: state.bannerList.errorFields,
  banner: state.bannerList.banner,
  createStatus: state.bannerList.createStatus,
  hasUpdatedDefaultValues: state.bannerList.hasUpdatedDefaultValues,
});

export default connect(mapPropsToState)(CreateBanner);
