import { getSearchKey } from '../utils';
import { BaseHelper } from './BaseGraphQLAPIHelper';

const FRESH_MARKET_NODE = `{
  id
  pk
  name
  photo
  detailPhoto1
  detailPhoto2
  detailPhoto3
  detailPhoto4
  detailPhoto5
  detailPhoto6
  detailPhoto7
  detailPhoto8
  detailPhoto9
  address
  latitude
  longitude
  description
  phoneNumber
  openingHours
  district {
    region {
      pk
      name
    }
    name
    translations {
      edges {
        node {
          name
          language
        }
      }
    }
  }
  mall {
    pk
    name
    translations {
      edges {
        node {
          name
          language
        }
      }
    }
  }
  translations {
    edges {
      node {
        pk
        language
        name
        description
        address
        openingHours
      }
    }
  }
}`;

const FRESH_MARKET_SIMPLE_NODE = `{
  id
  pk
  name
}`

export const getFreshMarkets = (afterCursor, payload = {}) => {
  let searchString = payload?.isAll ? '' : `first: ${payload?.pageSize || 20}`;
  if (afterCursor) {
    searchString += `, after: "${afterCursor}"`;
  }
  if (payload?.sort) {
    searchString += `, orderBy: "${payload.sort}"`;
  } else {
    searchString += `, orderBy: "name"`;
  }
  if (payload?.isFilterNameOrId && payload?.searchKey) {
    searchString += `, nameOrId: "${getSearchKey(payload.searchKey)}"`;
  } else if (payload?.searchKey) {
    searchString += `, nameIcontains: "${getSearchKey(payload.searchKey)}"`;
  }
  const node = payload?.isSimpleFilter ? FRESH_MARKET_SIMPLE_NODE : FRESH_MARKET_NODE;
  const query = `{
    freshMarkets(${searchString}) {
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node ${node}
        }
      }
    }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getFreshMarket = (id) => {
  const query = `{
    freshMarket(id: "${id}") ${FRESH_MARKET_NODE}
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};
