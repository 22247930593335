import React from 'react';
import './Login.scss';
import { Button } from 'react-bootstrap';
import { withResizeDetector } from 'react-resize-detector';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { Image, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import LoginLeftBackground from '../../assets/images/login_bg.png';
import LoginLeftIcon from '../../assets/images/login_icon.png';
import LoginLogo from '../../assets/images/login_logo.svg';
import { createAction } from '../../utils';
import { SAML_LOGIN_URL } from './SamlLogin';

const LEFT_ICON_MAX_WIDTH = 604;
const LEFT_ICON_MAX_LEFT_POSITION = 142;
const FORM_MAX_MARGIN_LEFT = 566;
const FORM_MAX_PADDING_LEFT = 100;
const MAX_DYNAMIC_SCREEN_WIDTH = 1280;
const MIN_DYNAMIC_SCREEN_WIDTH = 750;
const CHANGE_FIX = 1.25;

const LoginMain = ({ width, height }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loginFailed = useSelector((state) => state.users.loginFailed);
  const login = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const username = form.username.value;
    const password = form.password.value;
    // console.log('@@21: ', username, password);
    dispatch({
      type: 'users/login',
      payload: { username, password },
    });
    history.push('/');
  };
  let leftIconWidth = LEFT_ICON_MAX_WIDTH;
  let leftIconLeft = LEFT_ICON_MAX_LEFT_POSITION;
  let formLeftMargin = FORM_MAX_MARGIN_LEFT;
  let formLeftPadding = FORM_MAX_PADDING_LEFT;
  if (width < MAX_DYNAMIC_SCREEN_WIDTH && width >= MIN_DYNAMIC_SCREEN_WIDTH) {
    const changePercentage =
      (MAX_DYNAMIC_SCREEN_WIDTH - width) / MAX_DYNAMIC_SCREEN_WIDTH;
    leftIconWidth = (1 - CHANGE_FIX * changePercentage) * LEFT_ICON_MAX_WIDTH;
    leftIconLeft =
      (1 - CHANGE_FIX * changePercentage) * LEFT_ICON_MAX_LEFT_POSITION;
    formLeftMargin = (width / MAX_DYNAMIC_SCREEN_WIDTH) * FORM_MAX_MARGIN_LEFT;
    formLeftPadding =
      FORM_MAX_PADDING_LEFT -
      (((MAX_DYNAMIC_SCREEN_WIDTH - width) * CHANGE_FIX) /
        (MAX_DYNAMIC_SCREEN_WIDTH - MIN_DYNAMIC_SCREEN_WIDTH)) *
        FORM_MAX_PADDING_LEFT;
  }
  const hideLeft = width < MIN_DYNAMIC_SCREEN_WIDTH;
  if (hideLeft) {
    formLeftMargin = 0;
    formLeftPadding = 0;
  }
  const getFormItem = (title, name, type) => {
    return (
      <div className="login-form-item">
        <label className="login-form-label">{title}</label>
        <input name={name} type={type} className="login-form-input" />
        <label
          className={`login-error ${loginFailed ? '' : 'login-error-previous'}`}
        >
          We cannot recognize your login / password combination.
        </label>
      </div>
    );
  };
  return (
    <div className="login">
      {hideLeft ? null : (
        <>
          <Image className="left-bg" src={LoginLeftBackground} />
          <Image
            className="left-icon"
            style={{
              width: leftIconWidth,
              left: leftIconLeft,
            }}
            src={LoginLeftIcon}
          />
        </>
      )}
      <Form
        style={{ marginLeft: formLeftMargin, paddingLeft: formLeftPadding }}
        className="login-form"
        autoComplete="off"
        noValidate
        onSubmit={login}
      >
        <Image className="login-form-logo" src={LoginLogo} />
        {getFormItem('Account', 'username', 'text')}
        {getFormItem('Password', 'password', 'password')}
        <Button type="submit" className="btn-further login-form-button">
          Login
        </Button>
        <Button type="button" onClick={() => {
          console.log("123");
          window.location = SAML_LOGIN_URL;
        }} className="btn-further login-form-button">Login With Microsoft</Button>
      </Form>
    </div>
  );
};

const LoginWithSize = withResizeDetector(LoginMain);

function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.users.isLogin);
  if (history?.location?.state?.isLogout) {
    dispatch({ type: 'users/logout' });
    return <LoginWithSize />;
  }
  return isLogin ? <Redirect to="/" /> : <LoginWithSize />;
}

export default withRouter(Login);
