import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { ArrayStringData } from '../../../utils';
import { getConciergeLanguage } from './CouponTransactionRecord';
import i18n from '../../../I18n';

function Filter({ backAction = () => {} }) {
  const location = useLocation();
  const language = getConciergeLanguage(location);

  const { couponList } = useSelector((state) => ({
    couponList: state.couponList.currentPageCouponSetList,
  }));

  const parsedSearch = queryString.parse(location.search);
  const searchRecordType = parsedSearch['record_type'] || '';
  const searchExpiredDateType = parsedSearch['expired_date'] || '';
  const searchStore = parsedSearch['coupon_name'] || '';
  const searchCreateDate = parsedSearch['create_date'] || '';
  const searchAbsoluteDate = parsedSearch['absolute'] || '';
  const searchRelativeDay = parsedSearch['relativeDay'] || '';

  const [recordType, setRecordType] = useState(
    ArrayStringData(searchRecordType),
  );
  const [expiredDateType, setExpiredDateType] = useState(searchExpiredDateType);
  const [couponName, setCouponName] = useState(ArrayStringData(searchStore));
  const [createDate, setCreateDate] = useState(searchCreateDate);
  const [absoluteDate, setAbsoluteDate] = useState(searchAbsoluteDate);
  const [relativeDay, setRelativeDay] = useState(searchRelativeDay);

  const content = [
    {
      title: 'Record type',
      displayName: i18n.t('couponRecords.record_type', { locale: language }),
      data: [
        { name: i18n.t('couponRecords.customer_earned', { locale: language }), pk: 'EARN' },
        { name: i18n.t('couponRecords.customer_acquired', { locale: language }), pk: 'BUY' },
        { name: i18n.t('couponRecords.admin_added', { locale: language }), pk: 'GRANT' },
        { name: i18n.t('couponRecords.admin_removed', { locale: language }), pk: 'RECLAIM' },
        { name: i18n.t('couponRecords.customer_use', { locale: language }), pk: 'USE' },
        { name: i18n.t('couponRecords.coupon_expired', { locale: language }), pk: 'EXPIRED' },
      ],
      value: recordType,
      setValue: setRecordType,
      componment: FilterTypeEnum.choice,
    },
    // {
    //   title: 'Coupon name',
    //   displayName: '優惠券名字',
    //   data: couponList,
    //   value: couponName,
    //   setValue: setCouponName,
    //   componment: FilterTypeEnum.choiceWithSearch,
    //   loadMoreAction: 'couponList/getCurrentPageTemplateList',
    //   filterAction: 'couponList/getCurrentPageTemplateList',
    //   placeholder: '搜索',
    // },
    // {
    //   title: 'Create date',
    //   displayName: '創建日期',
    //   value: createDate,
    //   setValue: setCreateDate,
    //   componment: FilterTypeEnum.dateRange,
    // },
    {
      title: 'Expired date',
      displayName: i18n.t('couponRecords.expired_date', { locale: language }),
      data: [
        {
          name: i18n.t('couponRecords.all_time', { locale: language }),
          pk: 'allTime',
          subComponment: null,
        },
        {
          name: i18n.t('couponRecords.time_range', { locale: language }),
          pk: 'absolute',
          subComponment: {
            value: absoluteDate,
            setValue: setAbsoluteDate,
            componment: FilterTypeEnum.dateRange,
          },
        },
        {
          name: i18n.t('couponRecords.since_acquiring', { locale: language }),
          pk: 'relativeDay',
          subComponment: {
            value: relativeDay,
            setValue: setRelativeDay,
            componment: FilterTypeEnum.input,
          },
        },
      ],
      value: expiredDateType,
      setValue: setExpiredDateType,
      componment: FilterTypeEnum.singleChoice,
    },
  ];

  return (
    <BaseFilter
      content={content}
      backAction={backAction}
      filterText={i18n.t('transaction.filter', { locale: language })}
      resetAllText={i18n.t('transaction.reset_all', { locale: language })}
    />
  );
}

export default Filter;
