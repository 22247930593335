import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import '../storeCategory/CreateStoreCategory.scss';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import { validate } from '../storeCategoryLv3/CreateStoreCategoryValidate';
import CreateStoreCategoryLv3Content from '../../../components/storeCategoryLv3/CreateStoreCategoryLv3Content';

const CreateStoreCategoryLv4StepOne = ({
  languages,
  setShowDeletePrompt = () => {},
  selectedCategoryList,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch({
      type: 'language/getList',
      payload: { isSelectorLoad: true },
    });
    dispatch({
      type: 'createStoreCategoryLv3/getAllList',
      payload: {},
    });
  }, [dispatch]);

  const { getValues, setError, clearErrors } = useFormContext();

  const getLanguageTab = (language) => {
    const targetSectionConfig = {
      title: 'Link to Category lv3',
      selectedCategoryList,
    };
    return [
      <CreateStoreCategoryLv3Content
        language={language}
        targetSectionConfig={targetSectionConfig}
      />,
    ];
  };

  const languageTabConfig = {
    containers: languages?.map((item) => ({
      container: getLanguageTab(item.code),
      key: item.code,
      title: item.sourceName,
    })),
  };

  const save = async () => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 0,
    });
    if (!isValid) {
      return;
    }
    let uploadData = {
      values: getValues(),
    };
    if (params.id) {
      uploadData = {
        values: getValues(),
        categoryID: params.id,
      };
    }
    dispatch({
      type: 'createStoreCategoryLv4/createOrUpdate',
      payload: uploadData,
    });
  };

  return (
    <>
      <ContentSections languageTabContent={languageTabConfig} hidePreview />
      <SaveAndBackButtons
        saveTempText={params.id ? 'Delete' : null}
        saveText={params.id ? 'Update' : 'Save'}
        backAction={() => history.goBack()}
        saveAction={async () => save()}
        saveTempAction={() => setShowDeletePrompt(true)}
      />
    </>
  );
};

const mapPropsToState = (state) => ({
  languages: state.language.allList,
  selectedCategoryList: state.createStoreCategoryLv3.notPagedAllList,
});

export default connect(mapPropsToState)(CreateStoreCategoryLv4StepOne);
