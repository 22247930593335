import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import ContentSections from '../../../components/base/ContentSections';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import RecordTableCard from '../../../components/customer/RecordTableCard';
import RecordTimelineCard from '../../../components/customer/RecordTimelineCard';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { getConciergeLanguage } from '../../conciergeApp/couponRecord/CouponTransactionRecord';
import { LanguageConfig } from '../../../config/CustomEnums';
import i18n from '../../../I18n';


function CustomerOverviewDetail({
  customer,
  couponTransactions,
  transactions,
  avaliableCampaigns,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const id = params.id;

  const language = getConciergeLanguage(location);
  let isConcierge = false;
  if (language === LanguageConfig.traditionalChinese) {
    isConcierge = true;
  }

  const octopusCards = [];
  if (customer.octopusCards?.length) {
    customer.octopusCards.forEach((octopusCard, index) => {
      const octopusCardField = `octopusCard${index + 1}`;
      customer[octopusCardField] = octopusCard;
      octopusCards.push([{
        title: i18n.t('concierge.octopus_card', { index: index + 1, locale: language }),
        field: octopusCardField,
      }]);
    });
  } else {
    [...Array(2)].forEach((_, index) => {
      const octopusCardField = `octopusCard${index + 1}`;
      octopusCards.push([{
        title: i18n.t('concierge.octopus_card', { index: index + 1, locale: language }),
        field: octopusCardField,
      }]);
    })
  }

  const tabs = [
    {
      name: i18n.t('concierge.information', { locale: language }),
      content: (
        <div className="scroll-container-common">
          <ContentSections
            sections={[
              <MembershipInfoCard
                title={i18n.t('concierge.membership_information', { locale: language })}
                data={customer}
                language={language}
                fields={[
                  [{ title: i18n.t('concierge.membership_id', { locale: language }), field: 'membershipId' }],
                  [{ title: i18n.t('concierge.segments', { locale: language }), field: 'segment' }],
                  [{ title: i18n.t('concierge.customer_group', { locale: language }), field: 'group' }],
                  [{ title: i18n.t('concierge.referral_source', { locale: language }), field: 'referralSource' }],
                  [{ title: i18n.t('concierge.promotion_code', { locale: language }), field: 'promotionCode' }],
                  [{ title: i18n.t('concierge.create_at', { locale: language }), field: 'formateCreated' }],
                  [{ title: i18n.t('concierge.average_order_value', { locale: language }), field: 'averageOrder' }],
                  [{ title: i18n.t('concierge.total_number_of_orders', { locale: language }), field: 'totalOrders' }],
                  [{ title: i18n.t('concierge.days_from_last_order', { locale: language }), field: 'lastOrderDays' }],
                  [{ title: i18n.t('concierge.favourite_malls', { locale: language }), field: 'displayFavouritedMalls' }],
                  [{ title: i18n.t('concierge.favourite_content', { locale: language }), field: 'displayFavouriteContentTypes' }],
                  [{ title: 'Interest Clubs', field: 'displayJoinedInterestClubs' }],
                  [
                    {
                      title: i18n.t('concierge.direct_marketing', { locale: language }),
                      field: isConcierge ? 'optOutFromDirectMarketingTC' : 'optOutFromDirectMarketing',
                    },
                  ],
                  [{ title: i18n.t('concierge.active_status', { locale: language }), field: 'status' }],
                  [{ title: i18n.t('concierge.available_campaign', { locale: language }), field: 'availableCampaign' }],
                  [{ title: i18n.t('concierge.coupons', { locale: language }), field: 'coupons' }],
                ]}
                extraDataFields={{ availableCampaign: avaliableCampaigns }}
              />,
              <MembershipInfoCard
                title={i18n.t('concierge.personal_information', { locale: language })}
                data={customer}
                fields={[
                  [{ title: i18n.t('concierge.first_name', { locale: language }), field: 'firstName' }],
                  [{ title: i18n.t('concierge.last_name', { locale: language }), field: 'lastName' }],
                  [{ title: i18n.t('concierge.gender', { locale: language }), field: isConcierge ? 'displayGenderTC' : 'displayGender' }],
                  [{ title: i18n.t('concierge.birth_year', { locale: language }), field: 'displayYearOfBirth' }],
                  [{ title: i18n.t('concierge.birth_month', { locale: language }), field: 'displayMonthOfBirth' }],
                  [{ title: i18n.t('concierge.residential_district', { locale: language }), field: isConcierge ? 'displayResidentialDistrictTC' : 'displayResidentialDistrict' }],
                  [{ title: i18n.t('concierge.mobile_number', { locale: language }), field: 'mobileNumber' }],
                  [{ title: i18n.t('concierge.email', { locale: language }), field: 'email' }],
                  [{ title: i18n.t('concierge.working_district', { locale: language }), field: isConcierge ? 'displayWorkingDistrictTC' : 'displayWorkingDistrict' }],
                  [{ title: i18n.t('concierge.marital_status', { locale: language }), field: isConcierge ? 'displayMaritalStatusTC' : 'displayMaritalStatus' }],
                  [{ title: i18n.t('concierge.household_income', { locale: language }), field: 'householdIncome' }],
                  [{ title: i18n.t('concierge.have_children', { locale: language }), field: isConcierge ? 'displayHaveChildrenTC' : 'displayHaveChildren' }],
                  [{ title: i18n.t('concierge.age_range_of_children', { locale: language }), field: 'displayAgeOfChildren' }],
                  [{ title: i18n.t('concierge.assign_as_test_customer', { locale: language }), field: isConcierge ? 'assignToTestTC' : 'assignToTest' }],
                ]}
              />,
              <MembershipInfoCard
                title={i18n.t('concierge.car_owner_information', { locale: language })}
                data={customer}
                fields={[
                  [{ title: i18n.t('concierge.monthly_parking_user', { locale: language }), field: isConcierge ? 'displayIsMonthlyParkingUserTC' : 'displayIsMonthlyParkingUser' }],
                  ...octopusCards,
                  [{ title: i18n.t('concierge.car_type', { locale: language }), field: isConcierge ? 'displayCarTypeTC' : 'displayCarType' }],
                  [{ title: i18n.t('concierge.license_number', { locale: language }), field: 'displayLicenseNumber' }],
                ]}
              />,
            ]}
            hidePreview={true}
            // sectionStyle="detail-info-section-item"
          />
        </div>
      ),
    },
    {
      name: i18n.t('concierge.record', { locale: language }),
      content: (
        <div className="scroll-container-common">
          <ContentSections
            sections={[
              <RecordTableCard
                title={i18n.t('transaction.transaction', { locale: language })}
                buttonText={i18n.t('concierge.view_all_record', { title: i18n.t('transaction.transaction', { locale: language }),locale: language })}
                data={transactions?.slice(0, 10)}
                // data={[
                //   {
                //     pk: 1,
                //     createdDate: '12 Aug 2020 (Wed)',
                //     eventType: 'Void',
                //     transactionDate: 'Void\r\n12 Aug 2020 (Wed)',
                //     brand: 'Zara',
                //     store: 'AAA',
                //     transactionValue: 100,
                //   },
                // ]}
                fields={[
                  { displayName: 'ID', name: 'pk' },
                  { displayName: i18n.t('transaction.create_at', { locale: language }), name: 'createdDate' },
                  {
                    displayName: i18n.t('transaction.transaction_type', { locale: language }),
                    name: isConcierge ? 'transactionDisplayFormType' : 'transactionEnDisplayFormType',
                  },
                  { displayName: i18n.t('transaction.mall', { locale: language }), name: isConcierge ? 'mallTCName' : 'mallName' },
                  { displayName: i18n.t('transaction.store', { locale: language }), name: isConcierge ? 'storeName' : 'displayStoreName' },
                  { displayName: i18n.t('transaction.payment_method', { locale: language }), name: isConcierge ? 'displayTCPaymentMethod' : 'displayPaymentMethod' },
                  { displayName: i18n.t('transaction.transaction_date', { locale: language }), name: 'displayTransactionDate' },
                  { displayName: i18n.t('transaction.total_value', { locale: language }), name: 'totalValue' },
                  { displayName: i18n.t('transaction.status', { locale: language }), name: isConcierge ? 'displayOfflineEventType' : 'displayEnOfflineEventType' },
                ]}
                viewAllLink={`/${isConcierge ? 'concierge_transaction_records' : 'transaction_records'}?search=${customer.membershipId}`}
                buttonRequires={PermissionCodes.viewTransaction}
              />,
              <RecordTableCard
              title={i18n.t('concierge.coupons', { locale: language })}
              buttonText={i18n.t('concierge.view_all_record', { title: i18n.t('concierge.coupons', { locale: language }),locale: language })}
                data={couponTransactions.slice(0, 10)}
                fields={[
                  { displayName: 'ID', name: 'pk' },
                  { displayName: i18n.t('couponRecords.creation_date', { locale: language }), name: 'createdDate' },
                  { displayName: i18n.t('couponRecords.record_type', { locale: language }), name: isConcierge ? 'displayTCType' : 'displayType' },
                  { displayName: i18n.t('concierge.coupon_set_name', { locale: language }), name: isConcierge ? 'couponTemplateTCName' : 'couponTemplateName' },
                  { displayName: i18n.t('transaction.event_type', { locale: language }), name: isConcierge ? 'eventTCType' : 'eventType' },
                  { displayName: i18n.t('couponRecords.expiry_date', { locale: language }), name: 'couponExpiryDate' },
                ]}
                viewAllLink={`/${isConcierge ? 'concierge_coupon_records' : 'coupon_records'}?search=${customer.membershipId}`}
                tableWidth={694}
                buttonRequires={PermissionCodes.viewCouponTransaction}
              />,
            ]}
            hidePreview={true}
            sectionStyle="detail-record-section-item"
          />
        </div>
      ),
    },
  ];

  const buttons = [
    <AuthButton
      title={i18n.t('concierge.edit', { locale: language })}
      action={() => {
        dispatch({ type: 'customerList/clearData' });
        history.push({
          pathname: 'edit/',
        });
      }}
      requires={PermissionCodes.changeCustomer}
    />,
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  useEffect(() => {
    dispatch({ type: 'customerList/clearData' });
  }, [dispatch]);

  useEffect(() => {
    dispatch({
      type: 'customerList/getOneCustomer',
      payload: {id}
    });
  }, [dispatch, id]);

  useEffect(() => {
    dispatch({
      type: 'campaignList/getAvaliableCampaignNamesList',
      payload: {
        ssoUid: customer.ssoUid,
        isAll: true,
      }
    });
    dispatch({
      type: 'couponTransactionRecord/getCouponTransactions',
      payload: {
        searchKey: customer.membershipId,
      }
    });
    dispatch({
      type: 'transactionRecord/getCurrentPageTransactions',
      payload: {
        searchKey: customer.membershipId,
        rank: true,
      }
    });
  }, [dispatch, customer]);

  return (
    <div className="customer">
      <CustomListComponent
        caution={{
          detail: '',
          title: customer.name,
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb name={customer.name} />}
        buttons={buttons}
        tabs={tabs}
        onTabChange={(key) => {
          setActiveTab(key);
          //   let search = '';
          //   if (key !== 'Overview') {
          //     // search = queryString.stringify({
          //     //   [URLFilterKeys.type]: CampaignType.allTypes,
          //     //   [URLFilterKeys.page]: 1,
          //     //   [URLFilterKeys.searchKey]: '',
          //     //   [URLFilterKeys.rank]: true,
          //     // });
          //   }
          //   history.push({ pathname: '/customer', hash: key, search: search });
        }}
      />
    </div>
  );
}

const mapPropsToState = (state) => ({
  customer: state.customerList.customer,
  couponTransactions: state.couponTransactionRecord.couponTransactionList || [],
  transactions: state.transactionRecord.currentPageTransactionList || [],
  avaliableCampaigns: state.campaignList.avaliableCampaignNamesList,
});

export default connect(mapPropsToState)(CustomerOverviewDetail);
