import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import '../ScrollableComponent.scss';
import { useHistory } from 'react-router-dom';
import { ToastType } from '../../../models/NavBarModel';
import { isLongScreen } from '../../../utils/ScreenUtil';
import CustomSteps from '../CustomSteps';
import CustomTips from '../CustomTips';
import Loading from '../Loading';
import BasePrompt from '../prompt/BasePrompt';
import NextStepPrompt from '../prompt/NextStepPrompt';
import ContinueCreatePrompt from '../ContinueCreatePrompt';
import CustomViewer from '../CustomViewer';
import { LanguageConfig } from '../../../config/CustomEnums';
import i18n from '../../../I18n';

const getFunctionNameFromAction = (action) => {
  switch (action) {
    case 'POP':
      return 'goBack';
    case 'PUSH':
      return 'push';
    case 'REPLACE':
      return 'replace';
    default:
      return 'push';
  }
};

const BaseForm = ({
  defaultValues,
  stepsConfig,
  breadcrumb,
  caution,
  content,
  currentStep,
  formHasSubmitted,
  hasUpdatedDefaultValues,
  tempSave,
  continueCreateConfig = {},
  nextStepConfig = {},
  showFinishPop,
  buttons = [],
  hasleavePagePrompt = false,
  hasCancelButton = false,
  language = LanguageConfig.english,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [showLeavePagePromptPopup, setShowLeavePagePromptPopup] = useState(false);
  const [locationBlock, setLocationBlock] = useState({ unblock: () => {} });
  const [nextLocation, setNextLocation] = useState({});
  const [onSaving, setOnSaving] = useState(false);
  const dispatch = useDispatch();
  const methods = useForm({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues,
  });

  const formChanged = methods.formState.isDirty;
  const history = useHistory();
  const navigateToNextLocation = () => {
    const action = nextLocation?.action;
    const location = nextLocation?.nextLocation || { pathname: '/' };
    const functionName = getFunctionNameFromAction(action);
    locationBlock.unblock();
    if (functionName === 'goBack') {
      setLocationBlock({ unblock: () => {} });
      history.goBack();
    } else {
      setLocationBlock({ unblock: () => {} });
      history[functionName](location);
    }
  };
  const onConfirm = () => {
    setShowPopup(false);
    locationBlock.unblock();
    navigateToNextLocation();
  };
  const block = (nextLocation, action) => {
    let result = methods.formState.isDirty;
    if (formHasSubmitted) {
      result = false;
    }

    setShowPopup(result);
    setNextLocation({ nextLocation, action });
    return !result;
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  useEffect(() => {
    if (formChanged) {
      window.onbeforeunload = refreshOrClose;
      setLocationBlock({ unblock: history.block(block) });
    } else {
      window.onbeforeunload = null;
      setLocationBlock({ unblock: () => {} });
    }
    if (formHasSubmitted) {
      window.onbeforeunload = null;
      locationBlock.unblock();
      setLocationBlock({ unblock: () => {} });
    }
  }, [formChanged, formHasSubmitted]);

  const refreshOrClose = () => {
    if (formChanged) {
      return i18n.t('you_have_unsaved_changes', { locale: language });
    }
    return;
  };

  useEffect(() => {
    return () => {
      locationBlock.unblock();
      window.onbeforeunload = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasUpdatedDefaultValues) {
      methods.reset(defaultValues);
    }
  }, [defaultValues, hasUpdatedDefaultValues]);

  const onCancel = () => {
    setShowPopup(false);
  };
  const cautionComponent = (
    <div className="caution-area">
      <CustomTips detail={caution.detail}>
        {caution.titleIsHtml ? (
          <CustomViewer
            className="list-section-container-header-title"
            initValue={caution.title}
          />
        ) : (
          <label className="caution-text">{caution.title}</label>
        )}
      </CustomTips>
    </div>
  );
  const unSavePrompt = (
    <BasePrompt
      show={showPopup}
      closeAction={onCancel}
      rightButton={{
        text: hasCancelButton ? i18n.t('transaction.cancel', { locale: language }): i18n.t('save', { locale: language }),
        action: () => {
          if (hasCancelButton) {
            onCancel();
            return
          }
          tempSave(true, methods.getValues);
          setOnSaving(true);
          setTimeout(() => {
            dispatch({
              type: 'navBars/updateState',
              payload: {
                saveDiscardToastShowing: {
                  value: true,
                  type: ToastType.save,
                },
              },
            });
            onConfirm();
          }, 1500);
        },
      }}
      leftButton={{
        text: i18n.t('discard_changes', { locale: language }),
        action: () => {
          if (hasleavePagePrompt) {
            setShowPopup(false);
            setShowLeavePagePromptPopup(true);
            return 
          } 
          tempSave(false, methods.getValues);
          dispatch({
            type: 'navBars/updateState',
            payload: {
              saveDiscardToastShowing: {
                value: true,
                type: ToastType.discard,
              },
            },
          });
          onConfirm();
        },
      }}
      title={i18n.t('you_have_unsaved_changes', { locale: language })}
      description={i18n.t('save_changes_before_leaving', { locale: language })}
    />
  );

  const leavePagePrompt = (
    <BasePrompt
      show={showLeavePagePromptPopup}
      closeAction={() => {setShowLeavePagePromptPopup(false)}}
      rightButton={{
        text: 'Leave page',
        action: () => {
          tempSave(false, methods.getValues);
          dispatch({
            type: 'navBars/updateState',
            payload: {
              saveDiscardToastShowing: {
                value: true,
                type: ToastType.discard,
              },
            },
          });
          setShowLeavePagePromptPopup(false);
          locationBlock.unblock();
          navigateToNextLocation();
        },
      }}
      leftButton={{
        text: 'Cancel',
        action: () => {
          setShowLeavePagePromptPopup(false);
        },
      }}
      title={'Unsaved changes'}
      description={'If you leave this page, any unsaved changes will be lost.'}
    />
  );


  const finishCreateFlowPop = (
    <NextStepPrompt
      show={
        showFinishPop &&
        !history.location?.state?.from &&
        !history.location.pathname.includes('edit')
      }
      {...nextStepConfig}
    />
  );

  const continueCreatePop = (
    <ContinueCreatePrompt
      show={
        showFinishPop &&
        history.location?.state?.from &&
        !history.location.pathname.includes('edit')
      }
      {...continueCreateConfig}
    />
  );
  return (
    <>
      <div className="container-common scroll-container-common-header">
        <div
          className={`header-width ${
            isLongScreen
              ? 'scroll-container-long-screen'
              : 'scroll-container-short-screen'
          }`}
        >
          {breadcrumb}
          <div className="list-section-container-button-and-title">
            {cautionComponent}
            <div className="list-section-container-header-buttons">
              {buttons.map((item, index) => (
                <div key={index}>{item}</div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`scroll-container-common ${
          isLongScreen
            ? 'scroll-container-long-screen'
            : 'scroll-container-short-screen'
        }`}
      >
        {stepsConfig ? <CustomSteps stepsConfig={stepsConfig} /> : null}
        {onSaving ? (
          <div className="on-saving-area-active">
            <Loading />
          </div>
        ) : (
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit((data) => {
                console.log(data);
              })}
            >
              {content[currentStep]}
            </form>
          </FormProvider>
        )}
        {unSavePrompt}
        {leavePagePrompt}
        {finishCreateFlowPop}
        {continueCreatePop}
      </div>
    </>
  );
};

export default BaseForm;
