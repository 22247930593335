import { BaseHelper } from './BaseGraphQLAPIHelper';
import { getSearchKey } from '../utils';

const DistrictMallMappingNode = `{
  id
  pk
  favouriteMall1{
    pk
    id
    name
  }
  favouriteMall2{
      pk
      id
      name
  }
  favouriteMall3{
      pk
      id
      name
  }
  workingDistrict{
      pk
      id
      name
  }
  residentialRegion{
      pk
      id
      name
  }
}`;

export const getPagedDistrictMallMappings = (
  afterCursor,
  payload,
) => {

  let filter = ''
  const nameContains = getSearchKey(payload?.search) || null;
  if(nameContains){
    filter = `, nameIcontains: "${nameContains}"`;
  }

  const query = `{
    districtMallMappings (first: 20, after: "${afterCursor}", orderBy:"pk" ${filter}){
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node ${DistrictMallMappingNode}
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateDistrictMallMapping = (values) => {
  const query = `mutation UpdateDistrictMallMapping($input: UpdateDistrictMallMappingInput!) {
      updateDistrictMallMapping(input: $input) {
        success
        node ${DistrictMallMappingNode}
        errors {
          field
          messages
        }
      }
    }`;
  const variables = { input: values };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneDistrictMallMapping = (id) => {
  const query = `{
    districtMallMapping(id:"${id}") 
    ${DistrictMallMappingNode}
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

