import {
  getSegmentList,
  getOneSegment,
  createOrUpdateSegment,
  updateSegment,
  deleteSegments,
} from '../services/SegmentAPIHelper';
import {
  CheckStatus,
  SavedStatus,
  ConditionTag,
  DaysTag,
  APIStatus,
} from '../config/CustomEnums';
import { apiWithResponseHandle, loading } from './LoadingUtil';
import { EarningRuleErrorHandleField } from '../components/earning/EarningRuleHandleError';
import {
  convertCursorToNumber,
  convertNumberToCursor,
  delay,
  convertPKToId,
  saveToSessionStorage,
  removeFromSessionStorage,
  getObjectFromSessionStorage,
  capitalizeFirstLetter,
} from '../utils';

const segmentSessionKey = 'tempSegment';

function formatLookupWithSelector(source, key, selectorKey) {
  if (source[key]) {
    console.log('kevin@262', key, selectorKey, source[key][selectorKey]);
    return {
      lookup: ConditionTag[source[key].lookup],
      selected: source[key][selectorKey]
        ? source[key][selectorKey].edges.map((item) => ({
            pk: item.node.pk,
            name:
              key === 'countryCodeCharacteristic'
                ? '+' + item.node.name
                : item.node.name === 'Not disclosed'
                ? 'Prefer not to say'
                : item.node.name,
            value: item.node.pk,
          }))
        : [],
    };
  }
}

function formatLookupWithMinMax(source, key, subKey) {
  if (source[key]) {
    return {
      lookup: ConditionTag[source[key].lookup],
      minNum:
        source[key][subKey] ||
        source[key][`minimum${capitalizeFirstLetter(subKey)}`],
      maxNum: source[key][`maximum${capitalizeFirstLetter(subKey)}`],
    };
  }
}

function checkAndRemoveInvalid(source, key, listKey) {
  if (
    !source[key] ||
    (source[key].lookup === 'IN' &&
      source[key][listKey] &&
      source[key][listKey].length <= 0)
  ) {
    delete source[key];
  }
}

function fillParamValues(data, source, param, key) {
  if (!data[param]) {
    return;
  }

  switch (data[param].lookup) {
    case 'BETWEEN':
      console.log('@@78: ', source[param].minNum, source[param].maxNum);
      if (
        source[param].minNum === null ||
        source[param].minNum === undefined ||
        source[param].maxNum === null ||
        source[param].maxNum === undefined
      ) {
        delete data[param];
      } else {
        data[param][`minimum${capitalizeFirstLetter(key)}`] =
          source[param].minNum;
        data[param][`maximum${capitalizeFirstLetter(key)}`] =
          source[param].maxNum;
      }
      break;
    case 'EQUAL_TO':
    case 'NOT_EQUAL_TO':
    case 'LESS_THAN':
    case 'GREATER_THAN':
      if (!source[param] || !source[param].minNum) {
        delete data[param];
      } else {
        data[param][key] = source[param].minNum;
      }
      break;
    case 'IS_EMPTY':
    case 'IS_NOT_EMPTY':
      break;
    default:
      delete data[param];
      break;
  }
}

const getDemoGraphic = (item, title, key, subKey) => {
  if (!item || !item[key]) {
    return '';
  }

  if (key === 'signUpReferralCharacteristic') {
    return item[key][subKey[0]] ? title : '';
  }

  if (key === 'productCharacteristic') {
    return item[key][subKey[0]]
      ? title.replace('xxx', item[key][subKey[0]].join(', '))
      : '';
  }

  if (!('lookup' in item[key])) {
    const value = subKey.map((name, index) =>
      item[key][name].toString().toLowerCase(),
    );
    return title.replace(
      'xxx',
      value.join(' ').replace(/_/g, ' ').replace('years', 'year'),
    );
  }

  const lookup = item[key].lookup.toLowerCase();
  let graphic = `${title} ${lookup.replace(/_/g, ' ')}`;

  if (lookup === 'in') {
    const values = item[key][subKey].edges.map((item) =>
      subKey === 'countryCodes'
        ? `+${item.node.name.toString().toLowerCase()}`
        : item.node.name === 'Not disclosed'
        ? 'prefer not to say'
        : item.node.name.toString().toLowerCase(),
    );
    graphic += ` ${values.join(', ').replace(/\b, \b(?!.*?\b, \b)/, ' and ')}`;
  } else if (
    lookup.indexOf('equal') >= 0 ||
    lookup.indexOf('less') >= 0 ||
    lookup.indexOf('greater') >= 0
  ) {
    graphic += ` ${item[key][subKey]}`;
  } else if (lookup.indexOf('between') >= 0) {
    graphic += ` ${item[key][`minimum${capitalizeFirstLetter(subKey)}`]} and ${
      item[key][`maximum${capitalizeFirstLetter(subKey)}`]
    }`;
  }
  graphic += '; ';

  return graphic;
};

const graphicKeys = [
  { Gender: { key: 'genderCharacteristic', valueKey: 'genders' } },
  { Birthday: { key: 'monthOfBirthCharacteristic', valueKey: 'months' } },
  { Age: { key: 'ageCharacteristic', valueKey: 'age' } },
  {
    'Sign up anniversary (in year)': {
      key: 'signUpAnniversaryCharacteristic',
      valueKey: 'numberOfYears',
    },
  },
  {
    'Country code': {
      key: 'countryCodeCharacteristic',
      valueKey: 'countryCodes',
    },
  },
  {
    'Last purchase in xxx number of days before; ': {
      key: 'hasRecentPurchaseCharacteristic',
      valueKey: ['inXNumberOfDays'],
    },
  },
  {
    'Transaction xxx; ': {
      key: 'numberOfTransactionsCharacteristic',
      valueKey: ['quantity', 'period'],
    },
  },
  {
    'Total spending': {
      key: 'totalSpendingCharacteristic',
      valueKey: 'dollars',
    },
  },
  {
    'Average transaction': {
      key: 'averageTransactionValueCharacteristic',
      valueKey: 'dollars',
    },
  },
  {
    'xxx sign up; ': {
      key: 'signUpMemberCharacteristic',
      valueKey: ['period'],
    },
  },
  {
    'Sign up member via referral; ': {
      key: 'signUpReferralCharacteristic',
      valueKey: ['invited'],
    },
  },
  {
    'Last login xxx; ': {
      key: 'lastLoginCharacteristic',
      valueKey: ['period'],
    },
  },
  {
    'Product is xxx; ': {
      key: 'productCharacteristic',
      valueKey: ['products'],
    },
  },
  {
    'Log in xxx times;': {
      key: 'loginTimeCharacteristic',
      valueKey: ['times'],
    },
  },
];

const getDescription = (segment) => {
  if (!segment || !segment.pk) {
    return '';
  }
  let description = '';

  graphicKeys.map((item, index) => {
    Object.keys(item).map((title, index) => {
      description += getDemoGraphic(
        segment,
        title,
        item[title]['key'],
        item[title]['valueKey'],
      );
    });
  });
  if (segment.levelCharacteristic) {
    const levels = segment.levelCharacteristic.levels.edges.map((item) =>
      item.node.levelName.toString(),
    );
    description += `Level in ${levels
      .join(', ')
      .replace(/\b, \b(?!.*?\b, \b)/, ' and ')};`;
  }

  const campaignKeys = {
    'Earn rewards': { key: 'earningCampaignCharacteristic' },
    'Buy coupons': { key: 'couponCampaignCharacteristic' },
    View: { key: 'viewCampaignCharacteristic' },
  };

  Object.entries(campaignKeys).map(([key, value], index) => {
    if (segment[value.key]) {
      let times = segment[value.key].numberOfParticipateTimes;
      const isCampaign = segment[value.key].catalogType === 'CAMPAIGN';
      const name = isCampaign
        ? segment[value.key].campaign.name
        : segment[value.key].campaignCategory.name;
      if (segment[value.key].behavioralType === 'VIEW_CAMPAIGN') {
        if (segment[value.key].viewCampaignType === 'VIEW_TIMES') {
          times = `${
            segment[value.key].numberOfViewCampaignTimes
          } times ${segment[value.key].period
            .toLowerCase()
            .replace(/_/g, ' ')} `;
        } else {
          times = `${segment[value.key].stayPeriod} ${segment[
            value.key
          ].stayPeriodType.toLowerCase()} `;
        }
        description += `${key} ${
          isCampaign ? `campaign: ${name}` : name
        } ${times}; `;
      } else {
        description += `${key} ${times} times ${
          segment[value.key].period
            ? segment[value.key].period.toLowerCase().replace(/_/g, ' ') + ' '
            : ''
        }through ${isCampaign ? `campaign: ${name}` : name}; `;
      }
    }
  });

  return capitalizeFirstLetter(description);
};

const getInitialState = () => ({
  segmentList: [],
  segmentAllList: [],
  segment: {},
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  currentLastCursor: '',
  currentPage: 0,
  totalPage: 0,
  totalCount: 0,
  checkedList: [],
  formChanged: false,
  saved: SavedStatus.init,
  checked: CheckStatus.initOrNotChecked,
  errorFields: [],
  createErrorDict: {},
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Name', fieldName: 'name', orderField: 'name' },
    { displayName: 'Description', fieldName: 'description' },
  ],
  tempSegment: {},
});

const parseSegments = (data) => {
  return data.map((item, index) => {
    // item.node.conditionGroups = [item.node];

    const descriptionList = item.node.conditionGroups?.edges.map((condition) =>
      getDescription(condition.node),
    );

    return {
      ...item.node,
      description: descriptionList?.join('\nOr '),
    };
  });
};

const parseSegmentPeriod = (contentCondition) => {
  if (
    contentCondition.periodRange &&
    contentCondition.periodRange !== 'Please select'
  ) {
    if (contentCondition.periodRange === 'All the time') {
      return contentCondition.periodRange.toUpperCase().replace(/ /g, '_');
    } else if (
      contentCondition.periodDays &&
      contentCondition.periodDays !== 'Please select'
    ) {
      let periodDays =
        contentCondition.periodRange.toUpperCase().replace(/ /g, '_') +
        '_' +
        contentCondition.periodDays.toUpperCase().replace(/ /g, '_');
      if (periodDays.indexOf('YEAR') > 0) {
        periodDays += 'S';
      }

      return periodDays;
    }
  }

  return '';
};

const parseSegmentMutipleSelectorCondition = (contentCondition, key) => {
  let condition = null;
  if (contentCondition.lookup) {
    const lookup = contentCondition.lookup.toUpperCase().replace(/ /g, '_');
    if (contentCondition.lookup !== 'In') {
      condition = { lookup };
    } else if (contentCondition.selected.length > 0) {
      let value = contentCondition.selected.map((item) =>
        key === 'levels' ? item.pk : item.value,
      );
      condition = { lookup, [key]: value };
    }
  }

  return condition;
};

const parseSegmentDataRangeCondition = (contentCondition, key) => {
  let condition = null;
  if (contentCondition.lookup) {
    const lookup = contentCondition.lookup.toUpperCase().replace(/ /g, '_');

    if (lookup.indexOf('EMPTY') >= 0) {
      condition = { lookup };
    } else if (lookup.indexOf('BETWEEN') >= 0) {
      if (
        (parseInt(contentCondition.minNum) === 0 ||
          contentCondition.minNum > 0) &&
        (parseInt(contentCondition.maxNum) === 0 || contentCondition.maxNum > 0)
      ) {
        condition = {
          lookup,
          [`minimum${capitalizeFirstLetter(key)}`]: contentCondition.minNum,
          [`maximum${capitalizeFirstLetter(key)}`]: contentCondition.maxNum,
        };
      }
    } else if (
      parseInt(contentCondition.minNum) === 0 ||
      contentCondition.minNum > 0
    ) {
      condition = {
        lookup,
        [key]: contentCondition.minNum,
      };
    }
  }

  return condition;
};

const getPeriodDays = (period) => {
  let periodDays = '';
  if (period?.indexOf('WITHIN') >= 0) {
    periodDays = period.replace('WITHIN_', '').replace(/_/g, ' ').toLowerCase();

    if (periodDays.indexOf('s') > 0) {
      periodDays = periodDays.replace('years', 'year');
    }
  }

  return periodDays;
};

export default {
  namespace: 'segments',
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },

    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },

    loadSegmentFromCookie(state, { payload }) {
      const segment = getObjectFromSessionStorage(segmentSessionKey) || {};
      const serverData = payload ? payload.data.segment : null;

      let formatData = {};
      let tempData = {};
      if (serverData) {
        console.log('@@serverData: ', serverData);
        const conditionGroups = serverData.conditionGroups?.edges.map(
          (item, index) => {
            const node = item.node;
            let content = {};
            Object.entries(node).map(([key, value]) => {
              // console.log('@@369: ', key, value);
              if (!value || Object.keys(value).length <= 0) {
                // console.log('@@369: ', key);
                return;
              }
              // console.log('@@369-1: ', key);
              switch (key) {
                case 'genderCharacteristic':
                  content[key] = {
                    ...value,
                    ...formatLookupWithSelector(node, key, 'genders'),
                  };
                  break;
                case 'monthOfBirthCharacteristic':
                  content[key] = {
                    ...value,
                    ...formatLookupWithSelector(node, key, 'months'),
                  };
                  break;
                case 'ageCharacteristic':
                  content[key] = {
                    ...node[key],
                    ...formatLookupWithMinMax(node, key, 'age'),
                  };
                  break;
                case 'countryCodeCharacteristic':
                  content[key] = {
                    ...node[key],
                    ...formatLookupWithSelector(node, key, 'countryCodes'),
                  };
                  break;
                case 'signUpAnniversaryCharacteristic':
                  content[key] = {
                    ...node[key],
                    ...formatLookupWithMinMax(node, key, 'numberOfYears'),
                  };
                  break;
                case 'totalSpendingCharacteristic':
                case 'averageTransactionValueCharacteristic':
                  content[key] = {
                    ...node[key],
                    ...formatLookupWithMinMax(node, key, 'dollars'),
                  };
                  break;
                case 'levelCharacteristic':
                  console.log(
                    '@@407: ',
                    node[key].levels.edges.map((item) => item.node),
                  );
                  content[key] = {
                    ...node[key],
                    lookup: node[key].levels.edges.length > 0 ? 'In' : '',
                    selected: node[key].levels.edges.map((item) => ({
                      ...item.node,
                      label: item.node.levelName,
                      value: item.node,
                      name: item.node.levelName,
                    })),
                  };
                  break;
                case 'hasRecentPurchaseCharacteristic':
                  content.lastPurchase =
                    node.hasRecentPurchaseCharacteristic.inXNumberOfDays;
                  break;
                case 'numberOfTransactionsCharacteristic':
                case 'signUpMemberCharacteristic':
                case 'lastLoginCharacteristic':
                  content[key] = {
                    ...node[key],
                    number: node[key].quantity,
                    periodRange:
                      node[key].period.indexOf('WITHIN') >= 0
                        ? 'Within'
                        : capitalizeFirstLetter(
                            node[key].period.toLowerCase().replace(/_/g, ' '),
                          ),
                    periodDays: getPeriodDays(node[key].period),
                  };
                  break;

                case 'signUpReferralCharacteristic':
                  content.isSignupReferral =
                    node.signUpReferralCharacteristic.invited;
                  break;
                case 'loginTimeCharacteristic':
                  content.numberOfLogin = node.loginTimeCharacteristic.times;
                  break;
                case 'productCharacteristic':
                  content[key] = {
                    selected: node.productCharacteristic.products,
                  };
                  break;
                case 'earningCampaignCharacteristic':
                case 'couponCampaignCharacteristic':
                  content[key] = {
                    ...node[key],
                    viewType: capitalizeFirstLetter(
                      node[key].catalogType.replace(/_/g, ' ').toLowerCase(),
                    ),
                    viewValue:
                      node[key].catalogType === 'CAMPAIGN'
                        ? {
                            label: node[key].campaign.name,
                            value: node[key].campaign,
                          }
                        : {
                            label: node[key].campaignCategory.name,
                            value: node[key].campaignCategory,
                          },
                    viewedTimeType: 'VIEW_TIMES',
                    viewTimes: node[key].numberOfParticipateTimes,
                    periodRange:
                      node[key].period?.indexOf('WITHIN') >= 0
                        ? 'Within'
                        : node[key].period
                        ? capitalizeFirstLetter(
                            node[key].period?.toLowerCase(),
                          ).replace(/_/g, ' ')
                        : null,
                    periodDays: getPeriodDays(node[key].period),
                  };
                  break;
                case 'viewCampaignCharacteristic':
                  content[key] = {
                    ...node[key],
                    viewType: capitalizeFirstLetter(
                      node[key].catalogType.replace(/_/g, ' ').toLowerCase(),
                    ),
                    viewValue:
                      node[key].catalogType === 'CAMPAIGN'
                        ? {
                            label: node[key].campaign.name,
                            value: node[key].campaign,
                          }
                        : {
                            label: node[key].campaignCategory.name,
                            value: node[key].campaignCategory,
                          },
                    viewedTimeType: node[key].viewCampaignType,
                    number:
                      node[key].viewCampaignType === 'VIEW_TIMES'
                        ? node[key].numberOfViewCampaignTimes
                        : node[key].stayPeriod,
                    periodRange:
                      node[key].viewCampaignType === 'STAY_TIME'
                        ? capitalizeFirstLetter(
                            node[key].stayPeriodType.toLowerCase(),
                          )
                        : node[key].period?.indexOf('WITHIN') >= 0
                        ? 'Within'
                        : capitalizeFirstLetter(
                            node[key].period.toLowerCase(),
                          ).replace(/_/g, ' '),
                    periodDays: getPeriodDays(node[key].period),
                  };
                  break;
                case 'behavioralCampaignCharacteristics':
                  break;
                default:
                  // console.log('@@518: ', key, node[key], value);
                  content[key] = value;
                  break;
              }
            });
            // console.log('@@217: ', content);
            // return {
            //   [index + 1]: {
            //     ...item.node,
            //     ...content,
            //   },
            // };
            console.log('@@527: ', content);
            return {
              // ...item.node,
              ...content,
            };
          },
        );

        formatData = {
          id: serverData.id,
          pk: serverData.pk,
          name: serverData.name,
          generalName: serverData.name,
          conditionGroup: conditionGroups,
        };

        if (!payload.isView) {
          tempData = {
            id: serverData.id,
            pk: serverData.pk,
            name: serverData.name,
            generalName: serverData.name,
            conditionGroup: Object.assign(
              {},
              ...conditionGroups.map((condition, index) => ({
                [index + 1]: condition,
              })),
            ),
          };
          saveToSessionStorage(segmentSessionKey, tempData);
        }

        console.log('@@578: ', formatData);
      } else {
        formatData = {
          ...segment,
          conditionGroup: segment.conditionGroup
            ? Object.keys(segment.conditionGroup).map(
                (key) => segment.conditionGroup[key],
              )
            : null,
        };
        delete formatData.pk;
        delete formatData.id;

        // saveToSessionStorage(segmentSessionKey, formatData);
      }

      return {
        ...state,
        segment: {
          ...formatData,
          numberOfcustomers: payload?.data.segment?.customers?.edges.length,
          description: payload?.data.segment?.conditionGroups?.edges
            .map((condition) => getDescription(condition.node))
            .join('\nOr '),
        },
        tempSegment: tempData,
      };
    },
    removeFromCookie(state, { payload }) {
      removeFromSessionStorage(segmentSessionKey);

      return {
        ...state,
        formChanged: false,
        tempSegment: {},
      };
    },

    changeVals(state, { payload }) {
      console.log('@@138: vals changed', payload);
      let tempSegment = getObjectFromSessionStorage(segmentSessionKey);

      let data = {};
      if (payload.conditionIndex) {
        const originalConfition = tempSegment?.conditionGroup
          ? tempSegment.conditionGroup[payload.conditionIndex] || {}
          : {};
        data[payload.conditionIndex] = {};

        if (payload.groupName) {
          data[payload.conditionIndex][payload.groupName] = {};
          data[payload.conditionIndex][payload.groupName] = {
            ...originalConfition[payload.groupName],
            ...payload.vals,
          };
        } else {
          data[payload.conditionIndex] = {
            ...originalConfition,
            ...payload.vals,
          };
          //console.log('@@413: ', data[payload.conditionIndex]);
        }
      } else {
        data = payload.vals;
      }

      // if (payload.groupName) {
      //   data[payload.groupName] = payload.vals;
      // } else if (payload.vals) {
      //   data = payload.vals;
      // }

      if (payload.conditionIndex) {
        tempSegment = {
          ...tempSegment,
          ...data,
          conditionGroup: {
            ...tempSegment?.conditionGroup,
            [payload.conditionIndex]: {
              ...(tempSegment?.conditionGroup
                ? tempSegment.conditionGroup[payload.conditionIndex]
                : {}),
              ...data[payload.conditionIndex],
            },
          },
        };
      } else {
        tempSegment = {
          ...tempSegment,
          ...data,
        };
      }

      saveToSessionStorage(segmentSessionKey, tempSegment);

      return {
        ...state,
        formChanged: true,
        tempSegment,
      };
    },

    removeVals(state, { payload }) {
      console.log('@@138: vals removed', payload);
      let tempSegment = getObjectFromSessionStorage(segmentSessionKey);

      if (payload.conditionIndex) {
        if (payload.groupName) {
          if (
            tempSegment.conditionGroup[payload.conditionIndex] &&
            payload.groupName in
              tempSegment.conditionGroup[payload.conditionIndex]
          ) {
            delete tempSegment.conditionGroup[payload.conditionIndex][
              payload.groupName
            ];
          }
        } else if (tempSegment) {
          delete tempSegment.conditionGroup[payload.conditionIndex];
        }

        saveToSessionStorage(segmentSessionKey, tempSegment);
      }

      return {
        ...state,
        formChanged: true,
        tempSegment,
      };
    },

    resetVals(state, { payload }) {
      let tempSegment = getObjectFromSessionStorage(segmentSessionKey);
      const serverSegment = state.segment;
      // console.log('@@674: ', serverSegment);

      if (payload.conditionIndex) {
        const conditionGroupId =
          tempSegment.conditionGroup[payload.conditionIndex].pk;
        const source = serverSegment.conditionGroup.filter(
          (item) => item.pk === conditionGroupId,
        );
        console.log('@@679-source: ', source);
        tempSegment = {
          ...tempSegment,
          conditionGroup: {
            ...tempSegment.conditionGroup,
            [payload.conditionIndex]: source[0],
          },
        };
        saveToSessionStorage(segmentSessionKey, tempSegment);
      }
      console.log('@@679: ', tempSegment, payload.conditionIndex);
      return {
        ...state,
        formChanged: true,
        tempSegment,
      };
    },

    checkValsValid(state, { payload }) {
      let errorFields = [];
      let checked = CheckStatus.checkedWithSuccess;
      const segment = getObjectFromSessionStorage(segmentSessionKey);
      if (!payload.data.generalName) {
        errorFields.push(EarningRuleErrorHandleField['generalName'].name);
        checked = CheckStatus.checkedWithFail;
      }
      let createErrorDict = {};
      if (segment?.conditionGroup) {
        for (const [index, group] of Object.entries(segment.conditionGroup)) {
          console.log(index, group);
          for (const [key, value] of Object.entries(group)) {
            console.log(key, value);
            switch (key) {
              case 'genderCharacteristic':
              case 'monthOfBirthCharacteristic':
              case 'countryCodeCharacteristic':
              case 'levelCharacteristic':
                if (value?.lookup === 'In' && value?.selected?.length === 0) {
                  createErrorDict[index] = `Please provide ${key} condition`;
                }
                break;
              case 'ageCharacteristic':
              case 'signUpAnniversaryCharacteristic':
              case 'totalSpendingCharacteristic':
              case 'averageTransactionValueCharacteristic':
                if (value?.lookup === "Please select") {
                  createErrorDict[index] = `Please provide ${key} condition`;
                }
                break;
              case 'signUpMemberCharacteristic':
              case 'lastLoginCharacteristic':
                if (value?.periodRange === "Please select") {
                  createErrorDict[index] = `Please provide period range`;
                }
                break;
              case 'numberOfTransactionsCharacteristic':
              case 'viewCampaignCharacteristic':
              case 'couponCampaignCharacteristic':
              case 'earningCampaignCharacteristic':
                if (value?.periodRange === "Please select") {
                  createErrorDict[index] = `Please provide period range`;
                } else if (value?.periodRange === "Within" && value?.periodDays ==="Please select") {
                  createErrorDict[index] = `Please provide period range`;
                }
                if (!value?.number || isNaN(parseInt(value.number))) {
                  createErrorDict[index] = `Please provide number`;
                }
                break;
              case 'lastPurchase':
              case 'numberOfLogin':
                break;
              default:
            }
          }
        }
      }
      if (Object.keys(createErrorDict).length > 0) {
        checked = CheckStatus.checkedWithFail;
      }
      return {
        ...state,
        checked,
        errorFields,
        createErrorDict,
      };
    },
  },

  effects: {
    getSegmentsList: [
      function* ({ payload }, { call, select, put }) {
        // yield put({
        //   type: 'loading/updateState',
        //   payload: { status: APIStatus.calling },
        // });

        const page = payload.page;
        const pageCursor = payload.page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const serviceArgs = [getSegmentList, pageCursor, payload];
        console.log(
          '@@423: ',
          page,
          pageCursor,
          payload.reverse,
          payload.type,
          payload.search,
          payload.dateOfBirthRange,
        );
        function* onSuccess(data) {
          console.log('@@115: ', data);
          const pageInfo = data?.segments?.pageInfo;

          const currentLastCursor = pageInfo?.endCursor;
          const totalCount = data?.segments?.totalCount;

          yield put({
            type: 'updateState',
            payload: {
              segmentList: parseSegments(data?.segments?.edges),
              pageInfo: {
                startCursor: convertCursorToNumber(pageInfo.startCursor) + 1 || 0,
                endCursor: convertCursorToNumber(pageInfo.endCursor) + 1 || 0,
              },
              currentLastCursor,
              totalCount,
              totalPage: Math.ceil(totalCount / 20),
            },
          });

          // yield put({
          //   type: 'loading/updateState',
          //   payload: { status: APIStatus.success },
          // });
        }
        // function* onFailed(data) {
        //   yield put({
        //     type: 'loading/updateState',
        //     payload: { status: APIStatus.failed },
        //   });
        // }

        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getAllSegmentsList: [
      function* ({ payload }, { call, select, put }) {
        const serviceArgs = [getSegmentList, '', payload];
        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: {
              segmentAllList: parseSegments(data?.segments?.edges),
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    delete: [
      function* ({ payload }, { select, put, all }) {
        const { checkedSegments } = yield select((state) => ({
          checkedSegments: state.segments.checkedList,
        }));

        let pks = [];
        // let deleteCampaignIds = [];
        checkedSegments.forEach((item) => {
          pks.push(item.pk);

          // item.node.campaigns.edges.forEach((campaign) => {
          //   deleteCampaignIds.push(campaign.node.pk);
          // });
        });

        const serviceArgs = [deleteSegments, pks];
        const afterAction = payload.afterAction || (() => {});
        function* onSuccess(data) {
          console.log('@@153: ', data);
          yield all([
            put({
              type: 'segments/updateState',
              payload: { checkedList: [] },
            }),
          ]);
          yield delay(1000);
          afterAction();
        }
        function* onFailed(data) {
          console.log('@@122: ', data);
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    getOneSegment: [
      function* ({ payload }, { call, select, put }) {
        const serviceArgs = [
          getOneSegment,
          convertPKToId('SegmentNode', payload.id),
        ];
        function* onSuccess(data) {
          console.log('@@115: ', data);
          yield put({
            type: 'loadSegmentFromCookie',
            payload: {
              data,
              isView: payload.view || false,
            },
          });
        }
        function* onFailed(data) {
          console.log('@@122: ', data);
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    createOrUpdateSegment: [
      function* ({ payload }, { call, select, put }) {
        const segment = getObjectFromSessionStorage(segmentSessionKey);
        const serverSegment = yield select((state) => state.segments.segment);
        console.log('start creare or update segment: ', serverSegment);

        let conditionGroups = [];
        let conditionGroupsToDelete = [];
        const segmentConditionGroup = segment.conditionGroup || {};

        Object.keys(segmentConditionGroup).map((key, index) => {
          if (!isNaN(key)) {
            let oneConditionGroup = { displayOrder: key };
            const originalCondition = serverSegment.conditionGroup
              ? serverSegment.conditionGroup[key - 1] || {}
              : {};

            Object.keys(segment.conditionGroup[key]).map(
              (group, groupIndex) => {
                const contentCondition = segment.conditionGroup[key][group];
                if (!contentCondition) {
                  return;
                }

                let groupName = group;
                oneConditionGroup[groupName] = null;
                // let valueKey = '';

                switch (group) {
                  case 'isSignupReferral':
                    groupName = 'signUpReferralCharacteristic';
                    // valueKey = 'invited';
                    oneConditionGroup[groupName] = {
                      invited: contentCondition,
                    };
                    break;
                  case 'lastPurchase':
                    groupName = 'hasRecentPurchaseCharacteristic';
                    // valueKey = 'inXNumberOfDays';
                    oneConditionGroup[groupName] = {
                      inXNumberOfDays: contentCondition,
                    };
                    break;
                  case 'numberOfLogin':
                    groupName = 'loginTimeCharacteristic';
                    // valueKey = 'times';
                    oneConditionGroup[groupName] = {
                      times: contentCondition,
                    };
                    break;
                  case 'productCharacteristic':
                    // valueKey = 'sku';
                    oneConditionGroup[group] = {
                      products: contentCondition.selected,
                    };
                    break;
                  case 'genderCharacteristic':
                    // valueKey = 'gender';
                    oneConditionGroup[
                      groupName
                    ] = parseSegmentMutipleSelectorCondition(
                      contentCondition,
                      'genders',
                    );
                    break;
                  case 'monthOfBirthCharacteristic':
                    // valueKey = 'months';
                    oneConditionGroup[
                      groupName
                    ] = parseSegmentMutipleSelectorCondition(
                      contentCondition,
                      'months',
                    );
                    break;
                  case 'countryCodeCharacteristic':
                    // valueKey = 'countryCodes';
                    oneConditionGroup[
                      groupName
                    ] = parseSegmentMutipleSelectorCondition(
                      contentCondition,
                      'countryCodes',
                    );
                    break;
                  case 'levelCharacteristic':
                    // valueKey = 'levels';
                    oneConditionGroup[
                      groupName
                    ] = parseSegmentMutipleSelectorCondition(
                      contentCondition,
                      'levels',
                    );

                    delete oneConditionGroup[groupName].lookup;
                    break;

                  case 'ageCharacteristic':
                    // valueKey = 'age';
                    oneConditionGroup[
                      groupName
                    ] = parseSegmentDataRangeCondition(contentCondition, 'age');
                    break;
                  case 'signUpAnniversaryCharacteristic':
                    // valueKey = 'numberOfYears';
                    oneConditionGroup[
                      groupName
                    ] = parseSegmentDataRangeCondition(
                      contentCondition,
                      'numberOfYears',
                    );
                    break;
                  case 'totalSpendingCharacteristic':
                  case 'averageTransactionValueCharacteristic':
                    // valueKey = 'dollars';
                    oneConditionGroup[
                      groupName
                    ] = parseSegmentDataRangeCondition(
                      contentCondition,
                      'dollars',
                    );
                    break;
                  case 'numberOfTransactionsCharacteristic':
                  case 'signUpMemberCharacteristic':
                  case 'lastLoginCharacteristic':
                    const period = parseSegmentPeriod(contentCondition);

                    if (group === 'numberOfTransactionsCharacteristic') {
                      if (
                        (parseInt(contentCondition.number) === 0 ||
                          contentCondition.number > 0) &&
                        period
                      )
                        oneConditionGroup[groupName] = {
                          period,
                          quantity: contentCondition.number,
                        };
                    } else if (period) {
                      oneConditionGroup[groupName] = { period };
                    }
                    break;
                  case 'earningCampaignCharacteristic':
                  case 'couponCampaignCharacteristic':
                  case 'viewCampaignCharacteristic':
                    let campaignCondition = {};
                    if (
                      contentCondition.viewType &&
                      contentCondition.viewValue?.label
                    ) {
                      campaignCondition = {
                        catalogType: contentCondition.viewType
                          .toUpperCase()
                          .replace(/ /g, '_'),
                      };
                      if (contentCondition.viewType === 'Campaign') {
                        campaignCondition.campaign =
                          contentCondition.viewValue.value.pk;
                      } else {
                        campaignCondition.campaignCategory =
                          contentCondition.viewValue.value.pk;
                      }
                    }

                    if (group === 'viewCampaignCharacteristic')
                      campaignCondition.viewCampaignType =
                        contentCondition.viewedTimeType;
                    if (
                      group === 'viewCampaignCharacteristic' &&
                      contentCondition.viewedTimeType === 'STAY_TIME'
                    ) {
                      if (
                        contentCondition.periodRange &&
                        (contentCondition.number > 0 ||
                          parseInt(contentCondition.number) === 0)
                      ) {
                        oneConditionGroup[groupName] = {
                          ...campaignCondition,
                          stayPeriodType: contentCondition.periodRange.toUpperCase(),
                          stayPeriod: contentCondition.number,
                        };
                      }
                    } else {
                      const period = parseSegmentPeriod(contentCondition);
                      if (
                        contentCondition.number > 0 ||
                        parseInt(contentCondition.number) === 0 ||
                        ((contentCondition.viewTimes > 0 ||
                          parseInt(contentCondition.viewTimes) === 0) &&
                          period)
                      )
                        oneConditionGroup[groupName] = {
                          ...campaignCondition,
                          period: period,
                          ...([
                            'earningCampaignCharacteristic',
                            'couponCampaignCharacteristic',
                          ].includes(group)
                            ? {
                                numberOfParticipateTimes:
                                  contentCondition.number ||
                                  contentCondition.viewTimes,
                              }
                            : {
                                numberOfViewCampaignTimes:
                                  contentCondition.number,
                              }),
                        };
                    }

                    break;
                  default:
                    break;
                }

                if (oneConditionGroup[groupName])
                  oneConditionGroup[groupName]['displayOrder'] = groupIndex;
                else delete oneConditionGroup[groupName];
                // console.log('@@494-1-1: ', group, oneConditionGroup[group]);
                if (!oneConditionGroup[group]) delete oneConditionGroup[group];
              },
            );

            const conditionKeys = Object.keys(oneConditionGroup);
            console.log('@@oneConditionGroup: ', oneConditionGroup);
            if (conditionKeys.length > 1) {
              oneConditionGroup = {
                ...oneConditionGroup,
                displayOrder: key,
                id: segment.conditionGroup[key].pk,
              };

              Object.entries(originalCondition).map(([key, value], index) => {
                if (conditionKeys.includes(key)) {
                  return;
                }

                switch (key) {
                  case 'isSignupReferral':
                    if (!conditionKeys.includes('signUpReferralCharacteristic'))
                      oneConditionGroup.signUpReferralCharacteristic = null;
                    break;
                  case 'lastPurchase':
                    if (
                      !conditionKeys.includes('hasRecentPurchaseCharacteristic')
                    )
                      oneConditionGroup.hasRecentPurchaseCharacteristic = null;
                    break;
                  case 'numberOfLogin':
                    if (!conditionKeys.includes('loginTimeCharacteristic'))
                      oneConditionGroup.loginTimeCharacteristic = null;
                    break;
                  case 'pk':
                  case 'id':
                    break;
                  default:
                    oneConditionGroup[key] = null;
                    break;
                }
              });

              conditionGroups.push(oneConditionGroup);
            } else {
              if (
                parseInt(segment.conditionGroup[key].pk) === 0 ||
                segment.conditionGroup[key].pk > 0
              )
                conditionGroupsToDelete.push(segment.conditionGroup[key].pk);
            }
          }
        });

        const data = {
          name: segment.generalName,
          conditionGroups: conditionGroups,
        };
        console.log('@@494: ', data);

        if (segment.pk) {
          data.id = segment.pk;
          data.conditionGroupsToDelete = conditionGroupsToDelete;
        }

        const serviceArgs = [createOrUpdateSegment, data];
        function* onSuccess(data) {
          console.log('@@115: ', data);

          if (
            ('createSegment' in data && data.createSegment.errors) ||
            ('updateSegment' in data && data.updateSegment.errors)
          ) {
            yield put({
              type: 'updateState',
              payload: { saved: SavedStatus.savedWithFail, formChanged: false },
            });
          } else {
            yield put({
              type: 'updateState',
              payload: {
                saved: SavedStatus.savedWithSuccess,
                formChanged: false,
              },
            });

            yield put({ type: 'removeFromCookie' });
          }
        }
        function* onFailed(data) {
          console.log('@@122: ', data);
          yield put({
            type: 'updateState',
            payload: { saved: SavedStatus.savedWithFail, formChanged: false },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
  },
};
