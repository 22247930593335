import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import i18n from '../../../I18n.js';
import CustomMarkDownArea, { ReactHookFormMarkDown } from '../../base/CustomMarkDownArea';
import { useCompare } from '../../../utils';
import { useFormContext } from 'react-hook-form';
import { InputFieldControl2 } from '../../base/InputFieldControl.js';
import SelectMultipleStore from '../../base/SelectMultipleStore.js';
import CustomTips from '../../base/CustomTips.js';
import { SectionTopTitle } from '../../base/CommonComponent.js';
import SpecificCustomSwitchButton from '../../../containers/merchants/stores/SpecificCustomSwitchButton.js';
import BaseMultipleSelectorV2 from '../../base/BaseMultipleSelectorV2.js';
import { LanguageConfig } from '../../../config/CustomEnums.js';
import SelectMultipleStoreV2 from '../../base/SelectMultipleStoreV2.js';

function ShowInDetailPageSection({
  language,
  mallList,
  focusChange = () => {},
}) {
  const dispatch = useDispatch()
  const { watch, setValue, formState, control, getValues } = useFormContext()
  const errors = formState.errors
  const watchShowInMall = watch('isShowInMall')
  const watchSelectedMall = watch('selectedMall') || []
  const watchSelectMallStores = watch('selectMallStores')
  const watchSelectFreshMarketStores = watch('selectFreshMarketStores')

  useEffect(() => {
    dispatch({
      type: 'mall/getList',
      payload: {
        isAll: true,
        isSimpleFilter: true,
        isSelectorLoad: true,
        sort: 'name',
      }
    });
  }, [dispatch])

  return (
    <>
      <SectionTopTitle title={'Show in mall detail page (and related carpark and fresh market)'} />
      <div style={{marginTop: '8px'}}>
        <SpecificCustomSwitchButton
          show={true}
          checked={watchShowInMall}
          onChange={(value) => {
            setValue("isShowInMall", value, { shouldDirty: true })
          }}
        />
      </div>
      { watchShowInMall ?  
        <BaseMultipleSelectorV2
        title={'Select mall'}
        disabled={language !== LanguageConfig.english}
        namespace="mall"
        data={{
          sourceData: mallList,
          targetData: watchSelectedMall,
          targetChange: (selectedValue) => {
            setValue("selectedMall", selectedValue, { shouldDirty: true })
          }
        }}
        hideTopSpace={true}
      /> : null
      }
      
      <SelectMultipleStoreV2
        title={'Show in mall store detail page'}
        value={watchSelectMallStores}
        onChange={(value) => {
          console.log(value)
          setValue("selectMallStores", value, {shouldDirty: true})
        }}
        disabled={language !== LanguageConfig.english}
        onFocus={() => focusChange('selectedStore')}
      />
      <SelectMultipleStoreV2
        title={'Show in fresh market store detail page'}
        value={watchSelectFreshMarketStores}
        onChange={(value) => {
          console.log(value)
          setValue("selectFreshMarketStores", value, {shouldDirty: true})
        }}
        disabled={language !== LanguageConfig.english}
        showLineBrank={true}
        filterByMall={false}
        onFocus={() => focusChange('selectedStore')}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  mallList: state.mall.allList || [],
})

export default connect(mapPropsToState)(ShowInDetailPageSection);
