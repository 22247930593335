import React from 'react';
import _ from 'lodash';
import './ErrorFieldMessage.scss';

function ErrorFieldMessage(props) {
  return props.error ? (
    <label id={props.id} className={`error-field-message-style ${props.extra}`}>
      {props.message}
    </label>
  ) : null;
}

export const hasError = (errors, id) => {
  const error = _.get(errors, id);
  return error && error.message;
};

export const errorMessage = (errors, id) => {
  const error = _.get(errors, id);
  return error?.message;
};

export const getFirstError = (errors, errorKeyOrder = []) => {
  let error = null;
  let key = null;
  errorKeyOrder.some((errorKey) => {
    const errorForKey = _.get(errors, errorKey);
    console.log(errorForKey, errorKey);
    if (errorForKey) {
      error = errorForKey;
      key = errorKey;
      return true;
    }
    return false;
  });
  return {
    error,
    key,
  };
};

export const ReactHookFormErrorMessage = ({ errors, id, extra }) => {
  const error = _.get(errors, id);
  return error && error.message ? (
    <label id={id} className={`error-field-message-style ${extra}`}>
      {error.message}
    </label>
  ) : null;
};

export const ReactHookFormErrorFieldMessage = ({error, id, extra}) => {
  return error && error.message ? (
    <label id={id} className={`error-field-message-style ${extra}`}>
      {error.message}
    </label>
  ) : null;
}

export default ErrorFieldMessage;
