import React, { useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import i18n from '../../I18n';
import { LanguageConfig } from '../../config/CustomEnums';
import { hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import CustomTitleWithInput from '../base/CustomTitleWithInput';
import CustomEditor from '../base/CustomEditor';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import HtmlEditor from '../base/HtmlEditor';

const WebviewContentSection = ({ language }) => {
  const isEnglish = language === LanguageConfig.english;
  const { watch, setValue, control, formState, getValues } = useFormContext();
  const { errors } = formState;
  const watchName = watch('name');
  const initHtmlDescription = getValues(`translations.${language}.description`);

  return (
    <>
      <div className="create-section-title">
        {i18n.t('general', { locale: language })}
      </div>
      {isEnglish ? (
        <>
          <Controller
            control={control}
            name={'name'}
            rules={{ 
              required: 'Please provide a name',
            }}
            render={() => (
              <CustomTitleWithInput
                title={i18n.t('name', { locale: 'en' })}
                defaultValue={watchName}
                error={{
                  error: hasError(errors, 'name'),
                }}
                setValue={(value) => {
                  setValue('name', value, { shouldDirty: true });
                }}
              />
            )}
          />
          <ReactHookFormErrorMessage errors={errors} id={'name'} />
        </>
      ) : null}

      <Controller
        control={control}
        name={`translations.${language}.description`}
        rules={isEnglish ? { required: 'Please provide description' } : {}}
        render={({field, fieldState: { error }}) => (
          <>
            <CustomTitleLabel title={i18n.t(isEnglish ? 'description' : 'short_description', { locale: language })} />
            <HtmlEditor
              initialValue={initHtmlDescription}
              init={{
                height: 1000,
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              }}
              onEditorChange={(value, editor) => {
                field.onChange(value);
              }}
              className={error ? 'error-field' : ''}
            />
          </>
        )}
      />
      <ReactHookFormErrorMessage errors={errors} id={`translations.${language}.description`} />
    </>
  );
};

export default WebviewContentSection;
