import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { createAction } from '../../../utils';
import {
  saveDataToSessionStorage,
  removeDataFromSessionStorage,
} from '../../../models/MallModel';
import { SavedStatus } from '../../../config/CustomEnums';
import BaseForm from '../../../components/base/v2/BaseForm';
import Loading from '../../../components/base/Loading';
import CreateMallStepOne from './CreateMallStepOne';

function CreateMall({
  mall,
  formHasSubmitted,
  saved,
  hasUpdatedDefaultValues,
}) {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = params;

  const stepSet = [<CreateMallStepOne />];
  const goToMallList = () => history.push({ pathname: '/malls' });

  useEffect(() => {
    if (id) {
      dispatch(createAction('mall/getOneDetail')({ id }));
    } else {
      dispatch({ type: 'mall/loadFromCookie' });
    }
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch({ type: 'mall/clearData' });
    };
  }, []);

  useEffect(() => {
    if (id && saved === SavedStatus.savedWithSuccess) {
      goToMallList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saved]);

  if (!mall?.id && id) {
    return <Loading customClass={'common-full-height '} />;
  }

  const getButtons = () => {
    return [
      {
        text: 'Back to mall list',
        action: () => {
          history.push({
            pathname: '/malls',
          });
        },
      },
    ];
  };

  return (
    <BaseForm
      defaultValues={mall}
      formHasSubmitted={formHasSubmitted}
      hasUpdatedDefaultValues={hasUpdatedDefaultValues}
      tempSave={(save, getValues) => {
        if (save) {
          saveDataToSessionStorage(getValues());
        } else {
          removeDataFromSessionStorage();
        }
      }}
      showFinishPop={saved === SavedStatus.savedWithSuccess}
      continueCreateConfig={{
        onConfirm: () => {
          history.push(history.location.state?.from);
        },
        onCancel: goToMallList,
        onClose: goToMallList,
      }}
      nextStepConfig={{
        title: `Successfully ${id ? 'Updated' : 'Created'}!`,
        description: `Mall is successfully ${
          id ? 'updated' : 'created'
        }.`,
        steps: null,
        buttons: getButtons(),
      }}
      content={stepSet}
      currentStep={0}
      breadcrumb={<CustomBreadcrumb name={id ? mall.name : ''} />}
      caution={{
        detail: '',
        title: id ? mall.name : 'Create Mall',
      }}
    />
  );
}

const mapPropsToState = (state) => ({
  mall: state.mall.detail,
  formHasSubmitted: state.mall.formHasSubmitted,
  saved: state.mall.saved,
  hasUpdatedDefaultValues: state.mall.hasUpdatedDefaultValues,
})

export default connect(mapPropsToState)(CreateMall);
