import {
  getBrands,
  deleteBrand,
  createBrand,
  getBrand,
  updateBrand,
  getSimpleBrandList,
} from '../services/BrandAPIHelper';
import {
  convertCursorToNumber,
  convertNumberToCursor,
  saveToSessionStorage,
  getObjectFromSessionStorage,
  removeFromSessionStorage,
  getFileNameFromUrl,
} from '../utils';
import { apiWithResponseHandle, loading } from './LoadingUtil';
import { defaultStep } from './StepBarUtil';
import {
  LanguageConfig,
  APIStatus,
  SESSION_KEYS,
} from '../config/CustomEnums';
import { push } from 'react-burger-menu';

export const sessionDataKey = {
  objectKey: SESSION_KEYS.CREATE_BRAND_SESSION_KEY,
  stepEndKey: SESSION_KEYS.CREATE_BRAND_STEP_END_SESSION_KEY,
  origionalData: SESSION_KEYS.CREATE_BRAND_ORIGINAL_DATA_SESSION_KEY,
};

const getInitialState = () => ({
  brandList: [],
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk', orderField: 'pk' },
    { displayName: 'Name', fieldName: 'name', orderField: 'name' },
    { displayName: 'Cover photo', fieldName: 'coverPhoto' },
    { displayName: 'Related Store', fieldName: 'relatedStoreNames' },
  ],
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  currentLastCursor: '',
  currentPage: 0,
  totalPage: 0,
  totalCount: 0,
  checkedList: [],
  currentPageBrandList: [],
  selectedBrand: {},
  loadingStatus: APIStatus.none,
  errorFields: [],
  stepConfig: defaultStep([]),
  currentStep: 0,
  formHasSubmitted: false,
  hasUpdatedDefaultValues: false,
});

const parseTranslations = (brand) => {
  const translation = {};
  translation[LanguageConfig.english] = {
    name: brand.name,
    description: brand.description,
  }
  brand.translations.edges.forEach((item) => {
    let language = item.node.language;
    translation[language] = {
      id: item.node.pk,
      language: item.node.language,
      name: item.node.name,
      description: item.node.description,
    };
  });
  return translation;
};

const getStoreTranslations = (translationList) => {
  const translation = {};
  if (!translationList || translationList.length === 0) {
    return {};
  }
  translationList.forEach((item) => {
    const language = item.node.language;
    translation[language] = {
      storeName: item.node.name,
      storeAddress: item.node.address,
      storeDescription: item.node.description,
    };
  });
  return translation;
};

export const parseStore = (storeInfo) => {
  const store = storeInfo.node;
  return {
    ...store,
    storeID: store.id,
    storePK: store.pk,
    storeName: store.name,
    storeAddress: store.address,
    storeDescription: store.description,
    storeTranslations: getStoreTranslations(store.translations?.edges),
  };
};

const getPhotos = (
  photoList,
) => {
  return photoList.filter((photo) => photo)
}

export const parseBrand = (brand) => {
  let relatedStoreNameList = [];
  if (brand.stores) {
    relatedStoreNameList = brand.stores?.edges?.map((store) => store.node.name);
  }
  return {
    id: brand.id,
    pk: brand.pk,
    name: brand.name,
    description: brand.description,
    coverPhoto: brand.icon,
    brandPhotos: getPhotos([brand.brandCoverPhoto, brand.brandDetailPhoto1, brand.brandDetailPhoto2, brand.brandDetailPhoto3, brand.brandDetailPhoto4]),
    productPhotos: getPhotos([brand.productCoverPhoto, brand.productDetailPhoto1, brand.productDetailPhoto2, brand.productDetailPhoto3, brand.productDetailPhoto4]),
    translations: parseTranslations(brand),
    brandStores: brand.stores?.edges.map((store) => parseStore(store)),
    relatedStoreNames: relatedStoreNameList.length > 0
      ? relatedStoreNameList.toString()
      : '',
  };
};

export const parseBrandInputBody = (values) => {
  const coverPhoto = values.coverPhoto.type ? values.coverPhoto.value : values.coverPhoto
  const coverPhotoName = getFileNameFromUrl(coverPhoto);
  const translations = [];
  const translationArray = Object.keys(values.translations || {});
  translationArray.forEach((language) => {
    if (language === LanguageConfig.english) {
      return;
    }
    translations.push({
      ...values.translations[language],
      language: language
    });
  });
  let inputBody = {
    name: values.translations?.[LanguageConfig.english]?.name,
    description: values.translations?.[LanguageConfig.english]?.description,
    icon: coverPhotoName,
    translations: translations,
  };
  return inputBody;
};

export default {
  namespace: 'brand',
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },

    updateBrandList(state, { payload }) {
      const { newBrandList, page } = payload;
      return {
        ...state,
        brandList:
          page > 1 ? [...state.brandList, ...newBrandList] : newBrandList,
      };
    },

    loadBrandFromCookie(state, { payload }) {
      const brand = getObjectFromSessionStorage(sessionDataKey.objectKey);
      if (!brand) {
        return {
          ...state,
        };
      }
      saveToSessionStorage(sessionDataKey.origionalData, brand);
      saveToSessionStorage(sessionDataKey.objectKey, brand);
      return {
        ...state,
        selectedBrand: brand,
        hasUpdatedDefaultValues: true,
      };
    },

    saveOrRemoveBrandFromCookie(state, { payload }) {
      if (payload.save) {
        if (payload.values) {
          saveToSessionStorage(sessionDataKey.objectKey, payload.values);
        }
      } else {
        removeFromSessionStorage(sessionDataKey.objectKey);
        removeFromSessionStorage(sessionDataKey.origionalData);
      }
      saveToSessionStorage(sessionDataKey.stepEndKey, true);
      return {
        ...state,
      };
    },

    cleanSelectedBrand(state, action) {
      return {
        ...state,
        selectedBrand: {},
      };
    },
  },
  effects: {
    *setFieldToSession({ payload }, { select }) {
      const oldBrand = yield select((state) => state.brand.selectedBrand);
      const brand = { ...oldBrand, ...payload };

      saveToSessionStorage(sessionDataKey.objectKey, brand);
    },

    getCurrentPageBrands: [
      function* ({ payload }, { put, all }) {
        const { page, searchKey, rank, moreSearch, isAll } = payload;
        let afterCursor = '';
        if (page > 1) {
          afterCursor = convertNumberToCursor((page - 1) * 20 - 1);
        }
        const serviceArgs = [
          getBrands,
          afterCursor,
          rank,
          searchKey,
          isAll || false,
          moreSearch,
        ];
        function* onSuccess(data) {
          const brandData = data.brands.edges;
          const pageInfo = data.brands.pageInfo;
          const totalCount = data.brands.totalCount;
          const currentLastCursor = pageInfo.endCursor;
          const brandList = brandData.map((item) => parseBrand(item.node));
          yield all([
            put({
              type: 'updateState',
              payload: {
                currentPageBrandList: brandList,
                pageInfo: {
                  startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1 || 0,
                  endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1 || 0,
                },
                currentLastCursor,
                totalCount,
                totalPage: Math.ceil(totalCount / 20),
              },
            }),
            put({
              type: 'updateBrandList',
              payload: {
                newBrandList: brandList,
                page: page,
              },
            }),
          ]);
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getBrand: [
      function* ({ payload }, { put }) {
        yield put({
          type: 'updateState',
          payload: {
            loadingStatus: APIStatus.calling
          },
        });
        const { brandPK } = payload;
        const brandID = btoa(`BrandNode:${brandPK}`);
        const serviceArgs = [getBrand, brandID];

        function* onSuccess(data) {
          const brandData = data.brand;
          const brand = parseBrand(brandData);
          yield put({
            type: 'updateState',
            payload: {
              selectedBrand: brand,
              loadingStatus: APIStatus.success,
              hasUpdatedDefaultValues: true,
            }
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    // delete: [
    //   function* ({ payload }, { put, select }) {
    //     const { checkedList } = yield select((state) => ({
    //       checkedList: state.brand.checkedList,
    //     }));

    //     let ids = [];
    //     checkedList.forEach((item) => {
    //       ids.push(item.pk);
    //     });

    //     const serviceArgs = [
    //       deleteBrand,
    //       {
    //         ids: ids,
    //       },
    //     ];
    //     function* onSuccess() {
    //       const afterActions = payload.afterAction || (() => {});
    //       yield afterActions();
    //     }
    //     yield loading(serviceArgs, onSuccess);
    //   },
    //   { type: 'takeLatest' },
    // ],
    // createBrand: [
    //   function* ({ payload }, { select, put }) {
    //     const values = payload.values;
    //     const inputBody = parseBrandInputBody(values);
    //     const serviceArgs = [createBrand, inputBody];
    //     saveToSessionStorage(sessionDataKey.stepEndKey, true);
    //     function* onSuccess() {
    //       removeFromSessionStorage(sessionDataKey.objectKey);
    //       yield put({
    //         type: 'updateState',
    //         payload: {
    //           formHasSubmitted: true,
    //           selectedBrand: {},
    //         }
    //       });
    //       const afterActions = payload.afterActions || (() => {});
    //       yield afterActions();
    //     }
    //     yield loading(serviceArgs, onSuccess);
    //   },
    //   { type: 'takeLatest' },
    // ],
    // duplicate: [
    //   function* ({ payload }, { put }) {
    //     const translations = {};
    //     const translationArray = Object.keys(payload.data.translations || {});
    //     translationArray.forEach((language) => {
    //       const item = payload.data.translations[language];
    //       translations[language] = {
    //         name: `Copy of ${item.name}`,
    //         description: item.description,
    //       };
    //     });
    //     const values = {
    //       coverPhoto: payload.data.coverPhoto,
    //       translations: translations,
    //     };
    //     const afterActions = payload.afterAction || (() => {});
    //     yield put({
    //       type: 'createBrand',
    //       payload: {
    //         afterActions,
    //         values: values,
    //       }
    //     });
    //   },
    // ],
    // updateBrand: [
    //   function* ({ payload }, { select, put }) {
    //     const brand = yield select((state) => state.brand.selectedBrand);
    //     const values = payload.values;
    //     const inputBody = parseBrandInputBody(values);
    //     inputBody.id = brand.pk;
    //     const serviceArgs = [updateBrand, inputBody];
    //     saveToSessionStorage(sessionDataKey.stepEndKey, true);
    //     function* onSuccess() {
    //       removeFromSessionStorage(sessionDataKey.objectKey);
    //       yield put({
    //         type: 'updateState',
    //         payload: {
    //           formHasSubmitted: true,
    //         },
    //       });
    //       const afterActions = payload.afterActions || (() => {});
    //       yield afterActions();
    //     }
    //     yield loading(serviceArgs, onSuccess);
    //   },
    //   { type: 'takeLatest' },
    // ],
    getSimpleBrandList: [
      function* ({ payload }, { put, all }) {
        const { page } = payload;
        let afterCursor = '';
        if (page > 1) {
          afterCursor = convertNumberToCursor((page - 1) * 20 - 1);
        }
        console.log("@401", payload)
        const serviceArgs = [
          getSimpleBrandList,
          afterCursor,
          payload
        ];
        function* onSuccess(data) {
          const brandData = data.brands.edges;
          const pageInfo = data.brands.pageInfo;
          const totalCount = data.brands.totalCount;
          const currentLastCursor = pageInfo.endCursor;
          const brandList = data?.brands?.edges.map((item) => item.node);
          yield all([
            put({
              type: 'updateState',
              payload: {
                currentPageBrandList: brandList,
                pageInfo: {
                  startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1 || 0,
                  endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1 || 0,
                },
              },
            }),
            put({
              type: 'updateBrandList',
              payload: {
                newBrandList: brandList,
                page: page,
              },
            }),
          ]);
          if (payload?.afterAction) {
            payload.afterAction(brandList);
          }
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
