import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { ArrayStringData } from '../../../utils';
import { CampaignCategoryType } from '../../../config/CustomEnums';

function Filter({ backAction = () => {} }) {
  const location = useLocation();

  const parsedSearch = queryString.parse(location.search);
  const searchStatus = parsedSearch['status'] || '';
  const searchType = parsedSearch['category_type'] || '';

  const [status, setStatus] = useState(searchStatus);
  const [type, setType] = useState(searchType);

  const content = [
    {
      title: 'Category type',
      data: [
        { pk: CampaignCategoryType.GENERAL, name: 'General' },
        { pk: CampaignCategoryType.INTEREST_CLUB, name: 'Interest club' },
        // { pk: CampaignCategoryType.FRESH_MARKET, name: 'Fresh market' },
      ],
      value: type,
      setValue: setType,
      componment: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Status',
      data: [
        { name: 'Active', pk: 'true' },
        { name: 'Inactive', pk: 'false' },
      ],
      value: status,
      setValue: setStatus,
      componment: FilterTypeEnum.singleChoiceSegment,
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;
