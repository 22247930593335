import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { ArrayStringData } from '../../../utils';

function Filter({ backAction = () => {} }) {
  const history = useHistory();
  const location = useLocation();

  const parsedSearch = queryString.parse(location.search);
  const searchAdminType = parsedSearch['admin_type'] || '';
  const searchAccountType = parsedSearch['account_type'] || '';
  const searchStatus = parsedSearch['status'] || '';

  const [adminType, setAdminType] = useState(searchAdminType);
  const [accountType, setAccountType] = useState(searchAccountType);
  const [status, setStatus] = useState(searchStatus);

  const content = [
    {
      title: 'Account Type',
      data: [
        { name: 'LINK', pk: 'false' },
        { name: 'Tenant', pk: 'true' },
      ],
      value: accountType,
      setValue: setAccountType,
      componment: FilterTypeEnum.singleChoiceSegment,
    },
    {
      title: 'Admin Type',
      data: [
        { name: 'Ordinary', pk: 'false' },
        { name: 'Super', pk: 'true' },
      ],
      value: adminType,
      setValue: setAdminType,
      componment: FilterTypeEnum.singleChoiceSegment,
    },
    {
      title: 'Status',
      data: [
        { name: 'Active', pk: 'true' },
        { name: 'Inactive', pk: 'false' },
      ],
      value: status,
      setValue: setStatus,
      componment: FilterTypeEnum.singleChoiceSegment,
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

export default Filter;
