import React from 'react';
import { Table, Image } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  LanguageConfig, MessageChannel,
} from '../../config/CustomEnums';
import { listItem, listImage } from '../../components/banners/DisplayItems';
import i18n from '../../I18n';
import { addDomainToImage } from '../../utils';


function MessageOverviewTableSection(props) {
  const message = props.message || {};
  const language = props.language || LanguageConfig.english;

  const name = message.translations?.[language]?.name;
  const content = message.translations?.[language]?.content;
  const photo = message.translations?.[language]?.photo?.value;
  const isSelectFullScreenAd = message.channels?.indexOf(MessageChannel.fullScreenAd) !== -1;

  return (
      <>
        <label className="create-section-title">{message.displayChannels?.toUpperCase()}</label>
        {listItem(i18n.t('title(optional)', { locale: language }), name)}
        {isSelectFullScreenAd ? null : listItem(i18n.t('content', { locale: language }), content)}
        { photo == null || photo === ''
          ? listItem(i18n.t(isSelectFullScreenAd ? 'image' : 'image(optional)', { locale: language }), '-')
          : listImage(i18n.t(isSelectFullScreenAd ? 'image' : 'image(optional)', { locale: language }), addDomainToImage(photo))
        }
      </>
  );
}

export default MessageOverviewTableSection;
