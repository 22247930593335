import React, { useState, forwardRef, useEffect } from 'react';
import { Image, Dropdown } from 'react-bootstrap';
import './FilterableDropdown.scss';
import deleteSelectedButton from '../../assets/images/drop_down_delete_selected.png'
import NarrowUp from '../../assets/images/drop_down_not_show_narrow.svg';
import Search from '../../assets/images/drop_down_filter.svg';
import { CheckboxItem } from './BaseFilter';
import { removeElementFromArray } from '../../utils';
export const CustomToggle = forwardRef(({ children, onClick, disableDropDown }, ref) => (
  <div
    className="filterable-toogle-area"
    style={{opacity: disableDropDown ? '60%' : '100%'}}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

export const CustomMenu = forwardRef(
  (
    {
      children,
      style,
      className,
      'aria-labelledby': labeledBy,
      options,
      filter,
      filterButtons,
      filterAction,
      isLoadMore,
      placeholder,
      disableAutoFocus,
      isMultipleSelect,
    },
    ref,
  ) => {
    const [value, setValue] = useState('');
    const [filterBtn, setFilterBtn] = useState('');
    const [filteredList, setfilteredList] = useState(
      options.map((item) => item.label),
    );
    const getFilteredList = (filter1, filter2) => {
      // const prevList = options;
      // let filterList = prevList;
      // if (filter1) {
      //   filterList = filterList.filter((item) =>
      //     item.label.toLowerCase().includes(filter1.toLowerCase()),
      //   );
      // }
      // if (filter2) {
      //   filterList = filterList.filter(
      //     (item) =>
      //       item.value.subFilterField.toLowerCase() === filter2.toLowerCase(),
      //   );
      // }
      // setfilteredList(filterList.map((item) => item.label));
      filterAction(filter1);
      isLoadMore(false);
    };
    // console.log('@@127: ', options, filteredList);
    useEffect(() => {
      setfilteredList(options.map((item) => item.label));
    }, [options]);
    useEffect(() => {
      if (filter) {
        const filter = setTimeout(() => {
          getFilteredList(value);
        }, 1000);
        return () => {
          clearTimeout(filter);
        }
      }
    }, [value]);
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        {filter ? (
          <div className="filterable-search-bar">
            <Image src={Search} className="filterable-search-bar-icon" />
            <input
              autoFocus={!disableAutoFocus}
              className="filterable-search-bar-input"
              placeholder={placeholder}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              value={value}
            />
          </div>
        ) : null}
        {filterButtons && filterButtons.length > 0 ? (
          <div className="filter-buttons-bar">
            {filterButtons.map((button) => {
              return (
                <button
                  className={`filter-btn ${
                    filterBtn === button ? 'filter-btn-selected' : ''
                  }`}
                  onClick={() => {
                    setFilterBtn(button);
                    getFilteredList(value, button);
                  }}
                >
                  {button}
                </button>
              );
            })}
          </div>
        ) : null}
        <ul className="filterable-dropdown-menu-select-area">
          {React.Children.toArray(children).filter((child) => {
            return isMultipleSelect
            ? filteredList.includes(child.props.children.props.title)
            : filteredList.includes(child.props.children);
          })}
        </ul>
      </div>
    );
  },
);

const EmptyMenu = forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');
    return null;
  },
);

function FilterableDropdown(props) {
  const needFilter = props.needFilter === undefined ? true : props.needFilter;
  const filterButtons = props.filterButtons || [];
  const placeholder = props.placeholder || "Search for...";
  const labelPlaceholder = props.labelPlaceholder || 'Please select';
  const customClass = props.customClass;
  const onSelectChange = props.selectChange || (() => {});
  const onToggle = props.onToggle || (() => {});

  const muitipleSelect = props.muitipleSelect || false;
  const toolTips = props.toolTips || false;

  // const options = props.options || [];
  const [options, setOptions] = useState(props.options);
  const [value, setValue] = useState(
    muitipleSelect
      ? props.defaultValue?.map((item) => item?.value?.pk) || []
      : props.defaultValue?.label,
  );
  const [isOpen, setIsOpen] = useState();
  const isPromptDropdown = props.isPromptDropdown || false;
  const disableAutoFocus = props.disableAutoFocus || false;
  const disableDropDown = props.disableDropDown || false;

  const filterAction = props.filterAction || (() => {});
  const loadMoreAction = props.loadMoreAction || (() => {});
  const [isLoadMore, setIsLoadMore] = useState(false);
  useEffect(() => {
    if (muitipleSelect) {
      setValue(props.defaultValue?.map((item) => item?.value?.pk));
    } else {
      setValue(props.defaultValue?.label || props.defaultValue?.value?.label);
    }
  }, [props.defaultValue]);
  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);


  return (
    <Dropdown
      show={isOpen}
      onToggle={(isOpen, e, metadata) => {
        if (disableDropDown) {
          setIsOpen(false);
        } else if (metadata.source !== 'select' || !muitipleSelect) {
          setIsOpen(isOpen);
        }
        onToggle();
      }}
      className={`${
        isPromptDropdown
          ? 'filterable-dropdown-area'
          : 'filterable-dropdown-area-prompt'
      } ${customClass}`}
      onScroll={(event) => {
        if (
          event.nativeEvent.target.scrollTop +
            event.nativeEvent.target.clientHeight ===
          event.nativeEvent.target.scrollHeight
        ) {
          // setOptions([
          //   ...options,
          //   { label: 'fake test', value: { pk: 'f01', name: 'fake test' } },
          // ]);
          // load more action
          loadMoreAction();
          setIsLoadMore(true);
        }
      }}
    >
      <Dropdown.Toggle as={CustomToggle} disableDropDown={disableDropDown}>
        <>
          <label
            className={`btn-link-text-label ${
              value ? 'btn-link-text-label-active' : ''
            }`}
          >
            {value ? `${
                  muitipleSelect
                    ? value?.length > 0
                      ? options
                          .filter((item) => value.includes(item.value?.pk))
                          .map((item) => item?.label)
                          .join(',')
                      : muitipleSelect.default
                    : value
                }` : labelPlaceholder}
          </label>
          {muitipleSelect ? null :
          <Image
            src={deleteSelectedButton}
            className="filterable-dropdown-delete-selected"
            onClick={ (e) =>{
              e.stopPropagation();
              setValue('');
              onSelectChange({value: null, label: null});
            }}
          />
          }
          
          <Image
            src={NarrowUp}
            className={`filterable-toogle-area-indicator ${
              isOpen ? 'rtoate180' : ''
            }`}
          />
        </>
      </Dropdown.Toggle>

      <Dropdown.Menu
        as={CustomMenu}
        className="filterable-dropdown-menu"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          margin: 0,
          right: 'auto',
          bottom: 'auto',
        }}
        options={options}
        filter={needFilter}
        filterButtons={filterButtons}
        filterAction={filterAction}
        isLoadMore={setIsLoadMore}
        placeholder={placeholder}
        disableAutoFocus={disableAutoFocus}
        isMultipleSelect={muitipleSelect}
      >
        {options.map((option, index) => (
          <Dropdown.Item
            key={`${index}-${option.label}`}
            className="filterable-item"
            onSelect={(eventKey) => {
              // onSelectChange(option);
              // setValue(option.label);
              // console.log('@@125: ', option);
              if (!muitipleSelect) {
                onSelectChange(option);
                setValue(option.label);
              } else {
                let newValue = [];
                let selectValue = [];
                console.log('@@268-1: ', value);
                if (value?.indexOf(option.value?.pk) >= 0) {
                  newValue = removeElementFromArray(value, option.value?.pk);
                  selectValue = props.defaultValue.filter(
                    (item) => item.value?.pk !== option.value?.pk,
                  );
                } else {
                  newValue = [...value, option.value?.pk];
                  selectValue = [...props.defaultValue, option];
                }
                console.log('@@268: ', newValue, value);
                setValue(newValue);
                // onSelectChange(
                //   options.filter((item) => newValue.includes(item.value?.pk)),
                // );
                onSelectChange(selectValue);

                console.log(
                  '@@125: ',
                  newValue,
                  selectValue,
                );
              }
            }}
            eventKey={index}
          >
            {muitipleSelect ? (
                <CheckboxItem
                  index={index}
                  title={option.label}
                  defaultValue={value?.indexOf(option.value?.pk) >= 0}
                  onChange={() => {}}
                  needNoMargin={false}
                  toolTips={toolTips}
                />
              ) : (
                option.label
              )}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default FilterableDropdown;
