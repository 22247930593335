import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { LanguageConfig } from '../../../config/CustomEnums';
import i18n from '../../../I18n';
import CustomMarkDownArea, { ReactHookFormMarkDown } from '../../base/CustomMarkDownArea';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { useHistory, useParams } from 'react-router-dom';
import BasePrompt from '../../base/prompt/BasePrompt';
import { ContinueCreate } from '../CampaignUrlConfig';
import { useFormContext, Controller } from 'react-hook-form';
import { SectionTopTitle } from '../../base/CommonComponent';
import InputFieldControl, { InputFieldControl2 } from '../../base/InputFieldControl';
import {
  hasError,
  ReactHookFormErrorMessage,
  errorMessage
} from '../../base/ErrorFieldMessage';
import BaseMultipleSelectorV2 from '../../base/BaseMultipleSelectorV2';
import './GeneralSection.scss';
import ImageUploader from '../../base/ImageUploader';
import { ApplySwitch } from '../../base/LabelWithSwitch';
import {
  CampaignErrorHandleField,
} from './CreateCampaignHandleError';
import {
  insertKeyToAllLanguages,
} from '../../../utils';

function HtmlGeneralSection({
  mallList,
  language,
  languages,
  onFocus = (() => {}),
}) {
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const history = useHistory();
  const { watch, setValue, formState, getValues } = useFormContext();
  const errors = formState.errors;

  const coverPhoto = watch(`translations.${language}.coverPhoto`) || ''
  const applyCoverPhotoToAllLanguage = watch('applyCoverPhotoToAllLanguage');
  const watchShowInMall = watch('isShowInMallFreshmarket');
  const watchSelectedMall = watch('selectedMall') || [];
  const languagesList = languages?.map((item) => item.code);

  const campaignCoverPhoto = [coverPhoto]?.filter((item) => item);
  const watchBrand = watch('selectedBrand') || [];
  // const watchName = watch(`translations.${language}.generalName`);
  const [showAddBrand, setShowAddBrand] = useState(false);

  const showSelectedItems = (title, itemList) => (
    <div>
      <div className="create-section-label create-section-label-bottom-space">
        {title}
      </div>
      {itemList && itemList?.length > 0 ? itemList.map((item) => {
        return (
          <div
            className="second-section-content"
            key={item.name}
          >
            {item.name}
          </div>
        );
      }) : '-'}
    </div>
  );
  return (
    <>
      <InputFieldControl2
        name={`translations.${language}.generalName`}
        title={i18n.t('name', { locale: language })}
        // value={watchName}
        // setValue={(value) => {
        //   setValue(`translations.${language}.generalName`, value, { shouldDirty: true });
        // }}
        onFocus={() => {
          onFocus('name');
        }}
      />
      <label className="create-section-label create-section-label-bottom-space">
        {i18n.t('cover_photo', { locale: language })}
      </label>
      <label className="create-campaign-cover-photo-reminder">
        {i18n.t('cover_photo_suggest', { locale: language })}
      </label>
      <ImageUploader
        minWidth={900}
        minHeight={600}
        disabled={language !== LanguageConfig.english && applyCoverPhotoToAllLanguage}
        images={campaignCoverPhoto}
        imageLinkUrl={{
          display: true,
          title: i18n.t('cover_photo_links_url', { locale: language }),
          onFocus: () => onFocus('coverPhoto')
        }}
        onImageStateChange={(newState) => {
          const applyLanguages = applyCoverPhotoToAllLanguage
            ? languagesList
            : [language];
          applyLanguages.forEach((item) => {
            setValue(`translations.${item}.coverPhoto`, newState[0], {
              shouldDirty: true,
            });
          });
        }}
        aspect={3 / 2}
        maxImageNum={1}
        uploadImageClicked={() => onFocus('coverPhoto')}
        language={language}
        photoSectionTitle={'cover_photo'}
        errorMessage={errorMessage(
          errors,
          `translations.${language}.coverPhoto`,
        )}
      />
      <span id={`${CampaignErrorHandleField.coverPhoto.name}-${language}`} />
      {/* <ReactHookFormErrorMessage errors={errors} id={`translations.${language}.coverPhoto`} /> */}
      <ApplySwitch
        disabled={
          false
        }
        show={language === LanguageConfig.english}
        checked={applyCoverPhotoToAllLanguage}
        onChange={(value) => {
          console.log("@267", value)
          setValue('applyCoverPhotoToAllLanguage', value, {
            shouldDirty: true,
          });
          if (value) {
            console.log("@272")
            insertKeyToAllLanguages({
              languages,
              key: 'coverPhoto',
              value: coverPhoto,
              setValue,
              getValues,
            });
          }
        }}
      />
      <div style={{marginTop: '20px'}}>
      <ApplySwitch
        disabled={language === LanguageConfig.english ? false : true}
        show={language === LanguageConfig.english}
        checked={watchShowInMall}
        title={'Show in Mall / FM detail page'}
        onChange={(value) => {
          setValue('isShowInMallFreshmarket', value, {shouldDirty: true});
        }}
      />
      {!watchShowInMall ? null : language === LanguageConfig.english ? (
        <div>
          <BaseMultipleSelectorV2
            title={'Applicable Mall / FM'}
            hideTopSpace={true}
            size={["shorter"]}
            onFocusFunc={() => {
              onFocus('selectedMall');
            }}
            namespace="mallModel"
            data={{
              sourceData: mallList,
              targetData: watchSelectedMall,
              targetChange: (value) => {
                setValue('selectedMall', value, {
                  shouldDirty: true,
                });
              },
            }}
            error={{
              error: hasError(errors, 'selectedMall'),
            }}
          />
          <ReactHookFormErrorMessage errors={errors} id='selectedMall' />
        </div>
      ) : showSelectedItems("Applicable Mall / FM", watchSelectedMall)}
    </div>
    </>
  );
}

const mapPropsToState = (state) => ({
  languages: state.language.allList,
  mallList : state.testApis.notPagedAllList || []
})

export default connect(mapPropsToState)(HtmlGeneralSection);
