import GeneralMessageCampaign from '../../../assets/images/campaign/general_campaign_icon.png';
import CouponCampaign from '../../../assets/images/campaign/coupon_campaign_icon.png';
import EarningCampaign from '../../../assets/images/campaign/earning_campaign_icon.png';
import HtmlCampaign from '../../../assets/images/campaign/html_campaign_icon.png';
import StampCampaign from '../../../assets/images/campaign/stamp_campaign_icon.png';
import MissionCampaign from '../../../assets/images/campaign/mission_campaign_icon.png';
import FortuneBagCampaign from '../../../assets/images/campaign/fortune_bag_campaign_icon.png';

import { CampaignType, CampaignDisplayIn, MissionCampaignMode, MissionCampaignParticipant, MissionCampaignParticipantReward, CampaignPromotionType } from '../../../config/CustomEnums';

const couponCampaignPromptConfig = {
  title: 'Go to create a Coupon Set?',
  message: `You will leave the campaign creation process. After you create a coupon set, you can come back to create a coupon campaign.`,
  button: 'Go to Create Coupon Set',
};

const earningCampaignPromptConfig = {
  title: 'Go to create a earning rule Set?',
  message: `You will leave the campaign creation process. After you create a earning set, you can come back to create a earning campaign.`,
  button: 'Go to Create Earning Rule Set',
};

export const CampaignTypesConfig = [
  {
    id: CampaignType.generalMessageCampaign,
    title: 'General Message',
    image: GeneralMessageCampaign,
    description: 'Present contents to customers in native format',
  },
  {
    id: CampaignType.htmlCampaign,
    title: 'HTML',
    image: HtmlCampaign,
    description: 'Present contents to customers in webview format',
  },
  {
    id: CampaignType.couponsCampaign,
    title: 'Coupon',
    image: CouponCampaign,
    description:
      'Enable customers to acquire coupons',
  },
  {
    id: CampaignType.earningRulesCampaign,
    title: 'Earning',
    image: EarningCampaign,
    description:
      'Issue rewards to customers once they have completed specific activities',
  },
  {
    id: CampaignType.estampCampaign,
    title: 'Stamp campaign',
    image: StampCampaign,
    description:
      'Issue stamps to customers once they have completed specific activities. Customer could use the stamps to exchange for other rewards.',
  },
  {
    id: CampaignType.emissionCampaign,
    title: 'Mission campaign',
    image: MissionCampaign,
    description:
      'Issue badges(optional) to customers once they have completed specific activities. Support participant in team or individual.',
  },
  {
    id: CampaignType.fortuneBag,
    title: 'Fortune bag',
    image: FortuneBagCampaign,
    description:
      'Deliver multiple coupons to customer',
  },
];

export const MissionCampaignModeConfig = [
  {
    id: MissionCampaignMode.fixedRewards,
    title: 'Badge mode',
    descriptionList: [
      {title: 'Customer earn badges upon completing the required activities;'},
      {title: 'A fixed reward path will be shown in frontend;'},
      {title: 'Upon accumulated a certain amount of badges, customer is able to acquire the reward.'},]
  },
  {
    id: MissionCampaignMode.dynamicRewards,
    title: 'Direct rewards mode',
    descriptionList: [
      {title: 'Customer earn coupon rewards directly upon completing the required activities;'},
      {title: 'NO badges involed;'},
      {title: 'NO reward path shown in frontend.'},]
  },
];

export const DisplayInConfig = [
  {
    id: CampaignDisplayIn.eCoupon,
    title: 'E-Coupon',
    description: 'For E-coupon list page'
  },
  {
    id: CampaignDisplayIn.stamp,
    title: 'Stamp',
    description: 'For Stamp detail page'
  },
  {
    id: CampaignDisplayIn.mission,
    title: 'Mission',
    description: 'For Mission detail page'
  },
  {
    id: CampaignDisplayIn.interestClub,
    title: 'Interest club',
    description: 'For Interest club list page'
  },
  // {
  //   id: CampaignDisplayIn.freshMarketCommunity,
  //   title: 'Fresh market community',
  //   description: 'For Fresh market community list page'
  // },
  {
    id: CampaignDisplayIn.notListing,
    title: 'Not listing',
    description: 'For banner, Full-screen ad, message, mall detail page or store detail page.'
  },
]

export const ParticipantConfig = [
  {
    label: 'Individual',
    value: MissionCampaignParticipant.individual,
  },
  {
    label: 'Team',
    value: MissionCampaignParticipant.team,
  },
]

export const ParticipantRewardConfig = [
  {
    label: 'Leader',
    value: MissionCampaignParticipantReward.leader,
  },
  {
    label: 'Leader and Team member',
    value: MissionCampaignParticipantReward.leaderAndTeamMember,
  },
]

// export const CampaignPromotionTypeConfig = [
//   {
//     label: 'None',
//     value: CampaignPromotionType.none,
//   },
//   {
//     label: 'Mall',
//     value: CampaignPromotionType.mall,
//   },
//   {
//     label: 'Fresh market',
//     value: CampaignPromotionType.freshMarket,
//   },
//   {
//     label: 'Car park',
//     value: CampaignPromotionType.carPark,
//   },
// ]

export const getCampaignTypeSelectPromptConfig = (type) => {
  return type === CampaignType.couponsCampaign || CampaignType.fortuneBag
    ? couponCampaignPromptConfig
    : earningCampaignPromptConfig;
};

export const getCampaignTypeTitle = (type) => {
  switch (type) {
    case CampaignType.generalMessageCampaign:
      return CampaignTypesConfig[0].title
    case CampaignType.earningRulesCampaign:
      return CampaignTypesConfig[3].title
    case CampaignType.couponsCampaign:
      return CampaignTypesConfig[2].title
    case CampaignType.fortuneBag:
      return CampaignTypesConfig[6].title
    case CampaignType.htmlCampaign:
      return CampaignTypesConfig[1].title
    case CampaignType.estampCampaign:
      return CampaignTypesConfig[4].title
    case CampaignType.emissionCampaign:
      return CampaignTypesConfig[5].title
    default:
      return '-'
  }
}

export const getCampaignTypeConfig = (displayIn) => {
  switch (displayIn) {
    case CampaignDisplayIn.eCoupon:
      // return [CampaignTypesConfig[0], CampaignTypesConfig[1], CampaignTypesConfig[2], CampaignTypesConfig[3]]
      return [CampaignTypesConfig[2], CampaignTypesConfig[6]]
    case CampaignDisplayIn.stamp:
      return [CampaignTypesConfig[2]]
    case CampaignDisplayIn.mission:
      return [CampaignTypesConfig[3]]
    case CampaignDisplayIn.interestClub:
    case CampaignDisplayIn.freshMarketCommunity:
      return [CampaignTypesConfig[0], CampaignTypesConfig[1]]
    case CampaignDisplayIn.notListing:
      // return [CampaignTypesConfig[0], CampaignTypesConfig[1], CampaignTypesConfig[2]]
      return [CampaignTypesConfig[0], CampaignTypesConfig[2], CampaignTypesConfig[3], CampaignTypesConfig[6]]
    case CampaignDisplayIn.estamp:
      // return [CampaignTypesConfig[4], CampaignTypesConfig[0], CampaignTypesConfig[1]]
      return [CampaignTypesConfig[4]]
    case CampaignDisplayIn.emission:
      // return [CampaignTypesConfig[5], CampaignTypesConfig[0], CampaignTypesConfig[1], CampaignTypesConfig[3]]
      return [CampaignTypesConfig[5], CampaignTypesConfig[1]]
    default:
      return []
  }
}

export const getCampaignDisplayInTitle = (displayIn) => {
  switch (displayIn) {
    case CampaignDisplayIn.stamp:
      return DisplayInConfig[1].title
    case CampaignDisplayIn.mission:
      return DisplayInConfig[2].title
    case CampaignDisplayIn.interestClub:
      return DisplayInConfig[3].title
    // case CampaignDisplayIn.freshMarketCommunity:
    //   return DisplayInConfig[4].title
    case CampaignDisplayIn.notListing:
      return DisplayInConfig[4].title
    case CampaignDisplayIn.interestClub:
    case CampaignDisplayIn.freshMarketCommunity:
    case CampaignDisplayIn.estamp:
    case CampaignDisplayIn.emission:
      return ''
    default:
      return DisplayInConfig[0].title
  }
}

export const getParticipantTypeTitle = (type) => {
  switch (type) {
    case MissionCampaignParticipant.team:
      return ParticipantConfig[1].label
    case MissionCampaignParticipant.individual:
      return ParticipantConfig[0].label
    default:
      return '-'
  }
}

export const getParticipantRewardTypeTitle = (type) => {
  switch (type) {
    case MissionCampaignParticipantReward.leaderAndTeamMember:
      return ParticipantRewardConfig[1].label
    case MissionCampaignParticipantReward.leader:
      return ParticipantRewardConfig[0].label
    default:
      return '-'
  }
}

export const getMissionCampaignModeTypeConfig = (type) => {
  switch (type) {
    case MissionCampaignMode.fixedRewards:
      return MissionCampaignModeConfig[0].title
    case MissionCampaignMode.dynamicRewards:
      return MissionCampaignModeConfig[1].title
    default:
      return '-'
  }
}

// export const getCampaignPromotionTypeTitle = (type) => {
//   switch (type) {
//     case CampaignPromotionType.mall:
//       return CampaignPromotionTypeConfig[1].label
//     case CampaignPromotionType.freshMarket:
//       return CampaignPromotionTypeConfig[2].label
//     case CampaignPromotionType.carPark:
//       return CampaignPromotionTypeConfig[3].label
//     default:
//       return CampaignPromotionTypeConfig[0].label
//   }
// }