import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { LanguageConfig } from '../../../config/CustomEnums';
import i18n from '../../../I18n';
import CustomMarkDownArea, { ReactHookFormMarkDown } from '../../base/CustomMarkDownArea';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { useHistory, useParams } from 'react-router-dom';
import BasePrompt from '../../base/prompt/BasePrompt';
import { ContinueCreate } from '../CampaignUrlConfig';
import { useFormContext, Controller } from 'react-hook-form';
import { SectionTopTitle } from '../../base/CommonComponent';
import InputFieldControl, { InputFieldControl2 } from '../../base/InputFieldControl';
import {
  hasError,
  ReactHookFormErrorMessage,
} from '../../base/ErrorFieldMessage';
import BaseMultipleSelectorV2 from '../../base/BaseMultipleSelectorV2';
import './GeneralSection.scss';
import SelectMultipleStoreV2 from '../../base/SelectMultipleStoreV2';

function HtmlRelatedSection({
  campaignList,
  storeList,
  language,
  onFocus = (() => {}),
}) {
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const history = useHistory();
  const { watch, setValue, formState } = useFormContext();
  const errors = formState.errors;

  const watchRelatedCampaign = watch('selectedRelatedCampaign') || [];
  const watchRelatedMallStores = watch('selectRelatedMallStores') || [];
  const watchRelatedFreshMarketStores = watch('selectRelatedFreshMarketStores') || [];
  // const watchName = watch(`translations.${language}.generalName`);
  useEffect(() => {
    console.log("@38 use effect")
    dispatch({
      type: 'campaignList/getCampaignList', 
      payload: {
        isAll: true,
        isSimpleList: true,
        allDisplayList: true,
        filterTemplateCampaign: 'NOT_TEMPLATE',
        isExclusive: 'false',
        isPublished: true,
      },
    });
    // dispatch({
    //   type: 'storeModel/getAllStoreList',
    //   payload: {isAll: true}
    // })
  }, [dispatch]);

  return (
    <>
      <SectionTopTitle title={i18n.t('related', { locale: language })} />
      <div
        className="create-campaign-brand-select"
        onFocus={() => onFocus('watchRelatedCampaign')}
      >
        {language === LanguageConfig.english ? (
          <div>
            <BaseMultipleSelectorV2
              title={i18n.t('related_campaign_option', {locale: language})}
              size={["shorter"]}
              onFocusFunc={() => {
                onFocus('selectedRelatedCampaign');
              }}
              namespace="campaignModel"
              custom={{
                customItem: (item) => {
                  return `[ID:${item.pk}] ${item.name}`
                }
              }}
              data={{
                sourceData: campaignList,
                targetData: watchRelatedCampaign,
                targetChange: (value) => {
                  setValue('selectedRelatedCampaign', value, {
                    shouldDirty: true,
                  });
                },
              }}
              error={{
                error: hasError(errors, 'selectedRelatedCampaign'),
              }}
            />
            <ReactHookFormErrorMessage errors={errors} id='selectedRelatedCampaign' />
          </div>
        ) : (
          <div>
          <div className="create-section-label create-section-label-bottom-space">
            {i18n.t('related_campaign_option', {locale: language})}
          </div>
          {watchRelatedCampaign && watchRelatedCampaign?.length > 0 ? watchRelatedCampaign.map((campaign) => {
            return (
              <div
                className="second-section-content"
                key={campaign.name}
              >
                {campaign.name}
              </div>
            );
          }) : '-'}
        </div>
        )}
      </div>
      <div
        // className="create-campaign-brand-select"
        onFocus={() => onFocus('selectRelatedStore')}
      >
        {language === LanguageConfig.english ? (
          <div>
            <SelectMultipleStoreV2
              title={i18n.t('related_mall_store_option', {locale: language})}
              value={watchRelatedMallStores}
              onChange={(value) => {
                console.log(value)
                setValue("selectRelatedMallStores", value, {shouldDirty: true})
              }}
              disabled={language !== LanguageConfig.english}
              onFocus={() => onFocus('selectRelatedStore')}
            />
            <ReactHookFormErrorMessage errors={errors} id='selectRelatedMallStores' />
            <SelectMultipleStoreV2
              title={i18n.t('related_fm_store_option', {locale: language})}
              value={watchRelatedFreshMarketStores}
              onChange={(value) => {
                console.log(value)
                setValue("selectRelatedFreshMarketStores", value, {shouldDirty: true})
              }}
              disabled={language !== LanguageConfig.english}
              showLineBrank={true}
              filterByMall={false}
              onFocus={() => onFocus('selectRelatedStore')}
            />
            <ReactHookFormErrorMessage errors={errors} id='selectRelatedFreshMarketStores' />
          </div>
        ) : (
          <div>
            <div className="create-section-label create-section-label-bottom-space">
              {i18n.t('related_mall_store_option', {locale: language})}
            </div>
            {watchRelatedMallStores && watchRelatedMallStores?.length > 0 ? watchRelatedMallStores.map((store) => {
              return (
                <div
                  className="second-section-content"
                  key={store.name}
                >
                  {store.name}
                </div>
              );
            }) : '-'}
            <div className="create-section-label create-section-label-bottom-space">
              {i18n.t('related_fm_store_option', {locale: language})}
            </div>
            {watchRelatedFreshMarketStores && watchRelatedFreshMarketStores?.length > 0 ? watchRelatedFreshMarketStores.map((store) => {
              return (
                <div
                  className="second-section-content"
                  key={store.name}
                >
                  {store.name}
                </div>
              );
            }) : '-'}
          </div>
        )}
      </div>
    </>
  );
}

const mapPropsToState = (state) => ({
  // brandList: state.brand.brandList,
  // storeList: state.storeModel.allStoreList,
  campaignList: state.campaignList.pagedList
})

export default connect(mapPropsToState)(HtmlRelatedSection);
