import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { NavBarNames } from '../../../config/NavBarNameList';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import { ActionsDropdownForItem } from '../../../components/base/ActionsDropdown';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { ToastType } from '../../../models/NavBarModel';
import DeletePrompt from '../../../components/base/DeletePrompt';
import AuthButton from '../../../components/base/AuthButton';

function WebviewContentList({
  pagedList,
  totalCount,
  totalPage,
  pageInfo,
  listDisplayFields,
  checkedList,
  languages
}) {
  const [sortDisabled, setSortDisabled] = useState(false);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [deleteItems, setDeleteItems] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const searchDict = queryString.parse(location.search);
  const currentPage = searchDict?.page || 1;
  const searchKey = searchDict?.search;
  const hasSearchKey = searchKey && searchKey.length > 0;
  const fetchData = () => {
    setSortDisabled(hasSearchKey);
    dispatch({
      type: 'webContent/getList',
      payload: {
        page: currentPage,
        searchKey,
        ...searchDict,
      },
    });
    dispatch({
      type: 'language/getList',
      payload: { isSelectorLoad: true }
    });
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setDeleteItems(checkedList);
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.deleteWebContent,
    },
  ];

  const tabs = [
    {
      name: 'List Of webContent',
      content: (
        <BaseTabListContainer
          hideTab={true}
          tabs={[]}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          listContent={
            <BaseListContainer
              fields={listDisplayFields}
              dataList={pagedList}
              totalPage={totalPage}
              model={'webContent'}
              permissionGroup={PermissionCodes.webContent}
              deleteInfo={{
                data: [],
                title: 'webContent',
                relatedName: '',
                onComfirm: {},
                relatedSections: [],
              }}
              // sortable
              sortDisabled={sortDisabled}
              rankDisabled
              hasOtherActions
              useCustomCommonActions
              actions={['Edit', 'Delete']}
              customCommonActions={(item) => {
                const actions = [
                  {
                    name: 'Edit',
                    action: () => {
                      history.push(`${location.pathname}/${item.pk}/edit/`);
                    },
                    requires: PermissionCodes.changeWebContent,
                  },
                  {
                    name: 'Delete',
                    action: () => {
                      setDeleteItems([item]);
                      setShowDeletePrompt(true);
                    },
                    requires: PermissionCodes.deleteWebContent,
                  },
                ];
                return (
                  <ActionsDropdownForItem
                    object={item}
                    actions={actions}
                    onToggle={(open) => {
                      if (!hasSearchKey) {
                        setSortDisabled(open);
                      }
                    }}
                    otherActions={languages.map((language) => {
                      return {
                        name: `Copy URL (${language.sourceName})`,
                        action: () => {
                          navigator.clipboard.writeText(`${item.url}?language=${language.code}`);
                          dispatch({
                            type: 'navBars/updateState',
                            payload: {
                              saveDiscardToastShowing: {
                                value: true,
                                type: ToastType.copySuccess
                              },
                            },
                          });
                        },
                      };
                    })}
                  />
                );
              }}
            />
          }
        />
      ),
    },
  ];
  const buttons = [
    <AuthButton
      title={'Create Webview'}
      className="btn-further"
      action={() => {
        history.push({
          pathname: `${location.pathname}/create/`,
        });
      }}
      requires={PermissionCodes.addWebContent}
    />
  ];
  return (
    <>
      <CustomListComponent
        caution={{
          detail: '',
          title: NavBarNames.webviewContent,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={true}
      />
      <DeletePrompt
        data={deleteItems}
        title="Webview Content"
        relatedSections={[]}
        show={showDeletePrompt}
        onClose={() => {
          setShowDeletePrompt(false);
          setDeleteItems([]);
        }}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: `webContent/updateState`,
            payload: { checkedList: deleteItems },
          });
          dispatch({
            type: 'webContent/delete',
            payload: { afterAction: fetchData },
          });
          setDeleteItems([]);
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  pagedList: state.webContent.pagedList,
  totalCount: state.webContent.totalCount,
  totalPage: state.webContent.totalPage,
  pageInfo: state.webContent.pageInfo,
  listDisplayFields: state.webContent.listDisplayFields,
  checkedList: state.webContent.checkedList,
  languages: state.language.allList,
});

export default connect(mapPropsToState)(WebviewContentList);
