import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { useFormContext, Controller } from 'react-hook-form';
import BaseMultipleSelectorV2 from '../../base/BaseMultipleSelectorV2';
import { ReactHookFormMarkDown } from '../../base/CustomMarkDownArea';
import CustomSwitchButton from '../../base/CustomSwitchButton';
import { InputFieldControl2 } from '../../base/InputFieldControl';
import { LanguageConfig } from '../../../config/CustomEnums';
import { Image } from 'react-bootstrap';
import AuthButton from '../../base/AuthButton';
import addImage from '../../../assets/images/add2.png';
import deleteImage from '../../../assets/images/drop_down_delete_selected.png';
import './WebFrontEndSection.scss';
import { FieldControl } from '../../base/CommonComponent';
import i18n from '../../../I18n';
import { deliveryChannelList, promotionTypeList } from '../../../models/CreateCampaignModel';

function WebFrontEndSection({
  language,
  onFocus = (() => {}),
}) {
  const dispatch = useDispatch();
  const { watch, setValue, formState, control, getValues } = useFormContext();
  const watchEnablePromotion = watch('enablePromotion')
  const watchPromotionType = watch('promotionType') || []
  const watchDeliveryChannel = watch('deliveryChannel') || []

  console.log("@38")
  const promotionPosterItem = () => {
    return (
      <FieldControl 
        name={`translations.${language}.promotionPoster`}
        render={({field, formState}) => {
          if (!field.value) {
            field.onChange([''])
          }
          return (
            <>
            {(field.value)?.map((item, index) => {
              return (
                <div className='promotion-item-container'>
                  <input
                    onChange={({ target }) => {
                      field.value[index] = target.value
                      field.onChange(field.value)
                    }}
                    defaultValue={item}
                    value={item}
                    className={`custom-markdown-area-title  custom-markdown-area-title-short`}
                    onFocus={onFocus('promotionPoster')}
                  />
                  {index === 0 ? null :  
                    <div className='permotion-delete-btn-container'>
                      <AuthButton 
                        children={ <Image src={deleteImage} className="promotion-delete-btn"/>}
                        customClass='promotion-delete-btn'
                        action={() => {
                          const newRewardList = field.value?.filter((item1, index1) => {
                            if (index != index1) {return true}})
                          field.onChange(newRewardList)
                        }}
                      />
                    </div>
                  }
                </div>
              )
            })}
            {field.value?.length > 4 ? null : 
              <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                <AuthButton 
                  title={'Add URL'}
                  customClass="btn-back-button-common btn-download"
                  action={() => {
                    field.onChange([...field.value, ''])
                  }}
                />
              </div>
            }
            </>)
          }}
      />
  )}

  return (
    <>
    <label className="create-section-title">{i18n.t('promotion_section_title', { locale: language })}</label>
    <label className="section-short-description">{i18n.t('promotion_section_description', { locale: language })}</label>
    <label className="create-section-label create-section-label-bottom-space">
    {i18n.t('promotion_enable', { locale: language })}
    </label>
    <CustomSwitchButton
      disabled={language !== LanguageConfig.english}
      defaultChecked={watchEnablePromotion}
      onChange={(value) => {
        setValue("enablePromotion", value, { shouldDirty: true })
      }}
    />
    {watchEnablePromotion ? <>
      <BaseMultipleSelectorV2
        disabled={language !== LanguageConfig.english}
        title={i18n.t('promotion_delivery_channels', { locale: language })}
        size={["shorter"]}
        onFocusFunc={() => {
          onFocus('deliveryChannel');
        }}
        custom={{
          customItem: (item) => {
            return `${item.name}`
          }
        }}
        data={{
          sourceData: deliveryChannelList,
          targetData: watchDeliveryChannel,
          targetChange: (value) => {
            setValue('deliveryChannel', value, {
              shouldDirty: true,
            });
          },
        }}
      />
      <ReactHookFormMarkDown
        content={{
          label: i18n.t('promotion_tnc', { locale: language }),
          key: `translations.${language}.promotionTnc`,
          focus: () => {
            onFocus('promotionTnc')
          }
        }}
      />
      <label className="create-section-label create-section-label-bottom-space">{i18n.t('promotion_poster', { locale: language })}</label>
      <label className='create-section-tips'>{i18n.t('promotion_poster_tips', { locale: language })}</label>
      {promotionPosterItem()}
      <BaseMultipleSelectorV2
        disabled={language !== LanguageConfig.english}
        title={i18n.t('promotion_type', { locale: language })}
        size={["shorter"]}
        onFocusFunc={() => {
          onFocus('promotionType');
        }}
        custom={{
          customItem: (item) => {
            return `${item.name}`
          }
        }}
        data={{
          sourceData: promotionTypeList,
          targetData: watchPromotionType,
          targetChange: (value) => {
            setValue('promotionType', value, {
              shouldDirty: true,
            });
          },
        }}
      />
      <InputFieldControl2
        disabled={language !== LanguageConfig.english}
        name={`promotionContact`}
        title={i18n.t('promotion_contact', { locale: language })}
      />
      <InputFieldControl2
        name={`translations.${language}.promotionMagazineLink`}
        title={i18n.t('promotion_magazine_link', { locale: language })}
      />
      <InputFieldControl2
        disabled={language !== LanguageConfig.english}
        name={`lastYearPromotionId`}
        title={i18n.t('promotion_id', { locale: language })}
        type={'number'}
        className="custom-markdown-area-title  custom-markdown-area-title-short"
      />
      
    </> : null}
    </>

  );
}

export default WebFrontEndSection;