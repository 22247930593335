import React from 'react';
import { LanguageConfig } from '../../config/CustomEnums';
import './CountComponent.scss';
function CountComponent(props) {
  const { start, end, total, language } = props;
  let countContent = `Showing ${start} to ${end} of ${total} entries`;
  if (language && language === LanguageConfig.traditionalChinese) {
    countContent = `顯示 ${start} to ${end}，總共${total}個項目`;
  } else if (language && language === LanguageConfig.simplifiedChinese) {
    countContent = `显示 ${start} to ${end}，总共${total}个项目`;
  }

  return <label className="count-text">{countContent}</label>;
}

export default CountComponent;
