import React, { useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import LoadingIcon from '../../assets/images/loading.gif';
import Login from './Login';
import './SamlLogin.scss';

const CMS_BASE_URL =
process.env.REACT_APP_DOMAIN || 'https://aillia.hkdev.motherapp.com:443';
export const SAML_LOGIN_URL = CMS_BASE_URL + "/login/saml";


const SamlLogin = () => {
	const history = useHistory();
	const dispatch = useDispatch();
  const location = useLocation();
  const code = parse(location.search)['code']
	const isLogin = useSelector((state) => state.users.isLogin);
	const loginFailed = useSelector((state) => state.users.loginFailed);
  useEffect(()=>{
    if (!isLogin) {
      if (code) {
        console.log("@15");
        dispatch({
          type: 'users/login',
          payload: { username: code, isSamlLogin: true },
        })
      } else {

      }
    }
  }, []);
	if (!isLogin && code === undefined) {
	  return <Redirect to="/login" />;
	}
	if (history?.location?.state?.isLogout || isLogin || loginFailed) {
		return <Redirect to="/login" />;
	}
	return (
		<div className="loading-area">
			<Image src={LoadingIcon} className="loading-icon" />
		</div>
	)
}

export default SamlLogin;