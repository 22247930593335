import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useFormContext, Controller } from 'react-hook-form';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import CustomEditor from '../base/CustomEditor';
import {
  CustomTitleWithDropDown,
  CustomTitleWithDropDownAndInput,
} from './CustomTitleWithDropDown';
import { checkHasPermission } from '../../utils';
import CustomTitleWithSwitch from '../base/CustomTitleWithSwitch';
import BaseMultipleSelectorV2 from '../base/BaseMultipleSelectorV2';
import { PermissionCodes } from '../../config/PermissionCodes';
import CustomerFilterableDropdown from '../transactions/CustomerFilterableDropdown';
import { hasError, errorMessage, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import '../../containers/customers/customer/CustomerOverviewDetail.scss';
import i18n from '../../I18n';
import CustomTitleWithInput from '../base/CustomTitleWithInput';


function MembershipInfoEditCard({
  couponList,
  user,
  title,
  language,
}) {
  const dispatch = useDispatch();

  const { watch, setValue, control, formState, clearErrors } = useFormContext();
  const { errors } = formState;

  const watchPointTransaction = watch('pointTransaction');
  const watchTransactionPoint = watch('transactionPoint');
  const watchPointTransactionRemark = watch('pointTransactionRemark');
  const watchReferrerUser = watch('referrerUser');
  const watchDirectMarketing = watch('hasAgreedDirectMarketing');
  const watchIsForcedInactive = watch('isForcedInactive');
  const watchOwnedCoupons = watch('ownedCoupons');
  const watchRemovedCoupons = watch('removedCoupons');
  const watchInGroups = watch('inGroups');
  const watchLeaveGroups = watch('leaveGroups');
  const watchPromotionCode = watch('promotionCode');

  const [canEditPromotionCode, setCanEditPromotionCode] = useState(!!watchPromotionCode);

  useEffect(() => {
    dispatch({
      type: 'campaignList/getCampaignList',
      payload: {}
    });
  }, [dispatch]);

  const getPointTransactionSource = () => {
    let source = [];
    if (checkHasPermission(user, PermissionCodes.addCustomerPoints)) {
      source.push({ name: 'Add transaction', pk: 'ADD_POINTS' });
    }
    if (checkHasPermission(user, PermissionCodes.removeCustomerPoints)) {
      source.push({ name: 'Remove transaction', pk: 'REMOVE_POINTS' });
    }
    return source;
  };

  return (
    <div>
      <label className="create-section-title">{title}</label>

      {/* <CustomTitleWithDropDown
        title={'Current level'}
        source={levelList}
        defaultValue={{
          label: currentLevel?.levelName,
          value: currentLevel,
        }}
        needFilter={false}
        setValue={setCurrentLevel}
        disabled={true}
        error={{}}
      /> */}

      {/* <CustomTitleWithDropDownAndInput
        title="Change points transaction(Optional)"
        source={getPointTransactionSource()}
        defaultValue={''}
        needFilter={false}
        unit="Points"
        customClass="edit-custom-transaction-selector"
        setValue={(value) => {
          setValue('pointTransaction', value, { shouldDirty: true });
        }}
        setInputValue={(value) => {
          setValue('transactionPoint', value, { shouldDirty: true });
        }}
      /> */}
      {/* <ReactHookFormErrorMessage errors={errors} id='pointTransaction' /> */}
      {/* <ReactHookFormErrorMessage errors={errors} id='transactionPoint' /> */}

      {/* <CustomTitleLabel title="Points transaction remark(Optional)" /> */}
      {/* <CustomEditor
        initialValue={''}
        onValueChange={(value) => {
          setValue('pointTransactionRemark', value, { shouldDirty: true });
        }}
        onFocus={() => {}}
        errorMessage={''}
        error={''}
      />
      <ReactHookFormErrorMessage errors={errors} id='pointTransactionRemark' /> */}

      <BaseMultipleSelectorV2
        title={i18n.t('concierge.owned_coupons', { locale: language })}
        data={{
          sourceData: watchOwnedCoupons,
          targetData: watchRemovedCoupons,
          targetChange: (value) => {
            setValue('removedCoupons', value, { shouldDirty: true, });
          },
        }}
        addButton={{
          title: i18n.t('concierge.add_single_coupon', { locale: language }),
          link: '/coupons'
        }}
        error={{
          error: hasError(errors, 'removedCoupons'),
          id: 'removedCoupons',
          message: errorMessage(errors, 'removedCoupons'),
        }}
      />

      <BaseMultipleSelectorV2
        title={i18n.t('concierge.customer_group_optional', { locale: language })}
        data={{
          sourceData: watchInGroups,
          targetData: watchLeaveGroups,
          targetChange: (value) => {
            setValue('leaveGroups', value, { shouldDirty: true });
          },
        }}
        error={{
          error: hasError(errors, 'leaveGroups'),
          id: 'leaveGroups',
          message: errorMessage(errors, 'leaveGroups'),
        }}
      />

      <CustomerFilterableDropdown
        title={i18n.t('concierge.referrer_optional', { locale: language })}
        defaultSelectedCustomer={watchReferrerUser}
        selectCustomer={(value) => {
          setValue('referrerUser', value, { shouldDirty: true });
        }}
        moreSearch={{
          isFilterMembershipId: true
        }}
        customItem={(customer) => customer?.membershipId}
      />

      {/* <CustomTitleWithDropDown
        title={'Referred by campaign(Optional)'}
        source={campaignList}
        defaultValue={referrerCampaign}
        setValue={setReferrerCampaign}
      /> */}

      <CustomTitleWithInput
        title={i18n.t('concierge.promotion_code', { locale: language })}
        defaultValue={watchPromotionCode}
        setValue={(value) => {
          setValue('promotionCode', value, { shouldDirty: true });
        }}
        disabled={canEditPromotionCode}
      />
      <ReactHookFormErrorMessage errors={errors} id="promotionCode" />

      <CustomTitleWithSwitch
        title={i18n.t('concierge.direct_marketing', { locale: language })}
        defaultValue={!watchDirectMarketing}
        setValue={(value) => {
          setValue('hasAgreedDirectMarketing', !value, { shouldDirty: true });
        }}
      />

      <CustomTitleWithSwitch
        title={i18n.t('concierge.is_forced_inactive', { locale: language })}
        defaultValue={watchIsForcedInactive}
        setValue={(value) => {
          setValue('isForcedInactive', value, { shouldDirty: true });
        }}
      />
    </div>
  );
}

const mapPropsToState = (state) => ({
  couponList: state.couponList.couponSetList,
  user: state.users,
});

export default connect(mapPropsToState)(MembershipInfoEditCard);

