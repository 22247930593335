import React from 'react';
import '../../containers/customers/customer/CustomerOverviewDetail.scss';
import { Button } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory, useLocation } from 'react-router-dom';
import AuthButton from '../base/AuthButton';

function RecordTableCard({
  title,
  data,
  fields,
  viewAllLink,
  tableWidth = 920,
  buttonRequires,
  buttonText,
}) {
  const history = useHistory();

  return (
    <>
      <label className="create-section-title">{title}</label>
      {data.length <= 0 ? (
        <label className="custom-record-normal-font custom-timeline-time-color list-new-line">
          -
        </label>
      ) : (
        <>
          <PerfectScrollbar>
            <table
              className="custom-record-table"
              style={{ width: `${tableWidth}px` }}
            >
              <thead>
                {fields.map((field, index) => {
                  return (
                    <th
                      className="custom-record-blod-font"
                      style={{
                        width:
                          index === 0
                            ? '57px'
                            : `${
                                (tableWidth - 60 - 57) / (fields.length - 1)
                              }px`,
                      }}
                    >
                      {field.displayName}
                    </th>
                  );
                })}
              </thead>
              <tbody>
                {data.map((item) => {
                  return (
                    <tr>
                      {fields.map((field) => {
                        return (
                          <td>
                            <label>{item[field.name] || '-'}</label>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </PerfectScrollbar>
          <AuthButton
            className="btn-further view-all-button"
            onClick={() => {
              // dispatch({ type: 'customerList/clearData' });
              history.push(viewAllLink);
            }}
            requires={buttonRequires}
          >
            {buttonText || `View all ${title.toLowerCase()} record`}
          </AuthButton>
        </>
      )}
    </>
  );
}

export default RecordTableCard;
