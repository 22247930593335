function themedSectionValidate({ getValues, setError }) {
  const [coverPhoto, description] = getValues(['coverPhoto', 'description']);
  const errorList = [];
  if (!coverPhoto) {
    errorList.push({
      name: 'coverPhoto',
      message: 'Please provide cover photo',
    })
  }
  if (!description) {
    errorList.push({
      name: 'description',
      message: 'Please provide description',
    })
  }
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'manual',
      message: error.message,
    });
  });
  return !errorList.length;
}

export function validate({
  getValues,
  setError,
  clearErrors,
  isBack,
}) {
  console.log('@43 ThemedSectionValidate:', getValues());
  if (isBack) {
    return true;
  }
  clearErrors();
  const valid = themedSectionValidate({ getValues, setError });
  return valid;
}
