import { BaseHelper } from './BaseGraphQLAPIHelper';
import { getInFilterValue, getSearchKey } from '../utils';
export const getCampaignCategoryList = (
  afterCursor,
  searchKey,
  otherSearch,
) => {
  let filter = '';
  let pageSize = 20;
  if (searchKey) {
    filter += `, nameIcontains:"${getSearchKey(searchKey)}"`;
  }
  console.log("getCampaignCategoryList:", otherSearch)
  if (otherSearch?.status) {
    filter += `, isActive: ${otherSearch.status}`;
  }
  let orderBy = 'displayPriority';
  if (otherSearch?.sort) {
    orderBy = otherSearch.sort;
  }
  if (otherSearch?.isAll) {
    pageSize = 100;
  }
  if (otherSearch?.category_type) {
    filter += `, typeIn: ${getInFilterValue(otherSearch.category_type)}`
  }
  if (otherSearch?.typeIn) {
    filter += `, typeIn: ${getInFilterValue(otherSearch.typeIn)}`
  }

  const query = `{
    campaignCategories(first: ${pageSize}, after:"${afterCursor}", orderBy: "${orderBy}"${filter}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          name
          type
          ${
            otherSearch?.isAll
              ? ''
              : `displayPriority
          isForcedInactive
          translations(first: 10) {
            edges {
              node {
                language
                name
                pk
              }
            }
          }`
          }          
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getCampaignCategory = (id) => {
  const query = `{
    campaignCategory(id: "${id}") {
      id
      pk
      name
      type
      displayPriority
      isForcedInactive
      translations {
        edges {
          node {
            language
            name
            id
            pk
          }
        }
      }
    }
  }
  `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const updateCampaignCategory = (input) => {
  const query = `mutation UpdateCampaignCategory($input: UpdateCampaignCategoryInput!) {
    updateCampaignCategory(input: $input) {
      node {
        id
        pk
        name
        type
        displayPriority
        isForcedInactive
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const deleteCampaignCategories = (input) => {
  console.log("@114", input);
  const query = `mutation DeleteCampaignCategories($input: DeleteCampaignCategoriesInput!) {
    deleteCampaignCategories(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};
