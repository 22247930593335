import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import ContentSections from '../../../components/base/ContentSections';
import { APIStatus, LanguageConfig } from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import i18n from '../../../I18n';

const CampaignTagDetail = ({
  campaignTag,
  apiStatus,
  languages
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  
  const renderTabForLanguage = (language) => {
    const tagName =
    (language === LanguageConfig.english
      ? campaignTag.name
      : campaignTag.translations?.[language]?.name) || '-';
    const order = campaignTag?.order;
    const tagNameWithOrder = `${tagName}(${order})`;
    const tagStatus = campaignTag.isForcedInactive ? 'Inactive' : 'Active';

    return (
      <MembershipInfoCard
        title={i18n.t('content', { locale: language })}
        data={{ tagNameWithOrder, tagStatus }}
        fields={[
          [{
              title: 'Name',
              field: 'tagNameWithOrder',
          }],
          [
            {
              title: 'Status',
              field: 'tagStatus',
            },
          ],
        ]}
      />
    );
  }

  const tabs = [
    {
      name: 'Detail',
      content: (
        apiStatus === APIStatus.calling ? (
          <Loading />
        ) : (
          <ContentSections
            hidePreview
            languageTabContent={{
              containers: languages?.map((item) => ({
                container: [renderTabForLanguage(item.code)],
                key: item.code,
                title: item.sourceName,
              })),
            }}
            activeSection={LanguageConfig.english}
          />
        )
      ),
    },
  ];

  const buttons = [
    <AuthButton
      title="Edit"
      action={() => {
        history.push({
          pathname: 'edit/',
        });
      }}
      requires={PermissionCodes.changeCampaignTag}
    />,
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  useEffect(() => {
    const tagID = params.id;
    if (tagID) {
      dispatch({ type: 'language/getList', payload: { isSelectorLoad: true }});
      dispatch({ type: 'campaignTag/getOneDetail', payload: { id: tagID }});
    }
    return () => {
      dispatch({ type: 'campaignTag/clearData' });
    };
  }, []);

  return (
    <div className="campaign-tag-detail">
      <CustomListComponent
        caution={{
          detail: '',
          title: campaignTag.name,
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb name={campaignTag.name} />}
        buttons={buttons}
        tabs={tabs}
        onTabChange={(key) => {
          setActiveTab(key);
        }}
      />
    </div>
  );
};

const mapPropsToState = (state) => ({
  campaignTag: state.campaignTag.detail,
  apiStatus: state.campaignTag.apiStatus,
  languages: state.language.allList,
})

export default connect(mapPropsToState)(CampaignTagDetail);
