import { getInFilterValue, getSearchKey } from '../utils';
import { BaseHelper } from './BaseGraphQLAPIHelper';

const STORE_NODE = `
id
pk
name
photo
address
phoneNumber
propertyType
codeDisplayImage
codeDownloadImage
description
staffCode
mall {
  name
  district {
    region {
        pk
        name
    }
    name
  }
}
freshMarket {
  name
  mall {
    name
    district {
      region {
          pk
          name
      }
      name
    }
  }
}
brand {
    pk
    name
}
level2Categories{
  edges {
    node {
      pk
      name
    }
  }
}
translations {
  edges {
    node {
      language
      name
      description
      address
    }
  }
}
`

const SIMPLE_STORE_NODE = `
id
pk
name
storeType
translations {
  edges {
    node {
      language
      name
    }
  }
}
`

export const CONCIERGE_STORE_NODE = `
id
pk
name
storeType
brand {
  icon
}
mall {
  pk
}
freshMarket {
  mall {
    pk
  }
}
translations {
  edges {
    node {
      language
      name
    }
  }
}
`

export const getStores = () => {
  const query = `{
        stores(first: 100) {
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          edges {
            cursor
            node {
              id
              pk
              name
              photo
              address
              translations(first: 3) {
                edges {
                  node {
                    language
                    name
                  }
                }
              }
            }
          }
        }
      }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getStoresOfBrand = (brandID, afterCursor) => {
  const query = `{
    brand(id: "${brandID}", after: "${afterCursor}") {
      stores(first: 20) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            pk
            name
            photo
            address
            translations {
              edges {
                node {
                  language
                  name
                }
              }
            }
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getPagedStores = (afterCursor, search = {}) => {
  // const order = rank ? '-pk' : 'pk';
  console.log('@@73: ', search);
  let searchString = '';
  if (search.search) {
    searchString += `, name: "${getSearchKey(search.search)}"`;
  }
  if (search.subcategory) {
    searchString += `, level2CategoryIn: ${getInFilterValue(search.subcategory)}`;
  }
  if (search.brand) {
    searchString += `, brandIn: ${getInFilterValue(search.brand)}`;
  }
  if (search.mall) {
    searchString += `, mallIn: ${getInFilterValue(search.mall)}`;
  }
  if (search.mall_in_with_fresh_market) {
    searchString += `, mallInWithFreshMarket: ${getInFilterValue(search.mall_in_with_fresh_market)}`;
  }
  if (search.property_type) {
    searchString += `, propertyTypeIn: ${getInFilterValue(search.property_type)}`;
  }
  if (search.storeType) {
    searchString += `, storeTypeIn: ${getInFilterValue(search.storeType)}`;
  }
  let orderBy = 'name';
  if (search?.sort) {
    orderBy = search.sort;
  } else {
    orderBy = 'pk'
  }
  let pageSize = 20;
  if (search?.pageSize) {
    pageSize = search.pageSize;
  }

  const storeNode = search?.node ? search?.node : search?.isSimpleNode ? SIMPLE_STORE_NODE : STORE_NODE;
  const query = `{
    stores(first: ${pageSize}, after: "${afterCursor}", orderBy: "${orderBy}"${searchString}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ${storeNode}
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneStore = (id) => {
  const query = `{
    store(id: "${id}") {
      id
      pk
      name
      photo
      address
      detailPhoto1
      detailPhoto2
      detailPhoto3
      detailPhoto4
      detailPhoto5
      detailPhoto6
      detailPhoto7
      detailPhoto8
      detailPhoto9
      phoneNumber
      codeDisplayImage
      codeDownloadImage
      description
      staffCode
      website
      openingHours
      storeType
      propertyType
      level1Categories{
        edges {
          node {
            pk
            name
            translations {
              edges {
                node {
                  pk
                  language
                  name
                }
              }
            }
            lv2categories {
              edges {
                node {
                  pk
                  name
                  translations {
                    edges {
                      node {
                        pk
                        language
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      level2Categories{
        edges {
          node {
            pk
            name
          }
        }
      }
      level3Categories {
        edges {
          node {
            pk
            name
            translations {
              edges {
                node {
                  pk
                  language
                  name
                }
              }
            }
            lv4categories {
              edges {
                node {
                  pk
                  name
                  translations {
                    edges {
                      node {
                        pk
                        language
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      brand {
        pk
        name
        translations {
          edges {
            node {
              pk
              language
              name
            }
          }
        }
      }
      mall {
        name
        pk
        latitude
        longitude
        district {
          region {
              pk
              name
          }
          name
          pk
          translations {
            edges {
              node {
                pk
                language
                name
              }
            }
          }
        }
        translations {
          edges {
            node {
              pk
              language
              name
            }
          }
        }
      }
      freshMarket {
        name
        mall {
          latitude
          longitude
          district {
            name
            pk
            translations {
              edges {
                node {
                  pk
                  language
                  name
                }
              }
            }
          }
        }
        translations {
          edges {
            node {
              pk
              language
              name
            }
          }
        }
      }
      translations {
          edges {
            node {
              pk
              language
              name
              description
              address
              openingHours
            }
          }
        }
      }
    }
  `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteStores = (ids) => {
  const query = `mutation DeleteStores($input: DeleteStoresInput!) {
    deleteStores(input: $input) {
      success
    }
  }
  `;
  const variables = { input: { ids: ids } };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createOrUpdateStore = (values) => {
  const action = values.id ? 'Update' : 'Create';
  let query = `mutation ${action}Store($input: ${action}StoreInput!) {
    ${action.toLowerCase()}Store(input: $input) {
      success
      node {
          id
          pk
          name
          displayPriority
          photo
          address
          phoneNumber
          latitude
          longitude
          emailAddress
          description
          staffCode
          subcategories {
              edges {
                  node {
                      pk
                      category {
                          name
                          displayPriority
                      }
                      name
                      displayPriority
                  }
              }
          }
          district {
              region {
                  pk
                  name
              }
              name
          }
          brand {
              pk
          }
          translations {
            edges {
              node {
                pk
                language
                name
                description
                address
              }
            }
          }
      }
      errors {
        field
        messages
      }
    }
  }

  `;

  const variables = { input: values };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getDistricts = (afterCursor, search) => {
  let filter = '';

  if (search?.search) {
    filter += `, nameIcontains: "${getSearchKey(search.search)}"`;
  }
  const query = `{
    districts(first: 50, after: "${afterCursor}", orderBy: "pk"${filter}) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          region {
              pk
              name
          }
          name
          translations {
            edges {
              node {
                language
                name
                pk
              }
            }
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getDownloadStoreCodesZip = (values) => {
  const query = `mutation DownloadStores($input: DownloadStoreCodesInput!) {
    downloadStores(input: $input) {
      success
      zipLink
    }
  }`;
  const variables = { input: values };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getStoreGroupByMall = (
  afterCursor,
  filter
) => {
  let {isAll, searchKey, pageSize, brandIn} = filter;
  let searchString = `orderBy: "pk"`
  if (!isAll) {
    searchString += `, first: ${pageSize? pageSize: "20"}`
  }
  if (afterCursor) {
    searchString += `, after: "${afterCursor}"`
  }
  if (brandIn) {
    searchString +=`, brandIn:${getInFilterValue(brandIn ? brandIn : [])}`
  }
  console.log("@87", filter)
  const query = `{
    stores(${searchString}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          name
          mall {
              pk
              name
          }
        }
      }
    }
  }`
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
}

export const getAllStoreList = (
  afterCursor,
  filter
) => {
  let {isAll, searchKey, pageSize, brandIn, mallIn, freshMarketIn, propertyTypeIn, level2CategoryIn, level3CategoryIn} = filter;
  let searchString = `orderBy: "${filter?.orderBy || "pk"}"`
  if (!isAll) {
    searchString += `, first: ${pageSize? pageSize: "20"}`
  }
  if (afterCursor) {
    searchString += `, after: "${afterCursor}"`
  }
  if (brandIn && brandIn.length > 0) {
    searchString +=`, brandIn:${getInFilterValue(brandIn ? brandIn : [])}`
  }
  if (mallIn && mallIn.length > 0) {
    searchString +=`, mallIn:${getInFilterValue(mallIn ? mallIn : [])}`
  }
  if (freshMarketIn && freshMarketIn.length > 0) {
    searchString +=`, freshMarketIn:${getInFilterValue(freshMarketIn ? freshMarketIn : [])}`
  }
  if (level2CategoryIn && level2CategoryIn.length > 0) {
    searchString +=`, level2CategoryIn:${getInFilterValue(level2CategoryIn ? level2CategoryIn : [])}`
  }
  if (level3CategoryIn && level3CategoryIn.length > 0) {
    searchString +=`, level3CategoryIn:${getInFilterValue(level3CategoryIn ? level3CategoryIn : [])}`
  }
  if (propertyTypeIn) {
    searchString +=`, propertyTypeIn:${getInFilterValue(propertyTypeIn)}`
  }
  if (filter?.isFilterNameOrId && filter?.searchKey) {
    searchString += `, nameOrId: "${getSearchKey(filter.searchKey)}"`;
  } else if (filter?.searchKey) {
    searchString += `, nameIcontains: "${getSearchKey(filter.searchKey)}"`;
  }
  console.log("@87", filter)
  const query = `{
    stores(${searchString}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          name
          staffCode
          mall {
            pk
            name
          }
          freshMarket {
            pk
            name
          }
        }
      }
    }
  }`
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
}

export const getRegions = (afterCursor, payload) => {
  const query = `{
    regions(orderBy: "name") {
      edges {
        cursor
        node {
          id
          pk
          name
          districts(orderBy: "name") {
            edges {
              cursor
              node {
                id
                pk
                name
                malls(orderBy: "name") {
                  edges {
                    node {
                      id
                      pk
                      name
                      transactionSelectStoreIds
                      transactionSelectStores {
                        edges {
                          node {
                            pk
                            name
                            brand {
                              icon
                            }
                            translations {
                              edges {
                                node {
                                  language
                                  name
                                }
                              }
                            }
                          }
                        }
                      }
                      translations{
                        edges {
                          node {
                            language
                            name
                          }
                        }
                      }
                    }
                  }
                }
                translations {
                  edges {
                    node {
                      language
                      name
                    }
                  }
                }
              }
            }
          }
          translations {
            edges {
              node {
                language
                name
              }
            }
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};
