import React, { useEffect, useState } from 'react';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { Button } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import { useSelector, useDispatch } from 'react-redux';
import { createAction } from '../../../utils';
import DeletePrompt from '../../../components/base/DeletePrompt';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';
import ListButtonsGroup from '../../../components/base/ListButtonsGroup';
import { ImportResource } from '../../../models/DownloadImportModel';
import ExportSelectorView from '../../../components/customerGroup/ExportSelectorView';

export default function CustomerGroupList() {
  const queryString = require('query-string');
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const hiddenFileInput = React.useRef(null);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [showExportView, setShowExportView] = useState(false);


  const uploadID = 'uploadCsvId';
  const [uploadOriginFileName, setUploadOriginFileName] = useState();

  const {
    pageInfo,
    totalCount,
    totalPage,
    displayFields,
    groupList,
    checkedList,
    importedFile,
    allGroupList,
  } = useSelector((state) => ({
    pageInfo: state.customerGroup.pageInfo,
    totalCount: state.customerGroup.totalCount,
    totalPage: state.customerGroup.totalPage,
    displayFields: state.customerGroup.listDisplayFields,
    groupList: state.customerGroup.currentPageGroupList,
    checkedList: state.customerGroup.checkedList,
    importedFile: state.uploadFiles.importedFile,
    allGroupList: state.customerGroup.groupList,
  }));

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    const search = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);
    dispatch(
      createAction('customerGroup/getCurrentPageList')({
        search,
        rank,
        page,
        moreSearch: {
          ...searchDict,
          isSimpleList: true,
        },
      }),
    );
  }, [dispatch, location, queryString]);

  useEffect(() => {
    dispatch(
      createAction('customerGroup/getGroupList')({isSimpleList: true,}),
    );
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(importedFile).length) {
      dispatch({
        type: 'downloadAndImport/importFile',
        payload: {
          from: ImportResource.customerGroup,
          file: importedFile[uploadID]?.file,
          related: {
            fileName: uploadOriginFileName,
          }
        }
      });
    }
  }, [dispatch, importedFile]);

  const handleImportClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setUploadOriginFileName(fileUploaded?.name);
    // props.handleFile(fileUploaded);
    dispatch(
      createAction('uploadFiles/uploadFile')({
        file: fileUploaded,
        source: event,
        id: uploadID,
      }),
    );

    event.target.value = null;
  };

  const buttons = [
    <ListButtonsGroup
      hideExtraButtonWidth={500}
      extraButtons={[
        <AuthButton
          customClass="btn-back-button-common btn-download"
          title="Export .csv"
          action={() => setShowExportView(true)}
          requires={PermissionCodes.addExportjob}
        />,
        <AuthButton
          title={'Import .csv'}
          action={handleImportClick}
          requires={PermissionCodes.addImportjob}
        />,
      ]}
      extraPopContent={[
        {
          requires: PermissionCodes.addExportjob,
          action: () => setShowExportView(true),
          content: 'Export .csv',
        },
        {
          requires: PermissionCodes.addImportjob,
          action: handleImportClick,
          content: 'Import .csv',
        },
      ]}
      primaryButton={
        <AuthButton
          title="Create Customer Group"
          action={() => history.push('/customer_group/create')}
          requires={PermissionCodes.addCustomerGroup}
        />
      }
      primaryPopContent={{
        requires: PermissionCodes.addCustomerGroup,
        action: () => history.push('/customer_group/create'),
        content: "Create Customer Group",
      }}
    />,
    <input
      type="file"
      ref={hiddenFileInput}
      onChange={handleChange}
      style={{ display: 'none' }}
      accept=".csv"
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => setShowDeletePrompt(true),
      requires: PermissionCodes.deleteCustomerGroup,
    },
  ];
  const tabs = [
    {
      name: 'List of customer groups',
      content: (
        <BaseTabListContainer
          hideTab={true}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          listContent={
            <BaseListContainer
              fields={displayFields}
              model={'customerGroup'}
              permissionGroup={PermissionCodes.customergroup}
              dataList={groupList}
              totalPage={totalPage ? totalPage : 0}
              deleteInfo={{
                data: [],
                title: 'customer group',
                relatedName: '',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.CUSTOMER_GROUP,
              }}
              actions={['Detail', 'Edit', 'Delete', 'Duplicate']}
            />
          }
        />
      ),
    },
  ];
  return (
    <>
      <CustomListComponent
        caution={{
          detail:
            'Create various customer groups and target them with different experiences.',
          title: 'Customer groups',
        }}
        buttons={buttons}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={false}
        tabs={tabs}
      />
      <ExportSelectorView
        show={showExportView}
        sourceData={allGroupList}
        selectTitle={'Customer Group(s)'}
        namespace={'customerGroup'}
        exportResource={ImportResource.customerGroup}
        relatedField={'customerGroupIds'}
        dispatch={dispatch}
        onHide={() => {
          setShowExportView(false);
        }}
      />
      <DeletePrompt
        title={'customer group'}
        data={checkedList}
        relatedSections={DELETE_RELATED_SECTIONS.CUSTOMER_GROUP}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('customerGroup/delete')({
              afterAction: () => {
                history.push('/customer_group');
              },
            }),
          );
        }}
      />
    </>
  );
}
