import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Controller, useFormContext } from 'react-hook-form';
import {
  SectionTopTitle,
} from '../base/CommonComponent';
import { Button, Row } from 'react-bootstrap';
import CustomRadios from '../base/CustomRadios';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import { AdminErrorHandleFields } from '../../containers/admin/admins/AdminErrorHandleFields';

function RelatedStore({
  mallList,
  frashMarketList,
  mallStoreList,
  freshMarketStoreList
}) {
  const params = useParams();
  const { id } = params;
  const { watch, setValue, control } = useFormContext();
  const filterByFreshMarket = watch('filterByFreshMarket') || false;
  const selectedStore = watch('selectedStore');
  const [filterMall, setFilterMall] = useState(
    // selectedStore?.mall
    {
      label: selectedStore?.value?.mall?.name,
      value: selectedStore?.value?.mall
    }
  );
  const [filterFreshMarket, setFilterFreshMarket] = useState(
    // selectedStore?.freshMarket
    {
      label: selectedStore?.value?.freshMarket?.name,
      value: selectedStore?.value?.freshMarket
    }
  );

  // const [loadStore, setLoadStore] = useState(false);
  const showStore = !!(filterMall.value || filterFreshMarket.value);
  const dispatch = useDispatch();

  const isNotEmpty = (value) => {
    return !(value === undefined || value === null || value.length === 0);
  }

  const radioOptions = () => [
    {
      label: 'Mall',
      value: false,
    },
    {
      label: 'Fresh market',
      value: true,
    },
  ];

  const defaultFilter = {
    isAll: true,
    isSimpleFilter: true,
    isSelectorLoad: true,
    isFilterNameOrId: true,
    sort: 'name',
  }

  const defaultStoreFilter = {
    isAll: true,
    isFilterNameOrId: true,
    mallIn: !filterByFreshMarket && filterMall?.value ? [filterMall?.value?.pk] : null,
    freshMarketIn: filterByFreshMarket && filterFreshMarket?.value ? [filterFreshMarket?.value?.pk] : null,
    filterByFreshMarket: filterByFreshMarket,
  }

  useEffect(() => {
    console.log("@639", filterMall, filterFreshMarket)
    if (isNotEmpty(filterMall?.value) || isNotEmpty(filterFreshMarket?.value)) {
      dispatch({
        type: 'storeModel/getAllMallStoreList',
        payload: defaultStoreFilter
      });
    }
  }, [filterMall, filterFreshMarket, filterByFreshMarket]);

  useEffect(() => {
    dispatch({
      type: 'mall/getFilterMallList',
      payload: defaultFilter
    });
    dispatch({
      type: 'freshMarket/getList',
      payload: defaultFilter
    });
  }, [dispatch]);

  return (
    <>
      <SectionTopTitle title="Related store" />
      <CustomTitleLabel title={'Select mall or fresh market store'} />
      <Row style={{margin: "0px"}}>
        <Controller 
          control={control}
          name='filterByFreshMarket'
          render={({field}) => (
            <CustomRadios
              onChange={(value) => {
                setValue("selectedStore", null, {shouldDirty:true})
                setValue('filterByFreshMarket', value, {shouldDirty: true})
                setFilterMall('');
                setFilterFreshMarket('');
              }}
              default={filterByFreshMarket}
              options={radioOptions()}
            />
          )}
        />
      </Row>
      {
        filterByFreshMarket ? 
        <CustomTitleWithDropDown
          title={`Filter by fresh market`}
          placeholder={'Search by ID or Name'}
          setValue={(value) => {
            // setValue("selectMall", value, {shouldDirty:true})
            setFilterFreshMarket(value)
            setValue("selectedStore", '', {shouldDirty:true})
          }}
          source={frashMarketList}
          defaultValue={filterFreshMarket || selectedStore?.freshMarket}
          labelContainPk={true}
          filterAction={'freshMarket/getList'}
          defaultFilter={defaultFilter}
        /> : 
        <CustomTitleWithDropDown
          title={`Filter by mall`}
          placeholder={'Search by ID or Name'}
          setValue={(value) => {
            // setValue("selectMall", value, {shouldDirty:true})
            setFilterMall(value)
            setValue("selectedStore", '', {shouldDirty:true})
          }}
          source={mallList}
          defaultValue={filterMall}
          labelContainPk={true}
          filterAction={'mall/getFilterMallList'}
          defaultFilter={defaultFilter}
        />
        
      }
      <Controller
        control={control}
        name='selectedStore'
        rules={{
          validate: {
            notNull: (value) => {
              return (
                value?.label?.length > 0 ||
                AdminErrorHandleFields.required.selectedStore
              );
            },
        }, }}
        render={({field, fieldState: {error}}) => (
          <CustomTitleWithDropDown
            title={`Select store `}
            placeholder={'Search by ID or Name'}
            setValue={(value) => {
              setValue("selectedStore", value, {shouldDirty:true})
            }}
            source={showStore ? filterByFreshMarket ?  freshMarketStoreList : mallStoreList : []}
            defaultValue={selectedStore}
            filterAction={'storeModel/getAllMallStoreList'}
            defaultFilter={defaultStoreFilter}
            errors={error ? ['selectedStore']: []}
            errorName={'selectedStore'}
            errorMessage={error?.message}
            labelContainPk={true}
          />
        )}
      />

    </>
  );
}

const mapPropsToState = (state) => ({
  mallList: state.mall.filterMallList || [],
  frashMarketList: state.freshMarket.allList || [],
  mallStoreList: state.storeModel.allMallStoreList || [],
  freshMarketStoreList: state.storeModel.allFreshMarketStoreList || [],
});

export default connect(mapPropsToState)(RelatedStore);
