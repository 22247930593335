import React from 'react';
import './CustomMarkDownArea.scss';
import FollowCouponSetSwitch from '../campaign/campaignCreation/FollowCouponSetSwitch';
import SpecificCustomSwitchButton from '../../containers/merchants/stores/SpecificCustomSwitchButton';

export default function LabelWithSwitch({
  labelText,
  className,
  checked,
  onChange,
  notUseDefaultLabelStyle,
  showSwitch,
}) {
  const labelClass = `${
    notUseDefaultLabelStyle ? '' : 'create-section-label'
  } ${className}`;
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        maxWidth: '700px',
      }}
    >
      <label className={labelClass}>{labelText}</label>
      {showSwitch ? (
        <div
          style={{
            marginTop: notUseDefaultLabelStyle ? '0px' : '30px',
            marginLeft: 'auto',
          }}
        >
          <FollowCouponSetSwitch checked={checked} onChange={onChange} />
        </div>
      ) : null}
    </div>
  );
}

export const ApplySwitch = ({
  onChange = () => {},
  show,
  checked,
  disabled,
  title = 'Apply to all language'
}) => {
  if (!show) {
    return null;
  }
  console.log("applySwitch:", checked)
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <label
        className="create-section-label"
        style={{ marginTop: '10px', marginBottom: '8px' }}
      >
        {title}
      </label>
      <SpecificCustomSwitchButton
        disabled={disabled}
        checked={checked}
        onChange={(value) => {
          onChange(value);
        }}
      />
    </div>
  );
};
