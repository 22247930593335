export const PermissionCodes = {
  administratorgroup: 'administratorgroup',
  administrator: 'administrator',

  brand: 'brand',
  carpark: 'carpark',
  mall: 'mall',
  freshmarket: 'freshmarket',
  store: 'store',
  storecategory: 'storelevel1category',
  storecategoryLv3: 'storelevel3category',
  storecategoryLv4: 'storelevel4category',

  campaign: 'campaign',
  campaigncategory: 'campaigncategory',
  campaigntag: 'campaigntag',
  coupontemplate: 'coupontemplate',
  coupon: 'coupon',
  earningrule: 'earningrule',
  message: 'message',

  customer: 'customer',
  customergroup: 'customergroup',
  segment: 'segment',

  webContent: 'webviewcontent',
  appVersion: 'appversionmodel',

  pointtransaction: 'pointtransaction',
  transaction: 'transaction',
  coupontransaction: 'coupontransaction',
  stamprecord: 'stamprecord',
  badgerecord: 'badgerecord',
  conciergecoupontransaction: 'conciergecoupontransaction',
  conciergetransaction: 'conciergetransaction',

  districtmallmapping: 'districtmallmapping',
  viewDistrictMallMapping: 'view_districtmallmapping',
  changeDistrictMallMapping: 'change_districtmallmapping',

  addAdministratorGroup: 'add_administratorgroup',
  changeAdministratorGroup: 'change_administratorgroup',
  deleteAdministratorGroup: 'delete_administratorgroup',
  // view_administratorgroup
  addAdministrator: 'add_administrator',
  changeAdministrator: 'change_administrator',
  deleteAdministrator: 'delete_administrator',
  viewAdministrator: 'view_administrator',

  //   addCustomer: 'add_customer',
  changeCustomer: 'change_customer',
  deleteCustomer: 'delete_customer',
  viewCustomer: 'view_customer',

  addSegment: 'add_segment',
  changeSegment: 'change_segment',
  deleteSegment: 'delete_segment',
  viewSegment: 'view_segment',

  addCustomerGroup: 'add_customergroup',
  changeCustomerGroup: 'change_customergroup',
  deleteCustomerGroup: 'delete_customergroup',
  // viewCustomerGroup: 'view_customergroup',

  addCampaign: 's1_create_campaign',
  changeCampaign: 'change_campaign',
  deleteCampaign: 'delete_campaign',
  viewCampaign: 'view_campaign',
  publishCampaign: 'publish_campaign',
  unPublishCampaign: 'unpublish_campaign',
  unPublishCampaignToS3: 's4_withdraw_campaign_to_s3',
  unPublishCampaignToS2: 's4_withdraw_campaign_to_s2',
  unPublishCampaignToS1: 's4_withdraw_campaign_to_s1',
  changeCampaignForS1: 's1_change_campaign',
  changeCampaignForS2: 's2_change_campaign',
  changeCampaignForS3: 's3_change_campaign',
  peddingCampaign: 's1_pending_campaign',
  approvalCampaign: 's2_approve_campaign',
  publishCampaignForS3: 's3_publish_campaign',
  withdrawCampaignForS2: 's2_withdraw_campaign_to_s1',
  withdrawCampaignForS3ToS2: 's3_withdraw_campaign_to_s2',
  withdrawCampaignForS3ToS1: 's3_withdraw_campaign_to_s1',
  tempForPulished: 's4_create_temp_campaign',

  addCampaignCategory: 'add_campaigncategory',
  changeCampaignCategory: 'change_campaigncategory',
  deleteCampaignCategory: 'delete_campaigncategory',
  viewCampaignCategory: 'view_campaigncategory',

  addCampaignTag: 'add_campaigntag',
  changeCampaignTag: 'change_campaigntag',
  deleteCampaignTag: 'delete_campaigntag',
  viewCampaignTag: 'view_campaigntag',

  addEarningRule: 'add_earningrule',
  changeEarningRule: 'change_earningrule',
  deleteEarningRule: 'delete_earningrule',
  // viewEarningRule: 'view_earningrule',

  addMessage: 's1_create_message',
  changeMessage: 'change_message',
  deleteMessage: 'delete_message',
  viewMessage: 'view_message',
  unPublishMessageToS3: 's4_withdraw_message_to_s3',
  unPublishMessageToS2: 's4_withdraw_message_to_s2',
  unPublishMessageToS1: 's4_withdraw_message_to_s1',
  changeMessageForS1: 's1_change_message',
  changeMessageForS2: 's2_change_message',
  changeMessageForS3: 's3_change_message',
  peddingMessage: 's1_pending_message',
  approvalMessage: 's2_approve_message',
  publishMessageForS3: 's3_publish_message',
  withdrawMessageForS2: 's2_withdraw_message_to_s1',
  withdrawMessageForS3ToS2: 's3_withdraw_message_to_s2',
  withdrawMessageForS3ToS1: 's3_withdraw_message_to_s1',
  tempForMessagePulished: 's4_create_temp_message',

  addCouponTemplate: 'add_coupontemplate',
  changeCouponTemplate: 'change_coupontemplate',
  deleteCouponTemplate: 'delete_coupontemplate',
  viewCouponTemplate: 'view_coupontemplate',
  addCoupon: 'add_coupon',
  changeCoupon: 'change_coupon',
  deleteCoupon: 'delete_coupon',
  viewCoupon: 'view_coupon',

  //single coupon approval
  pendingSingleCoupon: 's1_pending_coupon',
  approvalSingleCoupon: 's2_approve_coupon',
  publishSingleCoupon: 's3_publish_coupon',
  withdrawSingleCouponForS2: 's2_withdraw_coupon_to_s1',
  withdrawSingleCouponForS3ToS2: 's3_withdraw_coupon_to_s2',
  withdrawSingleCouponForS3ToS1: 's3_withdraw_coupon_to_s1',
  reclaimSingleCouponToS1: 's4_withdraw_coupon_to_s1',
  reclaimSingleCouponToS2: 's4_withdraw_coupon_to_s2',
  reclaimSingleCouponToS3: 's4_withdraw_coupon_to_s3',

  addBrand: 'add_brand',
  changeBrand: 'change_brand',
  deleteBrand: 'delete_brand',
  //   viewBrand: 'view_brand',

  viewCarPark: 'view_carpark',
  changeCarPark: 'change_carpark',

  changeMall: 'change_mall',
  viewMall: 'view_mall',

  viewFreshMarket: 'view_freshmarket',

  addStore: 'add_store',
  changeStore: 'change_store',
  deleteStore: 'delete_store',
  viewStore: 'view_store',

  addStorecategory: 'add_storelevel1category',
  changeStorecategory: 'change_storelevel1category',
  deleteStorecategory: 'delete_storelevel1category',
  viewSorecategory: 'view_storelevel1category',

  addStorecategoryLv3: 'add_storelevel4category',
  changeStorecategoryLv3: 'change_storelevel3category',
  deleteStorecategoryLv3: 'delete_storelevel3category',
  viewStorecategoryLv3: 'view_storelevel3category',

  addStorecategoryLv4: 'add_storelevel4category',
  changeStorecategoryLv4: 'change_storelevel4category',
  deleteStorecategoryLv4: 'delete_storelevel4category',
  viewStorecategoryLv4: 'view_storelevel4category',

  addTransaction: 'add_transaction',
  changeTransaction: 'change_transaction',
  deleteTransaction: 'delete_transaction',
  viewTransaction: 'view_transaction',

  addWebContent: 'add_webviewcontent',
  changeWebContent: 'change_webviewcontent',
  deleteWebContent: 'delete_webviewcontent',
  viewWebContent: 'view_webviewcontent',

  viewAppVersion:'view_appversionmodel',
  changeAppVersion:'change_appversionmodel',

  //   addCouponTransaction: 'add_coupontransaction',
  //   changeCouponTransaction: 'change_coupontransaction',
  //   deleteCouponTransaction: 'delete_coupontransaction',
  viewCouponTransaction: 'view_coupontransaction',

  viewConciergecoupontransaction: 'view_conciergecoupontransaction',
  viewConciergetransaction: 'view_conciergetransaction',
  changeConciergetransaction: 'change_conciergetransaction',

  addPointtransaction: 'add_pointtransaction',
  viewPointtransaction: 'view_pointtransaction',
  changePointtransaction: 'change_pointtransaction',

  // publishCampaign: 'publishcampaign',
  // unPublishCampaign: 'unpublishcampaign',

  addExportjob: 'add_exportjob',
  addImportjob: 'add_importjob',

  addCustomerPoints: 'add_customer_points',
  removeCustomerPoints: 'remove_customer_points',
  testMessage: 'test_message',

  // home manage

  banners: 'homepagebanner',
  viewBanner: 'view_homepagebanner',
  changeBanner: 'change_homepagebanner',

  language: 'language',
  viewLanguage: 'view_language',
  changeLanguage: 'change_language',

  themedSection: 'themesection',
  viewThemedSection: 'view_themesection',
  changeThemedSection: 'change_themesection',

  // concierge app
  // transactionRecord: 'transactionRecord',
  // addTransactionRecord: 'add_transactionrecord',
  // deleteTransactionRecord: 'delete_transactionrecord',
  // viewTransactionRecord: 'view_transactionrecord',
  // changeTransactionRecord: 'change_transactionrecord',

  // couponRecord: 'couponRecord',
  // addCouponRecord: 'add_couponrecord',
  // deleteCouponRecord: 'delete_couponrecord',
  // viewCouponRecord: 'view_couponrecord',
  // changeCouponRecord: 'change_couponrecord',

  // record
  viewStampRecord: 'view_stamprecord',

  viewBadgeRecord: 'view_badgerecord',
};
