import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { useDispatch, connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createAction } from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import {
  CheckStatus,
  SavedStatus,
  LanguageConfig,
} from '../../../config/CustomEnums';
import { PermissionCodes } from '../../../config/PermissionCodes';
import {
  getPreviewLayoutImages,
  defaultPreviewImage,
} from '../../../components/mall/MallPreviewConfig';
import BasePrompt from '../../../components/base/prompt/BasePrompt';
import CreateMallStepOneContentSection from '../../../components/mall/CreateMallStepOneContentSection';

function CreateMallStepOne({ mall, checked, saved, languages }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const { getValues, trigger, formState } = useFormContext();
  const { errors } = formState;

  const inputWithImageConfig = getPreviewLayoutImages();
  const [activeSection, setActiveSection] = useState();

  useEffect(() => {
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
  }, [dispatch]);

  useEffect(() => {
    if (checked === CheckStatus.checkedWithSuccess) {
      dispatch(createAction('mall/createOrUpdate')({}));
    }
  }, [dispatch, checked]);

  useEffect(() => {
    if (
      saved === SavedStatus.savedWithSuccess &&
      history.location.pathname.includes('edit')
    ) {
      history.push('/malls');
    }
  }, [history, saved]);

  useEffect(() => {
    return () => {
      dispatch({ type: 'mall/clearData' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const id = params.id;
  useEffect(() => {
    if (id) {
      dispatch(createAction('mall/getOneDetail')({ id }));
    } else {
      dispatch({ type: 'mall/loadDataFromCookie' });
    }

    dispatch(createAction('mall/getDistrictList')({}));
  }, [dispatch, id]);

  const getLanguageTab = (language) => {
    return [
      [
        <CreateMallStepOneContentSection
          language={language}
          onFocus={setActiveSection}
        />,
      ],
    ];
  };

  const languageTabConfig = {
    enContainer: getLanguageTab(LanguageConfig.english),
    hantContainer: getLanguageTab(LanguageConfig.traditionalChinese),
    hansContainer: getLanguageTab(LanguageConfig.simplifiedChinese),
  };

  const getTabs = (language, setActiveSection) => {
    const tabs = [
      <CreateMallStepOneContentSection
        language={language}
        onFocus={setActiveSection}
      />,
    ];
    return tabs;
  };

  return (
    <>
      <ContentSections
        languageTabContent={{
          containers: languages?.map((item) => ({
            container: getTabs(item.code, setActiveSection),
            key: item.code,
            title: item.sourceName,
          })),
        }}
        inputWithImageConfig={inputWithImageConfig}
        defaultImage={defaultPreviewImage()}
        activeSection={activeSection}
      />
      <SaveAndBackButtons
        saveTempText={null}
        saveText={params.id ? 'Update' : 'Save'}
        backAction={() => history.goBack()}
        saveAction={async () => {
          let isValid = true;
          const [panoramicViewUrl] = getValues(['panoramicViewUrl']);
          const vaildFields = [];
          if (panoramicViewUrl.length) {
            panoramicViewUrl.forEach((item, index) => {
              vaildFields.push(`panoramicViewUrl.${index}.name`, `panoramicViewUrl.${index}.url`);
            });
            if (vaildFields.length) {
              isValid = await trigger(vaildFields);
            }
          }
          console.log('@83', isValid, getValues(), isValid);
          if (isValid) {
            dispatch(
              createAction('mall/updatePanoramicViewUrl')({
                ...getValues(),
                afterActions: () => history.push({ pathname: '/malls' }),
              }),
            );
          }
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  mall: state.mall.detail || {},
  checked: state.mall.checked,
  errorFields: state.mall.errorFields,
  saved: state.mall.saved,
  languages: state.language.allList,
});

export default connect(mapPropsToState)(CreateMallStepOne);
