import React, { useState, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import './CouponGeneral.scss';
import { useDispatch, connect } from 'react-redux';
import {
  LanguageConfig,
} from '../../../config/CustomEnums';
import { ReactHookFormErrorFieldMessage } from '../../base/ErrorFieldMessage';
import BaseMutipleSelectorV2 from '../../base/BaseMultipleSelectorV2';
import i18n from '../../../I18n';
import BasePrompt from '../../base/prompt/BasePrompt';
import {
  ReactHookFormErrorMessage,
} from '../../base/ErrorFieldMessage';
import { InputFieldControl2 } from '../../base/InputFieldControl';
import { ReactHookFormMarkDown } from '../../base/CustomMarkDownArea';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { SectionTopTitle } from '../../base/CommonComponent';

import SelectMultipleStore from '../../base/SelectMultipleStore';
import SelectMultipleBrand from '../../base/SelectMultipleBrand';
import SelectMultipleStoreV2 from '../../base/SelectMultipleStoreV2';

const CREATE_NEW_TYPE = {
  brand: 'brand',
  store: 'store',
  none: 'none',
};

const CouponGeneral = ({
  language,
  onFocus = (() => {}),
  brandList,
}) => {
  const [createNewType, setCreateNewType] = useState(CREATE_NEW_TYPE.none);
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;
  const createNewText =
    createNewType === CREATE_NEW_TYPE.brand ? 'brand' : 'store';
  const { register, watch, setValue, control, formState, clearErrors } =
    useFormContext();
  const { errors } = formState;
  const watchselectedDisplayBrand = watch("selectedDisplayBrand");
  const watchSelectMallStores = watch('selectMallStores') || [];
  const watchSelectFreshMarketStores = watch('selectFreshMarketStores') || [];
  const history = useHistory();
  
  useEffect(() =>{
    console.log("@80, get brand")
    dispatch({
      type: 'brand/getSimpleBrandList',
      payload: {
        // isAll: true
        isSelectorLoad: true,
        isSimpleFilter: true,
        sort: 'name',
        pageSize: 100
      }
    })
  }, [dispatch])

  const brandDisplaySection = (title, targetData, watchkey) =>  {
    // console.log("display rander")
    switch (language) {
      case LanguageConfig.english:
        return (
          <Controller control={control}
            name={watchkey}
            render={({
              field,
              fieldState: { error }
            }) => {
          return (<div>
            <BaseMutipleSelectorV2
              title={title}
              size={["shorter"]}
              onFocusFunc={() => {
                onFocus(watchkey);
              }}
              namespace="brandModel"
              data={{
                sourceData: brandList,
                targetData: targetData,
                targetChange: (value) => {
                  field.onChange(value)
                  // setValue(watchkey, value, {
                  //   shouldDirty: true,
                  // });
                },
              }}
              addButton={{
                action: () => {
                  setCreateNewType(CREATE_NEW_TYPE.brand);
                },
                title: 'Add Brand',
                requires: PermissionCodes.addStore,
                customClass:
                  'general-section-add-new btn-add-button-common',
              }}
              error={error}
            />
            <ReactHookFormErrorFieldMessage error={error} id={watchkey} />
          </div>)}} />
        )
      default:
        if (targetData && targetData?.length > 0) {
          return (
            <div>
              <div className="create-section-label create-section-label-bottom-space">
                {title}
              </div>
              {targetData.map((brand) => {
                return (
                  <div
                    className="second-section-content"
                    key={brand.name}
                  >
                    {brand.name}
                  </div>
                );
              })}
            </div>
          );
        } else {
          return (
            <div>
              <div className="create-section-label create-section-label-bottom-space">
                {title}
              </div>
              -
            </div>
          );
        }
    }
  }

    return (
      <>
      <SectionTopTitle title={i18n.t('general', { locale: language })} />
      <InputFieldControl2 
        name={`translations.${language}.name`}
        title={i18n.t('name', { locale: language })}
        onFocus={() => {
          onFocus('name');
        }}
      />
      <ReactHookFormMarkDown
        content={{
          label: i18n.t('short_description', { locale: language }),
          key: `translations.${language}.shortDescription`,
          focus: () => {
            onFocus('shortDescription')
          }
        }}
      />
      <ReactHookFormErrorMessage errors={errors} id={`translations.${language}.shortDescription`} />
      <SelectMultipleBrand
        disabled={language !== LanguageConfig.english}
        title={i18n.t('brand_display_option', {locale: language})}
        value={watchselectedDisplayBrand}
        onChange={(v) => setValue("selectedDisplayBrand", v, {shouldDirty: true})}
      />
      {/* {brandDisplaySection(i18n.t('brand_display', {locale: language}), watchselectedDisplayBrand, 'selectedDisplayBrand')} */}
      <SelectMultipleStoreV2
        title={'Applicable stores From mall'}
        value={watchSelectMallStores}
        onChange={(value) => {
          console.log(value)
          setValue("selectMallStores", value, {shouldDirty: true})
        }}
        disabled={language !== LanguageConfig.english}
        onFocus={() => {
          onFocus('selectedStores');
        }}
      />
      <SelectMultipleStoreV2
        title={'Applicable stores From fresh market'}
        value={watchSelectFreshMarketStores}
        onChange={(value) => {
          console.log(value)
          setValue("selectFreshMarketStores", value, {shouldDirty: true})
        }}
        disabled={language !== LanguageConfig.english}
        showLineBrank={true}
        filterByMall={false}
        onFocus={() => {
          onFocus('selectedStores');
        }}
      />
      { language === LanguageConfig.english ? <ReactHookFormErrorMessage errors={errors} id={`selectFreshMarketStores`} /> : null }
      <BasePrompt
        show={createNewType !== CREATE_NEW_TYPE.none}
        closeAction={() => {
          setCreateNewType(CREATE_NEW_TYPE.none);
        }}
        rightButton={{
          text: `Go to create ${createNewText}`,
          action: () => {
            setCreateNewType(CREATE_NEW_TYPE.none);
            const pathname =
              createNewType === CREATE_NEW_TYPE.brand
                ? '/brands/create'
                : '/stores/create';
            history.push({
              pathname: pathname,
              state: {
                from: history.location,
                title: 'Continue to create coupon?',
                content: 'You can continue to create the coupon.',
              },
            });
          },
        }}
        title={`Go to create a ${createNewText}?`}
        description={`You will leave the coupon creation process. After you create a new ${createNewText}, you can back to this page.`}
      />
    </>
  )
}


const mapPropsToState = (state) => ({
  brandList: state.brand.currentPageBrandList || [],
});

export default connect(mapPropsToState)(CouponGeneral);
