import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import CustomEditor from '../base/CustomEditor';
import CustomDateTimeSelect from '../base/CustomDateTimeSelect';
import { hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import { CustomTitleLabel } from '../../components/earning/CustomBaseComponments';
import { PermissionCodes } from '../../config/PermissionCodes';
import TransactionStepOneDropdownSection from './TransactionStepOneDropdownSection';
import CustomRadios from '../base/CustomRadios';
import QRScan from './scanner';
import CustomTransactionWithIcon from './CustomTransactionWithIcon';
import Scanner from '../../assets/images/scanner.svg';
import { zhHK } from 'date-fns/esm/locale';
import { registerLocale } from 'react-datepicker';
import { FORM_TYPE } from '../../config/CustomEnums';
import i18n from '../../I18n';
import ReceiptSection from './ReceiptSection';
import './ReceiptSection.scss';
import AuthButton from '../base/AuthButton';

function TransactionGeneralSection({ language, isTCTransaction }) {
  const dispatch = useDispatch();
  const location = useLocation();

  const { watch, setValue, formState, setError, clearErrors } = useFormContext();
  const { errors } = formState;

  const customerIDField = 'customerID';
  const customerField = 'customer';
  const regionField = 'region';
  const districtField = 'district';
  const mallField = 'mall';
  const remarksField = 'remarks';
  const staffNameField = 'staffName';
  const formTypeField = 'formType';
  const receiptsField = 'receipts';

  const customerID = watch(customerIDField);
  const customer = watch(customerField);
  const selectedRegion = watch(regionField);
  const selectedDistrict = watch(districtField);
  const selectedMall = watch(mallField);
  const staffName = watch(staffNameField);
  const remarks = watch(remarksField);
  const formType = watch(formTypeField);
  const receipts = watch(receiptsField) || setValue("receipts", [{
    invoiceId: 9999999999,
    transactionDate: new Date(),
  }], { shouldDirty: true });

  const customerError = hasError(errors, customerIDField) || hasError(errors, customerField)

  const [isVisible, setIsVisible] = useState(false);
  const [isCallApi, setIsCallApi] = useState(false);
  if (isTCTransaction) {
    registerLocale('zhHK', zhHK);
  }
  const { pagedCustomerList } = useSelector(
    (state) => ({
      pagedCustomerList: state.customerList.pagedCustomerList,
    }),
  );

  useEffect(() => {
    if (!formType) {
      setValue(formTypeField, FORM_TYPE.trade, { shouldDirty: true });
    }
  }, [setValue, formType]);

  useEffect(() => {
    if (pagedCustomerList.length) {
      clearErrors(customerField);
      setValue(customerField, pagedCustomerList[0], { shouldDirty: true });
    } else if (isCallApi && !pagedCustomerList.length) {
      setError(customerField, {
        type: 'required',
        message: i18n.t('transaction.customer_error', { locale: language }),
      });
      setValue(customerField, '', { shouldDirty: true });
    }
  }, [setValue, clearErrors, setError, pagedCustomerList]);

  useEffect(() => {
    const getCustomerData = setTimeout(() => {
      if (customerID) {
        dispatch({
          type: 'customerList/getPagedCustomers',
          payload: {
            keyword: customerID,
            isSimpleNode: true,
          },
        });
        setIsCallApi(true);
      }
    }, 1000);

    return () => {
      clearTimeout(getCustomerData);
    };
  }, [dispatch, customerID]);

  return (
    <>
      {/* <div className="first-section-title">GENERAL</div> */}
      <>
        <CustomTitleLabel title={i18n.t('transaction.select_type_of_form', { locale: language })} />
        <div style={{ display: 'flex', marginTop: 8 }}>
          <CustomRadios
            onChange={(value) => {
              setValue(formTypeField, value, { shouldDirty: true });
              clearErrors();
              receipts.forEach((receipt, index) => {
                if (value === FORM_TYPE.trade) {
                  setValue(`receipts[${index}].submitNumber`, '', { shouldDirty: true });
                  setValue(`receipts[${index}].store`, '', { shouldDirty: true });
                  setValue(`receipts[${index}].paymentMethod`, '', { shouldDirty: true });
                  setValue(`receipts[${index}].invoiceId`, '', { shouldDirty: true });
                } else {
                  setValue(`receipts[${index}].totalValue`, '', { shouldDirty: true });
                  setValue(`receipts[${index}].invoiceId`, 0, { shouldDirty: true });
                }
              });
            }}
            default={formType}
            options={[
              {
                label: i18n.t('transaction.transaction', { locale: language }),
                value: FORM_TYPE.trade,
              },
              {
                label: i18n.t('transaction.non_transaction', { locale: language }),
                value: FORM_TYPE.notTrade,
              },
            ]}
          />
        </div>
        <ReactHookFormErrorMessage errors={errors} id={formTypeField} />
      </>
      <CustomTransactionWithIcon
        title={i18n.t('transaction.memberid/mobile_number', { locale: language })}
        defaultValue={customerID}
        useDefaultValue={false}
        setValue={(value) => {
          setValue(customerIDField, value, { shouldDirty: true });
          setValue(customerField, '', { shouldDirty: true });
        }}
        icon={Scanner}
        iconAction={() => setIsVisible(true)}
        firstShortDescription={`${i18n.t('transaction.member_id', { locale: language })} ${customer?.membershipId || 'N/A'}`}
        secondShortDescription={`${i18n.t('transaction.octopus_card', { locale: language })} ${customer?.octopusCards || 'N/A'} `}
        error={{ error: customerError }}
        type='number'
      />
      {isVisible ? (
        <QRScan
          show={isVisible}
          handleClose={() => setIsVisible(false)}
          setValue={(value) => {
            setValue(customerIDField, value, { shouldDirty: true });
          }}
        />
      ) : null}
    </>
  );
}

export default TransactionGeneralSection;
