import React, { useState } from 'react';
import BaseActionModal from '../coupon/couponList/BaseActionModalView';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import CustomEditor from '../base/CustomEditor';
import i18n from '../../I18n';

function TransactionActionPrompt(props) {
  const language = props.language;
  const event = props.event;
  const [recallRemarks, setRecallRemarks] = useState();
  const [error, setError] = useState(false);
  const show = props.show;
  const onHide = props.onHide || (() => {});
  const action = props.action || (() => {});

  const promptTitle = event
    ? i18n.t('transaction.confirm_recall_transaction', { locale: language })
    : '';
  const promptMessage = event
    ? i18n.t('transaction.provide_recall_remarks', { locale: language })
    : '';

  const promptRightButton = event
    ? i18n.t('transaction.confirm', { locale: language })
    : '';

  const handleOnHide = () => {
    onHide();
    setError(false);
  };

  const getActionPromptDetailItems = () => {
    return (
      <div className='d-flex flex-column'>
        <CustomTitleLabel
          title={i18n.t('transaction.recall_remarks', { locale: language })}
        />
        <textarea
          value={recallRemarks}
          onChange={({ target }) => {
            setRecallRemarks(target.value);
            setError(false);
          }}
          className={`customer-editor-container ${
            error ? 'editor-container-error' : ''
          }`}
        />
        {error ? (
          <label className="editor-container-error-message">
            {i18n.t('transaction.provide_recall_remarks', {
              locale: language,
            })}
          </label>
        ) : null}
      </div>
    );
  };

  return (
    <BaseActionModal
      className={'transaction-action-prompt'}
      showModal={show}
      onHide={handleOnHide}
      modalTitle={promptTitle}
      modalGeneralContent={promptMessage}
      modalDetailContent={getActionPromptDetailItems()}
      actionName={promptRightButton}
      cancelName={i18n.t('transaction.cancel', { locale: language })}
      action={() => {
        action(recallRemarks, setError, onHide);
      }}
      check={true}
    />
  );
}

export default TransactionActionPrompt;
