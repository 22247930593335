import React, { useEffect } from 'react';
import i18n from '../../I18n';
import ReceiptSection from './ReceiptSection';
import './ReceiptSection.scss';
import AuthButton from '../base/AuthButton';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

function TransactionInfoSection({ language, isTCTransaction }) {
  const dispatch = useDispatch();
  const { watch, setValue } = useFormContext();

  const mallField = 'mall';
  const formTypeField = 'formType';
  const receiptsField = 'receipts';
  const stampCampaignField = 'stampCampaign';
  const missionCampaignField = 'missionCampaign';
  const otherCampaignField = 'otherCampaign';
  const carParkCampaignField = 'carParkCampaign';

  const selectedMall = watch(mallField);
  const formType = watch(formTypeField);
  const receipts =
    watch(receiptsField) ||
    setValue(
      'receipts',
      [
        {
          invoiceId: 9999999999,
          transactionDate: new Date(),
        },
      ],
      { shouldDirty: true },
    );
  const stampCampaign = watch(stampCampaignField);
  const missionCampaign = watch(missionCampaignField);
  const otherCampaign = watch(otherCampaignField);
  const carParkCampaign = watch(carParkCampaignField);

  const addReceiptDisabled = () =>
    (stampCampaign || missionCampaign || otherCampaign) && carParkCampaign;

  useEffect(() => {
    dispatch({
      type: 'createTransactionRecord/getAllPaymentMethed',
      payload: {},
    });
  }, []);

  return (
    <div>
      <div className="receipt-section-container">
        {receipts?.map((receipt, index) => (
          <ReceiptSection
            language={language}
            isTCTransaction={isTCTransaction}
            selectedMall={selectedMall}
            formType={formType}
            title={i18n.t('transaction.receipt', {
              locale: language,
              index: index + 1,
            })}
            index={index}
            receipts={receipts}
          />
        ))}
        <div style={{ marginTop: '20px' }}>
          <AuthButton
            customClass="btn-back-button-common"
            title={i18n.t('transaction.add_receipt', { locale: language })}
            disabled={addReceiptDisabled()}
            action={() => {
              setValue(
                'receipts',
                [
                  ...receipts,
                  {
                    invoiceId: 9999999999,
                    transactionDate: new Date(),
                  },
                ],
                { shouldDirty: true },
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default TransactionInfoSection;
