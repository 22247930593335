import React from 'react';
import { Image } from 'react-bootstrap';
import './DisplayItems.scss';

const titleForItem = (title, moreStyle) => {
  return (
    <label
      className={`create-section-label create-section-label-bottom-space ${moreStyle}`}
    >
      {title}
    </label>
  );
};

export const listItem = (title, value, moreStyle, extraStyle) => {
  return (
    <>
      {titleForItem(title, moreStyle)}
      <label className="banners-detail-text" style={extraStyle}>{value || '-'}</label>
    </>
  );
};

export const listMultiItem = (title, values, moreStyle) => {
  let items = <label className="banners-detail-text">{'-'}</label>
  if (values && values.length) {
    items = values.map(
      (value) => <label className="banners-detail-text">{value || '-'}</label>
    );
  }
  return (
    <>
      {titleForItem(title, moreStyle)}
      {items}
    </>
  );
};

export const listImage = (title, src, moreStyle) => {
  return (
    <>
      {titleForItem(title, moreStyle)}
      <Image className="feature-campaign-detail-image" src={src} />
    </>
  );
};

export const horizontallyListMultiImageWithItem = (
  title,
  values,
  moreStyle,
  imageStyle,
) => {
  let items = <label className="banners-detail-text">-</label>;
  if (values && values.length) {
    items = values?.map((value) => (
      <div style={{ display: 'flex', flexDirection: 'row', margin: '10px 0' }}>
        <Image className={`${imageStyle}`} src={value.icon} />
        <label className="banners-detail-text">{value.name}</label>
      </div>
    ));
  }
  return (
    <>
      {titleForItem(title, moreStyle)}
      {items}
    </>
  );
};

export const verticalListMultiImageWithItem = (
  title,
  values,
  moreStyle,
  imageStyle,
) => {
  let items = <label className="banners-detail-text">-</label>;
  if (values && values.length) {
    items = values?.map((value) => (
      <>
        {!value.name && !value.icon ? (
          <label className="banners-detail-text">-</label>
        ) : (
          <>
            <label className="banners-detail-text">{value.name || '-'}</label>
            {value.icon ? (
              <Image className={`${imageStyle}`} src={value.icon} />
            ) : (
              <label className="banners-detail-text">-</label>
            )}
          </>
        )}
      </>
    ));
  }
  return (
    <>
      {titleForItem(title, moreStyle)}
      {items}
    </>
  );
};

export const listMultiImage = (title, values) => {
  let images = values.map((value) => (
    <Image
      src={value}
      style={{
        maxWidth: 191,
        maxHeight: 191,
        marginRight: '20px',
        marginBottom: '20px',
      }}
    />
  ));
  return (
    <>
      {titleForItem(title)}
      <div>
        {images}
      </div>
    </>
  );
};

export const listStoreCategories = (title, values, language) => {
  let items = <label className="banners-detail-text">-</label>;
  if (values && values.length) {
    items = values?.map((value) => (
      <>
        <label className="banners-detail-text">
          - {value.translations?.[language]?.name}
        </label>
        <ul>
          {value?.translations?.[language]?.subNames.map((subName, index) => (
            <li key={index} className="store-category-text">
              {subName || '-'}
            </li>
          ))}
        </ul>
      </>
    ));
  }
  return (
    <>
      {titleForItem(title)}
      {items}
    </>
  );
};

export const listVendors = (title, values, moreStyle) => {
  let items = <label className="banners-detail-text">-</label>;
  if (values && values.length) {
    items = values?.map((value) => (
      <ul>
        <li>
          <label className="banners-detail-text">{value.name}</label>
        </li>
      </ul>
    ));
  }
  return (
    <>
      {titleForItem(title, moreStyle)}
      {items}
    </>
  );
}

export const listStores = (title, values, moreStyle) => {
  let items = <label className="banners-detail-text">-</label>;
  if (values && values.length) {
    items = values?.map((value) => (
      <ul>
        <li>
          <label className="banners-detail-text">{value.label}</label>
        </li>
      </ul>
    ));
  }
  return (
    <>
      {titleForItem(title, moreStyle)}
      {items}
    </>
  );
}