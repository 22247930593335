import React, { useEffect } from 'react';
import { Button, Image } from 'react-bootstrap';
import './SelectMultipleStore.scss';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import closeIcon from '../../assets/images/close.svg';
import { useSelector } from 'react-redux';

function SelectMultipleCampaignsBySearch({
  campaignType = '',
  title = '',
  filterTitle = '',
  value = [],
  onChange = ()=> {},
  disabled = false,
  showLineBrank = false,
  source = null,
  onFocus = () => {},
  customFilter = null,
  hasMarginTop = true,
  needLoadMore = true,
  placeholder = null,
  multiple = true
}) {

  const getCampaignFilter = (campaignType) => {
    let campaignFilterKey = '';
    switch (campaignType){
      case 'MISSION':
        campaignFilterKey = 'isMissionCampaign';
        break
      case 'OTHER':
        campaignFilterKey = 'isOtherCampaign';
        break
      case 'CAR_PARK':
        campaignFilterKey = 'isCarParkCampaign';
        break
      default:
        campaignFilterKey = 'isStampCampaign';
          break
    }
    return {
      [campaignFilterKey]: true,
      allDisplayIn: true,
      isSelectorLoad: true,
      sort: 'name',
      mallTransactionFilter: [campaignType],
    }
  };

  const getCampaignKeys = (campaignType) => {
    let campaignListKey = '';
    switch (campaignType){
      case 'MISSION':
        campaignListKey = 'missionCampaignList';
        break
      case 'OTHER':
        campaignListKey = 'otherCampaignList';
        break
      case 'CAR_PARK':
        campaignListKey = 'carParkCampaignList';
        break
      default:
        campaignListKey = 'stampCampaignList';
          break
    }
    return campaignListKey
  };

  const { campaignList } = useSelector((state) => ({
    campaignList: state.campaignList?.[getCampaignKeys(campaignType.toUpperCase())],
  }));

  return (
    <div style={{width: '780px', marginTop: hasMarginTop ? '30px' : 0}}
      onFocus={onFocus}
    >
      {!disabled && showLineBrank ? <div className='line'></div> : null}
      {title ? <label className="create-section-title">{title}</label> : null}
      {disabled ? null : (
        <CustomTitleWithDropDown 
          customClass={'filter-title'}
          title={filterTitle || `Search ${campaignType} Campaign`}
          source={source || campaignList}
          placeholder={placeholder || 'Search by campaign name'}
          labelContainPk={true}
          defaultValue={value ? value : []}
          setValue={(v) => {
            onChange(v);
          }}
          loadMoreAction={needLoadMore ? 'campaignList/getMallCampaignListForTransaction' : ''}
          customFilter={customFilter}
          filterAction={'campaignList/getMallCampaignListForTransaction'}
          defaultFilter={getCampaignFilter(campaignType.toUpperCase())}
          multiple={multiple}
          toolTips={true}
          notShowLabel={true}
          disableAutoFocus={true}
        />
      )}
      <div className='title-container'>
        <CustomTitleLabel 
          title={`Summary of selected ${campaignType} campaigns (${value.length})`}
        />
        {disabled ? null :  <Button className="clear-filter" style={{position: "relative", marginBottom:"7px"}}
          onClick={() => {
            console.log(`clear mall select ${campaignType} campaign filter click`)
            onChange()
          }}
        >Reset</Button>}
      </div>
      {value && value?.length > 0 ? 
      value?.map((item, index) => {
        return <div className='store-list-item-container'>
          <label className='store-list-item'>
          {item.label}
          </label>
          {disabled ? null :<Image
            src={closeIcon}
            className="list-item-delete"
            onClick={ (e) =>{
              e.stopPropagation();
              const newValue = value?.filter((filterItem) => filterItem.value.pk != item.value.pk)
              onChange(newValue)
            }}
          />}
        </div>
      })
      : '-'}
    </div>
  );
}

export default SelectMultipleCampaignsBySearch;