import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { ArrayStringData } from '../../../utils';
import { LanguageConfig, STAMP_EVENT_TYPE } from '../../../config/CustomEnums';
import i18n from '../../../I18n';
import { getConciergeLanguage } from '../../conciergeApp/couponRecord/CouponTransactionRecord';
import locale from 'antd/lib/date-picker/locale/zh_TW';

function Filter({ backAction = () => {} }) {
  const location = useLocation();
  const language = getConciergeLanguage(location);
  let isConcierge = false;
  if (language === LanguageConfig.traditionalChinese) {
    isConcierge = true;
  }

  const parsedSearch = queryString.parse(location.search);
  const searchEventType = parsedSearch['event_type'] || '';
  const searchRecordType = parsedSearch['record_type'] || '';
  const searchQuantity = parsedSearch['quantity'] || '';
  const searchCreateDate = parsedSearch['create_date'] || '';

  const { maxQuantityValue } = useSelector((state) => ({
    maxQuantityValue: state.stampRecord.maxQuantityValue,
  }));

  const [eventType, setEventType] = useState(searchEventType);
  const [recordType, setRecordType] = useState(
    ArrayStringData(searchRecordType),
  );
  const [quantity, setQuantity] = useState(() => {
    const values = ArrayStringData(searchQuantity);
    if (values.length > 0) return values;
    return [0, 0];
  });
  const [createDate, setCreateDate] = useState(searchCreateDate);

  const content = [
    {
      title: 'Event type',
      displayName: i18n.t('transaction.event_type', { locale: language }),
      data: [
        {
          pk: 'ADD_STAMP',
          name: i18n.t('concierge.add_stamp', { locale: language }),
        },
        {
          pk: 'REMOVE_STAMP',
          name: i18n.t('concierge.remove_stamp', { locale: language }),
        },
      ],
      value: eventType,
      setValue: setEventType,
      componment: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Record type',
      displayName: i18n.t('couponRecords.record_type', { locale: language }),
      data: [
        { name: i18n.t('concierge.customer_earned', { locale: language }), pk: 'CUSTOMER_EARN' },
        { name: i18n.t('concierge.customer_use', { locale: language }), pk: 'CUSTOMER_USE' },
        { name: i18n.t('concierge.admin_removed', { locale: language }), pk: 'ADMIN_REMOVE' },
        { name: i18n.t('concierge.stamp_expired', { locale: language }), pk: 'STAMP_EXPIRED' },
      ],
      value: recordType,
      setValue: setRecordType,
      componment: FilterTypeEnum.choice,
    },
    {
      title: 'Create date',
      displayName: i18n.t('concierge.create_date', { locale: language }),
      value: createDate,
      setValue: setCreateDate,
      componment: FilterTypeEnum.dateRange,
      locale: isConcierge ? locale : '',
    },
    {
      title: 'Quantity',
      displayName: i18n.t('concierge.quantity', { locale: language }),
      data: [0, maxQuantityValue],
      value: quantity,
      setValue: setQuantity,
      componment: FilterTypeEnum.slider,
    },
  ];

  return (
    <BaseFilter
      content={content}
      backAction={backAction}
      filterText={i18n.t('transaction.filter', { locale: language })}
      resetAllText={i18n.t('transaction.reset_all', { locale: language })}
    />
  );
}

export default Filter;
