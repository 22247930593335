import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import i18n from '../../I18n';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import './MessageGeneralSection.scss';
import { ReactHookFormImageUploader } from '../base/ImageUploader';
import CustomEditor from '../base/CustomEditor';
import CustomTitleWithInput from '../../components/base/CustomTitleWithInput';
import { hasError, errorMessage, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import { MessageChannel } from '../../config/CustomEnums';
import { useLocation } from 'react-router-dom';


function MessageGeneralSection({
  language
}) {
  const location = useLocation();

  const { watch, setValue, getValues, onFocus, formState, clearErrors } = useFormContext();
  const { errors } = formState;

  const messageTitleField = `translations.${language}.name`;
  const messageContentField = `translations.${language}.content`;
  const messagePhotoField = `translations.${language}.photo`;

  const name = watch(messageTitleField);
  const content = watch(messageContentField);
  const photo = watch(messagePhotoField);
  const [channels, relatedCampaign] = getValues(['channels', 'relatedCampaign']);
  const isSelectFullScreenAd = channels.indexOf(MessageChannel.fullScreenAd) !== -1;
  const uploadSuggest = i18n.t(isSelectFullScreenAd ? 'full_screen_ad_photo_suggest' : 'message_photo_suggest', { locale: language })

  useEffect(() => {
    if (location.pathname.includes('edit')) {
      return;
    }
    if (!name && relatedCampaign?.pk) {
      setValue(messageTitleField, relatedCampaign?.translations[language]?.name, { shouldDirty: true });
    }
  }, [])

  return (
    <>
      <label className="create-section-title">
        {i18n.t('general', { locale: language })}
      </label>
      <CustomTitleLabel
        title={i18n.t('title(optional)', { locale: language })}
      />
      <label className="create-message-suggest">
        {i18n.t(isSelectFullScreenAd ? 'use_in_fullscreen_ad' : 'use_in_inbox_push', { locale: language })}
      </label>
      <CustomTitleWithInput
        // title={i18n.t(isSelectFullScreenAd ? 'use_in_fullscreen_ad' : 'use_in_inbox_push', { locale: language })}
        type={'text'}
        customClass={` text-input-field `}
        defaultValue={name}
        setValue={(value) => {
          setValue(messageTitleField, value, { shouldDirty: true });
        }}
        error={{ error: hasError(errors, messageTitleField) }}
      />
      <ReactHookFormErrorMessage errors={errors} id={messageTitleField} />

      {isSelectFullScreenAd ? null : (
        <>
          <CustomTitleLabel title={i18n.t('content', { locale: language })} />
          <label className="create-message-suggest">
            {i18n.t('use_in_inbox_push', { locale: language })}
          </label>
          <textarea  
            value={content}
            className="text-input-area text-input-field"
            onChange={({target}) => {
              console.log('@@75');
              setValue(messageContentField, target.value, { shouldDirty: true });
            }}
          />
          <ReactHookFormErrorMessage errors={errors} id={messageContentField} />
        </>
      )}

      <ReactHookFormImageUploader
        title={i18n.t(isSelectFullScreenAd ? 'image' : 'image(optional)', { locale: language })}
        uploadSuggest={uploadSuggest}
        name={messagePhotoField}
        minWidth={isSelectFullScreenAd ? 600 : 560}
        minHeight={isSelectFullScreenAd ? 900 : 420}
        value={photo}
        aspect={isSelectFullScreenAd ? 2 / 3 : 4 / 3}
        sizeLimit={1}
        language={language}
        uploadImageClicked={() => {}}
      />
    </>
  );
}

export default MessageGeneralSection;
