import React from 'react';
import FilterableDropdown from './FilterableDropdown';
import './BaseRecentlySelector.scss'
import Loading from './Loading';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import ErrorFieldMessage, { hasError } from './ErrorFieldMessage';
import FilterableDropdownWithoutLabel from './FilterableDropdownWithoutLabel';
import { Image } from 'react-bootstrap';

export default function BaseRecentlySelector(props) {
  const optionContainerStyle = props.optionContainerStyle || '';
  const optionStyle = props.optionStyle || '';
  const defaultValue = props.defaultValue || null;
  const title = props.title;
  const source = props.source || [];
  const setValue = props.setValue || (() => {});
  const errors = props.errors || [];
  const errorName = props.errorName || props.title?.toLowerCase();
  const errorMessage = props.errorMessage;

  const notShowLabel = props.notShowLabel || false;
  const customItem = props.customItem || (() => {});

  const existError = Array.isArray(errors) ? errors.includes(errorName) : hasError(errors, errorName);

  const filterComponent = {};
  filterComponent.name = notShowLabel ? FilterableDropdownWithoutLabel : FilterableDropdown;

  const checkSelected = (value) => {
    return defaultValue?.pk === value?.pk;
  };

  const options = source?.map((item) => {
    return {
      value: item,
      icon: item?.icon,
      label: customItem(item) ? customItem(item) : item?.name,
      disabled: item?.disabled ? item?.disabled : false,
    };
  });

  return (
    <div style={{ display: 'grid', width: '100%' }}>
      {title ? (
        <CustomTitleLabel className={props.titleStyle} title={title} />
      ) : null}
      <div className={`option-container ${optionContainerStyle}`} >
        {options.map((item, index) => (
          <button
            key={`button-${index}`}
            type='button'
            className={`option ${item.disabled ? 'option-disabled' : ''} ${checkSelected(item.value) ? 'option-selected' : ''} ${optionStyle}`}
            onClick={() => {
              setValue(item);
            }}
            disabled={item.disabled}
          >
            {item.icon && <Image src={item.icon} className='option-icon' />}
            <label className='option-label'>{item.label}</label>
          </button>
        ))}
      </div>
      {errors ? (
        <ErrorFieldMessage
          error={existError}
          id={errorName}
          message={errorMessage || `Please provide ${errorName}`}
          extra={'list-new-line'}
        />
      ) : null}
    </div>
  );
}
