import { ActionType, ApprovalStatus } from '../config/CustomEnums';
import { getInFilterValue, getSearchKey } from '../utils';
import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getMessages = (
  afterCursor = '',
  rank = true,
  search = '',
  otherSearch = {},
) => {
  let orderBy = '-displayId';
  if (!rank) {
    orderBy = 'displayId';
  }
  if (otherSearch?.sort) {
    orderBy = otherSearch.sort;
  }

  let moreSearch = '';
  if (otherSearch.message_channel) {
    moreSearch += `, channelsIn: ${getInFilterValue(otherSearch.message_channel)}`;
  }
  if (otherSearch.delivery_time) {
    // const deliveryTimeRange = otherSearch.delivery_time.split(',');
    // moreSearch += `, scheduledDateGte: "${deliveryTimeRange[0]}", scheduledDateLte: "${deliveryTimeRange[1]}"`;
    moreSearch += `, scheduledDateEqual: "${otherSearch.delivery_time}"`;
  }
  if (otherSearch.status) {
    moreSearch += `, statusIn: ${getInFilterValue(otherSearch.status)}`;
  }

  const query = `
    {
        messages(first: 20, after:"${afterCursor}", orderBy: "${orderBy}", titleIcontains: "${getSearchKey(search)}" ${moreSearch}) {
          totalCount
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          edges {
            cursor
            node {
              id
              pk
              displayId
              sendingState
              approvalStatus
              # isDraft
              channels {
                  edges {
                      node {
                          pk
                      }
                  }
              }
              scheduledDate
              scheduledPeriodStartDate
              scheduledPeriodEndDate
              relatedContent
              title
              targetedCustomerGroups {
                  edges {
                      node {
                          pk
                          name
                      }
                  }
              }
              campaign {
                  pk
                  name
              }
            }
          }
        }
      }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getMessage = (id) => {
  const query = `
  {
    message(id: "${id}") {
      id
      pk
      channels {
          edges {
              node {
                  pk
              }
          }
      }
      scheduledDate
      scheduledPeriodStartDate
      scheduledPeriodEndDate
      relatedContent
      title
      content
      photo
      url
      isExclusive
      approvalStatus
      targetedCustomerGroups {
          edges {
              node {
                  pk
                  name
              }
          }
      }
      targetedSegments {
          edges {
              node {
                  pk
                  name
              }
          }
      }
      campaign {
          pk
          name
      }
      sendingState
      translations {
          edges {
              node {
                  language
                  title
                  content
                  photo
                  id
                  pk
              }
          }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createMessage = (inputBody) => {
  const query = `
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      node {
          id
          pk
          channels {
              edges {
                  node {
                      pk
                  }
              }
          }
          scheduledDate
          scheduledPeriodStartDate
          scheduledPeriodEndDate
          relatedContent
          title
          content
          photo
          url
          isExclusive
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteMessages = (ids) => {
  const query = `
  mutation DeleteMessages($input: DeleteMessagesInput!) {
    deleteMessages(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids: ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const duplicateMessage = (id) => {
  const query = `
  mutation CopyMessage($input: CopyMessageInput!) {
    copyMessage(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      id: id,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateMessage = (inputBody) => {
  const query = `
  mutation UpdateMessage($input: UpdateMessageInput!) {
    updateMessage(input: $input) {
      node {
          id
          pk
          scheduledDate
          scheduledPeriodStartDate
          scheduledPeriodEndDate
          relatedContent
          title
          content
          photo
          url
          isExclusive
          sendingState
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const sendTestMessageBeforeCreate = (inputBody) => {
  const query = `
  mutation TestMessageBeforeCreation($input: TestMessageBeforeCreationInput!) {
    testMessageBeforeCreation(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const sendTestMessageBeforeUpdate = (inputBody) => {
  const query = `
  mutation TestMessageBeforeUpdate($input: TestMessageBeforeUpdateInput!) {
    testMessageBeforeUpdate(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createTempMessage = (campaign) => {
  const query = `mutation CreateTempMessage($input: CreateTempMessageInput!) {
    createTempMessage(input: $input) {
      success
      node {
        id
        pk
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: {
      ...campaign,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateApprovalStatus = (input, approvalStatus, actionType) => {
  let mutation = null
  switch (actionType) {
    case ActionType.publish:
      mutation = 'PublishMessage'
      break
    case ActionType.unpublish:
      mutation = 'UnpublishMessage'
      break
    case ActionType.approval:
      mutation = 'ApproveMessage'
      break
    case ActionType.submitForApproval:
      mutation = 'PendingForApprovalMessage'
      break
    case ActionType.withdraw:
      if (approvalStatus === ApprovalStatus.pendingForApproval){
        mutation = 'WithdrawPendingForApprovalMessage'
      } else {
        mutation = 'WithdrawApprovedMessage'
      }
      break
    default:
      mutation = null
      break
  }
  console.log("@663 mutation", mutation)
  if (!mutation) {
    return
  }

  const query = `mutation ${mutation}($input: ${mutation}Input!) {
    ${mutation.charAt(0).toLowerCase()}${mutation.slice(1)}(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
}

export const rejectPendingForPublishMessage = (input) => {
  const query = `
  mutation RejectPendingForPublishMessage($input: RejectPendingForPublishMessageInput!) {
    rejectPendingForPublishMessage(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const rejectPendingForApprovalMessage = (input) => {
  const query = `
  mutation RejectPendingForApprovalMessage($input: RejectPendingForApprovalMessageInput!) {
    rejectPendingForApprovalMessage(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
