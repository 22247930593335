import React, { useState, useEffect } from 'react';
import BasePrompt from '../../base/prompt/BasePrompt';
import CreateCategoryPrompt from './CreateCategoryPrompt';
import { CampaignCategoryType, CampaignCategoryTypeChoice, CampaignDisplayIn, CampaignType, CampaignPromotionType } from '../../../config/CustomEnums';
import './PropertiesGeneralSection.scss';
import { useSelector, useDispatch, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CustomRadios from '../../base/CustomRadios';
import CustomAddRemoveMutipleSelector from '../../customer/CustomAddRemoveMutipleSelector';
import {
  CampaignErrorHandleField,
  isShowError,
  firstError,
} from './CreateCampaignHandleError';
import { Row } from 'react-bootstrap';
import { GENERAL_TYPE } from '../../../models/CreateCampaignModel';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { ContinueCreate } from '../CampaignUrlConfig';
import { useFormContext } from 'react-hook-form';
import { hasError, errorMessage, ReactHookFormErrorMessage } from '../../base/ErrorFieldMessage';
import BaseMultipleSelectorV2 from '../../base/BaseMultipleSelectorV2';
import InputFieldControl from '../../base/InputFieldControl';
import { CustomTitleWithDropDown } from '../../customer/CustomTitleWithDropDown';
// import { CampaignPromotionTypeConfig } from './CreateCampaignStepOneConfigs';

const options = (source) => {
  if (!source) {
    return [];
  }
  return source.map((item) => {
    return {
      name: item.name,
      value: item,
    };
  });
};

const getRadioOptions = () => [
  {
    label: GENERAL_TYPE.customerGroup,
    value: GENERAL_TYPE.customerGroup,
  },
  {
    label: GENERAL_TYPE.segment,
    value: GENERAL_TYPE.segment,
  },
];

function PropertiesGeneralSection({
  groups,
  segments,
  categories,
  campaignTagsList,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { watch, setValue, formState } = useFormContext();
  const errors = formState.errors;
  const campaignCategories = watch('categories') || [];
  const campaignSegments = watch('segments') || [];
  const customerGroup = watch('customerGroup') || [];
  const campaignType = watch('campaignType');
  const isExclusive = watch('isExclusive');
  const generalType = watch('generalType');
  const watchEarningRules = watch('linkedEarningRules')
  const birthdayStartDate = watchEarningRules?.birthdayTypePeriodStartDate
  const birthdayEndDate = watchEarningRules?.birthdayTypePeriodEndDate
  const requiredStamps = watch('requiredStamps');
  const campaignTags = watch('campaignTags') || [];
  const watchDisplayIn = watch('displayIn');
  // const watchCampaignPromotionType = watch('campaignPromotionType');

  const isInterestClub = watchDisplayIn === CampaignDisplayIn.interestClub;
  const category = Array.isArray(campaignCategories) ? campaignCategories[0] : campaignCategories;

  const [showAddPromt, setShowAddPromt] = useState(false)

  useEffect(() => {
    dispatch({
      type: 'campaignCategoryList/getCampaignCategoryList',
      payload: campaignCategoryDefaultFilter,
    });
    dispatch({
      type: 'segments/getSegmentsList',
      payload: { status: true , ...segmentConfig.defaultFilter, isSelectorLoad: true, isAll: true},
    });
    dispatch({
      type: 'customerGroup/getGroupList',
      payload: {
        isSimpleList: true,
      },
    });
    dispatch({
      type: 'campaignTag/getAllList', 
      payload: { status: true }
    });
  }, [dispatch]);

  const getFilterCampaignCategory = () => {
    switch (watchDisplayIn) {
      case CampaignDisplayIn.interestClub:
        return `${CampaignCategoryType.INTEREST_CLUB}`
      case CampaignDisplayIn.freshMarketCommunity:
        return `${CampaignCategoryType.GENERAL},${CampaignCategoryType.FRESH_MARKET}`
      default:
        return `${CampaignCategoryType.GENERAL}`
    }
  }

  const campaignCategoryDefaultFilter = {
    status: true,
    typeIn: getFilterCampaignCategory(),
  };

  const segmentConfig = {
    title: 'Target segments',
    data: segments,
    selectedList: campaignSegments,
    setValue: (option) => {
      setValue('segments', option, {shouldDirty: true})
    },
    // sourceReminderText: 'All item(s)',
    // amidReminderText: 'Select item(s)',
    addButton: {
      title: 'Add Segments',
      action: () => {
        setShowAddPromt(customerSegmentPromtConfig);
      },
    },
    requires: PermissionCodes.addSegment,
    // error: { errors, message: CampaignErrorHandleField.segment.message },
    // selectAllTitle: 'all segments',
    loadMoreAction: 'segments/getSegmentsList',
    errorIdKey: 'segments',
    defaultFilter:
      birthdayStartDate && birthdayEndDate
        ? {
            dateOfBirthRange: [birthdayStartDate, birthdayEndDate],
          }
        : {},
  };

  const customerGroupConfig = {
    title: 'Target customer groups',
    namespace: 'customerGroupsList',
    data: groups,
    selectedList: customerGroup,
    setValue: (option) => {
      setValue('customerGroup', option, {shouldDirty: true})
    },
    // sourceReminderText: 'All item(s)',
    // amidReminderText: 'Select item(s)',
    addButton: {
      title: 'Add Customer Group',
      action: () => {
        setShowAddPromt(customerGroupPromtConfig);
      },
    },
    requires: PermissionCodes.addCustomerGroup,
    // error: { errors, message: CampaignErrorHandleField.customerGroup.message },
    // selectAllTitle: 'all customer groups',
    loadMoreAction: 'customerGroup/getCurrentPageList',
    errorIdKey: 'customerGroup',
  };

  const campaignCategoryConfig = {
    title: 'Campaign category (optional)',
    namespace: 'campaignCategoryList',
    data: categories,
    selectedList: campaignCategories,
    setValue: (option) => {
      setValue('categories', option, {shouldDirty: true})
    },
    // sourceReminderText: 'Select categories',
    addButton: {
      title: 'Add Campaign Category',
      action: () => {
        setShowAddPromt(campaignCategoryPromtConfig);
      },
    },
    requires: PermissionCodes.addCampaignCategory,
    errorIdKey: 'categories',
    // selectAllTitle: 'all campaign categories',
    // loadMoreAction: 'campaignCategoryList/getCampaignCategoryList',
    // defaultFilter: { status: true },
    groupKey: watchDisplayIn === CampaignDisplayIn.interestClub || watchDisplayIn === CampaignDisplayIn.freshMarketCommunity ? 'type' : null,
    groupKeyChoice: CampaignCategoryTypeChoice
  };
  const campaignTagConfig = {
    title: 'Campaign tag (Optional)',
    namespace: 'campaignTagList',
    data: campaignTagsList,
    selectedList: campaignTags,
    setValue: (option) => {
      setValue('campaignTags', option, {shouldDirty: true})
    },
    // sourceReminderText: 'Select categories',
    addButton: {
      title: 'Add Campaign Tag',
      action: () => {
        setShowAddPromt(campaignTagPromtConfig);
      },
    },
    requires: PermissionCodes.addCampaignTag,
    errorIdKey: 'campaignTags',
    // selectAllTitle: 'all campaign categories',
    // loadMoreAction: 'campaignCategoryList/getCampaignCategoryList',
    // defaultFilter: { status: true },
  }

  const getSelectorWithConfig = (config) => {
    return (
      <BaseMultipleSelectorV2
          title={config.title}
          namespace={config.namespace}
          data={{
            sourceData: config.data,
            targetData: config.selectedList,
            targetChange: (value) => {
              config.setValue(value);
            },
          }}
          addButton={config.addButton}
          error={{
            error: hasError(errors, config.errorIdKey),
            id: config.errorIdKey,
            message: errorMessage(errors, config.errorIdKey),
          }}
          requires={config.requires}
          groupKey={config.groupKey}
          groupKeyChoice={config.groupKeyChoice}
        />
    );
  };
  
  const campaignTagPromtConfig = {
    title: 'Go to create campaign tag?',
    description: 'Go to create campaign tag',
    buttonText: 'Create Campaign Tag',
    pathname: '/campaign_tag/create',
  }
  const campaignCategoryPromtConfig = {
    title: 'Go to create campaign category?',
    description: 'Go to create campaign category',
    buttonText: 'Create Campaign Category',
    pathname: '/campaign_category/create',
  }
  const customerSegmentPromtConfig = {
    title: 'Go to create segment?',
    description: 'Go to create segment',
    buttonText: 'Create segments',
    pathname: '/segments/create',
  }
  const customerGroupPromtConfig = {
    title: 'Go to create customer group?',
    description: 'Go to create customer group',
    buttonText: 'Create customer group',
    pathname: '/customer_group/create',
  }
  console.log("2301", categories)
  return (
    <>
      {/* {campaignType === CampaignType.couponsCampaign ? ( */}
        <label className="create-section-title">General</label>
      {/* ) : null} */}
      <span id={`${CampaignErrorHandleField.customerGroup.name}`} />
      {isExclusive ? (
        <>
          <div
            className={`${
              campaignType === CampaignType.couponsCampaign
                ? ''
                : 'category-select-title-top'
            }`}
          >
            <label className="create-section-label create-section-label-bottom-space">
              Target customer
            </label>
            <Row className="featured-exclusive-row">
              <CustomRadios
                onChange={(value) => {
                  setValue('generalType', value, { shouldDirty: true});
                }}
                default={generalType}
                options={getRadioOptions()}
              />
            </Row>
          </div>
          {generalType === GENERAL_TYPE.customerGroup
            ? getSelectorWithConfig(customerGroupConfig)
            : null}
          {generalType === GENERAL_TYPE.segment
            ? getSelectorWithConfig(segmentConfig)
            : null}
        </>
      ) : null}
      {isInterestClub ? (
        <>
          <CustomTitleWithDropDown
            title='Campaign category for Interest Club'
            defaultValue={{
              value: category,
              label: category?.name,
            }}
            source={categories}
            setValue={(item) => {
              setValue('categories', item.value ? [item.value] : [], { shouldDirty: true });
            }}
            loadMoreAction={'campaignCategoryList/getCampaignCategoryList'}
            filterAction={'campaignCategoryList/getCampaignCategoryList'}
            defaultFilter={campaignCategoryDefaultFilter}
            isError={hasError(errors, 'categories')}
          />
          <ReactHookFormErrorMessage errors={errors} id="categories" />
        </>
      ) : getSelectorWithConfig(campaignCategoryConfig)}
      {getSelectorWithConfig(campaignTagConfig)}
      {campaignType === CampaignType.couponsCampaign && watchDisplayIn === CampaignDisplayIn.stamp ? (
        <>
          <InputFieldControl
            name={'requiredStamps'}
            title={'Required stamp'}
            value={requiredStamps}
            setValue={(value) => {
              setValue('requiredStamps', value, { shouldDirty: true });
            }}
            type='number'
            unit='stamps'
            className='coupon-campaign-section-input'
          />
        </>
      ) : null}
      {/* {watchDisplayIn === CampaignDisplayIn.stamp || watchDisplayIn === CampaignDisplayIn.mission ? null : (
        <div className="create-section-label-bottom-space">
          <label className="create-section-label featured-exclusive-row-tip-label">
            {'Promotion type (for LINK customer website)'}
          </label>
          <Row className="featured-exclusive-row">
            <CustomRadios
              onChange={(value) => {
                setValue('campaignPromotionType', value, {shouldDirty: true})
              }}
              default={watchCampaignPromotionType || CampaignPromotionType.none}
              options={CampaignPromotionTypeConfig}
            />
          </Row>
        </div>
      )} */}
      <BasePrompt
        show={showAddPromt ? true : false}
        closeAction={() => setShowAddPromt(false)}
        rightButton={{
          text: showAddPromt?.buttonText,
          action: () =>
            history.push({
              pathname: showAddPromt?.pathname,
              state: { from:
                {...history.location,
                  state: {
                    ...history.location?.state,
                    resume: true
                  }
                },
                ...ContinueCreate },
            }),
        }}
        title={showAddPromt?.title}
        description={showAddPromt?.description}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  categories: state.campaignCategoryList.categoryList,
  groups: state.customerGroup.groupList,
  segments: state.segments.segmentList,
  campaignTagsList: state.campaignTag.notPagedAllList,
});

export default connect(mapPropsToState)(PropertiesGeneralSection);
