import React, { Component } from 'react';
import './CouponTemplateOverview.scss';
import { parseCouponSetOverviewDatetime } from '../../../models/CouponUtil';
import BaseOverView from './BaseOverview';
import { getReadablePeriod } from '../../../utils/TimeFormatUtil';
import { WEEKDAY } from '../../../config/CustomEnums';

class CouponTemplateOverview extends Component {
  render() {
    const titleList = [
      'Total Stock',
      'Stock Left',
      'Linked Campaign Type',
      'Linked Campaign',
      'Create at',
      'Last Modified',
      'Blackout period (by period)',
      'Blackout period (by weekday)',
      'Status',
    ];
    const valueList = [
      this.props.couponSet?.totalNubmerOfGeneratedCoupons,
      this.props.couponSet?.stock,
      this.props.couponSet?.linkedCampaignsType,
      this.props.couponSet?.linkedCampaignsName,
      this.props.couponSet?.creationDate ? parseCouponSetOverviewDatetime(this.props.couponSet?.creationDate) : '-',
      this.props.couponSet?.lastModifiedDate ? parseCouponSetOverviewDatetime(this.props.couponSet?.lastModifiedDate) : '-',
      this.props.couponSet?.validPeriodValues?.map((item) => getReadablePeriod(item.startDate, item.endDate, false)),
      this.props.couponSet?.validWeekday?.map((item) => WEEKDAY[item]),
      this.props.couponSet?.status,
    ];
    return (
      <BaseOverView itemTitleList={titleList} itemValueList={valueList} />
    );
  }
}

export default CouponTemplateOverview;
