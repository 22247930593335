import React, { useEffect, useState } from 'react';
import { createAction } from '../../utils';
import BaseMutipleSelectorV2 from '../base/BaseMultipleSelectorV2';
import BasePrompt from '../base/prompt/BasePrompt';

const ExportSelectorView = ({
  show,
  onHide,
  sourceData,
  dispatch,
  selectTitle,
  namespace,
  exportResource,
  relatedField,
}) => {
  const [selectedTargetData, setSelectedTargetData] = useState([]);
  useEffect(() => {
    if (!show) {
      setSelectedTargetData([]);
    }
  }, [show, dispatch]);

  const body = () => (
    <>
      <>
        <div>
          <BaseMutipleSelectorV2
            size={['shorter']}
            title={selectTitle}
            namespace={namespace}
            searchPlaceholder="Search by name or ID"
            data={{
              sourceData: sourceData,
              targetData: selectedTargetData,
              targetChange: setSelectedTargetData,
            }}
            custom={{
              customItem: (value) => (
                <>
                  <label className="slot-title">{`[ID:${value.pk}]`}</label>
                  {value.name}
                </>
              ),
              customFilter: (inputValue, option) => {
                const inputIgnoreCase = inputValue?.toLowerCase();
                const optionIgnoreCase = option?.name?.toLowerCase();
                return (
                  optionIgnoreCase?.includes(inputIgnoreCase) ||
                  option.pk === inputValue
                );
              },
            }}
          />
        </div>
      </>
    </>
  );
  return (
    <BasePrompt
      show={show}
      closeAction={onHide}
      rightButton={{
        text: 'Export',
        action: () => {
          let data = {};
          data = {
            from: exportResource,
            related: {
              [relatedField]: selectedTargetData.map((item) => item.pk),
            },
          };

          dispatch(createAction('downloadAndImport/createDownloadTask')(data));
          onHide();
        },
        disabled: selectedTargetData.length === 0,
      }}
      title="CSV configuration"
      otherBody={body}
    />
  );
};

export default ExportSelectorView;
