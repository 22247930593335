import { connect, useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { createAction, getError, removeFromSessionStorage, saveToSessionStorage, useCompare } from '../../../utils';
import { APIStatus, SESSION_KEYS } from '../../../config/CustomEnums';
import BaseForm from '../../../components/base/v2/BaseForm';
import CreateAppVersionStepOne from './CreateAppVersionStepOne';

function CreateAppVersion({
  appVersion,
  createStatus,
  apiStatus,
  hasUpdatedDefaultValues,
  formHasSubmitted,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const caution = {
    detail: '',
    title: 'Create App Version',
    model: 'appVersion',
  };

  useEffect(() => {
    dispatch({ type: 'appVersion/loadFromCookie' });
    return () => {
      dispatch({ type: 'appVersion/clearData' });
    };
  }, [dispatch]);

  useEffect(() => {
    if (apiStatus === APIStatus.success && formHasSubmitted) {
      history.push('/app_version');
      return;
    }
  }, [apiStatus, formHasSubmitted, history]);

  const stepSet = [<CreateAppVersionStepOne />];
  console.log('@40', appVersion);
  return (
    <div>
      <BaseForm
        defaultValues={appVersion}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        tempSave={(save, getValues) => {
          if (save) {
            saveToSessionStorage(
              SESSION_KEYS.APP_VERSION_SESSION_KEY,
              getValues(),
            );
          } else {
            removeFromSessionStorage(SESSION_KEYS.APP_VERSION_SESSION_KEY);
          }
        }}
        showFinishPop={createStatus === APIStatus.success}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'App Version is successfully created.',
          steps: null,
          buttons: [
            {
              text: 'Back to App Version list',
              action: () => {
                history.push({
                  pathname: '/app_version',
                });
              },
            },
          ],
        }}
        continueCreateConfig={{
          onConfirm: () => {
            history.push(history.location.state?.from);
          },
          onCancel: () => history.push('/app_version'),
          onClose: () => history.push('/app_version'),
        }}
        content={stepSet}
        currentStep={0}
        breadcrumb={<CustomBreadcrumb name={appVersion.name || 'Create App Version'} />}
        caution={caution}
      />
    </div>
  );
}

const mapPropsToState = (state) => ({
  appVersion: state.appVersion.detail,
  createStatus: state.appVersion.createStatus,
  apiStatus: state.appVersion.apiStatus,
  hasUpdatedDefaultValues: state.appVersion.hasUpdatedDefaultValues,
  formHasSubmitted: state.appVersion.formHasSubmitted,
});

export default connect(mapPropsToState)(CreateAppVersion);
