import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormContext, Controller } from 'react-hook-form';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  LanguageConfig,
  EMAIL_REG,
} from '../../config/CustomEnums';
import ImageUploader, { ReactHookFormImageUploader } from '../base/ImageUploader';
import {
  CustomTitleWithDropDown,
  CustomTitleWithDropDownAndInput,
} from '../customer/CustomTitleWithDropDown';
import i18n from '../../I18n';
import { StoreErrorHandleFields } from '../../containers/merchants/stores/StoreErrorHandleFields';
import BrandSelectDropdown from '../campaign/campaignCreation/BrandSelectDropdown';
import {
  ReactHookFormErrorMessage,
} from '../base/ErrorFieldMessage';
import { ReactHookFormMarkDown } from '../base/CustomMarkDownArea';
import InputFieldControl from '../base/InputFieldControl';
import './CreateStoreStepOneContentSection.scss';
import BaseMultipleSelectorV2 from '../base/BaseMultipleSelectorV2';
import QRCodeCollapse from '../../containers/merchants/stores/QRCodeCollapse';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import { hasError, errorMessage } from '../base/ErrorFieldMessage';

const countryCodes = [
  { name: '853', pk: '853' },
  { name: '852', pk: '852' },
  { name: '86', pk: '86' },
];

function CreateStoreStepOneContentSection ({
  codeDisplayImage,
  codeDownloadImage,
  districtList,
  subCategoryList,
  language,
  brands,
  onFocus = (() => {}),
  setShowAlertPrompt = (() => {}),
}){
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const { register, watch, setValue, control, formState } = useFormContext();
  const { errors } = formState;

  const watchName = watch(`storeTranslations.${language}.name`);
  const watchDisplayPriority = watch('displayPriority');
  const watchCoverPhoto = watch('coverPhoto');
  const watchDistrict = watch('district');
  const watchAddress = watch(`storeTranslations.${language}.address`);
  const watchPhoneNumberCountryCode = watch('phoneNumberCountryCode');
  const watchPhonoNumberSubscriberNumber = watch('phoneNumberSubscriberNumber');
  const watchEmailAddress = watch('emailAddress');
  const watchBrand = watch('brand');
  const watchSubcategories = watch('selectedSubcategories') || [];
  const watchLatitude = watch('latitude');
  const watchLongitude = watch('longitude');
  const watchStaffCode = watch('staffCode');

  return (
    <div style={{ marginTop: '-30px' }} className="store-create-step-one">
      <InputFieldControl
        name={`storeTranslations.${language}.name`}
        title={i18n.t('name', { locale: language })}
        rules={language === LanguageConfig.english ? { required : StoreErrorHandleFields.name.required } : {}}
        value={watchName}
        setValue={(value) => {
          setValue(`storeTranslations.${language}.name`, value, { shouldDirty: true });
        }}
        onFocus={() => {
          onFocus('name');
        }}
      />
      {
        language === LanguageConfig.english ? (
          <>
            <InputFieldControl
              name={'displayPriority'}
              title={'Display order'}
              rules={{required: StoreErrorHandleFields.displayPriority.required}}
              value={watchDisplayPriority}
              type={'number'}
              setValue={(value) => {
                setValue('displayPriority', value, { shouldDirty: true });
              }}
            />
            <label className="create-message-suggest">
                {`Minimum value is 1. The smaller value, the higher priority.`}
            </label>
            <div style={{ display: 'grid' }}>
              <ReactHookFormImageUploader 
                uploadSuggest={i18n.t('cover_photo_suggest')}
                name={'coverPhoto'}
                rules={{required: StoreErrorHandleFields.coverPhoto.required}}
                minWidth={900}
                minHeight={600}
                aspect={4 / 3}
                value={watchCoverPhoto}
                language={language}
                uploadImageClicked={() => {
                  onFocus('coverPhoto');
                }}
              />
            </div>
            <Controller
              control={control}
              name={'district'}
              render={() => (
                <>
                  <CustomTitleWithDropDown
                    title='District(optional)'
                    source={districtList}
                    defaultValue={watchDistrict}
                    setValue={(value) => {
                      setValue('district', value, { shouldDirty: true });
                    }}
                    loadMoreAction={'storeModel/getDistrictList'}
                    filterAction={'storeModel/getDistrictList'}
                    onFocus={() => {onFocus('district')}}
                  />
                </>
              )}
            />
          </>
        ) : null
      }
      <InputFieldControl
        name={`storeTranslations.${language}.address`}
        title={i18n.t('address', { locale: language })}
        rules={language === LanguageConfig.english ? { required : StoreErrorHandleFields.address.required } : {}}
        value={watchAddress}
        setValue={(value) => {
          setValue(`storeTranslations.${language}.address`, value, { shouldDirty: true });
        }}
        onFocus={() => {
          onFocus('address');
        }}
      />
      {
        language === LanguageConfig.english ? (
          <>
            <Controller
              control={control}
              name={'phoneNumberSubscriberNumber'}
              render={() => (
                <CustomTitleWithDropDownAndInput
                  title="Mobile number(optional)"
                  source={countryCodes}
                  defaultValue={watchPhoneNumberCountryCode}
                  defaultInputValue={watchPhonoNumberSubscriberNumber}
                  needFilter={false}
                  customClass="dropdown-short"
                  inputCustomClass="dropdown-input-long"
                  setValue={(value) => {
                    if (value !== watchPhoneNumberCountryCode) {
                      setValue('phoneNumberCountryCode', value, { shouldDirty: true });
                    }
                  }}
                  setInputValue={(value) => {
                    if (value !== watchPhonoNumberSubscriberNumber) {
                      setValue('phoneNumberSubscriberNumber', value, {
                        shouldDirty: true,
                      });
                    }
                  }}
                  onFocus={() => {
                    onFocus('mobileNumber');
                  }}
                  inputOnFocusChange={() => onFocus('mobileNumber')}
                />
              )}
            />
            <InputFieldControl
              name={'emailAddress'}
              title={'Email(optional)'}
              rules={{
                validate: {
                  validEmail: (watchEmailAddress) => {
                    console.log("@330", watchEmailAddress)
                    if (!watchEmailAddress?.length) {
                      return true;
                    }
                    if (EMAIL_REG.test(watchEmailAddress)) {
                      return true;
                    }
                    return StoreErrorHandleFields.emailAddress.valid;
                  },
                },
              }}
              value={watchEmailAddress}
              setValue={(value) => {
                setValue('emailAddress', value, { shouldDirty: true });
              }}
              onFocus={() => {
                onFocus('email');
              }}
            />
          </>
        ) : null
      }
      <div>
        <ReactHookFormMarkDown
          content={{
            label: i18n.t('description_optional', { locale: language }),
            key: `storeTranslations.${language}.description`,
            focus: () => {
              onFocus('description');
            },
          }}
        />
      </div>
      {
        language === LanguageConfig.english ? (
          <>
            <Controller
              control={control}
              name="brand"
              render={() => (
                <>
                  <BrandSelectDropdown
                    title={'Related brand (Optional)'}
                    defaultValue={{
                      value: watchBrand,
                      label: watchBrand?.name,
                    }}
                    onSelectChange={(item) => {
                      onFocus('brand');
                      if (item.value !== watchBrand) {
                        setValue('brand', item.value, { shouldDirty: true });
                      }
                    }}
                    addButton={{
                      title: 'Create Now',
                      action: () => {
                        setShowAlertPrompt({
                          title: 'brand',
                          show: true,
                          pathname: '/brands/create/',
                        });
                      },
                      customClass: 'multiple-select-option-add btn-add-button-common',
                    }}
                    editable={!history.location.pathname?.includes('edit') || !brands}
                    onFocus={() => onFocus('brand')}
                  />
                  <label className="tips-message">Once set, cannot be changed.</label>
                </>
              )}
            />
            <Controller
              control={control}
              name="selectedSubcategories"
              rules={language === LanguageConfig.english ? { required : StoreErrorHandleFields.subcategories.required } : {}}
              render={() => (
                <BaseMultipleSelectorV2
                  title={'Subcategories'}
                  data={{
                    sourceData: subCategoryList,
                    targetData: watchSubcategories,
                    targetChange: (value) => {
                      console.log('subCategory @65', value);
                      setValue('selectedSubcategories', value, {
                        shouldDirty: true,
                      });
                    },
                  }}
                  addButton={{
                    title: 'Add Subcategory ',
                    action: () => {
                      setShowAlertPrompt({
                        title: 'subcategory',
                        show: true,
                        pathname: '/store_category_lv1_lv2/create',
                      });
                    },
                  }}
                  loadMoreAction={'storeCategoryList/getAllStoreSubcategoryList'}
                  onFocusFunc={() => {
                    onFocus('subCategory')
                  }}
                  error={{
                    error: hasError(errors, 'selectedSubcategories'),
                    id: 'selectedSubcategories',
                    message: errorMessage(errors, 'selectedSubcategories'),
                  }}
                />
                )}
            />
            {/* <ReactHookFormErrorMessage errors={errors} id='selectedSubcategories' /> */}
            <InputFieldControl
              name={'latitude'}
              title={'Latitude'}
              rules={language === LanguageConfig.english ? { required : StoreErrorHandleFields.latitude.required } : {}}
              value={watchLatitude}
              setValue={(value) => {
                setValue('latitude', value, { shouldDirty: true });
              }}
            />


            <InputFieldControl
              name={'longitude'}
              title={'Longitude'}
              rules={language === LanguageConfig.english ? { required : StoreErrorHandleFields.longitude.required } : {}}
              value={watchLongitude}
              setValue={(value) => {
                setValue('longitude', value, { shouldDirty: true });
              }}
            />
            <InputFieldControl
              name={'staffCode'}
              title={'Staff code'}
              rules={language === LanguageConfig.english ? {
                validate: {
                  validStaffCode: (watchStaffCode) => {
                    console.log("@330", watchStaffCode, watchStaffCode?.length)
                    if (!watchStaffCode?.length) {
                      return StoreErrorHandleFields.staffCode.required;
                    }
                    if (watchStaffCode?.length != 6) {
                      return StoreErrorHandleFields.staffCode.length;
                    }
                    return true
                  },
                },
              } : {}}
              value={watchStaffCode}
              setValue={(value) => {
                setValue('staffCode', value, { shouldDirty: true });
              }}
            />
            {
              codeDisplayImage ? 
              <>
                <CustomTitleLabel title={'QR code preview'} />
                <label className='qrcode-tips'>The store QR code is auto generate</label>
                <QRCodeCollapse
                  codeDisplayImage={codeDisplayImage}
                  codeDownloadImage={codeDownloadImage}
                />
              </> : null
            }
            
          </>          
        ) : null
      }
    </div>
  )
}


const mapPropsToState = (state) => ({
  checked: state.storeModel.checked,
  errorFields: state.storeModel.errorFields,
  saved: state.storeModel.saved,
  districtList: state.storeModel.districtList || [],
  subCategoryList: state.storeCategoryList.subCategoryList || [],
  formChanged: state.storeModel.formChanged,
  codeDisplayImage: state.storeModel.oneStore?.codeDisplayImage,
  codeDownloadImage: state.storeModel.oneStore?.codeDownloadImage,
  brands: state.storeModel.oneStore?.brand,
  test: state.language,
})

export default connect(mapPropsToState)(CreateStoreStepOneContentSection);
