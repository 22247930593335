import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  CampaignDisplayIn,
  CampaignType,
  LanguageConfig,
  PUBLISHER_TYPE,
} from '../../../config/CustomEnums';
import i18n from '../../../I18n';
import { errorMessage } from '../../base/ErrorFieldMessage';
import ImageUploader from '../../base/ImageUploader';
import { InputFieldControl2 } from '../../base/InputFieldControl';
import SelectMultipleBrand from '../../base/SelectMultipleBrand';
import {
  CustomTitleLabel,
  CustomTitleWithRadio,
} from '../../earning/CustomBaseComponments';

function PublisherTypeSection({ language }) {
  const { watch, formState, setValue, getValues } = useFormContext();
  const publisherType =
    watch('publisherType') ||
    setValue('publisherType', PUBLISHER_TYPE.brand, { shouldDirty: true });
  const watchBrand = watch('selectedBrand') || [];
  const providerPhoto = watch(`translations.${language}.providerPhoto`) || '';

  const [watchCampaignType, watchDisplayIn] = getValues([
    'campaignType',
    'displayIn',
  ]);
  const isMultipleBrand = !(
    watchCampaignType === CampaignType.htmlCampaign ||
    (watchDisplayIn === CampaignDisplayIn.interestClub &&
      watchCampaignType === CampaignType.generalMessageCampaign)
  );
  const publisherProviderPhoto = [providerPhoto]?.filter((item) => item);

  const { errors } = formState;
  return (
    <>
      <CustomTitleWithRadio
        title="Publisher type"
        defaultValue={publisherType}
        disabled={language !== LanguageConfig.english}
        setValue={(value) => {
          setValue('publisherType', value, { shouldDirty: true });
        }}
        options={[
          { label: 'Brand', value: PUBLISHER_TYPE.brand },
          { label: 'Content provider', value: PUBLISHER_TYPE.contentProvider },
        ]}
      />
      {publisherType === PUBLISHER_TYPE.brand ? (
        <SelectMultipleBrand
          disabled={language !== LanguageConfig.english}
          value={watchBrand}
          onChange={(v) => {
            const value = Array.isArray(v) ? v : v ? [v] : [];
            if (isMultipleBrand) {
              setValue('selectedBrand', value, { shouldDirty: true });
            } else {
              setValue('selectedBrand', value, { shouldDirty: true });
            }
          }}
          hasMarginTop={false}
          multiple={isMultipleBrand}
        />
      ) : null}
      {publisherType === PUBLISHER_TYPE.contentProvider ? (
        <>
          <InputFieldControl2
            name={`translations.${language}.providerName`}
            title={i18n.t('provider_name', { locale: language })}
          />

          <CustomTitleLabel
            title={i18n.t('provider_photo', { locale: language })}
          />
          <label className="create-campaign-cover-photo-reminder">
            {i18n.t('square_cover_photo_suggest', { locale: language })}
          </label>
          <ImageUploader
            minWidth={600}
            minHeight={600}
            images={publisherProviderPhoto}
            onImageStateChange={(newState) => {
              setValue(`translations.${language}.providerPhoto`, newState[0], {
                shouldDirty: true,
              });
            }}
            aspect={1 / 1}
            maxImageNum={1}
            language={language}
            errorMessage={errorMessage(
              errors,
              `translations.${language}.providerPhoto`,
            )}
          />
        </>
      ) : null}
    </>
  );
}

export default PublisherTypeSection;
