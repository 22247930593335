import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { CampaignDisplayIn, CampaignType, MissionCampaignMode } from '../../../config/CustomEnums';
import BaseMultipleSelectorV2 from '../../base/BaseMultipleSelectorV2';
import InputFieldControl, { InputFieldControl2 } from '../../base/InputFieldControl';
import { hasError, ReactHookFormErrorMessage } from '../../base/ErrorFieldMessage';
import { SimpleListWithCouponNode } from '../../../services/CampaignAPIHelper';


function FixedMissionSection({
  earningCampaignList,
  onFocus = () => {},
}) {
  const { watch, setValue, formState, getValues } = useFormContext();
  const dispatch = useDispatch();
  const { errors } = formState;

  const watchLinkedEarningCampaigns = watch("linkedEarningCampaigns")
  const { pk } = getValues();
 
  useEffect(() => {
    dispatch({
      type: 'campaignList/getCampaignList',
      payload: {
        displayIn: CampaignDisplayIn.mission,
        type: CampaignType.earningRulesCampaign,
        isAll: true,
        tasksForMissionMode: MissionCampaignMode.fixedRewards, 
        fieldNode: SimpleListWithCouponNode,
        missionCampaignId: pk || -1
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  return (
    <>
      <label className="create-section-title">{'Missions'}</label>
      <BaseMultipleSelectorV2
        title={"Link to earning campaign"}
        namespace="campaignModel"
        custom={{
          customItem: (item) => {
            return `[ID:${item.pk}] ${item.name}`
          }
        }}
        data={{
          sourceData: earningCampaignList,
          targetData: watchLinkedEarningCampaigns,
          targetChange: (value) => {
            console.log("@43", value)
            // watchLinkedOrder
            // value?.map((item) => {return {...item, order: null} })
            setValue('linkedEarningCampaigns', value, {
              shouldDirty: true,
            });
          },
        }}
        error={{
          error: hasError(errors, 'linkedEarningCampaigns'),
        }}
        onFocusFunc={() => onFocus('linkCampaign')}
      />
      <ReactHookFormErrorMessage errors={errors} id='linkedEarningCampaigns' />
      {watchLinkedEarningCampaigns?.length > 0 ? <div style={{marginTop: "25px"}}>
        <label className='create-section-label'>{'Earning campaign order in Frontend'}</label>
        {watchLinkedEarningCampaigns?.map((item, index) => 
          <InputFieldControl2
            name={`earningCampaignRelation.${item.id}.order`}
            customTitleClass={'no-title'}
            type='number'
            unit={item.name}
            className='coupon-campaign-section-input'
            onFocus={() => onFocus('linkCampaign')}
          />
        )
      }
      </div> : null}
      

    </>
  );
}

const mapPropsToState = (state) => ({
  earningCampaignList : state.campaignList.pagedList,
})

export default connect(mapPropsToState)(FixedMissionSection);
