import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './CustomDateTimeSelect.scss';
import DatePicker from 'react-datepicker';
import DateIcon from '../../assets/images/date_icon.svg';
import {
  formatDate,
  getCampignPeriodDate,
  getCurrentDay,
} from '../../utils/TimeFormatUtil';
import { Image } from 'react-bootstrap';

function CustomDateSelect(props) {
  const maxDate = props.maxDate;
  const minDate = props.minDate;
  const disabled = props.disabled || false;
  const onDateChange = props.onDateChange || (() => {});
  const defaultDate = props.defaultDate || getCurrentDay();
  const error = props.error;
  const [selectedDate, setSelectedDate] = useState(defaultDate);
  const [dateError, setDateError] = useState(error);
  const handleChange = (date) => {
    setSelectedDate(date);
  };
  const locale = props.locale;
  const customerDateFormat = props.dateFormat;

  const getDefaultDate = () => {
    if (typeof selectedDate === 'string') {
      return new Date(selectedDate);
    }
    return selectedDate;
  };

  useEffect(() => {
    setDateError(error);
    const date = new Date(selectedDate);
    onDateChange(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, selectedDate]);

  const CustomInputView = ({ value, onClick, dateFormat }) => {
    return (
      <button
        disabled={disabled}
        onClick={onClick}
        className={`custom-date-select-container select-time-text ${
          dateError ? 'error-field' : ''
        }`}
      >
        {dateFormat ? formatDate(value, dateFormat) : getCampignPeriodDate(value)}
        <Image src={DateIcon} className="custom-date-select-icon" />
      </button>
    );
  };

  return (
    <div className="custom-date-time-select-container">
      <DatePicker
        selected={getDefaultDate()}
        onChange={(date) => handleChange(date)}
        maxDate={maxDate}
        minDate={minDate}
        customInput={<CustomInputView dateFormat={customerDateFormat} />}
        locale={locale}
        showMonthDropdown
        showYearDropdown
      />
    </div>
  );
}

export default CustomDateSelect;
