import { defaultStep, getNewStepConfig } from './StepBarUtil';
import {
  createTransaction,
  getPaymentMethed,
  updateTransaction,
  UseRewardCoupon,
} from '../services/TransactionRecordAPIHelper';
import { loading } from './LoadingUtil';
import {
  getFileNameFromUrl,
  saveToSessionStorage,
  removeFromSessionStorage,
  getObjectFromSessionStorage,
} from '../utils';
import { APIStatus, COUPON_RECEIVER, FORM_TYPE, TRANSACTION_ERROR_KEYS } from '../config/CustomEnums';
import { simulateTransaction } from '../services/TransactionRecordAPIHelper';
import i18n from '../I18n';
import { parseTransaction } from './TransactionRecordListModel';

export const sessionDataKey = {
  enObjectKey: 'createTransactionEN',
  tcObjectKey: 'createTransactionTC',
  stepEndKey: 'createTransactionStepEnd',
  origionalData: 'createTransactionOriginalData',
  storeKey: 'transactionStoreData',
  enRecentlyData: 'recentlyDataEN',
  tcRecentlyData: 'recentlyDataTC',
};

function getTransactionInit() {
  return {
    pk: null,
    id: null,
    ssoUid: null,
    name: null,
    transactionType: null,
    transactionDisplayType: null,
    storeName: null,
    creationDate: null,
    transactionDate: new Date(),
    transactionDetailDisplayDate: null,
    transactionDetailCreationDate: null,
    totalValue: null,
    onlineEventType: null,
    offlineEventType: null,
    displayOfflineEventType: null,
    staffName: 'Staff',
    shippingFee: null,
    otherCharge: null,
    remarks: null,
    posInvoiceId: null,
    invoiceId: null,
    receiptImage: null,
    receipts: null,
    creditCardSlipImage: null,
    purchasedItems: [
      {
        productName: null,
        sku: null,
        category: null,
        brand: null,
        quantity: null,
        value: null,
      },
    ],
    customer: {},
    store: {},
    recentlyData: {},
  };
}

function getInitState() {
  const stepNameList = ['Select campaign', 'Choose basic information', 'Upload receipt', 'Confirm reward', 'Done'];
  const stepTCNameList = ['選擇活動', '選擇基本信息', '上傳收據', '確認獎賞', '完成'];
  return {
    transaction: getTransactionInit(),
    errorFields: [],
    stepConfig: defaultStep(stepNameList),
    stepTCConfig: defaultStep(stepTCNameList),
    currentStep: 0,
    selectedType: null,
    transactionStatusChanged: false,
    formHasSubmitted: false,
    hasUpdatedDefaultValues: false,
    allPaymentMetheds: [],
    createStatus: APIStatus.none,
    createErrorMessages: [],
    stampCampaignReward: null,
    missionCampaignRewardCoupon: null,
    missionCampaignRewardBadge: null,
    otherCampaignReward: null,
    carParkCampaignReward: null,
    simulateTransactionReward : {
      stampCampaignReward: null,
      stampCampaignRewardData: [],
      missionCampaignRewardCoupon: null,
      missionCampaignRewardBadge: null,
      missionCampaignRewardBadgeData: [],
      otherCampaignReward: null,
      carParkCampaignReward: null,
      couponReceiver: null,
    }
  };
}

function parsePhotoUrlForAPI(image) {
  let imageUrl = image;
  if (image && image.value) {
    imageUrl = image.value;
  }
  return getFileNameFromUrl(imageUrl);
}

function parseCreateTransactionInputBody(data) {
  const formType = data.formType;
  const inputBody = {
    staffName: data.staffName,
    customer: data.customer.pk,
    region: data.region.pk,
    district: data.district.pk,
    mall: data.mall.pk,
    receipts: data.receipts.map((receipt) => {
      return {
        store: receipt.store.pk,
        invoiceId: receipt.invoiceId,
        receiptImage: parsePhotoUrlForAPI(data.receiptImage),
        creditCardSlipImage: parsePhotoUrlForAPI(data.creditCardSlipImage),
        paymentMethod: receipt.paymentMethod.pk,
        totalValue: formType === FORM_TYPE.trade ? receipt.totalValue : receipt.submitNumber,
        date: receipt.transactionDate,
        title: receipt.title,
      }
    }),
    remarks: data.remarks,
    offlineEventType: 'APPROVED',
    transactionType: 'OFFLINE_REWARD_CLAIM',
    transactionFormType: data.formType,
    transactionRewardType: data.campaignType,
    stampCampaign: data.stampCampaign?.pk,
    missionCampaign: data.missionCampaign?.missionCampaignPK,
    missionDetailCampaign: data.missionCampaign?.missionDetailCampaignPK,
    otherCampaign: data.otherCampaign?.pk,
    carParkCampaign: data.carParkCampaign?.pk,
  };
  console.log('@@288: ', data, inputBody);
  return inputBody;
}

const parseLastFillData = (data) => {
  return {
    region: data.region,
    district: data.district,
    mall: data.mall,
    staffName: data.staffName,
    transactionDate: new Date(),
  }
}

export const parseTranslations = (data) => {
  let result = [];
  if (data) {
    result = data.map((item) => {
      const node = item.node ? item.node : item;
      const translationsEdges = node.translations?.edges || [];
      let translations = {};
      if (translationsEdges.length) {
        translationsEdges.forEach((item) => {
          const node = item.node;
          translations[node.language] = {
            ...node,
            language: node.language,
            name: node.name,
          }
        });
      }
      return {
        ...node,
        translations,
      }
    })
  }
  return result;
}

export const parseNameByLanguage = (data, language) => {
  let result = [];
  if (data) {
    result = data.map((node) => {
      return {
        ...node,
        name: node.translations[language]?.name || node.name,
      }
    })
  }
  return result;
}

const parseMallsList = (data, language) => {
  let result = [];
  const parseTranslationData = parseTranslations(data);
  const parseList = parseNameByLanguage(parseTranslationData, language);
  if (parseList) {
    result = parseList.map((item) => {
      return {
        ...item,
      }
    })
  }
  return result;
}

const parseDistrictsList = (data, language) => {
  let result = [];
  const parseTranslationData = parseTranslations(data);
  const parseList = parseNameByLanguage(parseTranslationData, language);
  if (parseList) {
    result = parseList.map((item) => {
      return {
        ...item,
        malls: parseMallsList(item.malls?.edges, language),
      }
    })
  }
  return result;
}


export const parseTransactionRegionList = (data, language) => {
  let result = [];
  const parseTranslationData = parseTranslations(data);
  const regionTCList = parseNameByLanguage(parseTranslationData, language);
  if (regionTCList) {
    result = regionTCList.map((region) => {
      return {
        ...region,
        districts: parseDistrictsList(region.districts?.edges, language),
      }
    })
  }
  return result;
}

export const getTransactionError = (errorKey, language, campaign) => {
  let errorMessage = '';
  if (!errorKey) {
    return errorMessage;
  }
  switch (errorKey) {
    case 'EARNING_LIMIT_3':
      errorMessage = i18n.t(`transaction.earning_limit_3`, {
        locale: language,
        times: campaign.earningCampaignTypeEarningRule.generalPurchaseTypePeriodicLimit,
        days: campaign.earningCampaignTypeEarningRule.generalPurchaseTypePeriodicLimitEffectiveNumberOfDays,
      })
      break;
    case 'EARNING_LIMIT_4':
      errorMessage = i18n.t(`transaction.earning_limit_4`, {
        locale: language,
        times: campaign.earningCampaignTypeEarningRule.generalPurchaseTypePerHeadPeriodicLimit,
        days: campaign.earningCampaignTypeEarningRule.generalPurchaseTypePerHeadPeriodicLimitEffectiveNumberOfDays,
      })
      break;
    case 'ELIGIBLE_DAY_ERROR':
      errorMessage = i18n.t(`transaction.eligible_day_error`, {
        locale: language,
        days: campaign.earningCampaignTypeEarningRule.generalPurchaseTypeEligibleNumberOfDaysSincePurchase,
      })
      break;
    default:
      errorMessage = i18n.t(`transaction.${TRANSACTION_ERROR_KEYS?.[errorKey]}`, { locale: language })
      break;
  }
  return errorMessage;
}

const parseSimulateTransactionReward = (node, values, language) => {
  const missionCampaignRewardCoupon = node?.missionCampaignRewardCoupon;
  const couponReceiver =  node?.missionCampaignRewardCouponReceiver;
  const stampCampaignRewardBonus = node?.stampCampaignRewardBonus
  const missionCampaignRewardBadge = node?.missionCampaignRewardBadge
  let missionCampaignRewardTypeKey = '';
  let missionCampaignReward = missionCampaignRewardCoupon;
  if(missionCampaignRewardCoupon != null){
    missionCampaignRewardTypeKey = couponReceiver?`transaction.${COUPON_RECEIVER[couponReceiver]}`:'transaction.coupon';
  }else if(missionCampaignRewardBadge !== null){
    missionCampaignReward = missionCampaignRewardBadge;
    missionCampaignRewardTypeKey = 'transaction.badge'
  }
  const missionCampaignRewardType = i18n.t(
    missionCampaignRewardTypeKey,
    { locale: language },
  )
  const displayMissionCampaignReward = `(+${missionCampaignReward} ${missionCampaignRewardType})`;

  const stampCampaignRewardType = i18n.t(
    stampCampaignRewardBonus
      ? 'transaction.stamp_with_bonus'
      : 'transaction.stamp',
    { locale: language },
  )
  const stampCampaignReward = node?.stampCampaignRewardStamp;
  const displayStampCampaignReward = `(+${stampCampaignReward} ${stampCampaignRewardType})`;

  const otherCampaignReward = node?.otherCampaignRewardCoupon;
  const otherCampaignRewardType = i18n.t('transaction.coupon', {
    locale: language,
  })
  const displayOtherCampaignReward = `(+${otherCampaignReward} ${otherCampaignRewardType})`;

  const carParkCampaignReward = node?.carParkCampaignRewardCoupon;
  const carParkCampaignRewardType = i18n.t(
    'transaction.coupon',
    { locale: language },
  )
  const displayCarParkCampaignReward = `(+${carParkCampaignReward} ${carParkCampaignRewardType})`;

  const result = {
    stampCampaignReward,
    stampCampaignRewardBonus,
    stampCampaignRewardData: node?.stampCampaignRewardData ? JSON.parse(node.stampCampaignRewardData)?.map((item) => {
      return {
        receiptTitle: item?.[0],
        rewards: item?.[1],
        withBonus: item?.[2],
        rejectReason: getTransactionError(item?.[3], language, values.stampCampaign),
      }
    }) : [],
    displayStampCampaignReward,
    stampCampaignRewardError: getTransactionError(node?.stampCampaignRewardError, language, values.stampCampaign),
    missionCampaignRewardCoupon,
    missionCampaignRewardBadge,
    missionCampaignRewardBadgeData: node?.missionCampaignRewardData ? JSON.parse(node.missionCampaignRewardData)?.map((item) => {
      return {
        receiptTitle: item?.[0],
        rewards: item?.[1],
        rejectReason: getTransactionError(item?.[2], language, values.missionCampaign),
      }
    }) : [],
    displayMissionCampaignReward,
    missionCampaignRewardError: getTransactionError(node?.missionCampaignRewardError, language, values.missionCampaign),
    otherCampaignReward,
    displayOtherCampaignReward,
    otherCampaignRewardError: getTransactionError(node?.otherCampaignRewardError, language, values.otherCampaign),
    carParkCampaignReward,
    displayCarParkCampaignReward,
    carParkCampaignRewardError: getTransactionError(node?.carParkCampaignRewardError, language, values.carParkCampaign),
    couponReceiver,
  }
  return result;
}

const updateCampaigns = (recentlyCampaigns, campaign) => {
  let result = recentlyCampaigns.filter((item) => {
    return item.pk !== campaign.pk;
  })
  result.push({pk: campaign?.pk, name: campaign?.name});
  if (result.length > 5) {
    result.shift();
  }
  return result;
}

export default {
  namespace: 'createTransactionRecord',
  state: getInitState(),

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    updateCouponState(state, { payload }) {
      const { transaction } = payload;
      return {
        ...state,
        ...payload,
        transaction: {
          ...state.transaction,
          coupons: transaction.coupons,
        }
      }
    },
    updateTransactionState(state, { payload }) {
      const transaction = {
        ...state.transaction,
        ...payload.transaction,
      };
      console.log('@@433: ', transaction);
      if (payload.isTCTransaction) {
        saveToSessionStorage(sessionDataKey.tcObjectKey, transaction);
      } else {
        saveToSessionStorage(sessionDataKey.enObjectKey, transaction);
      }
      return {
        ...state,
        transaction: transaction,
      };
    },
    loadTransactionFromCookie(state, { payload }) {
      const transactionKey = payload.isTCTransaction ? sessionDataKey.tcObjectKey : sessionDataKey.enObjectKey;
      const recentlyDataKey = payload.isTCTransaction ? sessionDataKey.tcRecentlyData : sessionDataKey.enRecentlyData;
      let transaction = getObjectFromSessionStorage(transactionKey);
      const recentlyData = getObjectFromSessionStorage(recentlyDataKey);
      if (!transaction) {
        transaction = state.transaction;
      }
      return {
        ...state,
        transaction: {
          ...transaction,
          recentlyData,
        },
        hasUpdatedDefaultValues: true,
      };
    },
    saveOrRemoveTransactionFromCookie(state, { payload }) {
      if (payload.save) {
        if (payload.values) {
          if (payload.isTCTransaction) {
            saveToSessionStorage(sessionDataKey.tcObjectKey, payload.values);
          } else {
            saveToSessionStorage(sessionDataKey.enObjectKey, payload.values);
          }
        }
      } else {
        if (payload.isTCTransaction) {
          removeFromSessionStorage(sessionDataKey.tcObjectKey);
        } else {
          removeFromSessionStorage(sessionDataKey.enObjectKey);
        }
      }
      saveToSessionStorage(sessionDataKey.stepEndKey, true);
      return {
        ...state,
      };
    },
    saveRecentlyData(state, { payload }) {
      const transaction = payload.data;
      const recentlyDataKey = payload.isTCTransaction ? sessionDataKey.tcRecentlyData : sessionDataKey.enRecentlyData;
      let recentlyData = getObjectFromSessionStorage(recentlyDataKey);
      if (!recentlyData) {
        recentlyData = {
          stampCampaigns: [],
          missionCampaigns: [],
          otherCampaigns: [],
          carParkCampaigns: [],
          stores: [],
        };
      }
      if (transaction) {
        if (transaction.stampCampaign) {
          recentlyData.stampCampaigns = updateCampaigns(recentlyData.stampCampaigns, transaction.stampCampaign);
        } else if (transaction.missionCampaign) {
          recentlyData.missionCampaigns = updateCampaigns(recentlyData.missionCampaigns, transaction.missionCampaign);
        } else if (transaction.otherCampaign) {
          recentlyData.otherCampaigns = updateCampaigns(recentlyData.otherCampaigns, transaction.otherCampaign);
        }
        if (transaction.carParkCampaign) {
          recentlyData.carParkCampaigns = updateCampaigns(recentlyData.carParkCampaigns, transaction.carParkCampaign);
        }
        // if (transaction.receipts){
        //   const stores = transaction.receipts.map((receipt) => receipt.store);
        //   recentlyData.stores = recentlyData.stores.filter((store) => {
        //     return stores.map((item) => item.pk).indexOf(store.pk) === -1;
        //   }).concat(stores);
        //   if (recentlyData.stores.length > 20) {
        //     recentlyData.stores.shift();
        //   }
        // }
      }

      saveToSessionStorage(recentlyDataKey, recentlyData);
      return {
        ...state,
      };
    },
    addPurchasedItem(state, { payload }) {
      return {
        ...state,
        transaction: {
          ...state.transaction,
          purchasedItems: [...state.transaction.purchasedItems, { ...payload }],
        },
      };
    },
    updatePruchasedItems(state, { payload }) {
      const { index, data } = payload;
      return {
        ...state,
        transaction: {
          ...state.transaction,
          purchasedItems: [
            ...state.transaction.purchasedItems.slice(0, index),
            {
              ...state.transaction.purchasedItems[index],
              ...data,
            },
            ...state.transaction.purchasedItems.slice(index + 1),
          ],
        },
      };
    },
    stepChange(state, { payload }) {
      const isBack = payload.isBack;
      let step = payload.step;
      const isValid = payload.isValid;
      let stepConfig = state.stepConfig;
      let stepTCConfig = state.stepTCConfig;
      if (payload.isTCTransaction) {
        stepTCConfig = getNewStepConfig(
          step,
          state.stepTCConfig,
          !isValid,
          isBack,
        );
      } else {
        stepConfig = getNewStepConfig(
          step,
          state.stepConfig,
          !isValid,
          isBack,
        );
      }
      if (isValid) {
        step = isBack ? step - 1 : step + 1;
      }
      return {
        ...state,
        stepConfig,
        stepTCConfig,
        currentStep: step,
      };
    },
    clearData(state, { payload }) {
      return { ...state, ...getInitState() };
    },
  },

  effects: {
    *setFieldToSession({ payload }, { select }) {
      const oldTransaction = yield select(
        (state) => state.createTransaction.transaction,
      );
      const transaction = { ...oldTransaction, ...payload };

      saveToSessionStorage(sessionDataKey.enObjectKey, transaction);
    },
    createTransaction: [
      function* ({ payload }, { call, select, put, all }) {
        const { values, isTCTransaction } = payload;
        const data = parseCreateTransactionInputBody(values);

        const serviceArgs = [createTransaction, data];

        saveToSessionStorage(sessionDataKey.stepEndKey, true);

        function* onSuccess(data) {
          const transactionData = data.createTransaction.node;
          const lastFillData = parseLastFillData(values);
          if (isTCTransaction) {
            removeFromSessionStorage(sessionDataKey.tcObjectKey);
          } else {
            removeFromSessionStorage(sessionDataKey.enObjectKey);
          }
          yield all([
            put({
              type: 'updateTransactionState',
              payload: {
                transaction: parseTransaction({
                  ...transactionData,
                  totalStampCampaignReward: data.createTransaction.stampCampaignRewardStamp,
                  stampCampaignRewardData: data.createTransaction.stampCampaignRewardData,
                  totalStampCampaignRewardBonus: data.createTransaction.stampCampaignRewardBonus,
                  totalMissionCampaignRewardBadge: data.createTransaction.missionCampaignRewardBadge,
                  missionCampaignRewardBadgeData: data.createTransaction.missionCampaignRewardData,
                }),
                isTCTransaction,
              }
            }),
            put({
              type: 'updateState',
              payload: {
                // transactionStatusChanged: true,
                formHasSubmitted: true,
                createStatus: APIStatus.success,
              },
            }),
            put({
              type: 'saveOrRemoveTransactionFromCookie',
              payload: {
                save: true,
                values: lastFillData,
                isTCTransaction,
              }
            }),
            put({
              type: 'saveRecentlyData',
              payload: {
                data: values,
                isTCTransaction,
              }
            })
          ]);
        }

        function* onFailed(data) {
          console.log('@@512: ', data);
          yield put({
            type: 'updateState',
            payload: {
              formHasSubmitted: true,
              createStatus: APIStatus.failed,
            },
          });
        }

        function* onArgumentsError(data) {
          console.log('@@458: ', data);
          yield put({
            type: 'updateState',
            payload: {
              createStatus: APIStatus.failed,
              createErrorMessages: data?.data?.createTransaction?.errors?.map((item) => {
                return {
                  field: item.field,
                  message: item.messages[0],
                }
              }),
            },
          });
        }
        yield loading(serviceArgs, onSuccess, onFailed, onArgumentsError);
      },
      { type: 'takeLatest' },
    ],

    updateTransaction: [
      function* ({ payload }, { put, select }) {
        const { values } = payload;
        let parsedInputBody = parseCreateTransactionInputBody(values);
        parsedInputBody.id = values.pk;

        saveToSessionStorage(sessionDataKey.stepEndKey, true);
        const serviceArgs = [updateTransaction, parsedInputBody];
        function* onSuccess() {
          removeFromSessionStorage(sessionDataKey.enObjectKey);
          yield put({
            type: 'updateState',
            payload: {
              transactionStatusChanged: true,
              formHasSubmitted: true,
            },
          });
        }
        function* onFailed(data) {
          console.log('@@512: ', data);
          yield put({
            type: 'updateState',
            payload: {
              formHasSubmitted: true,
            },
          });
        }
        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],

    getAllPaymentMethed:[
      function* ({ payload }, { put, select }) {
        const serviceArgs = [getPaymentMethed, '', payload];
        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: {
              allPaymentMetheds: parseTranslations(data?.paymentMethods?.edges),
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    simulateTransaction:[
      function* ({ payload }, { put, select }) {
        const { values, language } = payload;
        console.log('@@513', values);
        const data = parseCreateTransactionInputBody(values);

        const serviceArgs = [simulateTransaction, data];

        function* onSuccess(data) {
          const node = data.simulateTransaction?.node;
          yield put({
            type: 'updateState',
            payload: {
              simulateTransactionReward : {
                ...parseSimulateTransactionReward(node, values, language),
              }
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    useRewardCoupon: [
      function* ({ payload }, { put, select }) {
        const { selectCoupon, language, afterAction } = payload;
        const data = { coupon: selectCoupon.pk };

        const serviceArgs = [UseRewardCoupon, data];
        function* onSuccess(data) {
          if (data.useCoupon?.success) {
            yield put({
              type: 'navBars/updateState',
              payload: {
                saveDiscardToastShowing: {
                  value: true,
                  type: i18n.t('transaction.coupon_used_successfully', { locale: language }),
                },
              },
            });
          } else {
            yield put({
              type: 'navBars/updateState',
              payload: {
                saveDiscardToastShowing: {
                  value: true,
                  type: i18n.t('transaction.coupon_used_failed', { locale: language }),
                },
              },
            });
          }
          if (afterAction) {
            afterAction(data);
          }
        }
        function* onFailed(data) {
          if (afterAction) {
            yield afterAction(data);
          }
        }
        function* onArgumentsError(data) {
          if (afterAction) {
            yield afterAction(data);
          }
        }
        yield loading(serviceArgs, onSuccess, onFailed, onArgumentsError);
      },
      { type: 'takeLatest' },
    ],
  },
};
