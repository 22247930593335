import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { parseTransactionRegionList } from '../../models/CreateTransactionRecordModel';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';
import { CreateTransactionError } from '../../config/ErrorHandle';
import i18n from '../../I18n';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import { hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';

function TransactionStepOneDropdownSection(props) {
  const dispatch = useDispatch();

  const defaultSelectedRegion = props.selectedRegion;
  const defaultSelectedDistrict = props.selectedDistrict;
  const defaultSelectedMall = props.selectedMall;
  const staffName = props.staffName;
  const remarks = props.remarks;
  const select = props.select || (() => {});
  const errors = props.errors;
  const language = props.language;
  const receipts = props.receipts;
  const errorFields = Object.keys(errors);

  const { regionList } = useSelector((state) => ({
    regionList: state.storeModel.regionList,
  }));
  const transactionRegionList = parseTransactionRegionList(regionList, language);
  const districtList = defaultSelectedRegion?.districts;
  const mallList = defaultSelectedDistrict?.malls;

  const clearSelectedValue = (fieldName) => {
    switch (fieldName) {
      case 'district':
        select('mall', '');
        receipts.forEach((receipt, index) => {
          select(`receipts[${index}].store`, '');
        });
        break;
      case 'mall':
        receipts.forEach((receipt, index) => {
          select(`receipts[${index}].store`, '');
        });
        break;
      default:
        select('district', '');
        select('mall', '');
        receipts.forEach((receipt, index) => {
          select(`receipts[${index}].store`, '');
        });
        break;
    }
  };

  useEffect(() => {
    dispatch({
      type: 'storeModel/getRegionsList',
      payload: {},
    });
  }, [dispatch]);

  return (
    <div>
      <CustomTitleWithDropDown
        title={i18n.t('transaction.district', { locale: language })}
        labelPlaceholder={i18n.t('transaction.select_district', { locale: language })}
        needFilter={false}
        defaultValue={{
          value: defaultSelectedRegion,
          label: defaultSelectedRegion?.name,
        }}
        source={transactionRegionList}
        setValue={(item) => {
          select('region', item.value);
          clearSelectedValue();
        }}
        errors={errorFields}
        errorName={CreateTransactionError.region.name}
        errorMessage={errors?.['region']?.message || i18n.t('transaction.region_error', { locale: language })}
      />
      <CustomTitleWithDropDown
        title={i18n.t('transaction.sub_district', { locale: language })}
        labelPlaceholder={i18n.t('transaction.select_sub_district', { locale: language })}
        needFilter={false}
        defaultValue={{
          value: defaultSelectedDistrict,
          label: defaultSelectedDistrict?.name,
        }}
        source={districtList}
        setValue={(item) => {
          select('district', item.value);
          clearSelectedValue('district');
        }}
        loadMoreAction={() => {}}
        errors={errorFields}
        errorName={CreateTransactionError.district.name}
        errorMessage={errors?.['district']?.message || i18n.t('transaction.district_error', { locale: language })}
      />
      <CustomTitleWithDropDown
        title={i18n.t('transaction.mall', { locale: language })}
        labelPlaceholder={i18n.t('transaction.select_mall', { locale: language })}
        needFilter={false}
        defaultValue={{
          value: defaultSelectedMall,
          label: defaultSelectedMall?.name,
        }}
        source={mallList}
        setValue={(item) => {
          select('mall', item.value);
          clearSelectedValue('mall');
        }}
        loadMoreAction={() => {}}
        errors={errorFields}
        errorName={CreateTransactionError.mall.name}
        errorMessage={errors?.['mall']?.message || i18n.t('transaction.mall_error', { locale: language })}
      />
      <div>
        <CustomTitleLabel
          title={i18n.t('transaction.staff_name', { locale: language })}
        />
        <div>
          <input
            type="text"
            className={`text-input-field ${
              hasError(errors, 'staffName') ? 'error-field' : ''
            }`}
            onChange={(e) => {
              const value = e.target.value;
              // setValue('staffName', value, { shouldDirty: true });
              select('staffName', value)
            }}
            value={staffName || ''}
          />
        </div>
        <ReactHookFormErrorMessage errors={errors} id={'staffName'} />
      </div>
      <div className="d-flex flex-column">
        <CustomTitleLabel
          title={i18n.t('transaction.remarks', { locale: language })}
        />
        <textarea
          value={remarks}
          onChange={({ target }) => {
            // setValue('remarks', target.value, { shouldDirty: true });
            select('remarks', target.value)
          }}
          className="custom-textarea-input-box custom-textarea-input-font"
        />
      </div>
    </div>
  );
}

export default TransactionStepOneDropdownSection;
