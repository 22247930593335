import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import CustomDateTimeSelect from '../base/CustomDateTimeSelect';
import { errorMessage, hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import { CustomTitleLabel } from '../../components/earning/CustomBaseComponments';
import { zhHK } from 'date-fns/esm/locale';
import { registerLocale } from 'react-datepicker';
import { FORM_TYPE } from '../../config/CustomEnums';
import i18n from '../../I18n';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';
import {
  parseNameByLanguage,
  parseTranslations,
} from '../../models/CreateTransactionRecordModel';
import './ReceiptSection.scss';
import AuthButton from '../base/AuthButton';
import { Image } from 'react-bootstrap';
import deleteImage from '../../assets/images/drop_down_delete_selected.png';
import { getReceiptTitle } from './PurchaseReceiptSection';
import BaseRecentlySelector from '../base/BaseRecentlySelector';
import { CONCIERGE_STORE_NODE } from '../../services/StoreAPIHelper';

function ReceiptSection({
  language,
  isTCTransaction,
  selectedMall,
  formType,
  title,
  index,
  receipts,
}) {
  const dispatch = useDispatch();

  const { watch, setValue, formState } = useFormContext();
  const { errors } = formState;

  const transactionDateField = `receipts[${index}].transactionDate`;
  const storeField = `receipts[${index}].store`;
  const invoiceIdField = `receipts[${index}].invoiceId`;
  const paymentMethodField = `receipts[${index}].paymentMethod`;
  const totalValueField = `receipts[${index}].totalValue`;
  const submitNumberField = `receipts[${index}].submitNumber`;
  const receiptField = `receipts[${index}]`;
  const titleField = `receipts[${index}].title`;

  const selectedStore = watch(storeField);
  const transactionDate = watch(transactionDateField);
  const invoiceId = watch(invoiceIdField);
  const selectedPaymentMethod = watch(paymentMethodField);
  const totalValue = watch(totalValueField);
  const submitNumber = watch(submitNumberField);
  const receipt = watch(receiptField);

  const [isReset, setIsReset] = useState(false);

  if (isTCTransaction) {
    registerLocale('zhHK', zhHK);
  }
  const { stores, allPaymentMetheds } = useSelector((state) => ({
    stores: state.storeModel.storeList,
    allPaymentMetheds: state.createTransactionRecord.allPaymentMetheds,
  }));

  const storeList = selectedMall ? parseNameByLanguage(parseTranslations(stores), language) : [];
  const paymentMethedLIst = parseNameByLanguage(allPaymentMetheds, language);
  const isTrade = formType === FORM_TYPE.trade;
  const storeIdOrder = selectedMall.transactionSelectStoreIds || [];
  const recentlyStores = selectedMall?.transactionSelectStores?.edges.map((item) => {
    const translations = {}
    const apiTranslations = item?.node?.translations.edges || [];
  
    apiTranslations.forEach((translation) => {
      let language = translation.node.language;
      translations[language] = {
        name: translation.node.name,
      };
    });
    return {
      ...item.node,
      name: translations[language]?.name || item.node.name,
      icon: item?.node?.brand?.icon,
      storePK: item?.node?.pk,
      disabled: false,
    }
  }).sort(function(a, b){
    return storeIdOrder.indexOf(a.pk) - storeIdOrder.indexOf(b.pk)
  });

  // useEffect(() => {
  //   if (!invoiceId) {
  //     setValue(invoiceIdField, 9999999999, { shouldDirty: true });
  //   }
  // }, []);

  useEffect(() => {
    if (selectedMall) {
      if (formType === FORM_TYPE.notTrade) {
        dispatch({
          type: 'storeModel/getPagedStoreList',
          payload: {
            isSelectorLoad: true,
            node: CONCIERGE_STORE_NODE,
            search: '',
            storeType: 'CONCIERGE',
            mall_in_with_fresh_market: selectedMall.pk,
          },
        });
      }
      setIsReset(true);
    }
  }, [dispatch, selectedMall]);

  useEffect(() => {
    if (formType && formType === FORM_TYPE.notTrade) {
      setValue(storeField, stores?.[0] || {});
      setValue(
        paymentMethodField,
        allPaymentMetheds?.filter(
          (paymentMethod) => paymentMethod?.name === 'Others',
        )[0],
      );
    } else {
      if (!selectedPaymentMethod) {
        setValue(
          paymentMethodField,
          allPaymentMetheds?.filter(
            (paymentMethod) => paymentMethod?.name === 'Octopus card',
          )[0],
        );
      }
    }
  }, [formType, stores, allPaymentMetheds]);

  useEffect(() => {
    if (receipt?.invoiceId !== null && receipt?.store && (receipt?.totalValue || receipt?.submitNumber)) {
      setValue(titleField, getReceiptTitle(index, receipt, language, isTrade), { shouldDirty: true });
    }
  }, [selectedStore, invoiceId, totalValue, submitNumber]);

  return (
    <>
      <CustomTitleLabel title={title} />
      <div className="receipt-container">
        {isTrade ? (
          <>
            <CustomTitleWithDropDown
              title={i18n.t('transaction.store', { locale: language })}
              titleStyle={'no-top-space'}
              placeholder={i18n.t('transaction.search_for', {
                locale: language,
              })}
              labelPlaceholder={i18n.t('transaction.select_store', {
                locale: language,
              })}
              defaultValue={{
                value: selectedStore,
                label: selectedStore?.name,
              }}
              source={storeList}
              setValue={(item) => {
                setValue(storeField, item.value, { shouldDirty: true });
              }}
              errors={errors}
              errorName={storeField}
              errorMessage={errorMessage(errors, storeField)}
              loadMoreAction={'storeModel/getPagedStoreList'}
              filterAction={'storeModel/getPagedStoreList'}
              defaultFilter={{
                mall_in_with_fresh_market: selectedMall?.pk,
                isSelectorLoad: true,
                node: CONCIERGE_STORE_NODE,
                // storeType: formType === FORM_TYPE.notTrade ? 'CONCIERGE' : 'SHOP,OTHERS',
                pageSize: 100,
              }}
              disableAutoFocus={true}
              isReset={isReset}
              resetChange={() => {setIsReset(false)}}
            />
            {!!recentlyStores?.length && <BaseRecentlySelector
              title={i18n.t('transaction.recently_select_stores', { locale: language })}
              defaultValue={selectedStore}
              source={recentlyStores}
              setValue={(item) => {
                setValue(storeField, item.value, { shouldDirty: true });
              }}
            />}
            <BaseRecentlySelector
              title={i18n.t('transaction.payment_method', { locale: language })}
              defaultValue={selectedPaymentMethod}
              source={paymentMethedLIst}
              setValue={(item) => {
                setValue(paymentMethodField, item.value, { shouldDirty: true });
              }}
              errors={errors}
              errorName={paymentMethodField}
              errorMessage={errorMessage(errors, paymentMethodField)}
            />
          </>
        ) : null}
        <div>
          <CustomTitleLabel
            title={i18n.t('transaction.transaction_date', { locale: language })}
            className={isTrade ? '' : 'no-top-space'}
          />
          <CustomDateTimeSelect
            maxDate={new Date()}
            defaultTime={transactionDate}
            error={hasError(errors, transactionDateField)}
            onTimeChange={(date) => {
              setValue(transactionDateField, date, { shouldDirty: true });
            }}
            locale={isTCTransaction ? 'zhHK' : ''}
            dateFormat={isTCTransaction ? 'YYYY年M月D日' : ''}
          />
          <ReactHookFormErrorMessage
            errors={errors}
            id={transactionDateField}
          />
        </div>
        {isTrade ? (
          <>
            <div>
              <CustomTitleLabel
                title={i18n.t('transaction.total_value', { locale: language })}
              />
              <div>
                <input
                  type="number"
                  className={`text-input-field ${
                    hasError(errors, totalValueField) ? 'error-field' : ''
                  }`}
                  onChange={(e) => {
                    const value = e.target.value;
                    setValue(totalValueField, value, { shouldDirty: true });
                  }}
                  value={totalValue || ''}
                />
              </div>
              <div>
                <ReactHookFormErrorMessage
                  errors={errors}
                  id={totalValueField}
                />
              </div>
            </div>
            <div>
              <CustomTitleLabel
                title={i18n.t('transaction.invoice_id', { locale: language })}
              />
              <div>
                <input
                  type="text"
                  className={`text-input-field ${
                    hasError(errors, invoiceIdField) ? 'error-field' : ''
                  }`}
                  onChange={(e) => {
                    const value = e.target.value;
                    setValue(invoiceIdField, value, { shouldDirty: true });
                  }}
                  value={invoiceId}
                />
              </div>
              <div>
                <ReactHookFormErrorMessage
                  errors={errors}
                  id={invoiceIdField}
                />
              </div>
            </div>
          </>
        ) : (
          <div>
            <CustomTitleLabel
              title={i18n.t('transaction.quantity', { locale: language })}
            />
            <div>
              <input
                type="number"
                className={`text-input-field ${
                  hasError(errors, submitNumberField) ? 'error-field' : ''
                }`}
                onChange={(e) => {
                  const value = e.target.value;
                  setValue(submitNumberField, value, { shouldDirty: true });
                }}
                value={submitNumber || ''}
              />
            </div>
            <div>
              <ReactHookFormErrorMessage
                errors={errors}
                id={submitNumberField}
              />
            </div>
          </div>
        )}
        {receipts?.length > 1 ? (
          <div className="custom-delete-btn-container">
            <AuthButton
              children={
                <Image src={deleteImage} className="custom-delete-btn" />
              }
              customClass="custom-delete-btn"
              action={() => {
                const newReceiptsList = receipts?.filter((item, index1) => {
                  if (index !== index1) {
                    return item;
                  }
                });
                setValue('receipts', newReceiptsList, { shouldDirty: true });
              }}
            />
          </div>
        ) : null}
      </div>
    </>
  );
}

export default ReceiptSection;
