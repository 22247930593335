import {
  createAction,
  convertCursorToNumber,
  convertNumberToCursor,
} from '../utils';
import { loading } from './LoadingUtil';
import { formatDate, getDisplayDate } from '../utils/TimeFormatUtil';
import { createModel } from './BaseModel';
import { LanguageConfig, STAMP_EVENT_TYPE, STAMP_RECORD_TYPE } from '../config/CustomEnums';
import { getStampRecord, getStampRecords } from '../services/StampRecordsAPIHelper';
import i18n from '../I18n';

function getInitState() {
  return {
    listDisplayFields: {
      en: [
        { displayName: 'ID', fieldName: 'pk', linked: true },
        {
          displayName: 'First Name',
          fieldName: 'firstName',
          orderField: 'customer_first_name',
        },
        {
          displayName: 'Last Name',
          fieldName: 'lastName',
          orderField: 'customer_last_name',
        },
        {
          displayName: 'Membership ID',
          fieldName: 'membershipId',
          orderField: 'membershipId',
        },
        { displayName: 'Event Type', fieldName: 'displayEventType' },
        { displayName: 'Quantity', fieldName: 'stampQuantity', orderField: 'value' },
        { displayName: 'Record Type', fieldName: 'displayRecordType' },
        { displayName: 'Remark', fieldName: 'remarkEN' },
        { displayName: 'Create at', fieldName: 'creationDate', orderField: "creationDate"},
      ],
      'zh-Hant': [
        { displayName: 'ID', fieldName: 'pk', linked: true },
        {
          displayName: '名字',
          fieldName: 'firstName',
          orderField: 'customer_first_name',
        },
        {
          displayName: '姓氏',
          fieldName: 'lastName',
          orderField: 'customer_last_name',
        },
        {
          displayName: '會員編號',
          fieldName: 'membershipId',
          orderField: 'membershipId',
        },
        { displayName: '事件類型', fieldName: 'displayEventTypeTC' },
        { displayName: '數量', fieldName: 'stampQuantity', orderField: 'value' },
        { displayName: '紀錄類型', fieldName: 'displayRecordTypeTC' },
        { displayName: '備註', fieldName: 'remarkTC' },
        { displayName: '創建日期', fieldName: 'creationDate', orderField: "creationDate"},
      ]
    },
    maxQuantityValue: 0,
  };
}

function parseRecordType(recordType, language=LanguageConfig.english) {
  switch (recordType) {
    case 'CUSTOMER_EARN':
      return i18n.t('concierge.customer_earned', { locale: language });
    case 'CUSTOMER_USE':
      return i18n.t('concierge.customer_use', { locale: language });
    case 'ADMIN_REMOVE':
      return i18n.t('concierge.admin_removed', { locale: language });
    case 'STAMP_EXPIRED':
      return i18n.t('concierge.stamp_expired', { locale: language });
    default:
      break;
  }
}

function parseEventType(eventType, language=LanguageConfig.english) {
  switch (eventType) {
    case 'ADD_STAMP':
      return i18n.t('concierge.add_stamp', { locale: language });
    case 'REMOVE_STAMP':
      return i18n.t('concierge.remove_stamp', { locale: language });
    default:
      break;
  }
}

function parseStampRecord(item) {
  const remarks = item?.remarks ? JSON.parse(item.remarks) : '';
  return {
    pk: item.pk,
    id: item.id,
    membershipId: item.owner?.membershipId,
    firstName: item.owner?.firstName,
    lastName: item.owner?.lastName,
    name: item.owner
    ? item.owner?.nickname
      ? `${item.owner?.firstName} ${item.owner?.lastName} (${item.owner?.nickname})`
      : `${item.owner?.firstName} ${item.owner?.lastName}`
    : null,
    recordType: item.recordType,
    displayRecordType: parseRecordType(item.recordType),
    displayRecordTypeTC: parseRecordType(item.recordType, LanguageConfig.traditionalChinese),
    eventType: item.eventType,
    displayEventType: parseEventType(item.eventType),
    displayEventTypeTC: parseEventType(item.eventType, LanguageConfig.traditionalChinese),
    stampQuantity: item.value,
    remarkEN: remarks?.campaign_name_en,
    remarkTC: remarks?.campaign_name_tc,
    creationDate: getDisplayDate(item.recordAt),
    createDate: formatDate(item.recordAt, 'DD MMM yyyy (ddd), HH:mm a'),
    customer: item.customer,
  };
}

export default createModel({
  namespace: 'stampRecord',
  states: getInitState(),
  params: {
    objectKey: 'stampRecords',
    pkNode: 'StampRecordNode',
    detailAPI: getStampRecord,
    parseDetail: (data) => parseStampRecord(data?.stampRecord),
    initState: getInitState(),
  },
  reducers: {},
  effects: {
    getCurrentPageStampRecords: [
      function* ({ payload }, { call, put }) {
        const { page } = payload;
        let afterCursor = undefined;
        if (page > 1) {
          afterCursor = convertNumberToCursor((page - 1) * 20 - 1);
        }
        const serviceArgs = [getStampRecords, afterCursor, payload];
        function* onSuccess(data) {
          const stampRecordData = data.stampRecords?.edges;
          const pageInfo = data.stampRecords.pageInfo;
          const totalCount = data.stampRecords.totalCount;
          const startCursor = convertCursorToNumber(pageInfo.startCursor);
          const endCursor = convertCursorToNumber(pageInfo.endCursor);
          const stampRecordDataList = stampRecordData.map((item) =>
            parseStampRecord(item.node),
          );
          yield put(
            createAction('updateState')({
              pagedList: stampRecordDataList,
              pageInfo: {
                startCursor: isNaN(startCursor) ? 0 : startCursor + 1,
                endCursor: isNaN(endCursor) ? 0 : endCursor + 1,
              },
              totalCount,
              totalPage: Math.ceil(totalCount / 20),
              maxQuantityValue: data.stampRecords.maxStampValue,
            }),
          );
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
});
