import React, {useEffect, useState} from 'react';
import { Button, Image } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import './SelectMultipleStore.scss';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import closeIcon from '../../assets/images/close.svg';
function BaseMultipleSelectorDropDown({
  title = '',
  filterTitle = 'title',
  summaryTitle = '',
  fullSummaryTitle = '',
  summarySubTitle = '',
  value = [],
  onChange = ()=> {},
  disabled = false,
  showLineBrank = false,
  source = [],
  onFocus = () => {},
  customFilter = null,
  hasMarginTop = true,
  loadMoreAction = () => {},
  filterAction = () => {},
  placeholder = null,
  defaultFilter = {},
  customItem = () => {},
  addButtonInfo = {},
  fortunebagShowCouponLimitSection,
}) {
  return (
    <div style={{width: '780px', marginTop: hasMarginTop ? '30px' : 0}}
      onFocus={onFocus}
    >
      {!disabled && showLineBrank ? <div className='line'></div> : null}
      {title ? <label className="create-section-title">{title}</label> : null}
      {disabled ? null : (
        <CustomTitleWithDropDown 
          addButtonInfo={addButtonInfo}
          customClass={'filter-title'}
          title={filterTitle}
          source={source}
          placeholder={placeholder || 'Search by ID, name'}
          labelContainPk={true}
          defaultValue={value ? value : []}
          setValue={(v) => {
            onChange(v);
          }}
          loadMoreAction={loadMoreAction}
          customFilter={customFilter}
          filterAction={filterAction}
          defaultFilter={{
            isSelectorLoad: true,
            ...defaultFilter,
          }}
          multiple={true}
          toolTips={true}
          notShowLabel={true}
          disableAutoFocus={true}
          customItem={customItem}
        />
      )}
      <div className='title-container'>
        <CustomTitleLabel 
          title={fullSummaryTitle ? fullSummaryTitle  : `Summary of selected ${summaryTitle || filterTitle} (${value.length})`}
        />
        {disabled ? null :  <Button className="clear-filter" style={{position: "relative", marginBottom:"7px"}}
          onClick={() => {
            onChange()
          }}
        >Reset</Button>}
      </div>
      {summarySubTitle ? <label className="tips-message"> {summarySubTitle}</label> : null}
      {value && value?.length > 0 ? 
      value?.map((item, index) => {
        return <div className='store-list-item-container'>
          <label className='store-list-item'>
          {fortunebagShowCouponLimitSection ? item?.value?.stock + " - " : null}
          {item.label}
          </label>
          {disabled ? null :<Image
            src={closeIcon}
            className="list-item-delete"
            onClick={ (e) =>{
              e.stopPropagation();
              const newValue = value?.filter((filterItem) => filterItem.value.pk != item.value.pk)
              // setValue("selectedBrand", newValue, {shouldDirty: true});
              onChange(newValue)
            }}
          />}
        </div>
      })
      : '-'}
    </div>
  );
}

export default BaseMultipleSelectorDropDown;