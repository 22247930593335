import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import ContentSections from '../../components/base/ContentSections';
import {
  SaveAndBackButtons,
  SaveAndBackWithOutTempButtons,
} from '../../components/base/BottomStepComponent';
import { validate } from '../../containers/customers/customerGroup/CreateCustomerGroupValidate';
import { PermissionCodes } from '../../config/PermissionCodes';
import CreateCustomerGroupGeneral from './CreateCustomerGroupGeneral';
import TargetCustomerSection from './TargetCustomerSection';


function CreateCustomerGroupStepOne({
  setShowDeletePrompt = () => {},
}) {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const { watch, getValues, setValue, setError, formState, clearErrors } = useFormContext();
  const { errors } = formState;

  const save = (isCreate) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 0,
    });
    if (!isValid) {
      return;
    }
    if (isCreate) {
      dispatch({
        type: 'customerGroup/createCustomerGroup',
        payload: {
          values: getValues(),
        }
      });
    } else {
      dispatch({
        type: 'customerGroup/updateCustomerGroup',
        payload: {
          values: getValues(),
          // afterAction: () => {
          //   history.push('/customer_group');
          // },
        }
      });
    }
  };

  const sections = [
    <CreateCustomerGroupGeneral />,
    <TargetCustomerSection />
  ];

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      {params.id ? (
        <SaveAndBackButtons
          backAction={() => {
            history.push('/customer_group');
          }}
          saveTempText={'Delete'}
          tempRequires={PermissionCodes.deleteCustomerGroup}
          saveTempAction={() => {
            setShowDeletePrompt(true);
          }}
          saveText={'Update'}
          saveAction={() => {
            save(false);
          }}
        />
      ) : (
        <SaveAndBackWithOutTempButtons
          backAction={() => {
            history.push('/customer_group');
          }}
          saveText="Save"
          saveAction={() => {
            save(true);
          }}
        />
      )}
    </>
  );
}

export default CreateCustomerGroupStepOne;
