import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ImportResource } from '../../../models/DownloadImportModel';
import { createAction } from '../../../utils';
import CustomSwitchButton from '../../base/CustomSwitchButton';
import BaseMutipleSelectorV2 from '../../base/BaseMultipleSelectorV2';
import { CustomTitleLabel } from '../../earning/CustomBaseComponments';
import BasePrompt from '../../base/prompt/BasePrompt';
import CheckboxItem from './CheckboxItem';
import CustomDateSelect from '../../base/CustomDateSelect';
import BaseMultipleSelectorDropDown from '../../base/BaseMultipleSelectorDropDown';

export const EXPORT_BY = {
  coupon: 'COUPON',
  campaign: 'CAMPAIGN',
  couponset: 'COUPONSET',
};

const ExportCouponView = ({
  show,
  onHide,
  allData,
  campaigns,
  dispatch,
  exportBy,
  testData,
}) => {
  const [includeTransaction, setIncludeTransaction] = useState(false);
  const [includeForm, setIncludeForm] = useState(false);
  const [selectedCouponTemplates, setSelectedCouponTemplates] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [enableSeparate, setEnableSeparate] = useState(false);
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  useEffect(() => {
    if (!show) {
      setIncludeTransaction(false);
      setIncludeForm(false);
      setSelectedCouponTemplates([]);
      setSelectedCampaigns([]);
      setEnableSeparate(false);
    }
  }, [show, dispatch]);

  useEffect(() => {
    dispatch({
      type: 'campaignList/getCampaignList',
      payload: {
        sort: '-pk',
        allDisplayList: true,
        isSimpleList: true,
        disabledLoading: true,
      },
    });    
    return () => dispatch(createAction('campaignList/clearData')());
  }, []);

  useEffect(() => {
    if (!allData?.length && show) {
      dispatch({
        type: 'couponList/getAllList',
        payload: {
          sort: '-pk',
        },
      });
    }
  }, [allData?.length, show]);

  const body = () => (
    <>
      {exportBy === EXPORT_BY.campaign ? (
        <>
          <div>
            <CustomTitleLabel title="Export Date From" />
            <CustomDateSelect
              onDateChange={(date) => {
                setDateFrom(date)
              }}
              maxDate={dateTo}
            />
            <CustomTitleLabel title="Export Date To" />
            <CustomDateSelect
              onDateChange={(date) => {
                setDateTo(date)
              }}
              minDate={dateFrom}
            />
            <BaseMultipleSelectorDropDown
              filterTitle={'Campaign(s)(Option)'}
              summaryTitle={'campaigns'}
              placeholder='Search by name or ID'
              source={campaigns}
              value={selectedCampaigns}
              onChange={(v) => {
                if (v?.length > 0) {
                  v.sort(function(a,b){return a?.value?.pk-b?.value?.pk});
                }
                const value = Array.isArray(v) ? v : v ? [v] : [];
                setSelectedCampaigns(value);
              }}
              hasMarginTop={false}
              loadMoreAction={'campaignList/getCampaignList'}
              filterAction={'campaignList/getCampaignList'}
              defaultFilter={{
                sort: '-pk',
                allDisplayList: true,
                isSimpleList: true,
                disabledLoading: true,
              }}
            />
          </div>
        </>
      ) : (
        <>
          <div>
            <CustomTitleLabel title="Coupons(optional)" />
            <CheckboxItem
              title="Include customer transaction record"
              checked={includeTransaction}
              onChange={() => {
                setIncludeTransaction(!includeTransaction);
              }}
            />
            <CheckboxItem
              title="Include data from “high-value coupon form”"
              checked={includeForm}
              onChange={() => {
                setIncludeForm(!includeForm);
              }}
            />
          </div>
          <div>
            <BaseMutipleSelectorV2
              title="Coupon set"
              size={['shorter']}
              namespace="campaignList"
              searchPlaceholder="Search by name or ID"
              data={{
                sourceData: allData,
                targetData: selectedCouponTemplates,
                targetChange: setSelectedCouponTemplates,
              }}
              custom={{
                customItem: (value) => (
                  <>
                    <label className="slot-title">[{value.pk}]</label>
                    {value.name}
                  </>
                ),
                customFilter: (inputValue, option) => {
                  const inputIgnoreCase = inputValue?.toLowerCase();
                  const optionIgnoreCase = option?.name?.toLowerCase();
                  return (
                    optionIgnoreCase?.includes(inputIgnoreCase) ||
                    option.pk == inputValue
                  );
                },
              }}
            />
          </div>
        </>
      )}
      <div>
        <label className="create-section-label create-section-label-top-space">
          Export in separate files by coupon set(s)
        </label>
        <div
          className="second-section_description"
          style={{ marginBottom: 12 }}
        >
          If On, multiple CSV files will be downloaded based on different coupon
          set. If off, all the data merge into one CSV file.
        </div>

        <CustomSwitchButton
          defaultChecked={enableSeparate}
          onChange={setEnableSeparate}
        />
      </div>
    </>
  );
  return (
    <BasePrompt
      show={show}
      closeAction={onHide}
      rightButton={{
        text: 'Export',
        action: () => {
          console.log('@export');
          let data = {};
          if (exportBy === EXPORT_BY.campaign) {
            data = {
              from: ImportResource.couponRecord,
              related: {
                targetCampaignIds: selectedCampaigns.map((item) => item?.value?.pk),
                separateFiles: enableSeparate,
                dateFrom: dateFrom,
                dateTo: dateTo,
              },
            };
          } else {
            data = {
              from: ImportResource.couponRecord,
              related: {
                includeTransaction,
                includeHighValueCouponForm: includeForm,
                targetCouponTemplateIds: selectedCouponTemplates.map(
                  (item) => item?.pk,
                ),
                separateFiles: enableSeparate,
              },
            };
          }

          dispatch(createAction('downloadAndImport/createDownloadTask')(data));
          onHide();
        },
        disabled:
          (exportBy !== EXPORT_BY.campaign &&
            selectedCouponTemplates.length === 0),
      }}
      title="CSV configuration"
      otherBody={body}
    />
  );
};

const mapPropsToState = (state) => ({
  allData: state.couponList.notPagedAllList,
  campaigns: state.campaignList.allList,
});

export default connect(mapPropsToState)(ExportCouponView);
