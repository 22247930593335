import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './CustomTitleWithDropDown.scss';
import FilterableDropdown from '../base/FilterableDropdown';
import { createAction } from '../../utils';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import ErrorFieldMessage, { hasError } from '../base/ErrorFieldMessage';
import AuthButton from '../base/AuthButton';
import FilterableDropdownWithoutLabel from '../base/FilterableDropdownWithoutLabel';

export function CustomTitleWithDropDown(props) {
  const dispatch = useDispatch();
  const customClass = props.customClass || '';
  const needFilter = props.needFilter;
  const placeholder = props.placeholder || '';
  const labelPlaceholder = props.labelPlaceholder || '';
  const defaultValue = props.defaultValue || null;
  const title = props.title;
  const source = props.source || [];
  const labelContainPk = props.labelContainPk || false;
  const setValue = props.setValue || (() => {});
  const errors = props.errors || [];
  const errorName = props.errorName || props.title?.toLowerCase();
  const errorMessage = props.errorMessage;
  const disabled = props.disabled || false;
  const onFocus = props.onFocus || (() => {});
  const addButtonInfo = props.addButtonInfo || {};
  const isError = props.isError || false;
  const disableAutoFocus = props.disableAutoFocus || false;
  const disableDropDown = props.disableDropDown || false;

  const loadMoreAction = props.loadMoreAction;
  const customFilter = props.customFilter;
  const filterAction = props.filterAction;
  const defaultFilter = props.defaultFilter || {};
  const notShowLabel = props.notShowLabel || false;
  const customItem = props.customItem || (() => {});

  const existError = Array.isArray(errors) ? errors.includes(errorName) : hasError(errors, errorName);

  const filterComponent = {};
  filterComponent.name = notShowLabel ? FilterableDropdownWithoutLabel : FilterableDropdown;

  const onSelectChange = (value) => {
    setValue(value);
  };
  const options = source?.map((item) => {
    return {
      value: item,
      label: customItem(item) ? customItem(item) : labelContainPk ? `[ID:${item?.pk}] ${item?.name}` : item?.name,
    };
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [filterKey, setFilterKey] = useState('');

  useEffect(() => {
    if (loadMoreAction) {
      dispatch(
        createAction(loadMoreAction)({
          page: currentPage,
          search: filterKey,
          searchKey: filterKey,
          isSelectorLoad: true,
          ...defaultFilter,
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (props.isReset && loadMoreAction) {
      setCurrentPage(1);
      dispatch(
        createAction(loadMoreAction)({
          page: 1,
          search: filterKey,
          searchKey: filterKey,
          isSelectorLoad: true,
          ...defaultFilter,
        }),
      );
      props.resetChange && props.resetChange();
    }
  }, [props.isReset]);

  const loadMore = () => {
    if (!loadMoreAction) {
      return
    }
    const nextPage = currentPage + 1;
    console.log('@@83: ', nextPage, filterKey);
    dispatch(
      createAction(loadMoreAction)({
        page: nextPage,
        search: filterKey,
        searchKey: filterKey,
        isSelectorLoad: true,
        ...defaultFilter,
      }),
    );

    setCurrentPage(nextPage);
  };

  const filter = (search) => {
    let nextPage = currentPage + 1;
    if (search !== filterKey) {
      nextPage = 1;
    }
    console.log('@@102: ', search, nextPage);
    dispatch(
      createAction(filterAction)({
        page: nextPage,
        search: search,
        searchKey: search,
        isSelectorLoad: true,
        ...defaultFilter,
      }),
    );

    setCurrentPage(nextPage);
    setFilterKey(search);
  };

  return (
    <div style={{ display: 'grid', width: '100%' }}>
      {title ? (
        <CustomTitleLabel className={props.titleStyle} title={title} />
      ) : null}
      {disabled ? (
        <label className="custom-record-normal-font custom-timeline-time-color">
          {defaultValue?.label}
        </label>
      ) : (
        <>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <filterComponent.name
              selectChange={onSelectChange}
              isPromptDropdown={true}
              options={options}
              defaultValue={defaultValue}
              needFilter={needFilter}
              customClass={`custom-title-dropdown ${customClass} ${
                existError || isError ? 'error-field' : ''
              }`}
              onToggle={onFocus}
              loadMoreAction={loadMore}
              filterAction={customFilter || filter}
              placeholder={placeholder}
              labelPlaceholder={labelPlaceholder}
              disableAutoFocus={disableAutoFocus}
              disableDropDown={disableDropDown}
              muitipleSelect={props.multiple}
              toolTips={props.toolTips}
            />
            <AuthButton {...addButtonInfo} />
          </div>
          <ErrorFieldMessage
            error={existError}
            id={errorName}
            message={errorMessage || `Please provide ${errorName}`}
            extra={'list-new-line'}
          />
        </>
      )}
    </div>
  );
}

export function CustomTitleWithDropDownAndInput(props) {
  const dispatch = useDispatch();

  const { needFilter, customClass, unit, inputCustomClass } = props;
  const defaultValue = props.defaultValue || null;
  const defaultInputValue = props.defaultInputValue || null;
  const title = props.title;
  const source = props.source || [];
  const setValue = props.setValue || (() => {});
  const setInputValue = props.setInputValue || (() => {});
  const errors = props.errors || [];
  const disabled = props.disabled || false;
  const onFocus = props.onFocus || (() => {});
  const inputOnFocusChange = props.inputOnFocusChange || (() => {});

  const onSelectChange = (value) => {
    setValue(value);
  };
  const options = source?.map((item) => {
    return { value: item.pk, label: item?.name };
  });

  const isError = (field) => {
    return errors.includes(field);
  };


  return (
    <div className="d-flex flex-column">
      <CustomTitleLabel title={title} />
      {disabled ? (
        <label className="custom-record-normal-font custom-timeline-time-color">
          {defaultValue.label} {defaultInputValue}
        </label>
      ) : (
        <>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <FilterableDropdown
              customClass={`custom-title-dropdown ${customClass} ${
                isError(title.toLowerCase()) ? 'error-field' : ''
              }}`}
              selectChange={onSelectChange}
              isPromptDropdown={false}
              options={options}
              defaultValue={defaultValue}
              needFilter={needFilter}
              onToggle={onFocus}
            />

            <input
              type="number"
              onChange={({ target }) => setInputValue(target.value)}
              className={`custom-number-input-long ${inputCustomClass} ${
                isError(title.toLowerCase()) ? 'error-field' : ''
              }`}
              value={defaultInputValue}
              onFocus={inputOnFocusChange}
            />
            <label className="help-message">{unit}</label>
          </div>
          <ErrorFieldMessage
            error={isError(title.toLowerCase())}
            id={title.toLowerCase()}
            message={`Please provide ${title.toLowerCase()}`}
          />
        </>
      )}
    </div>
  );
}
