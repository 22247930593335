import React, { Component } from 'react';
import './CouponInstructionSection.scss';
import { useDispatch, connect } from 'react-redux';
import { createAction } from '../../../utils';
import CustomMarkDownArea, { ReactHookFormMarkDown } from '../../base/CustomMarkDownArea';
import i18n from '../../../I18n';
import { useFormContext } from 'react-hook-form';
import { InputFieldControl2 } from '../../base/InputFieldControl';

const CouponDetailSection = ({
  language,
  onFocus = () => {}
}) => {
  return (
    <>
    <InputFieldControl2
      areaTitle={i18n.t('detail', { locale: language })} 
      name={`translations.${language}.detailSectionTitle`}
      title={i18n.t('detail_section_title', { locale: language })}
      onFocus={() => {
        onFocus('detailsTitle');
      }}
    />
    <ReactHookFormMarkDown
      content={{
        label: i18n.t('detail_section_content', { locale: language }),
        key: `translations.${language}.detailSectionContent`,
        focus: () => {
          onFocus('detailsContent')
        }
      }}
    />
  </>
  );
  
}

const mapPropsToState = (state) => ({

})

export default connect(mapPropsToState)(CouponDetailSection);
