import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import CustomDateTimeSelect from '../base/CustomDateTimeSelect';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import SpecificCustomSwitchButton from '../../containers/merchants/stores/SpecificCustomSwitchButton';

function BonusPeriodSection(props) {
  const title = props.title;
  const titleDescription = props.titleDescription;
  const switchButtonTitle = props.switchButtonTitle;
  const startDate = props.startDate;
  const startDateChange = props.startDateChange || (() => {});
  const endDate = props.endDate;
  const endDateChange = props.endDateChange || (() => {});
  const maxDate = props.maxDate;
  const minDate = props.minDate;
  const reminder = props.reminder;
  const disabled = props.disabled;
  const startErrorObject = props.startErrorObject || {};
  const endErrorObject = props.endErrorObject || {};
  const switchButtonError = props.switchButtonError || {};
  const showPeriod = props.showPeriod;
  const setShowPeriod = props.setShowPeriod || {};

  const dataPicker = ({
    title,
    defaultTime,
    errorObject = {},
    onTimeChange = () => {},
    hasMaxDate = false,
    hasMinDate = false,
  }) => {
    return (
      <div>
        <label className="create-section-label create-section-label-bottom-space">
          {title}
        </label>
        <CustomDateTimeSelect
          disabled={!showPeriod}
          defaultTime={defaultTime}
          maxDate={hasMaxDate ? maxDate : null}
          minDate={hasMinDate ? minDate : null}
          error={!!errorObject.message}
          onTimeChange={onTimeChange}
        />
        {errorObject.message ? null : (
          <label className="tips-message">{reminder}</label>
        )}
        <ErrorFieldMessage
          id={errorObject.id}
          error={errorObject.message}
          message={errorObject.message}
        />
      </div>
    );
  };

  return (
    <>
      {title ? <label className="create-section-title">{title}</label> : null}
      {titleDescription ? (
        <label className="section-short-description">{titleDescription}</label>
      ) : null}
      {switchButtonTitle ? (
        <label className="create-section-label create-section-label-bottom-space">
          {switchButtonTitle}
        </label>
      ) : null}
      <div style={{ marginTop: '8px' }}>
        <SpecificCustomSwitchButton
          disabled={disabled}
          checked={showPeriod}
          onChange={setShowPeriod}
        />
      </div>
      <ErrorFieldMessage
        id={switchButtonError.id}
        error={switchButtonError.message}
        message={switchButtonError.message}
      />
      <Collapse in={showPeriod}>
        <div>
          {dataPicker({
            title: 'Start date',
            defaultTime: startDate,
            onTimeChange: startDateChange,
            errorObject: startErrorObject,
            hasMaxDate: true,
          })}
          {dataPicker({
            title: 'End date',
            defaultTime: endDate,
            onTimeChange: endDateChange,
            errorObject: endErrorObject,
            hasMinDate: true,
          })}
        </div>
      </Collapse>
    </>
  );
}

export default BonusPeriodSection;
