import { APIStatus, SESSION_KEYS } from '../config/CustomEnums';
import {
  deleteAppVersion,
  getAppVersions,
  createAppVersion,
} from '../services/AppVersionApiHelper';
import { getCampignPeriodDateTime } from '../utils/TimeFormatUtil';
import { createModel } from './BaseModel';

const getInitialState = () => ({
  oneAdminGroup: {},
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'iOS version', fieldName: 'iosAppVersionName' },
    { displayName: 'Android version', fieldName: 'androidAppVersionName' },
    { displayName: 'Create at', fieldName: 'creationDate' },
  ],
});

const parseAppVersion = (item) => {
  return {
    ...item,
    creationDate: getCampignPeriodDateTime(item.creationDate),
  };
};

const parseAppVersionList = (data) => {
  return data.map((item) => parseAppVersion(item.node));
};

export default createModel({
  namespace: 'appVersion',
  states: getInitialState(),
  params: {
    listAPI: getAppVersions,
    parse: (data) => parseAppVersionList(data?.appVersions?.edges),
    pkNode: 'AppVersionNode',
    deleteAPI: deleteAppVersion,
    sessionKey: SESSION_KEYS.APP_VERSION_SESSION_KEY,
    objectKey: 'appVersions',
    initState: getInitialState(),
  },

  reducers: {},

  effects: {
    createOrUpdate: [
      function* ({ payload }, { put }) {
        const { values, appVersionID } = payload;
        yield put({
          type: 'updateState',
          payload: {
            createStatus: APIStatus.calling,
          },
        });
        const input = {
          iosAppVersionName: values?.iosAppVersionName,
          androidAppVersionName: values?.androidAppVersionName,
        }

        const serviceArgs = [createAppVersion, input];
        yield put({
          type: 'responseCreateOrUpdate',
          payload: { serviceArgs, isCreate: true },
        });
      },
      { type: 'takeLatest' },
    ],
  },
});
