import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import { CampaignDisplayIn, CampaignType, MissionCampaignParticipant, MissionCampaignMode } from '../../../config/CustomEnums';
import BaseMultipleSelectorV2 from '../../base/BaseMultipleSelectorV2';
import InputFieldControl, { InputFieldControl2 } from '../../base/InputFieldControl';
import { Row } from 'react-bootstrap';
import CustomRadios from '../../base/CustomRadios';
import { ParticipantRewardConfig } from './CreateCampaignStepOneConfigs';
import { hasError, ReactHookFormErrorMessage } from '../../base/ErrorFieldMessage';
import { SimpleListWithCouponNode } from '../../../services/CampaignAPIHelper';


function DynamicMissionSection({
  earningCampaignList,
  onFocus = () => {},
}) {
  const { getValues, watch, setValue, control, formState } = useFormContext();
  const dispatch = useDispatch();
  const { errors } = formState;

  const watchLinkedEarningCampaigns = watch("linkedEarningCampaigns")
  console.log("@16", getValues())
  const watchCampaignParticipant = getValues('missionCampaignParticipant')
  const { pk } = getValues();
  useEffect(() => {
    dispatch({
      type: 'campaignList/getCampaignList',
      payload: {
        displayIn: CampaignDisplayIn.mission,
        type: CampaignType.earningRulesCampaign,
        isAll: true,
        tasksForMissionMode: MissionCampaignMode.dynamicRewards, 
        fieldNode: SimpleListWithCouponNode,
        missionCampaignId: pk || -1,
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  return (
    <>
      <label className="create-section-title">{'Missions'}</label>
      <BaseMultipleSelectorV2
        title={'Link to earning campaign (for earning campaign links to "common type" coupon only)'}
        namespace="campaignModel"
        custom={{
          customItem: (item) => {
            return `[ID:${item.pk}] ${item.name}`
          }
        }}
        data={{
          sourceData: earningCampaignList,
          targetData: watchLinkedEarningCampaigns,
          targetChange: (value) => {
            setValue('linkedEarningCampaigns', value, {
              shouldDirty: true,
            });
          },
        }}
        error={{
          error: hasError(errors, 'linkedEarningCampaigns'),
        }}
        onFocusFunc={() => onFocus('linkCampaign')}
      />
      <ReactHookFormErrorMessage errors={errors} id='linkedEarningCampaigns' />
      {watchLinkedEarningCampaigns?.length > 0 ? <div style={{marginTop: "25px"}}>
        <label className='create-section-label'>{'Earning campaign order in Frontend'}</label>
        {watchLinkedEarningCampaigns?.map((item) => 
          <InputFieldControl2
            name={`earningCampaignRelation.${item.id}.order`}
            customTitleClass={'no-title'}
            type='number'
            unit={item.name}
            className='coupon-campaign-section-input'
            useDefaultValue={false}
          />
        )
      }
      </div> : null}
      { watchCampaignParticipant === MissionCampaignParticipant.team ? <>
        {watchLinkedEarningCampaigns?.length > 0 ? <div style={{marginTop: "25px"}}>
          <label className='create-section-label'>{'Who can get the reward (Reward from Linked earning campaign)'}</label>
          {watchLinkedEarningCampaigns?.map((item) => 
            (<div>
              <label className='participant-title'>{`${item?.rewardCoupon?.name} (From earning campaign: ${item.name})`}</label>
              <Row className="participant-row">
                <Controller 
                  control={control}
                  name={`earningCampaignRelation.${item.id}.recipient`}
                  render={({field, fieldState: { error }}) => (
                    <CustomRadios
                      onChange={(value) => {
                        setValue(`earningCampaignRelation.${item.id}.recipient`, value, {shouldDirty: true})
                      }}
                      default={field.value}
                      options={ParticipantRewardConfig}
                    />
                  )}
                />
              </Row>
            </div>)
          )}
        </div> : null }
      </> : null
      }
    </>
  );
}

const mapPropsToState = (state) => ({
  earningCampaignList : state.campaignList.pagedList,
})

export default connect(mapPropsToState)(DynamicMissionSection);
