import React, { useEffect, useState } from 'react';
import './EStampCampaignList.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { campaignListBreads, eStampCampaignListBreads } from '../../../config/CustomBreadConfig';
import { getHashKeyString, createAction } from '../../../utils';
import {
  URLFragments,
  URLFilterKeys,
} from '../../../components/campaign/CampaignUrlConfig';
import {
  CampaignDisplayIn,
  CampaignType,
  DELETE_RELATED_SECTIONS,
} from '../../../config/CustomEnums';
import DateRangeSelection from '../../../components/dashboard/DateRangeSelection';
import DateRangeCompare from '../../../components/dashboard/DateRangeCompare';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import DeleteCampaignPrompt from '../../../components/campaign/campaignList/DeleteCamapignPrompt';
import queryString from 'query-string';
import BasePrompt from '../../../components/base/prompt/BasePrompt';
import Filter from './Filter';

const TabsConfig = [
  { key: CampaignType.allTypes, name: 'All Types' },
  { key: CampaignType.estampCampaign, name: 'Stamp' },
  // { key: CampaignType.generalMessageCampaign, name: 'General Message' },
  // { key: CampaignType.htmlCampaign, name: 'HTML'}
];

function EStampCampaignList() {
  const dispatch = useDispatch();
  const [showPrompt, setShowPrompt] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const activeTab = getHashKeyString(location.hash) || URLFragments.list;
  const {
    listFields,
    dataList,
    pageInfo,
    totalPage,
    totalCount,
    isSuperuser,
    userPermissions,
    resume,
  } = useSelector((state) => ({
    listFields: state.campaignList.listFields,
    dataList: state.campaignList.pagedList,
    pageInfo: state.campaignList.pageInfo,
    totalPage: state.campaignList.totalPage,
    totalCount: state.campaignList.totalCount,
    checkedList: state.campaignList.checkedList,
    isSuperuser: state.users.isSuperuser,
    userPermissions: state.users.userPermissions,
    resume: state.campaignList.eStampResume,
  }));

  const getFilterMapFromUrl = () => {
    const querySearch = queryString.parse(location.search);
    const type =
      queryString.parse(location.search)[URLFilterKeys.type] ||
      CampaignType.allTypes;
    const searchKey = queryString.parse(location.search)[
      URLFilterKeys.searchKey
    ];
    const stringRank = queryString.parse(location.search)[URLFilterKeys.rank];
    const stringPage =
      queryString.parse(location.search)[URLFilterKeys.page] || 1;
    const rank = stringRank === 'false';
    const page = parseInt(stringPage);
    console.log("@75", querySearch)
    return {
      ...querySearch,
      displayIn: CampaignDisplayIn.estamp,
      page: page,
      type: type,
      reverse: !rank,
      searchKey: searchKey,
    };
  };

  const getList = () => {
    const data = getFilterMapFromUrl();
    dispatch(createAction('campaignList/getCampaignList')({ ...data }));
  };

  useEffect(() => {
    if (activeTab === URLFragments.list) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, location]);

  useEffect(() => {
    dispatch({ type: 'campaignList/campaignResume', payload: {displayIn: CampaignDisplayIn.estamp} });

    dispatch(createAction('brand/getSimpleBrandList')({}));
    dispatch(createAction('customerGroup/getGroupList')({
      isSimpleList: true,
    }));
    dispatch(
      createAction('segments/getSegmentsList')({
        type: 'all',
        isSelectorLoad: true,
      }),
    );
    // dispatch(createAction('levels/getLevelList')({}));

    return () => {
      dispatch({ type: 'campaignList/clearData', payload: {displayIn: CampaignDisplayIn.estamp} });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getButton = (name, className, resume) => {
    return (
      <AuthButton
        title={name}
        className={className}
        action={() => {
          dispatch({ type: 'createCampaign/clearData', payload: {displayIn: CampaignDisplayIn.estamp} });
          history.push({
            pathname: '/estamp_campaigns/create',
            state: {resume}
          });
        }}
        requires={PermissionCodes.addCampaign}
      />
    );
  };

  const buttons = [getButton('Create Campaign', 'btn-further', false)];

  if (resume?.showResumeButton) {
    buttons.unshift(getButton('Resume', 'campaign-resume-button btn-further', true));
  }

  const groupActions = [
    {
      name: 'Delete',
      action: (campaign) => setShowPrompt(true),
      requires: PermissionCodes.deleteCampaign,
    },
  ];

  const tabs = [
    // {
    //   name: URLFragments.overview,
    //   content: <CampaignOverviewTab />,
    // },
    isSuperuser ||
    userPermissions?.filter(
      (val) => val.split('_')[1] === PermissionCodes.campaign,
    ).length > 0
      ? {
          name: URLFragments.list,
          content: (
            <BaseTabListContainer
              hideTab={false}
              maxTabWidth={400}
              tabs={TabsConfig}
              groupActions={groupActions}
              pageInfo={pageInfo}
              totalCount={totalCount}
              filter={{
                hasFilter: true,
                componment: Filter,
                displayIn: CampaignDisplayIn.estamp
              }}
              listContent={
                <BaseListContainer
                  fields={listFields}
                  dataList={dataList}
                  totalPage={totalPage}
                  model={'campaignList'}
                  actions={['Detail', 'Edit', 'Duplicate', 'Delete']}
                  permissionGroup={PermissionCodes.campaign}
                  deleteInfo={{
                    data: [],
                    title: 'Campaign',
                    relatedName: '',
                    onComfirm: {},
                    relatedSections: DELETE_RELATED_SECTIONS.CAMPAIGN,
                  }}
                  hasOtherActions={true}
                  customActions={{
                    publish: (item) => {
                      dispatch(
                        createAction(`createCampaign/publishCampaign`)({
                          campaignPk: item.pk,
                          isPublished: item.isPublished,
                          afterAction: () => getList(),
                        }),
                      );
                    },
                  }}
                />
              }
            />
          ),
        }
      : {},
  ];

  const hideResumePop = (hide) => {
    dispatch({
      type: 'campaignList/resumeAction',
      payload: { hideResumePop: hide , displayIn: CampaignDisplayIn.estamp},
    });
  };

  return (
    <>
      <CustomListComponent
        caution={{
          detail: 'Campaign list , should edit with the following',
          title: 'Campaigns',
        }}
        onTabChange={(key) => {
          let search = '';
          if (key !== URLFragments.overview) {
            search = queryString.stringify({
              [URLFilterKeys.type]: CampaignType.allTypes,
              [URLFilterKeys.page]: 1,
              [URLFilterKeys.searchKey]: '',
              [URLFilterKeys.rank]: true,
            });
          }
          history.push({ pathname: '/estamp_campaigns', hash: key, search: search });
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb breadcrumbs={eStampCampaignListBreads()} />}
        buttons={buttons}
        renderCompare={() => {
          return activeTab === URLFragments.overview ? (
            <DateRangeCompare inCustomListComponent />
          ) : null;
        }}
        renderTabsRight={() => {
          return activeTab === URLFragments.overview ? (
            <DateRangeSelection
              onTab
              applyDateRangeEvent={(startDate, endDate) => {
                dispatch(
                  createAction('overview/getCampaignsData')({
                    startDate: startDate.format('YYYY-MM-DD'),
                    endDate: endDate.format('YYYY-MM-DD'),
                  }),
                );
              }}
              clearDataEvent={() => {
                dispatch(createAction('overview/clearState')());
              }}
            />
          ) : null;
        }}
        tabs={tabs}
      />
      <DeleteCampaignPrompt
        show={showPrompt}
        onClose={() => setShowPrompt(false)}
        onConfirm={() => {
          setShowPrompt(false);
          dispatch(
            createAction('createCampaign/deleteCampaigns')({
              afterAction: () => {
                history.push(location);
              },
            }),
          );
        }}
      />
      <BasePrompt
        show={resume?.showResumePop}
        closeAction={() => hideResumePop(false)}
        rightButton={{
          text: 'Resume',
          action: () => {
            hideResumePop(false);
            history.push({
              pathname: '/estamp_campaigns/create',
              state: { resume: true }
            });
          },
        }}
        leftButton={{
          text: `Dismiss and don't remind me again`,
          action: () => hideResumePop(true),
        }}
        title={'You have unfinished campaign '}
        description={`You have unfinished campaign before. You can resume it by click “Resume” button at the top of the page`}
      />
    </>
  );
}

export default EStampCampaignList;
