import { getSearchKey } from '../utils';
import { BaseHelper } from './BaseGraphQLAPIHelper';

const STORE_NODE = `{
  id
  pk
  name
  displayPriority
  translations {
    edges {
      node {
        id
        pk
        language
        name
      }
    }
  }
  lv2categories(orderBy: "name") {
    edges {
      node {
        pk
        name
        displayPriority
        translations {
          edges {
            node {
              id
              pk
              language
              name
            }
          }
        }
      }
    }
  }
}`;

export const getStoreCategoryList = (afterCursor, searchKey, moreSearch) => {
  let filter = '';
  if (searchKey) {
    filter = `, nameIcontains:"${getSearchKey(searchKey)}"`;
  }
  let orderBy = 'name';
  if (moreSearch?.sort) {
    orderBy = moreSearch.sort;
  }
  const query = `{
    storeLevel1Categories(first: 20, after:"${afterCursor}", orderBy: "${orderBy}"${filter}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node ${STORE_NODE}
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getStoreCategory = (id) => {
  const query = `{
    storeLevel1Category(id: "${id}") ${STORE_NODE}
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const createStoreCategory = (values) => {
  const query = `mutation CreateStoreLevel1Category($input: CreateStoreLevel1CategoryInput!) {
    createStoreLevel1Category(input: $input) {
      node ${STORE_NODE}
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: {
      ...values,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
export const updateStoreCategory = (values) => {
  const query = `mutation UpdateStoreLevel1Category($input: UpdateStoreLevel1CategoryInput!) {
    updateStoreLevel1Category(input: $input) {
      node ${STORE_NODE}
    }
  }`;
  const variables = {
    input: {
      ...values,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteStoreCategories = (ids) => {
  const query = `mutation DeleteStoreLevel1Categories($input: DeleteStoreLevel1CategoriesInput!) {
    deleteStoreLevel1Categories(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids,
    },
  };
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getStoreSubCategoryList = (filter = {}) => {
  let userFilter = '';
  console.log("@131", filter)
  if (filter?.searchKey && filter?.isFilterNameOrId) {
    userFilter = `, nameOrId:"${getSearchKey(filter?.searchKey)}"`;
  } else if (filter?.searchKey) {
    userFilter = `, name:"${getSearchKey(filter?.searchKey)}"`;
  }
  if (filter?.sort) {
    userFilter += `, orderBy:"${filter?.sort}"`;
  } else {
    userFilter += `, orderBy:"pk"`
  }
  if (filter?.isAll) {
    userFilter += ``;
  } else {
    userFilter += `, first: ${filter?.pageSize ?  filter?.pageSize : 100}`;
  }
  const node = filter?.isSimpleFilter ? `{
    id
    pk
    name
  }` : `{
    id
    pk
    name
    displayPriority
    parentCategory {
        name
        displayPriority
    }
    lv3categories(first: 20) {
      edges {
        node {
          pk
          name
          displayPriority
        }
      }
    }
    translations(first: 20) {
      edges {
        node {
          language
          name
        }
      }
    }
  }`
  const query = `{
    storeLevel2Categories(${userFilter}) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node ${node}
    }
  }
}`;

  // translations {
  //   edges {
  //     node {
  //       language
  //       name
  //     }
  //   }
  // }
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};
