import {
  getCouponTransactions,
  getCouponTransaction,
  useCouponBySerialNumber,
} from '../services/CouponTransactionRecordAPIHelper';
import { parseExpiredDate } from './CouponListModel';
import {
  createAction,
  convertCursorToNumber,
  capitalizeFirstLetter,
} from '../utils';
import { loading } from './LoadingUtil';
import { formatDate, getDisplayDate } from '../utils/TimeFormatUtil';
import { getTCName } from './TransactionRecordListModel';
import i18n from '../I18n';
import { LanguageConfig } from '../config/CustomEnums';

const getInitialState = () => ({
  couponTransactionList: [],
  listDisplayFields: {
    'zh-Hant': [
      { displayName: 'ID', fieldName: 'pk', linked: true },
      {
        displayName: '名字',
        fieldName: 'displayFirstName',
        orderField: 'customerFirstName',
      },
      {
        displayName: '姓氏',
        fieldName: 'displayLastName',
        orderField: 'customerLastName',
      },
      {
        displayName: '會員編號',
        fieldName: 'membershipId',
        orderField: 'membershipId',
      },
      {
        displayName: '優惠券編號',
        fieldName: 'couponID',
        orderField: 'coupon',
      },
      { displayName: '紀錄類型', fieldName: 'displayTCType' },
      {
        displayName: '優惠券名字',
        fieldName: 'couponTemplateTCName',
        orderField: 'couponTemplateName',
      },
      {
        displayName: '事件類型',
        fieldName: 'eventTCType',
        orderField: 'type',
      },
      {
        displayName: '到期日期',
        fieldName: 'couponExpiryDate',
      },
      {
        displayName: '創建日期',
        fieldName: 'creationDate',
        orderField: 'creationDate',
      },
    ],
    en: [
      { displayName: 'ID', fieldName: 'pk', linked: true },
      {
        displayName: 'First Name',
        fieldName: 'displayFirstName',
        orderField: 'customerFirstName',
      },
      {
        displayName: 'Last name',
        fieldName: 'displayLastName',
        orderField: 'customerLastName',
      },
      {
        displayName: 'Member ID',
        fieldName: 'membershipId',
        orderField: 'membershipId',
      },
      {
        displayName: 'Coupon ID',
        fieldName: 'couponID',
        orderField: 'coupon',
      },
      { displayName: 'Record Type', fieldName: 'displayType' },
      {
        displayName: 'Coupon Set Name',
        fieldName: 'couponTemplateName',
        orderField: 'couponTemplateName',
      },
      {
        displayName: 'Event type',
        fieldName: 'eventType',
        orderField: 'type',
      },
      {
        displayName: 'Expiry Date',
        fieldName: 'couponExpiryDate',
      },
      {
        displayName: 'Create at',
        fieldName: 'creationDate',
        orderField: 'creationDate',
      },
    ],
  },
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  currentLastCursor: '',
  currentPage: 0,
  totalPage: 0,
  totalCount: 0,
  checkedList: [],
  currentPageCouponTransactionList: [],
  selectedCouponTransaction: {},
  useSuccessedCoupon: {},
});

const parseCouponTransactionType = (type, language) => {
  let displayType = '';
  switch (type) {
    case 'EARN':
      displayType = i18n.t('couponRecords.customer_earned', { locale: language });
      break;
    case 'BUY':
      displayType = i18n.t('couponRecords.customer_acquired', { locale: language });
      break;
    case 'GRANT':
      displayType = i18n.t('couponRecords.admin_added', { locale: language });
      break;
    case 'RECLAIM':
      displayType = i18n.t('couponRecords.admin_removed', { locale: language });
      break;
    case 'USE':
      displayType = i18n.t('couponRecords.customer_use', { locale: language });
      break;
    case 'EXPIRED':
      displayType = i18n.t('couponRecords.coupon_expired', { locale: language });
      break;
    default:
      break;
  }
  return displayType;
};

const getEventType = (transactionType, language) => {
  let eventType = '';
  switch (transactionType) {
    case 'EARN':
    case 'BUY':
    case 'GRANT':
      eventType = i18n.t('couponRecords.coupon_in', { locale: language });
      break;
    case 'RECLAIM':
    case 'USE':
    case 'EXPIRED':
      eventType = i18n.t('couponRecords.coupon_out', { locale: language });
      break;
    default:
      break;
  }
  return eventType;
};

const parseCouponTransaction = (item) => {
  const earningCampaignTypeEarningRule = item.detailCampaign
    ? item.detailCampaign?.earningCampaignTypeEarningRule
    : item.campaign?.earningCampaignTypeEarningRule;
  return {
    pk: item.pk,
    id: item.id,
    membershipId: item.customer?.membershipId,
    membershipIdWithPhoneNumber: item.customer
      ? item.customer.mobilePhoneNumberSubscriberNumber
        ? `${item.customer.membershipId} / +${item.customer.mobilePhoneNumberCountryCode} ${item.customer.mobilePhoneNumberSubscriberNumber}`
        : item.customer.membershipId
      : null,
    ssoUid: item.customer?.ssoUid,
    originType: item.type,
    displayType: parseCouponTransactionType(item.type, LanguageConfig.english),
    displayTCType: parseCouponTransactionType(item.type, LanguageConfig.traditionalChinese),
    pointsSpent: item.pointsSpent,
    creationDate: getDisplayDate(item.creationDate),
    usedDate: formatDate(item.usedAt, 'DD MMM yyyy (ddd), HH:mm a'),
    name: item.customer
      ? item.customer?.nickname
        ? `${item.customer?.firstName} ${item.customer?.lastName} (${item.customer?.nickname})`
        : `${item.customer?.firstName} ${item.customer?.lastName}`
      : null,
    displayFirstName: item.customer?.firstName,
    displayLastName: item.customer?.lastName,
    campaignName: item.campaign?.name,
    earningRuleName: item.campaign?.earningCampaignTypeEarningRule?.name,
    couponID: item.coupon?.pk,
    couponTemplateName: item.coupon?.template?.name,
    couponTemplateTCName: getTCName(item.coupon?.template) || item.coupon?.template?.name,
    couponTemplateNameWithSerialNumber: `${capitalizeFirstLetter(
      item.type.toString().toLowerCase(),
    )} ${item.coupon?.template?.name}(${item.coupon?.serialNumber})`,
    couponExpiryDate: item.coupon?.template
      ? parseExpiredDate(item.coupon?.template)
      : null,
    administratorName: item?.administrator?.username,
    createdDate: formatDate(item.creationDate),
    eventType: getEventType(item.type, LanguageConfig.english),
    eventTCType: getEventType(item.type, LanguageConfig.traditionalChinese),
    displayCampaign: item.campaign ? `[${item.campaign?.pk}] ${item.campaign?.name}` : '[DELETED]',
    displayTCCampaign: item.campaign ? `[${item.campaign?.pk}] ${getTCName(item.campaign)}` : '[已被刪除]',
    displayCoupon: `[${item.coupon?.pk}] ${item.coupon?.template?.name}`,
    displayTCCoupon: `[${item.coupon?.pk}] ${getTCName(item.coupon?.template)}`,
    displayEarningRule: earningCampaignTypeEarningRule ? `[${earningCampaignTypeEarningRule?.pk}] ${earningCampaignTypeEarningRule?.name}` : '[DELETED]',
    displayTCEarningRule: earningCampaignTypeEarningRule ? `[${earningCampaignTypeEarningRule?.pk}] ${getTCName(earningCampaignTypeEarningRule)}` : '[已被刪除]',
    usedStore: item.store ? `${item.store.name} - ${item.store.mall ? item.store.mall.name : item.store.freshMarket.name || '-'}` : '-',
    usedStoreTC: item.store ? `${getTCName(item.store)} - ${item.store.mall ? getTCName(item.store.mall) : getTCName(item.store.freshMarket) || '-'}` : '-',
  };
};

const parseUseSuccessedCoupon = (data) => {
  return {
    ...data,
    couponID: data.pk,
    couponName: getTCName(data?.template),
  };
};

export default {
  namespace: 'couponTransactionRecord',
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    getCouponTransactions: [
      function* ({ payload }, { call, put }) {
        // const { afterCursor } = payload;
        const serviceArgs = [
          getCouponTransactions,
          null,
          { ...payload, rank: true },
        ];
        function* onSuccess(data) {
          const conponTransactionData = data.couponTransactions.edges;
          const couponTransactionList = conponTransactionData.map((item) =>
            parseCouponTransaction(item.node),
          );
          yield put(
            createAction('updateState')({
              couponTransactionList: couponTransactionList,
            }),
          );
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getCurrentPageCouponTransactions: [
      function* ({ payload }, { call, put }) {
        const { page } = payload;
        let afterCursor = undefined;
        if (page > 1) {
          afterCursor = btoa(`arrayconnection:${(page - 1) * 20 - 1}`);
        }
        console.log('@@158: ', afterCursor);
        const serviceArgs = [getCouponTransactions, afterCursor, payload];
        function* onSuccess(data) {
          const conponTransactionData = data.couponTransactions.edges;
          const pageInfo = data.couponTransactions.pageInfo;
          const totalCount = data.couponTransactions.totalCount;
          const currentLastCursor = pageInfo.endCursor;
          const couponTransactionList = conponTransactionData.map((item) =>
            parseCouponTransaction(item.node),
          );
          yield put(
            createAction('updateState')({
              currentPageCouponTransactionList: couponTransactionList,
              pageInfo: {
                startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1 || 0,
                endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1 || 0,
              },
              currentLastCursor,
              totalCount,
              totalPage: Math.ceil(totalCount / 20),
            }),
          );
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getSingleCouponTransactionRecord: [
      function* ({ payload }, { put }) {
        const { couponTransactionPK } = payload;
        const transactionID = btoa(
          `CouponTransactionNode:${couponTransactionPK}`,
        );
        const serviceArgs = [getCouponTransaction, transactionID];
        function* onSuccess(data) {
          yield put(
            createAction('updateState')({
              selectedCouponTransaction: parseCouponTransaction(
                data.couponTransaction,
              ),
            }),
          );
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    useCouponBySerialNumber: [
      function* ({ payload }, { put }) {
        const { serialNumber, afterAction } = payload;
        const serviceArgs = [useCouponBySerialNumber, serialNumber];
        function* onSuccess(data) {
          console.log('@317', data.conciergeUseCoupon?.success);
          yield put(
            createAction('updateState')({
              useSuccessedCoupon: parseUseSuccessedCoupon(data.conciergeUseCoupon?.node?.coupon),
            }),
          );
          if (afterAction) {
            afterAction(data.conciergeUseCoupon?.success);
          }
        }
        function* onError(err) {
          console.log('usecoupon onError :', err);
          if (afterAction) {
            yield afterAction(false);
          }
        }
        function* onArgumentsError(data) {
          console.log('@@334: ', data);
          if (afterAction) {
            yield afterAction(false);
          }
        }
        yield loading(serviceArgs, onSuccess, onError, onArgumentsError);
      },
      { type: 'takeLatest' },
    ],
  },
};
