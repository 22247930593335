import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import {
  SectionTopTitle,
  FieldControl,
} from '../base/CommonComponent';
import CustomTitleWithSwitch from '../base/CustomTitleWithSwitch';

function StatusSections({
  hiddenSuper
}) {
  const { watch, setValue } = useFormContext();
  const watchIsActive = watch('isActive');
  const watchSuperAdmin = watch('isSuperuser');
  const statusSection = (
    <FieldControl
      name="isActive"
      render={() => (
        <CustomTitleWithSwitch
          title={'Active'}
          defaultValue={watchIsActive === undefined ? true : watchIsActive}
          setValue={(value) => {
            setValue('isActive', value, { shouldDirty: true });
          }}
        />
      )}
    />
  );
  const superAdminStatus = (
    <FieldControl
      name="isSuperuser"
      render={() => (
        <CustomTitleWithSwitch
          title={'Super Admin'}
          defaultValue={watchSuperAdmin}
          setValue={(value) => {
            setValue('isSuperuser', value, { shouldDirty: true });
          }}
        />
      )}
    />
  );
  return (
    <>
      <SectionTopTitle title="Status" />
      {statusSection}
      {hiddenSuper ? null : superAdminStatus}
    </>
  );
}




export default connect()(StatusSections);
