import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { listItem } from '../../../components/banners/DisplayItems';
import ContentSections from '../../../components/base/ContentSections';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import Loading from '../../../components/base/Loading';
import { APIStatus} from '../../../config/CustomEnums';

function DistrictMallMappingDetail({
  districtMallMapping,
  status,
  languages
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch({
      type: 'districtMallMappingList/clearData',
    });
    dispatch({
      type: 'districtMallMappingList/getOneDistrictMallMapping',
      payload: {
        id: params.id,
      }
    });
    dispatch({
      type: 'language/getList',
      payload: {
        isSelectorLoad: true,
      }
    });
  }, [dispatch, params.id]);


  const favouritaMall =(malllNumber)=>{
    let displayName = `Favourite Mall ${malllNumber}`;
    let firedName = `favouriteMall${malllNumber}`;
    let displayValue = districtMallMapping[firedName]?.label;
    return listItem(
      displayName,
      displayValue
    )
  }

  const renderTab = () => {
    const district = districtMallMapping.residentialRegion?.name || districtMallMapping.workingDistrict?.name;
    return [
      <>
        {listItem(
          'District',
          district
        )}
        {favouritaMall(1)}
        {favouritaMall(2)}
        {favouritaMall(3)}
      </>
    ]
  };

  const tabs = [
    {
      name: 'Detail',
      content: status === APIStatus.calling
      ? <Loading />
      : (
          <ContentSections
            hidePreview
            sections={renderTab(districtMallMapping.code)}
          />
        ),
    },
  ];

  const buttons = [
    <AuthButton
      title="Edit"
      action={() => {
        dispatch({ type: 'districtMallMappingList/clearData' });
        history.push({
          pathname: 'edit/',
        });
      }}
      requires={PermissionCodes.changeDistrictMallMapping}
    />,
  ];

  return (
    <div className="campaign-category-detail">
      <CustomListComponent
        caution={{
          detail: '',
          title: districtMallMapping?.districtName,
        }}
        breadcrumb={
          <CustomBreadcrumb name={districtMallMapping?.districtName} />
        }
        buttons={buttons}
        tabs={tabs}
      />
    </div>
  );
}

const mapPropsToState = (state) => ({
  districtMallMapping: state.districtMallMappingList.oneDistrictMallMapping,
  status: state.districtMallMappingList.loadingStatus,
});

export default connect(mapPropsToState)(DistrictMallMappingDetail);
