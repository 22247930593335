import React, {useEffect, useState} from 'react';
import { Button, Image } from 'react-bootstrap';
import Select, {components} from 'react-select';
import { connect, useDispatch } from 'react-redux';
import './SelectMultipleStore.scss';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';
import BaseMultipleSelectorV2 from './BaseMultipleSelectorV2';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import closeIcon from '../../assets/images/close.svg';
import SpecificCustomSwitchButton from '../../containers/merchants/stores/SpecificCustomSwitchButton';
function SelectMultipleStore({
  title = 'title',
  value = [],
  onChange = ()=> {},
  filterByMall = true,
  disabled = false,
  showLineBrank = false,
  mallList,
  frashMarketList,
  storeCategoryList,
  brandList,
  mallStoreList = [],
  freshMarketStoreList = [],
  storeCategoryL3List = [],
  storeCategoryL2List = [],
  onFocus = () => {},
}) {
  const [filterMall, setFilterMall] = useState(null);
  const [filterFreshMarket, setFilterFreshMarket] = useState(null);
  const [filterStoreCategoryL2, setFilterStoreCategoryL2] = useState(null);
  const [filterStoreCategoryL3, setFilterStoreCategoryL3] = useState(null);
  const [filterBrand, setFilterBrand] = useState(null);
  const [filterStore, setFilterStore] = useState(null);
  const [loadStore, setLoadStore] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
 
  const dispatch = useDispatch();

  const isNotEmpty = (value) => {
    return !(value === undefined || value === null || value.length === 0);
  }

  useEffect(() => {
    if (isNotEmpty(filterMall) || isNotEmpty(filterStoreCategoryL2) || 
    isNotEmpty(filterStoreCategoryL3) || isNotEmpty(filterBrand) || isNotEmpty(filterFreshMarket)) {
      setLoadStore(true)
      dispatch({
        type: 'storeModel/getAllMallStoreList',
        payload: {
          isAll: true,
          mallIn: filterMall?.map((item) => item?.value?.pk),
          brandIn: filterBrand?.map((item) => item?.value?.pk),
          freshMarketIn: filterFreshMarket?.map((item) => item?.value?.pk),
          filterByFreshMarket: !filterByMall,
          level2CategoryIn: filterStoreCategoryL2?.map((item) => item?.value?.pk),
          level3CategoryIn: filterStoreCategoryL3?.map((item) => item?.value?.pk),
        }
      });
    } else {
      setLoadStore(false)
    }
  }, [filterMall, filterFreshMarket, filterStoreCategoryL2, filterStoreCategoryL3, filterBrand]);

  useEffect(() => {
    dispatch({
      type: 'mall/getFilterMallList',
      payload: {
        isAll: true,
        isSimpleFilter: true,
        isSelectorLoad: true,
        sort: 'name',
      }
    });
    dispatch({
      type: 'freshMarket/getList',
      payload: {
        isAll: true,
        isSimpleFilter: true,
        isSelectorLoad: true,
        sort: 'name',
      }
    });
    dispatch({
      type: 'storeCategoryList/getAllStoreSubcategoryList',
      payload: {
        isAll: true,
        isSimpleFilter: true,
        isSelectorLoad: true,
        sort: 'name',
      }
    });
    dispatch({
      type: 'createStoreCategoryLv3/getList',
      payload: {
        isAll: true,
        isSimpleFilter: true,
        isSelectorLoad: true,
        sort: 'name'
      }
    });
    dispatch({
      type: 'brand/getSimpleBrandList',
      payload: {
        // isAll: true,
        pageSize: 100,
        isSimpleFilter: true,
        isSelectorLoad: true,
        sort: 'name',
      }
    });
  }, [dispatch]);

  const filterItem = ({
    title,
    value,
    setValue,
    source,
    loadActive,
    hasMargin,
    defaultFilter = {
      isAll: true
    }
  }) =>(
    <div className="filter-item">
      <Button className="clear-filter"
        onClick={() => {
          console.log("clear mall filter click")
          setValue()
        }}
      >Clear</Button>
      <CustomTitleWithDropDown 
        customClass={'filter-title'}
        title={title}
        source={source}
        placeholder={'Search by ID, name'}
        labelContainPk={true}
        defaultValue={value ? value : []}
        setValue={(v) => {
          console.log("@71", v)
          setValue(v);
        }}
        // loadMoreAction={loadActive}
        filterAction={loadActive}
        defaultFilter={{
          isSelectorLoad: true,
          isSimpleFilter: true,
          isFilterNameOrId: true,
          sort: 'name',
          ...defaultFilter
        }}
        multiple={{
          default: 'Please select',
        }}
        toolTips={true}
      />
    </div>
  )

  const showStoreLabel = (item) => {
    return filterByMall ? 
            `[ID:${item.pk}] ${item.name} - ${item.mall?.name} ${item.staffCode}` : 
            `[ID:${item.pk}] ${item.name} - ${item.freshMarket?.name} ${item.staffCode}`
  }

  return (
    <div style={{marginTop: '30px'}}
      onFocus={onFocus}
    >
      {!disabled && showLineBrank ? <div className='line'></div> : null}
      {title ? <label className="create-section-title">{title}</label> : null}
      {disabled ? null : 
      <>
        <div style={{marginTop: '8px'}}>
          <SpecificCustomSwitchButton
            show={true}
            checked={showFilter}
            onChange={(value) => {
              setShowFilter(value)
            }}
          />
        </div>
        {!showFilter ? null : <div style={{marginTop: '-15px'}}>
        <div className='clear-all-filter-container'>
          <Button className="clear-filter"
            onClick={() => {
              console.log("clear all filter click")
              setFilterMall()
              setFilterFreshMarket()
              setFilterStoreCategoryL2()
              setFilterStoreCategoryL3()
              setFilterBrand()
              setFilterStore()
            }}
          >Clear all filter </Button>
        </div>
        <div className='filter-item-container'>
          {filterByMall ? 
            filterItem({
              title: "Filter by mall",
              source: mallList,
              value: filterMall,
              setValue: setFilterMall,
              loadActive: 'mall/getFilterMallList'
            }) : 
            filterItem({
              title: "Filter by fresh market",
              source: frashMarketList,
              value: filterFreshMarket,
              setValue: setFilterFreshMarket,
              loadActive: 'freshMarket/getList'
            })
          }
          {filterByMall ? 
            filterItem({
              title: "Filter by store category",
              source: storeCategoryL2List,
              value: filterStoreCategoryL2,
              setValue: setFilterStoreCategoryL2,
              loadActive: 'storeCategoryList/getAllStoreSubcategoryList',
              hasMargin: true
            }) : 
            filterItem({
              title: "Filter by store category",
              source: storeCategoryL3List,
              value: filterStoreCategoryL3,
              setValue: setFilterStoreCategoryL3,
              loadActive: 'createStoreCategoryLv3/getList',
              hasMargin: true
            })
          }
          {filterByMall ? filterItem({
            title: "Filter by brand",
            source: brandList,
            value: filterBrand,
            setValue: setFilterBrand,
            loadActive: 'brand/getSimpleBrandList',
            defaultFilter: {
              pageSize: 100
            }
          }) : null}
        </div>
        <BaseMultipleSelectorV2
          title={'Select applicable stores '}
          namespace="storeModel"
          tips={'After selected stores from below table, the selected stores will be reflected in the "Summary of selected stores" at the bottom. You could clear the filter setting and further select additional stores.'}
          searchPlaceholder={'Search by name'}
          data={{
            sourceData: loadStore ? filterByMall ? mallStoreList : freshMarketStoreList : [],
            targetData: filterStore ? filterStore : [],
            targetChange: (selectedValue) => {
              setFilterStore(selectedValue)
              const uniqueIds = [];
              const newValue = [...value, ...selectedValue]
              onChange(newValue.filter((item) => {
                const isDuplicate = uniqueIds.includes(item.pk);
                if (!isDuplicate) {
                  uniqueIds.push(item.pk);
                  return true;
                }
                return false;
              }))
              console.log("@126", selectedValue)
            }
          }}
          custom={{
            customItem: (item)=>showStoreLabel(item),
            customFilter: (inputValue, option) => {
              const optionName = option?.name?.toLowerCase() || '';
              return optionName.indexOf(inputValue?.toLowerCase()) > -1;
            }
          }}
        />
        </div>}
      </>}
      <div>
        {disabled ? null : <div className='title-container'>
          <CustomTitleLabel 
            title={`Summary of selected stores (${value.length})`}
          />
          <Button className="clear-filter" style={{position: "relative", marginBottom:"7px"}}
            onClick={() => {
              console.log("clear mall filter click")
              onChange()
            }}
          >Reset</Button>
        </div>}
        {value.length ? 
         value?.map((item, index) => {
           return <div className='store-list-item-container'>
            <label className='store-list-item'>
              {showStoreLabel(item)}
            </label>
            {disabled ? null : <Image
              src={closeIcon}
              className="list-item-delete"
              onClick={ (e) =>{
                e.stopPropagation();
                const newValue = value?.filter((filterItem) => filterItem.pk != item.pk)
                onChange(newValue);
              }}
            />}
           </div>
         })
        : '-'}
        
      </div>
    </div>
  );
}

const mapPropsToState = (state) => ({
  mallList: state.mall.filterMallList || [],
  frashMarketList: state.freshMarket.allList || [],
  storeCategoryList: state.storeCategoryList.allList || [],
  brandList: state.brand.currentPageBrandList || [],
  mallStoreList: state.storeModel.allMallStoreList || [],
  freshMarketStoreList: state.storeModel.allFreshMarketStoreList || [],
  storeCategoryL3List: state.createStoreCategoryLv3.pagedList || [],
  storeCategoryL2List: state.storeCategoryList.subCategoryList || [],
})

export default connect(mapPropsToState)(SelectMultipleStore);