import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { parseDropDownCampaignList } from '../../models/CampaignListModel';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';


function CampaignSelectDropdown(props) {
  const title = props.title || null;
  const defaultValue = props.defaultValue || null;
  const setValue = props.setValue || (() => {});
  let { campaignList } = useSelector((state) => ({
    campaignList: state.campaignList.allList,
  }));
  campaignList = parseDropDownCampaignList(campaignList);
  const displayIn = props.displayIn || '';

  const onSelectChange = (value) => {
    setValue(value?.value);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: 'campaignList/getCampaignList',
      payload: {
        isSelectorLoad: true,
        isExpired: false,
        isPublished: true,
        displayIn: displayIn,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomTitleWithDropDown
      title={title}
      titleStyle={props.isPrompt ? '' : 'create-section-label-no-top-space'}
      setValue={onSelectChange}
      source={campaignList}
      defaultValue={defaultValue}
      loadMoreAction={'campaignList/getCampaignList'}
      filterAction={'campaignList/getCampaignList'}
      defaultFilter={{displayIn: 'E_COUPON,STAMP_LIST,MISSION_LIST,INTEREST_CLUB,FRESH_MARKET_COMMUNITY,NOT_LISTING'}}
    />
  );
}

export default CampaignSelectDropdown;
