import React from 'react';
import { useHistory } from 'react-router-dom';
import CreateMappingSection from './CreateMappingSection';
import { connect, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import ContentSections from '../base/ContentSections';
import { SaveAndBackWithOutTempButtons } from '../base/BottomStepComponent';
import { validate } from '../../containers/others/districtMallMapping/CreateDistrictMallMappingValidate';

const UpdataMappingContent = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { getValues, setError, clearErrors } = useFormContext();

  const save = () => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 0,
    });
    if (!isValid) {
      console.log('failed')
      return;
    }
    dispatch({
      type: 'districtMallMappingList/updateDistrictMallMapping',
      payload: {
        values: getValues(),
      }
    });
    }

  const sections = [
    <CreateMappingSection />,
  ];

  return (
    <>
    <ContentSections sections={sections} hidePreview={true} />
      <SaveAndBackWithOutTempButtons
        backAction={() => {
          history.push('/district_mall_mappings');
        }}
        saveText="Save"
        saveAction={() => {
          save();
        }}
      />
  </>
)
};

const mapPropsToState = (state) => ({
  savedSuccess: state.districtMallMappingList.saved,
  checked: state.districtMallMappingList.checked,
  tempMapping: state.districtMallMappingList.oneDistrictMallMapping
});

export default connect(mapPropsToState)(UpdataMappingContent);
