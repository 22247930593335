import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import DeletePrompt from '../../../components/base/DeletePrompt';
import { useDispatch, connect } from 'react-redux';
import { APIStatus, DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';
import CampaignTagContentSection from '../../../components/campaignTag/CampaignTagContentSection';

const CreateCampaignTagStepOne = ({ campaignTag, createStatus, languages }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const { getValues, trigger } = useFormContext();
  useEffect(() => {
    dispatch({ type: 'language/getList', payload: { isSelectorLoad: true } });
  }, []);

  return (
    <>
      {createStatus === APIStatus.calling ? (
        <Loading />
      ) : (
        <ContentSections
          languageTabContent={{
            containers: languages?.map((item) => ({
              container: [<CampaignTagContentSection language={item.code} />],
              key: item.code,
              title: item.sourceName,
            })),
          }}
          hidePreview
        />
      )}
      <SaveAndBackButtons
        saveTempText={params.id ? 'Delete' : null}
        saveText={params.id ? 'Update' : 'Save'}
        backAction={() => history.goBack()}
        saveAction={async () => {
          let isValid = await trigger(['name', 'order', 'isForcedInactive',], { shouldFocus: true });
          if (isValid) {
            let uploadData = {
              values: getValues(),
            };
            if (params.id) {
              uploadData = {
                values: getValues(),
                tagID: params.id,
              };
            }
            dispatch({
              type: 'campaignTag/createOrUpdate',
              payload: uploadData,
            });
          }
        }}
        saveTempAction={() => setShowDeletePrompt(true)}
      />
      <DeletePrompt
        message={{
          title: 'Delete this campaign tag',
          content: 'Did you confirm to delete this campaign tag?',
        }}
        title={'Campaign tag'}
        data={campaignTag}
        relatedSections={DELETE_RELATED_SECTIONS.CAMPAIGN_CATEGORY}
        show={showDeletePrompt}
        onClose={() => {
          setShowDeletePrompt(false);
        }}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: 'campaignTag/updateState',
            payload: { checkedList: [campaignTag] },
          });
          dispatch({ type: 'campaignTag/delete', payload: {} });
        }}
      />
    </>
  );
};

const mapPropsToState = (state) => ({
  campaignTag: state.campaignTag.detail,
  createStatus: state.campaignTag.createStatus,
  languages: state.language.allList,
});

export default connect(mapPropsToState)(CreateCampaignTagStepOne);
