import React from 'react';
import CustomerList from '../containers/customers/customer/CustomerList';
import LevelList from '../containers/customers/level/LevelList';
import TransactionList from '../containers/record/records/TransactionList';
import SegmentList from '../containers/customers/segments/SegmentList';
import CampaignList from '../containers/engagement/campaign/CampaignList';
import CouponList from '../containers/engagement/coupon/CouponList';
import EarningRuleList from '../containers/engagement/earningRule/EarningRuleList';
import MessageList from '../containers/engagement/message/MessageList';
import RewardList from '../containers/engagement/rewards/RewardList';
import BrandList from '../containers/merchants/brands/BrandList';
import StoreList from '../containers/merchants/stores/StoreList';
import Dashboard from '../containers/dashboard/Dashboard';
import { NavBarNames } from './NavBarNameList';
import { PermissionCodes } from './PermissionCodes';
import TranslationList from '../containers/merchants/translations/TranslationList';
import { URLFragments } from '../components/campaign/CampaignUrlConfig';
import { CouponURLQueryKeys } from '../components/coupon/CouponUrlConfig';
import CustomerGroupList from '../containers/customers/customerGroup/CustomerGroupList';
import CampaignCategory from '../containers/engagement/campaignCategory/CampaignCategory';
import StoreCategory from '../containers/merchants/storeCategory/StoreCategory';
import WebviewContentList from '../containers/others/webviewContent/WebviewContentList';
import CouponTransaction from '../containers/record/couponRecords/CouponTransaction';
import PointTransactionList from '../containers/record/pointRecords/PointTransactionList';
import AdminList from '../containers/admin/admins/AdminList';
import AdminGroupList from '../containers/admin/adminGroups/AdminGroupList';
import BannerList from '../containers/homeManage/banners/BannerList';
import FeaturedCampaignList from '../containers/homeManage/featuredCampaigns/FeaturedCampaignList';
import StoreCodeList from '../containers/merchants/stores/StoreCodeList';
import LanguageList from '../containers/engagement/language/LanguageList';
import EStampCampaignList from '../containers/engagement/campaign/EStampCampaignList';

import {
  pointRecordQueryKey,
  couponRecordQueryKey,
  transactionQueryKey,
} from '../components/pointRecord/RecordsUrlConfig';
import CampaignTagList from '../containers/engagement/campaignTag/CampaignTagList';
import StoreCategoryLv3List from '../containers/merchants/storeCategoryLv3/StoreCategoryLv3List';
import StoreCategoryLv4List from '../containers/merchants/storeCategoryLv4/StoreCategoryLv4List';
import EMissionCampaignList from '../containers/engagement/campaign/EMissionCampaignList';
import TransactionRecordList from '../containers/conciergeApp/transactionRecord/TransactionRecordList';
import CarParkList from '../containers/merchants/carparks/CarParkList';
import EvChargerVendorList from '../containers/merchants/vendors/EvChargerVendorList';
import MallList from '../containers/merchants/mall/MallList';
import FreshMarketList from '../containers/merchants/freshMarkets/FreshMarketList';
import CouponTransactionRecord from '../containers/conciergeApp/couponRecord/CouponTransactionRecord';
import StampRecordList from '../containers/record/stampRecords/StampRecordList';
import BadgeRecordList from '../containers/record/badgeRecords/BadgeRecordList';
import AppVersionList from '../containers/others/AppVersion/AppVersionList';
import ThemedSection from '../containers/homeManage/themedSection/ThemedSection';
import DistrictMallMappingList from '../containers/others/districtMallMapping/DistrictMallMappingList';

const queryString = require('query-string');
const createstep = {
  name: 'Create Steps',
  intro: true,
  list: [
    { 
      name: 'Coupon',
      path: '/coupons_step',
      permissionlist: [PermissionCodes.changeCoupon, PermissionCodes.addCampaign],
      list : [
        {
          location: {
            pathname: '/coupons/create',
          },
          name: NavBarNames.couponStep1,
          codename: PermissionCodes.changeCoupon,
        },
        {
          location: {
            pathname: '/campaigns/create?step=coupon_couponcampaign',
          },
          name: NavBarNames.couponStep2,
          codename: PermissionCodes.addCampaign,
        }
      ]
    },
    { 
      name: 'Stamp',
      path: '/stamp_step',
      permissionlist: [PermissionCodes.changeCoupon, PermissionCodes.addCampaign, PermissionCodes.changeEarningRule],
      list : [
        {
          location: {
            pathname: '/coupons/create',
          },
          name: NavBarNames.stampStep1,
          codename: PermissionCodes.changeCoupon,
        },
        {
          location: {
            pathname: '/campaigns/create?step=stamp_couponcampaign',
          },
          name: NavBarNames.stampStep2,
          codename: PermissionCodes.addCampaign,
        },
        {
          location: {
            pathname: '/earns/create?step=stamp_earnrule',
          },
          name: NavBarNames.stampStep3,
          codename: PermissionCodes.changeEarningRule,
        },
        {
          location: {
            pathname: '/estamp_campaigns/create?step=stamp_stampcampaign',
          },
          name: NavBarNames.stampStep4,
          codename: PermissionCodes.addCampaign,
        },
      ]
    },
    { 
      name: 'Mission',
      path: '/mission_step',
      permissionlist: [PermissionCodes.changeCoupon, PermissionCodes.addCampaign, PermissionCodes.changeEarningRule],
      list : [
        {
          location: {
            pathname: '/coupons/create',
          },
          name: NavBarNames.missionStep1,
          codename: PermissionCodes.changeCoupon,
        },
        {
          location: {
            pathname: '/earns/create',
          },
          name: NavBarNames.missionStep2,
          codename: PermissionCodes.changeEarningRule,
        },
        {
          location: {
            pathname: '/campaigns/create?step=mission_earncampaign',
          },
          name: NavBarNames.missionStep3,
          codename: PermissionCodes.addCampaign,
        },
        {
          location: {
            pathname: '/emission_campaigns/create?step=mission_missioncampaign',
          },
          name: NavBarNames.missionStep4,
          codename: PermissionCodes.addCampaign,
        },
      ]
    },
    {
      name: 'Interest club',
      path: '/interestclub_step',
      permissionlist: [PermissionCodes.addCampaign],
      list : [
        {
          location: {
            pathname: '/campaigns/create?step=interestclub_campaign',
          },
          name: NavBarNames.interestClubStep1,
          codename: PermissionCodes.addCampaign,
        },
      ]
    },
    { 
      name: 'Not listing',
      path: '/notlist_step',
      permissionlist: [PermissionCodes.changeCoupon, PermissionCodes.addCampaign, PermissionCodes.changeEarningRule],
      list : [
        {
          location: {
            pathname: '/coupons/create',
          },
          name: NavBarNames.notlistStep1,
          codename: PermissionCodes.changeCoupon,
        },
        {
          location: {
            pathname: '/earns/create',
          },
          name: NavBarNames.notlistStep2,
          codename: PermissionCodes.changeEarningRule,
        },
        {
          location: {
            pathname: '/campaigns/create?step=notlisting_campaign',
          },
          name: NavBarNames.notlistStep3,
          codename: PermissionCodes.addCampaign,
        },
      ]
    },
  ],
};
const dashboard = {
  path: '/',
  name: 'Dashboard',
  location: {
    pathname: '/',
  },
  exact: true,
  main: () => <Dashboard />,
};

const admin = {
  name: 'administrators',
  list: [
    {
      path: '/administrator_groups',
      location: {
        pathname: '/administrator_groups',
      },
      exact: true,
      name: NavBarNames.adminGroup,
      codename: PermissionCodes.administratorgroup,
      main: () => <AdminGroupList />,
    },
    {
      path: '/administrators',
      location: {
        pathname: '/administrators',
      },
      exact: true,
      name: NavBarNames.admin,
      codename: PermissionCodes.administrator,
      main: () => <AdminList />,
    },
  ],
};

const customers = {
  name: 'user',
  list: [
    {
      path: '/customers',
      location: {
        pathname: '/customers',
      },
      exact: true,
      name: NavBarNames.customer,
      // conciergemode: true,
      codename: PermissionCodes.customer,
      main: () => <CustomerList />,
    },
    {
      path: '/segments',
      location: {
        pathname: '/segments',
      },
      exact: true,
      name: NavBarNames.segments,
      codename: PermissionCodes.segment,
      main: () => <SegmentList />,
    },
    {
      path: '/customer_group',
      location: {
        pathname: '/customer_group',
        search: queryString.stringify({
          page: 1,
          rank: true,
          search: '',
        }),
      },
      exact: true,
      name: NavBarNames.customerGroup,
      codename: PermissionCodes.customergroup,
      main: () => <CustomerGroupList />,
    },
  ],
};

const engagement = {
  name: 'Engagement',
  list: [
    {
      path: '/campaigns',
      location: {
        pathname: '/campaigns',
        hash: URLFragments.list,
      },
      exact: true,
      name: NavBarNames.campaigns,
      codename: PermissionCodes.campaign,
      main: () => <CampaignList />,
    },
    {
      path: '/campaign_category',
      location: {
        pathname: '/campaign_category',
      },
      exact: true,
      name: NavBarNames.campaignCategory,
      codename: PermissionCodes.campaigncategory,
      main: () => <CampaignCategory />,
    },
    {
      path: '/campaign_tag',
      location: {
        pathname: '/campaign_tag',
      },
      exact: true,
      name: NavBarNames.campaignTag,
      codename: PermissionCodes.campaigntag,
      main: () => <CampaignTagList />,
    },
    {
      path: '/estamp_campaigns',
      location: {
        pathname: '/estamp_campaigns',
      },
      exact: true,
      name: NavBarNames.eStampCampaign,
      codename: PermissionCodes.campaign,
      main: () => <EStampCampaignList />,
    },
    {
      path: '/emission_campaigns',
      location: {
        pathname: '/emission_campaigns',
      },
      exact: true,
      name: NavBarNames.eMissionCampaign,
      codename: PermissionCodes.campaign,
      main: () => <EMissionCampaignList/>,
    },
    {
      path: '/messages',
      location: {
        pathname: '/messages',
        search: queryString.stringify({
          page: 1,
          rank: true,
          search: '',
        }),
      },
      exact: true,
      name: NavBarNames.message,
      codename: PermissionCodes.message,
      main: () => <MessageList />,
    },
    {
      path: '/earns',
      location: {
        pathname: '/earns',
      },
      exact: true,
      name: NavBarNames.earnRules,
      codename: PermissionCodes.earningrule,
      main: () => <EarningRuleList />,
    },
    {
      path: '/coupons',
      location: {
        pathname: '/coupons',
      },
      exact: true,
      name: NavBarNames.coupons,
      codename: PermissionCodes.coupontemplate,
      main: () => <CouponList />,
    },
    {
      path: '/languages',
      location: {
        pathname: '/languages',
      },
      exact: true,
      name: NavBarNames.langauge,
      codename: PermissionCodes.language,
      main: () => <LanguageList />,
    },
  ],
};

const merchants = {
  name: 'Merchants',
  list: [
    {
      path: '/brands',
      location: {
        pathname: '/brands',
        search: queryString.stringify({
          page: 1,
          rank: true,
          search: '',
        }),
      },
      exact: true,
      name: NavBarNames.brands,
      codename: PermissionCodes.brand,
      main: () => <BrandList />,
    },
    {
      path: '/carparks',
      location: {
        pathname: '/carparks',
      },
      exact: true,
      name: NavBarNames.carparks,
      codename: PermissionCodes.carpark,
      main: () => <CarParkList />,
    },
    {
      path: '/ev_charger_vendors',
      location: {
        pathname: '/ev_charger_vendors',
      },
      exact: true,
      name: NavBarNames.evChargerVendors,
      codename: PermissionCodes.carpark,
      main: () => <EvChargerVendorList />,
    },
    {
      path: '/malls',
      location: {
        pathname: '/malls',
      },
      exact: true,
      name: NavBarNames.malls,
      codename: PermissionCodes.mall,
      main: () => <MallList />,
    },
    {
      path: '/fresh_markets',
      location: {
        pathname: '/fresh_markets',
      },
      exact: true,
      name: NavBarNames.freshMarkets,
      codename: PermissionCodes.freshmarket,
      main: () => <FreshMarketList />,
    },
    {
      path: '/stores',
      location: {
        pathname: '/stores',
      },
      exact: true,
      name: NavBarNames.stores,
      codename: PermissionCodes.store,
      main: () => <StoreList />,
    },
    {
      path: '/store_codes',
      location: {
        pathname: '/store_codes',
      },
      exact: true,
      name: NavBarNames.storeCodes,
      codename: PermissionCodes.store,
      main: () => <StoreCodeList />,
    },
    {
      path: '/store_category_lv1_lv2',
      location: {
        pathname: '/store_category_lv1_lv2',
      },
      exact: true,
      name: NavBarNames.storeCategory,
      codename: PermissionCodes.storecategory,
      main: () => <StoreCategory />,
    },
    {
      path: '/store_category_lv3',
      location: {
        pathname: '/store_category_lv3',
      },
      exact: true,
      name: NavBarNames.storeCategoryLv3,
      codename: PermissionCodes.storecategoryLv3,
      main: () => <StoreCategoryLv3List />,
    },
    {
      path: '/store_category_lv4',
      location: {
        pathname: '/store_category_lv4',
      },
      exact: true,
      name: NavBarNames.storeCategoryLv4,
      codename: PermissionCodes.storecategoryLv4,
      main: () => <StoreCategoryLv4List />,
    },
  ],
};

const others = {
  name: 'Others',
  list: [
    {
      path: '/webview',
      location: {
        pathname: '/webview',
      },
      exact: true,
      name: NavBarNames.webviewContent,
      codename: PermissionCodes.webContent,
      main: () => <WebviewContentList />,
    },
    {
      path: '/app_version',
      location: {
        pathname: '/app_version',
      },
      exact: true,
      name: NavBarNames.appversion,
      codename: PermissionCodes.appVersion,
      main: () => <AppVersionList />,
    },
    {
      path: '/district_mall_mappings',
      location: {
        pathname: '/district_mall_mappings',
      },
      exact: true,
      name: NavBarNames.districtMallMappings,
      codename: PermissionCodes.districtmallmapping,
      main: () => <DistrictMallMappingList />,
      needLineBreak: true,
    },
  ],
};

const records = {
  name: 'Record',
  list: [
    {
      path: '/transaction_records',
      location: {
        pathname: '/transaction_records',
      },
      exact: true,
      name: NavBarNames.transactions,
      codename: PermissionCodes.transaction,
      main: () => <TransactionRecordList />,
    },
    // {
    //   path: '/point_records',
    //   location: {
    //     pathname: '/point_records',
    //     search: queryString.stringify({
    //       [pointRecordQueryKey.searchKey]: '',
    //       [pointRecordQueryKey.page]: 1,
    //       [pointRecordQueryKey.rank]: true,
    //       [pointRecordQueryKey.tab]: 'all',
    //     }),
    //   },
    //   exact: true,
    //   name: NavBarNames.pointRecords,
    //   codename: PermissionCodes.pointtransaction,
    //   main: () => <PointTransactionList />,
    // },
    {
      path: '/stamp_records',
      location: {
        pathname: '/stamp_records',
      },
      exact: true,
      name: NavBarNames.stampRecords,
      // conciergemode: true,
      codename: PermissionCodes.stamprecord,
      main: () => <StampRecordList />,
    },
    {
      path: '/badge_records',
      location: {
        pathname: '/badge_records',
      },
      exact: true,
      name: NavBarNames.badgeRecords,
      // conciergemode: true,
      codename: PermissionCodes.badgerecord,
      main: () => <BadgeRecordList />,
    },
    {
      path: '/coupon_records',
      location: {
        pathname: '/coupon_records',
      },
      exact: true,
      name: NavBarNames.couponRecordsEn,
      codename: PermissionCodes.coupontransaction,
      main: () => <CouponTransactionRecord />,
    },
  ],
};

const homemanager = {
  name: 'Manage Home page',
  list: [
    {
      path: '/banners',
      location: {
        pathname: '/banners',
      },
      exact: true,
      name: NavBarNames.banners,
      codename: PermissionCodes.banners,
      main: () => <BannerList />,
    },
    {
      path: '/home_campaigns',
      location: {
        pathname: '/home_campaigns',
      },
      exact: true,
      name: NavBarNames.featuredCampaign,
      codename: PermissionCodes.campaign,
      main: () => <FeaturedCampaignList />,
      needLineBreak: true,
    },
    {
      path: '/themed_section',
      location: {
        pathname: '/themed_section',
      },
      exact: true,
      name: NavBarNames.themedSection,
      codename: PermissionCodes.themedSection,
      main: () => <ThemedSection />,
    },
  ],
};

const conciergeapp = {
  name: 'Concierge App',
  list: [
    {
      path: '/concierge_customers',
      location: {
        pathname: '/concierge_customers',
      },
      exact: true,
      name: NavBarNames.customerTC,
      conciergemode: true,
      codename: PermissionCodes.customer,
      main: () => <CustomerList />,
    },
    {
      path: '/concierge_transaction_records',
      location: {
        pathname: '/concierge_transaction_records',
      },
      exact: true,
      name: NavBarNames.transactionRecord,
      codename: PermissionCodes.conciergetransaction,
      conciergemode: true,
      main: () => <TransactionRecordList />,
    },
    {
      path: '/concierge_stamp_records',
      location: {
        pathname: '/concierge_stamp_records',
      },
      exact: true,
      name: NavBarNames.stampRecordsTC,
      conciergemode: true,
      codename: PermissionCodes.stamprecord,
      main: () => <StampRecordList />,
    },
    {
      path: '/concierge_badge_records',
      location: {
        pathname: '/concierge_badge_records',
      },
      exact: true,
      name: NavBarNames.badgeRecordsTC,
      conciergemode: true,
      codename: PermissionCodes.badgerecord,
      main: () => <BadgeRecordList />,
    },
    {
      path: '/concierge_coupon_records',
      location: {
        pathname: '/concierge_coupon_records',
      },
      exact: true,
      name: NavBarNames.couponRecords,
      codename: PermissionCodes.conciergecoupontransaction,
      conciergemode: true,
      main: () => <CouponTransactionRecord />,
    },
  ],
};

export const tabList = [
  dashboard,
  createstep,
  customers,
  engagement,
  merchants,
  others,
  records,
  admin,
  homemanager,
  conciergeapp,
];
