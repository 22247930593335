import React, { useEffect } from 'react';
import CustomSwitchButton from '../../base/CustomSwitchButton';
import { Collapse } from 'react-bootstrap';
import CustomDateTimeSelect from '../../base/CustomDateTimeSelect';
import ErrorFieldMessage from '../../base/ErrorFieldMessage';
import { isShowError, firstError } from './CreateCampaignHandleError';

function PeriodSection(props) {
  const title = props.title;
  const titleDescription = props.titleDescription;
  const switchButtonTitle = props.switchButtonTitle;
  const isAlwaysPeriod = props.isAlwaysPeriod;
  const switchOnChange = props.switchOnChange || (() => {});
  const startDate = props.startDate;
  const startDateChange = props.startDateChange || (() => {});
  const endDate = props.endDate;
  const endDateChange = props.endDateChange || (() => {});
  const maxDate = props.maxDate;
  const minDate = props.minDate;
  const reminder = props.reminder
  const disabled = props.disabled;
  const startErrorObject = props.startErrorObject || {}
  const endErrorObject = props.endErrorObject || {}
  const switchButtonError = props.switchButtonError || {}

  const dataPicker = ({ title, defaultTime, errorObject = {}, onTimeChange = (() => {}), hasMaxDate = false, hasMinDate = false }) => {
    return <div>
      <label className="create-section-label create-section-label-bottom-space">
        {title}
      </label>
      <CustomDateTimeSelect
        disabled={isAlwaysPeriod}
        defaultTime={defaultTime}
        maxDate={hasMaxDate ? maxDate : null}
        minDate={hasMinDate ? minDate : null}
        error={!!errorObject.message}
        onTimeChange={onTimeChange}
      />
      {errorObject.message ? null :
        <label className="tips-message">
          {reminder}
        </label>}
      <ErrorFieldMessage
        id={errorObject.id}
        error={errorObject.message}
        message={errorObject.message}
      />
    </div>
  }

  return (
    <>
      <label className="create-section-title">{title}</label>
      <label className="section-short-description">{titleDescription}</label>
      {switchButtonTitle ? (
        <label className="create-section-label create-section-label-bottom-space">
          {switchButtonTitle}
        </label>
      ) : null}
      <CustomSwitchButton
        disabled={disabled}
        defaultChecked={isAlwaysPeriod}
        onChange={switchOnChange}
      />
      <ErrorFieldMessage
        id={switchButtonError.id}
        error={switchButtonError.message}
        message={switchButtonError.message}
      />
      <Collapse in={!isAlwaysPeriod}>
        <div>
          {dataPicker({ title: "Start date", defaultTime: startDate, onTimeChange: startDateChange, errorObject: startErrorObject, hasMaxDate: true })}
          {dataPicker({ title: "End date", defaultTime: endDate, onTimeChange: endDateChange, errorObject: endErrorObject, hasMinDate: true })}
        </div>
      </Collapse>
    </>
  );
}

export default PeriodSection;
