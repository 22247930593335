import React, { useState } from 'react';
import './PublishCampaignPrompt.scss';
import { useHistory } from 'react-router-dom';
import { URLFragments } from '../CampaignUrlConfig';
import NextStepPrompt from '../../base/prompt/NextStepPrompt';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { ActionType } from '../../../config/CustomEnums';

function PublishCampaignPrompt(props) {
  const defaultShow = props.show || false;
  const isPublish = props.isPublish;
  const isSave = props.isSave;
  const actionType = props.actionType;
  const history = useHistory();
  const [show, setShow] = useState(false);

  if (!show && defaultShow) {
    setShow(true);
  }

  const getTitle = () => {
    switch (actionType) {
      case ActionType.save:
        return 'Successfully Saved!'
      case ActionType.update:
      case ActionType.tempForPulished:
        return 'Successfully Updated!';
      case ActionType.submitForApproval:
        return 'Successfully Submitted!'
      case ActionType.approval:
        return 'Successfully Approved!'
      case ActionType.publish:
        return 'Successfully Published!'
      case ActionType.withdraw:
        return 'Successfully Withdrawn!'
      default:
        return 'Successfully Unpublished!'
    }

  };

  const getDescription = () => {
    switch (actionType) {
      case ActionType.save:
        return `You can update the content.`
      case ActionType.update:
      case ActionType.tempForPulished:
        return `The campaign is successfully updated.`;
      case ActionType.submitForApproval:
        return `Notify the checkers to approve the content.`
      case ActionType.approval:
        return `Notify the publishers to publish the content.`
      case ActionType.publish:
        return `Let your customers know about the campaign.`
      case ActionType.withdraw:
        return `You can update the content.`
      default:
        return `You can update the content.`
    }
  };

  return (
    <NextStepPrompt
      show={show}
      title={getTitle()}
      description={getDescription()}
      steps={null}
      buttons={[
        {
          text: 'Back to list',
          action: () =>
            history.push({
              pathname: props.pathname || '/campaigns',
              hash: URLFragments.list,
            }),
          requires: PermissionCodes.changeCampaign,
        },
      ]}
    />
  );
}

export default PublishCampaignPrompt;
