import React from 'react';
import './CreateCouponStepOne.scss';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { OnlyContinueButton } from '../../../components/base/BottomStepComponent';
import ContentSections from '../../../components/base/ContentSections';
import ItemTypeSet from '../../../components/base/ItemTypeSet';
import {
  COUPON_SET_TYPES,
  COUPON_REDEMPTION_METHOD,
  COUPON_MODE,
} from '../../../config/CustomEnums';
import { createAction } from '../../../utils';
import CustomRadios from '../../../components/base/CustomRadios';
import { saveCouponToSessionStorage } from '../../../models/CreateCouponModel';
import SystemGenerated from '../../../assets/images/coupon/systemGenerated.svg';
import ImportExistingCoupon from '../../../assets/images/coupon/importExistingCoupon.svg';

export const COUPON_TYPES_CONFIG = [
  {
    id: COUPON_SET_TYPES.SYSTEM_GENERATED,
    title: 'System generated',
    image: SystemGenerated,
    description: 'Coupon generated by the system',
  },
  {
    id: COUPON_SET_TYPES.IMPORT_EXISTING_COUPON,
    title: 'Import existing coupon',
    image: ImportExistingCoupon,
    description: 'Import existing coupon to the system',
  },
];

export const COUPON_REDEMPTION_METHOD_CONFIG = [
  {
    label: 'Mechant app scan Coupon QR code',
    value: COUPON_REDEMPTION_METHOD.MERCHANT_APP_SCAN_COUPON_QR_CODE,
  },
  {
    label: 'Users scan Store QR code',
    value: COUPON_REDEMPTION_METHOD.USERS_SCAN_STORE_QR_CODE,
  },
  {
    label: 'Use store PIN code only',
    value: COUPON_REDEMPTION_METHOD.INPUT_STORE_STAFF_CODE,
  },
  // {
  //   label: 'Users copy the discount code',
  //   value: COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE,
  // },
];

export const COUPON_MODE_CONFIG = [
  {
    label: "Common coupons",
    value: COUPON_MODE.COMMON,
  },
  {
    label: "Store-specific coupons ",
    value: COUPON_MODE.STORE_ONLY,
  }
]

const CreateCouponStepOne = ({}) => {
  const dispatch = useDispatch();
  const { register, watch, setValue, getValues } = useFormContext();
  const watchCouponSetType = watch('couponSetType');
  const watchRedemptionMethod = watch('redemptionMethod');
  const watchIsLinkedCustomer = watch('isLinkedCustomer');
  const watchCouponMode = watch('couponMode');
  const watchTotalNubmerOfGeneratedCoupons = watch('totalNubmerOfGeneratedCoupons');
  const [methodOptions, setMethodOptions] = React.useState(
    watchCouponSetType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON
    ? COUPON_REDEMPTION_METHOD_CONFIG
    : COUPON_REDEMPTION_METHOD_CONFIG.slice(0, 3),
  );
  console.log("CreateCouponStepOne:", getValues())
  const sections = [
    <div>
      <label className="step-one-title">Coupon set type</label>
      <div className="step-type-area message-channel-area">
        {COUPON_TYPES_CONFIG.map((typeItem) => (
          <ItemTypeSet
            moreThanThree={false}
            extraClassName="couponset-card"
            key={`${typeItem.id}-${typeItem.description}`}
            item={typeItem}
            selected={watchCouponSetType}
            onClick={(id) => {
              setValue('couponSetType', id, { shouldDirty: true });
              if (id === COUPON_SET_TYPES.SYSTEM_GENERATED) {
                setMethodOptions(COUPON_REDEMPTION_METHOD_CONFIG.slice(0, 3));
                if (
                  watchRedemptionMethod ===
                  COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE
                ) {
                  setValue('redemptionMethod', null, { shouldDirty: true });
                }
              } else {
                setMethodOptions(COUPON_REDEMPTION_METHOD_CONFIG);
              }
            }}
            disabled={watchIsLinkedCustomer}
          />
        ))}
      </div>
      <label className="step-one-title">Redemption method</label>
      <div style={{ display: 'flex', marginTop: 8, marginBottom: 8 }}>
        <CustomRadios
          onChange={(value) => {
            setValue('redemptionMethod', value, { shouldDirty: true });
          }}
          default={watchRedemptionMethod}
          disabled={watchIsLinkedCustomer}
          options={methodOptions}
        />
      </div>
      <label className="step-one-title">Mode</label>
      <div style={{ display: 'flex', marginTop: 8 }}>
        <CustomRadios
          onChange={(value) => {
            setValue('couponMode', value, { shouldDirty: true });
          }}
          default={watchCouponMode}
          disabled={!!watchTotalNubmerOfGeneratedCoupons}
          options={COUPON_MODE_CONFIG}
        />
      </div>
    </div>,
  ];
  return (
    <>
      <div className="create-coupon-step-one-container">
        <ContentSections sections={sections} hidePreview={true} />
      </div>
      <OnlyContinueButton
        disabledContinue={ !watchCouponSetType || !watchRedemptionMethod || !watchCouponMode}
        continueAction={() => {
          dispatch(
            createAction('createCoupon/stepChange')({
              step: 0,
              isValid: true,
            }),
          );
          // saveCouponToSessionStorage(getValues());
        }}
      />
    </>
  );
};
export default CreateCouponStepOne;
