import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { singleCouponBreads } from '../../../config/CustomBreadConfig';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import SingleCouponOverview from '../../../components/coupon/couponList/SingleCouponOverview';
import { DeleteAndMultipleButtons } from '../../../components/base/BottomStepComponent';
import { connect } from 'react-redux';
import { createAction } from '../../../utils';
import DeleteSingleCouponView from '../../../components/coupon/couponList/DeleteSingleCouponView';
import DeactiveSingleCouponView from '../../../components/coupon/couponList/DeactiveSingleCouponView';
import {
  CouponURLQueryKeys,
  CouponURLFragments,
} from '../../../components/coupon/CouponUrlConfig';
import DeletePrompt from '../../../components/base/DeletePrompt';
import { ActionType, ApprovalStatus, DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';
import { PermissionCodes } from '../../../config/PermissionCodes';
const queryString = require('query-string');

@connect(({ couponList, singleCoupon, users }) => ({
  couponSet: couponList.couponSet,
  singleCoupon: singleCoupon.singleCoupon,
  users: users
}))
class SingleCouponDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteView: false,
      showDeactiveView: false,
      selectedOwner: props.singleCoupon?.owner,
      isSuperuser: props.users.isSuperuser,
      userPermissions: props.users.userPermissions,
    };
  }

  componentDidMount() {
    const couponSetId = this.props.match.params.id;
    const singleCouponId = this.props.match.params.singleCouponId;
    this.props.dispatch(
      createAction('couponList/getCouponSet')({
        couponSetPK: couponSetId,
        notSaveToSession: true,
      }),
    );
    this.props.dispatch(
      createAction('singleCoupon/getSingleCoupon')({
        pk: singleCouponId,
      }),
    );
  }

  componentWillUnmount() {
    this.props.dispatch(createAction('singleCoupon/clearSingleCoupon')());
  }

  goBacktoCouponList = () => {
    return this.props.history.push({
      pathname: `/coupons/${this.props.couponSet.pk}`,
      search: queryString.stringify({
        [CouponURLQueryKeys.rank]: true,
        [CouponURLQueryKeys.page]: 1,
        [CouponURLQueryKeys.searchKey]: '',
      }),
      hash: CouponURLFragments.list,
      state: this.props.couponSet,
    });
  };

  buttonClicked = (actionType) => {
    if ((this.state.selectedOwner && this.state.selectedOwner !== '-')
    || (actionType !== ActionType.none && actionType !== ActionType.submitForApproval)) {
      this.props.dispatch(
        createAction('singleCoupon/approvalCouponStatus')({
          couponPK: this.props.singleCoupon.pk,
          customerPK: this.state.selectedOwner?.value?.pk,
          actionType: actionType,
          afterAction: this.goBacktoCouponList,
        }),
      );
    }
    this.goBacktoCouponList();
  }

  saveButtonClicked = () => {
    this.buttonClicked(this.getSaveAction())
  };

  tempButtonClicked = () => {
    this.buttonClicked(this.getTempAction())
  }
  
  hasPermission = (requires) => {
    if (this.state.isSuperuser) return true;
    if (this.state.userPermissions.includes(requires) || !requires) return true;

    return false;
  };

  getTempAction = () => {
    switch (this.props?.singleCoupon?.approvalStatus) {
      case ApprovalStatus.pendingForApproval:
        return ActionType.withdrawFromPendding
      case ApprovalStatus.pendingForPublish:
        return ActionType.withdrawFromApproval
      default:
        return ActionType.none
    }
  }
  
  hasTempPermission = () => {
    switch (this.props?.singleCoupon?.approvalStatus) {
      case ApprovalStatus.pendingForApproval:
        return this.hasPermission(PermissionCodes.withdrawSingleCouponForS2)
      case ApprovalStatus.pendingForPublish:
        return this.hasPermission(PermissionCodes.withdrawSingleCouponForS3ToS2) ||
        this.hasPermission(PermissionCodes.withdrawSingleCouponForS3ToS1)
      default:
        return false
    }
  }
  
  getSaveText = () => {
    switch (this.props?.singleCoupon?.approvalStatus) {
      case ApprovalStatus.published:
        return 'Reclaim'
      case ApprovalStatus.pendingForApproval:
        if (this.hasPermission(PermissionCodes.approvalSingleCoupon)) {
          return 'Approve'
        }
        return 'Pending for approval'
      case ApprovalStatus.pendingForPublish:
        if (this.hasPermission(PermissionCodes.publishSingleCoupon)) {
          return 'Publish'
        }
        return 'Pending for publish'
      default:
        return 'Submit for approval'
    }
  }
  getSaveAction = () => {
    switch (this.props?.singleCoupon?.approvalStatus) {
      case ApprovalStatus.published:
        return ActionType.reclaim
      case ApprovalStatus.pendingForApproval:
        if (this.hasPermission(PermissionCodes.approvalSingleCoupon)) {
          return ActionType.approval
        }
        return ActionType.none
      case ApprovalStatus.pendingForPublish:
        if (this.hasPermission(PermissionCodes.publishSingleCoupon)) {
          return ActionType.publish
        }
        return ActionType.none
      default:
        return ActionType.submitForApproval
    }
  }

  hasSavePermission = () => {
    switch (this.props?.singleCoupon?.approvalStatus) {
      case ApprovalStatus.draft:
        return this.hasPermission(PermissionCodes.pendingSingleCoupon)
      case ApprovalStatus.pendingForApproval:
        return this.hasPermission(PermissionCodes.approvalSingleCoupon)
      case ApprovalStatus.pendingForPublish:
        return this.hasPermission(PermissionCodes.publishSingleCoupon)
      case ApprovalStatus.published:
        return this.hasPermission(PermissionCodes.reclaimSingleCouponToS1) ||
        this.hasPermission(PermissionCodes.reclaimSingleCouponToS2) ||
        this.hasPermission(PermissionCodes.reclaimSingleCouponToS3)
      default:
        return false
    }
  }

  getBottomActions = () => {
    let actions = [];
    if (this.props.singleCoupon?.isForcedInactive) {
      actions = [
        {
          text: 'Active',
          action: () => {
            this.props.dispatch(
              createAction('singleCoupon/activeSingleCoupon')({
                couponPK: this.props.singleCoupon.pk,
                activeAction: this.goBacktoCouponList,
              }),
            );
          },
        },
        ...actions,
      ];
    } else {
      actions = [
        {
          text: 'Deactive',
          action: () => this.setState({ showDeactiveView: true }),
        },
        ...actions,
      ];
    }
    if (this.hasTempPermission()) {
      actions = [
        ...actions,
        {
          text: 'Withdraw',
          action: this.tempButtonClicked
        }
      ]
    }
    if (this.hasSavePermission()) {
      actions = [
        ...actions,
        {
          text: this.getSaveText(),
          action: this.saveButtonClicked,
        },
      ];
    }
    return actions;
  };

  render() {
    return (
      <div>
        <CustomListComponent
          breadcrumb={
            <CustomBreadcrumb
              breadcrumbs={singleCouponBreads(
                this.props.couponSet.name,
                this.props.singleCoupon?.serialNumber,
              )}
            />
          }
          title={`${this.props.couponSet.name}(${this.props.singleCoupon?.serialNumber})`}
        />
        <SingleCouponOverview
          singleCoupon={this.props.singleCoupon}
          couponSet={this.props.couponSet}
          defaultOwner={
            this.state.selectedOwner && this.state.selectedOwner.owner !== '-'
              ? this.state.selectedOwner
              : ''
          }
          selectedOwner={(owner) => {
            this.setState({ selectedOwner: owner });
          }}
        />
        <DeleteAndMultipleButtons
          deleteAction={() => {
            this.setState({ showDeleteView: true });
          }}
          multipleButtons={this.getBottomActions()}
        />
        <DeletePrompt
          data={this.props.singleCoupon}
          show={this.state.showDeleteView}
          title={'Single Coupon'}
          relatedName=""
          relatedSections={DELETE_RELATED_SECTIONS.COUPON}
          onClose={() => {
            this.setState({ showDeleteView: false });
          }}
          onConfirm={() => {
            this.props.dispatch(
              createAction('singleCoupon/updateState')({
                checkedList: [this.props.singleCoupon],
              }),
            );
            this.props.dispatch(
              createAction('singleCoupon/delete')({
                couponPK: this.props.singleCoupon.pk,
                afterAction: this.goBacktoCouponList,
              }),
            );
          }}
        />

        <DeactiveSingleCouponView
          showDeactiveView={this.state.showDeactiveView}
          onHide={() => this.setState({ showDeactiveView: false })}
          singleCoupon={this.props.singleCoupon}
          couponSet={this.props.couponSet}
          activeBtnClicked={() => {
            this.props.dispatch(
              createAction('singleCoupon/deactiveSingleCoupon')({
                couponPK: this.props.singleCoupon.pk,
                afterAction: this.goBacktoCouponList,
              }),
            );
          }}
        />
      </div>
    );
  }
}

export default withRouter(SingleCouponDetail);
