import { LanguageConfig, MessageChannel } from '../../../config/CustomEnums';

export const CreateMessageError = {
  content: {
    name: `translations.${LanguageConfig.english}.content`,
    message: 'Please provide a content.',
  },
  scheduledDate: {
    name: 'scheduledDate',
    message: 'Please provide a valid schedule time.',
  },
  channels: {
    name: 'channels',
    message: 'Please provide channels.',
  },
  photo: {
    name: `translations.${LanguageConfig.english}.photo`,
    message: 'Please provide a photo.',
  },
  scheduledPeriodDate: {
    name: 'scheduledPeriodDate',
    message: 'Please provide a valid start time/end time.',
  },
};

function firstStepValidate({ getValues, setError }) {
  const [channels] = getValues(['channels']);
  const errorList = [];
  if (channels == null || channels.length === 0) {
    errorList.push({
      name: CreateMessageError.channels.name,
      message: CreateMessageError.channels.message,
    });
  }
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'require',
      message: error.message,
    });
  });
  console.log('errorListMessage:', errorList);
  return !errorList.length;
}

function secondStepValidate({ getValues, setError }) {
  const errorList = [];
  const [channels, content, photo] = getValues([
    'channels',
    `translations.${LanguageConfig.english}.content`,
    `translations.${LanguageConfig.english}.photo`,
  ]);
  const isSelectFullScreenAd = channels.indexOf(MessageChannel.fullScreenAd) !== -1;
  if (!content && !isSelectFullScreenAd) {
    errorList.push({
      name: CreateMessageError.content.name,
      message: CreateMessageError.content.message,
    });
  }
  if (!photo && isSelectFullScreenAd) {
    errorList.push({
      name: CreateMessageError.photo.name,
      message: CreateMessageError.photo.message,
    });
  }
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'require',
      message: error.message,
    });
  });
  console.log('errorListMessage:', errorList);
  return !errorList.length;
}

function thirdStepValidate({ getValues, setError }) {
  const [
    channels,
    scheduledDate,
    scheduledPeriodStartDate,
    scheduledPeriodEndDate,
  ] = getValues([
    'channels',
    'scheduledDate',
    'scheduledPeriodStartDate',
    'scheduledPeriodEndDate',
  ]);
  const isSelectFullScreenAd = channels.indexOf(MessageChannel.fullScreenAd) !== -1;
  const errorList = [];
  const nowDate = new Date(new Date().setHours(0,0,0,0));
  if (isSelectFullScreenAd) {
    if (!scheduledPeriodStartDate || !scheduledPeriodEndDate || new Date(scheduledPeriodStartDate) < nowDate) {
      errorList.push({
        name: CreateMessageError.scheduledPeriodDate.name,
        message: CreateMessageError.scheduledPeriodDate.message,
      });
    }
  } else {
    if (!scheduledDate || new Date(scheduledDate) < new Date()) {
      errorList.push({
        name: CreateMessageError.scheduledDate.name,
        message: CreateMessageError.scheduledDate.message,
      });
    }
  }
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'require',
      message: error.message,
    });
  });
  return !errorList.length;
}

export function validate({ getValues, setError, clearErrors, step, isBack, languages }) {
  if (isBack) {
    return true;
  }
  clearErrors();
  switch (step) {
    case 0:
      const validStepOne = firstStepValidate({ getValues, setError });
      return validStepOne;
    case 1:
      const validStepTwo = secondStepValidate({ getValues, setError });
      return validStepTwo;
    case 2:
      const validStepThree = thirdStepValidate({ getValues, setError });
      return validStepThree;
    default:
      const validOne = firstStepValidate({ getValues, setError });
      const validTwo = secondStepValidate({ getValues, setError });
      const validThree = thirdStepValidate({ getValues, setError });
      return validOne && validTwo && validThree;
  }
}
