import { getSearchKey } from '../utils';
import { BaseHelper } from './BaseGraphQLAPIHelper';

const VENDOR_NODE = `{
  id
  pk
  name
  icon
  providerCode
  deepLink
  active
  translations{
    edges{
        node{
            name
            language
        }
    }
  }
}`;

export const getVendorList = (afterCursor, payload) => {
  const { moreSearch, searchKey } = payload;
  let searchString = payload?.isAll ? '' : `first: ${payload?.pageSize || 20}`;
  if (afterCursor) {
    searchString += `, after: "${afterCursor}"`;
  }
  if (moreSearch?.sort) {
    searchString += `, orderBy: "${moreSearch.sort}"`;
  } else {
    searchString += `, orderBy: "name"`;
  }
  if (searchKey) {
    searchString += `, nameIcontains:"${getSearchKey(searchKey)}"`;
  }
  
  const query = `{
    evChargerVendors(${searchString}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node ${VENDOR_NODE}
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getVendor = (id) => {
  const query = `{
    evChargerVendor(id: "${id}") ${VENDOR_NODE}
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};
