import {
    getPagedDistrictMallMappings,
    updateDistrictMallMapping,
    getOneDistrictMallMapping,
  } from '../services/DistrictMallMappingApiHelper';
import {
  APIStatus,
  CheckStatus,
  SavedStatus,
  SESSION_KEYS,
} from '../config/CustomEnums';
import {
  convertNumberToCursor,
  convertCursorToNumber,
  convertPKToId,
  getObjectFromSessionStorage,
  saveToSessionStorage,
  removeFromSessionStorage,
} from '../utils';
import { apiWithResponseHandle, loading } from './LoadingUtil';

const districtMallMappingSessionKey = SESSION_KEYS.DISTRICT_MALL_MAPPING_KEY;

export const UpdateDistrictMallMappingError = {
  mallLimit: {
    name: 'malls',
    message: 'Please do not select same malls.',
  },
};

const parseDistrictMallMapping = (item) => {
  let mallNameList = []

  if (item.favouriteMall1) {
    mallNameList.push(item.favouriteMall1.name)
  }
  if (item.favouriteMall2) {
    mallNameList.push(item.favouriteMall2.name)
  }
  if (item.favouriteMall3) {
    mallNameList.push(item.favouriteMall3.name)
  }

  const favouriteMall = (item, num) =>{
    let fieldName = `favouriteMall${num}`;
    return {
      label: item[fieldName] ? `[ID:${item[fieldName]?.pk}] ${item[fieldName]?.name}` : '-',
      value: item[fieldName]
    }
  }
  return {
    ...item,
    districtName: item.residentialRegion ? item.residentialRegion.name : item.workingDistrict?.name,
    favouriteMall1: favouriteMall(item, 1),
    favouriteMall2: favouriteMall(item, 2),
    favouriteMall3: favouriteMall(item, 3),
    mallNames: mallNameList.length > 0
      ? mallNameList.toString() 
      : '-',
  };
};

const parseDistrictMallMappingList = (data) => {
  // console.log('121', data)
  return data.map((item) => parseDistrictMallMapping(item.node));
};

const parseInputBody = (districtMallMapping) => {
  let inputBody = {
    id: districtMallMapping.pk,
    favouriteMall1: districtMallMapping.favouriteMall1?.value?.pk || null,
    favouriteMall2: districtMallMapping.favouriteMall2?.value?.pk || null,
    favouriteMall3: districtMallMapping.favouriteMall3?.value?.pk || null,
  };
  return inputBody
}

const getInitialState = () => ({
  oneDistrictMallMapping: {},
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk', linked: true },
    { displayName: 'Name', fieldName: 'districtName' },
    { displayName: 'Favourite malls', fieldName: 'mallNames' },
  ],
  districtMallMappingList: [],
  totalCount: 0,
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  currentPage: 0,
  totalPage: 0,
  checkedList: [],
  checked: CheckStatus.initOrNotChecked,
  errorFields: {},
  saved: SavedStatus.init,
  formChanged: false,
  hasUpdatedDefaultValues: false,
  formHasSubmitted: false,
});

export default {
  namespace: 'districtMallMappingList',
  state: getInitialState(),
  params: {
    sessionKey: SESSION_KEYS.DISTRICT_MALL_MAPPING_KEY,
    listAPI: getPagedDistrictMallMappings,
    parse: (data) => parseDistrictMallMappingList(data?.districtMallMappings?.edges || []),
    objectKey: 'districtMallMappings'
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    loadDataFromSessionStorage(state, { payload }) {
      const tempDistrictMallMapping = getObjectFromSessionStorage(districtMallMappingSessionKey);
      if(!tempDistrictMallMapping) {
        return {
          ...state,  
        }
      }

      return {
        ...state,
        oneDistrictMallMapping: tempDistrictMallMapping,
        hasUpdatedDefaultValues: true,
      };
    },

    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },
  effects: {
    getPagedDistrictMallMappings: [
      function* ({ payload }, { call, put }) {
        const page = payload.page;
        const pageCursor = payload.page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const serviceArgs = [
          getPagedDistrictMallMappings,
          pageCursor,
          { ...payload.moreSearch, ...payload },
        ];
        function* onSuccess(data) {
          console.log('@@115: ', data);
          const pageInfo = data?.districtMallMappings?.pageInfo;

          const currentLastCursor = pageInfo?.endCursor;
          const totalCount = data?.districtMallMappings?.totalCount;

          yield put({
            type: 'updateState',
            payload: {
              districtMallMappingList: parseDistrictMallMappingList(
                data?.districtMallMappings?.edges,
              ),
              pageInfo: {
                startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1 || 0,
                endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1 || 0,
              },
              currentLastCursor,
              totalCount,
              totalPage: Math.ceil(totalCount / 20),
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    getOneDistrictMallMapping: [
      function* ({ payload }, { all, put, select }) {
        yield put({
          type: 'updateState',
          payload: {
            loadingStatus: APIStatus.calling
          },
        });
        const mappingId = payload.id
        const serviceArgs = [
          getOneDistrictMallMapping,
          convertPKToId('DistrictMallMappingNode', mappingId),
        ];

        function* onSuccess(data) {
          yield all([
            put({
              type: 'updateState',
              payload: {
                oneDistrictMallMapping: parseDistrictMallMapping(data?.districtMallMapping),
                loadingStatus: APIStatus.success,
                hasUpdatedDefaultValues: true,
              },
            }),
          ]);
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    updateDistrictMallMapping: [
      function* ({ payload }, { all, put, select }) {
        const afterAction = payload.afterAction || (() => {});
        const { values } = payload;
        const inputBody = parseInputBody(values);
        const serviceArgs = [updateDistrictMallMapping, inputBody];

        function* onSuccess(data) {
          yield put({
            type: 'saveOrRemoveCustomerGroupFromCookie',
            payload: false,
          });
          yield put({
            type: 'updateState',
            payload: {
              formHasSubmitted: true,
            },
          });
          yield afterAction();
        }

        function* onFailed(data) {
          console.log('@@122: ', data);

          yield put({
            type: 'updateState',
            payload: {
              formChanged: false,
              saved: SavedStatus.savedWithFail,
            },
          });
        }
        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
  },
};
