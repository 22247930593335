import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import dva from './Dva';
import NavBarsModel from './models/NavBarModel';
import UsersModel from './models/UsersModel';
import CreateCampaignModel from './models/CreateCampaignModel';
import CreateCouponModel from './models/CreateCouponModel';
import SettingModel from './models/SettingModel';
import UploadFilesModel from './models/UploadFilesModel';
import BrandModel from './models/BrandModel';
import CarParkModel from './models/CarParkModel';
import EvChargerVendorModel from './models/EvChargerVendorModel';
import MallModel from './models/MallModel';
import StoreModel from './models/StoreModel';
import CouponListModel from './models/CouponListModel';
import SingleCouponModel from './models/SingleCouponModel';
import CustomerListModel from './models/CustomerListModel';
import CampaignListModel from './models/CampaignListModel';
import DashboardModel from './models/DashboardModel';
import EarningRulesModel from './models/EarningRulesModel';
import CreateEarningRulesModel from './models/CreateEarningRulesModel';
import LoadingModel from './models/LoadingModel';
import SegmentModel from './models/SegmentModel';
import LevelModel from './models/LevelModel';
import DownloadAndImport from './models/DownloadImportModel';
import CouponTransactionModel from './models/CouponTransactionModel';
import CouponTransactionRecordModel from './models/CouponTransactionRecordModel';
import CreatePointRecordModel from './models/CreatePointRecordModel';
import PointTransactionListModel from './models/PointTransactionListModel';
import TransactionRecordListModel from './models/TransactionRecordListModel';
import TransactionModel from './models/TransactionModel';
import CreateTransactionModel from './models/CreateTransactionModel';
import createTransactionRecord from './models/CreateTransactionRecordModel';
import AdminModel from './models/AdminModel';
import AdminGroupModel from './models/AdminGroupModel';
import CustomerGroupModel from './models/CustomerGroupModel';
import MessageListModel from './models/MessageListModel';
import CampaignCategoryListModel from './models/CampaignCategoryListModel';
import CreateCampaignCategoryModel from './models/CreateCampaignCategoryModel';
import CreateMessageModel from './models/CreateMessageModel';
import { persistStore, persistReducer } from 'redux-persist';
import { CookieStorage } from 'redux-persist-cookie-storage';
import { createFilter } from 'redux-persist-transform-filter';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { PersistGate } from 'redux-persist/integration/react';
import Cookies from 'cookies-js';
import OverviewModel from './models/OverviewModel';
import StoreCategoryModel from './models/StoreCategoryModel';
import CreateStoreCategoryModel from './models/CreateStoreCategoryModel';
import CreateStoreCategoryLv3Model from './models/CreateStoreCategoryLv3Model';
import CreateStoreCategoryLv4Model from './models/CreateStoreCategoryLv4Model';
import HomeBannersModel from './models/HomeBannersModel';
import HomeFeaturedModel from './models/HomeFeaturedModel';
import LanguageModel from './models/LanguageModel';
import FillingFormModel from './models/FillingFormModel';
import CampaignTagModel from './models/CampaignTagModel';
import TestModel from './models/TestModel';
import Mall from './models/MallModel';
import FreshMarket from './models/FreshMarketModel';
import StampRecordModel from './models/StampRecordModel';
import BadgeRecordModel from './models/BadgeRecordModel';
import WebContentModel from './models/WebContentModel';
import AppVersionModel from './models/AppVersionModel';
import ThemedSectionModel from './models/ThemedSectionModel';
import DistrictMallMappingModel from './models/DistrictMallMappingModel';

const midwares = [];
const usersFilter = createFilter('users', [
  'isLogin',
  'username',
  'isSuperuser',
  'firstLogin',
]);

const persistConfig = {
  key: 'root',
  storage: new CookieStorage(Cookies),
  whitelist: ['users'],
  stateReconciler: autoMergeLevel2,
  transforms: [usersFilter],
};

const app = dva({
  initialState: {},
  models: [
    NavBarsModel,
    UsersModel,
    CampaignListModel,
    CreateCampaignModel,
    CouponListModel,
    SingleCouponModel,
    CreateCouponModel,
    EarningRulesModel,
    SettingModel,
    UploadFilesModel,
    BrandModel,
    CarParkModel,
    EvChargerVendorModel,
    StoreModel,
    CustomerListModel,
    LoadingModel,
    CreateEarningRulesModel,
    SegmentModel,
    LevelModel,
    DownloadAndImport,
    CouponTransactionModel,
    CouponTransactionRecordModel,
    CreatePointRecordModel,
    PointTransactionListModel,
    TransactionRecordListModel,
    DashboardModel,
    OverviewModel,
    TransactionModel,
    CreateTransactionModel,
    createTransactionRecord,
    AdminModel,
    AdminGroupModel,
    CustomerGroupModel,
    MessageListModel,
    CampaignCategoryListModel,
    CampaignTagModel,
    CreateCampaignCategoryModel,
    CreateMessageModel,
    StoreCategoryModel,
    CreateStoreCategoryModel,
    CreateStoreCategoryLv3Model,
    CreateStoreCategoryLv4Model,
    HomeBannersModel,
    HomeFeaturedModel,
    LanguageModel,
    FillingFormModel,
    TestModel,
    Mall,
    FreshMarket,
    StampRecordModel,
    BadgeRecordModel,
    WebContentModel,
    AppVersionModel,
    ThemedSectionModel,
    DistrictMallMappingModel,
  ],
  onReducer: (rootReducer) => persistReducer(persistConfig, rootReducer),
  onAction: midwares,
  onError(e) {
    console.log('onError', e);
  },
});

const persistor = persistStore(app.getStore(), null, () => {
  app.getStore().dispatch({ type: 'users/startUp' });
});

const DvaApp = app.start(<App />);
ReactDOM.render(
  <React.StrictMode>
    <PersistGate load={null} persistor={persistor}>
      <DvaApp />
    </PersistGate>
  </React.StrictMode>,
  document.getElementById('root'),
);
serviceWorker.unregister();
