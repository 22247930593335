import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Collapse, Image } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import queryString from 'query-string';
import './CreateCampaignStepOne.scss';
import BasePrompt from '../../base/prompt/BasePrompt';
import ContentSections from '../../base/ContentSections';
import { OnlyContinueButton } from '../../base/BottomStepComponent';
import { ReactHookFormErrorMessage } from '../../base/ErrorFieldMessage';
import ItemTypeSet, { MIN_WIDTH, SPACE_BETWEEN } from '../../base/ItemTypeSet';
import { validate } from '../../../containers/engagement/campaign/CreateCampaignValidate';
import EarningRulesSelectDropdown from './EarningRulesSelectDropdown';
import {
  getCampaignTypeSelectPromptConfig,
  getCampaignTypeConfig,
} from './CreateCampaignStepOneConfigs';
import { ContinueCreate } from '../CampaignUrlConfig';
import {
  getScrollbarWidth,
  useMainContentWidth,
} from '../../../utils/ScreenUtil';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { CampaignType, APIStatus, CampaignDisplayIn, EarningRuleRewardType, EarningRuleType } from '../../../config/CustomEnums';
import addImage from '../../../assets/images/addx1.png';
import {
  isShowStampRelated,
} from '../../../models/CreateCampaignModel';
import BaseMultipleSelectorV2 from '../../base/BaseMultipleSelectorV2';
import { SimpleListForEstampWithCouponNode } from '../../../services/CampaignAPIHelper';

function CreateEStampCampaignStepOne({
  status,
  promptShowed,
  isPublished,
  couponCampaignList,
  campaignList,
}) {
  const mainContentWidth = useMainContentWidth();
  const fullContainerWith = mainContentWidth - 80 - 60 - getScrollbarWidth();

  const [showCreateNewLinkPrompt, setShowCreateNewLinkPrompt] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    getValues,
    watch,
    setValue,
    control,
    formState,
    clearErrors,
    setError,
  } = useFormContext();
  const { errors } = formState;

  const watchCampaignType = watch('campaignType');
  const watchEarningRules = watch('linkedEarningRules');
  const watchLinkedCouponCampaigns = watch("linkedCouponCampaigns") || [];
  const isStampRelated = isShowStampRelated(watchCampaignType);
  const params = useParams();
  const campaignId = params.id;

  const selectRef = useRef('linkDropdown');
  // const [createNewLinkTitle, setCreateNewLinkTitle] = useState();

  const promptconfig = getCampaignTypeSelectPromptConfig(watchCampaignType);
  const search = queryString.parse(history.location.search);

  console.log("@79", watchLinkedCouponCampaigns, watchLinkedCouponCampaigns.length)
  useEffect(() => {
    if (history.location.search && !watchCampaignType) {
      const type = search.type;
      const id = search.id;
      // const actionName = type === CampaignType.couponsCampaign
      //   ? 'getAndLinkCouponDetail'
      //   : 'getAndLinkEarningRuleDetail';
      // console.log("@90")
      if (type === CampaignType.estamp){
        dispatch({
          type: 'createCampaign/getAndLinkEarningRuleDetail',
          payload: {
            earningRuleId: id,
            afterAction: (data) => {
              console.log("@93", data);
              setValue('displayIn', CampaignDisplayIn.estamp)
              setValue('campaignType', type);
              setValue('linkedEarningRules', data.linkedEarningRules);
              // goToNextStep(true);
            }
          }
        });
      }
    } 
  })

  useEffect(() => {
    dispatch({
      type: 'campaignList/getCampaignList',
      payload: {
        displayIn: CampaignDisplayIn.stamp,
        type: CampaignType.couponsCampaign,
        isAll: true,
        fieldNode: SimpleListForEstampWithCouponNode,
        stampCampaignId: campaignId || -1
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const earningRuleLinkSet = (
    <Controller
      control={control}
      name="linkedEarningRules"
      render={() => (
        <>
          <div className="step-link-coupon-area-select">
            <EarningRulesSelectDropdown
              title={'Link to earning rule'}
              defaultValue={{
                value: watchEarningRules,
                label: watchEarningRules?.name,
              }}
              setValue={(item) => {
                const earningRule = item || {};
                setValue('linkedEarningRules', earningRule, { shouldDirty: true });
              }}
              addButtonInfo={{
                requires: PermissionCodes.changeEarningRule,
                children: <Image src={addImage} />,
                action: () => setShowCreateNewLinkPrompt(true),
                customClass: 'earning-rule-add-new-link-button',
              }}
              defaultFilter={{
                reward_type: EarningRuleRewardType.stamp,
                type: EarningRuleType.generalPurchase,
              }}
            />
          </div>
          <ReactHookFormErrorMessage errors={errors} id="linkedEarningRules" />
        </>
      )}
    />
  );

  const campaignTypeSelectArea = (
    <Controller
      control={control}
      name="campaignType"
      render={() => (
      <div
        className="step-type-area campaign-step-one-bottom"
        style={{
          justifyContent:
            fullContainerWith < (MIN_WIDTH + SPACE_BETWEEN) * 2
              ? 'center'
              : 'flex-start',
        }}
      >
        {getCampaignTypeConfig(CampaignDisplayIn.estamp).map((typeItem) => (
          <ItemTypeSet
            key={`${typeItem.id}-${typeItem.description}`}
            item={typeItem}
            selected={watchCampaignType}
            disable={isPublished}
            onClick={(id) => {
              if (id === watchCampaignType) {
                return;
              }
              clearErrors();
              setValue('displayIn', CampaignDisplayIn.estamp)
              setValue('campaignType', id, { shouldDirty: true });
              dispatch({
                type: 'createCampaign/typeChange',
                payload: { campaignType: id },
              });
            }}
          />
        ))}
        </div>
      )}
    />
  )

  const stampDetailForCouponCampaigns = <>
    <BaseMultipleSelectorV2
      title={"Link to coupon campaign (For stamp campaign detail page)"}
      namespace="campaignModel"
      custom={{
        customItem: (item) => {
          return `[ID:${item.pk}] ${item.name}`
        }
      }}
      data={{
        sourceData: couponCampaignList,
        targetData: watchLinkedCouponCampaigns,
        targetChange: (value) => {
          setValue('linkedCouponCampaigns', value, {
            shouldDirty: true,
          });
        },
      }}
    />
  </>
  
  const sections = () => {
    let section = [
      <div>
        <label className="step-one-title">Campaign Type</label>
        {campaignTypeSelectArea}
      </div>
    ]
    if (isStampRelated) {
      section.push(<>
        {earningRuleLinkSet}
        {stampDetailForCouponCampaigns}
        <BasePrompt
          show={showCreateNewLinkPrompt}
          closeAction={() => setShowCreateNewLinkPrompt(false)}
          rightButton={{
            text: promptconfig.button,
            action: () => {
              const pathname =
                watchCampaignType === CampaignType.couponsCampaign
                  ? '/coupons/create'
                  : '/earns/create';
              history.push({
                pathname: pathname,
                state: { from:
                  {...history.location,
                    state: {
                      ...history.location?.state,
                      resume: true
                    }}, ...ContinueCreate },
              });
            },
          }}
          title={promptconfig.title}
          description={promptconfig.message}
        />
      </>)
    }
    return section
  }

  const goToNextStep = (assignRemain) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 0,
      isBack: false,
    });
    // setValue('assignRemain', assignRemain);
    dispatch({
      type: 'createCampaign/stepChange',
      payload: {
        step: 0,
        isValid,
      },
    });
  };

  return (
    <>
      <ContentSections sections={sections()} hidePreview={true} />
      <OnlyContinueButton
        continueAction={async () => {
          goToNextStep(true);
        }}
        disabledContinue={
          !watchCampaignType ||
          (watchCampaignType === CampaignType.estampCampaign &&
            !watchEarningRules?.pk) ||
          (watchCampaignType === CampaignType.estampCampaign && 
            watchLinkedCouponCampaigns?.length===0)
        }
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  campaignType: state.createCampaign.campaign.campaignType,
  linkedEarningRules: state.createCampaign.campaign.linkedEarningRules,
  couponCampaignList: state.campaignList.pagedList,
  campaignList: state.campaignList,
  status: state.createCampaign.createStatus,
  promptShowed: state.createCampaign.promptShowed,
  isPublished: state.createCampaign.campaign.isPublished,
})

export default connect(mapPropsToState)(CreateEStampCampaignStepOne);
