import {
  getMalls,
  getCampaignTags,
} from '../services/TestAPIHelper';
import { createModel } from './BaseModel';
import { loading } from './LoadingUtil';

const getInitState = () => {
  return {
    listDisplayFields: [],
    pageInfo: {
      startCursor: '',
      endCursor: '',
      hasNextPage: false,
      hasPreviousPage: false,
    },
    totalPage: 0,
    totalCount: 0,
    currentPage: 0,
    checkedList: [],
    errorFields: {},
    formChanged: false,
    formHasSubmitted: false,
    hasUpdatedDefaultValues: false,
  };
};

export default createModel({
  namespace: 'testApis',
  state: getInitState(),
  params: {
    sessionKey: 'testApis',
    listAPI: getMalls,
    parse: (data) => data?.mallOrFreshmarkets?.edges?.map((item) => item.node),
    objectKey: 'mallOrFreshmarkets',
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    getCampaignTags:[
      function* ({ payload }, { put, select }) {
        const serviceArgs = [getCampaignTags, '', payload];
        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: {
              campaignTagsList: data?.campaignTags?.edges?.map((item) => item.node)
            },
          });
        
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  }
});
