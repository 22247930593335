import React, { useState, useEffect } from 'react';
import './StoreList.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { NavBarNames } from '../../../config/NavBarNameList';
import { createAction } from '../../../utils';
import DeletePrompt from '../../../components/base/DeletePrompt';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import Filter from './Filter';
import { DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';
import { ActionsDropdownForItem } from '../../../components/base/ActionsDropdown';
import { downloadZipFiles } from './QRCodeDownloadUtil';
import './StoreCodeList.scss';
import BasePrompt from '../../../components/base/prompt/BasePrompt';
import { ImportResource } from '../../../models/DownloadImportModel';
import CustomSwitchButton from '../../../components/base/CustomSwitchButton';
import BaseMutipleSelectorV2 from '../../../components/base/BaseMultipleSelectorV2';
import SelectMultipleBrand from '../../../components/base/SelectMultipleBrand';

const ExportStoreCodePop = ({ show, onClose }) => {
  const dispatch = useDispatch();
  const [exportSeparate, setExportSeparate] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState([]);

  // console.log("@30",brand, brandList2)
  const formPart = (
    <div className="performance-report-prompt-other-body">
      <div className="other-body-multi-selector">
        <SelectMultipleBrand
          title={'Select Brand(s)'}
          value={selectedBrand}
          onChange={(v) => setSelectedBrand(v)}
        />
      </div>
      <div className="other-body-title">
        Export in separate files by brand(s)
      </div>
      <div className="other-body-discription">
        If On, multiple files will be downloaded based on different brands. If
        off, all the data merge into one file.
      </div>
      <CustomSwitchButton
        defaultChecked={exportSeparate}
        onChange={(isChecked) => {
          setExportSeparate(isChecked);
        }}
      />
    </div>
  );

  return (
    <BasePrompt
      show={show}
      closeAction={() => {
        onClose();
      }}
      rightButton={{
        text: 'Export',
        action: () => {
          const exportData = {
            brands: selectedBrand.map((item)=> item?.value?.pk),
            separate_files: exportSeparate,
          };
          dispatch(
            createAction('downloadAndImport/createDownloadTask')({
              from: ImportResource.storeQRCodeExport,
              related: {
                filterConditions: JSON.stringify(exportData),
              },
            }),
          );
          onClose();
        },
      }}
      title={'Export Cover Photo'}
      otherBody={() => formPart}
    />
  );
};

export default function StoreCodeList() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [showExportPrompt, setShowExportPrompt] = useState(false);

  const buttons = [
    <AuthButton
      title="Export Cover Pohto"
      action={() => {
        setShowExportPrompt(true);
      }}
      requires={PermissionCodes.addExportjob}
      customClass={'general-section-add-new btn-add-button-common'}
    />,
  ];

  const { listFields, dataList, pageInfo, totalPage, totalCount, checkedList } =
    useSelector((state) => ({
      listFields: state.storeModel.codeListDisplayFields,
      dataList: state.storeModel.pagedStoreList,
      pageInfo: state.storeModel.pageInfo,
      totalPage: state.storeModel.totalPage,
      totalCount: state.storeModel.totalCount,
      checkedList: state.storeModel.checkedList,
    }));

  const groupActions = [
    {
      name: 'Download',
      action: () => {
        dispatch({ type: 'storeModel/downloadStoreCodes' });
      },
    },
  ];

  const tabs = [
    {
      name: '',
      content: (
        <BaseTabListContainer
          hideTab={true}
          tabs={[]}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          listContent={
            <BaseListContainer
              customClassName={'store-code-list-table'}
              fields={listFields}
              dataList={dataList}
              totalPage={totalPage}
              model={'storeModel'}
              permissionGroup={PermissionCodes.store}
              deleteInfo={{
                data: [],
                title: 'store',
                relatedName: '',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.STORE,
              }}
              sortable={false}
              rankDisabled
              useCustomCommonActions
              customCommonActions={(item) => {
                const actions = [
                  {
                    name: 'Download',
                    action: () => {
                      downloadZipFiles(item.codeDownloadImage);
                    },
                  },
                ];
                return (
                  <ActionsDropdownForItem object={item} actions={actions} />
                );
              }}
            />
          }
        />
      ),
    },
  ];
 
  useEffect(() => {
    dispatch({ type: 'storeModel/clearData' });
    // dispatch({
    //   type: 'brand/getSimpleBrandList',
    //   payload: {
    //     isAll: true,
    //     isSimpleFilter: true,
    //     isSelectorLoad: true,
    //     sort: 'name',
    //   }
    // });
    // dispatch(createAction('brand/getCurrentPageBrands')({isAll: true}));
    const queryString = require('query-string');
    if (queryString) {
      const querySearch = queryString.parse(location.search);
      const stringPage = querySearch['page'] || 1;
      const searchKey = querySearch['search'] || '';
      const sort = querySearch['sort'] || ''
      const page = parseInt(stringPage);
      const queryData = {
        page,
        sort,
        search: searchKey,
        reverse: true,
      };
      dispatch(createAction('storeModel/getPagedStoreList')(queryData));
    }
  }, [dispatch, location]);

  return (
    <div className="store-code-list-container">
      <CustomListComponent
        caution={{
          detail: ' ',
          title: NavBarNames.storeCodes,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={false}
      />
      <ExportStoreCodePop
        show={showExportPrompt}
        onClose={() => setShowExportPrompt(false)}
      />
    </div>
  );
}
