import i18n from 'i18n-js';
const translations = {
  en: {
    ...require('./translations/en.json'),
    campaign: require('./translations/campaign/en.json'),
    coupon: require('./translations/coupon/en.json'),
    transaction: require('./translations/transaction/en.json'),
    couponRecords: require('./translations/couponRecords/en.json'),
    concierge: require('./translations/concierge/en.json'),
  },
  'zh-Hant': {
    ...require('./translations/zh-Hant.json'),
    campaign: require('./translations/campaign/zh-Hant.json'),
    coupon: require('./translations/coupon/zh-Hant.json'),
    transaction: require('./translations/transaction/zh-Hant.json'),
    couponRecords: require('./translations/couponRecords/zh-Hant.json'),
    concierge: require('./translations/concierge/zh-Hant.json'),
  },
  'zh-Hans': {
    ...require('./translations/zh-Hans.json'),
    campaign: require('./translations/campaign/zh-Hans.json'),
    coupon: require('./translations/coupon/zh-Hans.json'),
    transaction: require('./translations/transaction/zh-Hans.json'),
    couponRecords: require('./translations/couponRecords/zh-Hans.json'),
    concierge: require('./translations/concierge/zh-Hans.json'),
  },
};
const languageTag = 'en';
i18n.locale = languageTag;
i18n.fallbacks = true;
i18n.translations = translations;

export default i18n;
