import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getThemeSections = (afterCursor, payload) => {
  let searchString = `first: ${payload?.pageSize || 20}`;
  if (payload?.sort) {
    searchString += `, orderBy: "${payload.sort}"`;
  } else {
    searchString += `, orderBy: "-pk"`;
  }

  const query = `
  {
    themeSections(${searchString}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          coverPhoto
          applyCoverPhotoToAllLanguage
          description
          campaignTags{
            edges {
              node {
                pk
                id
                name
              }
            }
          }
          translations(first: 10) {
            edges {
              node {
                id
                pk
                language
                coverPhoto
                description
              }
            }
          }
        }
      }
    }
  }
    `;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateThemeSection = (values) => {
  let query = `mutation UpdateThemeSection($input: UpdateThemeSectionInput!) {
    updateThemeSection(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: values,
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};
