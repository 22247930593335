import {
  getTransactions,
  getTransaction,
  approveTransaction,
  rejectTransaction,
  recallTransaction,
  deleteTransactions,
} from '../services/TransactionRecordsAPIHelper';
import {
  TRANSACTION_RECORD_TYPE,
  TRANSACTION_EVENT_TYPE,
} from '../containers/record/records/TransactionList';
import {
  getDisplayDate,
  getCouponExpiryDate,
  formatDate,
  TimeFormater,
} from '../utils/TimeFormatUtil';
import {
  createAction,
  convertCursorToNumber,
  formatNumberWithCommas,
} from '../utils';
import { loading } from './LoadingUtil';

const getInitialState = () => ({
  transactionList: [],
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk', linked: true },
    {
      displayName: 'Name (preferred name)',
      fieldName: 'name',
      linked: false,
      orderField: 'customerFirstName',
    },
    {
      displayName: 'Membership ID',
      fieldName: 'membershipId',
      orderField: 'membershipId',
    },
    {
      displayName: 'Creation Date',
      fieldName: 'displayCreationDate',
      orderField: 'creationDate',
    },
    {
      displayName: 'Transaction Date',
      fieldName: 'displayTransactionDate',
      orderField: 'date',
    },
    { displayName: 'Store', fieldName: 'storeName', orderField: 'store' },
    {
      displayName: 'Total Value',
      fieldName: 'totalValue',
      orderField: 'totalValue',
    },
    { displayName: 'Transaction Type', fieldName: 'transactionDisplayType' },
    { displayName: 'Online Event Type', fieldName: 'onlineEventType' },
    { displayName: 'Offline Event Type', fieldName: 'displayOfflineEventType' },
  ],
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  currentLastCursor: '',
  currentPage: 0,
  totalPage: 0,
  totalCount: 0,
  checkedList: [],
  currentPageTransactionList: [],
  selectedTransaction: {},
});

const parseTransactionDisplayType = (type) => {
  let displayType = '';
  switch (type) {
    case 'ONLINE':
      displayType = TRANSACTION_RECORD_TYPE.TYPE_ONLINE;
      break;
    case 'OFFLINE_POS':
      displayType = TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS;
      break;
    case 'OFFLINE_REWARD_CLAIM':
      displayType = TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM;
      break;
    default:
      break;
  }
  return displayType;
};

const parseEventType = (eventType) => {
  let displayEventType = '';
  switch (eventType) {
    case 'PENDING':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_PENDING;
      break;
    case 'AUTHORIZED':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_AUTHORIZED;
      break;
    case 'PAID':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_PAID;
      break;
    case 'PARTIALLY_PAID':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_PARTIALLY_PAID;
      break;
    case 'REFUNDED':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_REFUNDED;
      break;
    case 'PARTIALLY_REFUNDED':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_PARTIALLY_REFUNDED;
      break;
    case 'VOIDED':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_VOIDED;
      break;
    case 'WAITING_FOR_APPROVAL':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_WAITING;
      break;
    case 'APPROVED':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_APPROVED;
      break;
    case 'RECALLED':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_RECALLED;
      break;
    case 'REJECTED':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_REJECTED;
      break;
    default:
      break;
  }
  return displayEventType;
};

const parsePurchaseItems = (purchasedItems) => {
  const parsedPurchaseList = purchasedItems.map((item) => {
    const data = item.node;
    return {
      productName: data.name,
      sku: data.sku,
      category: data.category,
      brand: data.brand,
      quantity: data.quantity,
      value: data.value,
      displayValue: data.value
        ? `HK$ ${formatNumberWithCommas(data.value)}`
        : '',
      id: data.id,
      pk: data.pk,
    };
  });
  return parsedPurchaseList;
};

const parseTransaction = (data) => {
  const purchasedItems = data.purchasedItems?.edges || [];

  return {
    pk: data.pk,
    id: data.id,
    membershipId: data.customer.membershipId,
    ssoUid: data.customer.ssoUid,
    name: data.customer
      ? data.customer.nickname
        ? `${data.customer.firstName} ${data.customer.lastName} (${data.customer.nickname})`
        : `${data.customer.firstName} ${data.customer.lastName}`
      : null,
    transactionType: data.transactionType,
    transactionDisplayType: parseTransactionDisplayType(data.transactionType),
    storeName: data.store?.name,
    creationDate: data.creationDate,
    displayCreationDate: getDisplayDate(data.creationDate),
    transactionDate: data.date,
    displayTransactionDate: getDisplayDate(data.date),
    transactionDetailDisplayDate: formatDate(
      data.date,
      TimeFormater.dayMonthYearWeekTimeA,
    ),
    transactionDetailCreationDate: formatDate(
      data.creationDate,
      TimeFormater.dayMonthYearWeekTimeA,
    ),
    totalValue: data.totalValue,
    displayTotalValue: data.totalValue
      ? `HK$ ${formatNumberWithCommas(data.totalValue)}`
      : '',
    onlineEventType: parseEventType(data.onlineEventType),
    offlineEventType: data.offlineEventType,
    displayOfflineEventType: parseEventType(data.offlineEventType),
    staffName: data.staffName,
    shippingFee: data.shippingFee
      ? `HK$ ${formatNumberWithCommas(data.shippingFee)}`
      : '',
    otherCharge: data.otherCharge
      ? `HK$ ${formatNumberWithCommas(data.otherCharge)}`
      : '',
    remarks: data.remarks,
    posInvoiceId: data.posInvoiceId,
    invoiceId: data.invoiceId,
    receiptImage: data.receiptImage,
    creditCardSlipImage: data.creditCardSlipImage,
    purchasedItems: purchasedItems.length > 0
      ? parsePurchaseItems(purchasedItems)
      : [],
    deleteDisplayName: `${getCouponExpiryDate(data.creationDate)} ${
      data.customer?.firstName
    } ${data.customer?.lastName} $${data.totalValue}`,
    customer: {
      pk: data.customer?.pk,
      ssoUid: data.customer?.ssoUid,
      firstName: data.customer?.firstName,
      lastName: data.customer?.lastName,
      nickname: data.customer?.nickname,
      owner: `${data.customer?.firstName} ${data.customer?.lastName}`,
      membershipId: data.customer?.membershipId,
    },
    store: {
      storeID: data.store?.id,
      storePK: data.store?.pk,
      storeName: data.store?.name,
    },
    createdDate: formatDate(data.creationDate),
    eventType:
      parseEventType(data.onlineEventType) ||
      parseEventType(data.offlineEventType) ||
      '-',
    displayStoreName: data.store?.name,
    transactionValue: data.totalValue,
    brand: data.store?.brand?.name,
  };
};

export default {
  namespace: 'transactions',
  state: getInitialState(),

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },

    clearSelectedTransaction(state, { payload }) {
      return {
        ...state,
        selectedTransaction: {},
      };
    },
  },

  effects: {
    getCurrentPageTransactions: [
      function* ({ payload }, { call, put }) {
        const { page } = payload;
        let afterCursor = undefined;
        if (page > 1) {
          afterCursor = btoa(`arrayconnection:${(page - 1) * 20 - 1}`);
        }
        const serviceArgs = [getTransactions, afterCursor, payload];
        function* onSuccess(data) {
          const transactionData = data.transactions.edges;
          const pageInfo = data.transactions.pageInfo;
          const totalCount = data.transactions.totalCount;
          const currentLastCursor = pageInfo.endCursor;
          const transactionList = transactionData.map((item) => {
            return parseTransaction(item.node);
          });
          yield put(
            createAction('updateState')({
              currentPageTransactionList: transactionList,
              pageInfo: {
                startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1,
                endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1,
              },
              currentLastCursor,
              totalCount,
              totalPage: Math.ceil(totalCount / 20),
            }),
          );
        }
        yield loading(serviceArgs, onSuccess);
      },
      {
        type: 'takeLatest',
      },
    ],
    getTransaction: [
      function* ({ payload }, { call, put }) {
        const { transactionPK } = payload;
        const afterActions = payload.afterActions || (() => {});
        const transactionID = btoa(`TransactionNode:${transactionPK}`);
        const serviceArgs = [getTransaction, transactionID];
        function* onSuccess(data) {
          const transactionData = data.transaction;
          yield put(
            createAction('updateState')({
              selectedTransaction: parseTransaction(transactionData),
            }),
          );
          yield put(
            createAction('createTransaction/updateState')({
              transaction: parseTransaction(transactionData),
              hasUpdatedDefaultValues: true,
            }),
          );
          afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    approveTransaction: [
      function* ({ payload }, { put }) {
        const { transactionPK } = payload;
        const serviceArgs = [approveTransaction, transactionPK];
        function* onSuccess() {
          const afterActions = payload.afterActions || (() => {});
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      {
        type: 'takeLatest',
      },
    ],
    rejectTransaction: [
      function* ({ payload }, { put }) {
        const { transactionPK } = payload;
        const serviceArgs = [rejectTransaction, transactionPK];
        function* onSuccess() {
          const afterActions = payload.afterActions || (() => {});
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      {
        type: 'takeLatest',
      },
    ],
    recallTransaction: [
      function* ({ payload }, { put }) {
        const { transactionPK } = payload;
        const serviceArgs = [recallTransaction, transactionPK];
        function* onSuccess() {
          const afterActions = payload.afterActions || (() => {});
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    deleteTransaction: [
      function* ({ payload }, { put }) {
        const { ids } = payload;
        const serviceArgs = [deleteTransactions, ids];
        function* onSuccess() {
          const afterActions = payload.afterActions || (() => {});
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
