import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { PermissionCodes } from '../../../config/PermissionCodes';
import BaseForm from '../../../components/base/v2/BaseForm';
import CreateMappingContent from '../../../components/districtMallMapping/CreateMappingContent';


const CreateDistrictMallMapping = ({
  mapping,
  hasUpdatedDefaultValues,
  formHasSubmitted,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {

  const mappingId = params.id;
    dispatch({
      type: 'districtMallMappingList/getOneDistrictMallMapping',
      payload: { id: mappingId }
    });
   }, [dispatch, params.id]);

  useEffect(() => {
    if (formHasSubmitted && history.location.pathname.includes('edit')) {
      history.push('/district_mall_mappings');
    }
    
  }, [formHasSubmitted, history, mapping]);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'districtMallMappingList/clearData',
        payload: {}
      });
    };
  }, [dispatch]);

  const stepContent = [<CreateMappingContent />];

  return(
    <div className="create-banner">
      <BaseForm
        defaultValues={mapping}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        tempSave={(save, getValues) => {
          if (save) {
            dispatch({
              type: 'districtMallMappingList/updateDistrictMallMapping',
              payload: {save, values: getValues()},
            });
          }
        }}
        nextStepConfig={{
          title: 'Successfully Updated!',
          description: 'Mapping is successfully updated.',
          steps: null,
          buttons: [
            {
              text: 'Back to Mapping list',
              action: () => history.push('/district_mall_mappings'),
              requires: PermissionCodes.changeDistrictMallMapping,
            },
          ],
        }}
        showFinishPop={formHasSubmitted}
        content={stepContent}
        currentStep={0}
        breadcrumb={
          <CustomBreadcrumb name={'Edit Mapping'} />
        }
        caution={{
          detail: '',
          title: 'Edit Mapping',
        }}
      />
    </div>
  )
}

const mapPropsToState = (state) => ({
  mapping: state.districtMallMappingList.oneDistrictMallMapping,
  hasUpdatedDefaultValues: state.districtMallMappingList.hasUpdatedDefaultValues,
  formHasSubmitted: state.districtMallMappingList.formHasSubmitted,
});

export default connect(mapPropsToState)(CreateDistrictMallMapping);
