import {
  createAction,
  convertCursorToNumber,
  convertNumberToCursor,
} from '../utils';
import { loading } from './LoadingUtil';
import { formatDate, getDisplayDate } from '../utils/TimeFormatUtil';
import { createModel } from './BaseModel';
import { getBadgeRecord, getBadgeRecords } from '../services/BadgeRecordsAPIHelper';
import { BADGE_EVENT_TYPE, BADGE_RECORD_TYPE, LanguageConfig } from '../config/CustomEnums';
import i18n from '../I18n';

function getInitState() {
  return {
    listDisplayFields: {
      en: [
        { displayName: 'ID', fieldName: 'pk', linked: true },
        {
          displayName: 'First Name',
          fieldName: 'firstName',
          orderField: 'firstName',
        },
        {
          displayName: 'Last Name',
          fieldName: 'lastName',
          orderField: 'lastName',
        },
        {
          displayName: 'Membership ID',
          fieldName: 'membershipId',
          orderField: 'membershipId',
        },
        { displayName: 'Event Type', fieldName: 'displayEventType' },
        { displayName: 'Quantity', fieldName: 'badgeQuantity', orderField: 'badgeQuantity' },
        { displayName: 'Record Type', fieldName: 'displayRecordType' },
        { displayName: 'Remark', fieldName: 'remarkEN' },
        { displayName: 'Create at', fieldName: 'creationDate', orderField: "creationDate"},
      ],
      'zh-Hant': [
        { displayName: 'ID', fieldName: 'pk', linked: true },
        {
          displayName: '名字',
          fieldName: 'firstName',
          orderField: 'firstName',
        },
        {
          displayName: '姓氏',
          fieldName: 'lastName',
          orderField: 'lastName',
        },
        {
          displayName: '會員編號',
          fieldName: 'membershipId',
          orderField: 'membershipId',
        },
        { displayName: '事件類型', fieldName: 'displayEventTypeTC' },
        { displayName: '數量', fieldName: 'badgeQuantity', orderField: 'badgeQuantity' },
        { displayName: '紀錄類型', fieldName: 'displayRecordTypeTC' },
        { displayName: '備註', fieldName: 'remarkTC' },
        { displayName: '創建日期', fieldName: 'creationDate', orderField: "creationDate"},
      ]
    },
    maxQuantityValue: 0,
  };
}

function parseRecordType(recordType, language=LanguageConfig.english) {
  switch (recordType) {
    case 'CUSTOMER_EARN':
      return i18n.t('concierge.customer_earned', { locale: language });
    // case 'CUSTOMER_USE':
    //   return BADGE_RECORD_TYPE.CUSTOMER_USE;
    case 'ADMIN_REMOVE':
      return i18n.t('concierge.admin_removed', { locale: language });
    case 'BADGE_EXPIRED':
      return i18n.t('concierge.badge_expired', { locale: language });
    default:
      break;
  }
}

function parseEventType(eventType, language=LanguageConfig.english) {
  switch (eventType) {
    case 'ADD_BADGE':
      return i18n.t('concierge.add_badge', { locale: language });
    case 'REMOVE_BADGE':
      return i18n.t('concierge.remove_badge', { locale: language });
    default:
      break;
  }
}

function parseBadgeRecord(item) {
  const remarks = item?.remarks ? JSON.parse(item.remarks) : '';
  return {
    pk: item.pk,
    id: item.id,
    membershipId: item.customer?.membershipId,
    firstName: item.customer?.firstName,
    lastName: item.customer?.lastName,
    name: item.customer
    ? item.customer?.nickname
      ? `${item.customer?.firstName} ${item.customer?.lastName} (${item.customer?.nickname})`
      : `${item.customer?.firstName} ${item.customer?.lastName}`
    : null,
    recordType: item.recordType,
    displayRecordType: parseRecordType(item.recordType),
    displayRecordTypeTC: parseRecordType(item.recordType, LanguageConfig.traditionalChinese),
    eventType: item.eventType,
    displayEventType: parseEventType(item.eventType),
    displayEventTypeTC: parseEventType(item.eventType, LanguageConfig.traditionalChinese),
    badgeQuantity: item.badgeQuantity,
    remarkEN: remarks?.campaign_name_en,
    remarkTC: remarks?.campaign_name_tc,
    creationDate: getDisplayDate(item.creationDate),
    createDate: formatDate(item.creationDate, 'DD MMM yyyy (ddd), HH:mm a'),
    customer: item.customer,
  };
}

export default createModel({
  namespace: 'badgeRecord',
  states: getInitState(),
  params: {
    objectKey: 'badgeRecords',
    pkNode: 'BadgeRecordNode',
    detailAPI: getBadgeRecord,
    parseDetail: (data) => parseBadgeRecord(data?.badgeRecord),
    initState: getInitState(),
  },
  reducers: {},
  effects: {
    getCurrentPageBadgeRecords: [
      function* ({ payload }, { call, put }) {
        const { page } = payload;
        let afterCursor = undefined;
        if (page > 1) {
          afterCursor = convertNumberToCursor((page - 1) * 20 - 1);
        }
        const serviceArgs = [getBadgeRecords, afterCursor, payload];
        function* onSuccess(data) {
          const badgeRecordData = data.badgeRecords?.edges;
          const pageInfo = data.badgeRecords.pageInfo;
          const totalCount = data.badgeRecords.totalCount;
          const startCursor = convertCursorToNumber(pageInfo.startCursor);
          const endCursor = convertCursorToNumber(pageInfo.endCursor);
          const badgeRecordDataList = badgeRecordData.map((item) =>
            parseBadgeRecord(item.node),
          );
          yield put(
            createAction('updateState')({
              pagedList: badgeRecordDataList,
              pageInfo: {
                startCursor: isNaN(startCursor) ? 0 : startCursor + 1,
                endCursor: isNaN(endCursor) ? 0 : endCursor + 1,
              },
              totalCount,
              totalPage: Math.ceil(totalCount / 20),
              maxQuantityValue: data.badgeRecords.maxBadgeQuantity,
            }),
          );
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
});
