import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import i18n from '../../I18n';
import { parseNameByLanguage } from '../../models/CreateTransactionRecordModel';
import BasePrompt from '../base/prompt/BasePrompt';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import './UseRewardCoupon.scss';

function UseRewardCoupon({
  language,
  rewardCoupons,
  teamLeaderOnly,
  currentCustomer,
  afterAction,
}) {
  const [showPopup, setShowPopup] = useState(false);
  const [selectCoupon, setSelectCoupon] = useState();
  const dispatch = useDispatch();
  const coupons = parseNameByLanguage(rewardCoupons, language);

  const onCancel = () => {
    setShowPopup(false);
  };
  return (
    <>
      <div className="cut-line"></div>
      <CustomTitleLabel
        title={i18n.t('transaction.coupon_earned', { locale: language })}
      />
      {coupons.length > 0 ? (
        coupons.map((coupon) => (
          <>
            <div className="d-flex flex-row coupon-content">
              <label className="banners-detail-text">{`[${i18n.t(
                'transaction.coupon_id',
                { locale: language },
              )}${coupon.pk}] ${coupon.name}`}</label>
              {coupon.canConciergeUse ? (
                coupon.isUsed ? (
                  <label className="banners-detail-text coupon-used">
                    {i18n.t('transaction.used', { locale: language })}
                  </label>
                ) : coupon?.owner?.pk === currentCustomer?.pk ? (
                  <Button
                    onClick={() => {
                      setShowPopup(true);
                      setSelectCoupon(coupon);
                    }}
                    disabled={new Date() < new Date(coupon.effectiveDate)}
                    className="base-back-button base-save-and-back-component-button btn-back-button-common"
                  >
                    {i18n.t('transaction.use_now', { locale: language })}
                  </Button>
                ) : (
                  <label className="banners-detail-text coupon-used">
                    {i18n.t(
                      `${
                        teamLeaderOnly
                          ? 'transaction.coupon_for_team_leader_only'
                          : 'transaction.coupon_for_another_member'
                      }`,
                      {
                        locale: language,
                      },
                    )}
                  </label>
                )
              ) : (
                <label className="banners-detail-text coupon-used">
                  {i18n.t('transaction.not_applicable_in_this_concierge', {
                    locale: language,
                  })}
                </label>
              )}
            </div>
          </>
        ))
      ) : (
        <div className="d-flex flex-row coupon-content">
          <label className="banners-detail-text">-</label>
        </div>
      )}
      <BasePrompt
        show={showPopup}
        closeAction={onCancel}
        rightButton={{
          text: i18n.t('transaction.confirm', { locale: language }),
          action: () => {
            dispatch({
              type: 'createTransactionRecord/useRewardCoupon',
              payload: {
                selectCoupon,
                language,
                afterAction,
              },
            });
            setShowPopup(false);
          },
        }}
        leftButton={{
          text: i18n.t('transaction.cancel', { locale: language }),
          action: onCancel,
        }}
        title={i18n.t('transaction.use_coupon_title', { locale: language })}
        description={`${selectCoupon?.name} ${i18n.t(
          'transaction.use_coupon_description',
          { locale: language },
        )}`}
      />
    </>
  );
}

export default UseRewardCoupon;
