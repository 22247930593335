import React, { Component } from 'react';
import './CreateCouponStepTwo.scss';
import CouponDateTimeSelector from '../../../components/coupon/couponCreation/CouponDateTimeSelector';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createAction } from '../../../utils';
import { CouponErrorFields } from '../../../components/coupon/couponCreation/CouponHandleError';
import { APIStatus, COUPON_ATTRIBUTE, COUPON_REDEMPTION_METHOD, COUPON_SET_TYPES } from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';
import { useHistory } from 'react-router';
import { useFormContext } from 'react-hook-form';
import { validate } from './CreateCouponValidate';
import './CreateCouponStepThree.scss'
import CustomRadios from '../../../components/base/CustomRadios';

const CreateCouponStepThree = ({
  createCouponTemplateStatus
}) =>  {
  const {
    getValues, 
    setValue,
    setError, 
    clearErrors,
    watch,
    formState: { errors },
  } = useFormContext();
  const history = useHistory();
  const dispatch = useDispatch();
  const fromEdit = history.location.pathname.endsWith('edit') ||
    history.location.pathname.endsWith('edit/');
  
  const watchUseStorePinCode = watch('supportPinCode') || false;
  const watchCouponAttribute = watch('couponAttribute') || COUPON_ATTRIBUTE.GENERAL_COUPON;
  const watchCouponSetType = watch('couponSetType');
  const couponRedemptionMethod = getValues('redemptionMethod');


  const saveAction = (isGenerater, isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 2,
      isBack,
    });
    if (!isValid || isBack) {
      dispatch({
        type: 'createCoupon/stepChange',
        payload: { isBack: isBack, step: 2, isValid },
      });
    } else {
      if (fromEdit) {
        dispatch(
          createAction('createCoupon/updateCouponTemplateInput')({
            isGenerater: isGenerater,
            data: getValues(),
          }),
        );
      } else {
        dispatch(
          createAction('createCoupon/createCouponTemplate')({
            isGenerater: isGenerater,
            data: getValues(),
          }),
        );
      }
    }

  }

  
    return createCouponTemplateStatus === APIStatus.calling ? (
      <Loading />
    ) : (
      <div className="create-coupon-step-three-container has-bottom-margin">
        <div style={{ marginTop: 28 }}>
          <label className="step-one-title">Coupon attribute</label>
          <div style={{ display: 'flex', marginBottom: 25 }}>  
            <CustomRadios
              onChange={(value) => {
                setValue('couponAttribute', value, { shouldDirty: true });
              }}
              default={watchCouponAttribute}
              options={[
                {
                  label: "General coupon",
                  value: COUPON_ATTRIBUTE.GENERAL_COUPON,
                },
                {
                  label: "E-certificate coupon",
                  value: COUPON_ATTRIBUTE.E_CERTIFICATE_COUPON,
                }
              ]}
            />
          </div>
        </div>
        <CouponDateTimeSelector />

        {couponRedemptionMethod === COUPON_REDEMPTION_METHOD.INPUT_STORE_STAFF_CODE ? null :
        <div style={{ marginTop: 28 }}>
          <label className="step-one-title">Support store PIN code to use the coupon also</label>
          <div style={{ display: 'flex', marginTop: 8, marginBottom: 8 }}>  
            <CustomRadios
              onChange={(value) => {
                setValue('supportPinCode', value, { shouldDirty: true });
              }}
              default={watchUseStorePinCode}
              options={[
                {
                  label: "Yes",
                  value: true,
                },
                {
                  label: "No",
                  value: false
                }
              ]}
            />
          </div>
        </div>
        }
        
        <SaveAndBackButtons
          saveTempText="Save Only"
          saveText={`Save and ${
            watchCouponSetType === COUPON_SET_TYPES.SYSTEM_GENERATED
            ? 'Generate'
            : 'Import'
          } Coupon`}
          saveTempAction={() => {
            saveAction(false, false);
          }}
          saveAction={() => {
            saveAction(true, false);
          }}
          backAction={() => {
            saveAction(false, true)
          }}
        />
      </div>
    );
  
}

const mapPropsToState = (state) => ({
  createCouponTemplateStatus: state.createCoupon.createCouponTemplateStatus
})

export default connect(mapPropsToState)(CreateCouponStepThree);
