import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../utils';
import NextStepPrompt from '../../components/base/prompt/NextStepPrompt';
import { PermissionCodes } from '../../config/PermissionCodes';
import { ActionType } from '../../config/CustomEnums';

function CreateMessageSuccessPrompt(props) {
  const queryString = require('query-string');
  const history = useHistory();
  const params = useParams();
  const messagePK = params.id;
  const dispatch = useDispatch();
  const isTest = props.isTest || false;
  const actionType = props.actionType;
  // const isDraft = props.isDraft || false;
  const defaultShow = props.show || false;
  const onClose = props.onClose || (() => {});
  const [show, setShow] = useState(false);

  const { message } = useSelector((state) => ({
    message: state.createMessage.message,
  }));

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  if (!show && defaultShow) {
    setShow(true);
  }
  const getTitle = () => {
    if (isTest) {
      return 'Successfully sent!'
    }
    switch (actionType) {
      case ActionType.save:
        return 'Successfully Saved!'
      case ActionType.update:
      case ActionType.tempForPulished:
        return 'Successfully Updated!';
      case ActionType.submitForApproval:
        return 'Successfully Submitted!'
      case ActionType.approval:
        return 'Successfully Approved!'
      case ActionType.publish:
        return 'Successfully Scheduled!'
      case ActionType.withdraw:
        return 'Successfully Withdrawn!'
      default:
        return 'Successfully Unpublished!'
    }
  };

  const getDescription = () => {
    if (isTest) {
      return `Message is successfully send. Your testing device will receive soon!`
    }
    switch (actionType) {
      case ActionType.save:
        return 'Message is successfully saved!'
      case ActionType.update:
      case ActionType.tempForPulished:
        return 'Message is successfully updated!'
      case ActionType.submitForApproval:
        return `Notify the checkers to approve the content.`
      case ActionType.approval:
        return `Notify the publishers to publish the content.`
      case ActionType.publish:
        return `Your customer will receive soon!`
      case ActionType.withdraw:
        return `You can update the content.`
      default:
        return `You can update the content.`
    }
  };

  return (
    <NextStepPrompt
      show={show}
      title={getTitle()}
      description={getDescription()}
      steps={null}
      buttons={[
        {
          text: isTest ? 'Go to message preview' : 'Back to Message list',
          action: () => {
            if (isTest) {
              handleClose();
              dispatch(
                createAction('createMessage/updateState')({
                  currentStep: 3,
                }),
              );
            } else {
              history.push({
                pathname: '/messages',
                search: queryString.stringify({
                  page: 1,
                  rank: true,
                  search: '',
                }),
              });
            }
          },
          requires: PermissionCodes.changeMessage,
        },
        {
          text: isTest ? 'Edit message' : 'View details',
          action: () => {
            if (isTest) {
              setShow(false);
              dispatch(
                createAction('createMessage/updateState')({
                  currentStep: 1,
                }),
              );
            } else {
              history.push(`/messages/${messagePK ? messagePK : message.pk}/`);
            }
          },
          requires: PermissionCodes.changeMessage,
        },
      ]}
    />
  );
}

export default CreateMessageSuccessPrompt;
