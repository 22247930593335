import React, { useState, useRef, useEffect, createRef } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import './BonusRequirementSection.scss';
import './RewardSection.scss';
import {
  CustomTitleLabel,
  CustomNumberMutipleInputWithUnit,
  CustomTitleWithRadio,
} from './CustomBaseComponments';
import { useFormContext } from 'react-hook-form';
import InputFieldControl from '../base/InputFieldControl';
import { errorMessage, hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import SpecificCustomSwitchButton from '../../containers/merchants/stores/SpecificCustomSwitchButton';
import CustomRadios from '../base/CustomRadios';
import { BonusCriteriaRelationship, BonusRewardsRule } from '../../config/CustomEnums';
import BaseMultipleSelectorV2 from '../base/BaseMultipleSelectorV2';
import SelectMultipleStoreV2 from '../base/SelectMultipleStoreV2';
import BonusPeriodSection from './BonusPeriodSection';
import TargetCustomerGroupSegmentSection from './TargetCustomerGroupSegmentSection';

function BonusRequirementSection() {
  const { watch, formState, setValue } = useFormContext();
  const stampRewardTypeBonus = watch('stampRewardTypeBonus');
  const bonusRewardsRule = watch('bonusRewardsRule') || setValue('bonusRewardsRule', BonusRewardsRule.Multiplier, {shouldDirty: true});
  const multiplier = watch('multiplier');
  const additional = watch('additional');
  const bonusCriteriaRelationship = watch('bonusCriteriaRelationship') || setValue('bonusCriteriaRelationship', BonusCriteriaRelationship.or, {shouldDirty: true});
  const paymentMetheds = watch('paymentMetheds');
  const bonusPaymentMetheds = watch('bonusPaymentMetheds');
  const bonusPaymentMethedsLimit = watch('bonusPaymentMethedsLimit');
  const bonusSelectMallStores = watch('bonusSelectMallStores') || [];
  const bonusSelectFreshMarketStores = watch('bonusSelectFreshMarketStores') || [];
  const bonusStartDate = watch('bonusStartDate');
  const bonusEndDate = watch('bonusEndDate');
  const bonusPeriodLimit = watch('bonusPeriodLimit');
  const campaignSegments = watch('segments') || [];
  const customerGroup = watch('customerGroup') || [];
  const bonusTargetCustomerLimit = watch('bonusTargetCustomerLimit');

  const isMultiplier = bonusRewardsRule === BonusRewardsRule.Multiplier;

  const { errors } = formState;

  useEffect(() => {
    setValue('bonusPaymentMethedsLimit', bonusPaymentMetheds ? true : false, {shouldDirty: true});
    setValue('bonusPeriodLimit', (bonusStartDate && bonusEndDate) ? true : false, {shouldDirty: true});
    setValue('bonusTargetCustomerLimit', (campaignSegments?.length || customerGroup?.length) ? true : false, {shouldDirty: true});
  }, [])

  return (
    <>
      <label className="create-section-title">BONUS REQUIREMENT</label>
      <label className="tips-message" style={{ marginTop: '8px' }}>
        If turned on, the user can get extra rewards if all the rules of the
        selection are requirement. The bonus requirement is based on the
        selected requirement from the core above.
      </label>
      <SpecificCustomSwitchButton
        checked={stampRewardTypeBonus}
        onChange={(value) => {
          setValue('stampRewardTypeBonus', value, { shouldDirty: true });
        }}
      />
      {stampRewardTypeBonus ? (
        <>
          <CustomTitleLabel title="Bonus rewards" />
          <CustomTitleLabel className="subTiltle-margin-top" title="Rule" />
          <div style={{ display: 'flex' }}>
            <CustomRadios
              onChange={(value) => {
                setValue('bonusRewardsRule', value, { shouldDirty: true });
              }}
              default={bonusRewardsRule}
              options={[
                { label: 'Multiplier', value: BonusRewardsRule.Multiplier },
                { label: 'Additional', value: BonusRewardsRule.Additional },
              ]}
            />
          </div>
          <label className="tips-message" style={{ marginTop: '8px' }}>
            Eg. If the reward rule above is set to: HK$ 5 = 3 stamps
          </label>
          {isMultiplier ? (
            <InputFieldControl
              name={'multiplier'}
              value={multiplier}
              setValue={(value) => {
                setValue('multiplier', value, { shouldDirty: true });
              }}
              type="number"
              per="x"
              className="coupon-campaign-section-input"
              tips="If is x 2, then final reward rule is: HK$ 5 = 6 stamps"
            />
          ) : (
            <InputFieldControl
              name={'additional'}
              value={additional}
              setValue={(value) => {
                setValue('additional', value, { shouldDirty: true });
              }}
              type="number"
              per="+"
              unit="stamps"
              className="coupon-campaign-section-input"
              tips="If is + 2, then final reward rule is: HK$ 5 = 5 stamps"
            />
          )}

          <CustomTitleWithRadio
            title='Bonus criteria relationship'
            defaultValue={bonusCriteriaRelationship}
            setValue={(value) => {
              setValue('bonusCriteriaRelationship', value, { shouldDirty: true });
            }}
            options={[
              { label: 'OR', value: BonusCriteriaRelationship.or },
              { label: 'AND', value: BonusCriteriaRelationship.and },
            ]}
          />

          <div className="line" style={{marginTop: '24px'}} />

          <label className="create-section-title">By payment method</label>
          <div style={{ marginTop: '8px' }}>
            <SpecificCustomSwitchButton
              checked={bonusPaymentMethedsLimit}
              onChange={(value) => {
                setValue('bonusPaymentMethedsLimit', value, { shouldDirty: true });
              }}
            />
          </div>
          {bonusPaymentMethedsLimit ? (
            <BaseMultipleSelectorV2
              title={'Select payment method'}
              data={{
                sourceData: paymentMetheds,
                targetData: bonusPaymentMetheds,
                targetChange: (options) => {
                  setValue('bonusPaymentMetheds', options, { shouldDirty: true });
                },
              }}
              showPK={false}
              hideTopSpace={true}
            />
          ) : null}

          <SelectMultipleStoreV2
            title={'By applicable stores from malls'}
            value={bonusSelectMallStores}
            onChange={(value) => {
              setValue("bonusSelectMallStores", value, {shouldDirty: true})
            }}
            showLineBrank={true}
          />
          <ReactHookFormErrorMessage errors={errors} id={'bonusSelectMallStores'} />

          <SelectMultipleStoreV2
            title={'By applicable stores from fresh markets'}
            value={bonusSelectFreshMarketStores}
            onChange={(value) => {
              setValue("bonusSelectFreshMarketStores", value, {shouldDirty: true})
            }}
            showLineBrank={true}
            filterByMall={false}
          />
          <ReactHookFormErrorMessage errors={errors} id={'bonusSelectFreshMarketStores'} />

          <div className="line" style={{marginTop: '24px'}} />

          <BonusPeriodSection
            title="By period"
            startDate={bonusStartDate}
            startDateChange={(date) => {
              setValue('bonusStartDate', date, { shouldDirty: true})
            }}
            startErrorObject={{
              id: 'bonusStartDate',
              error: hasError(errors, 'bonusStartDate'),
              message: errorMessage(errors, 'bonusStartDate'),
            }}
            endDate={bonusEndDate}
            endDateChange={(date) => {
              setValue('bonusEndDate', date, { shouldDirty: true })
            }}
            endErrorObject={{
              id: 'bonusEndDate',
              error: hasError(errors, 'bonusEndDate'),
              message: errorMessage(errors, 'bonusEndDate'),
            }}
            showPeriod={bonusPeriodLimit}
            setShowPeriod={(value) => {
              setValue('bonusPeriodLimit', value, { shouldDirty: true })
            }}
          />

          <div className="line" />

          <label className="create-section-title">By target customer</label>
          <div style={{ marginTop: '8px' }}>
            <SpecificCustomSwitchButton
              checked={bonusTargetCustomerLimit}
              onChange={(value) => {
                setValue('bonusTargetCustomerLimit', value, { shouldDirty: true });
              }}
            />
          </div>
          {bonusTargetCustomerLimit ? (<TargetCustomerGroupSegmentSection />) : null}
        </>
      ) : null}
    </>
  );
}

const mapPropsToState = (state) => ({});

export default connect(mapPropsToState)(BonusRequirementSection);
