import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import BaseEditSection from '../../containers/base/BaseEditSection';
import BaseMultipleSelectorV2 from '../base/BaseMultipleSelectorV2';
import './TargetCustomerSection.scss';
import BaseMultipleSelectorDropDown from '../base/BaseMultipleSelectorDropDown';


function TargetCustomerSection({
  customerList,
}) {
  const dispatch = useDispatch();
  const { watch, getValues, setValue, setError, formState, clearErrors } = useFormContext();

  const customersField = 'customers';
  const selectedCustomerList = watch(customersField);

  const optionList = (list) => {
    if (!list) {
      return [];
    }
    return list.map((item) => {
      // const currentLevel = item.pointAccount.currentLevel;
      return {
        ...item,
        pk: item.pk,
        name: item.name,
        // tagKey: {
        //   pk: currentLevel.pk,
        //   id: currentLevel.id,
        //   name: currentLevel.levelName,
        //   levelName: currentLevel.levelName,
        // },
      };
    });
  };

  // useEffect(() => {
  //   dispatch({
  //     type: 'customerList/getAllCustomers',
  //     payload: {
  //       moreSearch: {
  //         sort: 'membershipId',
  //       }
  //     }
  //   });
  // }, [dispatch]);

  return (
    <div className="create-customer-group-target">
      <BaseMultipleSelectorDropDown
        title={'TARGET CUSTOMER'}
        filterTitle={'Customer'}
        placeholder='Search by membership ID'
        source={customerList}
        value={selectedCustomerList}
        onChange={(value) => {
          setValue(customersField, value, { shouldDirty: true, });
        }}
        loadMoreAction={'customerList/getPagedCustomers'}
        filterAction={'customerList/getPagedCustomers'}
        defaultFilter={{
          // sort: 'membershipId',
          isDropdownNode: true,
          isFilterMembershipId: true,
        }}
        customItem={(item) => item?.membershipId}
      />
    </div>
  );
}

const mapPropsToState = (state) => ({
  customerList: state.customerList.customerList,
});

export default connect(mapPropsToState)(TargetCustomerSection);
