import { getInFilterValue } from '../utils';
import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getMalls = (
  afterCursor,
  filter
) => {
  let {isAll, searchKey} = filter;
  let searchString = isAll ? 'first: 100' : 'first: 20';
  if (afterCursor) {
    searchString += `, after: "${afterCursor}"`;
  }
  if (searchKey) {
    searchString += `, name: "${searchKey}"`;
  }

  const query = `{
    mallOrFreshmarkets( ${searchString}) {
        totalCount
        pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
        }
        edges {
        cursor
        node {
            id
            pk
            name
            type
        }
      }
    }
  }`;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCampaignTags = (
  afterCursor,
  filter
) => {
  let {isAll, searchKey, type} = filter;
  let searchString = isAll ? 'first: 100' : 'first: 20';
  if (afterCursor) {
    searchString += `, after: "${afterCursor}"`;
  }
  if (searchKey) {
    searchString += `, nameIcontains: "${searchKey}"`;
  }
  if (type) {
    searchString += `, typeIn: "${type}"`
  }

  const query = `{
    campaignTags(${searchString}) { 
      edges {
        cursor
        node {
          id
          pk
          name
        }
      }
    }
  }`;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};