import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { ArrayStringData } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { CampaignDisplayIn, CampaignType } from '../../../config/CustomEnums';
import { DisplayInConfig } from '../../../components/campaign/campaignCreation/CreateCampaignStepOneConfigs';

function Filter({ 
  backAction = () => {},
  displayIn = null,
 }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const { brandList, customerGroupList, segmentList, levelList } = useSelector(
    (state) => ({
      brandList: state.brand.brandList,
      customerGroupList: state.customerGroup.groupList,
      segmentList: state.segments.segmentList,
      levelList: state.levels.levelList,
    }),
  );

  const parsedSearch = queryString.parse(location.search);
  const searchType = parsedSearch['type'] || '';
  const searchPublishStatus = parsedSearch['status'] || '';
  const searchExpiredStatus = parsedSearch['expired'] || '';
  const searchMessageChannel = parsedSearch['message_channel'] || '';
  const searchBrand = parsedSearch['brand'] || '';
  const searchActivePeriod = parsedSearch['active_period'] || '';
  const searchVisablePeriod = parsedSearch['visable_period'] || '';

  const searchTargetCustomer = parsedSearch['target_customer'] || '';
  const searchGroups = parsedSearch['groupsIn'] || '';
  const searchSegments = parsedSearch['segmentsIn'] || '';
  const searchLevels = parsedSearch['levelsIn'] || '';
  const searchDisplayIn = parsedSearch['display_in'] || '';
  const [type, setType] = useState(searchType);
  const [status, setStatus] = useState(
    ArrayStringData(searchPublishStatus)
  );
  const [expired, setExpired] = useState(searchExpiredStatus);
  const [messageChannel, setMessageChannel] = useState(
    ArrayStringData(searchMessageChannel),
  );
  const [brand, setBrand] = useState(ArrayStringData(searchBrand));
  const [activePeriod, setActivePeriod] = useState(searchActivePeriod);
  const [visablePeriod, setVisablePeriod] = useState(searchVisablePeriod);
  const [targetCustomer, setTargetCustomer] = useState(searchTargetCustomer);
  const [groups, setGroups] = useState(ArrayStringData(searchGroups));
  const [segments, setSegments] = useState(ArrayStringData(searchSegments));
  const [levels, setLevels] = useState(ArrayStringData(searchLevels));
  const [filterDisplayIn, setFilterDisplayIn] = useState(ArrayStringData(searchDisplayIn));

  const typeDataList = () => {
    if (displayIn === CampaignDisplayIn.estamp) {
      return [
        { pk: CampaignType.estampCampaign, name: 'Stamp' },
        // { pk: CampaignType.generalMessageCampaign, name: 'General Message' },
        // { pk: CampaignType.htmlCampaign, name: 'HTML' },
      ]
    } else if (displayIn === CampaignDisplayIn.emission) {
      return [
        { pk: CampaignType.emissionCampaign, name: 'Mission' },
        // { pk: CampaignType.generalMessageCampaign, name: 'General Message' },
        // { pk: CampaignType.htmlCampaign, name: 'HTML' },
        // { pk: CampaignType.earningRulesCampaign, name: 'Earning' },
      ]
    }
    return [
      { pk: CampaignType.couponsCampaign, name: 'Coupon' },
      { pk: CampaignType.earningRulesCampaign, name: 'Earning' },
      { pk: CampaignType.generalMessageCampaign, name: 'General Message' },
      { pk: CampaignType.htmlCampaign, name: 'HTML' },
      { pk: CampaignType.fortuneBag, name: 'Fortune Bag' },
    ]
  }

  const content = () => {
    let filterList = [
    {
      title: 'Type',
      data: typeDataList(),
      value: type,
      setValue: setType,
      componment: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Message channel',
      data: [
        { name: 'Inbox', pk: 'INBOX' },
        { name: 'Push', pk: 'PUSH_NOTIFICATION' },
        { name: 'Fullscreen ad', pk: 'FULLSCREEN_AD' },
      ],
      value: messageChannel,
      setValue: setMessageChannel,
      componment: FilterTypeEnum.choice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Brand',
      data: brandList,
      value: brand,
      setValue: setBrand,
      componment: FilterTypeEnum.choiceWithSearch,
      loadMoreAction: 'brand/getSimpleBrandList',
      filterAction: 'brand/getSimpleBrandList',
    },
    {
      title: 'Expired',
      data: [
        { name: 'Unexpired', pk: 'false' },
        // { name: 'Expired', pk: 'inactive' },
        { name: 'Expired', pk: 'true' },
      ],
      value: expired,
      setValue: setExpired,
      componment: FilterTypeEnum.singleChoice,
      className: 'fit-content-height-area',
    },
    {
      title: 'Status',
      data: [
        { name: 'Draft', pk: 'DRAFT'},
        { name: 'Pending For Approval', pk: 'PENDING_FOR_APPROVAL'},
        { name: 'Pending For Publish', pk: 'PENDING_FOR_PUBLISH'},
        { name: 'Published', pk: 'PUBLISHED' },
      ],
      value: status,
      setValue: setStatus,
      componment: FilterTypeEnum.choice,
      className: 'fit-content-height-area'
    },
    {
      title: 'Target customer',
      data: [
        {
          name: 'Customer group',
          pk: 'groupsIn',
          subComponment: {
            data: customerGroupList,
            value: groups,
            setValue: setGroups,
            componment: FilterTypeEnum.choice,
            className: 'filter-list-items-sub-area',
          },
        },
        {
          name: 'Segment',
          pk: 'segmentsIn',
          subComponment: {
            data: segmentList,
            value: segments,
            setValue: setSegments,
            componment: FilterTypeEnum.choice,
            className: 'filter-list-items-sub-area',
          },
        },
        // {
        //   name: 'Level',
        //   pk: 'levelsIn',
        //   subComponment: {
        //     data: levelList,
        //     value: levels,
        //     setValue: setLevels,
        //     componment: FilterTypeEnum.choice,
        //     className: 'filter-list-items-sub-area',
        //   },
        // },
      ],
      value: targetCustomer,
      setValue: setTargetCustomer,
      componment: FilterTypeEnum.singleChoiceTargetCustomer,
    },
    {
      title: 'Active period',
      value: activePeriod,
      setValue: setActivePeriod,
      componment: FilterTypeEnum.dateRange,
    },
    {
      title: 'Visible period',
      data: [
        { name: 'Inbox', pk: 'inbox' },
        { name: 'Push', pk: 'push' },
      ],
      value: visablePeriod,
      setValue: setVisablePeriod,
      componment: FilterTypeEnum.dateRange,
    },]
    if (displayIn !== CampaignDisplayIn.estamp && displayIn !== CampaignDisplayIn.emission) {
      filterList.push({
        title: 'Display In',
        data: DisplayInConfig.map((item) => {return { name: item.title, pk: item.id}}),
        value: filterDisplayIn,
        setValue: setFilterDisplayIn,
        componment: FilterTypeEnum.choice,
      })
    }
    return filterList
  } 

  // const confirmAction = () => {
  //   let search = {};
  //   if (targetCustomer) {
  //     search['target_customer'] = targetCustomer;
  //     if (selectedTarget) {
  //       search['selected_target'] = selectedTarget.join(',');
  //     }
  //   }

  //   backAction();

  //   return search;
  // };

  return <BaseFilter content={content()} backAction={backAction} />;
}

export default Filter;
