import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import CustomTitleWithInput from '../base/CustomTitleWithInput';
import { hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import '../../containers/customers/customer/CustomerOverviewDetail.scss';
import { CustomTitleWithDropDown } from './CustomTitleWithDropDown';
import CustomTitleWithSwitch from '../base/CustomTitleWithSwitch';
import i18n from '../../I18n';

function CarOwnerInformationCard({ title, language }) {
  const dispatch = useDispatch();
  const [carTypeLabel, setCarTypeLabel] = useState();

  const { watch, setValue, control, formState, clearErrors } = useFormContext();
  const { errors } = formState;

  const isMonthlyParkingUserField = 'carInfo.isMonthlyParkingUser';
  const octopusCardsField = 'octopusCards';
  const carTypeField = 'carInfo.carType';
  const licenseNumberField = 'carInfo.licenseNumber';
  const watchIsMonthlyParkingUser = watch(isMonthlyParkingUserField);
  const watchOctopusCards = watch(octopusCardsField);
  const watchCarType = watch(carTypeField);
  const watchLicenseNumber = watch(licenseNumberField);

  const CAR_TYPE = [
    { name: i18n.t('concierge.petrol_cars', { locale: language }), pk: "Petrol cars" },
    { name: i18n.t('concierge.electric_cars', { locale: language }), pk: "Electric cars" },
    { name: i18n.t('concierge.hybrid_cars', { locale: language }), pk: "Hybrid cars" },
  ]

  const octopusCardLength = watchOctopusCards.length >= 2 ? watchOctopusCards.length : 2;
  const octopusCards = [...Array(octopusCardLength)].map((octopusCard, index) => (
    <>
      <CustomTitleWithInput
        title={i18n.t('concierge.octopus_card', { index: index + 1, locale: language })}
        defaultValue={watchOctopusCards[index]}
        useDefaultValue={false}
        setValue={(value) => {
          watchOctopusCards[index] = value;
          setValue(octopusCardsField, watchOctopusCards, {
            shouldDirty: true,
          });
        }}
        error={{ error: hasError(errors, `octopusCard${index}`) }}
      />
      <ReactHookFormErrorMessage errors={errors} id={`octopusCard${index}`} />
    </>
  ));

  useEffect(() => {
    const defaultCarType = CAR_TYPE.filter((item) => item?.pk === watchCarType)[0];
    if (defaultCarType) {
      setCarTypeLabel(defaultCarType.name);
    }
  }, [setValue, watchCarType])

  return (
    <>
      <label className="create-section-title">{title}</label>

      <CustomTitleWithSwitch
        title={i18n.t('concierge.monthly_parking_user', { locale: language })}
        defaultValue={watchIsMonthlyParkingUser}
        setValue={(value) => {
          setValue(isMonthlyParkingUserField, value, { shouldDirty: true });
        }}
      />

      {octopusCards}

      <CustomTitleWithDropDown
        title={i18n.t('concierge.car_type', { locale: language })}
        needFilter={false}
        defaultValue={{
          value: watchCarType,
          label: carTypeLabel,
        }}
        source={CAR_TYPE}
        setValue={(item) => {
          setValue(carTypeField, item.value?.pk, { shouldDirty: true });
        }}
        loadMoreAction={() => {}}
      />
      <ReactHookFormErrorMessage errors={errors} id={carTypeField} />

      <CustomTitleWithInput
        title={i18n.t('concierge.license_number', { locale: language })}
        defaultValue={watchLicenseNumber}
        useDefaultValue={false}
        setValue={(value) => {
          setValue(licenseNumberField, value, { shouldDirty: true });
        }}
        error={{ error: hasError(errors, licenseNumberField) }}
      />
      <ReactHookFormErrorMessage errors={errors} id={licenseNumberField} />
    </>
  );
}

export default CarOwnerInformationCard;
