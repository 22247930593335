import React, { useState, useRef, useEffect } from 'react';
import '../../containers/customers/customer/CustomerOverviewDetail.scss';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import AlertInfoPrompt from '../base/prompt/AlertInfoPrompt';
import { addDomainToImage, isJsonFile } from '../../utils';
import { Link } from 'react-router-dom';
import CustomViewer from '../base/CustomViewer';
import Lottie from 'react-lottie';
import autoMergeLevel1 from 'redux-persist/es/stateReconciler/autoMergeLevel1';
import { LanguageConfig } from '../../config/CustomEnums';
import i18n from '../../I18n';

function MembershipInfoCard({ title, data, fields, extraDataFields, language=LanguageConfig.english }) {
  const tableRef = useRef();

  const [showPrompt, setShowPrompt] = useState(false);
  const [promptInfo, setPromptInfo] = useState({});
  const [tableWidth, setTableWidth] = useState(800);
  const tableOffset = tableRef.current?.offsetWidth;
  useEffect(() => {
    if (tableRef.current?.offsetWidth) {
      setTableWidth(tableRef.current?.offsetWidth);
    }
  }, [tableOffset]);

  const getDisplayValue = (field) => {
    let value = data[field];
    if (Array.isArray(value)) {
      value = value.slice(0, 10).join('\r\n');
    }

    if (field === 'mobileNumber') {
      value = value?.replace('\r\n', ' ');
    }

    if (extraDataFields && Object.keys(extraDataFields).includes(field)) {
      value = extraDataFields[field];
      if (Array.isArray(value)) {
        value = value.slice(0, 10).join('\r\n');
      }
    }

    return value || '-';
  };

  const getDisplayImage = (field) => {
    if (Array.isArray(data[field])) {
      return <>
      {
        data[field].map((item) => {
        return (
          <img
            alt="img"
            src={addDomainToImage(item)}
            style={{ maxWidth: 191, maxHeight: 191, marginRight: '20px', marginBottom: '20px' }}
          />
        )})
      }
      </>
    }
    else {
      const src = addDomainToImage(getDisplayValue(field))
      return isJsonFile(src) ? <Lottie 
      options={{
        loop: false, 
        path: src,
      }}
      style={{ marginLeft: 0, maxWidth: 191, width: 'auto', height: 'auto' }}
    /> : <img
        alt="img"
        src={src}
        style={{ maxWidth: 191, maxHeight: 191 }}
      />
    }
  }

  const getDisplayImageUrl = (field) => {
    if (Array.isArray(data[field])) {
      return <>
      {
        data[field].map((item) => {
        return (
          <div><label className="custom-record-normal-font custom-timeline-time-color list-new-line">
            {item || '-'}
          </label></div>
        )})
      }
      </>
    }
    return <></>
  }

  const needShowMore = (field) => {
    let value = data[field];
    if (extraDataFields && Object.keys(extraDataFields).includes(field)) {
      value = extraDataFields[field];
    }
    return Array.isArray(value) && value.length > 10;
  };

  const showMore = (field) => {
    let title, subTitle, content;
    if (field === 'coupons') {
      title = 'Coupon';
      subTitle = 'The following coupon belong to this customer';
    }

    if (field === 'availableCampaign') {
      title = 'Campaign';
      subTitle = 'The user can see the following activities';
    }

    if (field === 'displayPermissions') {
      title = 'Administrator permission';
      subTitle = 'The user has the following permissions';
    }

    let value = data[field];

    if (extraDataFields && Object.keys(extraDataFields).includes(field)) {
      value = extraDataFields[field];
    }

    content = (
      <ul>
        {value.map((item, index) =>
          item.id ? (
            <a
              className="channels-show-more-content"
              href={
                field === 'relatedCampaigns' ? `/campaigns/${item.id}/` : '#'
              }
            >
              <li key={index}>{item.name}</li>
            </a>
          ) : (
            <li key={index}>{item}</li>
          ),
        )}
      </ul>
    );

    setPromptInfo({ title, subTitle, content });
    setShowPrompt(true);
  };
  // console.log('@@56: ', tableRef.current);

  const getColumnValue = (column) => {
    if (column.field.includes('LinkUrl') && column.field.includes("Photo") && getDisplayValue(column.field) !== '-') {
      return getDisplayImageUrl(column.field)
    } else if (
      (column.field.includes('photo') || column.field.includes('Photo') || 
      column.field.includes('image') || column.field.includes('Image')) &&
      getDisplayValue(column.field) !== '-'
    ) {
      return getDisplayImage(column.field)
    } else if (
      (column.title === 'Description' ||
        column.title.toLowerCase().indexOf('short description') >= 0 ||
        column.title === 'Offer detail content' ||
        column.title === 'Instruction section content' ||
        column.title === 'Content' ||
        column.title.toLowerCase().indexOf('detail section content') >= 0) &&
      getDisplayValue(column.field) !== '-'
    ) {
      return <CustomViewer initValue={data[column.field]} />;
    } else {
      return getDisplayValue(column.field);
    }
  };

  return (
    <>
      <label className="create-section-title">{title}</label>

      <table 
        style={{tableLayout: "fixed", width: "100%"}}
        ref={tableRef}
      >
        {fields.map((row) => {
          return (
            <tr>
              {row.map((column) => {
                return (
                  <td
                    style={{
                      display: 'inline-grid',
                      width: `${tableWidth / row.length}px`,
                      wordBreak: `break-word`,
                    }}
                  >
                    <CustomTitleLabel title={column.title} />
                    {column.title === 'Brand' ? (
                      <label className="tips-message">
                        Once set, cannot be changed.
                      </label>
                    ) : null}
                    <label className="custom-record-normal-font custom-timeline-time-color list-new-line">
                      {getColumnValue(column)}
                    </label>
                    {needShowMore(column.field) ? (
                      <button
                        type="button"
                        className="btn-link reset-button show-more-button channel-show-more-button"
                        onClick={() => {
                          showMore(column.field, data[column.field]);
                        }}
                      >
                        {i18n.t('concierge.show_more', { locale: language })}
                      </button>
                    ) : null}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </table>
      <AlertInfoPrompt
        show={showPrompt}
        onCancel={() => setShowPrompt(false)}
        onClose={() => setShowPrompt(false)}
        onConfirm={() => setShowPrompt(false)}
        title={promptInfo.title}
        subTitle={promptInfo.subTitle}
        content={promptInfo.content}
      />
    </>
  );
}

export default MembershipInfoCard;
