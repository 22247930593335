import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { createAction, removeFromSessionStorage } from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import ProfileSection from '../../../components/campaign/campaignCreation/ProfileSection';
import ContentSection from '../../../components/campaign/campaignCreation/ContentSection';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import { useHistory, useParams } from 'react-router-dom';
import {
  APIStatus,
  LanguageConfig,
  CampaignType,
  SESSION_KEYS,
  CampaignDisplayIn,
} from '../../../config/CustomEnums';
import { PermissionCodes } from '../../../config/PermissionCodes';
import Loading from '../../../components/base/Loading';
import CustomListComponent from '../../../components/base/CustomListComponent';
import AuthButton from '../../../components/base/AuthButton';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { ToastType } from '../../../models/NavBarModel';

function CampaignDetail({
  createStatus,
  campaign,
}) {
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const history = useHistory();
  // const { createStatus, campaign } = useSelector((state) => ({
  //   createStatus: state.createCampaign.createStatus,
  //   campaign: state.createCampaign.campaign || {},
  // }));
  // const selectedChannels = campaign?.selectedChannels || []
  // const channels = [{ name: 'All channel' }, ...selectedChannels];
  useEffect(() => {
    console.log("@32")
    removeFromSessionStorage(SESSION_KEYS.CREATE_CAMPAIGN_PERVIEW_SESSION_KEY);
    dispatch(
      createAction('createCampaign/getOneCampaign')({ id, isDetail: true }),
    );
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    return () => dispatch({ type: 'createCampaign/clearData' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (createStatus === APIStatus.calling) {
    return <Loading customClass={'common-full-height '} />;
  }

  const sections = [
    <ProfileSection data={campaign} />
  ];

  const tabs = [
    {
      name: 'Detail',
      content: (
        <div className="scroll-container-common">
          <ContentSection data={campaign} />
          <ContentSections sections={sections} hidePreview={true} />
          <SaveAndBackButtons
            backAction={() => history.push('/campaigns')}
          />
        </div>
      ),
    },
  ];

  const copyLinkButton = 
    <AuthButton
      title="Copy deep link"
      action={() => {
        navigator.clipboard.writeText(`${process.env.REACT_APP_DEEP_LINK_DOMAIN}/?page=campaignDetail&id=${campaign.pk}`);
        dispatch({
          type: 'navBars/updateState',
          payload: {
            saveDiscardToastShowing: {
              value: true,
              type: ToastType.copySuccess
            },
          },
        });
      }}
      customClass={'btn-back-button-common btn-download'}
      requires={PermissionCodes.changeCampaign}
    />
  

  const editButton = 
    <AuthButton
      title="Edit"
      action={() => {
        dispatch({ type: 'admin/clearData' });
        history.push({
          pathname: 'edit/',
        });
      }}
      requires={PermissionCodes.changeCampaign}
    />
  
  const buttons = () => {
    if (campaign.displayIn === CampaignDisplayIn.stamp || campaign.displayIn === CampaignDisplayIn.mission) {
      return [editButton]
    }
    return [copyLinkButton, editButton]
  }

  return (
    <CustomListComponent
      caution={{
        detail: '',
        title: campaign.translations?.[LanguageConfig.english]?.generalName,
      }}
      hideTab
      breadcrumb={
        <CustomBreadcrumb
          name={campaign.translations?.[LanguageConfig.english]?.generalName}
        />
      }
      buttons={buttons()}
      tabs={tabs}
    />
  );
}

const mapPropsToState = (state) => ({
  createStatus: state.createCampaign.createStatus,
  campaign: state.createCampaign.campaign || {},
})

export default connect(mapPropsToState)(CampaignDetail)
