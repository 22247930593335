import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Transfer, Tree, Tooltip } from 'antd';
import './BaseMultipleSelectorV2.scss';
import nodata from '../../assets/images/no_data_transfer.svg';
import loading from '../../assets/images/selector_loading.gif';
import AuthButton from './AuthButton';
import ErrorFieldMessage from './ErrorFieldMessage';
import FollowCouponSetSwitch from '../campaign/campaignCreation/FollowCouponSetSwitch';
import SearchIcon from '../../assets/images/drop_down_filter.svg';
import SpecificCustomSwitchButton from '../../containers/merchants/stores/SpecificCustomSwitchButton';

export const SELECTOR_SIZE = {
  HIGHER: 'higher',
  WIDER: 'wider',
  SHORTER: 'shorter',
  NARROWER: 'narrower',
};

const getUnRepeatData = (data = []) => {
  // console.log('getUnRepeatData1:', data);
  const unRepeatData = [];
  data.forEach((item) => {
    if (!item?.pk) {
      return;
    }
    const itemAlreadyExist = unRepeatData.some(
      (unRepeatItem) => unRepeatItem?.pk === item.pk,
    );
    if (!itemAlreadyExist) {
      unRepeatData.push({ ...item, key: item.pk });
    }
  });
  // console.log('unRepeatData1:', unRepeatData);
  return unRepeatData;
};

const generateTree = (treeNodes = []) =>
  treeNodes.map(({ children, ...props }) => ({
    ...props,
    children: generateTree(children),
  }));

const groupTree = (data = [], key, groupKeyChoice, showPK) => {
  const treeData = [];
  data.forEach((item) => {
    const parentPK = item[key]?.pk || groupKeyChoice?.[item[key]]?.pk;
    if (!parentPK) {
      return;
    }
    const exsitGroup = treeData.find((group) => group.pk == parentPK);
    if (exsitGroup) {
      exsitGroup.children.push({ title: showPK ? `[ID:${item.pk}] ${item.name}` : item.name, ...item, key: item.pk });
      return;
    }
    treeData.push({
      ...item[key],
      ...groupKeyChoice?.[item[key]],
      key: `group-${parentPK}`,
      title: item[key].name || groupKeyChoice?.[item[key]]?.name,
      children: [{ title: showPK ? `[ID:${item.pk}] ${item.name}` : item.name, ...item, key: item.pk }],
    });
  });
  return treeData;
};

const removeUnExsitTarget = (source = [], target = []) => {
  const cleanedTarget = target.filter((item) =>
    source.some((sourceItem) => sourceItem.pk === item.pk),
  );
  return cleanedTarget;
};

export default function BaseMultipleSelectorV2({
  title,
  requires,
  disabled,
  namespace = '',
  groupKey,
  groupKeyChoice,
  tips,
  hideTopSpace,
  searchPlaceholder = 'Search by ID, name',
  onFocusFunc,
  custom = {
    customItem: null,
    customTitle: null,
    customContainerStyle: null,
    customFilter: null,
  },
  size = [],
  data = {
    sourceData: [],
    targetData: [],
    targetChange: () => {},
  },
  switchInfo = {
    showSwitch: false,
    checked: false,
    checkChange: () => {},
  },
  addButton = {
    title: '',
    action: () => {},
    link: '',
    customClass: '',
  },
  error = {
    id: '',
    message: '',
    error: false,
  },
  showPK=true,
  isToggle=false,
}) {
  const { allListLoading, selectedAllListLoading } = useSelector((state) => ({
    allListLoading: state[namespace]?.allListLoading,
    selectedAllListLoading: state[namespace]?.selectedAllListLoading,
  }));
  // console.log('errorSelec:', error);
  const history = useHistory();
  const isLoading = allListLoading || selectedAllListLoading;
  const sourceData = data.sourceData;
  const targetData = data.targetData;
  const sourceUnRepeat = getUnRepeatData(sourceData);
  const targetUnRepeat = getUnRepeatData(targetData);
  const cleanedTarget = removeUnExsitTarget(sourceUnRepeat, targetUnRepeat);
  const groupSourceData = groupKey ? groupTree(sourceData, groupKey, groupKeyChoice, showPK) : [];
  const targetKeys = cleanedTarget.map((item) => item.key);
  const [sourceSelectedKeys, setSourceSelectedKeys] = useState([]);
  const [targetSelectedKeys, setTargetSelectedKeys] = useState([]);
  const [searchInfo, setSearchInfo] = useState({ left: '', right: '' });
  const [searchedSelectedKeys, setSearchedSelectedKeys] = useState([]);
  const [open, setOpen] = useState(true);
  const targetChange = (changeKeys) => {
    const keys = changeKeys;
    keys.sort((a, b) => a - b);
    const newTargetData = keys.map((key) =>
      sourceUnRepeat.find((item) => item.pk == key),
    );
    data.targetChange(newTargetData);
  };

  const filterOption = (inputValue, option) => {
    if (custom?.customFilter) {
      return custom.customFilter(inputValue, option);
    }
    const optionName = option?.name?.toLowerCase() || '';
    const optionKey = option?.pk?.toString() || '';
    return optionName.indexOf(inputValue?.toLowerCase()) > -1 || optionKey.indexOf(inputValue?.toLowerCase()) > -1;
  };

  const getSearchedSelectedKeys = () => {
    const searchedKeys = [];
    const allSelectedKeys = [...targetSelectedKeys, ...sourceSelectedKeys];
    sourceUnRepeat.forEach((source) => {
      const isInTarget = targetKeys.includes(source.pk);
      const searchKey = isInTarget ? searchInfo.right : searchInfo.left;
      const isSearchResultItem =
        source.name.toLowerCase().indexOf(searchKey.toLowerCase()) > -1 ||
        source?.pk.toString().indexOf(searchKey) > -1;
      if (!isSearchResultItem) {
        return;
      }
      const isSelected = allSelectedKeys.indexOf(source.pk) > -1;
      if (isSelected) {
        searchedKeys.push(source.pk);
      }
    });
    return searchedKeys;
  };

  const getTreeFitlerData = (direction) => {
    const treeData = [];
    const searchValue = searchInfo[direction];
    groupSourceData.forEach((sourceTree) => {
      if (!sourceTree?.children?.length) {
        return;
      }
      const searchedResult = sourceTree.children.filter((child) => {
        return (
          filterOption(searchValue, child) &&
          (direction === 'left'
            ? !targetKeys.includes(child.key)
            : targetKeys.includes(child.key))
        );
      });
      if (!!searchedResult?.length) {
        treeData.push({
          key: sourceTree.key,
          title: showPK ? `[ID:${sourceTree.pk}] ${sourceTree.title}` : sourceTree.title,
          pk: sourceTree.pk,
          name: sourceTree.name,
          children: searchedResult,
        });
      }
    });
    return treeData;
  };

  const treeCheckChange = (key, direction, treeData) => {
    onFocusFunc && onFocusFunc();
    const allKeysList =
      direction === 'left' ? sourceSelectedKeys : targetSelectedKeys;
    const groupCheckSeperatorIndex = `${key}`.indexOf('-');
    if (groupCheckSeperatorIndex > -1) {
      const groupCheckedKey = key.substring(groupCheckSeperatorIndex + 1);
      const filteredTreeObject = treeData.find(
        (item) => item.pk == groupCheckedKey,
      );
      if (!filteredTreeObject || !filteredTreeObject.children?.length) {
        return;
      }
      const sourceGroupTreeObject = groupSourceData.find(
        (item) => item.pk == groupCheckedKey,
      );
      // console.log('sourceGroupTreeObject:', sourceGroupTreeObject);
      if (!sourceGroupTreeObject || !sourceGroupTreeObject.children?.length) {
        return;
      }
      const sourceChildrenKeys = sourceGroupTreeObject.children.map(
        (item) => item.key,
      );
      const treeChildrenKeys = filteredTreeObject.children.map(
        (item) => item.key,
      );
      const notAllSelected = filteredTreeObject.children.some(
        (item) => !searchedSelectedKeys.includes(item.key),
      );
      const removedOldKeyList = allKeysList.filter(
        (item) => !sourceChildrenKeys.includes(item),
      );
      // console.log('removedOldKeyList:', removedOldKeyList, sourceChildrenKeys);
      if (notAllSelected) {
        removedOldKeyList.push(...treeChildrenKeys);
      }
      direction === 'left'
        ? setSourceSelectedKeys(removedOldKeyList)
        : setTargetSelectedKeys(removedOldKeyList);
      return;
    }
    const isSelected = allKeysList.includes(key);
    const removedOldKeyList = allKeysList.filter((item) => item !== key);
    if (!isSelected) {
      removedOldKeyList.push(key);
    }
    direction === 'left'
      ? setSourceSelectedKeys(removedOldKeyList)
      : setTargetSelectedKeys(removedOldKeyList);
  };

  useEffect(() => {
    // const newSearchKeys = getSearchedSelectedKeys();
    const newSearchKeys = [...targetSelectedKeys, ...sourceSelectedKeys];
    setSearchedSelectedKeys(newSearchKeys);
  }, [
    targetKeys.length,
    searchInfo.left,
    searchInfo.right,
    sourceSelectedKeys.length,
    targetSelectedKeys.length,
  ]);

  return (
    <>
      {!hideTopSpace ? <div style={{ marginTop: '35px' }} /> : null}
      <div
        className={`v2-selector-multiple-container ${
          size.includes(SELECTOR_SIZE.SHORTER) ? 'is-shorter' : ''
        } ${size.includes(SELECTOR_SIZE.HIGHER) ? 'is-higher' : ''} ${
          size.includes(SELECTOR_SIZE.WIDER) ? 'is-wider' : ''
        } ${size.includes(SELECTOR_SIZE.NARROWER) ? 'is-narrower' : ''}`}
        style={custom.customContainerStyle || {}}
      >
        {custom.customTitle ? (
          custom.customTitle
        ) : (
          <div className={'v2-selector-multiple-container-title'}>
            <label>{title}</label>
            {isToggle ? (
              <div style={{marginLeft: '10px'}}>
                <SpecificCustomSwitchButton
                  show={true}
                  checked={open}
                  onChange={() => {
                    setOpen(!open);
                  }}
                />
              </div>
            ) : null}
            {switchInfo?.showSwitch ? (
              <div style={{ marginLeft: 'auto', marginRight: '40px' }}>
                <FollowCouponSetSwitch
                  checked={switchInfo?.checked}
                  onChange={switchInfo?.checkChange || (() => {})}
                />
              </div>
            ) : null}
          </div>
        )}
        {tips ? (
          <label
            className="second-section_description"
            style={{ marginTop: '0px' }}
          >
            {tips}
          </label>
        ) : null}
        {open ? (
          <div>
            <div
              style={{
                position: 'relative',
                paddingTop: '25px',
                marginTop: '10px',
              }}
            >
              <div className="multiple-selector-v2-search-left">
                <img alt="" src={SearchIcon} />
              </div>
              <div className="multiple-selector-v2-search-right">
                <img alt="" src={SearchIcon} />
              </div>
              <Transfer
                dataSource={sourceUnRepeat}
                className={`v2-selector-multiple-transfer ${
                  error?.error ? 'v2-selector-multiple-transfer-error' : null
                }`}
                disabled={disabled}
                filterOption={filterOption}
                showSearch
                targetKeys={targetKeys}
                onSelectChange={(sourceSelectedKeys, targetSelectedKeys) => {
                  onFocusFunc && onFocusFunc();
                  setSourceSelectedKeys(sourceSelectedKeys);
                  setTargetSelectedKeys(targetSelectedKeys);
                }}
                onSearch={(direction, value) => {
                  setSearchInfo({ ...searchInfo, [direction]: value });
                }}
                selectedKeys={searchedSelectedKeys}
                onChange={targetChange}
                locale={{
                  searchPlaceholder: searchPlaceholder,
                  selectAll: 'Select all',
                  selectInvert: 'Invert selection',
                  notFoundContent: (
                    <img
                      alt=""
                      style={isLoading ? { width: '80px', height: '80px' } : {}}
                      src={isLoading ? loading : nodata}
                    />
                  ),
                }}
                render={(item) =>
                  custom.customItem ? custom.customItem(item) : showPK ? `[ID:${item.pk}] ${item.name}` : item.name
                }
                titles={['Available item(s)', 'Selected item(s)']}
              >
                {groupKey
                  ? ({ direction }) => {
                      const treeData = getTreeFitlerData(direction);
                      const groupTreeKeys = [];
                      treeData.forEach((item) => {
                        if (!item.children) {
                          return;
                        }
                        const keys = item.children.map((child) => child.key) || [];
                        groupTreeKeys.push(...keys);
                      });
                      const checkedKeys =
                        groupTreeKeys.filter((groupTreeKey) =>
                          [...sourceSelectedKeys, ...targetSelectedKeys].includes(
                            groupTreeKey,
                          ),
                        ) || [];
                      // console.log('treeData:', treeData);
                      if (!treeData?.length) {
                        return null;
                      }
                      return (
                        <Tree
                          checkable
                          disabled={disabled}
                          defaultExpandAll={true}
                          className={disabled ? 'tree-disabled' : ''}
                          checkedKeys={checkedKeys}
                          treeData={generateTree(treeData)}
                          onCheck={(_, { node: { key } }) =>
                            treeCheckChange(key, direction, treeData)
                          }
                          onSelect={(_, { node: { key } }) =>
                            treeCheckChange(key, direction, treeData)
                          }
                        />
                      );
                    }
                  : null}
              </Transfer>
            </div>
            {addButton?.title ? (
              <div
                style={{ marginTop: '10px' }}
                className={`custom-add-remove-mutiple-selector-add-btn-container `}
              >
                <AuthButton
                  title={addButton.title}
                  requires={requires}
                  disabled={disabled}
                  customClass={'general-section-add-new btn-add-button-common'}
                  action={() => {
                    if (addButton.link) {
                      history.push(addButton.link);
                    }
                    if (addButton.action) {
                      addButton.action();
                    }
                  }}
                />
              </div>
            ) : null}
          </div>
        ) : null}

        <ErrorFieldMessage
          id={error.id}
          error={error.error}
          message={error.message}
        />
      </div>
    </>
  );
}
