import React, { useState } from 'react';
import CustomSwitchButton from '../../base/CustomSwitchButton';
import { Collapse, Image } from 'react-bootstrap';
import ErrorFieldMessage from '../../base/ErrorFieldMessage';
import { DatePicker, Checkbox } from 'antd';
import { CustomTitleLabel } from '../../earning/CustomBaseComponments';
import AuthButton from '../../base/AuthButton';
import deleteImage from '../../../assets/images/close.svg';
import moment from 'moment';
import { useFormContext } from 'react-hook-form';
import './PeriodAndWeekdaySection.scss';
import { getCampignPeriodDate } from '../../../utils/TimeFormatUtil';
import CustomRangePicker from '../../base/CustomRangePicker';

const { RangePicker } = DatePicker;

function PeriodAndWeekdaySection(props) {
  const { watch, setValue } = useFormContext();

  const title = props.title;
  const titleDescription = props.titleDescription;
  const switchButtonTitle = props.switchButtonTitle;
  const periodValuesField = props.periodValuesField;
  const periodValues = props.periodValues || [];
  const periodValuesChange = props.periodValuesChange || (() => {});
  const weekday = props.weekday || [];
  const weekdayChange = props.weekdayChange || (() => {});
  const disabled = props.disabled;
  const switchButtonError = props.switchButtonError || {};
  const dateFormat = props.dateFormat || 'DD MMM YYYY (ddd)';
  const maxDate = props.maxDate;
  const minDate = props.minDate;
  const disabledDate = props.disabledDate;

  const [show, setShow] = useState((periodValues.length || weekday.length) ? true : false);

  const getRangePicker = () => {
    return periodValues.map((item, index) => {
      const watchStartDate = watch(`${periodValuesField}.${index}.startDate`);
      const watchEndDate = watch(`${periodValuesField}.${index}.endDate`);
      const onChange = (dates, dateStrings) => {
        let [start, end] = dates || [];
        if (dates && dates.length) {
          start = new Date(start);
          end = new Date(end);
          start.setHours(0, 0, 0);
          end.setHours(0, 0, 0);
        }
        setValue(`${periodValuesField}.${index}.startDate`, start, { shouldDirty: true });
        setValue(`${periodValuesField}.${index}.endDate`, end, { shouldDirty: true });
      };
      return (
        <div className="d-flex flex-row" style={{ marginBottom: '8px' }}>
          <CustomRangePicker
            defaultValue={[
              watchStartDate
                  ? moment(getCampignPeriodDate(watchStartDate), dateFormat)
                  : null,
                watchEndDate
                  ? moment(getCampignPeriodDate(watchEndDate), dateFormat)
                  : null,
            ]}
            onChange={onChange}
            dateFormat={dateFormat}
            disabledDate={disabledDate}
            minDate={minDate}
            maxDate={maxDate}
          />
          <AuthButton
            children={<Image src={deleteImage} className="custom-delete-btn" />}
            customClass="custom-delete-btn"
            action={() => {
              const tempArray = [].concat(periodValues);
              tempArray.splice(index, 1);
              periodValuesChange(tempArray);
            }}
          />
        </div>
      );
    });
  };

  return (
    <div className='d-flex flex-column'>
      {title ? <label className="create-section-title">{title}</label> : null}
      {titleDescription ? (
        <label className="section-short-description">{titleDescription}</label>
      ) : null}
      {switchButtonTitle ? (
        <label className="create-section-label create-section-label-bottom-space">
          {switchButtonTitle}
        </label>
      ) : null}
      <CustomSwitchButton
        disabled={disabled}
        defaultChecked={show}
        onChange={() => {
          setShow(!show);
        }}
      />
      <ErrorFieldMessage
        id={switchButtonError.id}
        error={switchButtonError.message}
        message={switchButtonError.message}
      />
      <Collapse in={show}>
        <div>
          <div className="d-flex flex-column">
            <CustomTitleLabel title="By period" />
            {getRangePicker()}
            <AuthButton
              title="Add period"
              customClass={'btn-back-button-common'}
              action={() => {
                const tempArray = [].concat(periodValues);
                tempArray.push({
                  startDate: '',
                  endDate: '',
                });
                periodValuesChange(tempArray);
              }}
            />

            <CustomTitleLabel title="By weekday" />
            <Checkbox.Group
              defaultValue={weekday}
              options={[
                {label: 'Monday', value: 'MONDAY'},
                {label: 'Tuesday', value: 'TUESDAY'},
                {label: 'Wednesday', value: 'WEDNESDAY'},
                {label: 'Thursday', value: 'THURSDAY'},
                {label: 'Friday', value: 'FRIDAY'},
                {label: 'Saturday', value: 'SATURDAY'},
                {label: 'Sunday', value: 'SUNDAY'},
              ]}
              onChange={weekdayChange}
            />
          </div>
        </div>
      </Collapse>
    </div>
  );
}

export default PeriodAndWeekdaySection;
