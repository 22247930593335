import { getInFilterValue, getSearchKey } from '../utils';
import { BaseHelper } from './BaseGraphQLAPIHelper';

const STAMP_RECORDS_NODE = `{
  id
  pk
  owner {
    id
    pk
    membershipId
    firstName
    lastName
    nickname
  }
  eventType
  recordType
  value
  remarks
  recordAt
}`;

export const getStampRecords = (afterCursor, payload = {}) => {
  let searchString = payload?.isAll ? '' : `first: ${payload?.pageSize || 20}`;
  if (afterCursor) {
    searchString += `, after: "${afterCursor}"`;
  }
  if (payload?.sort) {
    searchString += `, orderBy: "${payload.sort}"`;
  } else {
    searchString += `, orderBy: "-pk"`;
  }
  if (payload?.searchKey) {
    searchString += `, membershipPhone: "${getSearchKey(payload.searchKey)}"`;
  }
  if (payload?.event_type) {
    searchString += `, eventTypeIn: ${getInFilterValue(payload.event_type)}`;
  }
  if (payload?.record_type) {
    searchString += `, recordTypeIn: ${getInFilterValue(payload.record_type)}`;
  }
  if (payload?.create_date) {
    const createDate = payload.create_date.split(',');
    searchString += `, recordDateGte: "${createDate[0]}", recordDateLte: "${createDate[1]}"`;
  }
  if (payload?.quantity) {
    const quantity = payload.quantity.split(',');
    searchString += `, valueGte: ${quantity[0]}, valueLte: ${quantity[1]}`;
  }
  const query = `{
    stampRecords(${searchString}) {
        totalCount
        maxStampValue
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node ${STAMP_RECORDS_NODE}
        }
      }
    }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getStampRecord = (id) => {
  const query = `{
    stampRecord(id: "${id}") ${STAMP_RECORDS_NODE}
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};
