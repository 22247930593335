import { getSearchKey } from '../utils';
import { BaseHelper } from './BaseGraphQLAPIHelper';

const STORE_NODE = `{
  id
  pk
  name
  displayPriority
  translations {
    edges {
      node {
        id
        pk
        language
        name
      }
    }
  }
  parentCategories(orderBy: "name") {
    edges {
      node {
        pk
        name
        displayPriority
        translations {
          edges {
            node {
              id
              pk
              language
              name
            }
          }
        }
      }
    }
  }
}`;

export const getStoreCategoryListLv4 = (afterCursor, payload) => {
  const searchKey = payload?.searchKey || null;
  const moreSearch = payload?.moreSearch || null;
  let filter = '';
  if (searchKey) {
    filter = `, nameIcontains:"${getSearchKey(searchKey)}"`;
  }
  let orderBy = 'name';
  if (moreSearch?.sort) {
    orderBy = moreSearch.sort;
  }
  let pageSize = `first: ${payload?.pageSize || 20},`;
  if (payload?.isAll) {
    pageSize = '';
  }
  const query = `{
    storeLevel4Categories(${pageSize}, after:"${afterCursor}", orderBy: "${orderBy}"${filter}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node ${STORE_NODE}
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getStoreCategoryLv4 = (id) => {
  const query = `{
    storeLevel4Category(id: "${id}") ${STORE_NODE}
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const createStoreCategoryLv4 = (values) => {
  const query = `mutation CreateStoreLevel4Category($input: CreateStoreLevel4CategoryInput!) {
    createStoreLevel4Category(input: $input) {
      node ${STORE_NODE}
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: {
      ...values,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
export const updateStoreCategoryLv4 = (values) => {
  const query = `mutation UpdateStoreLevel4Category($input: UpdateStoreLevel4CategoryInput!) {
    updateStoreLevel4Category(input: $input) {
      node ${STORE_NODE}
    }
  }`;
  const variables = {
    input: {
      ...values,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteStoreCategoriesLv4 = (ids) => {
  const query = `mutation DeleteStoreLevel4Categories($input: DeleteStoreLevel4CategoriesInput!) {
    deleteStoreLevel4Categories(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids,
    },
  };
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};
