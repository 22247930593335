import { LanguageConfig } from '../config/CustomEnums';
import { getFreshMarket, getFreshMarkets } from '../services/FreshMarketAPIHelper';
import { createModel } from './BaseModel';
import { getNameByLanguage } from './CarParkModel';
import { getDetailPhotos } from './MallModel';

const getInitialState = () => ({
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    {
      displayName: 'Name',
      fieldName: 'name',
      orderField: 'name',
    },
    { displayName: 'Cover photo', fieldName: 'coverPhoto' },
  ],
});

const parseFreshMarket = (freshMarket) => {
  const translationsEdges = freshMarket.translations?.edges || [];
  const translations = {};
  translations[LanguageConfig.english] = {
    name: freshMarket.name,
    description: freshMarket.description,
    address: freshMarket.address,
    district: freshMarket.district?.name,
    mall: freshMarket.mall?.name ? `[ID:${freshMarket.mall?.pk}] ${freshMarket.mall?.name}` : '-',
    openingHours: freshMarket.openingHours
  };
  if (translationsEdges.length) {
    translationsEdges.forEach((item) => {
      let language = item.node.language;
      const districtName = getNameByLanguage(freshMarket.district, language);
      const mallName = getNameByLanguage(freshMarket.mall, language);
      translations[language] = {
        id: item.node.pk,
        language: item.node.language,
        name: item.node.name,
        description: item.node.description,
        address: item.node.address,
        district: districtName || '-',
        mall: mallName ? `[ID:${freshMarket.mall?.pk}] ${mallName}` : '-',
        openingHours: item.node.openingHours
      };
    });
  }
  return {
    ...freshMarket,
    coverPhoto: freshMarket.photo,
    detailPhotos: getDetailPhotos(freshMarket),
    displayPhoneNumner: freshMarket.phoneNumber,
    translations,
  };
}

export default createModel({
  namespace: 'freshMarket',
  states: getInitialState(),
  params: {
    listAPI: getFreshMarkets,
    parse: (data) => data?.freshMarkets?.edges?.map((item) => parseFreshMarket(item.node)),
    objectKey: 'freshMarkets',
    pkNode: 'FreshMarketNode',
    detailAPI: getFreshMarket,
    parseDetail: (data) => parseFreshMarket(data?.freshMarket),
    initState: getInitialState(),
  },
  reducers: {},
  effects: {},
});
