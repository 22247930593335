import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getError, createAction, isNumber } from '../../utils';
import CustomTitleWithInput from '../base/CustomTitleWithInput';
import { AdminErrorHandleFields } from '../../containers/admin/admins/AdminErrorHandleFields';
import { ToastType } from '../../models/NavBarModel';
import { SavedStatus } from '../../config/CustomEnums';
import BasePrompt from '../../components/base/prompt/BasePrompt';
function ChangePasswordPrompt(props) {
  const defaultShow = props.show;
  const onClose = props.onClose || (() => {});
  const onConfirm = props.onConfirm || (() => {});
  const admin = props.admin;

  const { changePasswordStatus } = useSelector((state) => ({
    changePasswordStatus: state.admin.changePasswordStatus,
  }));

  const [errorFields, setErrorFields] = useState({ fields: [], messages: [] });
  const [show, setShow] = useState(defaultShow);

  const [password, setPassword] = useState();
  const [passwordConfirmation, setPasswordConfirmation] = useState();

  const dispatch = useDispatch();

  const handleConfirm = () => {
    if (!password) {
      setErrorFields({
        fields: ['password'],
        messages: [
          {
            field: 'password',
            errorType: 'required',
          },
        ],
      });
    } else if (password.length < 8) {
      setErrorFields({
        fields: ['password'],
        messages: [
          {
            field: 'password',
            errorType: 'length',
          },
        ],
      });
    } else if (isNumber(password)) {
      setErrorFields({
        fields: ['password'],
        messages: [
          {
            field: 'password',
            errorType: 'numeric',
          },
        ],
      });
    } else if (password !== passwordConfirmation) {
      setErrorFields({
        fields: [],
        messages: [],
      });

      dispatch({
        type: 'navBars/updateState',
        payload: {
          saveDiscardToastShowing: {
            value: true,
            type: ToastType.passwordConfirmation,
          },
        },
      });
    } else {
      dispatch(
        createAction('admin/changePassword')({
          data: {
            id: admin.pk,
            password1: password,
            password2: passwordConfirmation,
          },
        }),
      );
    }
  };

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  useEffect(() => {
    if (changePasswordStatus === SavedStatus.savedWithSuccess) {
      setShow(false);
      onConfirm();
      dispatch(
        createAction('admin/updateState')({
          changePasswordStatus: SavedStatus.init,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changePasswordStatus]);

  const otherBody = () => {
    return (
      <>
        <CustomTitleWithInput
          title={'Password'}
          defaultValue={''}
          setValue={setPassword}
          error={getError('password', errorFields, AdminErrorHandleFields)}
          type="password"
          tips="Password at least 8 characters, can't be entirely numeric."
        />

        <CustomTitleWithInput
          title={'Password confirmation'}
          defaultValue={''}
          setValue={setPasswordConfirmation}
          error={getError(
            'passwordConfirmation',
            errorFields,
            AdminErrorHandleFields,
          )}
          type="password"
          tips="Enter the same password as before, for verification"
        />
      </>
    );
  };

  return (
    <BasePrompt
      show={show}
      closeAction={handleClose}
      rightButton={{
        text: 'Change password',
        action: handleConfirm,
      }}
      title={`Change password: ${admin.username}`}
      description={`Enter a new password for the user ${admin.username}`}
      otherBody={otherBody}
    />
  );
}

export default ChangePasswordPrompt;
