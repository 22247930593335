import { LanguageConfig } from '../config/CustomEnums';
import { getVendor, getVendorList } from '../services/EvChargerVendorAPIHelper';
import { createModel } from './BaseModel';
import { parseTranslations } from './CreateTransactionRecordModel';

import { StatusTag } from '../config/CustomEnums';

const getInitialState = () => ({
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk', orderField: 'pk',},
    { displayName: 'Name', fieldName: 'name' },
    { displayName: 'Logo', fieldName: 'icon' },
    { displayName: 'Provider code', fieldName: 'providerCode' },
    { displayName: 'Deep Link', fieldName: 'deepLink' },
    { displayName: 'Status', fieldName: 'displayStatus' },
  ],
});

export const getNameByLanguage = (data, language) => {
  const translationsEdges = data?.translations?.edges || [];
  let result = '';
  translationsEdges.forEach((item) => {
    if (language === item.node.language) {
      result = item.node.name;
    }
  });
  return result || data?.name;
};

const parseVendor = (vendor) => {

  const translationsEdges = vendor.translations?.edges || [];
  const translations = {};
  translations[LanguageConfig.english] = {
    name: vendor.name,
  };
  if (translationsEdges.length) {
    translationsEdges.forEach((item) => {
      let language = item.node.language;
      const vendorName = getNameByLanguage(vendor, language)
      translations[language] = {
        ...item.node,
        id: item.node.pk,
        name: vendorName
      };
    });
  }
  return {
    ...vendor,
    icon: vendor.icon,
    displayStatus: vendor.active ? StatusTag.active: StatusTag.deactive,
    translations,
  };
};

export default createModel({
  namespace: 'evChargerVendor',
  states: getInitialState(),
  params: {
    listAPI: getVendorList,
    parse: (data) =>
      data?.evChargerVendors?.edges?.map((item) => parseVendor(item.node)),
    pkNode: 'EvChargerVendorNode',
    detailAPI: getVendor,
    parseDetail: (data) => parseVendor(data?.evChargerVendor),
    objectKey: 'evChargerVendors',
    initState: getInitialState(),
  },
  reducers: {},
  effects: {},
});
