import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import { CampaignDisplayIn, CampaignType, MissionCampaignParticipant, MissionCampaignParticipantReward } from '../../../config/CustomEnums';
import BaseMultipleSelectorV2 from '../../base/BaseMultipleSelectorV2';
import InputFieldControl, { InputFieldControl2 } from '../../base/InputFieldControl';
import { CustomTitleWithDropDown } from '../../customer/CustomTitleWithDropDown';
import { Row, Image } from 'react-bootstrap';
import CustomRadios from '../../base/CustomRadios';
import AuthButton from '../../base/AuthButton';
import addImage from '../../../assets/images/add2.png';
import deleteImage from '../../../assets/images/drop_down_delete_selected.png';
import { hasError, ReactHookFormErrorMessage } from '../../base/ErrorFieldMessage';

function FixedMissionRewardSection({
  couponTemplateList = [],
  disabled = false,
  onFocus = () => {},
}) {
  const { getValues, watch, formState, clearErrors, setValue, control } = useFormContext();
  const dispatch = useDispatch();
  const { errors } = formState;

  const watchCampaignParticipant = getValues('missionCampaignParticipant')
  const watchRewards = watch("fixedMissionRewards") || [{}];
  const watchTotalBadge = watch("totalBadgeQuantity")

  useEffect(() => {
    dispatch({
      type: 'couponList/getCouponTemplateList',
      payload: {
        status: true,
        isAll: true,
        isSimpleList: true,
        sort: "-lastModifiedDate",
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const updateTotalBadegs = (rewardList = watchRewards) => {
    let totalBadgeQuantity = 0
    rewardList.forEach(item => {
      const badge = Number(item?.requiredBadges)
      totalBadgeQuantity = badge > totalBadgeQuantity ? badge : totalBadgeQuantity
    })
    setValue("totalBadgeQuantity", totalBadgeQuantity)
  }

  const rewardItem = (item, index) => {
    return (
      <div className='reward-item-container'>
        <CustomTitleWithDropDown
          title={`Link to coupon ${index + 1}`}
          titleStyle={'create-section-label-no-top-space'}
          setValue={(item) => {
            watchRewards[index] = {...watchRewards[index], couponTemplate: item?.value}
            setValue("fixedMissionRewards", watchRewards, {shouldDirty:true})
          }}
          source={couponTemplateList}
          defaultValue={{
            value: item.couponTemplate,
            label: item.couponTemplate?.name,
          }}
          filterAction={'couponList/getCouponTemplateList'}
          defaultFilter={{
            status: true,
            isAll: true,
            isSimpleList: true,
            sort: "-lastModifiedDate",
          }}
          isError={hasError(errors, `fixedMissionRewards[${index}].couponTemplate`)}
          onFocus={() => onFocus('badgeCard')}
          disabled={disabled}
        />
        <ReactHookFormErrorMessage errors={errors} id={`fixedMissionRewards[${index}].couponTemplate`} />
        <InputFieldControl
          title={'Required badges'}
          name={`fixedMissionRewards[${index}].requiredBadges`}
          type='number'
          value={item.requiredBadges}
          setValue={(value) => {
            watchRewards[index] = {...watchRewards[index], requiredBadges: value}
            setValue("fixedMissionRewards", watchRewards, {shouldDirty:true})
            updateTotalBadegs()
          }}
          onFocus={() => onFocus('badgeCard')}
          disabled={disabled}
        />
        <InputFieldControl
          title={'Coupon quantity'}
          name={`fixedMissionRewards[${index}].couponQuantity`}
          type='number'
          value={item.couponQuantity}
          setValue={(value) => {
            watchRewards[index] = {...watchRewards[index], couponQuantity: value}
            setValue("fixedMissionRewards", watchRewards, {shouldDirty:true})
          }}
          onFocus={() => onFocus('badgeCard')}
          disabled={disabled}
        />
        { watchCampaignParticipant === MissionCampaignParticipant.team ? <>
          <label className='create-section-label create-section-label-bottom-space'>{`Who can get the reward`}</label>
          <Row className="participant-row">
            <Controller 
              control={control}
              name={`fixedMissionRewardsrecipient`}
              render={() => (
                <CustomRadios
                  onChange={(value) => {
                    watchRewards[index] = {...watchRewards[index], recipient: value}
                    setValue("fixedMissionRewards", watchRewards, {shouldDirty:true})
                  }}
                  default={item.recipient}
                  disabled={disabled}
                  options={[
                    {
                      label: 'Leader',
                      value: MissionCampaignParticipantReward.leader,
                    },
                    {
                      label: 'Leader and Team member',
                      value: MissionCampaignParticipantReward.leaderAndTeamMember,
                    },
                  ]}
                />
              )}
            />
          </Row>
        </> : null}
        { watchRewards?.length > 1 ? 
        <div className='custom-delete-btn-container'>
          <AuthButton 
            children={ <Image src={deleteImage} className="custom-delete-btn"/>}
            customClass='custom-delete-btn'
            action={() => {
              const newRewardList = watchRewards?.filter((item1, index1) => { if (index != index1) {return item1}})
              setValue("fixedMissionRewards", newRewardList, {shouldDirty: true})
              if (Number(item.requiredBadges) === watchTotalBadge) {
                updateTotalBadegs(newRewardList)
              }
            }}
            others={{
              disabled: disabled,
            }}
          />
        </div> : null}
      </div>
    )
  }

  return (
    <>
      <label className="create-section-title">{'Badge Card'}</label>
      {watchRewards?.map((item, index) => rewardItem(item, index))}
      <ReactHookFormErrorMessage errors={errors} id={`fixedMissionRewards`} />
      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
        <AuthButton 
          children={ <Image src={addImage} className="custom-add-btn"/>}
          customClass='custom-add-btn'
          action={() => {
            setValue("fixedMissionRewards", [...watchRewards, {}])
          }}
          others={{
            disabled: disabled,
          }}
        />
      </div>
      <label className={`create-section-label`}>{"Total Badge quantity"}</label>
      <label>{watchTotalBadge || '-'}</label>
    </>
  );
}

const mapPropsToState = (state) => ({
  couponTemplateList : state.couponList.couponTemplateList,
})

export default connect(mapPropsToState)(FixedMissionRewardSection);
