import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import queryString from 'query-string';
import { createAction } from '../../../utils';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import BaseListContainer from '../../base/BaseListContainer';
import { NavBarNames } from '../../../config/NavBarNameList';
import { PermissionCodes } from '../../../config/PermissionCodes';

function DistrictMallMappingList({
  listFields,
  totalCount,
  dataList,
  pageInfo,
  totalPage,
}
) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    return function () {
      dispatch({ type: 'districtMallMappingList/clearData' });
    };
  }, []);

  useEffect(() => {
    const querySearch = queryString.parse(history.location.search);
    dispatch(
      createAction('districtMallMappingList/getPagedDistrictMallMappings')({
        ...querySearch,
      }),
    );
  }, [location]);


  const tabs = [
    {
      name: 'District Mall Mappings',
      content: (
        <BaseTabListContainer
          hideTab={true}
          // hideSearch={true}
          maxTabWidth={260}
          // tabs={[]}
          pageInfo={pageInfo}
          totalCount={totalCount}
          listContent={
            <BaseListContainer
              fields={listFields}
              dataList={dataList}
              totalPage={totalPage}
              // sortable
              // sortDisabled={false}
              // rankDisabled
              model={'districtMallMappingList'}
              permissionGroup={PermissionCodes.districtmallmapping}
              deleteInfo={{}}
              actions={['Edit', 'Detail']}
              hasOtherActions
              customActions={{
              }}
            />
          }
        />
      ),
    },
  ];

  return (
    <div className="banner-list-container">
      <CustomListComponent
        caution={{
          detail: 'View or edit district mall mapping',
          title: NavBarNames.districtMallMappings,
        }}
        breadcrumb={<CustomBreadcrumb />}
        tabs={tabs}
        hideTab={true}
      />
    </div>
  );
}

const mapPropsToState = (state) => ({
  listFields: state.districtMallMappingList.listDisplayFields,
  dataList: state.districtMallMappingList.districtMallMappingList,
  pageInfo: state.districtMallMappingList.pageInfo,
  totalPage: state.districtMallMappingList.totalPage,
  totalCount: state.districtMallMappingList.totalCount,
});

export default connect(mapPropsToState)(DistrictMallMappingList);
