import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Filter.scss';
import queryString from 'query-string';
import { createAction, ArrayStringData } from '../../utils';
import { useLocation } from 'react-router-dom';
import BaseFilter, { FilterTypeEnum } from '../base/BaseFilter';
import { getConciergeLanguage } from '../../containers/conciergeApp/couponRecord/CouponTransactionRecord';
import { LanguageConfig } from '../../config/CustomEnums';
import i18n from '../../I18n';
import locale from 'antd/lib/date-picker/locale/zh_TW';

function Filter({ backAction = () => {} }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const language = getConciergeLanguage(location);
  let isConcierge = false;
  if (language === LanguageConfig.traditionalChinese) {
    isConcierge = true;
  }

  const genderSource = [
    { name: i18n.t('concierge.male', { locale: language }), pk: 'MALE' },
    { name: i18n.t('concierge.female', { locale: language }), pk: 'FEMALE' },
  ];

  const { groupSource, segmentSource } = useSelector((state) => ({
    groupSource: state.customerGroup.groupList,
    segmentSource: state.segments.segmentList,
    // levelSource: state.levels.levelList,
    filters: state.customerList.filters,
  }));

  const parsedSearch = queryString.parse(location.search);
  // const searchLevels = parsedSearch['levels'] || '';
  const searchSegments = parsedSearch['segments'] || '';
  const searchGroups = parsedSearch['customer_groups'] || '';
  const searchGenders = parsedSearch['gender'] || '';
  const searchAge = parsedSearch['age'] || '';
  const searchBirthStart = parsedSearch['start_date'] || '';
  const searchBirthEnd = parsedSearch['end_date'] || '';
  const searchCreationPeriod = parsedSearch['create_date'] || '';

  const [age, setAge] = useState(() => {
    const ages = ArrayStringData(searchAge);
    if (ages.length > 0) return ages;
    return [0, 0];
  });
  const [genders, setGenders] = useState(ArrayStringData(searchGenders));

  const [groups, setGroups] = useState(ArrayStringData(searchGroups));
  const [segments, setSegments] = useState(ArrayStringData(searchSegments));
  // const [levels, setLevels] = useState(ArrayStringData(searchLevels));

  const [startDate, setStartDate] = useState(searchBirthStart);
  const [endDate, setEndDate] = useState(searchBirthEnd);
  const [creationPeriod, setCreationPeriod] = useState(searchCreationPeriod);

  const content = [
    {
      title: 'Customer groups',
      displayName: i18n.t('concierge.customer_group', { locale: language }),
      data: groupSource,
      value: groups,
      setValue: setGroups,
      componment: FilterTypeEnum.choice,
    },
    {
      title: 'Segments',
      displayName: i18n.t('concierge.segments', { locale: language }),
      data: segmentSource,
      value: segments,
      setValue: setSegments,
      componment: FilterTypeEnum.choice,
    },
    // {
    //   title: 'Levels',
    //   data: levelSource,
    //   value: levels,
    //   setValue: setLevels,
    //   componment: FilterTypeEnum.choice,
    // },
    {
      title: 'Gender',
      displayName: i18n.t('concierge.gender', { locale: language }),
      data: genderSource,
      value: genders,
      setValue: setGenders,
      componment: FilterTypeEnum.segment,
    },
    {
      title: 'Age',
      displayName: i18n.t('concierge.age', { locale: language }),
      data: [0, 80],
      value: age,
      setValue: setAge,
      componment: FilterTypeEnum.slider,
    },
    {
      title: 'Start date',
      displayName: i18n.t('concierge.start_date', { locale: language }),
      value: startDate,
      setValue: setStartDate,
      componment: FilterTypeEnum.date,
      locale: isConcierge ? locale : '',
    },
    {
      title: 'End date',
      displayName: i18n.t('concierge.end_date', { locale: language }),
      value: endDate,
      setValue: setEndDate,
      componment: FilterTypeEnum.date,
      locale: isConcierge ? locale : '',
    },
    {
      title: 'Create date',
      value: creationPeriod,
      setValue: setCreationPeriod,
      componment: FilterTypeEnum.dateRange,
    },
  ];

  return (
    <BaseFilter
      content={content}
      backAction={backAction}
      filterText={i18n.t('transaction.filter', { locale: language })}
      resetAllText={i18n.t('transaction.reset_all', { locale: language })}
    />
  );
}

export default Filter;
