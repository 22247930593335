import React from 'react';
import { Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import './ProfileSection.scss';
import { getCampaignTypeTitle, getCampaignDisplayInTitle, getParticipantTypeTitle, getParticipantRewardTypeTitle, getMissionCampaignModeTypeConfig, getCampaignPromotionTypeTitle } from './CreateCampaignStepOneConfigs';
import CheckOnIcon from '../../../assets/images/check_on.svg';
import { CampaignCategoryType, CampaignDisplayIn, CampaignType, MissionCampaignMode, MissionCampaignParticipant, WEEKDAY } from '../../../config/CustomEnums';
import { GENERAL_TYPE } from '../../../models/CreateCampaignModel';
import { getReadablePeriod } from '../../../utils/TimeFormatUtil';
import { addDomainToImage, getImageUrl, isJsonFile } from '../../../utils';
import Lottie from 'react-lottie';
import autoMergeLevel1 from 'redux-persist/es/stateReconciler/autoMergeLevel1';
import { listMultiItem } from '../../banners/DisplayItems';

function ProfileSection({ data }) {
  const { getValues } = useFormContext() || {};
  const campaign = data || getValues() || {};
  const category = Array.isArray(campaign.categories) ? campaign.categories[0] : campaign.categories;

  const getListStrings = (list) => {
    if (list?.length === 0) {
      return null;
    }
    return list?.map((item) => item?.name || item?.pk).toString();
  };

  const getListStringsWithOrder = (list, orderDic) => {
    if (list?.length === 0) {
      return null;
    }
    return list?.map((item) => {
      return `${item?.name} (${orderDic[item.id]?.order || '-'})`
    }).toString();
  }

  const profileField = (field, value, showShortDescription = false) => {
    return (
      <>
        <label className="create-section-label create-section-label-bottom-space">
          {field}
        </label>
        <label className="create-section-field-input profile-section-label-bottom">
          {value || '-'}
        </label>
        {showShortDescription ? (
          <div className="display-over-all-limit-container">
            <Image src={CheckOnIcon} className="display-over-all-limit-icon" />
            <label className="display-over-all-limit">
              Display the overall limit in the campaign.
            </label>
          </div>
        ) : null}
      </>
    );
  };
  const imageField = (field, image) => {
    return (
      <>
        <label className="create-section-label create-section-label-bottom-space">
          {field}
        </label>
        { image ? 
        isJsonFile(addDomainToImage(getImageUrl(image))) ?
        <Lottie 
          options={{
            loop: false, 
            path: addDomainToImage(getImageUrl(image)),
          }}
          style={{ marginLeft: 0, maxWidth: 191, width: 'auto', height: 'auto' }}
        /> : 
        <img
          alt="img"
          src={addDomainToImage(getImageUrl(image))}
          style={{ maxWidth: 191, maxHeight: 191 }}
        /> :
        "-" }
      </>
    );
  }
  const fixedRewardField = (field, value) => {
    return (
      <>
        <label className="create-section-label create-section-label-bottom-space">
          {field}
        </label>
        {value?.map((item) => <>
          <label className="create-section-field-input">{item.couponTemplate?.name || '-'}</label>
          <label className="create-section-field-input list-item">{item.requiredBadges || '-'} Required badges</label>
          <label className="create-section-field-input list-item">{item.couponQuantity || '-'} Coupon quantity</label>
          {campaign.missionCampaignParticipant === MissionCampaignParticipant.team ?
          <label className="create-section-field-input list-item">{getParticipantRewardTypeTitle(item.recipient) || '-'} can get the reward</label>
          : null}
          
        </>
        )}
      </>
    );
  };
  const rewardField = (field, value, itemList) => {
    // console.log(item)
    return (
      <>
        <label className="create-section-label create-section-label-bottom-space">
          {field}
        </label>
        {value?.map((item) => <>
          <label className="create-section-field-input">{item?.rewardCoupon?.name || '-'}</label>
          {campaign.missionCampaignParticipant === MissionCampaignParticipant.team ?
          <label className="create-section-field-input list-item">{itemList[item.id]?.recipient || '-'} can get the reward</label>
          : null}
        </>
        )}
      </>
    );
  };
  const listField = (field, list = []) => {
    console.log("@105", list)
    return <>
        <label className="create-section-label create-section-label-bottom-space">
          {field}
        </label>
        { list?.length === 0 ? 
          <label className="create-section-field-input">{'-'}</label> :
          list?.map((item) =>
            <label className="create-section-field-input list-item">{item}</label>
          )
        }
      </>
  }
  const getApplicationStoreList = (mallStoreField = [], FreshMarketStoreField = []) => {
    if (mallStoreField?.length === 0 && FreshMarketStoreField?.length === 0) {
      return [];
    }
    return [...mallStoreField?.map((item) => `[${item.pk}] ${item?.name} (${item?.mall?.name})`), 
    ...FreshMarketStoreField?.map((item) => `[${item.pk}] ${item?.name} (${item?.freshMarket?.name})`)];
  }

  const isEmpty = (value) => {
    return value === "" || value === null || value == undefined
  }

  return (
    <>
      <label className="create-section-title">PROPERTIES</label>

      {campaign.displayIn === CampaignDisplayIn.estamp || campaign.displayIn === CampaignDisplayIn.emission
        ? null : profileField('Display in', getCampaignDisplayInTitle(campaign.displayIn))}
      {profileField('Campaign type', getCampaignTypeTitle(campaign.campaignType))}
      {campaign.campaignType === CampaignType.emissionCampaign ? (
        <>
          {profileField('Set mode', getMissionCampaignModeTypeConfig(campaign.missionCampaignMode))}
          {profileField('Participant', getParticipantTypeTitle(campaign.missionCampaignParticipant))}
          {campaign.missionCampaignParticipant === MissionCampaignParticipant.team ? <>
          {profileField('Minimum number of invitees (Except for the leader)', campaign.missionCampaignMinimumNumberOfInvitees)}
          {profileField('Maximum number of invitees (Except for the leader)', campaign.missionCampaignMaximumNumberOfInvitees)}
          </> : null}
          {campaign.missionCampaignMode === MissionCampaignMode.fixedRewards ? <>
          {/* {imageField('Image for badge empty slot (Optional)', campaign.emptyStampPhoto)}
          {imageField('Image for acquried badge (Optional)', campaign.acquiredStampPhoto)} */}
          {profileField('Link to earning campaign', getListStringsWithOrder(campaign.linkedEarningCampaigns, campaign.earningCampaignRelation))}
          {fixedRewardField('Rewards', campaign.fixedMissionRewards)}
          </> : <>
          {profileField('Link to earning campaign (for earning campaign links to "common type" coupon only)', 
            getListStringsWithOrder(campaign.linkedEarningCampaigns, campaign.earningCampaignRelation))}
          {rewardField('Rewards', campaign.linkedEarningCampaigns, campaign.earningCampaignRelation)}
          </>}
          {/* {profileField('Mission TNC URL', campaign.missionCampaignTncUrl)}
          {profileField('Mission Privacy policy URL', campaign.missionCampaignPrivacyUrl)} */}
        </>
      ) : null}
      {campaign.campaignType === CampaignType.couponsCampaign
        ? profileField('Link to coupon set', campaign.linkedCoupon?.name)
        : campaign.campaignType === CampaignType.earningRulesCampaign || campaign.campaignType === CampaignType.estampCampaign || campaign.CampaignType === CampaignType.emissionCampaign
        ? profileField('Link to earning rule', campaign.linkedEarningRules?.name)
        : null
      }
      {campaign.campaignType === CampaignType.fortuneBag
        ? listMultiItem('Selected coupon templates', campaign.selectedCouponSets?.map((tag) => tag?.label || tag?.pk)) : null
      }
      { campaign.displayIn === CampaignDisplayIn.interestClub ?
      <>
        {profileField('Campaign category for Interest Club', category?.name || category?.pk)}
        {profileField('No. of Likes', campaign.numberOfLikes)}
        {profileField('No. of Bookmarks', campaign.numberOfBookmarks)}
      </> :
      <>
        {profileField('Campaign category', getListStrings(campaign.categories?.filter((item) => { if (item.type === CampaignCategoryType.GENERAL) {return item}})))}
      </>}
      {listMultiItem('Campaign tag', campaign.campaignTags?.map((tag) => tag?.name || tag?.pk))}
      { (campaign.campaignType === CampaignType.htmlCampaign || !(campaign.isExclusive && campaign.generalType)) ? null : <>
      {profileField(
        'Target customer groups',
        getListStrings(
          campaign.generalType === GENERAL_TYPE.customerGroup ? campaign.customerGroup : [],
        ),
      )}
      {profileField(
        'Segments',
        getListStrings(campaign.generalType === GENERAL_TYPE.segment ? campaign.segments : []),
      )}
      </>}
      {profileField(
        'Active period',
        getReadablePeriod(campaign.activeStartDate, campaign.activeEndDate, campaign.isAlwaysActivePeriod),
      )}
      {listMultiItem(
        'Blackout period (by period)',
        campaign.activePeriodValues?.map((item) =>
          getReadablePeriod(item.startDate, item.endDate, false)
        ),
      )}
      {listMultiItem(
        'Blackout period (by weekday)',
        campaign.activeWeekday?.map((item) => WEEKDAY[item]),
      )}
      {profileField(
        'Visible period',
        getReadablePeriod(
          campaign.visibleStartDate,
          campaign.visibleEndDate,
          campaign.isAlwaysVisiblePeriod,
        ),
      )}
      {/* {profileField('Related brand for display in frontend', getListStrings(campaign.selectedBrand))} */}
      {campaign.displayIn === CampaignDisplayIn.stamp || campaign.displayIn === CampaignDisplayIn.mission ? null :
       <>
       {profileField('Show in mall detail page (and related carpark and fresh market)', campaign.isShowInMall ? getListStrings(campaign.selectedMall) : '-')}
       {listField('Show in store detail page', getApplicationStoreList(campaign.selectMallStores, campaign.selectFreshMarketStores))}
       {campaign.campaignType === CampaignType.htmlCampaign ? (
        <>
          {listField('Related campaign', campaign.selectedRelatedCampaign ? campaign.selectedRelatedCampaign.map((campaign) => campaign.name || campaign.pk) : [])}
          {listField('Related store', getApplicationStoreList(campaign.selectRelatedMallStores, campaign.selectRelatedFreshMarketStores))}
        </>
       ) : null}
       {/* {profileField('Promotion type (for LINK customer website)', getCampaignPromotionTypeTitle(campaign.campaignPromotionType))} */}
       </>
      }
      
      {campaign.campaignType === CampaignType.couponsCampaign ? (
        <>
          {profileField(
            'Per campaign coupon acquiring limit',
            campaign.overallLimit === null ? '-' : `${campaign.overallLimit} number of coupons`,
            campaign.displayOverLimit,
          )}
          {profileField(
            'Per head coupon acquiring limit during the campaign active period',
            campaign.perHeadLimit === null ? '-' : `${campaign.perHeadLimit} number of coupons`,
          )}
          { campaign.displayIn === CampaignDisplayIn.stamp ? profileField('Required stamps', `${campaign.requiredStamps} stamps`) : null}
        </>
      ) : null}
      {campaign.campaignType === CampaignType.fortuneBag  ? (
        <>
          {profileField(
            'Overall limit',
            isEmpty(campaign.overallLimit) ? '-' : `${campaign.overallLimit} times`,
          )}
          {profileField(
            'Overall period limit',
            isEmpty(campaign.periodicLimit && campaign.periodicLimitDays)  ? '-' : `${campaign.periodicLimit} times per ${campaign.periodicLimitDays} days`,
          )}
          {profileField(
            'Per head limit',
            isEmpty(campaign.perHeadLimit) ? '-' : `${campaign.perHeadLimit} times`,
          )}
          {profileField(
            'Per head periodic limit',
            isEmpty(campaign.perHeadPeriodicLimit && campaign.perHeadPeriodicLimitDays) ? '-' : `${campaign.perHeadPeriodicLimit} times per ${campaign.perHeadPeriodicLimitDays} days`,
          )}
          {profileField(
            'No. of coupons can be acquired each time',
            isEmpty(campaign.acquireCouponNumberEachTime) ? '-' : `${campaign.acquireCouponNumberEachTime} number of coupons`,
          )}
        </>
      ) : null}
      {campaign.campaignType === CampaignType.estampCampaign ? (
        <>
          {profileField(
            'Number of Stamp slot (Per head owned coupon limit)',
            campaign.ownedCouponLimit === null ? '-' : campaign.ownedCouponLimit
          )}
          {profileField(
            'Link to coupon campaign (For stamp campaign detail page)',
            getListStrings(campaign.linkedCouponCampaigns)
          )}
          { campaign.uploadStampIcons ? <>
          {imageField(
            'Image for stamp empty slot',
            campaign.emptyStampPhoto
          )}
          {imageField(
            'Image for acquried stamp',
            campaign.acquiredStampPhoto
          )}
          </> : null}
        </>
      ) : null}
    </>
  );
}

export default ProfileSection;
