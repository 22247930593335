// home breadcrumb
export const homeBreads = () => {
  return [{ name: 'Home', link: '/' }];
};

//campaign breadcrumb
export const campaignListBreads = () => {
  const homeBreadList = homeBreads();
  homeBreadList.push({ name: 'Campaigns', link: '/campaigns' });
  return homeBreadList;
};

export const createCampaignBreads = (bread) => {
  const camapignListBreadList = campaignListBreads();
  camapignListBreadList.push({
    name: `${bread ? bread : 'Create Campaign'}`,
    link: '/campaigns/create',
  });
  return camapignListBreadList;
};

//estamp campaign breadcrumb
export const eStampCampaignListBreads = () => {
  const homeBreadList = homeBreads();
  homeBreadList.push({ name: 'Stamp Campaigns', link: '/estamp_campaigns' });
  return homeBreadList;
};

export const createEStampCampaignBreads = (bread) => {
  const camapignListBreadList = eStampCampaignListBreads();
  camapignListBreadList.push({
    name: `${bread ? bread : 'Create Stamp Campaign'}`,
    link: '/estamp_campaigns/create',
  });
  return camapignListBreadList;
};

//emission campaign breadcrumb
export const eMissionCampaignListBreads = () => {
  const homeBreadList = homeBreads();
  homeBreadList.push({ name: 'Mission Campaigns', link: '/emission_campaigns' });
  return homeBreadList;
};

export const createEMissionCampaignBreads = (bread) => {
  const camapignListBreadList = eMissionCampaignListBreads();
  camapignListBreadList.push({
    name: `${bread ? bread : 'Create Mission Campaign'}`,
    link: '/emission_campaigns/create',
  });
  return camapignListBreadList;
};

export const editCampaignBreads = () => {
  const camapignListBreadList = campaignListBreads();
  camapignListBreadList.push({
    name: 'Edit Campaign',
  });
  return camapignListBreadList;
};

// coupon breadcrumb

export const couponSetBreads = () => {
  const homeBreadList = homeBreads();
  homeBreadList.push({
    name: 'Coupon Sets',
    link: '/coupons',
  });
  return homeBreadList;
};

export const createCouponBreads = (id) => {
  const couponSetBreadList = couponSetBreads();
  couponSetBreadList.push({
    name: `${id ? 'Edit': 'Create'} Coupon Set`,
    link: '/coupons/create',
  });
  return couponSetBreadList;
};

export const couponSetDetailBreads = (couponSetName, isBack = false) => {
  const couponSetListBreadList = couponSetBreads();
  couponSetListBreadList.push({
    name: couponSetName,
    link: '/coupons/couponSet',
    isBack: isBack,
  });
  return couponSetListBreadList;
};

export const singleCouponBreads = (couponSetName, singleCouponSerialNum) => {
  const couponSetDetailBreadList = couponSetDetailBreads(couponSetName, true);
  couponSetDetailBreadList.push({
    name: singleCouponSerialNum,
    link: '/coupons/couponSet/singleCoupon',
  });
  return couponSetDetailBreadList;
};
