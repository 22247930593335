import React from 'react';
import './LevelList.scss'
function LevelList (){ 
    return (
        <h1 className="text-header">
            this is LevelList
        </h1>
    );
  }

export default LevelList;