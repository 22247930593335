import { capitalizeFirstLetter, getSearchKey } from '../utils';
import { BaseHelper } from './BaseGraphQLAPIHelper';

const CAMPAIGN_TAG_NODE = `{
  id
  pk
  name
  displayPriority
  isForcedInactive
  translations {
    edges {
      node {
        pk
        language
        name
      }
    }
  }
}`;

export const getCampaignTagList = (afterCursor, payload) => {
  let searchString = payload?.isAll ? '' : `first: ${payload?.pageSize || 20}`;
  if (afterCursor) {
    searchString += `, after: "${afterCursor}"`;
  }
  if (payload?.sort) {
    searchString += `, orderBy: "${payload.sort}"`;
  } else {
    searchString += `, orderBy: "displayPriority"`;
  }
  if (payload?.searchKey) {
    searchString += `, nameIcontains:"${getSearchKey(payload.searchKey)}"`;
  }
  if (payload?.status) {
    searchString += `, isActive: ${payload.status}`;
  }

  const query = `{
    campaignTags(${searchString}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node ${CAMPAIGN_TAG_NODE}
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getCampaignTag = (id) => {
  const query = `{
    campaignTag(id: "${id}") ${CAMPAIGN_TAG_NODE}
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const createCampaignTag = (input) => {
  return createOrUpdateCampaignTag(input, 'createCampaignTag');
};

export const updateCampaignTag = (input) => {
  return createOrUpdateCampaignTag(input, 'updateCampaignTag');
};

const createOrUpdateCampaignTag = (input, keyWord) => {
  const capitalizeKeyWord = capitalizeFirstLetter(keyWord);
  const query = `mutation ${capitalizeKeyWord}($input: ${capitalizeKeyWord}Input!) {
    ${keyWord}(input: $input) {
      node ${CAMPAIGN_TAG_NODE}
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: {
      ...input,
    },
  };
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const deleteCampaignTags = (ids) => {
  const query = `mutation DeleteCampaignTags($input: DeleteCampaignTagsInput!) {
    deleteCampaignTags(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids,
    },
  };
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};
