import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { LanguageConfig, CampaignType } from '../../../config/CustomEnums';
import i18n from '../../../I18n';
import CustomMarkDownArea, { ReactHookFormMarkDown } from '../../base/CustomMarkDownArea';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { useHistory, useParams } from 'react-router-dom';
import BasePrompt from '../../base/prompt/BasePrompt';
import { ContinueCreate } from '../CampaignUrlConfig';
import { useFormContext, Controller } from 'react-hook-form';
import { SectionTopTitle } from '../../base/CommonComponent';
import InputFieldControl, { InputFieldControl2 } from '../../base/InputFieldControl';
import ErrorFieldMessage, {
  hasError,
  ReactHookFormErrorMessage,
} from '../../base/ErrorFieldMessage';
import BaseMultipleSelectorV2 from '../../base/BaseMultipleSelectorV2';
import './GeneralSection.scss';
import HtmlEditor from '../../base/HtmlEditor';
import SelectMultipleBrand from '../../base/SelectMultipleBrand';
import PublisherTypeSection from './PublisherTypeSection';
import { createAction, addDomainToImage } from '../../../utils';

function GeneralSection({
  brandList,
  language,
  onFocus = (() => {}),
}) {
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const history = useHistory();
  const { watch, setValue, formState, control, getValues } = useFormContext();
  const errors = formState.errors;

  const watchCampaignType = watch('campaignType');
  // const watchName = watch(`translations.${language}.generalName`);
  const [showAddBrand, setShowAddBrand] = useState(false);
  const editorRef = useRef(null);

  const initHtmlContent = getValues(`translations.${language}.htmlContent`)
  // const log = () => {
  //   if (editorRef.current) {
  //     const htmlContent = editorRef.current.getContent()
  //     console.log(editorRef.current.getContent());
  //     // setValue(`translations.${language}.htmlContent`, htmlContent, {shouldDirty: true })
  //   }
  // };
  return (
    <>
      <SectionTopTitle title={i18n.t('general', { locale: language })} />
      <InputFieldControl2
        name={`translations.${language}.generalName`}
        title={i18n.t('name', { locale: language })}
        // value={watchName}
        // setValue={(value) => {
        //   setValue(`translations.${language}.generalName`, value, { shouldDirty: true });
        // }}
        onFocus={() => {
          onFocus('name');
        }}
      />
      { watchCampaignType === CampaignType.htmlCampaign ? 
      <>
        <label className={`create-section-label content-title`}>{i18n.t('content', { locale: language })}</label>
        <Controller 
          control={control} 
          name={`translations.${language}.htmlContent`} 
          render={({field, fieldState: { error }}) => {
            console.log("@68", field, typeof field.value)
            
            return <>
              <HtmlEditor
                // onInit={(evt, editor) => editorRef.current = editor}
                initialValue={initHtmlContent}
                init={{
                  height: 1000,
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  images_upload_handler: async blobInfo => {
                    return new Promise((resolve, reject) => {
                      dispatch({
                        type: 'uploadFiles/uploadBlob',
                        payload: { 
                          blobInfo,
                          afterAction: (data) => {
                            if (data?.status < 300) {
                              resolve(addDomainToImage(data.blob_image))
                            } else {
                              reject("")
                            }
                          }
                        }, 
                      })
                    });
                  },
                }}
                onEditorChange={(value, editor) => {
                  // console.log("@79", editor);
                  // console.log("@71", value); 
                  field.onChange(value);
                }}
                className={error ? 'error-field' : ''}
              />
              {/* <button onClick={log}>Log editor content</button> */}
              <ErrorFieldMessage error={error} message={error?.message} id={`translations.${language}.htmlContent`} />
            </>
          }} 
        />
      </> : 
      <>      
      <ReactHookFormMarkDown
        content={{
          label: i18n.t('short_description', { locale: language }),
          // error:
          //   language === LanguageConfig.english
          //     ? hasError(errors, `translations.${language}.shortDescription`)
          //     : false,
          key: `translations.${language}.shortDescription`,
          focus: () => {
            onFocus('shortDescription')
          }
        }}
      />
      <ReactHookFormErrorMessage errors={errors} id={`translations.${language}.shortDescription`} />
      </> }

      <div
        className="create-campaign-brand-select"
        onFocus={() => onFocus('selectedBrand')}
        style={{flexDirection: 'column'}}
      >
        <PublisherTypeSection
          language={language}
        />
        {/* {language === LanguageConfig.english ? (
          <div>
            <BaseMultipleSelectorV2
              title={i18n.t('brand_display_option', {locale: language})}
              size={["shorter"]}
              onFocusFunc={() => {
                onFocus('selectedBrand');
              }}
              namespace="brandModel"
              custom={{
                customItem: (item) => {
                  return `[ID:${item.pk}] ${item.name}`
                }
              }}
              data={{
                sourceData: brandList,
                targetData: watchBrand,
                targetChange: (value) => {
                  setValue('selectedBrand', value, {
                    shouldDirty: true,
                  });
                },
              }}
              addButton={{
                action: () => {
                  setShowAddBrand(true);
                },
                title: 'Add Brand',
                requires: PermissionCodes.addStore,
                customClass:
                  'general-section-add-new btn-add-button-common',
              }}
              error={{
                error: hasError(errors, 'selectedBrand'),
              }}
            />
            <ReactHookFormErrorMessage errors={errors} id='selectedBrand' />
          </div>
        ) : (
          <div>
          <div className="create-section-label create-section-label-bottom-space">
            {i18n.t('brand_display_option', {locale: language})}
          </div>
          {watchBrand && watchBrand?.length > 0 ? watchBrand.map((brand) => {
            return (
              <div
                className="second-section-content"
                key={brand.name}
              >
                {brand.name}
              </div>
            );
          }) : '-'}
        </div>
        )} */}
      </div>
      <BasePrompt
        show={showAddBrand}
        closeAction={() => setShowAddBrand(false)}
        rightButton={{
          text: 'Create Brand',
          action: () =>
            history.push({
              pathname: '/brands/create',
              state: { from:
                {...history.location,
                  state: {
                    ...history.location?.state,
                    resume: true
                  }}, ...ContinueCreate },
            }),
        }}
        title={'Go to create brand?'}
        description={`You will leave the campaign creation process. After you create a new brand, you can back to this page.`}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  // brandList: state.brand.brandList,
  brandList: state.brand.currentPageBrandList || [],
})

export default connect(mapPropsToState)(GeneralSection);
