import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactHookFormImageUploader } from '../base/ImageUploader';
import { FORM_TYPE, LanguageConfig } from '../../config/CustomEnums';
import i18n from '../../I18n';
import './ReceiptSection.scss';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';

export const getReceiptTitle = (index, receipt, language, isTrade = false) => {
  let title = i18n.t('transaction.receipt', {
    locale: language,
    index: index + 1,
  });
  title = isTrade
    ? `${title} - ${receipt?.invoiceId} - (${receipt?.store?.name}: HK$${receipt?.totalValue})`
    : `${title} - 0 - (${receipt?.store?.name}: ${receipt?.submitNumber})`;
  return title;
}

function PurchaseReceiptSection(props) {
  const { watch } = useFormContext();
  const receiptImageField = `receiptImage`;
  const creditCardSlipImageField = `creditCardSlipImage`;

  const receipts = watch('receipts') || [{}];
  const formType = watch('formType');
  const receiptImage = watch(receiptImageField);
  const creditCardSlipImage = watch(creditCardSlipImageField);

  const isTrade = formType === FORM_TYPE.trade;

  const language = props.language;

  const getReceiptsTitle = (receipt, index) => {
    const titleField = `receipts[${index}].title`;

    const title = watch(titleField);

    return (
      <CustomTitleLabel title={title} className={'no-top-space d-block'} />
    );
  };

  return (
    <div>
      {receipts.map((receipt, index) => getReceiptsTitle(receipt, index))}
      <div className="receipt-container">
        <div className="first-section">
          <ReactHookFormImageUploader
            title={i18n.t('transaction.upload_receipt_image', {
              locale: language,
            })}
            uploadSuggest={i18n.t('transaction.suggested_image', {
              locale: language,
            })}
            name={receiptImageField}
            minWidth={375}
            minHeight={667}
            value={receiptImage}
            aspect={0}
            maxImageNum={1}
            language={language}
            labelNewline={true}
            sizeLimit={0}
            needCrop={false}
          />
        </div>
        <div className="first-section">
          <ReactHookFormImageUploader
            title={i18n.t('transaction.upload_credit_card_slip_image', {
              locale: language,
            })}
            uploadSuggest={i18n.t('transaction.suggested_image', {
              locale: language,
            })}
            name={creditCardSlipImageField}
            minWidth={375}
            minHeight={667}
            value={creditCardSlipImage}
            aspect={0}
            maxImageNum={1}
            language={language}
            labelNewline={true}
            sizeLimit={0}
            needCrop={false}
          />
        </div>
      </div>
    </div>
  );
}

export default PurchaseReceiptSection;
