import { apiWithResponseHandle } from './LoadingUtil';
import { APIStatus } from '../config/CustomEnums';
import { updateCampaign } from '../services/CampaignAPIHelper';

export const ErrorFields = {
  displayPriority: {
    name: 'order',
    message: 'Please provide a display order.',
  },
};

const getInitialState = () => ({
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Campaign name', fieldName: 'name', orderField: 'name' },
    { displayName: 'Cover photo', fieldName: 'coverPhoto' },
    { displayName: 'Campaign type', fieldName: 'typeDisplay' },
    { displayName: 'Brand', fieldName: 'displayBrand', orderField: 'brand' },
    {
      displayName: 'Active Period',
      fieldName: 'displayActivePeriod',
      orderField: 'startDate',
    },
    {
      displayName: 'Visible Period',
      fieldName: 'displayVisiblePeriod',
      orderField: 'displayStartDate',
    },
    {
      displayName: 'Display Order',
      fieldName: 'order',
      orderField: 'displayPriority',
    },
    { displayName: 'Status', fieldName: 'displayStatus' },
  ],
  errorFields: [],
  createStatus: APIStatus.none,
  formChanged: false,
});

const checkFields = (data) => {
  const errorFields = [];
  if (!data.displayPriority) {
    errorFields.push(ErrorFields.displayPriority);
  }
  return errorFields;
};

export default {
  namespace: 'featuredCampaignList',
  state: { ...getInitialState() },
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },
  effects: {
    updateFeaturedCampaign: [
      function* ({ payload }, { put }) {
        const serviceArgs = [
          updateCampaign,
          {
            id: payload.data.id,
            displayPriority: payload.data.displayPriority,
          },
        ];
        const afterAction = payload.afterAction || (() => {});
        const errorFields = checkFields(payload.data);
        yield put({ type: 'updateState', payload: { errorFields } });
        if (errorFields.length) {
          return;
        }
        yield put({
          type: 'updateState',
          payload: { createStatus: APIStatus.calling },
        });
        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.success },
          });
          yield afterAction();
        }
        function* onFailed(data) {
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.failed },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed, onFailed);
      },
    ],
  },
};
