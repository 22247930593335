import React, { useEffect, useState } from 'react';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';

import ErrorFieldMessage from '../base/ErrorFieldMessage';
import CustomTips from '../base/CustomTips';
import { ImageTextButton } from '../base/CustomImageButton';
import { Image } from 'react-bootstrap';

function CustomTransactionWithIcon({
  title,
  defaultValue,
  setValue,
  error = {},
  type = 'text',
  tips,
  focusChange = () => {},
  extra,
  customTitleClass,
  customClass,
  disabled,
  blurAction = (target) => {},
  useDefaultValue = true,
  unit,
  per,
  firstShortDescription,
  secondShortDescription,
  imagesTips,
  icon,
  iconAction,
}) {
  useEffect(() => {
  }, [defaultValue]);

  const renderLabel = (tips, label) => {
    if (!tips) {
      return renderLabelText(label, 'create-section-label-bottom-space');
    }
    return (
      <div className="create-section-label-bottom-space">
        <CustomTips detail={tips.text} innerImage={tips.image}>
          {renderLabelText(label)}
        </CustomTips>
      </div>
    );
  };
  const renderLabelText = (labelText, className) => {
    return (
      <label className={`create-section-label ${className} ${customTitleClass}`}>{labelText}</label>
    );
  };

  return (
    <div className="d-flex flex-column">
      {renderLabel(imagesTips, title)}
      {tips ? <label className="tips-message">{tips}</label> : null}
      <div style={unit ? {position: 'relative'} : {display: "flex", position: 'relative'}}>
      {per ? <label className="help-message margin-right-10-percent">{per}</label> : null}
      <input
        type={type}
        onChange={({ target }) => setValue(target.value)}
        {...(useDefaultValue
          ? { defaultValue: defaultValue || '' }
          : { value: defaultValue || '' })}
        className={`custom-markdown-area-title ${
          error.error ? 'error-field' : ''
        } custom-markdown-area-title-short ${
          type === 'number' ? 'custom-number-input-short' : ''
        } ${customClass}`}
        onFocus={focusChange}
        {...extra}
        disabled={disabled}
        onBlur={({ target }) => blurAction(target)}
        style={{width: '100%'}}
      />
      {icon ? (
        <button type='button' className="custom-image-button" style={{position: 'absolute', left: '579px', bottom: '-1px'}} onClick={iconAction}>
          <Image src={icon}  />
        </button>
      ) : null}
      {unit ? <label className="section-short-description">{unit}</label> : null}
      </div>
      <div>
        {firstShortDescription ? <label className="section-short-description" style={{marginRight: '40px'}}>{firstShortDescription}</label>: null}
        {secondShortDescription ? <label className="section-short-description">{secondShortDescription}</label>: null}
      </div>
      {error.error ? <ErrorFieldMessage id={error.id} error={error} message={error.error} /> : null}
    </div>
  );
}

export default CustomTransactionWithIcon;
