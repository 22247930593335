import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import '../../containers/customers/customer/CustomerOverviewDetail.scss';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import ErrorFieldMessage from '../base/ErrorFieldMessage';
import CustomRadioWithTitleAndRelated from './CustomRadioWithTitleAndRelated';
import CustomTitleWithSwitch from '../base/CustomTitleWithSwitch';
import { hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import CustomDateSelect from '../base/CustomDateSelect';
import { getCurrentDay } from '../../utils/TimeFormatUtil';
import {
  CustomTitleWithDropDown,
  CustomTitleWithDropDownAndInput,
} from './CustomTitleWithDropDown';
import { listItem } from '../banners/DisplayItems';
import i18n from '../../I18n';
import { parseNameByLanguage } from '../../models/CreateTransactionRecordModel';

export const AGE_RANGE = [
  { name: '0-5', pk: 0 },
  { name: '6-10', pk: 1 },
  { name: '11-15', pk: 2 },
  { name: '16-20', pk: 3 },
  { name: '20+', pk: 4 },
]

function CustomTitleWithInput({ title, defaultValue, setValue, error = {} }) {
  return (
    <div style={{ display: 'grid' }}>
      <CustomTitleLabel title={title} />
      <input
        type="text"
        onChange={({ target }) => setValue(target.value)}
        value={defaultValue || ''}
        className={`custom-markdown-area-title ${
          error.error ? 'error-field' : ''
        } custom-markdown-area-title-short`}
        onFocus={() => {}}
      />
      <ErrorFieldMessage id={error.id} error={error} message={error.message} />
    </div>
  );
}

function PersonalInfoEditCard({ title, language }) {
  const dispatch = useDispatch();

  const { watch, setValue, control, formState, clearErrors, getValues } =
    useFormContext();
  const { errors } = formState;

  const watchFirstName = watch('firstName');
  const watchLastName = watch('lastName');
  const watchChineseFirstName = watch('chineseFirstName');
  const watchChineseLastName = watch('chineseLastName');
  const watchNickName = watch('nickname');
  const watchGender = watch('gender');
  const watchIsAssignedAsTestingCustomer = watch('isAssignedAsTestingCustomer');
  const watchDateOfBirth = watch('dateOfBirth');
  const watchResidentialDistrict = watch('residentialDistrict');
  const watchWorkingDistrict = watch('workingDistrict');
  const mobileNumber = watch('mobileNumber');
  const watchMobilePhoneNumberCountryCode = watch('mobilePhoneNumberCountryCode');
  const watchMobilePhoneNumberSubscriberNumber = watch('mobilePhoneNumberSubscriberNumber');
  const watchEmailAddress = watch('emailAddress');
  const watchMaritalStatus = watch('maritalStatus');
  const watchHouseholdIncome = watch('householdIncome');
  const watchHaveChildren = watch('haveChildren');
  const watchAgeOfChildren = watch('ageOfChildren');

  const { residentialDistricts, workingDistricts } = useSelector((state) => ({
    residentialDistricts: state.customerList.residentialDistricts,
    workingDistricts: state.customerList.workingDistricts,
  }));

  const parseResidentialDistricts = parseNameByLanguage(residentialDistricts, language);
  const parseWorkingDistricts = parseNameByLanguage(workingDistricts, language);

  const handleDefaultValue = (defaultValue, fieldName, sourceList) => {
    const filterValue = sourceList?.filter((item) => item?.pk === defaultValue?.pk)[0];
    if (sourceList.length) {
      setValue(fieldName, filterValue, { shouldDirty: true });
    }
  }

  useEffect(() => {
    dispatch({
      type: 'customerList/getResidentialDistricts',
      payload: {
        isAll: true,
      },
    });
    dispatch({
      type: 'customerList/getWorkingDistricts',
      payload: {
        isAll: true,
      },
    });
  }, [dispatch]);

  useEffect(() => {
    handleDefaultValue(watchResidentialDistrict, 'residentialDistrict', parseResidentialDistricts);
    handleDefaultValue(watchWorkingDistrict, 'workingDistrict', parseWorkingDistricts);
  }, [residentialDistricts, workingDistricts])

  return (
    <div>
      <label className="create-section-title">{title}</label>

      <CustomTitleWithInput
        title={i18n.t('concierge.first_name', { locale: language })}
        defaultValue={watchFirstName}
        setValue={(value) => {
          setValue('firstName', value, { shouldDirty: true });
        }}
      />
      <ReactHookFormErrorMessage errors={errors} id="firstName" />

      <CustomTitleWithInput
        title={i18n.t('concierge.last_name', { locale: language })}
        defaultValue={watchLastName}
        setValue={(value) => {
          setValue('lastName', value, { shouldDirty: true });
        }}
      />
      <ReactHookFormErrorMessage errors={errors} id="lastName" />

      {/* <CustomTitleWithInput
        title={'Chinese First name(Optional)'}
        defaultValue={''}
        setValue={setChineseFirstName}
        error={{}}
      />

      <CustomTitleWithInput
        title={'Chinese Last name(Optional)'}
        defaultValue={''}
        setValue={setChineseLastName}
        error={{}}
      /> */}

      {/* <CustomTitleWithInput
        title={'Preferred name (optional)'}
        defaultValue={watchNickName}
        setValue={(value) => {
          setValue('nickname', value, { shouldDirty: true });
        }}
        error={{}}
      />
      <ReactHookFormErrorMessage errors={errors} id='nickname' /> */}

      <CustomRadioWithTitleAndRelated
        title={i18n.t('concierge.gender', { locale: language })}
        options={[
          { label: i18n.t('concierge.male', { locale: language }), value: 'MALE' },
          { label: i18n.t('concierge.female', { locale: language }), value: 'FEMALE' },
          { label: i18n.t('concierge.not_disclosed', { locale: language }), value: 'NOT_DISCLOSED' },
        ]}
        defaultValue={watchGender}
        setValue={(value) => {
          setValue('gender', value, { shouldDirty: true });
        }}
      />
      <ReactHookFormErrorMessage errors={errors} id="gender" />

      {/* <CustomTitleWithDropDown
        title={'Preferred message language'}
        source={[
          { name: 'English', value: 'en' },
          { name: '简体', value: 'zh-Hant' },
          { name: '繁体', value: 'zh-Hans' },
        ]}
        defaultValue={preferredMessageLanguage}
        needFilter={false}
        setValue={setPreferredMessageLanguage}
        errors={errorFields?.fields}
      /> */}

      <CustomTitleLabel title={i18n.t('concierge.date_of_birth', { locale: language })} />
      <CustomDateSelect
        defaultDate={watchDateOfBirth}
        error={hasError(errors, 'dateOfBirth')}
        onDateChange={(date) => {
          setValue('dateOfBirth', date, { shouldDirty: true });
        }}
        maxDate={getCurrentDay()}
      />
      <ReactHookFormErrorMessage errors={errors} id="dateOfBirth" />

      <CustomTitleWithDropDown
        title={i18n.t('concierge.residential_district', { locale: language })}
        defaultValue={{
          value: watchResidentialDistrict,
          label: watchResidentialDistrict?.name,
        }}
        source={parseResidentialDistricts}
        setValue={(item) => {
          setValue('residentialDistrict', item.value, { shouldDirty: true });
        }}
        // loadMoreAction={'customerList/getResidentialDistricts'}
        filterAction={'customerList/getResidentialDistricts'}
        defaultFilter={{isAll: true}}
        isError={hasError(errors, 'residentialDistrict')}
      />
      <ReactHookFormErrorMessage errors={errors} id="residentialDistrict" />

      <CustomTitleWithDropDownAndInput
        title={i18n.t('concierge.mobile_number', { locale: language })}
        source={[
          // { name: '856', pk: '856' },
          { name: '852', pk: '852' },
          { name: '86', pk: '86' },
        ]}
        defaultValue={{
          label: watchMobilePhoneNumberCountryCode,
          value: watchMobilePhoneNumberCountryCode,
        }}
        defaultInputValue={watchMobilePhoneNumberSubscriberNumber}
        needFilter={false}
        customClass="dropdown-short"
        inputCustomClass="dropdown-input-long"
        setValue={(value) => {
          setValue('mobilePhoneNumberCountryCode', value.value, {
            shouldDirty: true,
          });
        }}
        setInputValue={(value) => {
          setValue('mobilePhoneNumberSubscriberNumber', value, {
            shouldDirty: true,
          });
        }}
      />
      <ReactHookFormErrorMessage
        errors={errors}
        id={'mobilePhoneNumberCountryCode'}
      />
      <ReactHookFormErrorMessage
        errors={errors}
        id={'mobilePhoneNumberSubscriberNumber'}
      />

      <CustomTitleWithInput
        title={i18n.t('concierge.email', { locale: language })}
        defaultValue={watchEmailAddress}
        useDefaultValue={false}
        setValue={(value) => {
          setValue('emailAddress', value, { shouldDirty: true });
        }}
        error={{ error: hasError(errors, 'emailAddress') }}
      />
      <ReactHookFormErrorMessage errors={errors} id={'emailAddress'} />

      <CustomTitleWithDropDown
        title={i18n.t('concierge.working_district', { locale: language })}
        defaultValue={{
          value: watchWorkingDistrict,
          label: watchWorkingDistrict?.name,
        }}
        source={parseWorkingDistricts}
        setValue={(item) => {
          setValue('workingDistrict', item.value, { shouldDirty: true });
        }}
        // loadMoreAction={'customerList/getResidentialDistricts'}
        filterAction={'customerList/getWorkingDistricts'}
        defaultFilter={{isAll: true}}
        isError={hasError(errors, 'workingDistrict')}
      />
      <ReactHookFormErrorMessage errors={errors} id="workingDistrict" />

      <CustomRadioWithTitleAndRelated
        title={i18n.t('concierge.marital_status', { locale: language })}
        options={[
          { label: i18n.t('concierge.single', { locale: language }), value: 'Single' },
          { label: i18n.t('concierge.married', { locale: language }), value: 'Married' },
        ]}
        defaultValue={watchMaritalStatus}
        setValue={(value) => {
          setValue('maritalStatus', value, { shouldDirty: true });
        }}
      />
      <ReactHookFormErrorMessage errors={errors} id="maritalStatus" />

      <CustomTitleWithDropDown
        title={i18n.t('concierge.monthly_parking_user', { locale: language })}
        needFilter={false}
        defaultValue={{
          value: watchHouseholdIncome,
          label: watchHouseholdIncome,
        }}
        source={[
          {name: '< 10000'},
          {name: '10000 - 20000'},
          {name: '20001 - 30000'},
          {name: '30001 - 40000'},
          {name: '40001 - 50000'},
          {name: '> 50000'},
        ]}
        setValue={(item) => {
          setValue('householdIncome', item.value.name, { shouldDirty: true });
        }}
        loadMoreAction={() => {}}
      />
      <ReactHookFormErrorMessage errors={errors} id="householdIncome" />

      <CustomTitleWithSwitch
        title={i18n.t('concierge.have_children', { locale: language })}
        defaultValue={watchHaveChildren}
        setValue={(value) => {
          setValue('haveChildren', value, { shouldDirty: true });
        }}
      />

      {watchHaveChildren ? (
        <>
          <CustomTitleWithDropDown
            title={i18n.t('concierge.age_range_of_children', { locale: language })}
            needFilter={false}
            defaultValue={watchAgeOfChildren}
            source={AGE_RANGE}
            setValue={(item) => {
              setValue('ageOfChildren', item);
            }}
            loadMoreAction={() => {}}
            multiple={{
              default: watchAgeOfChildren,
            }}
          />
          <ReactHookFormErrorMessage errors={errors} id="ageOfChildren" />
        </>
      ) : null}

      <CustomTitleWithSwitch
        title={i18n.t('concierge.assign_as_test_customer', { locale: language })}
        defaultValue={watchIsAssignedAsTestingCustomer}
        setValue={(value) => {
          setValue('isAssignedAsTestingCustomer', value, { shouldDirty: true });
        }}
      />
      <ReactHookFormErrorMessage errors={errors} id='isAssignedAsTestingCustomer' />
    </div>
  );
}

export default PersonalInfoEditCard;
