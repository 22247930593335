import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ContentSections from '../../../components/base/ContentSections';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import { OnlyBackButton } from '../../../components/base/BottomStepComponent';
import { badgeRecordQueryKey } from '../../../components/pointRecord/RecordsUrlConfig';
import { BADGE_EVENT_TYPE, LanguageConfig } from '../../../config/CustomEnums';
import i18n from '../../../I18n';
import { getConciergeLanguage } from '../../conciergeApp/couponRecord/CouponTransactionRecord';


function BadgeRecordOverview({
  badgeRecord
}) {
  const queryString = require('query-string');
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const currentLocation = useLocation();

  const language = getConciergeLanguage(currentLocation);
  let isConcierge = false;
  if (language === LanguageConfig.traditionalChinese) {
    isConcierge = true;
  }

  useEffect(() => {
    dispatch({
      type: 'badgeRecord/getOneDetail',
      payload: {
        id: params.id,
      }
    });
  }, [dispatch, params.id]);

  const getBadgeDetailSectionFieldsWithType = (recordType) => {
    let extraFields = [];
    if (recordType === 'BADGE_EXPIRED') {
      extraFields = extraFields.concat([
        [{ title: i18n.t('concierge.expiry_date', { locale: language }), field: 'createDate' }],
      ]);
    }
    return [
      [{ title: i18n.t('concierge.quantity', { locale: language }), field: 'badgeQuantity' }],
      [{ title: i18n.t('concierge.remarks_optional', { locale: language }), field: isConcierge ? 'remarkTC' : 'remarkEN' }],
      ...extraFields,
    ];
  };

  const tabs = [
    {
      name: '',
      content: (
        <div className="scroll-container-common">
          <ContentSections
            sections={[
              <MembershipInfoCard
                title={i18n.t('concierge.view_badge_record_upper', { locale: language })}
                data={badgeRecord}
                fields={[
                  [{ title: i18n.t('concierge.customer', { locale: language }), field: 'name' }],
                  [
                    {
                      title: i18n.t('concierge.record_type', { locale: language }),
                      field: isConcierge ? 'displayRecordTypeTC' : 'displayRecordType',
                    },
                  ],
                ]}
              />,
              <MembershipInfoCard
                title={
                  badgeRecord.displayEventType ===
                  BADGE_EVENT_TYPE.ADD_BADGE
                  ? i18n.t('concierge.add_badge_upper', { locale: language })
                  : i18n.t('concierge.remove_badge_upper', { locale: language })
                }
                data={badgeRecord}
                fields={getBadgeDetailSectionFieldsWithType(
                  badgeRecord.recordType,
                )}
              />,
            ]}
            hidePreview={true}
            sectionStyle="transaction-record-detail-section"
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '',
          title: i18n.t('concierge.view_badge_record', { locale: language }),
        }}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
        buttons={[]}
      />
      <OnlyBackButton
        text={i18n.t('transaction.back', { locale: language })}
        backAction={() => {
          history.push({
            pathname: isConcierge ? '/concierge_badge_records' : '/badge_records',
            search: queryString.stringify({
              [badgeRecordQueryKey.page]: 1,
              [badgeRecordQueryKey.rank]: true,
              [badgeRecordQueryKey.searchKey]: '',
              [badgeRecordQueryKey.tab]: 'all',
            }),
          });
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  badgeRecord: state.badgeRecord.detail,
});

export default connect(mapPropsToState)(BadgeRecordOverview);
