import { LanguageConfig } from '../../../config/CustomEnums';
import { anchorElementWithId } from '../../../utils';
export const CampaignErrorHandleField = {
  generalName: {
    name: 'generalName',
    message: 'Please provide a campaign name and ensure the length is not more than 200',
  },
  coverPhoto: {
    name: 'coverPhoto',
    message: 'Please provide a cover photo.',
  },
  squarePhoto: {
    name: 'squarePhoto',
    message: 'Please provide an square photo.',
  },
  htmlContent: {
    name: 'htmlContent',
    message:
      'Please provide html contnet.',
  },
  shortDescription: {
    name: 'shortDescription',
    message:
      'Please provide a short description.',
  },
  customerGroup: {
    name: 'customerGroup',
    message: 'Please provide a target customer group.',
  },
  segment: {
    name: 'customerGroup',
    message: 'Please provide a target segment.',
  },
  activeEndDate: {
    name: 'activeEndDate',
    message:
      'The end date & time of a campaign cannot be on or before the start date and time.',
  },
  activeEndDateWithCoupon: {
    name: 'activeEndDate',
    message:
      'The end date & time of a campaign cannot be after the end date & time of coupon.',
  },
  visibleEndDate: {
    name: 'visibleEndDate',
    message:
      'The end date & time of a campaign cannot be on or before the start date and time.',
  },
  requiredStamps: {
    name: 'requiredStamps',
    message: 'Please provide a required/valid stamps.',
  },
  overallLimit: {
    name: 'overallLimit',
    message: 'Coupon campaign overall limit should be smaller than the number of generated coupon of given coupon template.'
  },
  perHeadLimit: {
    name: 'perHeadLimit',
    message: 'Coupon campaign overall limit must be larger than per head limit.'
  },
  fortuneBagOverallLimit: {
    name: 'overallLimit',
    message: 'Overall limit need larger than Periodic limit and Per head limit'
  },
  fortuneBagPeriodicLimit: {
    name: 'eriodicLimit',
    message: 'Period limit need larger than Per head periodic limit'
  },
  fortuneBagPerHeadLimit: {
    name: 'perHeadLimit',
    message: 'Per head limit need larger than Per head periodic limit.'
  },
  acquireCouponNumberEachTimeEmpty: {
    name: 'acquireCouponNumberEachTime',
    message: 'Fortune bag campaign number of coupons can be acquired each time should be filled with positive integer.'
  },
  acquireCouponNumberEachTime: {
    name: 'acquireCouponNumberEachTime',
    message: 'Fortune bag campaign number of coupons can be acquired each time should not be greater than the number of linked coupon sets.'
  },
  selectedCarpark:{
    name: 'selectedCarpark',
    message: 'Please selected the Carpark',
  },
  selectedMall:{
    name: 'selectedMall',
    message: 'Please selected the Mall',
  },
  positiveNumber:{
    name: 'positiveNumber',
    message: 'Please provide positive number',
  },
  requiredField: {
    name: 'required',
    message: 'This Field is required',
  },
  invitees: {
    name: 'invitees',
    message: 'Maximum number must be larger than Minimum number',
  },
  linkedEarningCampaigns: {
    name: 'linkedEarningCampaigns',
    message: 'Please select at least one earning campaign.'
  },
  linkedCouponSet: {
    name: 'fixedMissionRewards',
    message: 'Please select at least one coupon set.'
  },
  requiredBadges: {
    name: 'requiredBadges',
    message: 'Same no. of badges already exists. Please enter another number.'
  },
  couponQuantityRequired: {
    name: 'couponQuantityRequired',
    message: 'Please provide a quantity.'
  },
  couponQuantity: {
    name: 'couponQuantity',
    message: 'Exceed current coupon stock. Please generate more coupons or input a smaller number.'
  },
  numberLimit: {
    name: 'numberLimit',
    message: 'Please enter 1 to 9999'
  },
  requiredUrl: {
    name: 'requiredUrl',
    message: 'Please input a URL.'
  },
  checkList: {
    name: 'checkList',
    message: 'Please review the item and tick the checkbox.'
  },
  fileRequired: {
    name: 'fileRequired',
    message: 'Please upload a file.'
  },
  categories: {
    name: 'categories',
    message: 'please select campaign category for interest club'
  }
};

export const firstError = (id, errors, language = LanguageConfig.english) => {
  const firstError = errors[0];
  const error = id?.includes(firstError) && language === LanguageConfig.english;
  if (error) {
    setTimeout(() => {
      anchorElementWithId(id);
    }, 100);
  }
};

export const isShowError = (
  field,
  errors,
  language = LanguageConfig.english,
) => {
  const error =
    errors.indexOf(field) > -1 && language === LanguageConfig.english;
  return error;
};
