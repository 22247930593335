import { 
  LanguageConfig,
  CampaignType,
  APIStatus,
  SESSION_KEYS,
  CampaignDisplayIn,
  MissionCampaignMode,
  EarningRuleTypeKey,
  CampaignPromotionType,
  ActionType,
  PUBLISHER_TYPE,
} from '../config/CustomEnums';
import {
  createCampaign,
  publishCampaign,
  getCampaign,
  unPublishCampaign,
  updateCampaign,
  deleteCampaign,
  getOneEarningRule,
  getOneCoupon,
  updateApprovalStatus,
  CreateTempCampaign,
  rejectPendingForPublishCampaign,
  rejectPendingForApprovalCampaign,
} from '../services/CampaignAPIHelper';
import { CampaignErrorHandleField } from '../components/campaign/campaignCreation/CreateCampaignHandleError';
import { defaultStep, getNewStepConfig } from './StepBarUtil';
import {
  getFileNameFromUrl,
  saveToSessionStorage,
  removeFromSessionStorage,
  getObjectFromSessionStorage,
  createAction,
  delay,
  addDomainToImage,
  convertPKToId,
  getImageImage,
} from '../utils';
import { apiWithResponseHandle } from './LoadingUtil';
import {
  CREATE_CAMPAIGN_CATEGORY_FAILD,
  CREATE_CAMPAIGN_CATEGORY_SUCCESS,
} from './CreateCampaignCategoryModel';
import { getLoadResumeType, getTypeDisplay } from './CampaignListModel';
import { createModel } from './BaseModel';
import { IMAGE_TYPES } from './UploadFilesModel';
import { switchCouponTemplateWithEarningRule } from './EarningRulesModel';

export const sessionDataKey = {
  objectKey: SESSION_KEYS.CREATE_CAMPAIGN_SESSION_KEY,
  stepEndKey: SESSION_KEYS.CREATE_CAMPAIGN_END_SESSION_KEY,
  origionalData: SESSION_KEYS.CREATE_CAMPAIGN_ORIGIN_DATA_SESSION_KEY,
  estampObjectKey: SESSION_KEYS.CREATE_ESTAMP_CAMPAIGN_SESSION_KEY,
  emissionObjectKey: SESSION_KEYS.CREATE_EMISSION_CAMPAIGN_SESSION_KEY,
};

export const GENERAL_TYPE = {
  customerGroup: 'Customer group',
  segment: 'Segment',
  // level: 'Level',
};

export const promotionTypeList = [
  {pk: 'Shopping Centre', name: 'Mall'},
  {pk: 'Fresh Market', name: 'Fresh Market'},
  {pk: 'Carpark', name: 'Car Park'},
]
export const deliveryChannelList = [
  // {pk: 'Web', name: 'Web'},
  // {pk: 'CorpWeb', name: 'CorpWeb'},
  // {pk: 'MobileApp', name: 'MobileApp'},
  {pk: 'Web', name: 'Customer website'},
  {pk: 'eDirectory', name: 'eDirectory'},
]

export const LINK_COUPON_STRING = 'linkedCouponInfo';

// const isFollowTranslations = [
//   'isFollowCouponTemplateStores',
//   'isFollowCouponTemplateTourCard',
//   'isFollowCouponTemplateName',
//   'isFollowCouponTemplateShortDescription',
//   'isFollowCouponTemplateCoverPhoto',
//   'isFollowCouponTemplateDetailPhotos',
//   'isFollowCouponTemplateLogoLabels',
//   'isFollowCouponTemplateOfferSection',
//   'isFollowCouponTemplateInstructionSection',
//   'isFollowCouponTemplateMerchantTerms',
//   'isFollowCouponTemplateGenericTerms',
// ];

// const applyCouponToCampaign = ({
//   getValues,
//   setValue,
//   languages = [],
//   key,
// }) => {
//   const couponString = localStorage.getItem(LINK_COUPON_STRING);
//   const newCoupon = JSON.parse(couponString || '{}');
//   let couponKeyOne = '';
//   let couponKeyTwo = '';
//   switch (key) {
//     case 'isFollowCouponTemplateName':
//       couponKeyOne = 'generalName';
//       break;
//     case 'isFollowCouponTemplateShortDescription':
//       couponKeyOne = 'shortDescription';
//       break;
//     case 'isFollowCouponTemplateCoverPhoto':
//       couponKeyOne = 'coverPhoto';
//       break;
//     case 'isFollowCouponTemplateDetailPhotos':
//       couponKeyOne = 'detailPhotos';
//       break;
//     case 'isFollowCouponTemplateLogoLabels':
//       couponKeyOne = 'logoLabels';
//       break;
//     case 'isFollowCouponTemplateOfferSection':
//       couponKeyOne = 'offerDetailTitle';
//       couponKeyTwo = 'offerDetailContent';
//       break;
//     case 'isFollowCouponTemplateInstructionSection':
//       couponKeyOne = 'instructionSectionTitle';
//       couponKeyTwo = 'instructionSectionContent';
//       break;
//     case 'isFollowCouponTemplateMerchantTerms':
//       couponKeyOne = 'merchantTermsAndConditionsTitle';
//       couponKeyTwo = 'merchantTermsAndConditionsContent';

//       break;
//     case 'isFollowCouponTemplateGenericTerms':
//       couponKeyOne = 'genericTermsAndConditionsTitle';
//       couponKeyTwo = 'genericTermsAndConditionsContent';
//       break;
//     case 'isFollowCouponTemplateTourCard':
//       const tourCards = getTourCard(newCoupon.tourCards?.edges);
//       setValue('tourCards', tourCards);
//       setValue('showTourCard', newCoupon.showTourCard);
//       couponKeyOne = 'tourCardSectionTitle';
//       break;
//     default:
//       break;
//   }
//   const translations = getTranslationForCampaign(newCoupon);
//   languages.forEach((item) => {
//     const language = item.code;
//     if (!language) {
//       return;
//     }
//     if (couponKeyOne) {
//       const couponValueOne = translations?.[language]?.[couponKeyOne];
//       console.log('campaignKeyOne:', couponKeyOne, key, couponValueOne);
//       insertKeyToTranslations({
//         getValues,
//         setValue,
//         language,
//         key: couponKeyOne,
//         value: couponValueOne,
//       });
//     }

//     if (couponKeyTwo) {
//       const couponValueTwo = translations?.[language]?.[couponKeyTwo];
//       insertKeyToTranslations({
//         getValues,
//         setValue,
//         language,
//         key: couponKeyTwo,
//         value: couponValueTwo,
//       });
//     }
//   });
// };

// export const changeSwitch = ({
//   getValues,
//   setValue,
//   languages,
//   key,
//   value,
// }) => {
//   console.log('languages:', languages);
//   setValue(key, value, { shouldDirty: true });
//   if (!!value) {
//     applyCouponToCampaign({
//       getValues,
//       setValue,
//       languages,
//       key,
//     });
//   }
//   const allSwitchOn = isAllSwitchOn({ getValues });
//   console.log('allSwitchOn:', allSwitchOn);
//   setValue('isAllFollowCouponTemplate', !!allSwitchOn);
// };

// export const switchAll = ({ getValues, setValue, isOn }) => {
//   console.log('switchAll:');
//   try {
//     if (isOn) {
//       const couponString = localStorage.getItem(LINK_COUPON_STRING);
//       const newCoupon = JSON.parse(couponString);
//       transferCouponContentToCampagin({
//         newCoupon,
//         setValue,
//         getValues,
//       });
//     }
//   } catch (e) {
//     console.log('couponParseError: ', e);
//   }
//   console.log('switchAllcouponParseError: ', getValues());
//   isFollowTranslations.forEach((item) => {
//     setValue(item, isOn, {
//       shouldDirty: true,
//     });
//   });
//   setValue('isAllFollowCouponTemplate', isOn);
// };

// const isAllSwitchOn = ({ getValues }) => {
//   const switchValues = getValues(isFollowTranslations) || [];
//   console.log('switchValues:', switchValues);
//   const isAllSwitched = switchValues.every((item) => !!item);
//   return isAllSwitched;
// };

export const isShowCouponRelated = (campaignType) => {
  return campaignType === CampaignType.couponsCampaign
};

export const isShowEarningRuleRelated = (campaignType) => {
  return campaignType === CampaignType.earningRulesCampaign
}

export const isShowFortuneBagRelated = (campaignType) => {
  return campaignType === CampaignType.fortuneBag
}

export const isShowStampRelated = (campaignType) => {
  return campaignType === CampaignType.estampCampaign
}

export const isShowMissonRelated = (campaignType) => {
  return campaignType === CampaignType.emissionCampaign
}

const stepNames = ['Type', 'Content', 'Properties', 'Preview'];

export const saveDataToSessionStorage = (data = {}) => {
  Object.keys(data?.translations || {}).forEach(language => {
    let htmlContent = data?.translations?.[language]?.htmlContent
    console.log("@211", typeof htmlContent)
    // if (htmlContent && typeof htmlContent === 'object') {
    //   data.translations[language] = {
    //     ...data?.translations?.[language],
    //     htmlContent: htmlContent
    //   }
    // }
    
  })
  console.log("@217", data)
  if (data?.displayIn === CampaignDisplayIn.estamp) {
    saveToSessionStorage(sessionDataKey.estampObjectKey, data);
  } else if (data?.displayIn === CampaignDisplayIn.emission) {
    saveToSessionStorage(sessionDataKey.emissionObjectKey, data);
  } else {
    saveToSessionStorage(sessionDataKey.objectKey, data);
  }
};

export const removeDataFromSessionStorage = (displayIn = null) => {
  console.log("@229 remove data form session storage", displayIn)
  let type = sessionDataKey.objectKey
  if (displayIn === CampaignDisplayIn.estamp) {
    type = sessionDataKey.estampObjectKey
  } else if (displayIn === CampaignDisplayIn.emission) {
    type = sessionDataKey.emissionObjectKey
  }
  removeFromSessionStorage(type);
  removeFromSessionStorage(sessionDataKey.fortuneCoupons);
};

// export function fieldTransfer({ setValue, getValues }) {
//   try {
//     const couponString = localStorage.getItem(LINK_COUPON_STRING);
//     const newCoupon = JSON.parse(couponString);
//     if (!newCoupon) {
//       return;
//     }
//     if (newCoupon.brand) {
//       const brandSection = getBrandSectionForCampaign(newCoupon.brand);
//       setValue('linkedBrand', brandSection.linkedBrand);
//       setValue('brandSection', brandSection);
//     }
//     setValue('coupon_stock', newCoupon.stock || 0);
//     const switchValues = getValues(isFollowTranslations) || [];
//     const [selectedLanguages] = getValues(['selectedLanguages']) || [];
//     switchValues.forEach((item, index) => {
//       const fieldName = isFollowTranslations[index];
//       if (!!item) {
//         changeSwitch({
//           getValues,
//           setValue,
//           languages: selectedLanguages,
//           key: fieldName,
//           value: item,
//         });
//       }
//     });
//   } catch (e) {
//     console.log('fieldTransfer error: ', e);
//   }
// }

// function checkStepTwoFields(data, translations, isBack) {
//   const generalName = data[LanguageConfig.english].generalName;
//   const coverPhoto = data[LanguageConfig.english].coverPhoto;
//   const isExclusive = data.isExclusive;
//   const square = data[LanguageConfig.english].squareCoverPhoto;
//   const shortDescription = data[LanguageConfig.english].shortDescription;
//   const errorFields = [];
//   if (!generalName) {
//     errorFields.push(CampaignErrorHandleField.generalName.name);
//   }
//   if (!shortDescription || shortDescription?.length > 200) {
//     errorFields.push(CampaignErrorHandleField.shortDescription.name);
//   }
//   if (!coverPhoto) {
//     errorFields.push(CampaignErrorHandleField.coverPhoto.name);
//   }
//   if (isExclusive && !square) {
//     errorFields.push(CampaignErrorHandleField.squarePhoto.name);
//   }
//   return {
//     invalid: isBack ? false : errorFields.length > 0,
//     errorFields: isBack ? [] : errorFields,
//     data: {
//       translations: {
//         [LanguageConfig.english]: {
//           ...translations[LanguageConfig.english],
//           ...data[LanguageConfig.english],
//         },
//         [LanguageConfig.traditionalChinese]: {
//           ...translations[LanguageConfig.traditionalChinese],
//           ...data[LanguageConfig.traditionalChinese],
//         },
//         [LanguageConfig.simplifiedChinese]: {
//           ...translations[LanguageConfig.simplifiedChinese],
//           ...data[LanguageConfig.simplifiedChinese],
//         },
//       },
//     },
//   };
// }

// function checkStepThreeFields(data, isBack, isExclusive) {
//   const {
//     activeStartDate,
//     activeEndDate,
//     visibleStartDate,
//     visibleEndDate,
//     campaignType,
//     requiredPoints,
//     isAlwaysActivePeriod,
//     isAlwaysVisiblePeriod,
//     customerGroup,
//     segments,
//     generalType,
//   } = data;

//   const errorFields = [];
//   if (isExclusive) {
//     if (
//       generalType === GENERAL_TYPE.customerGroup &&
//       customerGroup?.length === 0
//     ) {
//       errorFields.push(CampaignErrorHandleField.customerGroup.name);
//     }
//     if (generalType === GENERAL_TYPE.segment && segments?.length === 0) {
//       errorFields.push(CampaignErrorHandleField.customerGroup.name);
//     }
//   }
//   if (
//     !isAlwaysActivePeriod &&
//     activeStartDate.getTime() >= activeEndDate.getTime()
//   ) {
//     errorFields.push(CampaignErrorHandleField.activeEndDate.name);
//   }
//   if (
//     !isAlwaysVisiblePeriod &&
//     visibleStartDate.getTime() >= visibleEndDate.getTime()
//   ) {
//     errorFields.push(CampaignErrorHandleField.visibleEndDate.name);
//   }
//   if (
//     campaignType === CampaignType.couponsCampaign &&
//     requiredPoints !== 0 &&
//     !requiredPoints
//   ) {
//     errorFields.push(CampaignErrorHandleField.requiredPoints.name);
//   }
//   return {
//     invalid: isBack ? false : errorFields.length > 0,
//     errorFields: isBack ? [] : errorFields,
//     data: { ...data },
//   };
// }

function getTranlationDataForCreateCampaign(campaign, language, update) {
  let languageField = {};
  const translation = campaign?.translations?.[language] || {};
  const isContentProvider = campaign?.publisherType === PUBLISHER_TYPE.contentProvider;
  if (language !== LanguageConfig.english) {
    languageField = {
      language: language,
    };
    if (translation.pk && update) {
      languageField['id'] = translation.pk;
    }
  }

  const result = {
    ...languageField,
    shortDescription: translation.shortDescription,
    name: translation.generalName,
    providerName: isContentProvider ? translation?.providerName : '',
    providerPhoto: isContentProvider ? getImageImage(translation?.providerPhoto) : null,
    instructionSectionTitle: translation.instructionSectionTitle,
    instructionSectionContent: translation.instructionSectionContent,
    detailSectionTitle: translation.detailSectionTitle,
    detailSectionContent: translation.detailSectionContent,
    coverPhoto: getImageImage(translation.coverPhoto) || null,
    coverPhotoLandingUrl: translation.coverPhoto?.linkUrl || null,
    detailPhoto1: getImageImage(translation.detailPhotos?.[0]) || null,
    detailPhoto2: getImageImage(translation.detailPhotos?.[1]) || null,
    detailPhoto3: getImageImage(translation.detailPhotos?.[2]) || null,
    detailPhoto4: getImageImage(translation.detailPhotos?.[3]) || null,
    detailPhotoLandingUrl1: translation.detailPhotos?.[0]?.linkUrl || null,
    detailPhotoLandingUrl2: translation.detailPhotos?.[1]?.linkUrl || null,
    detailPhotoLandingUrl3: translation.detailPhotos?.[2]?.linkUrl || null,
    detailPhotoLandingUrl4: translation.detailPhotos?.[3]?.linkUrl || null,
    promotionTnc: translation.promotionTnc,
    promotionPoster: JSON.stringify(translation.promotionPoster),
    promotionMagazineLink: translation.promotionMagazineLink,
    // squareCoverPhoto: getImageImage(translation.squareCoverPhoto) || null,
  };

  if (campaign.campaignType === CampaignType.htmlCampaign) {
    const htmlContent = translation.htmlContent
    // console.log("@389", htmlContent, typeof htmlContent)
    result["htmlContent"] = htmlContent
  }
  if (campaign.campaignType === CampaignType.emissionCampaign) {
    result["missionCampaignTncUrl"] = translation.missionCampaignTncUrl
    result['missionCampaignPrivacyPolicyUrl'] = translation.missionCampaignPrivacyUrl
    result["missionCampaignRedeemedGiftImage"] = campaign.uploadBadgeIcons ? getImageImage(translation.redeemedGiftBadgePhoto) || null : null
    result["missionCampaignRedeemedFinalGiftImage"] = campaign.uploadBadgeIcons ? getImageImage(translation.redeemedFinalGiftBadgePhoto) || null : null
  }
  // console.log("@297", result, campaign, campaign.campaignType === CampaignType.htmlCampaign)
  // if (translation.squareCoverPhoto) {
  //   result['squareCoverPhoto'] = getImageImage(
  //     translation.squareCoverPhoto,
  //   );
  // }

  // if (translation.detailPhotoOne) {
  //   result['detailPhoto1'] = getFileNameFromUrl(translation.detailPhotoOne);
  // }
  // if (translation.detailPhotoTwo) {
  //   result['detailPhoto2'] = getFileNameFromUrl(translation.detailPhotoTwo);
  // }
  // if (translation.detailPhotoThree) {
  //   result['detailPhoto3'] = getFileNameFromUrl(translation.detailPhotoThree);
  // }
  // if (translation.detailPhotoFour) {
  //   result['detailPhoto4'] = getFileNameFromUrl(translation.detailPhotoFour);
  // }
  return result;
}

function getEmissionDataForCreateCampaign(campaign, update) {

  const result = {
    missionCampaignMode: campaign.missionCampaignMode,
    missionCampaignParticipant: campaign.missionCampaignParticipant,
    missionCampaignMinimumNumberOfInvitees: campaign.missionCampaignMinimumNumberOfInvitees,
    missionCampaignMaximumNumberOfInvitees: campaign.missionCampaignMaximumNumberOfInvitees,
  }
  if (campaign.missionCampaignMode === MissionCampaignMode.fixedRewards) {
    result["missionCampaignTotalBadgeQuantity"] = campaign.totalBadgeQuantity
    result["missionCampaignEmptySlotBadgeImage"] = campaign.uploadBadgeIcons ? getImageImage(campaign.emptySlotBadgePhoto) || null : null
    result["missionCampaignAcquiredBadgeImage"] = campaign.uploadBadgeIcons ? getImageImage(campaign.acquiredBadgePhoto) || null : null
    result["missionCampaignEmptyGiftSlotImage"] = campaign.uploadBadgeIcons ? getImageImage(campaign.emptyGiftSlotBadgePhoto) || null : null
    result["missionCampaignCollectedGiftImage"] = campaign.uploadBadgeIcons ? getImageImage(campaign.collectedGiftBadgePhoto) || null : null
    // result["missionCampaignRedeemedGiftImage"] = getImageImage(campaign.redeemedGiftBadgePhoto) || null
    result["missionCampaignEmptyFinalGiftSlotImage"] = campaign.uploadBadgeIcons ? getImageImage(campaign.emptyFinalGiftSlotBadgePhoto) || null : null
    // result["missionCampaignRedeemedFinalGiftImage"] = getImageImage(campaign.redeemedFinalGiftBadgePhoto) || null
    result["missionCampaignCollectedFinalGiftImage"] = campaign.uploadBadgeIcons ? getImageImage(campaign.collectedFinalGiftBadgePhoto) || null : null
    result["missionTasks"] = campaign.linkedEarningCampaigns?.map((item) => {
      const result = {
        earningCampaign: item.pk,
        displayOrder: campaign.earningCampaignRelation[item.id]?.order || null,
      }
      if (update) {
        result["id"] = campaign.earningCampaignRelation[item.id]?.id || null
      }
      return result
    })
    result["missionCampaignRewards"] = campaign.fixedMissionRewards?.map((item) => {
      console.log("@465", item)
      const result = {
        couponTemplate: item.couponTemplate?.pk,
        requiredBadges: item.requiredBadges,
        couponQuantity: item.couponQuantity,
        recipient: item.recipient,
      }
      if (update) {
        result["id"] = item.id
      }
      return result
    })
  }
  
  if (campaign.missionCampaignMode === MissionCampaignMode.dynamicRewards) {
    result["missionTasks"] = campaign.linkedEarningCampaigns?.map((item) => {
      const result = {
        earningCampaign: item.pk,
        displayOrder: campaign.earningCampaignRelation[item.id]?.order || null,
        dynamicRewardReceiver: campaign.earningCampaignRelation[item.id]?.recipient || null,
      }
      if (update) {
        result["id"] = campaign.earningCampaignRelation[item.id]?.id || null
      }
      return result
    })
  }
  return result
}

function getDataForCreateCampaign(campaign, update) {
  const categories = campaign.categories?.map((category) => category.pk);
  let earningRule = {};
  let coupon = {};
  if (campaign.campaignType === CampaignType.couponsCampaign) {
    coupon = {
      couponCampaignTypeCouponTemplate: campaign.linkedCoupon.pk,
      couponCampaignTypeShouldShowCouponStock: campaign.displayOverLimit,
      couponCampaignTypeOverallLimit: campaign.overallLimit || null,
      couponCampaignTypePerHeadLimit: campaign.perHeadLimit || null,
      couponCampaignTypeRequiredStamps: campaign.requiredStamps,
      couponCampaignTypeRequiredPoints: campaign.requiredPoints || 0,
    };
  }
  let fortuneBag = {};
  if (campaign.campaignType === CampaignType.fortuneBag) {
    fortuneBag = {
      fortuneBagCampaignTypeCouponTemplates: campaign.selectedCouponSets?.map((item) => item?.value?.pk || item.pk),
      fortuneBagCampaignTypeOverallLimit: campaign.overallLimit || null,
      fortuneBagCampaignTypePeriodicLimit: campaign.periodicLimit || null,
      fortuneBagCampaignTypePeriodicLimitEffectiveNumberOfDays: campaign.periodicLimitDays || null,
      fortuneBagCampaignTypePerHeadLimit: campaign.perHeadLimit || null,
      fortuneBagCampaignTypePerHeadPeriodicLimit: campaign.perHeadPeriodicLimit || null,
      fortuneBagCampaignTypePerHeadPeriodicLimitEffectiveNumberOfDays: campaign.perHeadPeriodicLimitDays || null,
      fortuneBagCampaignTypeAcquireCouponNumberEachTime: campaign.acquireCouponNumberEachTime
    }
  }
  if (campaign.campaignType === CampaignType.earningRulesCampaign || campaign.campaignType === CampaignType.estampCampaign) {
    earningRule = {
      earningCampaignTypeEarningRule: campaign.linkedEarningRules.pk,
    };
  }
  const formattedTranslation = getTranlationDataForCreateCampaign(
    campaign,
    LanguageConfig.english,
    update,
  );

  const otherTranslations = [];
  Object.keys(campaign?.translations || {}).forEach(language => {
    if (language === LanguageConfig.english) {
      return;
    }
    otherTranslations.push(getTranlationDataForCreateCampaign(
      campaign,
      language,
      update,
    ))
  })

  let emissionTypeData = {}
  if (campaign.campaignType === CampaignType.emissionCampaign) {
    emissionTypeData = getEmissionDataForCreateCampaign(campaign, update)
  }
  console.log("@523", emissionTypeData)

  const promotionData = {
    enablePromotion: campaign.enablePromotion,
    promotionType: JSON.stringify(campaign.promotionType?.map((item) => item.pk)),
    promotionContact: campaign.promotionContact,
    lastYearPromotionId: campaign.lastYearPromotionId,
    deliveryChannel: JSON.stringify(campaign.deliveryChannel?.map((item) => item.pk)),
  }

  const result = {
    type: campaign.campaignType,
    displayIn: campaign.displayIn,
    categories: categories,
    isFeatured: campaign.isFeatured || false,
    isExclusive: campaign.isExclusive || false,
    publisherType: campaign?.publisherType,
    brand: campaign?.publisherType === PUBLISHER_TYPE.brand ? campaign.selectedBrand?.map((item) => item?.value?.pk || item.pk) : [],
    // stores: campaign.linkedStores?.map((item) => item.pk),
    applyCoverPhotoToAllLanguage: campaign.applyCoverPhotoToAllLanguage,
    applyDetailPhotosToAllLanguage: campaign.applyDetailPhotosToAllLanguage,
    tags: campaign.campaignTags?.map((item) => item.pk),
    isShowInMall: campaign.isShowInMall,
    malls: campaign.isShowInMall ? campaign.selectedMall?.map((item) => item.pk) : [],
    stores: [...(campaign?.selectMallStores ?? []).map((item) => item.pk), ...(campaign?.selectFreshMarketStores ?? []).map((item) => item.pk)],
    relatedStores: [...(campaign?.selectRelatedMallStores ?? []).map((item) => item.pk), ...(campaign?.selectRelatedFreshMarketStores ?? []).map((item) => item.pk)],
    relatedCampaigns: (campaign?.selectedRelatedCampaign ?? []).map((item) => item.pk),
    blackoutPeriod: (campaign.activePeriodValues ?? []).map((item) => update ? item : {startDate: item.startDate, endDate: item.endDate}),
    blackoutWeekday: campaign.activeWeekday,
    // promotionType: campaign.campaignPromotionType,
    // applySquareCoverPhotoToAllLanguage: campaign.applySquareCoverPhotoToAllLanguage,
    translations: otherTranslations,
    ...formattedTranslation,
    ...coupon,
    ...fortuneBag,
    ...earningRule,
    ...emissionTypeData,
    ...promotionData,
  };
  const generalType = campaign.generalType;
  let targetGroup = [];
  let segments = [];
  if (campaign.isExclusive && campaign.generalType) {
    const groupLength = campaign.customerGroup?.length;
    const segmentsLength = campaign.segments?.length;
    if (generalType === GENERAL_TYPE.customerGroup && groupLength) {
      targetGroup = campaign.customerGroup?.map((group) => group.pk);
    } else if (generalType === GENERAL_TYPE.segment && segmentsLength) {
      segments = campaign.segments?.map((segment) => segment.pk);
    }
  }
  result['targetedCustomerGroups'] = targetGroup
  result['targetedSegments'] = segments
  if (!campaign.isAlwaysActivePeriod) {
    result['endDate'] = campaign.activeEndDate;
    result['startDate'] = campaign.activeStartDate;
  } else {
    result['endDate'] = null;
    result['startDate'] = campaign.creationDate || new Date();
  }
  if (!campaign.isAlwaysVisiblePeriod) {
    result['displayEndDate'] = campaign.visibleEndDate;
    result['displayStartDate'] = campaign.visibleStartDate;
  } else {
    result['displayEndDate'] = null;
    result['displayStartDate'] = campaign.creationDate || new Date();
  }

  if (campaign.campaignType === CampaignType.estampCampaign) {
    result['stampCampaignTypePerHeadOwnedStampLimit'] = parseInt(campaign.ownedCouponLimit) || null;
    result['stampCampaignTypeLinkCouponCampaigns'] = campaign.linkedCouponCampaigns?.map((item) => item.pk)
    result['stampCampaignTypeEmptySlotIcon'] = campaign.uploadStampIcons ? getImageImage(campaign.emptyStampPhoto) || null : null
    result['stampCampaignTypeAcquiredStampIcon'] = campaign.uploadStampIcons ? getImageImage(campaign.acquiredStampPhoto) || null : null
  }
  return result;
}

function getBrandSectionForCampaign(brandSection) {
  if (!brandSection) {
    return { brandSection: [], linkedBrand: [] };
  }

  return {
    brandSection: brandSection?.edges?.map((item) => {console.log(item); return {id: item.node.id, pk: item.node.pk}}),
    selectedBrand: brandSection?.edges?.map((item) => {return {
      value: {id: item.id, pk: item.node.pk, name: item.node.name},
      label:`[${item.node.pk}] ${item.node.name}`,
      id: item.id, pk: item.node.pk, name: item.node.name
    }}),
  };
}

function getSelectedCouponSetsSectionForCampaign(selectedCouponSetsSection) {
  if (!selectedCouponSetsSection) {
    return { selectedCouponSetsSection: [], selectedCouponSets: [] };
  }

  return {
    selectedCouponSetsSection: selectedCouponSetsSection?.edges?.map((item) => {console.log(item); return {id: item.node.id, pk: item.node.pk}}),
    selectedCouponSets: selectedCouponSetsSection?.edges?.map((item) => {return {
      value: {id: item.node.id, pk: item.node.pk, name: item.node.name, stock: item.node.stock},
      label:`[ID:${item.node.pk}] ${item.node.name}`,
      id: item.node.id, pk: item.node.pk, name: item.node.name
    }}),
  };
}

function getCouponAndEarningRuleForCampaign(couponSection) {
  if (!couponSection) {
    return {};
  }
  return {
    id: couponSection.id,
    pk: couponSection.pk,
    name: couponSection.name,
    absoluteExpiryDate: couponSection.absoluteExpiryDate,
    totalNubmerOfGeneratedCoupons: couponSection.totalNubmerOfGeneratedCoupons,
    couponTemplate: switchCouponTemplateWithEarningRule(couponSection),
  };
}

function getCategoriesAndTargetGroupForCampaign(section) {
  if (!section.edges.length) {
    return [];
  }
  const targetGroup = section.edges.map((edge) => {
    const target = edge.node;
    return target
    // return {
    //   name: target.name,
    //   value: { ...target },
    // };
  });
  return targetGroup;
}

const getDetailPhotos = (node) => {
  const photos = [];
  if (node.detailPhoto1) {
    photos.push({
      value: addDomainToImage(node.detailPhoto1),
      linkUrl: node.detailPhotoLandingUrl1,
      type: IMAGE_TYPES.TYPE_URL,
    });
  }
  if (node.detailPhoto2) {
    photos.push({
      value: addDomainToImage(node.detailPhoto2),
      linkUrl: node.detailPhotoLandingUrl2,
      type: IMAGE_TYPES.TYPE_URL,
    });
  }

  if (node.detailPhoto3) {
    photos.push({
      value: addDomainToImage(node.detailPhoto3),
      linkUrl: node.detailPhotoLandingUrl3,
      type: IMAGE_TYPES.TYPE_URL,
    });
  }
  if (node.detailPhoto4) {
    photos.push({
      value: addDomainToImage(node.detailPhoto4),
      linkUrl: node.detailPhotoLandingUrl4,
      type: IMAGE_TYPES.TYPE_URL,
    });
  }
  return photos;
};

function translationAssemble(node, isCampaign) {
  if (!node) {
    return {};
  }
  const language = node.language || LanguageConfig.english;
  const data = {
    instructionSectionTitle: node.instructionSectionTitle,
    instructionSectionContent: node.instructionSectionContent,
    detailSectionTitle: node.detailSectionTitle,
    detailSectionContent: node.detailSectionContent,
    coverPhoto: node.coverPhoto ? {
      value:addDomainToImage(node.coverPhoto),
      linkUrl: node.coverPhotoLandingUrl,
      type: IMAGE_TYPES.TYPE_URL,
    } : null,
    detailPhotos: getDetailPhotos(node),
    htmlContent: node.htmlContent,
    missionCampaignTncUrl: node.missionCampaignTncUrl,
    missionCampaignPrivacyUrl: node.missionCampaignPrivacyPolicyUrl,
    promotionTnc: node.promotionTnc,
    promotionPoster: JSON.parse(node.promotionPoster) || [''],
    promotionMagazineLink: node.promotionMagazineLink,
    redeemedGiftBadgePhoto: addDomainToImage(node.missionCampaignRedeemedGiftImage),
    redeemedFinalGiftBadgePhoto: addDomainToImage(node.missionCampaignRedeemedFinalGiftImage),
  };
  if (isCampaign) {
    data.generalName = node.name;
    data.id = node.id;
    data.pk = node.pk;
    data.shortDescription = node.shortDescription;
    data.providerName = node?.providerName;
    data.providerPhoto = addDomainToImage(node?.providerPhoto);
    // data.squareCoverPhoto = addDomainToImage(node.squareCoverPhoto);
  }
  return {
    [language]: data,
  };
}

function getTranslationForCampaign(campaign, isCampaign) {
  const enTranslation = translationAssemble(campaign, isCampaign);
  const chineseTranslation = campaign?.translations?.edges.map((item) => {
    return translationAssemble(item.node, isCampaign);
  });
  const reducedTranslation = chineseTranslation?.reduce(function (obj, item) {
    return {
      ...obj,
      ...item,
    };
  }, {});
  return {
    ...enTranslation,
    ...reducedTranslation,
  };
}

function getMillisecondsFromDate(date) {
  if (!date) {
    return '';
  }
  const momentTime = new Date(date);
  return momentTime;
}

function assembleCampaign(campaignData) {
  if (!campaignData) {
    return {};
  }
  campaignData.brandSection = campaignData.brand;
  campaignData.selectedCouponSetsSection = campaignData.fortuneBagCampaignTypeCouponTemplates;

  const linkedCoupon = getCouponAndEarningRuleForCampaign(
    campaignData.couponCampaignTypeCouponTemplate,
  );
  const linkedEarningRules = {
    ...campaignData.earningCampaignTypeEarningRule,
    ...getCouponAndEarningRuleForCampaign(
      campaignData.earningCampaignTypeEarningRule,
    ),
  };
  const categories = getCategoriesAndTargetGroupForCampaign(
    campaignData.categories,
  );
  const targetGroup = getCategoriesAndTargetGroupForCampaign(
    campaignData.targetedCustomerGroups,
  );
  const segments = getCategoriesAndTargetGroupForCampaign(
    campaignData.targetedSegments,
  );
  const translations = getTranslationForCampaign(campaignData, true);
  const brandSection = getBrandSectionForCampaign(campaignData.brandSection);
  const selectedCouponSetsSection = getSelectedCouponSetsSectionForCampaign(campaignData.selectedCouponSetsSection)
  const startTime = getMillisecondsFromDate(campaignData.startDate);
  const endTime = getMillisecondsFromDate(campaignData.endDate);
  const visibleStartTime = getMillisecondsFromDate(
    campaignData.displayStartDate,
  );
  const visibleEndTime = getMillisecondsFromDate(campaignData.displayEndDate);
  const publicationTime = getMillisecondsFromDate(campaignData.publicationDate);
  let generalType = GENERAL_TYPE.customerGroup;
  if (segments && segments.length > 0) {
    generalType = GENERAL_TYPE.segment;
  }
  const emptyStampPhoto = campaignData.stampCampaignTypeEmptySlotIcon ? {
    value: addDomainToImage(campaignData.stampCampaignTypeEmptySlotIcon),
    type: IMAGE_TYPES.TYPE_URL,
  } : null
  const acquiredStampPhoto = campaignData.stampCampaignTypeAcquiredStampIcon ? {
    value: addDomainToImage(campaignData.stampCampaignTypeAcquiredStampIcon),
    type: IMAGE_TYPES.TYPE_URL,
  } : null

  let emissionTypeData = {}
  if (campaignData.type === CampaignType.emissionCampaign) {
    let earningCampaignRelation = {}
    const missionTask = campaignData.earningCampaignRelation?.edges?.map((item) => {
      earningCampaignRelation[item.node?.earningCampaign?.id] = {
        order: item.node?.displayOrder,
        recipient: item.node?.dynamicRewardReceiver,
      }
    })
    emissionTypeData = {
      missionCampaignMode: campaignData.missionCampaignMode,
      missionCampaignParticipant: campaignData.missionCampaignParticipant,
      missionCampaignMinimumNumberOfInvitees: campaignData.missionCampaignMinimumNumberOfInvitees,
      missionCampaignMaximumNumberOfInvitees: campaignData.missionCampaignMaximumNumberOfInvitees,
      totalBadgeQuantity: campaignData.missionCampaignTotalBadgeQuantity,
      linkedEarningCampaigns: campaignData.earningCampaignRelation?.edges?.map((item) => {
        const earningCampaign = item?.node?.earningCampaign
        const rewardType = `${EarningRuleTypeKey[earningCampaign?.earningCampaignTypeEarningRule?.type]}TypeCouponRewardTypeCouponTemplate`
        const rewardCoupon = earningCampaign?.earningCampaignTypeEarningRule?.[rewardType]
        return {
          ...earningCampaign,
          rewardCoupon
        }}),
      earningCampaignRelation,
      fixedMissionRewards: campaignData.linkedRewards?.edges?.map((item) => item.node),
      emptySlotBadgePhoto: addDomainToImage(campaignData.missionCampaignEmptySlotBadgeImage),
      acquiredBadgePhoto: addDomainToImage(campaignData.missionCampaignAcquiredBadgeImage),
      emptyGiftSlotBadgePhoto: addDomainToImage(campaignData.missionCampaignEmptyGiftSlotImage),
      collectedGiftBadgePhoto: addDomainToImage(campaignData.missionCampaignCollectedGiftImage),
      // redeemedGiftBadgePhoto: addDomainToImage(campaignData.missionCampaignRedeemedGiftImage),
      emptyFinalGiftSlotBadgePhoto: addDomainToImage(campaignData.missionCampaignEmptyFinalGiftSlotImage),
      // redeemedFinalGiftBadgePhoto: addDomainToImage(campaignData.missionCampaignRedeemedFinalGiftImage),
      collectedFinalGiftBadgePhoto: addDomainToImage(campaignData.missionCampaignCollectedFinalGiftImage),
      
    }
  }
  console.log("@523", campaignData)
  const applicationStores = campaignData.stores?.edges?.map((item) => item.node)
  const relatedStores = campaignData.relatedStores?.edges?.map((item) => item.node)
  return {
    id: campaignData.id,
    pk: campaignData.pk,
    publisherType: campaignData.publisherType,
    creationDate: campaignData.creationDate,
    displayIn: campaignData.displayIn,
    campaignType: campaignData.type,
    isExclusive: campaignData.isExclusive || false,
    isFeatured: campaignData.isFeatured || false,
    isAlwaysActivePeriod: !endTime,
    activeStartDate: startTime,
    activeEndDate: endTime || startTime,
    isAlwaysVisiblePeriod: !visibleEndTime,
    activePeriodValues: campaignData.blackoutPeriod?.edges?.map((item) => {
      return {
        startDate: item.node.startDate,
        endDate: item.node.endDate,
        id: item.node.pk,
      };
    }),
    activeWeekday: campaignData.blackoutWeekday,
    visibleStartDate: visibleStartTime,
    visibleEndDate: visibleEndTime || visibleStartTime,
    publicationDate: publicationTime,
    overallLimit: campaignData.type === CampaignType.fortuneBag 
      ? campaignData.fortuneBagCampaignTypeOverallLimit :campaignData.couponCampaignTypeOverallLimit,
    perHeadLimit: campaignData.type === CampaignType.fortuneBag 
      ? campaignData.fortuneBagCampaignTypePerHeadLimit : campaignData.couponCampaignTypePerHeadLimit,
    requiredPoints: campaignData.couponCampaignTypeRequiredPoints,
    requiredStamps: campaignData.couponCampaignTypeRequiredStamps ,
    displayOverLimit:
      campaignData.couponCampaignTypeShouldShowCouponStock || false,
    approvalStatus: campaignData.approvalStatus,
    // isPublished: campaignData.isPublished,
    // linkedBrand: brandSection.linkedBrand,
    selectedBrand: brandSection.selectedBrand,
    brandSection: brandSection,
    linkedStores: campaignData.stores?.edges?.map((item) => item.node),
    linkedEarningRules,
    linkedCoupon,
    categories,
    campaignTags: campaignData.tags?.edges?.map((item) => item.node),
    isShowInMall: campaignData.isShowInMall || false,
    selectedMall: campaignData.malls?.edges?.map((item) => item.node),
    selectedRelatedCampaign: campaignData.relatedCampaigns?.edges?.map((item) => item.node),
    selectMallStores: applicationStores.filter((store) => store.propertyType === "MALL_STORE"),
    selectFreshMarketStores: applicationStores.filter((store) => store.propertyType === "FRESH_MARKET_STORE"),
    selectRelatedMallStores: relatedStores.filter((store) => store.propertyType === "MALL_STORE"),
    selectRelatedFreshMarketStores: relatedStores.filter((store) => store.propertyType === "FRESH_MARKET_STORE"),
    periodicLimit: campaignData.fortuneBagCampaignTypePeriodicLimit,
    periodicLimitDays: campaignData.fortuneBagCampaignTypePeriodicLimitEffectiveNumberOfDays,
    perHeadPeriodicLimit: campaignData.fortuneBagCampaignTypePerHeadPeriodicLimit,
    perHeadPeriodicLimitDays: campaignData.fortuneBagCampaignTypePerHeadPeriodicLimitEffectiveNumberOfDays,
    acquireCouponNumberEachTime: campaignData.fortuneBagCampaignTypeAcquireCouponNumberEachTime,
    selectedCouponSets: selectedCouponSetsSection.selectedCouponSets,
    selectedCouponSetsSection: selectedCouponSetsSection,
    customerGroup: targetGroup || [],
    translations,
    generalType,
    segments: segments || [],
    typeDisplay: getTypeDisplay(campaignData.type),
    order: campaignData.displayPriority,
    applyCoverPhotoToAllLanguage: campaignData.applyCoverPhotoToAllLanguage,
    applyDetailPhotosToAllLanguage: campaignData.applyDetailPhotosToAllLanguage,
    ownedCouponLimit: campaignData.stampCampaignTypePerHeadOwnedStampLimit,
    linkedCouponCampaigns: campaignData.stampCampaignTypeLinkCouponCampaigns?.edges?.map((item) => item.node),
    emptyStampPhoto,
    acquiredStampPhoto,
    uploadStampIcons: campaignData.stampCampaignTypeEmptySlotIcon ? true : false,
    uploadBadgeIcons: campaignData.missionCampaignEmptySlotBadgeImage ? true : false,
    ...emissionTypeData,
    enablePromotion: campaignData.enablePromotion,
    promotionType: promotionTypeList.filter(({ pk }) => JSON.parse(campaignData.promotionType)?.includes(pk)),
    promotionContact: campaignData.promotionContact,
    lastYearPromotionId: campaignData.lastYearPromotionId,
    deliveryChannel: deliveryChannelList.filter(({ pk }) => JSON.parse(campaignData.deliveryChannel)?.includes(pk)),
    missionCampaignHasStarted: campaignData.missionCampaignHasStarted,
    isTempFortuneBag: campaignData.isTempFortuneBag,
    numberOfLikes: campaignData.numberOfLikes,
    numberOfBookmarks: campaignData.numberOfBookmarks,
    // campaignPromotionType: campaignData.promotionType,
    // applySquareCoverPhotoToAllLanguage: campaignData.applySquareCoverPhotoToAllLanguage,
  };
}

function getTranlation() {
  return {
    generalName: '',
    shortDescription: '',
    coverPhoto: '',
    // squareCoverPhoto: '',
    detailPhotoOne: '',
    detailPhotoTwo: '',
    detailPhotoThree: '',
    detailPhotoFour: '',
    instructionSectionTitle: '',
    instructionSectionContent: '',
    detailSectionTitle: '',
    detailSectionContent: '',
  };
}

const campaignInit = () => ({
  id: null,
  pk: null,
  applyCoverPhotoToAllLanguage: false,
  applyDetailPhotosToAllLanguage: false,
  // applySquareCoverPhotoToAllLanguage: false,
  isExclusive: false,
  creationDate: null,
  isFeatured: false,
  campaignType: '',
  isAlwaysActivePeriod: false,
  activeStartDate: null,
  activeEndDate: null,
  isAlwaysVisiblePeriod: false,
  visibleStartDate: null,
  visibleEndDate: null,
  overallLimit: null,
  perHeadLimit: null,
  requiredPoints: 0,
  requiredStamps: null,
  displayOverLimit: false,
  linkedCoupon: null,
  linkedEarningRules: null,
  selectedCouponSets: null,
  // linkedBrand: null,
  order: null,
  typeDisplay: null,
  categories: [],
  customerGroup: [],
  segments: [],
  generalType: GENERAL_TYPE.customerGroup,
  translations: {},
  fixedMissionRewards: [{}]
});

const getInitialState = () => ({
  campaign: campaignInit(),
  hasUpdatedDefaultValues: false,
  formHasSubmitted: false,
  errorFields: [],
  stepConfig: defaultStep(stepNames),
  currentStep: 0,
  languageTag: LanguageConfig.english,
  createStatus: APIStatus.none,
  campaignPublish: APIStatus.none,
});

// function saveDataToSessions(oldCampaign) {
//   const localCampaign = getObjectFromSessionStorage(sessionDataKey.objectKey);

//   const localEn = localCampaign?.translations?.[LanguageConfig.english] || {};
//   const localTraditional =
//     localCampaign?.translations?.[LanguageConfig.traditionalChinese] || {};
//   const localSimplified =
//     localCampaign?.translations?.[LanguageConfig.simplifiedChinese] || {};

//   const finalCampaign = {
//     ...localCampaign,
//     ...oldCampaign,
//     translations: {
//       [LanguageConfig.english]: {
//         ...localEn,
//         ...oldCampaign.translations[LanguageConfig.english],
//       },
//       [LanguageConfig.traditionalChinese]: {
//         ...localTraditional,
//         ...oldCampaign.translations[LanguageConfig.traditionalChinese],
//       },
//       [LanguageConfig.simplifiedChinese]: {
//         ...localSimplified,
//         ...oldCampaign.translations[LanguageConfig.simplifiedChinese],
//       },
//     },
//   };
//   saveToSessionStorage(sessionDataKey.objectKey, finalCampaign);
// }

const parseCouponTranslationsForCampaign = (couponTemplate) => {
  const translations = {};
  translations[LanguageConfig.english] = {
    generalName: couponTemplate.name,
    shortDescription: couponTemplate.shortDescription,
    coverPhoto: couponTemplate.coverPhoto ? {
      value: addDomainToImage(couponTemplate.coverPhoto),
      linkUrl: couponTemplate.coverPhotoLandingUrl,
      type: IMAGE_TYPES.TYPE_URL,
    } : null,
    detailPhotos: getDetailPhotos(couponTemplate),
    instructionSectionTitle: couponTemplate.instructionSectionTitle,
    instructionSectionContent: couponTemplate.instructionSectionContent,
    detailSectionTitle: couponTemplate.detailSectionTitle,
    detailSectionContent: couponTemplate.detailSectionContent,
  };
  couponTemplate.translations.edges.forEach((item) => {
    let language = item.node.language;
    translations[language] = {
      language: item.node.language,
      generalName: item.node.name,
      shortDescription: item.node.shortDescription,
      coverPhoto: item.node.coverPhoto ? {
        value: addDomainToImage(item.node.coverPhoto),
        linkUrl: item.node.coverPhotoLandingUrl,
        type: IMAGE_TYPES.TYPE_URL,
      } : null,
      detailPhotos: getDetailPhotos(item.node),
      instructionSectionTitle: item.node.instructionSectionTitle,
      instructionSectionContent: item.node.instructionSectionContent,
      detailSectionTitle: item.node.detailSectionTitle,
      detailSectionContent: item.node.detailSectionContent,
    };
  });
  return translations;
};

export default createModel({
  namespace: 'createCampaign',
  state: getInitialState(),

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },

    updateCampaign(state, { payload }) {
      console.log("@722 update campaign", payload)
      const campaign = { ...state.campaign, ...payload };
      // saveToSessionStorage(sessionDataKey.objectKey, campaign);
      // saveDataToSessions(campaign);
      return { ...state, campaign: campaign };
    },

    assembleCampaign(state, { payload }) {
      const campaignData = assembleCampaign(payload.campaign);
      const linkedCoupon = campaignData.linkedCoupon || {};
      const linkedEarningRules = campaignData.linkedEarningRules || {};
      const promptShowed =
        Object.keys(linkedCoupon).length > 0 ||
        Object.keys(linkedEarningRules).length > 0;
      const campaign = {
        ...state.campaign,
        ...campaignData,
        translations: {
          ...state.campaign?.translations,
          ...campaignData.translations,
        },
      };
      // saveToSessionStorage(sessionDataKey.origionalData, campaign);
      // saveToSessionStorage(sessionDataKey.objectKey, campaign);
      // saveDataToSessions(campaign);
      return {
        ...state,
        promptShowed,
        campaign: campaign,
        hasUpdatedDefaultValues: true,
      };
    },

    loadCampaignFromCookie(state, { payload }) {
      const [loadType, resumeType] = getLoadResumeType(payload?.displayIn)
      const campaign = payload.resume ? getObjectFromSessionStorage(loadType) || campaignInit() : campaignInit();
      // console.log("791", campaign)
      // if (!campaign) {
      //   return {
      //     ...state,
      //   };
      // }
      if (campaign?.pk) {
        removeDataFromSessionStorage(payload?.displayIn);
      }
      const linkedCoupon = campaign.linkedCoupon || {};
      const linkedEarningRules = campaign.linkedEarningRules || {};
      const promptShowed =
        Object.keys(linkedCoupon).length > 0 ||
        Object.keys(linkedEarningRules).length > 0;
      // saveToSessionStorage(sessionDataKey.origionalData, campaign);
      // saveToSessionStorage(sessionDataKey.objectKey, campaign);
      // saveDataToSessions(campaign);
      console.log("@815", promptShowed, payload)
      return {
        ...state,
        promptShowed: promptShowed,
        campaign: {
          ...campaign,
          ...payload.createType,
        },
        hasUpdatedDefaultValues: true,
      };
    },

    // saveOrRemoveCampaignFromCookie(state, { payload }) {
    //   if (!payload) {
    //     removeFromSessionStorage(sessionDataKey.objectKey);
    //   }
    //   saveToSessionStorage(sessionDataKey.stepEndKey, true);
    //   return {
    //     ...state,
    //   };
    // },

    stepChange(state, { payload }) {
      const isBack = payload.isBack;
      const data = payload.data;
      let step = payload.step;
      const isValid = payload.isValid;
      let result = { invalid: false, errorFields: [], data: {} };
      // if (step === 1) {
      //   data['isExclusive'] = state.campaign.isExclusive;
      //   result = checkStepTwoFields(data, state.campaign.translations, isBack);
      // }
      // if (step === 2) {
      //   data['campaignType'] = state.campaign.campaignType;
      //   result = checkStepThreeFields(data, isBack, state.campaign.isExclusive);
      // }
      // const errorFields = result.errorFields;
      const stepConfig = getNewStepConfig(
        step,
        state.stepConfig,
        !isValid,
        isBack,
      );

      if (isValid) {
        step = isBack ? step - 1 : step + 1;
      }
      // saveDataToSessions({
      //   ...state.campaign,
      //   ...result.data,
      // });
      return {
        ...state,
        currentStep: step,
        stepConfig,
        // errorFields: errorFields,
        createStatus: APIStatus.none,
        // campaign: {
        //   ...state.campaign,
        //   ...result.data,
        // },
      };
    },
    clearData(state, { payload }) {
      if (payload?.deleteSession) {
        removeDataFromSessionStorage(payload?.displayIn);
      }
      localStorage.removeItem(LINK_COUPON_STRING);
      console.log("@873", payload)
      return { ...state, ...getInitialState() };
    },

    assembleCampaignWithCoupon(state, { payload }) {
      const linkedCoupon = payload.linkedCoupon;
      return {
        ...state,
        hasUpdatedDefaultValues: true,
        campaign: {
          ...state.campaign,
          linkedCoupon,
          campaignType: payload?.campaignType,
          displayIn: payload?.displayIn,
          activeEndDate: linkedCoupon?.absoluteExpiryDate,
          selectedBrand: linkedCoupon.selectedDisplayBrand,
          applyCoverPhotoToAllLanguage: linkedCoupon.applyCoverPhotoToAllLanguage,
          applyDetailPhotosToAllLanguage: linkedCoupon.applyDetailPhotosToAllLanguage,
          translations: {
            ...state.campaign?.translations,
            ...linkedCoupon?.translations
          },
        },
      };
    }

    // linkedUpdate(state, { payload }) {
    //   const translations = getTranslationForCampaign(payload.data);
    //   const campaign = {
    //     ...state.campaign,
    //     translations: {
    //       [LanguageConfig.english]: {
    //         ...(state.campaign.translations[LanguageConfig.english] || {}),
    //         ...(translations[LanguageConfig.english] || {}),
    //       },
    //       [LanguageConfig.simplifiedChinese]: {
    //         ...(state.campaign.translations[LanguageConfig.simplifiedChinese] ||
    //           {}),
    //         ...(translations[LanguageConfig.simplifiedChinese] || {}),
    //       },
    //       [LanguageConfig.traditionalChinese]: {
    //         ...(state.campaign.translations[
    //           LanguageConfig.traditionalChinese
    //         ] || {}),
    //         ...(translations[LanguageConfig.traditionalChinese] || {}),
    //       },
    //     },
    //   };
    //   saveToSessionStorage(sessionDataKey.objectKey, campaign);
    //   saveDataToSessions(campaign);
    //   return {
    //     ...state,
    //     campaign: campaign,
    //   };
    // },

    // clearData(state, { payload }) {
    //   if (payload?.deleteSession) {
    //     removeFromSessionStorage(sessionDataKey.objectKey);
    //   }
    //   removeFromSessionStorage(CampaignType.couponsCampaign);
    //   removeFromSessionStorage(CampaignType.earningRulesCampaign);
    //   removeFromSessionStorage(CampaignType.generalMessageCampaign);
    //   return { ...state, ...getInitialState() };
    // },
  },

  effects: {
    // *setFieldToSession({ payload }, { select }) {
    //   const oldCampaign = yield select(
    //     (state) => state.createCampaign.campaign,
    //   );
    //   const language = payload.language;
    //   let campaign = {};
    //   if (language) {
    //     delete payload.language;
    //     campaign = {
    //       ...oldCampaign,
    //       translations: {
    //         ...oldCampaign.translations,
    //         [language]: {
    //           ...oldCampaign.translations[language],
    //           ...payload,
    //         },
    //       },
    //     };
    //   } else {
    //     campaign = { ...oldCampaign, ...payload };
    //   }

    //   saveToSessionStorage(sessionDataKey.objectKey, campaign);
    //   saveDataToSessions(campaign);
    // },

    // *typeChange({ payload }, { select, put }) {
    //   const stateCampaign = yield select(
    //     (state) => state.createCampaign.campaign,
    //   );
    //   const campaignType = payload.campaignType;
    //   yield put({
    //     type: 'updateCampaign',
    //     payload: {
    //       campaignType,
    //       linkedEarningRules: {
    //         ...stateCampaign?.linkedEarningRules,
    //         type: '',
    //       },
    //     },
    //   });
    //   const linkedCoupon = stateCampaign.linkedCoupon;
    //   const linkedEarningRules = stateCampaign.linkedEarningRules;
    //   const couponId = linkedCoupon?.pk;
    //   const earningRuleId = linkedEarningRules?.pk;
    //   let name = '';
    //   let actionName = '';
    //   if (campaignType === CampaignType.couponsCampaign && couponId) {
    //     name = linkedCoupon?.name;
    //     actionName = 'getAndLinkCouponDetail';
    //   }
    //   if (campaignType === CampaignType.earningRulesCampaign && earningRuleId) {
    //     name = linkedEarningRules?.name;
    //     actionName = 'getAndLinkEarningRuleDetail';
    //   }
    //   if (actionName) {
    //     yield put({
    //       type: actionName,
    //       payload: { name, couponId, earningRuleId },
    //     });
    //   }
    // },

    // publishOrUnpublishCamapaign: [
    //   function* ({ payload }, { call, select, put }) {
    //     yield put({
    //       type: 'updateState',
    //       payload: { createStatus: APIStatus.calling },
    //     });
    //     saveToSessionStorage(sessionDataKey.stepEndKey, true);
    //     let { campaignPk, isPublished } = yield select((state) => ({
    //       campaignPk: state.createCampaign.campaign.pk,
    //       isPublished: state.createCampaign.campaign.isPublished,
    //     }));

    //     if (campaignPk) {
    //       yield put.resolve({
    //         type: 'updateCampaignFields',
    //         payload,
    //       });
    //     } else {
    //       yield put.resolve({
    //         type: 'createOneCampaign',
    //         payload,
    //       });
    //     }
    //     campaignPk = yield select((state) => state.createCampaign.campaign.pk);
    //     const apiError = yield select((state) => state.createCampaign.apiError);
    //     if (apiError) {
    //       yield put({
    //         type: 'updateState',
    //         payload: { createStatus: APIStatus.failed },
    //       });
    //       return;
    //     }
    //     isPublished
    //       ? yield put({
    //           type: 'unPublishCampaign',
    //           payload: { ...payload, campaignPk },
    //         })
    //       : yield put({
    //           type: 'publishCampaign',
    //           payload: { ...payload, campaignPk },
    //         });
    //   },
    //   { type: 'takeLatest' },
    // ],

    publishCampaign: [
      function* ({ payload }, { all, select, put }) {
        const afterAction = payload.afterAction || (() => {});
        const campaignPk = payload.campaignPk;
        let serviceArgs = [unPublishCampaign, { id: campaignPk }];
        console.log("@1038", payload);
        if (!payload.isPublished) {
          serviceArgs = [publishCampaign, {id: campaignPk, otaAllocationNum: payload.otaAllocatedNum}]
        }
        // const serviceArgs = [
        //   publishCampaign,
        //   {
        //     id: campaignPk,
        //   },
        // ];
        function* onSuccess(data) {
          yield all([
            put({
              type: 'updateState',
              payload: {
                createStatus: APIStatus.success,
                campaignPublish: !payload.isPublished
                ? APIStatus.success
                : APIStatus.none,
              }
            }),
            put({
              type: 'updateCampaign',
              payload: { isPublished: payload.isPublished, notSave: true },
            }),
          ]);
          afterAction();
        }

        function* onError(response) {
          yield put({
            type: 'updateState',
            payload: {
              createStatus: APIStatus.failed,
              campaignPublish: APIStatus.failed,
            },
          });
          afterAction();
        }

        yield apiWithResponseHandle(
          serviceArgs,
          onSuccess,
          onError,
          onError,
        );
      },
    ],

    updateApprovalStatus: [
      function* ({ payload }, { all, select, put }) {
        const afterAction = payload.afterAction || (() => {});
        const campaignPk = payload.data?.pk;
        let serviceArgs = [updateApprovalStatus, { id: campaignPk }, payload.data?.approvalStatus, payload.actionType];
        function* onSuccess(data) {
          console.log("@1295", data)
          yield all([
            put({
              type: 'updateState',
              payload: {
                createStatus: APIStatus.success,
                campaignPublish: payload.actionType === ActionType.publish
                ? APIStatus.success
                : APIStatus.none,
                formHasSubmitted: true,
              }
            }),
          ]);
          afterAction();
        }

        function* onError(response) {
          yield put({
            type: 'updateState',
            payload: {
              createStatus: APIStatus.failed,
              campaignPublish: APIStatus.failed,
            },
          });
          afterAction();
        }

        yield apiWithResponseHandle(
          serviceArgs,
          onSuccess,
          onError,
          onError,
        );
      },
    ],

    // unPublishCampaign: [
    //   function* ({ payload }, { call, select, put }) {
    //     const afterAction = payload.afterAction || (() => {});
    //     const campaignPk = payload.campaignPk;
    //     const serviceArgs = [
    //       unPublishCampaign,
    //       {
    //         id: campaignPk,
    //       },
    //     ];

    //     function* onSuccess(data) {
    //       yield put({
    //         type: 'updateState',
    //         payload: { createStatus: APIStatus.success },
    //       });
    //       afterAction();
    //     }
    //     function* onError(response) {
    //       yield put({
    //         type: 'updateState',
    //         payload: { createStatus: APIStatus.failed },
    //       });
    //       afterAction();
    //     }

    //     function* onArgumentsError(response) {
    //       yield put({
    //         type: 'updateState',
    //         payload: { createStatus: APIStatus.failed },
    //       });
    //       afterAction();
    //     }
    //     yield apiWithResponseHandle(
    //       serviceArgs,
    //       onSuccess,
    //       onError,
    //       onArgumentsError,
    //     );
    //   },
    //   { type: 'takeLatest' },
    // ],

    createOrUpdateCampaign: [
      function* ({ payload }, { call, select, put, all }) {
        const resume = payload.resume || false
        yield put({
          type: 'updateState',
          payload: { createStatus: APIStatus.calling },
        });
        saveToSessionStorage(sessionDataKey.stepEndKey, true);
        const { modelCampaign } = yield select((state) => ({
          modelCampaign: state.createCampaign.campaign,
        }));
        console.log("@1135 payload", payload)
        const originalCampaign = payload?.data || modelCampaign;
        let campaignData = {};
        console.log("@1141 campaign data", campaignData)
        campaignData = getDataForCreateCampaign(
          originalCampaign,
          payload.actionType === ActionType.tempForPulished ? false : originalCampaign.pk,
        );
        console.log("@1145", campaignData)
        let serviceArgs = [createCampaign, campaignData];
        if (payload.actionType === ActionType.tempForPulished) {
          campaignData.originCampaign = originalCampaign.pk;
          campaignData.translations = campaignData.translations?.map((item) => {
            delete item.id
            return {
              ...item,
            }
          })
          serviceArgs = [CreateTempCampaign, campaignData];
        } else if (originalCampaign.pk) {
          campaignData.id = originalCampaign.pk;
          serviceArgs = [updateCampaign, campaignData];
        }
        // const campaignPk = yield select(
        //   (state) => state.createCampaign.campaign.pk,
        // );
        // if (campaignPk) {
        //   yield put.resolve({ type: 'updateCampaignFields', payload });
        // } else {
        //   yield put.resolve({ type: 'createOneCampaign', payload });
        // }

        // const { newCampaignPk, apiError } = yield select((state) => ({
        //   newCampaignPk: state.createCampaign.campaign.pk,
        //   apiError: state.createCampaign.apiError,
        // }));

        // yield put({
        //   type: 'updateState',
        //   payload: {
        //     createStatus:
        //       !newCampaignPk || apiError ? APIStatus.failed : APIStatus.success,
        //   },
        // });
        function* onSuccess(data) {
          console.log("@1173", data, payload.actionType)
          const campaign =
            data[`${originalCampaign.pk ? payload.actionType === ActionType.tempForPulished ? 'createTemp' : 'update' : 'create'}Campaign`].node;
          if (resume) {
            removeDataFromSessionStorage(campaign?.displayIn)
          }
          if (!campaign) {
            yield put({
              type: 'updateState',
              payload: {
                createStatus: APIStatus.failed,
              },
            });
            return;
          }

          yield all([
            put({
              type: 'updateState',
              payload: {
                apiError: false,
                createStatus: APIStatus.success,
                formHasSubmitted: true,
              },
            }),
            put({
              type: 'updateCampaign',
              payload: { id: campaign.id, pk: campaign.pk, campaignType: campaign.type },
            }),
            payload.actionType !== undefined && payload.actionType !== ActionType.update 
            && payload.actionType !== ActionType.save && payload.actionType !== ActionType.tempForPulished &&
              put({
                type: 'updateApprovalStatus',
                payload: {
                  ...payload,
                  data: {
                    pk: campaign.pk,
                    approvalStatus: campaign.approvalStatus,
                  },
                  actionType: payload?.actionType,
                  otaAllocationNum: payload.otaAllocatedNum,
                },
              }),
          ]);
        }
        function* failed(data) {
          console.log('createOneCampaign failed:', data);
          yield put({ type: 'updateState', payload: { apiError: true } });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, failed, failed);
      },
      { type: 'takeLatest' },
    ],

    createOneCampaign: [
      function* ({ payload }, { call, select, put, all }) {
        const originalCampaign = yield select(
          (state) => state.createCampaign.campaign,
        );
        const createCampaignData = getDataForCreateCampaign(
          originalCampaign,
          false,
        );

        const serviceArgs = [createCampaign, createCampaignData];
        function* onSuccess(data) {
          const campaign = data.createCampaign.node;
          if (!campaign) {
            yield put({
              type: 'updateState',
              payload: {
                createStatus: APIStatus.failed,
              },
            });
            return;
          }
          yield put({
            type: 'updateState',
            payload: { apiError: false },
          });
          yield put({
            type: 'updateCampaign',
            payload: { id: campaign.id, pk: campaign.pk, campaignType: campaign.type },
          });
          yield put({ type: 'saveOrRemoveCampaignFromCookie', payload: false });
        }
        function* failed(data) {
          console.log('createOneCampaign failed:', data);
          yield put({ type: 'updateState', payload: { apiError: true } });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, failed, failed);
      },
      { type: 'takeLatest' },
    ],

    createCamapigncategory: [
      function* ({ payload }, { call, all, put, race, take }) {
        const {
          enCategory,
          simpleCategory,
          traditionalCategory,
          priority,
          successAction,
        } = payload;

        yield put(
          createAction('createCampaignCategory/createCampaignCategory')({
            enCategory,
            simpleCategory,
            traditionalCategory,
            priority,
            isForcedInactive: false,
            successAction
          }),
        );
        const [success, failed] = yield race([
          take(CREATE_CAMPAIGN_CATEGORY_SUCCESS),
          take(CREATE_CAMPAIGN_CATEGORY_FAILD),
        ]);
        if (success) {
          yield put(
            createAction('campaignCategoryList/getCampaignCategoryList')({status: true }),
          );
        }
      },
      { type: 'takeLatest' },
    ],

    getOneCampaign: [
      function* ({ payload }, { all, select, put }) {
        yield put({
          type: 'updateState',
          payload: { createStatus: APIStatus.calling },
        });
        const id = convertPKToId('CampaignNode', payload.id);
        const serviceArgs = [getCampaign, id];
        function* onSuccess(data) {
          console.log('getOneCampaign onSuccess :', data);
          yield all([
            put({
              type: 'assembleCampaign',
              payload: { 
                campaign: data.campaign,
                isDetail: payload.isDetail,
                newItems: payload,
                initOneCampaign: true,
              },
            }),
            put({
              type: 'updateState',
              payload: { createStatus: APIStatus.success },
            }),
          ]);
        }
        function* onError(err) {
          console.log('getOneCampaign onError :', err);
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.failed },
          });
        }
        function* onArgumentsError(err) {
          console.log('getOneCampaign arguments error :', err);
          yield put({
            type: 'updateState',
            payload: { createStatus: APIStatus.failed },
          });
        }
        yield apiWithResponseHandle(
          serviceArgs,
          onSuccess,
          onError,
          onArgumentsError,
        );
      },
      { type: 'takeLatest' },
    ],
    deleteCampaigns: [
      function* ({ payload }, { call, select, put }) {
        const selectedCampaignIds = yield select(
          (state) => state.campaignList.checkedList,
        );
        const deleteCampaignIds = selectedCampaignIds?.map((item) => item.pk);
        console.log("@1344", deleteCampaignIds)
        const afterAction = payload.afterAction || (() => {});
        yield call(deleteCampaign, deleteCampaignIds);
        yield delay(1000);
        afterAction();
      },
      { type: 'takeLatest' },
    ],

    // deleteCampaignBrands: [
    //   function* ({ payload }, { call, select, put }) {
    //     const campaignPks = payload.campaignPks;
    //     const serviceArgs = [deleteCampaignBrand, campaignPks];
    //     yield apiWithResponseHandle(serviceArgs);
    //   },
    //   { type: 'takeLatest' },
    // ],

    // deleteCampaignTranslations: [
    //   function* ({ payload }, { call, select, put }) {
    //     const campaignPks = payload.campaignPks;
    //     const serviceArgs = [deleteCampaignTranslation, campaignPks];
    //     yield apiWithResponseHandle(serviceArgs);
    //   },
    //   { type: 'takeLatest' },
    // ],

    duplicateCampaign: [
      function* ({ payload }, { call, select, put }) {
        yield put({ type: 'clearData' });
        const campaignId = payload.campaignId;
        const afterAction = payload.afterAction || (() => {});
        yield put.resolve({
          type: 'getOneCampaign',
          payload: { id: campaignId },
        });
        const translations = yield select(
          (state) => state.createCampaign.campaign.translations,
        );
        const englishName = translations[LanguageConfig.english].generalName;
        translations[
          LanguageConfig.english
        ].generalName = `Copy of ${englishName}`;
        yield put.resolve({
          type: 'updateCampaign',
          payload: { translations },
        });
        yield put.resolve({ type: 'createOneCampaign' });
        afterAction();
      },
      { type: 'takeLatest' },
    ],

    updateCampaignFields: [
      function* ({ payload }, { all, select, put }) {
        const originalCampaign = yield select(
          (state) => state.createCampaign.campaign,
        );

        const campaignData = getDataForCreateCampaign(originalCampaign, true);
        campaignData.id = originalCampaign.pk;

        const serviceArgs = [updateCampaign, campaignData];
        function* onSuccess(data) {
          yield put({ type: 'saveOrRemoveCampaignFromCookie', payload: false });
          yield put({ type: 'updateState', payload: { apiError: false } });
        }
        function* failed(data) {
          yield put({ type: 'updateState', payload: { apiError: true } });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, failed, failed);
      },
      { type: 'takeLatest' },
    ],

    getAndLinkCouponDetail: [
      function* ({ payload }, { call, select, put }) {
        if (!payload.couponId) {
          return;
        }
        const couponId = convertPKToId('CouponTemplateNode', payload.couponId);
        const linkedCoupon = yield select(
          (state) => state.createCampaign.campaign.linkedCoupon,
        ) || {};
        const afterAction = payload?.afterAction || (() => {});
        const serviceArgs = [getOneCoupon, couponId];
        function* onSuccess(data) {
          const translations = parseCouponTranslationsForCampaign(data.couponTemplate);
          const selectedDisplayBrand = data.couponTemplate?.brand?.edges.map((item) => {
            return {
              ...item.node,
              value: {...item.node},
              label: `[${item.node.pk}] ${item.node.name}`,
            }
          });
          yield afterAction({linkedCoupon : {
            ...linkedCoupon,
            ...data.couponTemplate,
          }})
          yield put({
            type: 'assembleCampaignWithCoupon',
            payload: {
              linkedCoupon: {
                ...linkedCoupon,
                ...data.couponTemplate,
                selectedDisplayBrand,
                translations,
              },
              campaignType: payload?.campaignType,
              displayIn: payload?.displayIn,
            },
          })
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    getAndLinkEarningRuleDetail: [
      function* ({ payload }, { call, select, put }) {
        if (!payload.earningRuleId) {
          return;
        }
        const earningRuleId = convertPKToId(
          'EarningRuleNode',
          payload.earningRuleId,
        );
        const linkedEarningRules = yield select(
          (state) => state.createCampaign.campaign.linkedEarningRules,
        ) || {};
        const serviceArgs = [getOneEarningRule, earningRuleId];
        const afterAction = payload?.afterAction || (() => {});
        function* onSuccess(data) {
          yield afterAction({linkedEarningRules : {
            ...linkedEarningRules,
            ...data.earningRule,
          }})
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    rejectCampaign: [
      function* ({ payload }, { all, put }) {
        const { id, message, isPendingForApprovalCampaign } = payload;
        const afterAction = payload.afterAction || (() => {});
        const rejectApi = isPendingForApprovalCampaign ? rejectPendingForApprovalCampaign : rejectPendingForPublishCampaign;
        const serviceArgs = [rejectApi, { id, message }];
        function* onSuccess(data) {
          yield all([
            put({
              type: 'updateState',
              payload: {
                createStatus: APIStatus.success,
                formHasSubmitted: true,
              }
            }),
          ]);
          afterAction();
        }

        function* onError(response) {
          yield put({
            type: 'updateState',
            payload: {
              createStatus: APIStatus.failed,
              formHasSubmitted: true,
            },
          });
          afterAction();
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onError, onError);
      },
      { type: 'takeLatest' },
    ],
  },
});
