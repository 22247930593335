import React, { useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { APIStatus, SESSION_KEYS } from '../../../config/CustomEnums';
import { removeFromSessionStorage, saveToSessionStorage } from '../../../utils';
import BaseForm from '../../../components/base/v2/BaseForm';
import CreateCampaignTagStepOne from './CreateCampaignTagStepOne';

const CreateCampaignTag = ({
  campaignTag,
  createStatus,
  apiStatus,
  hasUpdatedDefaultValues,
  formHasSubmitted,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const caution = {
    detail: '', //Need fill in the tag name
    title: params.id ? campaignTag.name : 'Create campaign tag',
    model: 'campaignTag',
  };

  useEffect(() => {
    const tagID = params.id;
    if (tagID) {
      dispatch({ type: 'campaignTag/getOneDetail', payload: { id: tagID } });
    } else {
      dispatch({ type: 'campaignTag/loadFromCookie' });
    }
    return () => {
      dispatch({ type: 'campaignTag/clearData' });
    };
  }, [dispatch, params.id]);

  useEffect(() => {
    if (
      apiStatus === APIStatus.success &&
      formHasSubmitted &&
      history.location.pathname.includes('edit')
    ) {
      history.push('/campaign_tag');
      return;
    }
  }, [formHasSubmitted, apiStatus, campaignTag, history]);

  const stepSet = [<CreateCampaignTagStepOne />];
  return (
    <div className="campaign-tag">
      <BaseForm
        defaultValues={campaignTag}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        tempSave={(save, getValues) => {
          if (save) {
            saveToSessionStorage(
              SESSION_KEYS.CAMPAIGN_TAG_SESSION_KEY,
              getValues(),
            );
          } else {
            removeFromSessionStorage(SESSION_KEYS.CAMPAIGN_TAG_SESSION_KEY);
          }
        }}
        showFinishPop={createStatus === APIStatus.success}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Campaign tag is successfully created.',
          steps: null,
          buttons: [
            {
              text: 'Back to campaign tags list',
              action: () => {
                history.push({
                  pathname: '/campaign_tag',
                });
              },
            },
          ],
        }}
        continueCreateConfig={{
          onConfirm: () => {
            history.push(history.location.state?.from);
          },
          onCancel: () => history.push('/campaign_tag'),
          onClose: () => history.push('/campaign_tag'),
        }}
        content={stepSet}
        currentStep={0}
        breadcrumb={<CustomBreadcrumb name={campaignTag.name} />}
        caution={caution}
      />
    </div>
  );
};

const mapPropsToState = (state) => ({
  campaignTag: state.campaignTag.detail,
  createStatus: state.campaignTag.createStatus,
  apiStatus: state.campaignTag.apiStatus,
  hasUpdatedDefaultValues: state.campaignTag.hasUpdatedDefaultValues,
  formHasSubmitted: state.campaignTag.formHasSubmitted,
});

export default connect(mapPropsToState)(CreateCampaignTag);
