import { APIStatus, LanguageConfig } from '../config/CustomEnums';
import {
  getThemeSections,
  updateThemeSection,
} from '../services/ThemedSectionAPIHelper';
import { getImageImage } from '../utils';
import { createModel } from './BaseModel';
import { parseTranslations } from './CreateTransactionRecordModel';

const fixTranslations = (translations) => {
  const translationsToDelete = [];
  const fixedTranslations = translations.filter((translation) => {
    const { description, id, coverPhoto } = translation;
    if (id && (description === '' || description === null || description === undefined) && !coverPhoto) {
      translationsToDelete.push(id);
      return false;
    }
    return true;
  });
  return [translationsToDelete, fixedTranslations];
};

export default createModel({
  namespace: 'themeSection',
  params: {
    listAPI: getThemeSections,
    parse: (data) =>
      data?.themeSections?.edges.map((item) => {
        const campaignTags = item?.node?.campaignTags?.edges.map(
          (campaignTag) => campaignTag.node,
        );
        const formatTranslations = parseTranslations([item])?.[0];
        return {
          node: {
            ...formatTranslations,
            campaignTags,
            campaignTagsName: campaignTags?.map(
              (campaignTag) => campaignTag.name,
            ),
          },
        };
      }),
    pkNode: 'ThemeSectionNode',
    objectKey: 'themeSections',
  },

  reducers: {},

  effects: {
    updateThemeSection: [
      function* ({ payload }, { put }) {
        yield put({
          type: 'updateState',
          payload: {
            createStatus: APIStatus.calling,
          },
        });
        let translations = [];
        for (const [key, value] of Object.entries(payload.translations)) {
          if (key === LanguageConfig.english) {
            continue;
          }
          if (value?.pk == null && value?.description == null && value?.coverPhoto == null) {
            continue;
          }
          const translationData = {
            description: value?.description,
            coverPhoto: getImageImage(value?.coverPhoto) || null,
            language: key,
          };
          if (value?.pk) {
            translationData.id = value?.pk;
          }
          translations.push(translationData);
        }
        const [translationsToDelete, fixedTranslations] = fixTranslations(translations);
        const input = {
          id: payload.pk,
          coverPhoto: getImageImage(payload.coverPhoto),
          description: payload.description,
          applyCoverPhotoToAllLanguage: payload.applyCoverPhotoToAllLanguage,
          translations: fixedTranslations,
          translationsToDelete: translationsToDelete,
          campaignTags: payload?.campaignTags.map((item) => item.pk),
        };

        const serviceArgs = [updateThemeSection, input];
        yield put({
          type: 'responseCreateOrUpdate',
          payload: { serviceArgs, isCreate: false, afterActions: payload?.afterActions },
        });
      },
      { type: 'takeLatest' },
    ],
  },
});
