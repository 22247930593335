import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import queryString from 'query-string';
import BaseListContainer from '../../base/BaseListContainer';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import Filter from '../../../components/customer/Filter';
import DeletePrompt from '../../../components/base/DeletePrompt';
import DateRangeCompare from '../../../components/dashboard/DateRangeCompare';
import DateRangeSelection from '../../../components/dashboard/DateRangeSelection';
import CustomerOverview from '../../../components/customer/CustomerOverview';
import AuthButton from '../../../components/base/AuthButton';
import CustomFileUploader from '../../../components/base/CustomFileUploader';
import ListButtonsGroup from '../../../components/base/ListButtonsGroup';
import BasePrompt from '../../../components/base/prompt/BasePrompt';
import { ImportResource } from '../../../models/DownloadImportModel';
// import BaseTest from '../../base/BaseTest';
import {
  DELETE_RELATED_SECTIONS,
  LanguageTransLation,
} from '../../../config/CustomEnums';
import { NavBarNames } from '../../../config/NavBarNameList';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { getHashKeyString } from '../../../utils';
import './CustomerList.scss';
import { getConciergeLanguage } from '../../conciergeApp/couponRecord/CouponTransactionRecord';
import i18n from '../../../I18n';
import { ActionsDropdownForItem } from '../../../components/base/ActionsDropdown';

const TAB_OVERVIEW = 'Overivew';

function CustomerList({
  listFields,
  dataList,
  pageInfo,
  totalPage,
  totalCount,
  checkedList,
  filters,
  importedFile,
  isSuperuser,
  userPermissions,
  importError,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [deleteItems, setDeleteItems] = useState([]);

  const language = getConciergeLanguage(location);
  const TAB_LIST_OF_CUSTOMER = i18n.t('concierge.lis_of_customer', { locale: language });

  const activeTab = getHashKeyString(location.hash) || TAB_LIST_OF_CUSTOMER;

  const hiddenFileInput = React.useRef(null);

  const uploadID = 'uploadCsvId'

  const handleImportClick = (event) => {
    hiddenFileInput.current.click();
    console.log('@@71');
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    // props.handleFile(fileUploaded);
    console.log('@@77');
    dispatch({
      type: 'uploadFiles/uploadFile',
      payload: {
        file: fileUploaded,
        source: event,
        id: uploadID,
      }
    });

    event.target.value = null;
  };
  const exportCSVAction = () => {
    const parsedSearch = queryString.parse(location.search);
    const createDatePeriod = parsedSearch?.create_date?.split(',');
    dispatch({
      type: 'downloadAndImport/createDownloadTask',
      payload: {
        from: ImportResource.customer,
        related: {
          groupsIn: parsedSearch?.customer_groups?.split(','),
          segmentsIn: parsedSearch?.segments?.split(','),
          ageRange: parsedSearch?.age?.split(','),
          genderIn: parsedSearch?.gender?.split(','),
          dateOfBirthGte: parsedSearch?.start_date,
          dateOfBirthLte: parsedSearch?.end_date,
          creationDateGte: createDatePeriod?.[0],
          creationDateLte: createDatePeriod?.[1],
        },
      }
    });
  };

  const buttons = [
    <ListButtonsGroup
      hideExtraButtonWidth={500}
      extraButtons={[
        <AuthButton
          customClass="btn-back-button-common btn-download"
          title={i18n.t('concierge.export_csv', { locale: language })}
          action={exportCSVAction}
          requires={PermissionCodes.addExportjob}
        />,
        // <AuthButton
        //   title={'Import .csv'}
        //   action={handleImportClick}
        //   requires={PermissionCodes.addImportjob}
        // />,
      ]}
      extraPopContent={[
        {
          requires: PermissionCodes.addExportjob,
          action: exportCSVAction,
          content: i18n.t('concierge.export_csv', { locale: language }),
        },
        // {
        //   requires: PermissionCodes.addImportjob,
        //   action: handleImportClick,
        //   content: 'Import .csv',
        // },
      ]}
    />,
    // <input
    //   type="file"
    //   ref={hiddenFileInput}
    //   onChange={handleChange}
    //   style={{ display: 'none' }}
    //   accept=".csv"
    // />,
  ];

  const groupActions = [
    {
      name: i18n.t('concierge.delete', { locale: language }),
      action: () => {
        // setShowPrompt(true);
        setDeleteItems(checkedList);
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.deleteCustomer,
    },
  ];

  const showData = () => {
    const searchDict = queryString.parse(location.search);
    let result = false;
    if (
      searchDict?.customer_groups ||
      searchDict?.segments ||
      searchDict?.gender ||
      searchDict?.age ||
      searchDict?.start_date ||
      searchDict?.end_date ||
      searchDict?.create_date ||
      searchDict?.search
    ) {
      result = true;
    }
    return result;
  };
  const tabs = [
    // {
    //   name: TAB_OVERVIEW,
    //   content: <CustomerOverview />,
    // },
    isSuperuser ||
    userPermissions?.filter(
      (val) => val.split('_')[1] === PermissionCodes.customer,
    ).length > 0
      ? {
          name: TAB_LIST_OF_CUSTOMER,
          content: (
            <BaseTabListContainer
              hideTab={true}
              tabs={[]}
              groupActions={groupActions}
              // defaultTabKey={levels}
              pageInfo={pageInfo}
              totalCount={totalCount}
              searchPlaceholder={i18n.t('transaction.search_membership_id/mobile_number', { locale: language })}
              searchMoreStyle={{ width: '350px' }}
              filter={{
                hasFilter: true,
                componment: Filter,
                filterTitle: i18n.t('transaction.filter', { locale: language }),
                moreStyle: { right: '417px' },
              }}
              componentLanguage={language}
              listContent={
                <BaseListContainer
                  actionFieldDisplayName={i18n.t('transaction.action', { locale: language })}
                  fields={listFields[language]}
                  dataList={dataList}
                  totalPage={totalPage}
                  model={'customerList'}
                  permissionGroup={PermissionCodes.customer}
                  deleteInfo={{
                    data: [],
                    title: 'customers',
                    relatedName: '',
                    relatedSections: DELETE_RELATED_SECTIONS.CUSTOMER,
                    onComfirm: {},
                  }}
                  useCustomCommonActions
                  customCommonActions={(item) => {
                    const actions = [
                      {
                        name: i18n.t('concierge.edit', { locale: language }),
                        action: () => {
                          return history.push(`${location.pathname}/${item.pk}/edit/`);
                        },
                        requires: PermissionCodes.changeCustomer,
                      },
                      {
                        name: i18n.t('transaction.view_detail', { locale: language }),
                        action: () => {
                          return history.push(`${location.pathname}/${item.pk}/`);
                        },
                        requires: PermissionCodes.viewCustomer,
                      },
                      {
                        name: i18n.t('concierge.delete', { locale: language }),
                        action: () => {
                          setDeleteItems([item]);
                          setShowDeletePrompt(true);
                        },
                        requires: PermissionCodes.deleteCustomer,
                      },
                    ];
                    const otherAction=[
                      {
                        name:
                          item.status === 'Active'
                            ? i18n.t('concierge.deactive', { locale: language })
                            : i18n.t('concierge.active', {locale: language}),
                        action: () => {
                          dispatch({
                            type: 'customerList/updateCustomerActive',
                            payload: {
                              data: {
                                isForcedInactive: item.status === 'Deactive',
                                id: item.pk,
                              },
                              afterAction: () => history.push(location),
                            }
                          });
                        },
                        requires: PermissionCodes.changeCustomer,
                      },
                    ];
                    return (
                      <ActionsDropdownForItem
                        object={item}
                        actions={actions}
                        otherActions={otherAction}
                        actionTitle={i18n.t('transaction.action', { locale: language })}
                        otherActionTitle={i18n.t('transaction.others', { locale: language })}
                      />
                    )
                  }}
                />
              }
            />
          ),
        }
      : {},
  ];

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);

  // useEffect(() => {
  //   // dispatch(createAction('levels/getLevelList')({}));
  //   // dispatch(
  //   //   createAction('customerList/getPagedCustomers')({
  //   //     page: 0,
  //   //     reverse: true,
  //   //     search: '',
  //   //   }),
  //   // );
  // }, [dispatch]);

  useEffect(() => {
    // console.log('@@202: ', filters);
    if (activeTab === TAB_LIST_OF_CUSTOMER && showData()) {
      const parsedSearch = queryString.parse(location.search);

      const searchKey = parsedSearch['search'] || '';
      const stringRank = parsedSearch['rank'] || 'true';
      const stringPage = parsedSearch['page'] || 1;
      const rank = stringRank === 'true';
      const page = parseInt(stringPage);
      console.log('@@202: ', parsedSearch);
      dispatch({
        type: 'customerList/getPagedCustomers',
        payload: {
          ...parsedSearch,
          page,
          reverse: rank,
          search: searchKey,
          isFilterMembershipIDOrPhoneNumber: true,
          isListNode: true,
        }
      });
    } else {
      dispatch({ type: 'customerList/clearData' });
    }
  }, [dispatch, location, activeTab]);

  useEffect(() => {
    if (Object.keys(importedFile).length) {
      dispatch({
        type: 'downloadAndImport/importFile',
        payload: {
          from: ImportResource.customer,
          file: importedFile[uploadID]?.file,
        }
      });
    }
  }, [dispatch, importedFile]);

  const [showError, setShowError] = useState(false);

  useEffect(() => {
    console.log("@256", importError)
    setShowError(importError ? Object.keys(importError).length > 0 : false);
  }, [importError]);

  useEffect(() => {
    dispatch({ type: 'customerGroup/getGroupList', payload: {isSimpleList: true} });
    dispatch({ type: 'segments/getSegmentsList', payload: {} });
    return () => {
      dispatch({ type: 'customerList/clearData' });
    }
  }, [dispatch]);

  return (
    <>
      <CustomListComponent
        caution={{
          // detail:
          //   'All the customers are listed here, and you can view their details and records. You can learn about the user usage of the app here.',
          title: i18n.t('concierge.customer_record', { locale: language }),
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={true}
        onTabChange={(key) => {
          // setActiveTab(key);
          history.push({ pathname: location.pathname, hash: key });
        }}
        renderCompare={() => {
          return activeTab === TAB_OVERVIEW ? <DateRangeCompare /> : null;
        }}
        renderTabsRight={() => {
          return activeTab === TAB_OVERVIEW ? (
            <DateRangeSelection
              onTab
              applyDateRangeEvent={(startDate, endDate) => {
                dispatch({
                  type: 'overview/getCustomersData',
                  payload: {
                    startDate: startDate.format('YYYY-MM-DD'),
                    endDate: endDate.format('YYYY-MM-DD'),
                  }
                });
              }}
              clearDataEvent={() => {
                dispatch({
                  type: 'overview/clearState',
                  payload: {}
                });
              }}
            />
          ) : null;
        }}
      />

      <DeletePrompt
        data={deleteItems}
        title={'customers'}
        relatedName={''}
        relatedSections={DELETE_RELATED_SECTIONS.CUSTOMER}
        show={showDeletePrompt}
        onClose={() => {
          setShowDeletePrompt(false);
          setDeleteItems([]);
        }}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: `customerList/updateState`,
            payload: { checkedList: deleteItems },
          })
          dispatch({
            type: 'customerList/delete',
            payload: {
              afterAction: () => {
                history.push(location);
              },
            }
          });
          setDeleteItems([]);
        }}
      />

      <BasePrompt
        show={showError}
        title={importError?.title}
        description={importError?.content}
        closeAction={() => {
          setShowError(false);
          dispatch({
            type: 'downloadAndImport/updateState',
            payload: { error: {} },
          });
        }}
        leftButton={{
          text: 'Cancel',
          action: () => {
            setShowError(false);
            dispatch({
              type: 'downloadAndImport/updateState',
              payload: { error: {} },
            });
          },
        }}
        rightButton={{
          text: 'Import again',
          action: () => {
            handleImportClick();
            setShowError(false);
            dispatch({
              type: 'downloadAndImport/updateState',
              payload: { error: {} },
            });
          },
          requires: PermissionCodes.addImportjob,
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  listFields: state.customerList.listDisplayFields,
  dataList: state.customerList.pagedCustomerList,
  pageInfo: state.customerList.pageInfo,
  totalPage: state.customerList.totalPage,
  totalCount: state.customerList.listTotalCount,
  checkedList: state.customerList.checkedList,
  filters: state.customerList.filters,
  importedFile: state.uploadFiles.importedFile,
  isSuperuser: state.users.isSuperuser,
  userPermissions: state.users.userPermissions,
  importError: state.downloadAndImport.error,
});

export default connect(mapPropsToState)(CustomerList);
