import { CAMPAIGN_REWARD_TYPE, FORM_TYPE } from '../../../config/CustomEnums';
import { CreateTransactionError } from '../../../config/ErrorHandle';
import i18n from '../../../I18n';

const campaignValidate = ({getValues, language, errorList})=>{
  const campaignTypeField = 'campaignType';
  const stampCampaignField = 'stampCampaign';
  const missionCampaignField = 'missionCampaign';
  const otherCampaignField = 'otherCampaign';
  const carParkCampaignField = 'carParkCampaign';
  const [campaignType, stampCampaign, missionCampaign, otherCampaign, carParkCampaign] = getValues([
    campaignTypeField,
    stampCampaignField,
    missionCampaignField,
    otherCampaignField,
    carParkCampaignField,
  ]);
  if (!campaignType) {
    errorList.push({
      name: campaignTypeField,
      message: i18n.t('transaction.campaign_type_error', { locale: language }),
    });
  }
  if (campaignType === CAMPAIGN_REWARD_TYPE.stamp && !stampCampaign) {
    errorList.push({
      name: stampCampaignField,
      message: i18n.t('transaction.stamp_campaign_error', { locale: language }),
    });
  }
  if (campaignType === CAMPAIGN_REWARD_TYPE.mission && !missionCampaign) {
    errorList.push({
      name: missionCampaignField,
      message: i18n.t('transaction.mission_campaign_error', { locale: language }),
    });
  }
  if (campaignType === CAMPAIGN_REWARD_TYPE.other && !otherCampaign) {
    errorList.push({
      name: otherCampaignField,
      message: i18n.t('transaction.other_campaign_error', { locale: language }),
    });
  }
  if (campaignType === CAMPAIGN_REWARD_TYPE.carPark && !carParkCampaign) {
    errorList.push({
      name: carParkCampaignField,
      message: i18n.t('transaction.carpark_campaign_error', { locale: language }),
    });
  }
  return errorList;
}

function firstStepValidate({ getValues, setError, language }) {
  const formTypeField = 'formType';
  const customerIDField = 'customerID';
  const customerField = 'customer';
  const regionField = 'region';
  const districtField = 'district';
  const mallField = 'mall';
  const receiptsField = 'receipts';
  const staffNameField = 'staffName';
  const [
    formType,
    customerID,
    customer,
    region,
    district,
    mall,
    staffName,
    receipts,
  ] = getValues([
    formTypeField,
    customerIDField,
    customerField,
    regionField,
    districtField,
    mallField,
    staffNameField,
    receiptsField,
  ]);
  let errorList = [];
  if (!formType) {
    errorList.push({
      name: formTypeField,
      message: i18n.t('transaction.form_type_error', { locale: language }),
    });
  }
  if (!customerID) {
    errorList.push({
      name: customerIDField,
      message: i18n.t('transaction.customer_id_error', { locale: language }),
    });
  }
  if (!customer?.membershipId) {
    errorList.push({
      name: customerField,
      message: i18n.t('transaction.customer_error', { locale: language }),
    });
  }
  if (!region?.pk) {
    errorList.push({
      name: regionField,
      message: i18n.t('transaction.region_error', { locale: language }),
    });
  }
  if (!district?.pk) {
    errorList.push({
      name: districtField,
      message: i18n.t('transaction.district_error', { locale: language }),
    });
  }
  if (!mall?.pk) {
    errorList.push({
      name: mallField,
      message: i18n.t('transaction.mall_error', { locale: language }),
    });
  }
  if (!staffName) {
    errorList.push({
      name: staffNameField,
      message: i18n.t('transaction.staff_name_error', { locale: language }),
    });
  }
  console.log("errorList@111", errorList)
  errorList = campaignValidate({getValues, language, errorList});

  errorList.forEach((error) => {
    setError(error.name, {
      type: 'require',
      message: error.message,
    });
  });
  console.log('errorListMessage:', errorList);
  return !errorList.length;
}

const transactionInfoValidate = ({getValues, language, errorList})=>{
  const formTypeField = 'formType';
  const customerIDField = 'customerID';
  const customerField = 'customer';
  const regionField = 'region';
  const districtField = 'district';
  const mallField = 'mall';
  const receiptsField = 'receipts';
  const staffNameField = 'staffName';
  const [
    formType,
    customerID,
    customer,
    region,
    district,
    mall,
    staffName,
    receipts,
  ] = getValues([
    formTypeField,
    customerIDField,
    customerField,
    regionField,
    districtField,
    mallField,
    staffNameField,
    receiptsField,
  ]);
  // if (!region?.pk) {
  //   errorList.push({
  //     name: regionField,
  //     message: i18n.t('transaction.region_error', { locale: language }),
  //   });
  // }
  // if (!district?.pk) {
  //   errorList.push({
  //     name: districtField,
  //     message: i18n.t('transaction.district_error', { locale: language }),
  //   });
  // }
  // if (!mall?.pk) {
  //   errorList.push({
  //     name: mallField,
  //     message: i18n.t('transaction.mall_error', { locale: language }),
  //   });
  // }
  // if (!staffName) {
  //   errorList.push({
  //     name: staffNameField,
  //     message: i18n.t('transaction.staff_name_error', { locale: language }),
  //   });
  // }
  receipts.forEach((receipt, index) => {
    const transactionDateField = `receipts[${index}].transactionDate`;
    const storeField = `receipts[${index}].store`;
    const invoiceIdField = `receipts[${index}].invoiceId`;
    const paymentMethodField = `receipts[${index}].paymentMethod`;
    const totalValueField = `receipts[${index}].totalValue`;
    const submitNumberField = `receipts[${index}].submitNumber`;
    if (!receipt?.transactionDate) {
      errorList.push({
        name: transactionDateField,
        message: i18n.t('transaction.transaction_date_error', { locale: language }),
      });
    }
    if (receipt?.transactionDate > new Date()) {
      errorList.push({
        name: transactionDateField,
        message: i18n.t('transaction.transaction_date_vaild_error', { locale: language }),
      });
    }
    if (formType === FORM_TYPE.notTrade && !receipt?.store?.storePK) {
      errorList.push({
        name: mallField,
        message: i18n.t('transaction.without_concierge_store_error', { locale: language }),
      });
    }
    if (formType === FORM_TYPE.trade && !receipt?.store?.storePK) {
      errorList.push({
        name: storeField,
        message: i18n.t('transaction.store_require_error', { locale: language }),
      });
    }
    if (formType === FORM_TYPE.trade && !receipt?.paymentMethod) {
      errorList.push({
        name: paymentMethodField,
        message: i18n.t('transaction.payment_method_error', { locale: language }),
      });
    }
    if (formType === FORM_TYPE.trade && !receipt?.invoiceId) {
      errorList.push({
        name: invoiceIdField,
        message: i18n.t('transaction.invoice_id_error', { locale: language }),
      });
    }
    if (formType === FORM_TYPE.trade && (!receipt?.totalValue || receipt?.totalValue === '')) {
      errorList.push({
        name: totalValueField,
        message: i18n.t('transaction.total_value_error', { locale: language }),
      });
    } else if (formType === FORM_TYPE.trade && !parseInt(receipt?.totalValue)) {
      errorList.push({
        name: totalValueField,
        message: i18n.t('transaction.total_value_format_error', { locale: language }),
      });
    }
    if (formType === FORM_TYPE.notTrade && (!receipt?.submitNumber || receipt?.submitNumber === '')) {
      errorList.push({
        name: submitNumberField,
        message: i18n.t('transaction.submit_number_error', { locale: language }),
      });
    } else if (formType === FORM_TYPE.notTrade && !parseInt(receipt?.submitNumber)) {
      errorList.push({
        name: submitNumberField,
        message: i18n.t('transaction.submit_number_format_error', { locale: language }),
      });
    }
  })

  return errorList;
}

function secondStepValidate({ getValues, setError, language }) {
  let errorList = [];
  errorList = transactionInfoValidate({ getValues, language, errorList })
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'require',
      message: error.message,
    });
  });
  return !errorList.length;
}

const receiptsValidate = ({getValues, language, errorList})=>{
  const receiptImageField = 'receiptImage';
  const creditCardSlipImageField = `creditCardSlipImage`;
  const [receiptImage, creditCardSlipImage] = getValues([receiptImageField, creditCardSlipImageField]);

    if (!receiptImage) {
      errorList.push({
        name: receiptImageField,
        message: i18n.t('transaction.receipt_image_error', { locale: language }),
      });
    }
    // if (!receipt?.creditCardSlipImage) {
    //   errorList.push({
    //     name: creditCardSlipImageField,
    //     message: i18n.t('transaction.credit_card_slip_image_error', { locale: language }),
    //   });
    // }

  return errorList;
}

function thirdStepValidate({ getValues, setError, language }) {
  let errorList = [];
  errorList = receiptsValidate({getValues, language, errorList});
  
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'require',
      message: error.message,
    });
  });
  return !errorList.length;
}

export function validate({ getValues, setError, clearErrors, step, isBack, language }) {
  if (isBack) {
    return true;
  }
  clearErrors();
  switch (step) {
    case 0:
      const validStepOne = firstStepValidate({ getValues, setError, language });
      return validStepOne;
    case 1:
      const validStepTwo = secondStepValidate({ getValues, setError, language });
      return validStepTwo;
    case 2:
      const validStepThree = thirdStepValidate({ getValues, setError, language });
      return validStepThree;
    default:
      const validOne = firstStepValidate({ getValues, setError });
      const validTwo = secondStepValidate({ getValues, setError });
      const validThree = thirdStepValidate({ getValues, setError });
      return validOne && validTwo && validThree;
  }
}
