import { getSearchKey } from '../utils';
import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getPagedAdminGroups = (afterCursor, moreSearch) => {
  let order = moreSearch?.rank ? '-pk' : 'pk';
  if(moreSearch?.sort) {
    order = moreSearch.sort
  }
  const searchKey = moreSearch?.search || '';
  console.log('@@6: ', order, afterCursor, moreSearch);
  const query = `
  {
    administratorGroups(first: ${moreSearch?.pageSize || 20}, after: "${afterCursor}", orderBy: "${order}", nameIcontains: "${getSearchKey(searchKey)}") {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          name
          administrators {
            edges {
              node {
                username
              }
            }
          }
          permissions {
            edges {
              node {
                id
                pk
                name
                codename
              }
            }
          }
        }
      }
    }
  }
  `;
  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const duplicateAdminGroup = (pk) => {
  const query = ``;
  const variables = {
    input: {
      id: pk,
    },
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteAdminGroup = (pks) => {
  const query = `
  mutation DeleteAdministratorGroups($input: DeleteAdministratorGroupsInput!) {
    deleteAdministratorGroups(input: $input) {
     success
   }
 }

 `;

  const variables = {
    input: {
      ids: pks,
    },
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneAdminGroup = (id) => {
  console.log('@@76: ', id);
  const query = `
  {
    administratorGroup(id: "${id}") {
      id
      pk
      name
      administrators {
        edges {
          node {
            username
          }
        }
      }
      permissions {
        edges {
          node {
            pk
            name
            codename
          }
        }
      }
    }
  }
  `;

  const variables = {};

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateOrCreateAdminGroup = (value) => {
  let query = `
  mutation CreateAdministratorGroup($input: CreateAdministratorGroupInput!) {
    createAdministratorGroup(input: $input) {
      node {
        id
        pk
        name
      }
      errors {
        field
        messages
      }
    }
  }
  `;

  if (value.id) {
    query = `
    mutation UpdateAdministratorGroup($input: UpdateAdministratorGroupInput!) {
      updateAdministratorGroup(input: $input) {
        node {
          id
          pk
          name
        }
        errors {
          field
          messages
        }
      }
    }

  `;
  }

  const variables = {
    input: value,
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};
