import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Image, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import queryString from 'query-string';
import './CreateCampaignStepOne.scss';
import ContentSections from '../../base/ContentSections';
import { OnlyContinueButton } from '../../base/BottomStepComponent';
import { ReactHookFormErrorMessage } from '../../base/ErrorFieldMessage';
import ItemTypeSet, { MIN_WIDTH, SPACE_BETWEEN } from '../../base/ItemTypeSet';
import { validate } from '../../../containers/engagement/campaign/CreateCampaignValidate';
import EarningRulesSelectDropdown from './EarningRulesSelectDropdown';
import CampaignLinkPrompt from './CampaignLinkPrompt';
import {
  getCampaignTypeSelectPromptConfig,
  getCampaignTypeConfig,
  MissionCampaignModeConfig,
  ParticipantConfig,
} from './CreateCampaignStepOneConfigs';
import {
  getScrollbarWidth,
  useMainContentWidth,
} from '../../../utils/ScreenUtil';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { CampaignType, CampaignDisplayIn, MissionCampaignParticipant } from '../../../config/CustomEnums';
import addImage from '../../../assets/images/addx1.png';
import {
  isShowEarningRuleRelated,
  isShowMissonRelated,
} from '../../../models/CreateCampaignModel';
import CustomRadios from '../../base/CustomRadios';
import { InputFieldControl2 } from '../../base/InputFieldControl';
import BasePrompt from '../../base/prompt/BasePrompt';
import { ContinueCreate } from '../CampaignUrlConfig';

function CreateEMissionCampaignStepOne({
  status,
  promptShowed,
  isPublished,
}) {
  const mainContentWidth = useMainContentWidth();
  const fullContainerWith = mainContentWidth - 80 - 60 - getScrollbarWidth();

  const [showCreateNewLinkPrompt, setShowCreateNewLinkPrompt] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    getValues,
    watch,
    setValue,
    control,
    formState,
    clearErrors,
    setError,
  } = useFormContext();
  const { errors } = formState;

  const watchCampaignType = watch('campaignType');
  const watchEarningRules = watch('linkedEarningRules');
  const watchCampaignMode = watch('missionCampaignMode');
  const watchCampaignParticipant = watch('missionCampaignParticipant')
  const isMissionRelated = isShowMissonRelated(watchCampaignType);
  const isEarningRuleRelated = isShowEarningRuleRelated(watchCampaignType);
  const [showLinkPrompt, setShowLinkPrompt] = useState(false);


  const selectRef = useRef('linkDropdown');

  const promptconfig = getCampaignTypeSelectPromptConfig(watchCampaignType);
  const search = queryString.parse(history.location.search);

  useEffect(() => {
    if (history.location.search && !watchCampaignType) {
      const type = search.type;
      const id = search.id;
      if (type === CampaignType.earningRulesCampaign){
        dispatch({
          type: 'createCampaign/getAndLinkEarningRuleDetail',
          payload: {
            earningRuleId: id,
            afterAction: (data) => {
              console.log("@93", data);
              setValue('displayIn', CampaignDisplayIn.emission)
              setValue('campaignType', type);
              setValue('linkedEarningRules', data.linkedEarningRules);
              goToNextStep(true);
            }
          }
        });
      }
    } 
  })

  const earningRuleLinkSet = (
    <Controller
      control={control}
      name="linkedEarningRules"
      render={() => (
        <>
          <div className="step-link-coupon-area-select">
            <EarningRulesSelectDropdown
              title={'Link to earning rule'}
              defaultValue={{
                value: watchEarningRules,
                label: watchEarningRules?.name,
              }}
              setValue={(item) => {
                const earningRule = item || {};
                setValue('linkedEarningRules', earningRule, { shouldDirty: true });
              }}
              addButtonInfo={{
                requires: PermissionCodes.changeEarningRule,
                children: <Image src={addImage} />,
                action: () => setShowCreateNewLinkPrompt(true),
                customClass: 'earning-rule-add-new-link-button',
              }}
            />
          </div>
          <ReactHookFormErrorMessage errors={errors} id="linkedEarningRules" />
        </>
      )}
    />
  );

  const campaignTypeSelectArea = (
    <Controller
      control={control}
      name="campaignType"
      render={() => (
      <div
        className="step-type-area campaign-step-one-bottom"
        style={{
          justifyContent:
            fullContainerWith < (MIN_WIDTH + SPACE_BETWEEN) * 2
              ? 'center'
              : 'flex-start',
        }}
      >
        {getCampaignTypeConfig(CampaignDisplayIn.emission).map((typeItem) => (
          <ItemTypeSet
            key={`${typeItem.id}-${typeItem.description}`}
            item={typeItem}
            selected={watchCampaignType}
            disable={isPublished}
            onClick={(id) => {
              if (id === watchCampaignType) {
                return;
              }
              clearErrors();
              setValue('campaignType', id, { shouldDirty: true });
              setValue('displayIn', CampaignDisplayIn.emission, {shouldDirty: true});
              dispatch({
                type: 'createCampaign/typeChange',
                payload: { campaignType: id },
              });
              if (id === CampaignType.earningRulesCampaign) {
                if (!promptShowed) {
                  setShowLinkPrompt(true);
                }
              }
            }}
          />
        ))}
        </div>
      )}
    />
  )

  const campaignModeSelectArea = (
    <div>
      <label className='step-one-title'>Set Mode</label>
      <Controller
        control={control}
        name="campaignType"
        render={() => (
        <div
          className="step-type-area campaign-step-one-bottom"
          style={{
            justifyContent:
              fullContainerWith < (MIN_WIDTH + SPACE_BETWEEN) * 2
                ? 'center'
                : 'flex-start',
          }}
        >
          {MissionCampaignModeConfig.map((typeItem) => (
            <ItemTypeSet
              key={`${typeItem.id}`}
              item={typeItem}
              selected={watchCampaignMode}
              moreThanThree={false}
              className={"no-image list-description"}
              disable={isPublished}
              onClick={(id) => {
                if (id === watchCampaignMode) {
                  return;
                }
                setValue("missionCampaignMode", id, {shouldDirty: true})
                setValue("linkedEarningCampaigns", [], {shouldDirty: true})
              }}
            />
          ))}
          </div>
        )}
      />
    </div>
  )

  const participointSection = (
    <>
      <label className='step-one-title'>Participant</label>
      <Row className="participant-row">
        <Controller 
          control={control}
          name='missionCampaignParticipant'
          render={() => (
            <CustomRadios
              onChange={(value) => {
                setValue('missionCampaignParticipant', value, {shouldDirty: true})
              }}
              default={watchCampaignParticipant}
              options={ParticipantConfig}
            />
          )}
        />
      </Row>
      {watchCampaignParticipant === MissionCampaignParticipant.team ? <>
      <InputFieldControl2 
        title='Minimum number of invitees (Except for the leader)'
        name={'missionCampaignMinimumNumberOfInvitees'}
        type='number'
        unit='People'
        className='coupon-campaign-section-input'
      />
      <InputFieldControl2 
        title='Maximum number of invitees (Except for the leader)'
        name={'missionCampaignMaximumNumberOfInvitees'}
        type='number'
        unit='People'
        className='coupon-campaign-section-input'
      />
      </> : null}
    </>
  )

  const showPrompt = () => {
    if (promptShowed) {
      return;
    }
    if (!showLinkPrompt) {
      return;
    }
    return (
      <CampaignLinkPrompt
        handleContinue={() => {
          dispatch({
            type: 'createCampaign/updateState',
            payload: { promptShowed: true },
          });
          dispatch({
            type: 'createCampaign/stepChange',
            payload: {
              step: 0,
              isValid: true
            }
          });
        }}
        closePrompt={() => {
          dispatch({
            type: 'createCampaign/updateState',
            payload: { promptShowed: true },
          });
          if (!watchEarningRules) {
            window.scrollTo(0, selectRef.current.offsetTop);
          }
        }}
        campaignType={watchCampaignType}
        linkedEarningRules={watchEarningRules}
      />
    );
  };
  
  const sections = () => {
    let section = [
      <div>
        <label className="step-one-title">Campaign Type</label>
        {campaignTypeSelectArea}
        {isEarningRuleRelated ? earningRuleLinkSet : null}
        {showPrompt()}
        <BasePrompt
          show={showCreateNewLinkPrompt}
          closeAction={() => setShowCreateNewLinkPrompt(false)}
          rightButton={{
            text: promptconfig.button,
            action: () => {
              const pathname ='/earns/create';
              history.push({
                pathname: pathname,
                state: { from:
                  {...history.location,
                    state: {
                      ...history.location?.state,
                      resume: true
                    }}, ...ContinueCreate },
              });
            },
          }}
          title={promptconfig.title}
          description={promptconfig.message}
        />
      </div>
    ]
    if (isMissionRelated) {
      section.push(<>
        {campaignModeSelectArea}
      </>)
      section.push(<>
        {participointSection}
      </>)
    }
    return section
  }

  const goToNextStep = (assignRemain) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 0,
      isBack: false,
    });
    // setValue('assignRemain', assignRemain);
    dispatch({
      type: 'createCampaign/stepChange',
      payload: {
        step: 0,
        isValid,
      },
    });
  };

  return (
    <>
      <ContentSections sections={sections()} hidePreview={true} />
      <OnlyContinueButton
        continueAction={async () => {
          goToNextStep(true);
        }}
        disabledContinue={
          !watchCampaignType ||
          (watchCampaignType === CampaignType.earningRulesCampaign &&
            !watchEarningRules?.pk) ||
          (watchCampaignType === CampaignType.emissionCampaign && !(watchCampaignMode?.length > 0)) ||
          (watchCampaignType === CampaignType.emissionCampaign && !(watchCampaignParticipant?.length > 0))
        }
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  campaignType: state.createCampaign.campaign.campaignType,
  linkedEarningRules: state.createCampaign.campaign.linkedEarningRules,
  status: state.createCampaign.createStatus,
  promptShowed: state.createCampaign.promptShowed,
  isPublished: state.createCampaign.campaign.isPublished,
})

export default connect(mapPropsToState)(CreateEMissionCampaignStepOne);
