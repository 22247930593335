import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ContentSections from '../../../components/base/ContentSections';
import { listItem, listImage } from '../../../components/banners/DisplayItems';
import { APIStatus } from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';
import i18n from '../../../I18n';

function VendorDetail({ vendor, status, languages }) {
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'evChargerVendor/getOneDetail',
      payload: { id: params.id },
    });
    dispatch({
      type: 'language/getList',
      payload: { isSelectorLoad: true },
    });
    return () => {
      dispatch({ type: 'evChargerVendor/clearData' });
    };
  }, [dispatch, params.id]);

  const renderTabForLanguage = (language) => {
    return [
      <>
        {listItem(
          i18n.t('name', { locale: language }),
          vendor.translations?.[language]?.name,
        )}
        {listImage('Logo', vendor?.icon)}
        {listItem('Provider Code', vendor?.providerCode)}
        {listItem('Deep link', vendor?.deepLink)}
        {listItem('Status', vendor.displayStatus)}

      </>,
    ];
  };

  const tabs = [
    {
      name: '',
      content:
        status === APIStatus.calling ? (
          <Loading />
        ) : (
          <div className="scroll-container-common">
            <ContentSections
              languageTabContent={{
                containers: languages?.map((item) => ({
                  container: renderTabForLanguage(item.code),
                  key: item.code,
                  title: item.sourceName,
                })),
              }}
              hidePreview={true}
            />
          </div>
        ),
    },
  ];

  const buttons = [];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '', //view message
          title: vendor?.name ? vendor.name : '',
        }}
        breadcrumb={
          <CustomBreadcrumb name={vendor?.name ? `${vendor.name}` : ''} />
        }
        hideTab={true}
        tabs={tabs}
        buttons={buttons}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  vendor: state.evChargerVendor.detail,
  status: state.evChargerVendor.createStatus,
  languages: state.language.allList,
});

export default connect(mapPropsToState)(VendorDetail);
