import React from 'react';
import { CampaignType, MissionCampaignMode } from '../../../config/CustomEnums';

import PreviewGeneralCampaign from '../../../assets/images/campaign/preview/preview_general_campaign.svg';
import PreviewGeneralCampaignName from '../../../assets/images/campaign/preview/preview_general_campaign_name.svg';
import PreviewGeneralCampaignShortDescription from '../../../assets/images/campaign/preview/preview_general_campaign_short_description.svg';
import PreviewGeneralCampaignBrand from '../../../assets/images/campaign/preview/preview_general_campaign_brand.svg';
import PreviewGeneralCampaignCoverPhoto from '../../../assets/images/campaign/preview/preview_general_campaign_photo.svg';
import PreviewGeneralCampaignInstructionTitle from '../../../assets/images/campaign/preview/preview_general_campaign_instruction_title.svg';
import PreviewGeneralCampaignInstructionContent from '../../../assets/images/campaign/preview/preview_general_campaign_instruction_content.svg';
import PreviewGeneralCampaignDetailTitle from '../../../assets/images/campaign/preview/preview_general_campaign_detail_title.svg';
import PreviewGeneralCampaignDetailContent from '../../../assets/images/campaign/preview/preview_general_campaign_detail_content.svg';

import PreviewCouponCampaign from '../../../assets/images/campaign/preview/preview_coupon_campaign.svg';
import PreviewCouponCampaignName from '../../../assets/images/campaign/preview/preview_coupon_campaign_name.svg';
import PreviewCouponCampaignShortDescription from '../../../assets/images/campaign/preview/preview_coupon_campaign_short_description.svg';
import PreviewCouponCampaignBrand from '../../../assets/images/campaign/preview/preview_coupon_campaign_brand.svg';
import PreviewCouponCampaignCoverPhoto from '../../../assets/images/campaign/preview/preview_coupon_campaign_photo.svg';
import PreviewCouponCampaignInstructionTitle from '../../../assets/images/campaign/preview/preview_coupon_campaign_instruction_title.svg';
import PreviewCouponCampaignInstructionContent from '../../../assets/images/campaign/preview/preview_coupon_campaign_instruction_content.svg';
import PreviewCouponCampaignDetailTitle from '../../../assets/images/campaign/preview/preview_coupon_campaign_detail_title.svg';
import PreviewCouponCampaignDetailContent from '../../../assets/images/campaign/preview/preview_coupon_campaign_detail_content.svg';

import PreviewEarnningCampaign from '../../../assets/images/campaign/preview/preview_earning_campaign.svg';
import PreviewEarnningCampaignName from '../../../assets/images/campaign/preview/preview_earning_campaign_name.svg';
import PreviewEarnningCampaignShortDescription from '../../../assets/images/campaign/preview/preview_earning_campaign_short_description.svg';
import PreviewEarnningCampaignBrand from '../../../assets/images/campaign/preview/preview_earning_campaign_brand.svg';
import PreviewEarnningCampaignCoverPhoto from '../../../assets/images/campaign/preview/preview_earning_campaign_photo.svg';
import PreviewEarnningCampaignInstructionTitle from '../../../assets/images/campaign/preview/preview_earning_campaign_instruction_title.svg';
import PreviewEarnningCampaignInstructionContent from '../../../assets/images/campaign/preview/preview_earning_campaign_instruction_content.svg';
import PreviewEarnningCampaignDetailTitle from '../../../assets/images/campaign/preview/preview_earning_campaign_detail_title.svg';
import PreviewEarnningCampaignDetailContent from '../../../assets/images/campaign/preview/preview_earning_campaign_detail_content.svg';

import PreviewEStampCampaign from '../../../assets/images/campaign/preview/preview_stamp_campaign.svg';
import PreviewEStampCampaignName from '../../../assets/images/campaign/preview/preview_stamp_campaign_name.svg';
import PreviewEStampCampaignShortDescription from '../../../assets/images/campaign/preview/preview_stamp_campaign_short_description.svg';
import PreviewEStampCampaignBrand from '../../../assets/images/campaign/preview/preview_stamp_campaign_brand.svg';
import PreviewEStampCampaignCoverPhoto from '../../../assets/images/campaign/preview/preview_stamp_campaign_photo.svg';
import PreviewEStampCampaignIcon from '../../../assets/images/campaign/preview/preview_stamp_campaign_detail_upload_stamp.svg'
import PreviewEStampCampaignInstructionTitle from '../../../assets/images/campaign/preview/preview_stamp_campaign_instruction_title.svg';
import PreviewEStampCampaignInstructionContent from '../../../assets/images/campaign/preview/preview_stamp_campaign_instruction_content.svg';
import PreviewEStampCampaignDetailTitle from '../../../assets/images/campaign/preview/preview_stamp_campaign_detail_title.svg';
import PreviewEStampCampaignDetailContent from '../../../assets/images/campaign/preview/preview_stamp_campaign_detail_content.svg';

import PreviewEMissionCampaign from '../../../assets/images/campaign/preview/preview_mission_fixed_campaign.svg';
import PreviewEMissionCampaignName from '../../../assets/images/campaign/preview/preview_mission_fixed_campaign_name.svg';
import PreviewEMissionCampaignShortDescription from '../../../assets/images/campaign/preview/preview_mission_fixed_campaign_short_description.svg';
import PreviewEMissionCampaignBrand from '../../../assets/images/campaign/preview/preview_mission_fixed_campaign_brand.svg';
import PreviewEMissionCampaignCoverPhoto from '../../../assets/images/campaign/preview/preview_mission_fixed_campaign_photo.svg';
import PreviewEMissionCampaignBadgeCard from '../../../assets/images/campaign/preview/preview_mission_fixed_campaign_badge_card.svg';
import PreviewEMissionCampaignLinkCampaign from '../../../assets/images/campaign/preview/preview_mission_fixed_campaign_link_earning_campaign.svg';
import PreviewEMissionCampaignUrl from '../../../assets/images/campaign/preview/preview_mission_fixed_campaign_mission_tnc.svg';
import PreviewEMissionCampaignInstructionTitle from '../../../assets/images/campaign/preview/preview_mission_fixed_campaign_instruction_title.svg';
import PreviewEMissionCampaignInstructionContent from '../../../assets/images/campaign/preview/preview_mission_fixed_campaign_instruction_content.svg';
import PreviewEMissionCampaignDetailTitle from '../../../assets/images/campaign/preview/preview_mission_fixed_campaign_detail_title.svg';
import PreviewEMissionCampaignDetailContent from '../../../assets/images/campaign/preview/preview_mission_fixed_campaign_detail_content.svg';

import PreviewEMissionDirectCampaign from '../../../assets/images/campaign/preview/preview_mission_direct_campaign.svg';
import PreviewEMissionDirectCampaignName from '../../../assets/images/campaign/preview/preview_mission_direct_campaign_name.svg';
import PreviewEMissionDirectCampaignShortDescription from '../../../assets/images/campaign/preview/preview_mission_direct_campaign_short_description.svg';
import PreviewEMissionDirectCampaignBrand from '../../../assets/images/campaign/preview/preview_mission_direct_campaign_brand.svg';
import PreviewEMissionDirectCampaignCoverPhoto from '../../../assets/images/campaign/preview/preview_mission_direct_campaign_photo.svg';
import PreviewEMissionDirectCampaignLinkCampaign from '../../../assets/images/campaign/preview/preview_mission_direct_campaign_link_earning_campaign.svg';
import PreviewEMissionDirectCampaignUrl from '../../../assets/images/campaign/preview/preview_mission_direct_campaign_mission_tnc.svg';
import PreviewEMissionDirectCampaignInstructionTitle from '../../../assets/images/campaign/preview/preview_mission_direct_campaign_instruction_title.svg';
import PreviewEMissionDirectCampaignInstructionContent from '../../../assets/images/campaign/preview/preview_mission_direct_campaign_instruction_content.svg';
import PreviewEMissionDirectCampaignDetailTitle from '../../../assets/images/campaign/preview/preview_mission_direct_campaign_detail_title.svg';
import PreviewEMissionDirectCampaignDetailContent from '../../../assets/images/campaign/preview/preview_mission_direct_campaign_detail_content.svg';

// import TipsCouponWithOutDetailTitleCom from '../../../assets/images/campaign/coupon_without_detail_title.svg';
// import TipsCouponWithOutDetailCom from '../../../assets/images/campaign/coupon_without_detail.svg';
// import TipsCouponWithOutInstructionTitleCom from '../../../assets/images/campaign/coupon_without_instruction_title.svg';
// import TipsCouponWithOutInstructionCom from '../../../assets/images/campaign/coupon_without_instruction.svg';

// import TipsEarningWithOutDetailTitleCom from '../../../assets/images/campaign/earning_without_detail_title.svg';
// import TipsEarningWithOutDetailCom from '../../../assets/images/campaign/earning_without_detail.svg';
// import TipsEarningWithOutInstructionTitleCom from '../../../assets/images/campaign/earning_without_instruction_title.svg';
// import TipsEarningWithOutInstructionCom from '../../../assets/images/campaign/earning_without_instruction.svg';

// import TipsGeneralWithOutDetailTitleCom from '../../../assets/images/campaign/general_without_detail_title.svg';
// import TipsGeneralWithOutDetailCom from '../../../assets/images/campaign/general_without_detail.svg';

// const withOutTitle = (title, defaultText) =>
//   `The ${title} section title is optional, if didn’t input this part, then will show “${defaultText}” default in app. Please refer to the following sample.`;
// const withOutSection = (title) =>
//   `The ${title} section is optional, if didn’t input this part, then will hide this part in app. Please refer to the following sample.`;
// const withOutInstructionTitleText = withOutTitle('instruction', 'How to use');
// const withOutDetailTitleText = withOutTitle('detail', 'Details');
// const withOutInstruction = withOutSection('instruction');
// const withOutDetail = withOutSection('detail');

const couponCampaignPreview = {
  name: PreviewCouponCampaignName,
  shortDescription: PreviewCouponCampaignShortDescription,
  selectedBrand: PreviewCouponCampaignBrand,
  otherPhotos: PreviewCouponCampaignCoverPhoto,
  instructionTitle: PreviewCouponCampaignInstructionTitle,
  instructionContent: PreviewCouponCampaignInstructionContent,
  detailsTitle: PreviewCouponCampaignDetailTitle,
  detailsContent: PreviewCouponCampaignDetailContent,
};

export const earningRulesCampaignPreview = {
  name: PreviewEarnningCampaignName,
  shortDescription: PreviewEarnningCampaignShortDescription,
  selectedBrand: PreviewEarnningCampaignBrand,
  otherPhotos: PreviewEarnningCampaignCoverPhoto,
  instructionTitle: PreviewEarnningCampaignInstructionTitle,
  instructionContent: PreviewEarnningCampaignInstructionContent,
  detailsTitle: PreviewEarnningCampaignDetailTitle,
  detailsContent: PreviewEarnningCampaignDetailContent,
};

const generalMessageCampaignPreview = {
  name: PreviewGeneralCampaignName,
  shortDescription: PreviewGeneralCampaignShortDescription,
  selectedBrand: PreviewGeneralCampaignBrand,
  otherPhotos: PreviewGeneralCampaignCoverPhoto,
  instructionTitle: PreviewGeneralCampaignInstructionTitle,
  instructionContent: PreviewGeneralCampaignInstructionContent,
  detailsTitle: PreviewGeneralCampaignDetailTitle,
  detailsContent: PreviewGeneralCampaignDetailContent,
};

const eStampCampaignPreview = {
  name: PreviewEStampCampaignName,
  shortDescription: PreviewEStampCampaignShortDescription,
  selectedBrand: PreviewEStampCampaignBrand,
  otherPhotos: PreviewEStampCampaignCoverPhoto,
  stampIcons: PreviewEStampCampaignIcon,
  instructionTitle: PreviewEStampCampaignInstructionTitle,
  instructionContent: PreviewEStampCampaignInstructionContent,
  detailsTitle: PreviewEStampCampaignDetailTitle,
  detailsContent: PreviewEStampCampaignDetailContent,
};

const eMissionFixModeCampaignPreview = {
  name: PreviewEMissionCampaignName,
  shortDescription: PreviewEMissionCampaignShortDescription,
  selectedBrand: PreviewEMissionCampaignBrand,
  otherPhotos: PreviewEMissionCampaignCoverPhoto,
  badgeCard: PreviewEMissionCampaignBadgeCard,
  linkCampaign: PreviewEMissionCampaignLinkCampaign,
  tncUrl: PreviewEMissionCampaignUrl,
  instructionTitle: PreviewEMissionCampaignInstructionTitle,
  instructionContent: PreviewEMissionCampaignInstructionContent,
  detailsTitle: PreviewEMissionCampaignDetailTitle,
  detailsContent: PreviewEMissionCampaignDetailContent,
};

const eMissionDynamicModeCampaignPreview = {
  name: PreviewEMissionDirectCampaignName,
  shortDescription: PreviewEMissionDirectCampaignShortDescription,
  selectedBrand: PreviewEMissionDirectCampaignBrand,
  otherPhotos: PreviewEMissionDirectCampaignCoverPhoto,
  linkCampaign: PreviewEMissionDirectCampaignLinkCampaign,
  tncUrl: PreviewEMissionDirectCampaignUrl,
  instructionTitle: PreviewEMissionDirectCampaignInstructionTitle,
  instructionContent: PreviewEMissionDirectCampaignInstructionContent,
  detailsTitle: PreviewEMissionDirectCampaignDetailTitle,
  detailsContent: PreviewEMissionDirectCampaignDetailContent,
};

export const getPreviewLayoutImages = (campaignType, mode = null) => {
  if (campaignType === CampaignType.couponsCampaign) {
    return couponCampaignPreview;
  }
  if (campaignType === CampaignType.earningRulesCampaign) {
    return earningRulesCampaignPreview;
  }
  if (campaignType === CampaignType.estampCampaign) {
    return eStampCampaignPreview;
  }
  if (campaignType === CampaignType.emissionCampaign && mode === MissionCampaignMode.fixedRewards) {
    return eMissionFixModeCampaignPreview;
  }
  if (campaignType === CampaignType.emissionCampaign && mode === MissionCampaignMode.dynamicRewards) {
    return eMissionDynamicModeCampaignPreview;
  }
  return generalMessageCampaignPreview;
};

// export const getTipsImages = (campaignType) => {
//   let detailsTitleImage = TipsCouponWithOutDetailTitleCom;
//   let detailsContentImage = TipsCouponWithOutDetailCom;
//   let instructionTitleImage = TipsCouponWithOutInstructionTitleCom;
//   let instructionContentImage = TipsCouponWithOutInstructionCom;

//   if (campaignType === CampaignType.generalMessageCampaign) {
//     detailsTitleImage = TipsGeneralWithOutDetailTitleCom;
//     detailsContentImage = TipsGeneralWithOutDetailCom;
//     instructionTitleImage = null;
//     instructionContentImage = null;
//   }
//   if (campaignType === CampaignType.earningRulesCampaign) {
//     detailsTitleImage = TipsEarningWithOutDetailTitleCom;
//     detailsContentImage = TipsEarningWithOutDetailCom;
//     instructionTitleImage = TipsEarningWithOutInstructionTitleCom;
//     instructionContentImage = TipsEarningWithOutInstructionCom;
//   }
//   const tips = {
//     detailsTitle: {
//       text: withOutDetailTitleText,
//       image: detailsTitleImage,
//     },
//     detailsContent: {
//       text: withOutDetail,
//       image: detailsContentImage,
//     },
//   };
//   if (instructionTitleImage && instructionContentImage) {
//     tips.instructionTitle = {
//       text: withOutInstructionTitleText,
//       image: instructionTitleImage,
//     };
//     tips.instructionContent = {
//       text: withOutInstruction,
//       image: instructionContentImage,
//     };
//   }
//   return tips;
// };

export const defaultPreviewImage = (campaignType, mode = null) => {
  if (campaignType === CampaignType.couponsCampaign) {
    return PreviewCouponCampaign;
  }
  if (campaignType === CampaignType.earningRulesCampaign) {
    return PreviewEarnningCampaign;
  }
  if (campaignType === CampaignType.estampCampaign) {
    return PreviewEStampCampaign;
  }
  if (campaignType === CampaignType.emissionCampaign && mode === MissionCampaignMode.fixedRewards) {
    return PreviewEMissionCampaign;
  }
  if (campaignType === CampaignType.emissionCampaign && mode === MissionCampaignMode.dynamicRewards) {
    return PreviewEMissionDirectCampaign;
  }
  return PreviewGeneralCampaign;
};
