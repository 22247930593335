import { getInFilterValue, getSearchKey } from '../utils';
import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getCouponTransactions = (afterCursor = null, search = {}) => {
  let searchString = `, orderBy: "${search.rank ? '-pk' : 'pk'}"`;
  if (search?.sort) {
    searchString = `, orderBy: "${search?.sort}"`;
  }
  if (search.searchKey) {
    searchString += `, membershipPhone: "${getSearchKey(search.searchKey)}"`;
  }
  if (search.coupon_name) {
    searchString += `, couponTemplateIn: ${getInFilterValue(search.coupon_name)}`;
  }
  if (search.record_type) {
    searchString += `, typeIn: ${getInFilterValue(search.record_type)}`;
  }
  if (search.create_date) {
    const createDate = search.create_date.split(',');
    searchString += `, creationDateGte: "${createDate[0]}", creationDateLte: "${createDate[1]}"`;
  }

  if (search.expired_date) {
    switch (search.expired_date) {
      case 'absolute':
        if (search.absolute) {
          const expiredDateRange = search.absolute.split(',');
          searchString += `, absoluteGte: "${expiredDateRange[0]}", absoluteLte: "${expiredDateRange[1]}"`;
        }
        break;
      case 'relativeDay':
        if (search.relativeDay) {
          searchString += `, relativeDay: ${search.relativeDay}`;
        }
        break;
      default:
        searchString += `, allTime: true`;
        break;
    }
  }

  const query = `{
        couponTransactions(
          first: 20,
          after: "${afterCursor}"${searchString}
        ) {
          totalCount
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          edges {
            cursor
            node {
              id
              pk
              type
              pointsSpent
              creationDate
              customer {
                pk
                ssoUid
                firstName
                lastName
                nickname
                membershipId
                mobilePhoneNumberCountryCode
                mobilePhoneNumberSubscriberNumber
              }
              campaign {
                pk
                name
                earningCampaignTypeEarningRule {
                    pk
                    name
                  }
              }
              coupon {
                id
                pk
                effectiveDate
                expiryDate
                serialNumber
                template {
                    name
                    validPeriodType
                    absoluteEffectiveDate
                    absoluteExpiryDate
                    numberOfDaysToExpireAfterAcquisition
                    translations {
                      edges {
                        node {
                          language
                          name
                          pk
                        }
                      }
                    }
                  }
              }
              administrator {
                pk
                username
              }
            }
          }
        }
      }`;
  const variables = {
    input: {},
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCouponTransaction = (couponTransactionID) => {
  const query = `
  {
    couponTransaction(id: "${couponTransactionID}") {
      id
      pk
      type
      pointsSpent
      creationDate
      usedAt
      store {
        name
        translations {
          edges {
            node {
              language
              name
            }
          }
        }
        mall {
          name
          translations {
            edges {
              node {
                language
                name
              }
            }
          }
        }
        freshMarket {
          name
          translations {
            edges {
              node {
                language
                name
              }
            }
          }
        }
      }
      customer {
        pk
        ssoUid
        firstName
        lastName
        nickname
        membershipId
        mobilePhoneNumberCountryCode
        mobilePhoneNumberSubscriberNumber
      }
      campaign {
        pk
        name
        earningCampaignTypeEarningRule {
          pk
          name
          translations {
            edges {
              node {
                language
                name
                pk
              }
            }
          }
        }
        translations {
          edges {
            node {
              language
              name
              pk
            }
          }
        }
      }
      detailCampaign {
        pk
        name
        earningCampaignTypeEarningRule {
          pk
          name
          translations {
            edges {
              node {
                language
                name
                pk
              }
            }
          }
        }
        translations {
          edges {
            node {
              language
              name
              pk
            }
          }
        }
      }
      coupon {
        id
        pk
        effectiveDate
        expiryDate
        template {
          name
          validPeriodType
          absoluteEffectiveDate
          absoluteExpiryDate
          numberOfDaysToExpireAfterAcquisition
          translations {
            edges {
              node {
                language
                name
                pk
              }
            }
          }
        }
      }
      administrator {
        pk
        username
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const useCouponBySerialNumber = (serialNumber) => {
  const query = `mutation ConciergeUseCoupon($input: ConciergeUseCouponInput!) {
    conciergeUseCoupon(input: $input) {
      success
      node {
        success
        coupon {
          id
          pk
          template {
            name
            translations {
              edges {
                node {
                  language
                  name
                }
              }
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: {
      serialNumber,
    }
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
