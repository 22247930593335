import React, { Component } from 'react';
import './CouponKeyVisual.scss';
import ImageUploader from '../../base/ImageUploader';
import { useDispatch, connect } from 'react-redux';
import { createAction, insertKeyToAllLanguages } from '../../../utils';
import {
  isShowError,
  firstError,
  CampaignErrorHandleField
} from '../../campaign/campaignCreation/CreateCampaignHandleError';
import { CouponErrorFields } from './CouponHandleError';
import i18n from '../../../I18n';
import { LanguageConfig } from '../../../config/CustomEnums';


import './CouponKeyVisual.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { ApplySwitch } from '../../base/LabelWithSwitch';
import {
  errorMessage
} from '../../base/ErrorFieldMessage';

const CouponKeyVisual = ({
  language,
  languages,
  onFocus = (() => {}),
}) => {
  const languagesList = languages?.map((item) => item.code);
  const dispatch = useDispatch();
  const { control, watch, setValue, formState, getValues } = useFormContext();
  const { errors } = formState;
  const watchCoverPhoto = watch(`translations.${language}.coverPhoto`);
  const watchOtherPhoto = watch(`translations.${language}.otherPhoto`) || [];
  const applyCoverPhotoToAllLanguage = watch('applyCoverPhotoToAllLanguage');
  const applyDetailPhotosToAllLanguage = watch(
    'applyDetailPhotosToAllLanguage',
  );
  const coverPhoto = [watchCoverPhoto]?.filter((item) => item);
  const otherPhotos = watchOtherPhoto?.filter((item) => item);

  const watchRedemptionMethod = watch('redemptionMethod');
  return (
    <>
      <label className="create-section-title">
        {i18n.t('key_visual', { locale: language })}
      </label>
      {language === LanguageConfig.english ? null : (
        <label className="create-campaign-cover-photo-reminder">
          {i18n.t('campaign.cover_photo_reminder', { locale: language })}
        </label>
      )}

      <label className="create-section-label create-section-label-bottom-space">
        {i18n.t('cover_photo', { locale: language })}
      </label>
      <label className="create-campaign-cover-photo-reminder">
        {i18n.t('cover_photo_suggest', { locale: language })}
      </label>
      <ImageUploader
        minWidth={900}
        minHeight={600}
        disabled={language !== LanguageConfig.english && applyCoverPhotoToAllLanguage}
        images={coverPhoto}
        onImageStateChange={(newState) => {
          const applyLanguages = applyCoverPhotoToAllLanguage
            ? languagesList
            : [language];
          applyLanguages.forEach((item) => {
            setValue(`translations.${item}.coverPhoto`, newState[0], {
              shouldDirty: true,
            });
          });
        }}
        aspect={3 / 2}
        maxImageNum={1}
        uploadImageClicked={() => onFocus('coverPhoto')}
        language={language}
        photoSectionTitle={'cover_photo'}
        errorMessage={errorMessage(
          errors,
          `translations.${language}.coverPhoto`,
        )}
      />
      <span id={`${CampaignErrorHandleField.coverPhoto.name}-${language}`} />
      {/* <ReactHookFormErrorMessage errors={errors} id={`translations.${language}.coverPhoto`} /> */}
      <ApplySwitch
        disabled={
          false
        }
        show={language === LanguageConfig.english}
        checked={applyCoverPhotoToAllLanguage}
        onChange={(value) => {
          console.log("@267", value)
          setValue('applyCoverPhotoToAllLanguage', value, {
            shouldDirty: true,
          });
          if (value) {
            insertKeyToAllLanguages({
              languages: languagesList,
              key: 'coverPhoto',
              value: watchCoverPhoto,
              setValue,
              getValues,
            });
          }
        }}
      />
      <label className="create-section-label create-section-label-bottom-space">
        {i18n.t('other_photos', { locale: language })}
      </label>
      <label className="create-campaign-cover-photo-reminder">
        {i18n.t('cover_photo_suggest', { locale: language })}
      </label>
      <ImageUploader
        minWidth={900}
        minHeight={600}
        images={otherPhotos}
        disabled={language !== LanguageConfig.english && applyDetailPhotosToAllLanguage}
        onImageStateChange={(newState) => {
          const applyLanguages = applyDetailPhotosToAllLanguage
            ? languagesList
            : [language];
          applyLanguages.forEach((item) => {

            setValue(`translations.${item}.otherPhoto`, newState, {
              shouldDirty: true,
            });
          });
        }}
        aspect={3 / 2}
        maxImageNum={4}
        language={language}
        photoSectionTitle={'other_photo'}
        uploadImageClicked={() => onFocus('otherPhotos')}
      />
      <ApplySwitch
        disabled={false}
        show={language === LanguageConfig.english}
        checked={applyDetailPhotosToAllLanguage}
        onChange={(value) => {
          setValue('applyDetailPhotosToAllLanguage', value, {
            shouldDirty: true,
          });
          if (value) {
            insertKeyToAllLanguages({
              languages: languagesList,
              key: 'otherPhoto',
              value: watchOtherPhoto,
              setValue,
              getValues,
            });
          }
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  languages: state.language.allList,
});

export default connect(mapPropsToState)(CouponKeyVisual);
