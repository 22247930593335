import React from 'react';
import { LanguageConfig } from '../../config/CustomEnums';
import { listItem, listImage } from '../banners/DisplayItems';
import i18n from '../../I18n';
import { addDomainToImage } from '../../utils';
import CustomViewer from '../base/CustomViewer';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';

function ThemedSectionOverviewTableSection(props) {
  const themeSection = props.themeSection || {};
  const language = props.language || LanguageConfig.english;
  const isEnglish = language === LanguageConfig.english;

  const description = isEnglish ? themeSection.description : themeSection.translations?.[language]?.description;
  const coverPhoto = isEnglish ? themeSection.coverPhoto : themeSection.translations?.[language]?.coverPhoto;

  return (
    <>
      {coverPhoto == null || coverPhoto === ''
        ? listItem(i18n.t('cover_photo', { locale: language }), '-', 'create-section-label-no-top-space')
        : listImage(
            i18n.t('cover_photo', { locale: language }),
            addDomainToImage(coverPhoto),
            'create-section-label-no-top-space',
          )}
      <CustomTitleLabel title={i18n.t('description', { locale: language })} />
      <label className="custom-record-normal-font custom-timeline-time-color list-new-line">
        <CustomViewer initValue={description} />
      </label>
    </>
  );
}

export default ThemedSectionOverviewTableSection;
