import { APIStatus, LanguageConfig, SESSION_KEYS } from '../config/CustomEnums';
import {
  createStoreCategoryLv4,
  getStoreCategoryLv4,
  updateStoreCategoryLv4,
  deleteStoreCategoriesLv4,
  getStoreCategoryListLv4,
} from '../services/StoreCategoryLv4APIHelper';
import { convertPKToId } from '../utils';
import { createModel } from './BaseModel';
import {
  assembleStoreCategory,
  assembleStoreCategoryList,
  fixTranslations,
  getCreateStoreCategory,
} from './CreateStoreCategoryLv3Model';
import { apiWithResponseHandle } from './LoadingUtil';

const getInitialState = () => ({
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    {
      displayName: 'Store category Lv4',
      fieldName: 'name',
      orderField: 'name',
    },
    // {
    //   displayName: 'Display order',
    //   fieldName: 'order',
    //   orderField: 'displayPriority',
    // },
    {
      displayName: 'Store category Lv3',
      fieldName: 'formattedParentCategoryName',
    },
  ],
  detail: {
    name: null,
    order: null,
    parentCategories: [],
    translations: [],
  },
  formChanged: false,
  saved: -1,
});

export const STORE_CATEGORY_LV4_ORDER_LAST = 'STORE_CATEGORY_LV4_ORDER_LAST';

export default createModel({
  namespace: 'createStoreCategoryLv4',
  states: getInitialState(),
  params: {
    listAPI: getStoreCategoryListLv4,
    parse: (data) => assembleStoreCategoryList(data.storeLevel4Categories.edges),
    pkNode: 'StoreLevel4CategoryNode',
    detailAPI: getStoreCategoryLv4,
    parseDetail: (data) => assembleStoreCategory(data.storeLevel4Category),
    deleteAPI: deleteStoreCategoriesLv4,
    sessionKey: SESSION_KEYS.STORE_CATEGORY_LV4_SESSION_KEY,
    objectKey: 'storeLevel4Categories',
    initState: getInitialState(),
  },
  reducers: {},
  effects: {
    createOrUpdate: [
      function* ({ payload }, { put }) {
        const { values, categoryID } = payload;
        yield put({
          type: 'updateState',
          payload: {
            createStatus: APIStatus.calling,
          },
        });
        const input = getCreateStoreCategory(values);
        let serviceArgs = [createStoreCategoryLv4, input];
        if (categoryID) {
          const id = parseInt(categoryID);
          if (isNaN(id)) {
            return;
          }
          const [translationsToDelete, fixedTranslations] = fixTranslations(
            input.translations,
          );

          const uploadData = {
            id,
            name: input.name,
            displayPriority: input.displayPriority,
            translations: fixedTranslations,
            translationsToDelete: translationsToDelete,
            parentCategories: input.parentCategories,
          };
          serviceArgs = [updateStoreCategoryLv4, uploadData];
        }
        yield put({
          type: 'responseCreateOrUpdate',
          payload: { serviceArgs, isCreate: true },
        });
      },
      { type: 'takeLatest' },
    ],
    updateCategoryOrder: [
      function* ({ payload }, { call, put, select }) {
        const { id, order, afterAction } = payload;
        const input = {
          id,
        };
        if (order === STORE_CATEGORY_LV4_ORDER_LAST) {
          const countResponse = yield call(getStoreCategoryListLv4, '', '');
          const countResponseData = countResponse.data;
          if (countResponseData?.data?.error) {
            return;
          }
          input.displayPriority =
            countResponseData.data.storeLevel4Categories.totalCount;
        } else {
          input.displayPriority = order;
        }
        const response = yield call(updateStoreCategoryLv4, input);
        const responseData = response.data;
        if (
          responseData.errors ||
          responseData.data.updateStoreLevel4Category.errors
        ) {
          return;
        }
        if (afterAction) {
          afterAction();
        }
      },
      {
        type: 'takeEvery',
      },
    ],
    duplicateCategory: [
      function* ({ payload }, { call, put, race, take }) {
        const { pk, afterAction } = payload;
        const id = convertPKToId('StoreLevel4CategoryNode', pk);
        const response = yield call(getStoreCategoryLv4, id);
        const responseData = response.data;
        if (
          responseData === undefined ||
          responseData.data?.storeLevel4Category.pk === null
        ) {
          return;
        }
        const category = assembleStoreCategory(responseData.data.storeLevel4Category);
        const input = getCreateStoreCategory(category);
        const uploadData = {
          name: `copy of ${input.name}`,
          displayPriority: input.displayPriority + 1,
          translations: input.translations.map((translation) => ({
            name: translation.name,
            language: translation.language,
          })),
          parentCategories: input.parentCategories,
        };
        console.log('@337', uploadData);
        function* onFailed(data) {
          console.log('success', data);
        }
        const serviceArgs = [createStoreCategoryLv4, uploadData];
        function* onSuccess(data) {
          if (afterAction) {
            yield afterAction();
          }
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      {
        type: 'takeEvery',
      },
    ],
  },
});
