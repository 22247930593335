import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import './StoreList.scss';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { NavBarNames } from '../../../config/NavBarNameList';
import DeletePrompt from '../../../components/base/DeletePrompt';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import Filter from './Filter';
import { DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';


function StoreList({
  listFields,
  dataList,
  pageInfo,
  totalPage,
  totalCount,
  checkedList,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  //   const [activeTab, setActiveTab] = useState(tabs[0].name);
  const [sortDisabled, setSortDisabled] = useState(false);

  const buttons = [
    // <AuthButton
    //   title="Create Store"
    //   action={() => {
    //     history.push('/stores/create');
    //   }}
    //   requires={PermissionCodes.addStore}
    // />,
  ];

  const groupActions = [
    // {
    //   name: 'Delete',
    //   action: () => {
    //     setShowDeletePrompt(true);
    //   },
    //   requires: PermissionCodes.deleteStore,
    // },
  ];

  const tabs = [
    {
      name: 'List of Stores',
      content: (
        <BaseTabListContainer
          hideTab={true}
          tabs={[]}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          filter={{
            hasFilter: true,
            componment: Filter,
          }}
          listContent={
            <BaseListContainer
              fields={listFields}
              dataList={dataList}
              totalPage={totalPage}
              model={'storeModel'}
              permissionGroup={PermissionCodes.store}
              deleteInfo={{
                data: [],
                title: 'store',
                relatedName: '',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.STORE,
              }}
              sortable
              sortDisabled={sortDisabled}
              rankDisabled
              // onSortEnd={(oldIndex, newIndex) => {
              //   if (oldIndex === newIndex) return;
              //   const startOrder = dataList[0].order;
              //   // const newDataList = arrayMove(dataList, oldIndex, newIndex);

              //   const movedItem = dataList[oldIndex];
              //   dispatch({
              //     type: 'storeModel/createOrUpdate',
              //     payload: {
              //       data: {
              //         id: movedItem.pk,
              //         displayPriority: startOrder + newIndex,
              //       },
              //       afterAction: () => history.push(location),
              //     }
              //   });
              //   // setDataList(
              //   //   newDataList.map((data, index) => ({
              //   //     ...data,
              //   //     order: startOrder + index,
              //   //   })),
              //   // );
              // }}
              // onOrderChangedByEdit={(pk, newOrder) => {
              //   dispatch({
              //     type: 'storeModel/createOrUpdate',
              //     payload: {
              //       data: {
              //         id: pk,
              //         displayPriority: newOrder,
              //       },
              //       afterAction: () => history.push(location),
              //     }
              //   });
              // }}
              actions={['Detail']}
              // hasOtherActions
              // customActions={{
              //   moveToFirst: (item) => {
              //     dispatch({
              //       type: 'storeModel/createOrUpdate',
              //       payload: {
              //         data: {
              //           id: item.pk,
              //           displayPriority: 1,
              //         },
              //         afterAction: () => history.push(location),
              //       }
              //     });
              //   },
              //   moveToLast: (item) => {
              //     dispatch({
              //       type: 'storeModel/createOrUpdate',
              //       payload: {
              //         data: {
              //           id: item.pk,
              //           displayPriority: totalCount,
              //         },
              //         afterAction: () => history.push(location),
              //       }
              //     });
              //   },
              // }}
            />
          }
        />
      ),
    },
  ];

  useEffect(() => {
    dispatch({ type: 'storeModel/clearData' });

    const queryString = require('query-string');
    if (queryString) {
      const querySearch = queryString.parse(location.search);
      const searchKey = querySearch['search'] || '';
      const stringRank = querySearch['rank'] || 'true';
      const stringPage = querySearch['page'] || 1;
      const rank = stringRank === 'true';
      const page = parseInt(stringPage);
      dispatch({
        type: 'storeModel/getPagedStoreList',
        payload: {
          ...querySearch,
          page,
          reverse: rank,
          search: searchKey,
        }
      });
    }
    dispatch({
      type: 'storeCategoryList/getAllStoreSubcategoryList',
      payload: {}
    });
    dispatch({
      type: 'brand/getSimpleBrandList',
      payload: {}
    });
  }, [dispatch, location]);

  const detail = 'After you create stores, you can specify which store the coupon can be used in.   A store may belongs to a brand.';

  return (
    <>
      <CustomListComponent
        caution={{
          detail: detail,
          title: NavBarNames.stores,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={false}
      />
      <DeletePrompt
        data={checkedList}
        title={'stores'}
        relatedName={''}
        relatedSections={DELETE_RELATED_SECTIONS.STORE}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: 'storeModel/delete',
            payload: {
              afterAction: () => {
                history.push(location);
              },
            }
          });
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  listFields: state.storeModel.listDisplayFields,
  dataList: state.storeModel.pagedStoreList,
  pageInfo: state.storeModel.pageInfo,
  totalPage: state.storeModel.totalPage,
  totalCount: state.storeModel.totalCount,
  checkedList: state.storeModel.checkedList,
});

export default connect(mapPropsToState)(StoreList);
