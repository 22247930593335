import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { EarningRuleRewardType, EarningRuleType } from '../../../config/CustomEnums';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackWithOutTempButtons } from '../../../components/base/BottomStepComponent';
import RewardSection from '../../../components/earning/RewardSection';
import GeneralPurchaseSection from '../../../components/earning/GeneralPurchaseSection';
import GPSCheckInSection from '../../../components/earning/GPSCheckInSection';
import ReferralSection from '../../../components/earning/ReferralSection';
import BirthdaySection from '../../../components/earning/BirthdaySection';
import CodeSection from '../../../components/earning/CodeSection';
import { createAction, getObjectFromSessionStorage } from '../../../utils';
import { validate } from './CreateEarningRuleValidate';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import FillingFormSection from '../../../components/earning/FillingFormSection';
import LimitSection from '../../../components/earning/LimitSection';
import BonusRequirementSection from '../../../components/earning/BonusRequirementSection';

const dataArray = [];

function CreateEarningruleStepThree({
  earningRule
}) {
  const { getValues, watch, formState, clearErrors, setError } =
    useFormContext();
  const errors = formState.errors;
  const fieldError = Object.keys(errors || []).length;
  const [earningRuleType] = getValues(['type']);
  const history = useHistory();

  const dispatch = useDispatch();

  const watchRewardType = watch('rewardType');

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 2,
      isBack,
    });
    if (isBack) {
      dispatch({
        type: 'createEarningRules/stepChange',
        payload: { isBack: isBack, step: 2, isValid },
      });
    } else if (isValid) {
      dispatch(
        createAction('createEarningRules/createOrUpdateEarningRule')({
          isValid: isValid,
          data: getValues(),
          afterAction: earningRule.id ? () => history.push({ pathname: '/earns' }) : null
        }),
      );
    }
    
  };

  const earningRuleProperties = () => {
    let properties;
    switch (earningRuleType) {
      case EarningRuleType.generalPurchase:
        properties = [
          <GeneralPurchaseSection/>,
          watchRewardType === EarningRuleRewardType.stamp ? (
            <BonusRequirementSection />
          ) : null,
          <LimitSection
            title='LIMIT'
            tips={{
              'overallLimit': 'Maximum number of times to do general purchase per campaign.',
              'periodicLimit': 'Maximum number of times to do general purchase per period.',
              'perHeadLimit': 'Maximum number of times to do general purchase per customer.',
              'perHeadPeriodicLimit': 'Maximum number of times to do general purchase per customer per period.'
            }}
          />
        ];
        break;
      case EarningRuleType.gpsCheckIn:
        properties = [
          <GPSCheckInSection/>
        ];
        break;
      case EarningRuleType.memberReferral:
        properties = [
          <ReferralSection/>
        ];
        break;
      case EarningRuleType.birthday:
        properties = [
          <BirthdaySection/>
        ];
        break;
      case EarningRuleType.qrCodeScanning:
        properties = [
          <CodeSection
            sectionTitle='QR CODE SCANNING'
            inputTitle='Input QR codes'
            importTitle='Import QR codes'
            title='QR codes'
            tips={{
              inputTip: 'Separate the QR codes by comma.',
              importTip: 'Upload a CSV file contains QR codes.'
            }}
            name='qrCodes'
            defaultValue={earningRule?.qrCodes}
            hasLimitSection={true}
            limitTips={{
              'overallLimit': 'Maximum number of times to scan QR code per campaign.',
              'periodicLimit': 'Maximum number of times to scan QR code per period.',
              'perHeadLimit': 'Maximum number of times to scan QR code per customer.',
              'perHeadPeriodicLimit': 'Maximum number of times to scan QR code per customer per period.'
            }}
          />
        ];
        break;
      case EarningRuleType.fillingForm:
        properties = [
          <FillingFormSection/>
        ];
        break;
      case EarningRuleType.newMember:
        properties = [
          <CodeSection
            sectionTitle='Promotion code'
            inputTitle='Input Promotion codes'
            importTitle='Import Promotion codes'
            title='Promotion codes'
            tips={{
              inputTip: 'Separate the Promotion codes by comma.',
              importTip: 'Upload a CSV file contains Promotion codes.'
            }}
            name='newMemberTypePromotionCodes'
            defaultValue={earningRule?.newMemberTypePromotionCodes}
          />
        ];
        break;
      default:
        properties = [];
        break;
    }
    return properties;
  };

  // const earningRulePropertie = earningRuleProperties();
  const sections = [
    <RewardSection/>,
    ...earningRuleProperties(),
  ];

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <SaveAndBackWithOutTempButtons
        saveText={'Save'}
        saveAction={() => {
          stepChangeAction(false);
        }}
        backAction={() => {
          stepChangeAction(true);
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  earningRule: state.createEarningRules.earningRule
})

export default connect(mapPropsToState)(CreateEarningruleStepThree);
