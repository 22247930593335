import React from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import ContentSections from '../base/ContentSections';
import { validate } from '../../containers/conciergeApp/transactionRecord/CreateTransactionValidate';
import TransactionGeneralSection from './TransactionGeneralSection';
import { OnlyContinueButton } from '../base/BottomStepComponent';
import i18n from '../../I18n';
import TransactionCampaignSection from './TransactionCampaignSection';
import TransactionStepOneDropdownSection from './TransactionStepOneDropdownSection';

function CreateTransactionRecordStepOne({ language, isTCTransaction }) {
  const dispatch = useDispatch();

  const { watch, getValues, clearErrors, setError, setValue, formState } = useFormContext();
  const { errors } = formState;

  const stampCampaignField = 'stampCampaign';
  const missionCampaignField = 'missionCampaign';
  const otherCampaignField = 'otherCampaign';
  const carParkCampaignField = 'carParkCampaign';
  const regionField = 'region';
  const districtField = 'district';
  const mallField = 'mall';
  const receiptsField = 'receipts';
  const staffNameField = 'staffName';
  const remarksField = 'remarks';

  const stampCampaign = watch(stampCampaignField);
  const missionCampaign = watch(missionCampaignField);
  const otherCampaign = watch(otherCampaignField);
  const carParkCampaign = watch(carParkCampaignField);
  const selectedRegion = watch(regionField);
  const selectedDistrict = watch(districtField);
  const selectedMall = watch(mallField);
  const staffName = watch(staffNameField);
  const remarks = watch(remarksField);

  const receipts =
    watch(receiptsField) ||
    setValue(
      'receipts',
      [
        {
          invoiceId: 9999999999,
          transactionDate: new Date(),
        },
      ],
      { shouldDirty: true },
    );

  const hasCampaignTransactionError = ()=>(
    stampCampaign?.campaignTransactionError?.length ||
    missionCampaign?.campaignTransactionError?.length ||
    otherCampaign?.campaignTransactionError?.length ||
    carParkCampaign?.campaignTransactionError?.length
  )
  

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 0,
      isBack: isBack,
      language,
    });
    dispatch({
      type: 'createTransactionRecord/stepChange',
      payload: {
        isBack: isBack,
        step: 0,
        isValid,
        isTCTransaction,
      },
    });
  };

  const sections = [
    <TransactionGeneralSection language={language} isTCTransaction={isTCTransaction} />,
    <TransactionStepOneDropdownSection
        selectedRegion={selectedRegion}
        selectedDistrict={selectedDistrict}
        selectedMall={selectedMall}
        staffName={staffName}
        remarks={remarks}
        select={(field, value) => {
          setValue(field, value, { shouldDirty: true });
        }}
        errors={errors}
        language={language}
        receipts={receipts}
      />,
    <TransactionCampaignSection language={language} />
  ];

  return (
    <>
      <ContentSections
        sections={sections}
        hidePreview={true}
      />
        <OnlyContinueButton
          text={i18n.t('transaction.continue', { locale: language })}
          disabledContinue={hasCampaignTransactionError()}
          continueAction={() => {
            stepChangeAction(false);
          }}
        />
    </>
  );
}

export default CreateTransactionRecordStepOne;
