import React, { useState, useEffect } from 'react';
import './CouponCampaignSection.scss';

import {InputFieldControl2} from '../../base/InputFieldControl';
import { useFormContext } from 'react-hook-form';

function EStampCampaignSection({}) {
  const { watch } = useFormContext();
  const ownedCouponLimit = watch('ownedCouponLimit')

  return (
    <>
      <label className="create-section-title">Stamp card</label>
      <InputFieldControl2
        name={'ownedCouponLimit'}
        title={'Number of Stamp slot (Max. no. of stamps can be collected)'}
        value={ownedCouponLimit}
        type='number'
        className='coupon-campaign-section-input'
      />
    </>
  );
}

export default EStampCampaignSection;
