import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { createAction } from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import { APIStatus, LanguageConfig, PROPERTY_TYPE } from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import QRCodeCollapse from './QRCodeCollapse';
import { CustomTitleLabel } from '../../../components/earning/CustomBaseComponments';
import i18n from 'i18n-js';
import { listImage, listItem, listMultiImage, listStoreCategories } from '../../../components/banners/DisplayItems';
import { ToastType } from '../../../models/NavBarModel';

function StoreDetail({ store, status, languages }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      dispatch(createAction('language/getList')({ isSelectorLoad: true }));
      dispatch(
        createAction('storeModel/getOneStore')({ id: params.id, view: true }),
      );
    }
    return () => {
      dispatch({ type: 'storeModel/clearData' });
    };
  }, [dispatch, params.id]);

  const getValueFromTranslations = (language, valueKeys) => {
    const data = {};
    // console.log("110", language, valueKeys)
    const datas = valueKeys?.forEach((valueKey) => {
      data[valueKey] = store?.storeTranslations?.[language]?.[valueKey];
    });
    // console.log("@152",data)
    return data;
  };

  const renderTabForLanguage = (language) => {
    const isEnglish = language === LanguageConfig.english;

    return [
      <>
        {listItem(
          i18n.t('name', { locale: language }),
          store.storeTranslations?.[language]?.name,
        )}
        {isEnglish
          ? store.detailPhotos
            ? listMultiImage('Photos', store.detailPhotos)
            : listItem('Photos', '')
          : null}
        {listItem('Sub-district', store.storeTranslations?.[language]?.districtName)}
        {listItem('Address', store.storeTranslations?.[language]?.address)}
        {isEnglish ? (
          <>
            {listItem('Phone Number', store.phoneNumber)}
            {listItem('Website', store.website)}
          </>
        ) : null}
        {listItem(
          i18n.t('description', { locale: language }),
          store.storeTranslations?.[language]?.description,
        )}
        {listItem('Opening hours', store.storeTranslations?.[language]?.openingHours)}
        {listItem('Store type', store.storeType ? i18n.t(store.storeType, { locale: language }) : '')}
        {listItem('Related brand', store.storeTranslations?.[language]?.brandName)}
        {isEnglish ? (
          listItem('Property Type', PROPERTY_TYPE[store.propertyType])
        ) : null}
        {listItem('Related mall / FM', store.storeTranslations?.[language]?.mallOrFreshMarketName)}
        {listStoreCategories('Store category Lv1 / Lv2', store.level1Categories, language)}
        {listStoreCategories('Store category Lv3 / Lv4', store.level3Categories, language)}
        {isEnglish ? (
          <>
            {listItem('Longitude', store.longitude)}
            {listItem('Latitude', store.latitude)}
            {listItem('Staff code', store.staffCode)}
          </>
        ) : null}
      </>,
    ];
  };

  const tabs = [
    {
      name: 'Detail',
      content:
        status === APIStatus.calling ? (
          <Loading />
        ) : (
          <>
            <ContentSections
              hidePreview
              languageTabContent={{
                containers: languages?.map((item) => ({
                  container: [renderTabForLanguage(item.code)],
                  key: item.code,
                  title: item.sourceName,
                })),
              }}
              activeSection={LanguageConfig.english}
            />
            {/* <ContentSections
            sections={[
              <>
                <MembershipInfoCard
                  title="information"
                  data={store}
                  fields={[
                    [{ title: 'Display order', field: 'displayPriority' }],
                    [{ title: 'Cover image', field: 'photo' }],
                    [{ title: 'District', field: 'displayDistrict' }],
                    [{ title: 'Phone number', field: 'displayPhoneNumner' }],
                    [{ title: 'Email', field: 'emailAddress' }],
                    [{ title: 'Latitude', field: 'latitude' }],
                    [{ title: 'Longitude', field: 'longitude' }],
                    [{ title: 'Staff code', field: 'staffCode' }],
                    [{ title: 'Subcategories', field: 'displaySubcategory' }],
                    [{ title: 'Brand', field: 'displayBrand' }],
                  ]}
                />
                <CustomTitleLabel title={'QR code preview'} />
                <label>The store QR code is auto generate</label>
                <QRCodeCollapse
                  codeDisplayImage={store?.codeDisplayImage}
                  codeDownloadImage={store?.codeDownloadImage}
                />
              </>,
            ]}
            hidePreview
          /> */}
          </>
        ),
    },
  ];

  const buttons = [
    // <AuthButton
    //   title="Edit"
    //   action={() => {
    //     // dispatch({ type: 'customerList/clearData' });
    //     dispatch({ type: 'storeModel/clearData' });
    //     history.push({
    //       pathname: 'edit/',
    //     });
    //   }}
    //   requires={PermissionCodes.changeStore}
    // />,
    <AuthButton
      title="Copy deep link"
      action={() => {
        navigator.clipboard.writeText(`${process.env.REACT_APP_DEEP_LINK_DOMAIN}/?page=storeDetail&id=${store.pk}`);
        dispatch({
          type: 'navBars/updateState',
          payload: {
            saveDiscardToastShowing: {
              value: true,
              type: ToastType.copySuccess
            },
          },
        });
      }}
      customClass={'btn-back-button-common btn-download'}
      requires={PermissionCodes.changeStore}
    />
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  return (
    <div className="campaign-category-detail">
      <CustomListComponent
        caution={{
          detail: '',
          title: store.name,
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb name={store.name} />}
        buttons={buttons}
        tabs={tabs}
        onTabChange={(key) => {
          setActiveTab(key);
        }}
      />
    </div>
  );
}

const mapPropsToState = (state) => ({
  store: state.storeModel.oneStore || {},
  status: state.loading.status,
  languages: state.language.allList,
});

export default connect(mapPropsToState)(StoreDetail);
