import React, { useEffect } from 'react';
import { connect, useDispatch,  } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { APIStatus, SESSION_KEYS } from '../../../config/CustomEnums';
import CreateCarParkStepOne from './CreateCarParkStepOne';
import { removeFromSessionStorage, saveToSessionStorage } from '../../../utils';
import BaseForm from '../../../components/base/v2/BaseForm';

const CreateCarpark = ({
  carpark,
  createStatus,
  hasUpdatedDefaultValues,
  formHasSubmitted,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const caution = {
    detail: '',
    title: carpark.name,
    model: 'carpark',
  };

  useEffect(() => {
    const id = params.id;
    if (id) {
      dispatch({ type: 'carpark/getOneDetail', payload: { id: id } });
    } else {
      dispatch({ type: 'carpark/loadFromCookie' });
    }
    return () => {
      dispatch({ type: 'carpark/clearData' });
    };
  }, [dispatch, params.id]);

  useEffect(() => {
    if (
      createStatus === APIStatus.success &&
      formHasSubmitted &&
      history.location.pathname.includes('edit')
    ) {
      history.push('/carparks');
      return;
    }
  }, [formHasSubmitted, createStatus, history]);

  const stepSet = [<CreateCarParkStepOne />];
  return (
    <div>
      <BaseForm
        defaultValues={carpark}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        tempSave={(save, getValues) => {
          if (save) {
            saveToSessionStorage(
              SESSION_KEYS.CARPARK_SESSION_KEY,
              getValues(),
            );
          } else {
            removeFromSessionStorage(SESSION_KEYS.CARPARK_SESSION_KEY);
          }
        }}
        showFinishPop={createStatus === APIStatus.success}
        nextStepConfig={{
          title: 'Successfully Updated!',
          description: 'Carpark is successfully Updates.',
          steps: null,
          buttons: [
            {
              text: 'Back to Car Park list',
              action: () => {
                history.push({
                  pathname: '/carparks',
                });
              },
            },
          ],
        }}
        content={stepSet}
        currentStep={0}
        breadcrumb={<CustomBreadcrumb name={carpark.name} />}
        caution={caution}
      />
    </div>
  );
};

const mapPropsToState = (state) => ({
  carpark: state.carpark.detail,
  createStatus: state.carpark.createStatus,
  hasUpdatedDefaultValues: state.carpark.hasUpdatedDefaultValues,
  formHasSubmitted: state.carpark.formHasSubmitted,
});

export default connect(mapPropsToState)(CreateCarpark);


