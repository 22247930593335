import React, { useState, useEffect } from 'react';
import CustomSwitchButton from '../../base/CustomSwitchButton';
import { useSelector, useDispatch } from 'react-redux';
import './CouponCampaignSection.scss';
import ErrorFieldMessage from '../../base/ErrorFieldMessage';
import {
  CampaignErrorHandleField,
  isShowError,
  firstError,
} from './CreateCampaignHandleError';
import InputFieldControl from '../../base/InputFieldControl';
import { useFormContext } from 'react-hook-form';
import { hasError, errorMessage } from '../../base/ErrorFieldMessage';
import { useLocation } from 'react-router-dom';

function CouponCampaignSection({}) {
  const location = useLocation();
  // const {
  //   overallLimit,
  //   displayOverLimit,
  //   perHeadLimit,
  //   requiredPoints,
  //   errorFields,
  // } = useSelector((state) => ({
  //   overallLimit: state.createCampaign.campaign.overallLimit,
  //   displayOverLimit: state.createCampaign.campaign.displayOverLimit,
  //   perHeadLimit: state.createCampaign.campaign.perHeadLimit,
  //   requiredPoints: state.createCampaign.campaign.requiredPoints,
  //   errorFields: state.createCampaign.errorFields,
  // }));
  // const dispatch = useDispatch();
  // const onSubmit = props.onSubmit;
  // const onSubmitAction = props.onSubmitAction || (() => {});
  // const [displayLimit, setDisplayLimit] = useState(displayOverLimit);
  // const [overallCount, setOverallCount] = useState(overallLimit);
  // const [perHeadCount, setPerHeadCount] = useState(perHeadLimit);
  // const [requiredPointsCount, setRequiredPointsCount] = useState(
  //   requiredPoints,
  // );

  // useEffect(() => {
  //   if (onSubmit) {
  //     onSubmitAction({
  //       overallLimit: overallCount,
  //       displayOverLimit: displayLimit,
  //       perHeadLimit: perHeadCount,
  //       requiredPoints: requiredPointsCount,
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [onSubmit]);

  // useEffect(() => {
  //   firstError(CampaignErrorHandleField.requiredPoints.name, errorFields);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [errorFields]);

  // const numberInput = (
  //   labelText,
  //   type,
  //   valueChange,
  //   showShortDescription,
  //   defaultValue = undefined,
  //   onError = false,
  // ) => {
  //   return (
  //     <>
  //       <label className="create-section-label create-section-label-bottom-space">
  //         {labelText}
  //       </label>
  //       <div>
  //         <input
  //           value={defaultValue}
  //           type="number"
  //           min="0"
  //           className={`coupon-campaign-section-input ${
  //             onError ? 'error-field' : ''
  //           }`}
  //           onChange={({ target }) => {
  //             let value = target.value;
  //             if (value === '') {
  //               value = null;
  //             }
  //             valueChange(value);
  //           }}
  //         />
  //         <label className="section-short-description">{type}</label>
  //       </div>
  //       {showShortDescription ? (
  //         <label className="coupon-campaign-section-caution-description">
  //           It should not be greater than 1000, the current number of coupon
  //           left in stock.
  //         </label>
  //       ) : null}
  //     </>
  //   );
  // };
  //   overallLimit: state.createCampaign.campaign.overallLimit,
  //   displayOverLimit: state.createCampaign.campaign.displayOverLimit,
  //   perHeadLimit: state.createCampaign.campaign.perHeadLimit,
  //   requiredPoints: state.createCampaign.campaign.requiredPoints,
  //   errorFields: state.createCampaign.errorFields,
  const dispatch = useDispatch();
  const { watch, setValue, formState } = useFormContext();
  const errors = formState.errors;
  const overallLimit = watch('overallLimit')
  const displayOverLimit = watch('displayOverLimit')
  const perHeadLimit = watch('perHeadLimit')
  const linkedCoupon = watch('linkedCoupon')

  useEffect(() => {
    if (location.pathname.includes('edit')) {
      return;
    }
    if (!perHeadLimit) {
      setValue('perHeadLimit', 5, { shouldDirty: true });
    }
  }, [])

  return (
    <>
      <label className="create-section-title">Limit settings (optional)</label>
      <InputFieldControl
        name={'overallLimit'}
        title={'Per campaign coupon acquiring limit'}
        value={overallLimit}
        setValue={(value) => {
          setValue('overallLimit', value, { shouldDirty: true });
        }}
        type='number'
        unit='number of coupons'
        className='coupon-campaign-section-input'
        shortDescription={`It should not be greater than ${linkedCoupon?.totalNubmerOfGeneratedCoupons}, the number of coupons generated`}
      />

      <label className="create-section-label create-section-label-bottom-space">
        Display the overall limit in the campaign
      </label>
      <CustomSwitchButton
        defaultChecked={displayOverLimit}
        onChange={(value) => {
          setValue('displayOverLimit', value, { shouldDirty: true})
        }}
      />
      <InputFieldControl
        name={'perHeadLimit'}
        title={'Per head coupon acquiring limit during the campaign active period'}
        value={perHeadLimit}
        setValue={(value) => {
          setValue('perHeadLimit', value, { shouldDirty: true });
        }}
        type='number'
        unit='number of coupons'
        className='coupon-campaign-section-input'
      />
    </>
  );
}

export default CouponCampaignSection;
