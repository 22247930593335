import { APIStatus, LanguageConfig, SESSION_KEYS } from '../config/CustomEnums';
import {
  createWebContent,
  deleteWebContent,
  getWebContent,
  getWebContentList,
  updateWebContent,
} from '../services/WebContentAPIHelper';
import { formatDate } from '../utils/TimeFormatUtil';
import { createModel } from './BaseModel';

const getInitialState = () => ({
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    {
      displayName: 'Name',
      fieldName: 'name',
      orderField: 'name',
      // linked: false,
    },
    {
      displayName: 'Create date',
      fieldName: 'creationDate',
      orderField: 'creationDate',
    },
  ],
});

export const getNameByLanguage = (data, language) => {
  const translationsEdges = data?.translations?.edges || [];
  let result = '';
  translationsEdges.forEach((item) => {
    if (language === item.node.language) {
      result = item.node.name;
    }
  });
  return result || data?.name;
};

const parseWebContent = (data) => {
  const translationsEdges = data.translations?.edges || [];
  const translations = {};
  translations[LanguageConfig.english] = {
    description: data.description,
  };
  if (translationsEdges.length) {
    translationsEdges.forEach((item) => {
      let language = item.node.language;
      translations[language] = {
        id: item.node.pk,
        language: item.node.language,
        description: item.node.description,
      };
    });
  }
  return {
    ...data,
    creationDate: formatDate(data.creationDate),
    translations,
  };
};

const getPostData = (originData) => {
  const { name, translations } = originData;

  let newTranslations = [];
  for (const [key, value] of Object.entries(translations)) {
    if (key === LanguageConfig.english) {
      continue;
    }
    if (value?.id == null && value?.description == null) {
      continue;
    }
    const translationData = {
      description: value?.description,
      language: key,
    };
    if (value?.id) {
      translationData.id = value?.id;
    }
    newTranslations.push(translationData);
  }

  return {
    name,
    description: translations[LanguageConfig.english]?.description,
    translations: newTranslations,
  };
};

const fixTranslations = (translations) => {
  const translationsToDelete = [];
  const fixedTranslations = translations.filter((translation) => {
    const { description, id } = translation;
    if (id && (description === '' || description === null || description === undefined)) {
      translationsToDelete.push(id);
      return false;
    }
    return true;
  });
  return [translationsToDelete, fixedTranslations];
};

export default createModel({
  namespace: 'webContent',
  states: getInitialState(),
  params: {
    listAPI: getWebContentList,
    parse: (data) =>
      data?.webviewContents?.edges?.map((item) => parseWebContent(item.node)),
    pkNode: 'WebviewContentNode',
    detailAPI: getWebContent,
    deleteAPI: deleteWebContent,
    parseDetail: (data) => parseWebContent(data.webviewContent),
    objectKey: 'webviewContents',
    initState: getInitialState(),
    sessionKey: SESSION_KEYS.WEBVIEW_CONTENT_SESSION_KEY,
  },
  reducers: {},
  effects: {
    createOrUpdate: [
      function* ({ payload }, { put }) {
        const { values, webviewContentID } = payload;
        yield put({
          type: 'updateState',
          payload: {
            createStatus: APIStatus.calling,
          },
        });
        const input = getPostData(values);
        let serviceArgs = [createWebContent, input];
        if (webviewContentID) {
          const id = parseInt(webviewContentID);
          if (isNaN(id)) {
            return;
          }
          const [translationsToDelete, fixedTranslations] = fixTranslations(
            input.translations,
          );

          const uploadData = {
            id,
            name: input.name,
            description: input.description,
            translations: fixedTranslations,
            translationsToDelete: translationsToDelete,
          };
          serviceArgs = [updateWebContent, uploadData];
        }
        yield put({
          type: 'responseCreateOrUpdate',
          payload: { serviceArgs, isCreate: true },
        });
      },
      { type: 'takeLatest' },
    ],
  },
});
