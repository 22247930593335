import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';
import ContentSections from '../base/ContentSections';
import { OnlyContinueButton } from '../base/BottomStepComponent';
import { APIStatus, CampaignDisplayIn, MessageChannel, RELATED_CONTENT } from '../../config/CustomEnums';
import Loading from '../base/Loading';
import { validate } from '../../containers/engagement/message/CreateMessageValidate';
import { MessageChannelConfig } from './CreateMessageConfig';
import MessageChannelCard from './MessageChannelCard';
import CampaignSelectDropdown from './CampaignSelectDropdown';
import { hasError, errorMessage, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import CustomRadios from '../base/CustomRadios';

const RELATED_CONTENT_CONFIG = [
  {
    label: 'Related campaign',
    value: RELATED_CONTENT.campaign,
  },
  {
    label: 'Related Survey',
    value: RELATED_CONTENT.survey,
  },
];

function CreateMessageStepOne({
  status,
  message,
}) {
  const dispatch = useDispatch();

  const { watch, setValue, getValues, setError, control, formState, clearErrors } = useFormContext();
  const { errors } = formState;

  const channelsField = 'channels';
  const relatedCampaignField = 'relatedCampaign';
  const relatedContentField = 'relatedContent';
  const channels = watch(channelsField);
  const relatedCampaign = watch(relatedCampaignField);
  const relatedContent = watch(relatedContentField);
  const [relatedContentConfig, setRelatedContentConfig] = useState(RELATED_CONTENT_CONFIG);
  const displayIn = [
    CampaignDisplayIn.eCoupon,
    CampaignDisplayIn.interestClub,
    CampaignDisplayIn.freshMarketCommunity,
    CampaignDisplayIn.notListing,
    CampaignDisplayIn.estamp,
    CampaignDisplayIn.emission,
  ].join();

  const stepChangeAction = () => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 0,
      isBack: false,
    });
    dispatch({
      type: 'createMessage/stepChange',
      payload: {
        isBack: false,
        step: 0,
        isValid
      },
    });
  };

  if (message.pk) {
    stepChangeAction();
  }

  useEffect(() => {
    if (!relatedContent) {
      setValue(relatedContentField, RELATED_CONTENT.campaign, { shouldDirty: true });
    }
  }, [setValue, relatedContent]);

  const sections = [
    <>
      {status === APIStatus.calling ? (
        <Loading />
      ) : (
        <div>
          <label className="step-one-title">Message channel</label>
          <div className="step-type-area message-channel-area">
            {MessageChannelConfig.map((channel) => (
              <MessageChannelCard
                key={channel.channel}
                channelConfig={channel}
                selectedChannels={channels}
                select={(channel) => {
                  let isSelectInbox = channels.indexOf(MessageChannel.inbox) !== -1;
                  let isSelectPush = channels.indexOf(MessageChannel.push) !== -1;
                  let isSelectFullScreenAd = channels.indexOf(MessageChannel.fullScreenAd) !== -1;
                  if (channel === MessageChannel.inbox) {
                    isSelectInbox = !isSelectInbox;
                    setRelatedContentConfig(RELATED_CONTENT_CONFIG);
                  }
                  if (channel === MessageChannel.push) {
                    isSelectPush = !isSelectPush;
                    if (isSelectPush) {
                      isSelectInbox = true;
                      setRelatedContentConfig(RELATED_CONTENT_CONFIG);
                    }
                  }
                  if (channel === MessageChannel.fullScreenAd) {
                    isSelectFullScreenAd = !isSelectFullScreenAd;
                    if (isSelectFullScreenAd) {
                      isSelectInbox = false;
                      isSelectPush = false;
                      setValue(relatedContentField, RELATED_CONTENT.campaign, { shouldDirty: true });
                      setRelatedContentConfig([
                        {
                          label: 'Related campaign',
                          value: RELATED_CONTENT.campaign,
                        },
                      ]);
                    }
                  }
                  if (isSelectPush || isSelectInbox) {
                    isSelectFullScreenAd = false;
                  }
                  let selectedChannels = [];
                  if (isSelectInbox) {
                    selectedChannels.push(MessageChannel.inbox);
                  }
                  if (isSelectPush) {
                    selectedChannels.push(MessageChannel.push);
                  }
                  if (isSelectFullScreenAd) {
                    selectedChannels.push(MessageChannel.fullScreenAd);
                  }
                  setValue(channelsField, selectedChannels, { shouldDirty: true });
                }}
              />
            ))}
          </div>
          <div>
            <CustomTitleLabel title={'Set related content (Single select)'} />
            <div style={{ display: 'flex', marginTop: 8 }}>
              <CustomRadios
                onChange={(value) => {
                  setValue(relatedContentField, value, { shouldDirty: true });
                }}
                default={relatedContent}
                options={relatedContentConfig}
              />
            </div>
            <ReactHookFormErrorMessage errors={errors} id={relatedContentField} />
          </div>
          {relatedContent === RELATED_CONTENT.campaign ? (
            <>
              <CampaignSelectDropdown
                title={'Related campaign (optional)'}
                isPrompt
                defaultValue={{
                  label: relatedCampaign?.name,
                  value: relatedCampaign,
                }}
                setValue={(value) => {
                  setValue(relatedCampaignField, value, { shouldDirty: true });
                }}
                displayIn={displayIn}
              />
              <ReactHookFormErrorMessage
                errors={errors}
                id={relatedCampaignField}
              />
            </>
          ) : null}
          {relatedContent === RELATED_CONTENT.survey ? <></> : null}
        </div>
      )}
    </>,
  ];

  return (
    <>
      <ContentSections
        sections={sections}
        hidePreview={true}
      />
      <OnlyContinueButton
        disabledContinue={channels.length === 0}
        continueAction={() => {
          stepChangeAction();
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  status: state.createMessage.createStatus,
  message: state.createMessage.message,
});

export default connect(mapPropsToState)(CreateMessageStepOne);
