import {
  getCouponTemplateList,
  updateCouponSetActiveStatus,
  getCouponSet,
  getAllCouponSet,
  deleteCouponSets,
} from '../services/CouponAPIHelper';
import {
  assembleCouponTemplates,
  parseCouponSetListDate,
  parseSingleCoupon,
  parseCouponSet,
} from './CouponUtil';
import {
  createAction,
  convertCursorToNumber,
  convertNumberToCursor,
  convertPKToId,
} from '../utils';
import { APIStatus, LanguageConfig } from '../config/CustomEnums';
import { loading, apiWithResponseHandle } from './LoadingUtil';
import { IMAGE_TYPES } from './UploadFilesModel';
import { createModel } from './BaseModel';

const getInitialState = () => ({
  totalCount: 0,
  couponTemplateList: [],
  currentPage: 0,
  getCouponTemplateListStatus: APIStatus.none,
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  couponSetList: [],
  currentPageCouponSetList: [],
  currentLastCursor: '',
  totalPage: 0,
  checkedList: [],
  couponSet: {},
  couponSetListAll: [],
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Name', fieldName: 'name', orderField: 'name' },
    {
      displayName: 'Stock Left /Total Stock',
      fieldName: 'leftStockInTotal',
      orderField: 'stock',
    },
    {
      displayName: 'Linked Campaign type',
      fieldName: 'linkedCampaignsType',
      // orderField: 'linkedCampaignsType',
    },
    {
      displayName: 'Linked Campaign',
      fieldName: 'linkedCampaignsName',
      // orderField: 'linkedCampaigns',
    },
    { displayName: 'Expiry Date', fieldName: 'expiryDate' },
    {
      displayName: 'Create at',
      fieldName: 'displayCreationDate',
      orderField: 'creationDate',
    },
    {
      displayName: 'Last Modified',
      fieldName: 'displayLastModifiedDate',
      orderField: 'lastModifiedDate',
    },
    { displayName: 'Status', fieldName: 'status' },
  ],
});

export const COUPON_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  EXPIRED: 'Expired',
  ACTIVE_PENDIND_FOR_APPROVAL: 'Active(Pending for approval)',
  ACTIVE_PENDING_FOR_PUBLISH: 'Active(Pending for publish)',
  ACTIVE_ACQUIRED: 'Active(Acquired)',
  USED: 'Used',
};

const parseCouponStatus = (couponTemplate) => {
  const isForcedInactive = couponTemplate.isForcedInactive;
  const expiredDate = couponTemplate.absoluteExpiryDate;
  if (isForcedInactive) {
    return COUPON_STATUS.INACTIVE;
  }
  if (
    couponTemplate.validPeriodType === 'ABSOLUTE' &&
    new Date(expiredDate) < new Date()
  ) {
    return COUPON_STATUS.EXPIRED;
  }
  return COUPON_STATUS.ACTIVE;
};

export const parseExpiredDate = (couponTemplate) => {
  if (couponTemplate.validPeriodType === 'ABSOLUTE') {
    const startDate = parseCouponSetListDate(
      couponTemplate.absoluteEffectiveDate,
    );
    const endDate = parseCouponSetListDate(couponTemplate.absoluteExpiryDate);
    return `${startDate} - ${endDate}`;
  } else if (couponTemplate.validPeriodType === 'RELATIVE') {
    return `${couponTemplate.numberOfDaysToExpireAfterAcquisition} days since acquiring`;
  } else {
    return `All time valid`;
  }
};

const parseSingleCouponWithStatus = (singleCouponList) => {
  if (singleCouponList.length > 0) {
    let parsedSingleCouponList = singleCouponList.map((singleCoupon) => {
      return parseSingleCoupon(singleCoupon);
    });
    parsedSingleCouponList = parsedSingleCouponList.map((singleCoupon) => {
      let status = COUPON_STATUS.ACTIVE;
      if (singleCoupon.isUsed) {
        status = COUPON_STATUS.USED;
      } else if (singleCoupon.isExpired) {
        status = COUPON_STATUS.EXPIRED;
      } else if (singleCoupon.isForcedInactive) {
        status = COUPON_STATUS.INACTIVE;
      } else if (singleCoupon.owner.owner !== '-' || singleCoupon.dateOfGrant) {
        status = COUPON_STATUS.ACTIVE_ACQUIRED;
      }
      return {
        ...singleCoupon,
        status: status,
      };
    });
    return parsedSingleCouponList;
  } else {
    return [];
  }
};

export default createModel({
  namespace: 'couponList',
  states: getInitialState(),
  params: {
    // sessionKey: SESSION_KEYS.ENTITLEMENT_CUSTOMER_SESSION_KEY,
    // dataKey: SESSION_KEYS.ENTITLEMENT_CUSTOMERS_SESSION_KEY,
    listAPI: getCouponTemplateList,
    parse: (data) =>
      data?.couponTemplates.edges.map((item) => parseCouponSet(item.node)),
    pkNode: 'CouponTemplateNode',
    objectKey: 'couponTemplates',
  },
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },

    assembleCouponTemplateList(state, { payload }) {
      const { templateList, page } = payload;
      const edges = templateList.edges;
      const couponTemplateList = assembleCouponTemplates(edges);
      return {
        ...state,
        couponTemplateList:
          page > 1
            ? [...state.couponTemplateList, ...couponTemplateList]
            : couponTemplateList,
      };
    },

    updateCurrentPageTemplateList(state, { payload }) {
      const { templateList } = payload;
      const pageInfo = templateList.pageInfo;
      let currentPageCouponTemplateList = assembleCouponTemplates(
        templateList.edges,
      );
      currentPageCouponTemplateList = currentPageCouponTemplateList.map(
        (item) => {
          return {
            ...item,
            status: parseCouponStatus(item),
            expiredDate: parseExpiredDate(item),
            leftStockInTotal: `${item.stock} /${item.totalNubmerOfGeneratedCoupons}`,
          };
        },
      );
      return {
        ...state,
        currentPageCouponSetList: currentPageCouponTemplateList,
        currentPageInfo: {
          ...state.currentPageInfo,
          totalCount: templateList.totalCount,
          pageInfo: pageInfo,
          currentPageCouponTemplateList: currentPageCouponTemplateList,
        },
      };
    },

    updateSingleCouponList(state, { payload }) {
      const { singleCoupons } = payload;
      const pageInfo = singleCoupons?.pageInfo;
      const totalCount = singleCoupons?.totalCount;
      const singleCouponList = parseSingleCouponWithStatus(singleCoupons.edges);
      return {
        ...state,
        currentSingleCouponList: {
          ...state.currentSingleCouponList,
          totalCount,
          pageInfo,
          singleCouponList: singleCouponList,
        },
      };
    },

    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },
  },

  effects: {
    getCouponTemplateList: [
      function* ({ payload }, { call, select, put }) {
        const response = yield call(getCouponTemplateList, '', {
          ...payload,
          rank: false,
          filterName: '',
          all: true,
        });
        console.log('getCouponTemplateList:', response);
        if (!response || response.status >= 300) {
          return;
        }
        const responseData = response?.data;
        if (responseData?.errors) {
          return;
        }
        const data = responseData?.data;
        yield put({
          type: 'assembleCouponTemplateList',
          payload: { templateList: data?.couponTemplates },
        });
      },
      { type: 'takeLatest' },
    ],

    getCouponSet: [
      function* ({ payload }, { put }) {
        const { couponSetPK } = payload;
        const couponSetID = convertPKToId('CouponTemplateNode', couponSetPK);
        const notSaveToSession = payload.notSaveToSession;
        const serviceArgs = [getCouponSet, couponSetID];

        function* onSuccess(data) {
          const couponSetData = data.couponTemplate;
          const couponSet = parseCouponSet(couponSetData);
          couponSet.status = parseCouponStatus(couponSet);
          // const enCoverPhoto =
          //   couponSet.translations[LanguageConfig.english].coverPhoto;
          // couponSet.translations[LanguageConfig.english].coverPhoto = {
          //   type: IMAGE_TYPES.TYPE_URL,
          //   value: enCoverPhoto,
          // };
          yield put(
            createAction('updateState')({
              [couponSetID]: {
                ...couponSet,
              },
              couponSet,
            }),
          );
          yield put(
            createAction('createCoupon/loadCouponFromAPI')({
              // ...couponSet,
              // notSaveToSession: notSaveToSession,
              data: couponSet,
              newItems: payload,
            }),
          );
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    getCurrentPageTemplateList: [
      function* ({ payload }, { select, put, all }) {
        yield put(
          createAction('updateState')({
            getCouponTemplateListStatus: APIStatus.calling,
          }),
        );
        const { page } = payload;
        if (!payload.filterName && payload.search) {
          payload.filterName = payload.search;
        }
        let afterCursor = undefined;
        let serviceArgs = [];
        if (page > 1) {
          afterCursor = convertNumberToCursor((page - 1) * 20 - 1);
        }
        serviceArgs = [getCouponTemplateList, afterCursor, payload];

        function* onSuccess(data) {
          const pageInfo = data?.couponTemplates?.pageInfo;
          const currentLastCursor = pageInfo?.endCursor;
          const totalCount = data?.couponTemplates?.totalCount;

          yield all([
            put({
              type: 'updateState',
              payload: {
                totalCount: totalCount,
                getCouponTemplateListStatus: APIStatus.success,
                pageInfo: {
                  startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1 || 0,
                  endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1 || 0,
                },
                currentLastCursor,
                totalPage: Math.ceil(totalCount / 20),
              },
            }),
            put({
              type: 'updateCurrentPageTemplateList',
              payload: {
                templateList: data.couponTemplates,
              },
            }),
            put({
              type: 'assembleCouponTemplateList',
              payload: {
                templateList: data.couponTemplates,
                page: page,
              },
            }),
          ]);
        }
        function* onFailed() {
          yield put(
            createAction('updateState')({
              getCouponTemplateListStatus: APIStatus.failed,
            }),
          );
        }
        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],

    delete: [
      function* ({ payload }, { select, put }) {
        const checkedList = yield select(
          (state) => state.couponList.checkedList,
        );
        let ids = [];
        ids = checkedList.map((item) => item.pk);
        const serviceArgs = [deleteCouponSets, ids];
        function* onSuccess() {
          const afterAction = payload.afterAction;
          yield afterAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    duplicate: [
      function* ({ payload }, { put, select }) {
        const couponSet = payload.data;

        const afterAction = payload.afterAction || (() => {});

        yield put.resolve(
          createAction('getCouponSet')({ couponSetPK: couponSet.pk }),
        );

        yield put.resolve(
          createAction('createCoupon/createCouponTemplate')({
            isDuplicate: true,
            afterAction: afterAction,
          }),
        );
        console.log('@@337: ', afterAction);
        yield afterAction();
      },
      { type: 'takeLatest' },
    ],

    updateCouponSetActiveStatus: [
      function* ({ payload }, { put, select, call }) {
        const { couponSetPK, isForcedInactive } = payload;
        const couponSet = yield select((state) => state.couponList.couponSet);
        const serviceArgs = [
          updateCouponSetActiveStatus,
          couponSetPK,
          isForcedInactive,
        ];
        function* onSuccess() {
          const updateAction = payload.updateAction || (() => {});
          const afterAction = payload.afterAction || (() => {});
          if (couponSet?.coupons && couponSet?.coupons?.length > 0) {
            yield put(
              createAction('singleCoupon/updateState')({
                singleCouponList: couponSet.coupons,
              }),
            );
            yield updateAction();
          } else {
            yield put.resolve(
              createAction('singleCoupon/getAllSingleCoupons')({
                template: couponSet,
                afterAction: updateAction,
              }),
            );
          }
          yield afterAction();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    getAllCouponSet: [
      function* ({ payload }, { put, select, call }) {
        const { ssoUid } = payload;
        console.log('@@458: ', ssoUid);
        const serviceArgs = [getAllCouponSet, ssoUid];

        function* onSuccess(data) {
          console.log('@@462: ', data);
          console.log(
            '@@462-1: ',
            data?.coupons?.edges.map((item) => ({
              name: item.node.template.name,
              value: item.node.pk,
            })),
          );
          yield put({
            type: 'updateState',
            payload: {
              couponSetList: data?.coupons?.edges.map((item) => ({
                name: item.node.template.name,
                value: item.node.pk,
              })),
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
});
