import React from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import ContentSections from '../base/ContentSections';
import { validate } from '../../containers/conciergeApp/transactionRecord/CreateTransactionValidate';
import TransactionCampaignSection from './TransactionCampaignSection.js';
import { ContinueWithBackButtons } from '../base/BottomStepComponent';
import i18n from '../../I18n';
import PurchaseReceiptSection from './PurchaseReceiptSection.js';
import { parseNameByLanguage } from '../../models/CreateTransactionRecordModel';

function CreateTransactionRecordStepThree({ language, isTCTransaction }) {
  const dispatch = useDispatch();

  const { setValue, getValues, clearErrors, setError, formState } = useFormContext();
  const { errors } = formState;
  const errorFields = Object.keys(errors);
  const [
    customer,
    stampCampaign,
    missionCampaign,
    otherCampaign,
    carParkCampaign,
  ] = getValues([
    'customer',
    'stampCampaign',
    'missionCampaign',
    'otherCampaign',
    'carParkCampaign',
  ]);

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 2,
      isBack: isBack,
      language,
    });
    dispatch({
      type: 'createTransactionRecord/stepChange',
      payload: {
        isBack: isBack,
        step: 2,
        isValid,
        isTCTransaction,
      },
    });
  };

  const getUpdateField = (campaign, index) => {
    switch (index) {
      case 0:
        return {pk: campaign?.pk, updateField: 'stampCampaign', campaignType: 'STAMP'};
      case 1:
        return {pk: campaign?.pk, updateField: 'missionCampaign', campaignType: 'MISSION'};
      case 2:
        return {pk: campaign?.pk, updateField: 'otherCampaign', campaignType: 'OTHER'};
      case 3:
        return {pk: campaign?.pk, updateField: 'carParkCampaign', campaignType: 'CAR_PARK'};
      default:
        break
    }
  };

  const updateCampaign = () => {
    const needUpdateCampaigns = [stampCampaign, missionCampaign, otherCampaign, carParkCampaign].map((campaign, index) => {
      return getUpdateField(campaign, index);
    })
    needUpdateCampaigns.forEach((item) => {
      if (item.pk) {
        dispatch({
          type: 'campaignList/getCampaignListForTransaction',
          payload: {
            isStampCampaign: item.campaignType === 'STAMP',
            isMissionCampaign: item.campaignType === 'MISSION',
            isOtherCampaign: item.campaignType === 'OTHER',
            isCarParkCampaign: item.campaignType === 'CAR_PARK',
            searchKey: item.pk,
            allDisplayIn: true,
            isSelectorLoad: true,
            transactionFilter: [item.campaignType, customer?.membershipId],
            transactionErrorFilter: {
              campaignType: item.campaignType,
              membershipId: customer?.membershipId,
              receipts:[""],
            },
            afterAction: (campaigns) => {
              setValue(item.updateField, parseNameByLanguage(campaigns, language)[0], { shouldDirty: true });
              stepChangeAction(false);
            },
          },
        });
      }
    })
  }

  const sections = [<PurchaseReceiptSection language={language} />];

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <ContinueWithBackButtons
        continueText={i18n.t('transaction.continue', { locale: language })}
        backText={i18n.t('transaction.back', { locale: language })}
        backAction={() => {
          stepChangeAction(true);
        }}
        continueAction={() => {
          updateCampaign();
        }}
        // disabledContinue={errorFields.length > 0}
      />
    </>
  );
}

export default CreateTransactionRecordStepThree;
