import { MessageChannel } from '../../config/CustomEnums';
import InboxImage from '../../assets/images/message/inbox.svg';
import PushImage from '../../assets/images/message/push.svg';
import FullScreenAdImage from '../../assets/images/message/fullScreenAd.svg';

export const MessageChannelConfig = [
  {
    channel: MessageChannel.inbox,
    image: InboxImage,
    description: 'Show in inbox to ensure the customers will not miss out even push notification is off.',
    suggest: 'Suggest length: 1,000 character',
  },
  {
    channel: MessageChannel.push,
    image: PushImage,
    description: 'Show as a push notification. Tap to open the app.',
    suggest: 'Suggest length: 235 character',
  },
  {
    channel: MessageChannel.fullScreenAd,
    image: FullScreenAdImage,
    description: 'Show as a fullscreen size format when customers start the app.',
    suggest: '',
  },
];
