import React, {useEffect, useState} from 'react';
import { Button, Image } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import './SelectMultipleStore.scss';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import closeIcon from '../../assets/images/close.svg';
function SelectMultipleBrand({
  title = '',
  filterTitle = 'Search a brand',
  value = [],
  onChange = ()=> {},
  disabled = false,
  showLineBrank = false,
  brandList,
  source = null,
  onFocus = () => {},
  customFilter = null,
  hasMarginTop = true,
  needLoadMore = true,
  placeholder = null,
  multiple = true,
}) {
  return (
    <div style={{width: '780px', marginTop: hasMarginTop ? '30px' : 0}}
      onFocus={onFocus}
    >
      {!disabled && showLineBrank ? <div className='line'></div> : null}
      {title ? <label className="create-section-title">{title}</label> : null}
      {disabled ? null : (
        <CustomTitleWithDropDown 
          customClass={'filter-title'}
          title={filterTitle}
          source={source || brandList}
          placeholder={placeholder || 'Search by ID, name'}
          labelContainPk={true}
          defaultValue={value ? value : []}
          setValue={(v) => {
            onChange(v);
          }}
          loadMoreAction={needLoadMore ? 'brand/getSimpleBrandList' : ''}
          customFilter={customFilter}
          filterAction={'brand/getSimpleBrandList'}
          defaultFilter={{
            isSelectorLoad: true,
            isSimpleFilter: true,
            isFilterNameOrId: true,
            sort: 'name',
            pageSize: 100
          }}
          multiple={multiple}
          toolTips={true}
          notShowLabel={true}
          disableAutoFocus={true}
        />
      )}
      <div className='title-container'>
        <CustomTitleLabel 
          title={`Summary of selected brands (${value.length})`}
        />
        {disabled ? null :  <Button className="clear-filter" style={{position: "relative", marginBottom:"7px"}}
          onClick={() => {
            console.log("clear mall filter click")
            // setValue("selectedBrand", [], {shouldDirty: true});
            onChange()
          }}
        >Reset</Button>}
      </div>
      {value && value?.length > 0 ? 
      value?.map((item, index) => {
        return <div className='store-list-item-container'>
          <label className='store-list-item'>
          {item.label}
          </label>
          {disabled ? null :<Image
            src={closeIcon}
            className="list-item-delete"
            onClick={ (e) =>{
              e.stopPropagation();
              const newValue = value?.filter((filterItem) => filterItem.value.pk != item.value.pk)
              // setValue("selectedBrand", newValue, {shouldDirty: true});
              onChange(newValue)
            }}
          />}
        </div>
      })
      : '-'}
    </div>
  );
}

const mapPropsToState = (state) => ({
  brandList: state.brand.currentPageBrandList || [],
})

export default connect(mapPropsToState)(SelectMultipleBrand);