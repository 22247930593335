import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { NavBarNames } from '../../../config/NavBarNameList';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import { ActionsDropdownForItem } from '../../../components/base/ActionsDropdown';
import { PermissionCodes } from '../../../config/PermissionCodes';

function CarParkList({
  apiDataList,
  totalCount,
  totalPage,
  pageInfo,
  listDisplayFields,
}) {
  const [dataList, setDataList] = useState([]);
  const [sortDisabled, setSortDisabled] = useState(false);
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();
  const searchDict = queryString.parse(location.search);
  const currentPage = searchDict?.page || 1;
  const searchKey = searchDict?.search;
  const hasSearchKey = searchKey && searchKey.length > 0;
  const fetchData = () => {
    setSortDisabled(hasSearchKey);
    dispatch({
      type: 'carpark/getList',
      payload: {
        page: currentPage,
        searchKey,
        moreSearch: { ...searchDict },
      },
    });
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  useEffect(() => {
    setDataList(apiDataList);
  }, [apiDataList]);
  const groupActions = [];

  const tabs = [
    {
      name: 'List Of Carparks',
      content: (
        <BaseTabListContainer
          hideTab={true}
          tabs={[]}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          listContent={
            <BaseListContainer
              fields={listDisplayFields}
              dataList={dataList}
              totalPage={totalPage}
              model={'carpark'}
              permissionGroup={PermissionCodes.carpark}
              deleteInfo={{
                data: [],
                title: 'carparks',
                relatedName: '',
                onComfirm: {},
                relatedSections: [],
              }}
              // sortable
              sortDisabled={sortDisabled}
              rankDisabled
              hasOtherActions
              useCustomCommonActions
              customCommonActions={(item) => {
                const actions = [
                  {
                    name: 'Detail',
                    action: () => {
                      history.push(`${location.pathname}/${item.pk}/`);
                    },
                    requires: PermissionCodes.viewCarPark,
                  },
                  {
                    name: 'Edit',
                    action: () => {
                      history.push(`${location.pathname}/${item.pk}/edit/`);
                    },
                    requires: PermissionCodes.changeCarPark,
                  },
                ];
                return (
                  <ActionsDropdownForItem
                    object={item}
                    actions={actions}
                    onToggle={(open) => {
                      if (!hasSearchKey) {
                        setSortDisabled(open);
                      }
                    }}
                  />
                );
              }}
            />
          }
        />
      ),
    },
  ];
  const buttons = [];
  return (
    <>
      <CustomListComponent
        caution={{
          detail: '',
          title: NavBarNames.carparks,
        }}
        defaultActiveKey={tabs[0].name}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={true}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  apiDataList: state.carpark.pagedList,
  totalCount: state.carpark.totalCount,
  totalPage: state.carpark.totalPage,
  pageInfo: state.carpark.pageInfo,
  listDisplayFields: state.carpark.listDisplayFields,
});

export default connect(mapPropsToState)(CarParkList);
