import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Dashboard.scss';
import '../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../components/base/CustomBreadcrumb';
import { NavBarNames } from '../../config/NavBarNameList';
import CustomTips from '../../components/base/CustomTips';
import CustomersCard from '../../components/dashboard/CustomersCard';
import CampaignsCard from '../../components/dashboard/CampaignsCard';
import CouponsCard from '../../components/dashboard/CouponsCard';
import DateRangeSelection from '../../components/dashboard/DateRangeSelection';
import { createAction } from '../../utils';
import DateRangeCompare from '../../components/dashboard/DateRangeCompare';
import Onboarding from '../onboarding/Onboarding';
import AuthButton from '../../components/base/AuthButton';

const Dashboard = () => {
  const dispatch = useDispatch();
  const { customerPLLFile, user } = useSelector((state) => ({
    customerPLLFile: state.customerList?.customerPLLFile,
    user: state.users,
  }));
  const tip =
    'The dashboard shows an overview of the customers, campaigns, and coupons. The percentage change is compared to the previous period.';

  const downloadPLL = () => {
    dispatch({ type: 'customerList/downloadPLL', payload: {} });
  };

  useEffect(() => {
    if(user?.isSuperuser){
      dispatch({ type: 'customerList/getCustoemrPLLFile', payload: {} });
    }
  }, [user]);

  return (
    <>
      <div className="list-section-container-header list-section-container-header-bottom dashboard-header">
        <CustomBreadcrumb />
        <div className="list-section-container-button-and-title">
          <div className="list-section-container-header-left dashboard-header-left">
            <div className="dashboard-title">
              <CustomTips detail={tip}>
                <label className="list-section-container-header-title">
                  {NavBarNames.dashboard}
                </label>
              </CustomTips>
            </div>
            <DateRangeCompare />
          </div>
          <AuthButton
            customClass="btn-back-button-common btn-download"
            title={'Generate PLL'}
            action={downloadPLL}
            requires={['super_admin_permission']}
          />
          <AuthButton
            customClass="btn-back-button-common btn-download"
            title={
              customerPLLFile?.file && customerPLLFile?.status==="COMPLETED"
                ? customerPLLFile?.file.slice(0, 15) + '...'
                : customerPLLFile?.status || "no file"
            }
            action={() => {
              if (customerPLLFile?.fileName) {
                window.open(customerPLLFile?.fileName, '_blank').focus();
              }
            }}
            requires={['super_admin_permission']}
          />
          <DateRangeSelection
            applyDateRangeEvent={(startDate, endDate) => {
              dispatch(
                createAction('dashboard/getDashboardData')({
                  startDate: startDate.format('YYYY-MM-DD'),
                  endDate: endDate.format('YYYY-MM-DD'),
                }),
              );
            }}
            clearDataEvent={() => {
              dispatch(createAction('dashboard/clearState')());
            }}
          />
        </div>
      </div>
      <CustomersCard />
      <div className="dashboard-campaigns-coupons">
        <CouponsCard />
        <CampaignsCard />
      </div>

      <Onboarding />
    </>
  );
};

export default Dashboard;
