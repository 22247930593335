import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import queryString from 'query-string';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import AuthButton from '../../../components/base/AuthButton';
import ListButtonsGroup from '../../../components/base/ListButtonsGroup';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { NavBarNames } from '../../../config/NavBarNameList';
import { createAction } from '../../../utils';
import BaseListContainer from '../../base/BaseListContainer';
import { DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';
import DeletePrompt from '../../../components/base/DeletePrompt';
import { ActionsDropdownForItem } from '../../../components/base/ActionsDropdown';

function AppVersionList({
    pagedList,
    totalCount,
    totalPage,
    pageInfo,
    listDisplayFields,
    checkedList,
  }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchDict = queryString.parse(location.search);
  const currentPage = searchDict?.page || 1; 

  const createAppVersion = () => {
    // dispatch({ type: `AppVersion/create` });
    history.push(`${location.pathname}/create`);
  };
  
  const fetchData = () => {
    dispatch(
      createAction('appVersion/getList')({
        page: currentPage,
        reverse: true,
      }),
    );
  }

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [deleteItems, setDeleteItems] = useState([]);

  useEffect(() => {
    dispatch({ type: `appVersion/clearData` });
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history.location]);

  const buttons = [
    <ListButtonsGroup
      hideExtraButtonWidth={580}
      hideAllButtonWidth={580}
      primaryButton={
        <AuthButton
          title="Add new version"
          action={createAppVersion}
          requires={PermissionCodes.changeAppVersion}
        />
      }
      primaryPopContent={{
        requires: PermissionCodes.changeAppVersion,
        action: createAppVersion,
        content: 'AppVersion',
      }}
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        setDeleteItems(checkedList);
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.changeAppVersion,
    },
  ];

  const tabs = [
    {
      name: 'List of app version',
      content: (
        <BaseTabListContainer
          hideTab={true}
          hideSearch={true}
          tabs={[]}
          groupActions={groupActions}
          pageInfo={pageInfo}
          totalCount={totalCount}
          listContent={
            <BaseListContainer
              fields={listDisplayFields}
              dataList={pagedList}
              totalPage={totalPage}
              model={'appVersion'}
              permissionGroup={PermissionCodes.appVersion}
              deleteInfo={{
                data: [],
                title: 'App version',
                relatedName: '',
                onComfirm: {},
                relatedSections: [],
              }}
              useCustomCommonActions
              customCommonActions={(item) => {
                const actions = [
                  {
                    name: 'Delete',
                    action: () => {
                      setDeleteItems([item]);
                      setShowDeletePrompt(true);
                    },
                    requires: PermissionCodes.changeAppVersion,
                  },
                ];
                return (
                  <ActionsDropdownForItem
                    object={item}
                    actions={actions}
                  />
                );
              }}
            />
          }
        />
      ),
    },
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          title: NavBarNames.appversion,
        }}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={true}
      />
      <DeletePrompt
        data={deleteItems}
        title={'App Version'}
        relatedSections={[]}
        show={showDeletePrompt}
        onClose={() => {
          setShowDeletePrompt(false);
          setDeleteItems([]);
        }}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: `appVersion/updateState`,
            payload: { checkedList: deleteItems },
          });
          dispatch({
            type: 'appVersion/delete',
            payload: { afterAction: fetchData },
          });
          setDeleteItems([]);
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  pagedList: state.appVersion.pagedList,
  totalCount: state.appVersion.totalCount,
  totalPage: state.appVersion.totalPage,
  pageInfo: state.appVersion.pageInfo,
  listDisplayFields: state.appVersion.listDisplayFields,
  checkedList: state.appVersion.checkedList,
});

export default connect(mapPropsToState)(AppVersionList);
