import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import './CouponList.scss';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { couponSetBreads } from '../../../config/CustomBreadConfig';
import { connect, useDispatch, useSelector } from 'react-redux';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { createAction, getHashKeyString } from '../../../utils';
import CouponsOverview from '../../../components/coupon/couponList/CouponsOverview';
import DateRangeCompare from '../../../components/dashboard/DateRangeCompare';
import DateRangeSelection from '../../../components/dashboard/DateRangeSelection';
import { PermissionCodes } from '../../../config/PermissionCodes';
import AuthButton from '../../../components/base/AuthButton';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import DeactiveCouponSetView from '../../../components/coupon/couponList/DeactiveCouponSetView';
import DeletePrompt from '../../../components/base/DeletePrompt';
import { COUPON_STATUS } from '../../../models/CouponListModel';
import Filter from './Filter';
import { DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';

export const TAB_OVERVIEW = 'Overview';
export const TAB_LIST_OF_COUPON_SETS = 'List of Coupon sets';

export default function CouponList() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const {
    totalCount,
    pageInfo,
    totalPage,
    displayFields,
    couponSetList,
    checkedList,
    isSuperuser,
    userPermissions,
    singleCouponList,
  } = useSelector((state) => ({
    totalCount: state.couponList.totalCount,
    pageInfo: state.couponList.pageInfo,
    totalPage: state.couponList.totalPage,
    displayFields: state.couponList.listDisplayFields,
    couponSetList: state.couponList.currentPageCouponSetList,
    checkedList: state.couponList.checkedList,
    isSuperuser: state.users.isSuperuser,
    userPermissions: state.users.userPermissions,
    singleCouponList: state.singleCoupon.singleCouponList,
  }));

  const actionList = [
    {
      name: 'Delete',
      action: () => setShowDeleteView(true),
      requires: PermissionCodes.deleteCouponTemplate,
    },
  ];

  const tabs = [
    // { name: TAB_OVERVIEW, content: <CouponsOverview /> },
    isSuperuser ||
    userPermissions.filter(
      (val) => val.split('_')[1] === PermissionCodes.coupontemplate,
    ).length > 0
      ? {
          name: TAB_LIST_OF_COUPON_SETS,
          content: (
            <BaseTabListContainer
              hideTab={true}
              groupActions={actionList}
              pageInfo={pageInfo}
              totalCount={totalCount || 0}
              filter={{ hasFilter: true, componment: Filter }}
              listContent={
                <BaseListContainer
                  fields={displayFields}
                  model={'couponList'}
                  permissionGroup={PermissionCodes.coupontemplate}
                  dataList={couponSetList}
                  totalPage={totalPage}
                  deleteInfo={{
                    data: [],
                    title: 'Coupon Set',
                    relatedName: '',
                    onComfirm: {},
                    relatedSections: DELETE_RELATED_SECTIONS.COUPON_TEMPLATE,
                  }}
                  actions={['Edit', 'Detail', 'Delete', 'Duplicate']}
                  hasOtherActions={true}
                  customActions={{
                    deactive: (item) => {
                      dispatch(
                        createAction('couponList/updateState')({
                          couponSet: item,
                        }),
                      );
                      if (item.couponStatus === COUPON_STATUS.ACTIVE) {
                        setShowDeactiveView(true);
                        setOperationItem(item);
                      } else if (item.couponStatus === COUPON_STATUS.INACTIVE) {
                        activeOrDeactiveAction(item);
                      }
                    },
                    requires: PermissionCodes.changeCouponTemplate,
                  }}
                />
              }
            />
          ),
        }
      : {},
  ];
  const activeTab = getHashKeyString(location?.hash) || TAB_LIST_OF_COUPON_SETS;
  const [showDeleteView, setShowDeleteView] = useState(false);
  const [showDeactiveView, setShowDeactiveView] = useState(false);
  const [operationItem, setOperationItem] = useState();

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    const searchKey = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);

    dispatch(
      createAction('couponList/getCurrentPageTemplateList')({
        ...searchDict,
        rank: rank,
        filterName: searchKey,
        page: page,
      }),
    );
  }, [dispatch, location]);

  const activeOrDeactiveAction = (couponSet) => {
    let isForcedInactive = true;
    let singleCouponAction = 'singleCoupon/deactiveCoupons';
    if (couponSet.couponStatus === COUPON_STATUS.INACTIVE) {
      isForcedInactive = false;
      singleCouponAction = 'singleCoupon/activeCoupons';
    }
    dispatch(
      createAction('couponList/updateCouponSetActiveStatus')({
        couponSetPK: couponSet.pk,
        isForcedInactive,
        afterAction: () => history.push(location),
        // updateAction: () => {
        //   dispatch(
        //     createAction(singleCouponAction)({
        //       afterAction: () => history.push(location),
        //     }),
        //   );
        // },
      }),
    );
  };

  const buttons = [
    <AuthButton
      title="Create Coupon Set"
      action={() => {
        history.push('/coupons/create');
        dispatch(createAction('createCoupon/clearState')());
      }}
      requires={PermissionCodes.addCouponTemplate}
    />,
  ];

  return (
    <>
      <CustomListComponent
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        defaultActiveKey={activeTab}
        caution={{
          detail:
            'Coupon set stores the content and controls a list of coupons. After you create a coupon set, you can generate a list of single coupons for your customers',
          title: 'Coupon Set',
        }}
        onTabChange={(key) => {
          history.push({ pathname: location.pathname, hash: key });
        }}
        renderCompare={() => {
          return activeTab === TAB_OVERVIEW ? (
            <DateRangeCompare inCustomListComponent />
          ) : null;
        }}
        renderTabsRight={() => {
          return activeTab === TAB_OVERVIEW ? (
            <DateRangeSelection
              onTab
              applyDateRangeEvent={(startDate, endDate) => {
                dispatch(
                  createAction('overview/getCouponsData')({
                    startDate: startDate.format('YYYY-MM-DD'),
                    endDate: endDate.format('YYYY-MM-DD'),
                  }),
                );
              }}
              clearDataEvent={() => {
                dispatch(createAction('overview/clearState')());
              }}
            />
          ) : null;
        }}
      />
      <DeletePrompt
        data={checkedList}
        show={showDeleteView}
        title={'Coupon Set'}
        relatedName=""
        relatedSections={DELETE_RELATED_SECTIONS.COUPON_TEMPLATE}
        onClose={() => setShowDeleteView(false)}
        onConfirm={() => {
          setShowDeleteView(false);
          dispatch(
            createAction('couponList/delete')({
              afterAction: () => history.push(location),
            }),
          );
        }}
      />
      <DeactiveCouponSetView
        couponSet={operationItem}
        showDeactiveView={showDeactiveView}
        onHide={() => setShowDeactiveView(false)}
        deactiveAction={() => {
          activeOrDeactiveAction(operationItem);
        }}
      />
    </>
  );
}
