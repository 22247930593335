import React from 'react';
import { InputFieldControl2 } from '../../base/InputFieldControl';

function TNCSection({
  language,
  onFocus = () => {},
}) {
  return (
    <>
    <label className="create-section-title">{'Mission URL'}</label>
    <InputFieldControl2
      name={`translations.${language}.missionCampaignTncUrl`}
      title={'Mission TNC URL'}
      onFocus={() => onFocus('tncUrl')}
    />
    {/* <InputFieldControl2
      name={`translations.${language}.missionCampaignPrivacyUrl`}
      title={'Mission Privacy policy URL'}
    /> */}
    </>

  );
}

export default TNCSection;
