import React from 'react';
import { useFormContext } from 'react-hook-form';
import { listItem } from '../banners/DisplayItems';
import InputFieldControl from '../base/InputFieldControl';
import { LanguageConfig } from '../../config/CustomEnums';

export default function CarparkGeneralSection({ language }){
  const isEnglish = language === LanguageConfig.english;
  const { getValues, watch, setValue } = useFormContext();
  const carparkName = isEnglish ? getValues('name') : getValues(`translations.${language}.name`);
  const watchChargerLocation = isEnglish ? watch('chargerLocation') : watch(`translations.${language}.chargerLocation`);

  return(
    <>
      <label className="create-section-title">{'General'}</label>
      { listItem( 'CarPark', carparkName )}
      <InputFieldControl
        name={isEnglish ? 'chargerLocation' : `translations.${language}.chargerLocation`}
        title={'Charger Location'}
        rules={ isEnglish ? { required: 'Please input content' } : {}}
        value={watchChargerLocation}
        setValue={(value) => {
          setValue(
            isEnglish ? 'chargerLocation' : `translations.${language}.chargerLocation`,
            value,
            {
              shouldDirty: true,
            },
          );
        }}
      />
    </>
  )
}
  