import React, { useState, useRef, useEffect, createRef } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import '../campaign/campaignCreation/PropertiesGeneralSection.scss';
import './RewardSection.scss';
import './QRCodeScanningSection.scss';
import { CSVReader } from 'react-papaparse';
import {
  CustomTitleLabel,
  CustomNumberInputWithUnit,
  CustomNumberMutipleInputWithUnit,
} from './CustomBaseComponments';
import { Button, Image } from 'react-bootstrap';
import { ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import deleteImage from '../../assets/images/earning/deletex1.png';
import { removeElementFromArray, useCompare } from '../../utils';
import { useFormContext } from 'react-hook-form';
import InputFieldControl from '../base/InputFieldControl';
import LimitSection from './LimitSection';

const buttonRef = createRef();

function CodeSection({
  sectionTitle,
  inputTitle,
  importTitle,
  title,
  tips,
  name,
  defaultValue,
  hasLimitSection=false,
  limitTips,
}) {
  const { watch, formState, setValue } = useFormContext();
  const errors = formState.errors;
  const fieldError = Object.keys(errors || []).length;
  const camma = ',';

  const dispatch = useDispatch();

  const [showFileBox, setShowFileBox] = useState(false);
  const inputCodeField = "inputCodes";
  const importCodeField = "importCodes";
  const inputCodes = watch(inputCodeField) || defaultValue || [];
  const importCodes = watch(importCodeField) || [];
  const codes = watch(name) || [];

  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = (data) => {
    console.log('---------------------------');
    console.log(data);
    console.log('---------------------------');

    let currentImportedCodes = [];
    data.map((element) => {
      const hasValueData = removeElementFromArray(element.data, '');
      if (hasValueData.length > 0) {
        currentImportedCodes = [...currentImportedCodes, ...hasValueData];
      }
    });
    setValue(importCodeField, currentImportedCodes, { shouldDirty: true })
    setCodesWithoutDuplicate(inputCodes, currentImportedCodes);

    setShowFileBox(true);
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const handleOnRemoveFile = (data) => {
    console.log('---------------------------');
    console.log(data);
    console.log('---------------------------');
  };

  const handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
      const test = codes.filter(function (val) {
        return importCodes.indexOf(val) === -1;
      })
      setValue(name,
        codes.filter(function (val) {
          return importCodes.indexOf(val) === -1;
        }),
        { shouldDirty: true}
      );
      setValue(importCodeField, [], { shouldDirty: true})

      setShowFileBox(false);
    }
  };

  const setCodesWithoutDuplicate = (inputCodes, importCodes) => {
    const nonEmptyInputCodes = removeElementFromArray(inputCodes, '');
    const nonEmptyImportCodes = removeElementFromArray(importCodes, '');

    const codes = [...nonEmptyImportCodes, ...nonEmptyInputCodes];
    const unrepeatCodes = codes.filter((item, index) => {
      if (codes.indexOf(item) === index) {
        return item;
      }
    });

    console.log("@181", unrepeatCodes)
    setValue(name, unrepeatCodes, {shouldDirty: true})
  };

  return (
    <>
      <label className="create-section-title">{sectionTitle}</label>

      <CustomTitleLabel title={inputTitle} />
      <label className="tips-message">{tips?.inputTip}</label>
      <textarea
        onChange={({ target }) => {
          const currentCodes = target.value.trim().split(camma);
          setValue(inputCodeField, currentCodes, { shouldDirty: true })
          setCodesWithoutDuplicate(currentCodes, importCodes);
        }}
        className="custom-textarea-input-box custom-textarea-input-font"
        onFocus={() => {}}
        value={inputCodes?.join(camma)}
      />
      <ReactHookFormErrorMessage errors={errors} id={name} />

      <CustomTitleLabel title={importTitle} />
      <label className="tips-message">
        {tips?.importTip}
      </label>

      <CSVReader
        ref={buttonRef}
        onFileLoad={handleOnFileLoad}
        onError={handleOnError}
        noClick
        noDrag
        onRemoveFile={handleOnRemoveFile}
      >
        {({ file }) => (
          <div>
            <div style={{ display: showFileBox ? 'flex' : 'none' }}>
              <div className="custom-file-box">{file && file.name}</div>
              <Button
                className="custom-image-button"
                onClick={handleRemoveFile}
              >
                <Image src={deleteImage} />
              </Button>
            </div>

            <Button
              className="multiple-select-option-add btn-add-button-common upload-file-button"
              onClick={handleOpenDialog}
            >
              Choose file
            </Button>
          </div>
        )}
      </CSVReader>
      <ReactHookFormErrorMessage errors={errors} id={name} />

      <CustomTitleLabel title={title} />
      <div>
        {codes?.map((element) => {
          return (
            <div className="qr-codes-delete-button">
              <label className="custom-textarea-input-font qr-codes-name-label">
                {element}
              </label>
              <Button
                className="custom-image-button"
                onClick={() => {
                  setValue(name, removeElementFromArray(codes, element), {shouldDirty: true})
                  setValue(inputCodeField, removeElementFromArray(inputCodes, element), {shouldDirty: true})
                  setValue(importCodeField, removeElementFromArray(importCodes, element), {shouldDirty: true})
                }}
              >
                <Image src={deleteImage} />
              </Button>
            </div>
          );
        })}
      </div>
      {hasLimitSection ? (
        <LimitSection
          tips={limitTips}
        />
      ) : null}
    </>
  );
}

const mapPropsToState = (state) => ({
  // earningRule: state.createEarningRules.earningRule
})

export default connect(mapPropsToState)(CodeSection);
