import React, { Component } from 'react';
import './CouponInstructionSection.scss';
import { useDispatch, connect } from 'react-redux';
import CustomMarkDownArea, { ReactHookFormMarkDown } from '../../base/CustomMarkDownArea';
import i18n from '../../../I18n';
import { useFormContext } from 'react-hook-form';
import { InputFieldControl2 } from '../../base/InputFieldControl';

const CouponInstructionSection = ({
  language,
  onFocus = () => {},
}) =>  {
  return (
    <>
      <InputFieldControl2 
        areaTitle={i18n.t('instruction', { locale: language })}
        name={`translations.${language}.instructionSectionTitle`}
        title={i18n.t('instruction_section_title', { locale: language })}
        onFocus={() => {
          onFocus('instructionTitle');
        }}
      />
      <ReactHookFormMarkDown
        content={{
          label: i18n.t('instruction_section_content', { locale: language }),
          key: `translations.${language}.instructionSectionContent`,
          focus: () => {
            onFocus('instructionContent')
          }
        }}
      />
    </>
  );
  
}

const mapPropsToState = (state) => ({
})

export default connect(mapPropsToState)(CouponInstructionSection);
