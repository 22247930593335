import { CampaignErrorHandleField } from '../../../components/campaign/campaignCreation/CreateCampaignHandleError';
import { isValidHttpUrl, reducer } from '../../../utils';
import { CampaignDisplayIn, CampaignType, MissionCampaignParticipant, MissionCampaignMode } from '../../../config/CustomEnums';
import {
  GENERAL_TYPE,
  isShowCouponRelated,
  isShowEarningRuleRelated,
} from '../../../models/CreateCampaignModel';

function firstStepValidate({ getValues, setError }) {
  const [
    campaignType,
    missionCampaignParticipant,
    missionCampaignMinimumNumberOfInvitees,
    missionCampaignMaximumNumberOfInvitees,
  ] = getValues([
    'campaignType',
    'missionCampaignParticipant',
    'missionCampaignMinimumNumberOfInvitees',
    'missionCampaignMaximumNumberOfInvitees',
  ])
  const errorList = [];
  if (campaignType === CampaignType.emissionCampaign && missionCampaignParticipant === MissionCampaignParticipant.team) {
    if(isEmpty(missionCampaignMinimumNumberOfInvitees)) {
      errorList.push({
        name: 'missionCampaignMinimumNumberOfInvitees',
        message: CampaignErrorHandleField.requiredField.message,
      });
    } else if(Number(missionCampaignMinimumNumberOfInvitees) < 0) {
      errorList.push({
        name: 'missionCampaignMinimumNumberOfInvitees',
        message: CampaignErrorHandleField.positiveNumber.message,
      });
    }
    if(isEmpty(missionCampaignMaximumNumberOfInvitees)) {
      errorList.push({
        name: 'missionCampaignMaximumNumberOfInvitees',
        message: CampaignErrorHandleField.requiredField.message,
      });
    } else if(Number(missionCampaignMaximumNumberOfInvitees) < 0) {
      errorList.push({
        name: 'missionCampaignMaximumNumberOfInvitees',
        message: CampaignErrorHandleField.positiveNumber.message,
      });
    }
    if(Number(missionCampaignMinimumNumberOfInvitees) > Number(missionCampaignMaximumNumberOfInvitees)) {
      errorList.push({
        name: 'missionCampaignMaximumNumberOfInvitees',
        message: CampaignErrorHandleField.invitees.message,
      });
    }
    errorList.forEach((error) => {
      setError(error.name, {
        type: 'manual',
        message: error.message,
      });
    });
  }
  return !errorList.length;
}

function secondStepValidate({ getValues, setError }) {
  const [
    generalName,
    coverPhoto,
    // isExclusive,
    // square,
    shortDescription,
    // showInCarpark,
    showInMall,
    // selectedCarpark,
    selectedMall,
    campaignType,
    htmlContent,
    missionCampaignMode,
    linkedEarningCampaigns,
    fixedMissionRewards,
    missionCampaignTncUrl,
    missionCampaignPrivacyUrl,
    uploadStampIcons,
    emptyStampPhoto,
    acquiredStampPhoto,
    uploadBadgeIcons,
    emptySlotBadgePhoto,
    acquiredBadgePhoto,
    emptyGiftSlotBadgePhoto,
    collectedGiftBadgePhoto,
    emptyFinalGiftSlotBadgePhoto,
    collectedFinalGiftBadgePhoto,
    translations
  ] = getValues([
    'translations.en.generalName',
    'translations.en.coverPhoto',
    // 'isExclusive',
    // 'translations.en.squareCoverPhoto',
    'translations.en.shortDescription',
    // 'showInCarpark',
    'isShowInMallFreshmarket',
    // 'selectedCarpark',
    'selectedMall',
    'campaignType',
    'translations.en.htmlContent',
    'missionCampaignMode',
    'linkedEarningCampaigns',
    'fixedMissionRewards',
    'translations.en.missionCampaignTncUrl',
    'translations.en.missionCampaignPrivacyUrl',
    'uploadStampIcons',
    'emptyStampPhoto',
    'acquiredStampPhoto',
    'uploadBadgeIcons',
    'emptySlotBadgePhoto',
    'acquiredBadgePhoto',
    'emptyGiftSlotBadgePhoto',
    'collectedGiftBadgePhoto',
    'emptyFinalGiftSlotBadgePhoto',
    'collectedFinalGiftBadgePhoto',
    'translations',
  ])
  // console.log("@40", htmlContent)
  const errorList = [];
  if (!generalName || generalName.length > 200) {
    errorList.push({
      name: 'translations.en.generalName',
      message: CampaignErrorHandleField.generalName.message,
    });
  }
  if (campaignType === CampaignType.htmlCampaign && (!htmlContent || htmlContent?.length < 1)) {
    errorList.push({
      name: 'translations.en.htmlContent',
      message: CampaignErrorHandleField.htmlContent.message,
    });
  }
  if (campaignType !== CampaignType.htmlCampaign && !shortDescription) {
    errorList.push({
      name: 'translations.en.shortDescription',
      message: CampaignErrorHandleField.shortDescription.message,
    });
  }
  if (!coverPhoto) {
    errorList.push({
      name: 'translations.en.coverPhoto',
      message: CampaignErrorHandleField.coverPhoto.message,
    });
  }
  // console.log("@55", showInCarpark, selectedCarpark, selectedCarpark?.length, selectedCarpark?.length < 1)
  // if(showInCarpark && (!selectedCarpark || selectedCarpark?.length ===0)) {
  //   errorList.push({
  //     name: 'selectedCarpark',
  //     message: CampaignErrorHandleField.selectedCarpark.message,
  //   });
  // }
  if(showInMall && (!selectedMall || selectedMall?.length ===0)) {
    errorList.push({
      name: 'selectedMall',
      message: CampaignErrorHandleField.selectedMall.message,
    });
  }
  // if (isExclusive && !square) {
  //   errorList.push({
  //     name: 'translations.en.squareCoverPhoto',
  //     message: CampaignErrorHandleField.squarePhoto.message,
  //   });
  // }

  // stamp campaign
  if (campaignType === CampaignType.estampCampaign && uploadStampIcons) {
    if (!emptyStampPhoto) {
      errorList.push({
        name: 'emptyStampPhoto',
        message: CampaignErrorHandleField.fileRequired.message
      })
    }
    if (!acquiredStampPhoto) {
      errorList.push({
        name: 'acquiredStampPhoto',
        message: CampaignErrorHandleField.fileRequired.message
      })
    }
  }

  //mission campaign
  if (campaignType === CampaignType.emissionCampaign) {
    if (uploadBadgeIcons){
      if (!emptySlotBadgePhoto) {
        errorList.push({
          name: 'emptySlotBadgePhoto',
          message: CampaignErrorHandleField.fileRequired.message
        })
      }
      if (!acquiredBadgePhoto) {
        errorList.push({
          name: 'acquiredBadgePhoto',
          message: CampaignErrorHandleField.fileRequired.message
        })
      }
      if (!emptyGiftSlotBadgePhoto) {
        errorList.push({
          name: 'emptyGiftSlotBadgePhoto',
          message: CampaignErrorHandleField.fileRequired.message
        })
      }
      if (!collectedGiftBadgePhoto) {
        errorList.push({
          name: 'collectedGiftBadgePhoto',
          message: CampaignErrorHandleField.fileRequired.message
        })
      }
      if (!emptyFinalGiftSlotBadgePhoto) {
        errorList.push({
          name: 'emptyFinalGiftSlotBadgePhoto',
          message: CampaignErrorHandleField.fileRequired.message
        })
      }
      if (!collectedFinalGiftBadgePhoto) {
        errorList.push({
          name: 'collectedFinalGiftBadgePhoto',
          message: CampaignErrorHandleField.fileRequired.message
        })
      }
      Object.keys(translations).forEach((key) => {
        console.log("@243", key)
        if (!translations[key]['redeemedGiftBadgePhoto']) {
          errorList.push({
            name: 'translations.en.redeemedGiftBadgePhoto',
            message: CampaignErrorHandleField.fileRequired.message
          })
          errorList.push({
            name: `translations.${key}.redeemedGiftBadgePhoto`,
            message: CampaignErrorHandleField.fileRequired.message
          })
        } 
        if (!translations[key]['redeemedFinalGiftBadgePhoto']) {
          errorList.push({
            name: 'translations.en.redeemedFinalGiftBadgePhoto',
            message: CampaignErrorHandleField.fileRequired.message
          })
          errorList.push({
            name: `translations.${key}.redeemedFinalGiftBadgePhoto`,
            message: CampaignErrorHandleField.fileRequired.message
          })
        }
      });
    }
    if (!linkedEarningCampaigns || linkedEarningCampaigns?.length === 0) {
      errorList.push({
        name: 'linkedEarningCampaigns',
        message: CampaignErrorHandleField.linkedEarningCampaigns.message
      })
    }
    console.log("@152", missionCampaignTncUrl)
    if (!missionCampaignTncUrl) {
      errorList.push({
        name: 'translations.en.missionCampaignTncUrl',
        message: CampaignErrorHandleField.requiredUrl.message
      })
    }
    // if (!missionCampaignPrivacyUrl) {
    //   errorList.push({
    //     name: 'translations.en.missionCampaignPrivacyUrl',
    //     message: CampaignErrorHandleField.requiredUrl.message
    //   })
    // }
    if (missionCampaignMode === MissionCampaignMode.fixedRewards) {
      if(!fixedMissionRewards || fixedMissionRewards?.length === 0) {
        errorList.push({
          name: 'fixedMissionRewards',
          message: CampaignErrorHandleField.linkedCouponSet.message
        })
      } else {
        let requiredBadgesList = []
        fixedMissionRewards.forEach((item, index) => {
          if (!item?.couponTemplate?.name) {
            errorList.push({
              name: `fixedMissionRewards[${index}].couponTemplate`,
              message: CampaignErrorHandleField.linkedCouponSet.message
            })
          }
          if (isEmpty(item?.requiredBadges) || Number(item?.requiredBadges) < 0 || Number(item?.requiredBadges) > 9999) {
            errorList.push({
              name: `fixedMissionRewards[${index}].requiredBadges`,
              message: CampaignErrorHandleField.numberLimit.message
            })
          } else if (requiredBadgesList.includes(item?.requiredBadges)) {
            errorList.push({
              name: `fixedMissionRewards[${index}].requiredBadges`,
              message: CampaignErrorHandleField.requiredBadges.message
            })
          } else {
            requiredBadgesList.push(item?.requiredBadges)
          }
          if (isEmpty(item?.couponQuantity) || Number(item?.couponQuantity) < 0) {
            errorList.push({
              name: `fixedMissionRewards[${index}].couponQuantity`,
              message: CampaignErrorHandleField.couponQuantityRequired.message
            })
          } else if (Number(item?.couponQuantity) > Number(item?.couponTemplate?.stock)) {
            errorList.push({
              name: `fixedMissionRewards[${index}].couponQuantity`,
              message: CampaignErrorHandleField.couponQuantity.message
            })
          }
        })
      }
    }
  }

  errorList.forEach((error) => {
    setError(error.name, {
      type: 'manual',
      message: error.message,
    });
  });
  return !errorList.length;
}

const isEmpty = (value) => {
  return value === undefined || value === null || value === '' || isNaN(value);
}

function thirdStepValidate({ getValues, setError }) {
  const [
    isExclusive,
    activeStartDate,
    activeEndDate,
    visibleStartDate,
    visibleEndDate,
    campaignType,
    requiredStamps,
    isAlwaysActivePeriod,
    isAlwaysVisiblePeriod,
    customerGroup,
    segments,
    generalType,
    overallLimit,
    periodicLimit,
    periodicLimitDays,
    perHeadLimit,
    perHeadPeriodicLimit,
    perHeadPeriodicLimitDays,
    acquireCouponNumberEachTime,
    selectedCouponSets,
    displayOverLimit,
    linkedCoupon,
    linkedEarningRules,
    displayIn,
    ownedCouponLimit,
    categories
  ] = getValues([
    'isExclusive',
    'activeStartDate',
    'activeEndDate',
    'visibleStartDate',
    'visibleEndDate',
    'campaignType',
    'requiredStamps',
    'isAlwaysActivePeriod',
    'isAlwaysVisiblePeriod',
    'customerGroup',
    'segments',
    'generalType',
    'overallLimit',
    'periodicLimit',
    'periodicLimitDays',
    'perHeadLimit',
    'perHeadPeriodicLimit',
    'perHeadPeriodicLimitDays',
    'acquireCouponNumberEachTime',
    'selectedCouponSets',
    'displayOverLimit',
    'linkedCoupon',
    'linkedEarningRules',
    'displayIn',
    'ownedCouponLimit',
    'categories',
  ])
  const linkedCouponExpiryDate = linkedCoupon?.absoluteExpiryDate
    ? new Date(linkedCoupon?.absoluteExpiryDate)
    : null;
  const couponRewardExpiryDate = linkedEarningRules?.couponTemplate
    ?.absoluteExpiryDate
    ? new Date(linkedEarningRules?.couponTemplate?.absoluteExpiryDate)
    : null;
  const couponExpiryDate = linkedCouponExpiryDate || couponRewardExpiryDate;
  const isInterestClub = displayIn === CampaignDisplayIn.interestClub;
  const errorList = [];
  if (isExclusive) {
    if (
      generalType === GENERAL_TYPE.customerGroup &&
      customerGroup?.length === 0
    ) {
      errorList.push({
        name: 'customerGroup',
        message: CampaignErrorHandleField.customerGroup.message,
      });
    }
    if (generalType === GENERAL_TYPE.segment && segments?.length === 0) {
      errorList.push({
        name: 'segments',
        message: CampaignErrorHandleField.segment.message,
      });
    }
  }
  if (
    !isAlwaysActivePeriod &&
    activeStartDate.getTime() >= activeEndDate.getTime()
  ) {
    errorList.push({
      name: 'activeEndDate',
      message: CampaignErrorHandleField.activeEndDate.message,
    });
    // errorFields.push(CampaignErrorHandleField.activeEndDate.name);
  }
  if (
    !isAlwaysActivePeriod &&
    couponExpiryDate &&
    activeEndDate.getTime() > couponExpiryDate.getTime()
  ) {
    errorList.push({
      name: 'activeEndDate',
      message: CampaignErrorHandleField.activeEndDateWithCoupon.message,
    });
  }
  if (
    isAlwaysActivePeriod && couponExpiryDate
  ) {
    errorList.push({
      name: 'isAlwaysActivePeriod',
      message: CampaignErrorHandleField.activeEndDateWithCoupon.message,
    });
  }
  if (
    !isAlwaysVisiblePeriod &&
    visibleStartDate.getTime() >= visibleEndDate.getTime()
  ) {
    errorList.push({
      name: 'visibleEndDate',
      message: CampaignErrorHandleField.visibleEndDate.message,
    });
    // errorFields.push(CampaignErrorHandleField.visibleEndDate.name);
  }
  if (
    campaignType === CampaignType.couponsCampaign && displayIn === CampaignDisplayIn.stamp &&
    ((requiredStamps !== 0 &&
    !requiredStamps) || requiredStamps <= 0)
  ) {
    errorList.push({
      name: 'requiredStamps',
      message: CampaignErrorHandleField.requiredStamps.message,
    });
    // errorFields.push(CampaignErrorHandleField.requiredPoints.name);
  }
  
  if(campaignType === CampaignType.couponsCampaign){
    const generatedCouponNumber = linkedCoupon?.totalNubmerOfGeneratedCoupons
    if(Number(overallLimit) < 0) {
      errorList.push({
        name: 'overallLimit',
        message: CampaignErrorHandleField.positiveNumber.message,
      });
    }
    if(Number(perHeadLimit) < 0) {
      errorList.push({
        name: 'perHeadLimit',
        message: CampaignErrorHandleField.positiveNumber.message,
      });
    }
    if(Number(overallLimit) > Number(generatedCouponNumber)) {
      errorList.push({
        name: 'overallLimit',
        message: CampaignErrorHandleField.overallLimit.message,
      });
    }
    if(Number(perHeadLimit) > Number(overallLimit)) {
      errorList.push({
        name: 'perHeadLimit',
        message: CampaignErrorHandleField.perHeadLimit.message,
      });
    }
  }
  if(campaignType === CampaignType.fortuneBag){
    if (!isEmpty(overallLimit) && Number(overallLimit) < Number(periodicLimit)) {
      errorList.push({
        name: 'overallLimit',
        message: CampaignErrorHandleField.fortuneBagOverallLimit.message,
      });
    }
    if (!isEmpty(overallLimit) && Number(overallLimit) < Number(perHeadLimit))  {
      errorList.push({
        name: 'overallLimit',
        message: CampaignErrorHandleField.fortuneBagOverallLimit.message,
      });
    }
    if (!isEmpty(perHeadLimit) && Number(perHeadLimit) < Number(perHeadPeriodicLimit)) {
      errorList.push({
        name: 'perHeadLimit',
        message: CampaignErrorHandleField.fortuneBagPerHeadLimit.message,
      });
    }
    if (!isEmpty(periodicLimit) && Number(periodicLimit) < Number(perHeadPeriodicLimit))  {
      errorList.push({
        name: 'periodicLimit',
        message: CampaignErrorHandleField.fortuneBagPeriodicLimit.message,
      });
    }
    if(isEmpty(acquireCouponNumberEachTime)) {
      errorList.push({
        name: 'acquireCouponNumberEachTime',
        message: CampaignErrorHandleField.acquireCouponNumberEachTimeEmpty.message,
      });
    }
    else if( Number(acquireCouponNumberEachTime) > Number(selectedCouponSets.length)) {
      errorList.push({
        name: 'acquireCouponNumberEachTime',
        message: CampaignErrorHandleField.acquireCouponNumberEachTime.message,
      });
    }  
  }
  if (campaignType === CampaignType.estampCampaign) {
    if (isEmpty(ownedCouponLimit) || Number(ownedCouponLimit) < 1 || Number(ownedCouponLimit) > 9999) {
      errorList.push({
        name: `ownedCouponLimit`,
        message: CampaignErrorHandleField.numberLimit.message
      })
    }
  }
  if (isInterestClub && (!categories || categories?.length < 1)) {
    errorList.push({
      name: `categories`,
      message: CampaignErrorHandleField.categories.message
    })
  }
  // return {
  //   invalid: isBack ? false : errorFields.length > 0,
  //   errorFields: isBack ? [] : errorFields,
  //   data: { ...data },
  // };
  // return true;
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'manual',
      message: error.message,
    });
  });
  return !errorList.length;
}

const fourStepValidate = ({ getValues, setError, checkCheckList }) => {
  const [
    checkList
  ] = getValues([
    'checkList'
  ])
  if (Object.keys(checkList || {}).length === 0 || !checkCheckList) {
    return true
  }
  const errorList = [];
  (Object.entries(checkList) || []).forEach(([key, value]) => {
    if (!value) {
      errorList.push({
        name: 'checkList',
        message: CampaignErrorHandleField.checkList.message
      })
      return
    }
  })
  errorList.forEach((error) => {
    setError(error.name, {
      type: 'manual',
      message: error.message,
    });
  });
  return !errorList.length;
}

export function validate({ getValues, setError, clearErrors, step, isBack, checkCheckList = false }) {
  console.log('@71 campaignValidate:', getValues());
  if (isBack) {
    return true;
  }
  clearErrors();
  switch (step) {
    case 0:
      const validStepOne = firstStepValidate({ getValues, setError });
      return validStepOne;
    case 1:
      const validStepTwo = secondStepValidate({ getValues, setError });
      return validStepTwo;
    case 2:
      const validStepThree = thirdStepValidate({ getValues, setError });
      return validStepThree;
    case 3:
      const validStepFour = fourStepValidate({ getValues, setError, checkCheckList });
      return validStepFour
    default:
      const validOne = firstStepValidate({ getValues, setError });
      const validTwo = secondStepValidate({ getValues, setError });
      const validThree = thirdStepValidate({ getValues, setError });
      return validOne && validTwo && validThree;
  }
}
