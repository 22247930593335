import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import DeletePrompt from '../../../components/base/DeletePrompt';
import { useDispatch, connect } from 'react-redux';
import { APIStatus } from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';
import WebviewContentSection from '../../../components/webviewContent/WebviewContentSection';

const CreateWebviewContentStepOne = ({ webContent, createStatus, languages }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const { getValues, trigger } = useFormContext();
  useEffect(() => {
    dispatch({ type: 'language/getList', payload: { isSelectorLoad: true } });
  }, []);

  return (
    <>
      {createStatus === APIStatus.calling ? (
        <Loading />
      ) : (
        <ContentSections
          languageTabContent={{
            containers: languages?.map((item) => ({
              container: [<WebviewContentSection language={item.code} />],
              key: item.code,
              title: item.sourceName,
            })),
          }}
          hidePreview
        />
      )}
      <SaveAndBackButtons
        saveTempText={params.id ? 'Delete' : null}
        saveText={params.id ? 'Update' : 'Save'}
        backAction={() => history.goBack()}
        saveAction={async () => {
          let isValid = await trigger(['name', 'translations.en.description'], { shouldFocus: true });
          if (isValid) {
            let uploadData = {
              values: getValues(),
            };
            if (params.id) {
              uploadData = {
                values: getValues(),
                webviewContentID: params.id,
              };
            }
            dispatch({
              type: 'webContent/createOrUpdate',
              payload: uploadData,
            });
          }
        }}
        saveTempAction={() => setShowDeletePrompt(true)}
      />
      <DeletePrompt
        message={{
          title: 'Delete this webview content',
          content: 'Did you confirm to delete this webview content?',
        }}
        title={'Webview Content'}
        data={webContent}
        relatedSections={[]}
        show={showDeletePrompt}
        onClose={() => {
          setShowDeletePrompt(false);
        }}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: 'webContent/updateState',
            payload: { checkedList: [webContent] },
          });
          dispatch({ type: 'webContent/delete', payload: {} });
        }}
      />
    </>
  );
};

const mapPropsToState = (state) => ({
  webContent: state.webContent.detail,
  createStatus: state.webContent.createStatus,
  languages: state.language.allList,
});

export default connect(mapPropsToState)(CreateWebviewContentStepOne);
