import React, { useState, useEffect } from 'react';
import './LoadingPrompt.scss';
import { Image, Modal } from 'react-bootstrap';
import LoadingIcon from '../../../assets/images/loading-transparent.gif';

function LoadingPrompt(props) {
  const closeAction = props.closeAction || (() => {});
  const customClass = props.customClass || '';
  const [show, setShow] = useState(props.show);

  const handleClose = () => {
    setShow(false);
    closeAction();
  };

  if (!show && props.show) {
    setShow(true);
  }

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdropClassName={'full-screen'}
      className={'loading-modal'}
    >
      <Modal.Body className={`${customClass}`}>
        <Image src={LoadingIcon} className="loading-icon"></Image>
      </Modal.Body>
    </Modal>
  );
}

export default LoadingPrompt;
