import {
  getCustomerGroups,
  getAllCustomers,
  getCustomersByPage,
  deleteCustomers,
  updateCustomer,
  getOneCustomer,
  getCustomerActivityLog,
  deactiveCustomer,
  activeCustomer,
  getResidentialDistricts,
  getWorkingDistricts,
  generatePLL,
  getCustomerPLLFile,
} from '../services/CustomerAPIHelper';
import {
  convertNumberToCursor,
  convertCursorToNumber,
  delay,
  convertPKToId,
  getObjectFromSessionStorage,
  saveToSessionStorage,
} from '../utils';
import { apiWithResponseHandle, loading } from './LoadingUtil';
import {
  StatusTag,
  LanguageTransLation,
  CheckStatus,
  SavedStatus,
  SIGN_UP_METHOD,
  LanguageConfig,
} from '../config/CustomEnums';
import { formatDate, TimeFormater } from '../utils/TimeFormatUtil';
import { CustomerErrorHandleFields } from '../containers/customers/customer/CustomerErrorHandleFields';
import { AGE_RANGE } from '../components/customer/PersonalInfoEditCard';
import i18n from '../I18n';
import { parseTranslations } from './CreateTransactionRecordModel';
import { getTCName } from './TransactionRecordListModel';

const customerSessionKey = 'tempCustomer';
const getInitialState = () => ({
  customerList: [],
  tempCustomerList: [],
  customerGroup: [],
  listDisplayFields: {
    en: [
      { displayName: 'Customer ID', fieldName: 'pk', linked: true },
      { displayName: 'First name', fieldName: 'displayFirstName' },
      { displayName: 'Last name', fieldName: 'displayLastName' },
      { displayName: 'Test member', fieldName: 'testCustomer' },
      { displayName: 'Mobile number', fieldName: 'displayMobileNumber' },
      { displayName: 'Birth year', fieldName: 'displayDateOfBirth' },
      { displayName: 'Membership ID', fieldName: 'membershipId' },
      { displayName: 'RequestedToDel', fieldName: 'isDeleted' },
      { displayName: 'Active Status', fieldName: 'status' },
    ],
    'zh-Hant': [
      { displayName: 'Customer ID', fieldName: 'pk', linked: true },
      { displayName: '名字', fieldName: 'displayFirstName' },
      { displayName: '姓氏', fieldName: 'displayLastName' },
      { displayName: '測試會員', fieldName: 'testCustomerTC' },
      { displayName: '手機號碼', fieldName: 'displayMobileNumber' },
      { displayName: '出生年份', fieldName: 'displayDateOfBirth' },
      { displayName: '會員編號', fieldName: 'membershipId' },
      { displayName: '請求刪除', fieldName: 'displayIsDeleted' },
      { displayName: '有效狀態', fieldName: 'displayStatus' },
    ],
  },
  pagedCustomerList: [],
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  listTotalCount: 0,
  currentLastCursor: '',
  currentPage: 0,
  totalPage: 0,
  totalCount: 0,
  checkedList: [],
  customer: {},
  checked: CheckStatus.initOrNotChecked,
  errorFields: {},
  test: false,
  saved: SavedStatus.init,
  activityLogs: [],
  formChanged: false,
  filters: {
    groups: [],
    segments: [],
    levels: [],
    genders: [],
    age: [0, 0],
    startDate: '',
    endDate: '',
    count: 0,
  },
  hasUpdatedDefaultValues: false,
  formHasSubmitted: false,
  residentialDistricts: [],
  workingDistricts: [],
});

const getLevelPrivilege = (level) => {
  let levelPrivileges = [];
  switch (level) {
    case 'Level 1':
      levelPrivileges = ['Discount for the transaction'];
      break;
    case 'Level 2':
      levelPrivileges = [
        'Discount for the transaction',
        'Refunds for no reason',
      ];
      break;
    case 'Level 3':
      levelPrivileges = [
        'Discount for the transaction',
        'Refunds for no reason',
        '7 days insured',
      ];
      break;
    case 'Level 4':
      levelPrivileges = [
        'Discount for the transaction',
        'Refunds for no reason',
        '7 days insured',
        '24h Customer service',
      ];
      break;
    default:
      levelPrivileges = [];
      break;
  }

  return levelPrivileges;
};

const getReferralSource = (item) => {
  let referralSource = [];
  const referrer = item.referrer
    ? item.referrer.membershipId
    : '';
  const referredCampaign = item.referredByCampaign
    ? `[ID:${item.referredByCampaign.pk}] ${item.referredByCampaign.name}`
    : '';

  if (referrer) {
    referralSource.push(referrer);
  }

  if (referredCampaign) {
    referralSource.push('campaign ' + referredCampaign);
  }

  return referralSource.join(', ');
};

const getAgeOfChildren = (ageOfChildren) => {
  const result = [];
  if (ageOfChildren.length) {
    AGE_RANGE.forEach((ageRangeItem) => {
      if (ageOfChildren.includes(ageRangeItem.name)) {
        result.push({
          label: ageRangeItem.name,
          value: ageRangeItem,
        })
      }
    })
  }
  return result;
}

const getCarType = (carType, language) => {
  let result = '';
  switch (carType) {
    case 'Petrol cars':
      result = i18n.t('concierge.petrol_cars', { locale: language });
      break;
    case 'Electric cars':
      result = i18n.t('concierge.electric_cars', { locale: language });
      break;
    case 'Hybrid cars':
      result = i18n.t('concierge.hybrid_cars', { locale: language });
      break;
    default:
      result = '';
      break;
  }
  return result;
}

const getMaritalStatus = (maritalStatus, language) => {
  let result = '';
  switch (maritalStatus) {
    case 'Single':
      result = i18n.t('concierge.single', { locale: language });
      break;
    case 'Married':
      result = i18n.t('concierge.married', { locale: language });
      break;
    default:
      result = '';
      break;
  }
  return result;
}

const getGender = (gender, language) => {
  let result = '';
  switch (gender) {
    case 'MALE':
      result = i18n.t('concierge.male', { locale: language });
      break;
    case 'FEMALE':
      result = i18n.t('concierge.female', { locale: language });
      break;
    case 'NOT_DISCLOSED':
      result = i18n.t('concierge.not_disclosed', { locale: language });
      break;
    default:
      result = '';
      break;
  }
  return result;
}

const parseListCustomer = (item) => {
  return {
    pk: item.pk,
    owner: `${item.firstName} ${item.lastName}`,
    name: item.nickname
      ? `${item.firstName} ${item.lastName} (${item.nickname})`
      : `${item.firstName} ${item.lastName}`,
    displayFirstName: item.firstName,
    displayLastName: item.lastName,
    membershipId: item.membershipId,
    mobileNumber: item.mobilePhoneNumberCountryCode && item.mobilePhoneNumberSubscriberNumber
      ? `+${item.mobilePhoneNumberCountryCode}\r\n${item.mobilePhoneNumberSubscriberNumber}`
      : '-',
    displayMobileNumber: item.mobilePhoneNumberSubscriberNumber,
    email: item.emailAddress,
    pointAccount: item.pointAccount,
    level: item.pointAccount?.currentLevel?.levelName,
    segment: item.segments?.edges.map((seg) => seg.node.name).join(', '),
    status: item.isForcedInactive ? StatusTag.deactive : StatusTag.active,
    displayStatus: item.isForcedInactive
      ? i18n.t('concierge.deactive', {
          locale: LanguageConfig.traditionalChinese,
        })
      : i18n.t('concierge.active', {
          locale: LanguageConfig.traditionalChinese,
        }),
    testCustomer: item.isAssignedAsTestingCustomer ? 'Test\r\nmember' : '-',
    testCustomerTC: item.isAssignedAsTestingCustomer ? '測試會員' : '-',
    octopusCards: item.customerOctopusCards?.edges.map((octopus) => `${octopus.node.octopusCardNumber}-${octopus.node.octopusCardCheckDigit || ''}`).join(', '),
    displayDateOfBirth: formatDate(item.dateOfBirth, 'yyyy'),
    isDeleted: item.isDeleted ? 'Yes' : 'No',
    displayIsDeleted: item.isDeleted ? '是' : '否',
  };
};

const parseDetailCustomer = (item) => {
  return {
    ...item,
    owner: `${item.firstName} ${item.lastName}`,
    name: item.nickname
      ? `${item.firstName} ${item.lastName} (${item.nickname})`
      : `${item.firstName} ${item.lastName}`,
    membersipId: item.membersipId,
    signUpMethod: item.signUpMethod,
    signUpMethodDisplay: SIGN_UP_METHOD?.[item.signUpMethod] || '-',
    chineseFirstName: '-',
    chineseLastName: '-',
    chineseName: '-',
    mobileNumber: item.mobilePhoneNumberCountryCode && item.mobilePhoneNumberSubscriberNumber
      ? `+${item.mobilePhoneNumberCountryCode}\r\n${item.mobilePhoneNumberSubscriberNumber}`
      : '-',
    email: item.emailAddress,
    level: item.pointAccount?.currentLevel?.levelName,
    levelRenewalDate: formatDate(item.pointAccount?.currentLevelRenewDatetime),
    levelExpiredIndays: item.pointAccount?.currentLevelExpiredInXDays,
    segment: item.segments?.edges.map((seg) => seg.node.name).join(', '),
    status: item.isForcedInactive ? StatusTag.deactive : StatusTag.active,
    group: item.groups?.edges.map((seg) => seg.node.name).join(', '),
    inGroups: item.groups?.edges.map((seg) => ({
      pk: seg.node.pk,
      name: seg.node.name,
      value: seg.node,
    })),
    referrerUser: item.referrer
      ? {
          label: item.referrer.membershipId,
          value: item.referrer,
        }
      : null,
    referrerCampaign: item.referredByCampaign
      ? {
          label: item.referredByCampaign?.name,
          value: item.referredByCampaign,
        }
      : null,
    promotionCode: item.promotionCode,
    referralSource: getReferralSource(item),
    formateCreated: formatDate(item.creationDate),
    lifetime: item.totalSpending,
    averageOrder: item.averageOrderValue,
    totalOrders: item.totalNumberOfOrders,
    lastOrderDays: item.daysFromLastOrder,
    optOutFromDirectMarketing: item.hasAgreedDirectMarketing ? 'No' : 'Yes',
    optOutFromDirectMarketingTC: item.hasAgreedDirectMarketing ? '否' : '是',
    legalAgreement: 'Yes',
    dataProcessingAgreement: 'Yes',
    pointsBalance: item.pointAccount?.balance,
    totalLifetimePointsUsed: item.pointAccount?.totalPointsUsed,
    totalLifetimePointsExpired: item.pointAccount?.totalPointsExpired,
    tpe: item.pointAccount?.totalPointsEarned,
    levelPrivilege: getLevelPrivilege(
      item.pointAccount?.currentLevel?.levelName,
    ).join(', '),
    availableCampaign: '-',
    coupons: item.coupons?.edges.map((item, index) => `[ID:${item.node.pk}] ${item.node.template.name}`),
    ownedCoupons: item.coupons?.edges.map((item, index) => ({
      pk: item.node.pk,
      name: item.node.template.name,
      value: item.node,
    })),
    preferredMessageLanguageDisplay: item.preferredMessageLanguage
      ? LanguageTransLation[item.preferredMessageLanguage]
      : '-',
    socialMedia: '-',
    gender: item.gender?.value,
    displayGender: getGender(item.gender?.value, LanguageConfig.english),
    displayGenderTC: getGender(item.gender?.value, LanguageConfig.traditionalChinese),
    testCustomer: item.isAssignedAsTestingCustomer ? 'Test\r\ncustomer' : '-',
    assignToTest: item.isAssignedAsTestingCustomer ? 'Yes' : 'No',
    assignToTestTC: item.isAssignedAsTestingCustomer ? '是' : '否',
    dateOfBirth: new Date(item.dateOfBirth),
    displayDateOfBirth: formatDate(item.dateOfBirth),
    displayYearOfBirth: formatDate(item.dateOfBirth, 'yyyy'),
    displayMonthOfBirth: formatDate(item.dateOfBirth, 'MMMM'),
    displayFavouriteContentTypes: item.favouriteContentTypes?.edges.map((item) => `[ID:${item.node.pk}] ${item.node.name}`),
    displayJoinedInterestClubs: item.joinedInterestClubs?.edges.map((item) => `[ID:${item.node?.club?.pk}] ${item.node?.club?.name}`),
    displayFavouritedMalls: item.favouritedMalls?.edges.map((item) => `[ID:${item.node.pk}] ${item.node.name}`),
    displayHaveChildren: item.haveChildren ? 'Yes' : 'No',
    displayHaveChildrenTC: item.haveChildren ? '是' : '否',
    ageOfChildren: getAgeOfChildren(item.ageOfChildren),
    displayAgeOfChildren: item.ageOfChildren.join(),
    displayIsMonthlyParkingUser: item.carInfo?.isMonthlyParkingUser ? 'Yes' : 'No',
    displayIsMonthlyParkingUserTC: item.carInfo?.isMonthlyParkingUser ? '是' : '否',
    displayCarType: getCarType(item.carInfo?.carType, LanguageConfig.english),
    displayCarTypeTC: getCarType(item.carInfo?.carType, LanguageConfig.traditionalChinese),
    displayLicenseNumber: item.carInfo?.licenseNumber,
    octopusCards: item.customerOctopusCards?.edges.map((octopus) => `${octopus.node.octopusCardNumber}-${octopus.node.octopusCardCheckDigit || ''}`),
    displayResidentialDistrict: item.residentialDistrict?.name,
    displayResidentialDistrictTC: getTCName(item.residentialDistrict),
    displayWorkingDistrict: item.workingDistrict?.name,
    displayWorkingDistrictTC: getTCName(item.workingDistrict),
    displayMaritalStatus: getMaritalStatus(item.maritalStatus, LanguageConfig.english),
    displayMaritalStatusTC: getMaritalStatus(item.maritalStatus, LanguageConfig.traditionalChinese),
  };
};

const parseCustomerList = (data) => {
  return data.map((item) => {
    return parseListCustomer(item.node);
  });
};

const parseActivityLogs = (data) => {
  console.log('@@152: ', data);
  return data?.map((item) => {
    return {
      ...item.node,
      text: item.node.customerPointAndLevelLogTitle,
      updateTime: formatDate(
        item.node.creationDate,
        TimeFormater.dayMonthYearWeekTime,
      ),
    };
  });
};

export default {
  namespace: 'customerList',
  state: getInitialState(),
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },

    updateAllCustomerTempList(state, { payload }) {
      const { tempCustomerList, page } = payload;
      return {
        ...state,
        tempCustomerList: page > 1
          ? [...state.tempCustomerList, ...tempCustomerList]
          : tempCustomerList,
        totalCount: page > 1
          ? state.tempCustomerList.length + tempCustomerList.length
          : tempCustomerList.length,
      };
    },

    updateCustomerList(state, { payload }) {
      const { customerList, page } = payload;
      return {
        ...state,
        customerList: page > 1
          ? [...state.customerList, ...customerList]
          : customerList,
        totalCount:  page > 1
          ? state.customerList.length + customerList.length
          : customerList.length,
      };
    },

    assembleCustomerGroup(state, { payload }) {
      const groups = payload.list.map((group) => {
        const node = group.node;
        return {
          id: node.id,
          pk: node.pk,
          name: node.name,
        };
      });
      return { ...state, customerGroup: [...groups] };
    },

    changeVals(state, { payload }) {
      // deprecated
      console.log('@@customer edit: vals changed', payload);
      let tempCustomer = getObjectFromSessionStorage(customerSessionKey);

      let data = {};
      if (payload.vals) {
        data = payload.vals;
      }

      tempCustomer = { ...tempCustomer, ...data };
      saveToSessionStorage(customerSessionKey, tempCustomer);

      return {
        ...state,
        formChanged: true,
      };
    },

    checkValsValid(state, { payload }) {
      let tempCustomer = getObjectFromSessionStorage(customerSessionKey);

      let errorFields = { fields: [], messages: [] };
      let checked = CheckStatus.initOrNotChecked;

      Object.keys(CustomerErrorHandleFields).map((field) => {
        if (!tempCustomer[field]) {
          errorFields.fields.push(field);
          errorFields.messages.push({ field, errorType: 'required' });
        }
      });

      if (errorFields.fields.length > 0) {
        checked = CheckStatus.checkedWithFail;
      } else {
        checked = CheckStatus.checkedWithSuccess;
      }

      return {
        ...state,
        checked,
        errorFields,
        test: true,
      };
    },

    clearData(state, { payload }) {
      return {
        ...state,
        ...getInitialState(),
      };
    },

    updateWorkingDistrictsList(state, { payload }) {
      const { workingDistricts, page } = payload;
      return {
        ...state,
        workingDistricts: page > 1
          ? [...state.workingDistricts, ...workingDistricts]
          : workingDistricts,
      };
    },

    updateResidentialDistrictsList(state, { payload }) {
      const { residentialDistricts, page } = payload;
      return {
        ...state,
        residentialDistricts: page > 1
          ? [...state.residentialDistricts, ...residentialDistricts]
          : residentialDistricts,
      };
    },
  },

  effects: {
    getCustomerGroups: [
      function* ({ payload }, { call, select, put }) {
        const serviceArgs = [getCustomerGroups];
        function* onSuccess(data) {
          yield put({
            type: 'assembleCustomerGroup',
            payload: { list: data.customerGroups.edges },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getAllCustomers: [
      function* ({ payload }, { call, put, select }) {
        const { afterCursor, search, moreSearch } = payload;
        const page = afterCursor ? payload.page : 1;
        const response = yield call(getAllCustomers, afterCursor, search, moreSearch);
        if (!response || response.status >= 300) {
          return;
        }
        if (response.data.data?.customers) {
          const customerInfo = response.data.data.customers;
          const tempCustomerList = parseCustomerList(customerInfo.edges);
          yield put({
            type: 'updateAllCustomerTempList',
            payload: {
              tempCustomerList: tempCustomerList,
              page: page,
            },
          });
          if (customerInfo.pageInfo.hasNextPage) {
            yield put({
              type: 'getAllCustomers',
              payload: {
                afterCursor: customerInfo.pageInfo.endCursor,
                page: page + 1
              },
            });
          } else {
            const tempCustomerList = yield select((state) => state.customerList.tempCustomerList);
            yield put({
              type: 'updateState',
              payload: {
                customerList: tempCustomerList,
              },
            });
          }
        }
      },
      { type: 'takeLatest' },
    ],
    getPagedCustomers: [
      function* ({ payload }, { call, put, all }) {
        const page = payload.page;
        const pageCursor = page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const serviceArgs = [
          getCustomersByPage,
          pageCursor,
          payload.reverse,
          payload.search,
          payload.customer_groups,
          payload.segments,
          payload.levels,
          payload.age,
          payload.gender,
          payload.start_date,
          payload.end_date,
          payload.moreSearch,
          payload.sort,
          payload.keyword,
          payload.isFilterNameOrId,
          payload.isFilterMembershipIDOrPhoneNumber,
          payload.isSimpleNode,
          payload
        ];
        console.log('@@363');
        function* onSuccess(data) {
          const pageInfo = data?.customers?.pageInfo;

          const currentLastCursor = pageInfo?.endCursor;
          const totalCount = data?.customers?.totalCount;
          console.log('@@115: ', data);
          yield all([
            put({
              type: 'updateState',
              payload: {
                pagedCustomerList: parseCustomerList(data?.customers?.edges),
                pageInfo: {
                  startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1 || 0,
                  endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1 || 0,
                },
                currentLastCursor: currentLastCursor,
                listTotalCount: totalCount,
                totalPage: Math.ceil(totalCount / 20),
              },
            }),
            put({
              type: 'updateCustomerList',
              payload: {
                customerList: parseCustomerList(data?.customers?.edges),
                page,
              },
            }),
          ]);
        }
        function* onFailed(data) {
          console.log('@@122: ', data);
        }

        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    delete: [
      function* ({ payload }, { all, put, select }) {
        const { checkedList } = yield select((state) => ({
          checkedList: state.customerList.checkedList,
        }));

        let pks = [];
        checkedList.forEach((item) => {
          pks.push(item.pk);
        });

        const serviceArgs = [deleteCustomers, pks];
        const afterAction = payload.afterAction || (() => {});
        console.log('@@231: ', pks);
        function* onSuccess(data) {
          console.log('@@115: ', data);

          yield all([
            put({
              type: 'updateState',
              payload: {
                checkedList: [],
                formChanged: false,
              },
            }),
          ]);
          yield delay(1000);
          afterAction();
        }
        function* onFailed(data) {
          console.log('@@122: ', data);

          yield put({
            type: 'updateState',
            payload: {
              checkedList: [],
              formChanged: false,
            },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    updateCustomer: [
      function* ({ payload }, { all, put, select }) {
        const tempCustomer = payload.data;
        const originCustomer = payload.customer;
        const data = {
          id: tempCustomer.pk,
          firstName: tempCustomer.firstName,
          lastName: tempCustomer.lastName,
          // nickname: tempCustomer.nickname,
          gender: tempCustomer.gender,
          dateOfBirth: formatDate(tempCustomer.dateOfBirth, TimeFormater.yearMonthDay),
          hasAgreedDirectMarketing: tempCustomer.hasAgreedDirectMarketing,
          residentialDistrict: tempCustomer.residentialDistrict?.pk,
          workingDistrict: tempCustomer.workingDistrict?.pk,
          maritalStatus: tempCustomer.maritalStatus,
          householdIncome: tempCustomer.householdIncome,
          haveChildren: tempCustomer.haveChildren,
          ageOfChildren: tempCustomer.ageOfChildren?.map((item) => item.value?.name),
          carInfo: tempCustomer.carInfo,
          customerOctopusCards: tempCustomer.octopusCards,
          // address: tempCustomer.address,
          // company: tempCustomer.company,
          // signUpChannel: tempCustomer.signUpChannel,
          referrer: tempCustomer.referrerUser?.value?.pk || originCustomer.referrer?.pk,
          promotionCode: tempCustomer.promotionCode,
          couponsToReclaim: tempCustomer.removedCoupons?.map(
            (item) => item.value.pk,
          ),
          groupsToLeave: tempCustomer.leaveGroups?.map((item) => item.value.pk),
          // pointsChange: {
          //   type: tempCustomer.pointTransaction?.value || null,
          //   value: tempCustomer.transactionPoint || null,
          //   remarks: tempCustomer.pointTransactionRemark || null,
          // },
          isAssignedAsTestingCustomer: tempCustomer.isAssignedAsTestingCustomer,
          isForcedInactive: tempCustomer.isForcedInactive,
        };
        data.mobilePhoneNumberCountryCode = tempCustomer.mobilePhoneNumberCountryCode;
        data.mobilePhoneNumberSubscriberNumber = tempCustomer.mobilePhoneNumberSubscriberNumber;
        data.emailAddress = tempCustomer?.emailAddress ? tempCustomer.emailAddress : null;

        const serviceArgs = [updateCustomer, data];

        console.log('@@382: ', data);

        function* onSuccess(data) {
          console.log('@@115: ', data);

          yield put({
            type: 'updateState',
            payload: {
              formChanged: false,
              formHasSubmitted: true,
              saved: SavedStatus.savedWithSuccess,
            },
          });
        }
        function* onFailed(data) {
          console.log('@@122: ', data);

          yield put({
            type: 'updateState',
            payload: {
              formChanged: false,
              formHasSubmitted: true,
              saved: SavedStatus.savedWithFail,
            },
          });

          yield put({
            type: 'navBars/updateState',
            payload: {
              saveDiscardToastShowing: {
                value: true,
                type: data.data.errors[0].message,
              },
            },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed, onFailed);
      },
      { type: 'takeLatest' },
    ],
    updateCustomerActive: [
      function* ({ payload }, { all, put, select }) {
        let activeAPI = deactiveCustomer;
        if (payload.data.isForcedInactive) {
          activeAPI = activeCustomer;
        }
        const afterAction = payload.afterAction || (() => {});
        const serviceArgs = [activeAPI, { id: payload.data.id }];

        function* onSuccess(data) {
          console.log('@@115: ', data);

          yield afterAction();
        }
        function* onFailed(data) {
          console.log('@@122: ', data);
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    getOneCustomer: [
      function* ({ payload }, { call, put }) {
        const serviceArgs = [
          getOneCustomer,
          convertPKToId('CustomerNode', payload.id),
        ];
        function* onSuccess(data) {
          console.log('@@115: ', data);
          const customer = parseDetailCustomer(data?.customer);
          yield put({
            type: 'updateState',
            payload: {
              customer: customer,
              hasUpdatedDefaultValues: true,
            },
          });
          saveToSessionStorage(customerSessionKey, customer);
        }
        function* onFailed(data) {
          console.log('@@122: ', data);
        }

        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    getCustomerActivityLog: [
      function* ({ payload }, { call, put }) {
        const serviceArgs = [getCustomerActivityLog, payload.ssoUid];
        function* onSuccess(data) {
          console.log('@@115: ', data);
          const activityLogs = parseActivityLogs(
            data?.customerActivityLogs.edges,
          );
          yield put({
            type: 'updateState',
            payload: {
              activityLogs,
            },
          });
        }
        function* onFailed(data) {
          console.log('@@122: ', data);
        }

        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    getResidentialDistricts: [
      function* ({ payload }, { call, put }) {
        const page = payload.page;
        const pageCursor = payload.page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const serviceArgs = [getResidentialDistricts, pageCursor, payload];
        function* onSuccess(data) {
          const residentialDistricts = parseTranslations(data?.residentialDistricts.edges);
          yield put({
            type: 'updateResidentialDistrictsList',
            payload: {
              residentialDistricts,
              page,
            },
          });
        }
        function* onFailed(data) {
          console.log('@@697: ', data);
        }

        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    getWorkingDistricts: [
      function* ({ payload }, { call, put }) {
        const page = payload.page;
        const pageCursor = payload.page
          ? convertNumberToCursor((page - 1) * 20 - 1)
          : '';
        const serviceArgs = [getWorkingDistricts, pageCursor, payload];
        function* onSuccess(data) {
          const workingDistricts = parseTranslations(data?.workingDistricts.edges);
          yield put({
            type: 'updateWorkingDistrictsList',
            payload: {
              workingDistricts,
              page,
            },
          });
        }
        function* onFailed(data) {
          console.log('@@697: ', data);
        }

        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    downloadPLL: [
      function* ({ payload }, { call, put }) {
        const serviceArgs = [generatePLL];
        function* onSuccess(data) {
          // const url = data?.generatePll?.node?.url 
          // window.open(url, '_blank').focus()
          // console.log('@@877', data);
        }
        function* onFailed(data) {
          console.log('@@880: ', data);
        }

        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
    getCustoemrPLLFile: [
      function* ({ payload }, { call, put }) {
        const serviceArgs = [getCustomerPLLFile];
        function* onSuccess(data) {
          const customerPLLFile = data?.customerPllFiles?.edges?.[0]?.node;
          console.log('@@892: ', data);
          yield put({
            type: 'updateState',
              payload: {
                customerPLLFile,
              },
          });
        }
        function* onFailed(data) {
          console.log('@@697: ', data);
        }

        yield loading(serviceArgs, onSuccess, onFailed);
      },
      { type: 'takeLatest' },
    ],
  },
};
