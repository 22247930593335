import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import ContentSections from '../base/ContentSections';
import { validate } from '../../containers/conciergeApp/transactionRecord/CreateTransactionValidate';
import {
  ContinueWithBackButtons,
  OnlyContinueButton,
} from '../base/BottomStepComponent';
import MembershipInfoCard from '../customer/MembershipInfoCard';
import {
  APIStatus,
  CAMPAIGN_REWARD_TYPE,
  COUPON_RECEIVER,
} from '../../config/CustomEnums';
import { getCampaignTypeConfig } from './TransactionCampaignSection';
import { useHistory, useLocation } from 'react-router-dom';
import { CustomTitleWithIcon } from '../earning/CustomBaseComponments';
import Success from '../../assets/images/success.svg';
import Failed from '../../assets/images/fail.svg';
import UseRewardCoupon from './UseRewardCoupon';
import i18n from '../../I18n';
import { getNameByLanguage } from '../../models/CarParkModel';
import { getExtraInfo } from './CreateTransactionRecordStepFour';

function CreateTransactionRecordStepFive({ language, isTCTransaction }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentLocation = useLocation();

  const { getValues, clearErrors, setError } = useFormContext();

  const { createStatus, createErrorMessages, transaction } = useSelector(
    (state) => ({
      createStatus: state.createTransactionRecord.createStatus,
      createErrorMessages:
        state.createTransactionRecord.createErrorMessages || [],
      transaction: state.createTransactionRecord.transaction,
    }),
  );

  const customerIDField = 'customerID';
  const campaignTypeField = 'campaignType';
  const stampCampaignField = 'stampCampaign';
  const missionCampaignField = 'missionCampaign';
  const otherCampaignField = 'otherCampaign';
  const carParkCampaignField = 'carParkCampaign';
  const staffNameField = 'staffName';
  const [
    customerID,
    campaignType,
    stampCampaign,
    missionCampaign,
    otherCampaign,
    carParkCampaign,
    staffName,
  ] = getValues([
    customerIDField,
    campaignTypeField,
    stampCampaignField,
    missionCampaignField,
    otherCampaignField,
    carParkCampaignField,
    staffNameField,
  ]);
  let displayMissionCampaign = getNameByLanguage(transaction?.missionDetailCampaign, language) || getNameByLanguage(missionCampaign, language);
  if (transaction.missionCampaignRewardCoupon !== null) {
    displayMissionCampaign += `(+${
      transaction.missionCampaignRewardCoupon || 0
    } ${i18n.t(transaction.couponReceiver ? `transaction.${COUPON_RECEIVER[transaction.couponReceiver]}` : 'transaction.coupon', { locale: language })})`;
  } else if (transaction.missionCampaignRewardBadge !== null) {
    displayMissionCampaign += `(+${transaction.totalMissionCampaignRewardBadge || 0} ${i18n.t('campaign.badge', { locale: language })})`;
    displayMissionCampaign += getExtraInfo(transaction.missionCampaignRewardBadgeData, 'badge', language);
  }
  let displayStampCampaign = `${getNameByLanguage(stampCampaign, language)} (+${
    transaction.totalStampCampaignReward || 0
  } ${i18n.t(transaction.totalStampCampaignRewardBonus ? 'transaction.stamp_with_bonus' : 'campaign.stamp', { locale: language })})`;
  displayStampCampaign += getExtraInfo(transaction.stampCampaignRewardData, 'stamp', language);
  const data = {
    customerID,
    campaignType: getCampaignTypeConfig(language).filter(
      (type) => type.value === campaignType,
    )[0]?.label,
    stampCampaign: displayStampCampaign,
    missionCampaign: displayMissionCampaign,
    otherCampaign: `${getNameByLanguage(otherCampaign, language)} (+${
      transaction.otherCampaignReward || 0
    } ${i18n.t('campaign.coupon', { locale: language })})`,
    carParkCampaign: carParkCampaign?.name
      ? `${getNameByLanguage(carParkCampaign, language)} (+${
          transaction.carParkCampaignReward || 0
        } ${i18n.t('campaign.coupon', { locale: language })})`
      : '',
    staffName,
  };
  const fields = [
    [{ title: i18n.t('transaction.memberid/mobile_number', { locale: language }), field: customerIDField }],
    [{ title: i18n.t('transaction.transaction_reward_type', { locale: language }), field: campaignTypeField }],
    campaignType === CAMPAIGN_REWARD_TYPE.stamp
      ? [{ title: i18n.t('transaction.stamp_campaign', { locale: language }), field: stampCampaignField }]
      : [],
    campaignType === CAMPAIGN_REWARD_TYPE.mission
      ? [{ title: i18n.t('transaction.mission_campaign', { locale: language }), field: missionCampaignField }]
      : [],
    campaignType === CAMPAIGN_REWARD_TYPE.other
      ? [{ title: i18n.t('transaction.other_campaign', { locale: language }), field: otherCampaignField }]
      : [],
    [{ title: i18n.t('transaction.carpark_campaign', { locale: language }), field: carParkCampaignField }],
    [{ title: i18n.t('transaction.staff_name', { locale: language }), field: staffNameField }],
  ];

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 4,
      isBack: isBack,
    });
    dispatch({
      type: 'createTransactionRecord/stepChange',
      payload: {
        isBack: isBack,
        step: 4,
        isValid,
        isTCTransaction,
      },
    });
  };

  // useEffect(() => {
  //   if (createStatus === APIStatus.success && transaction?.id) {
  //     dispatch({
  //       type: 'transactionRecord/getTransaction',
  //       payload: {
  //         transactionPK: transaction.pk,
  //       },
  //     });
  //   }
  // }, [dispatch, createStatus, transaction]);

  return (
    <>
      <ContentSections
        sections={[
          <>
            {createStatus === APIStatus.success ? (
              <CustomTitleWithIcon title={i18n.t('transaction.rewards_distribute_successfully', { locale: language })} icon={Success} />
            ) : (
              createErrorMessages.map((error) => {
                return (
                  <CustomTitleWithIcon
                    title={
                      `${error.field} - ${error.message}` || 'server error'
                    }
                    icon={Failed}
                  />
                );
              })
            )}
            <MembershipInfoCard title={''} data={data} fields={fields} />
            {createStatus === APIStatus.success && transaction?.coupons ? (
              <UseRewardCoupon
                language={language}
                rewardCoupons={transaction?.coupons}
                UseRewardCoupon={transaction?.couponReceiver === 'LEADER'}
                currentCustomer={transaction?.customer}
                afterAction={() => {
                  dispatch({
                    type: 'transactionRecord/getTransaction',
                    payload: {
                      transactionPK: transaction?.pk,
                      updateCouponOnly: true,
                    },
                  });
                }}
              />
            ) : null}
          </>,
        ]}
        hidePreview={true}
      />
      {createStatus === APIStatus.success ? (
        <OnlyContinueButton
          text={i18n.t('transaction.view_transaction_recode_list', { locale: language })}
          disabledContinue={false}
          continueAction={() => {
            if (isTCTransaction) {
              history.push('/concierge_transaction_records');
            } else {
              history.push('/transaction_records');
            }
          }}
        />
      ) : (
        <ContinueWithBackButtons
          backText={i18n.t('transaction.back', { locale: language })}
          backAction={() => stepChangeAction(true)}
          continueText={i18n.t('transaction.view_transaction_recode_list', { locale: language })}
          continueAction={() => {
            if (isTCTransaction) {
              history.push('/concierge_transaction_records');
            } else {
              history.push('/transaction_records');
            }
          }}
        />
      )}
    </>
  );
}

export default CreateTransactionRecordStepFive;
