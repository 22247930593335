const storePreview = {
};

export const getPreviewLayoutImages = () => {
  return storePreview;
};

export const defaultPreviewImage = () => {
  return null;
};
