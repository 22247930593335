import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { CampaignType, EarningRuleType, MissionCampaignMode } from '../../../config/CustomEnums';
import AuthButton from '../../base/AuthButton';
import { Button } from 'react-bootstrap';
import './ApprovalCheckList.scss';

function ApprovalCheckList({}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users);
  const { watch, setValue, formState, getValues } = useFormContext();
  const errors = formState.errors['checkList'];
  const campaignType = getValues('campaignType')
  const linkedCoupon = getValues('linkedCoupon')   //coupon campaign : e-coupon, interest club, fresh market community, not list
  const selectedCouponSets = getValues('selectedCouponSets')
  const linkedEarningRules = getValues('linkedEarningRules')   //earning campaign : not list
  const linkedEarningRulesReward = (earningRule) => {
    switch (earningRule?.type) {
      case EarningRuleType.birthday:
        return earningRule.birthdayTypeCouponRewardTypeCouponTemplate
      case EarningRuleType.fillingForm:
        return earningRule.fillingFormTypeCouponRewardTypeCouponTemplate
      case EarningRuleType.generalPurchase:
        return earningRule.generalPurchaseTypeCouponRewardTypeCouponTemplate
      case EarningRuleType.gpsCheckIn:
        return earningRule.gpsCheckInTypeCouponRewardTypeCouponTemplate
      case EarningRuleType.memberReferral:
        return earningRule.memberReferralTypeCouponRewardTypeCouponTemplate
      case EarningRuleType.newMember:
        return earningRule.newMemberTypeCouponRewardTypeCouponTemplate
      case EarningRuleType.qrCodeScanning:
        return earningRule.qrCodeScanningTypeCouponRewardTypeCouponTemplate
      default:
        return earningRule?.couponTemplate || null
    } 
  }
  
  const linkedCouponCampaigns = getValues("linkedCouponCampaigns") // estamp campaign: stamp detail campaign
  const linkedCouponCampaignsCouponSet = linkedCouponCampaigns?.map((item) => item?.couponCampaignTypeCouponTemplate)

  const missionCampaignMode = getValues('missionCampaignMode');
  const linkedEarningCampaigns = getValues("linkedEarningCampaigns") //emission campaign: misson detail campaign
  const linkedEarningCampaignsEarninRule = linkedEarningCampaigns?.map((item) => item?.earningCampaignTypeEarningRule)
  const fixedMissionRewards = getValues("fixedMissionRewards");
  

  console.log("@23", campaignType)

  const checkList = watch("checkList") || {}
  console.log("@50", errors)
  const addNewCheckList = (item) => {
    if (!item || checkList?.[item?.id] !== undefined) {
      return
    }
    setValue("checkList", {...checkList, [item.id]: false})
  }
  const item = (item, pathRoot) => {
    addNewCheckList(item)
    return (
    <div className={`check-list-item-container`}>
      <label className={`checkbox-container check-list-label`}>
        {`[ID:${item?.pk}] ${item?.name}`}
        <input  type="checkbox" checked={checkList?.[item?.id]} onChange={() => {
          console.log("@67", checkList)
          const newCheckList = {
            ...checkList,
            [item.id]: !checkList?.[item?.id]
          }
          setValue("checkList", newCheckList, {shouldDirty: true})
        }} />
        <span className={errors && !checkList?.[item?.id] ? 'checkmark error-field' : 'checkmark'}></span>
      </label>
      <Button
        className={'btn-back-button-common btn-download btn btn-primary'}
        onClick={() => {
          window.open(`${pathRoot}/${item?.pk}/edit`, "_blank")
        }}
      >
        {'Go to review'}
      </Button>
      {errors && !checkList?.[item?.id] ? 
        <label className='error-field-message-style'>{errors.message}</label>
        : null
      }
        

    </div>)
  }

  return (
    <>
      <label className="create-section-title">{'Checklist'}</label>
      {/* //coupon campaign */}
      { campaignType === CampaignType.couponsCampaign ? <>
        <label className="create-section-label check-list-section">{'Related coupon set'}</label>
        {item(linkedCoupon, '/coupons')}
      </> : null}

      { campaignType === CampaignType.fortuneBag ? <>
        <label className="create-section-label check-list-section">{'Related coupon sets'}</label>
        {selectedCouponSets?.map((element) => {
          return item(element.value, '/coupons')
        })}
      </> : null}

      { campaignType === CampaignType.earningRulesCampaign ? <>
        <label className="create-section-label check-list-section">{'Related coupon set'}</label>
        {item(linkedEarningRulesReward(linkedEarningRules), '/coupons')}
        <label className="create-section-label check-list-section">{'Related earning rule'}</label>
        {item(linkedEarningRules, '/earns')}
      </> : null}

      { campaignType === CampaignType.estampCampaign ? <>
        <label className="create-section-label check-list-section">{'Related coupon set'}</label>
        {linkedCouponCampaignsCouponSet?.map((element) => {
          return item(element, '/coupons')
        })}
        <label className="create-section-label check-list-section">{'Related coupon campaign (For Stamp detail page)'}</label>
        {linkedCouponCampaigns?.map((element) => {
          return item(element, '/campaigns')
        })}
        <label className="create-section-label check-list-section">{'Related earning rule'}</label>
        {item(linkedEarningRules, '/earns')}
      </> : null}

      {  campaignType === CampaignType.emissionCampaign ? <>
        { missionCampaignMode === MissionCampaignMode.fixedRewards ? <>
          <label className="create-section-label check-list-section">{'Related coupon set'}</label>
          {fixedMissionRewards?.map((element) => {
            return item(element?.couponTemplate, '/coupons')
          })}
        </> : <>
        <label className="create-section-label check-list-section">{'Related coupon set'}</label>
        {linkedEarningCampaignsEarninRule?.map((element) => {
          return item(linkedEarningRulesReward(element), '/coupons')
        })}
        </>}
        <label className="create-section-label check-list-section">{'Related earning rule'}</label>
        {linkedEarningCampaignsEarninRule?.map((element) => {
          return item(element, '/earns')
        })}
        <label className="create-section-label check-list-section">{'Related Earning campaign (For Mission detail page)'}</label>
        {linkedEarningCampaigns?.map((element) => {
          return item(element, '/campaigns')
        })}
      </> : null}
    </>
  );
}

export default ApprovalCheckList;
