import React, { useEffect } from 'react';
import InputFieldControl from '../../base/InputFieldControl';
import { useFormContext } from 'react-hook-form';
import { ReactHookFormErrorMessage } from '../../base/ErrorFieldMessage';
import { useLocation } from 'react-router-dom';
import { CustomTitleLabel, CustomNumberMutipleInputWithUnit } from '../../earning/CustomBaseComponments';
import BaseMultipleSelectorDropDown from '../../base/BaseMultipleSelectorDropDown';

function FortuneBagCampaignSection({disabled}) {
  const location = useLocation();
  const { watch, setValue, formState } = useFormContext();
  const errors = formState.errors;
  const selectedCouponSets = watch('selectedCouponSets') || [];

  const overallLimit = watch('overallLimit')
  const periodicLimit = watch('periodicLimit')
  const periodicLimitDays = watch('periodicLimitDays')
  const perHeadLimit = watch('perHeadLimit')
  const perHeadPeriodicLimit = watch('perHeadPeriodicLimit')
  const perHeadPeriodicLimitDays = watch('perHeadPeriodicLimitDays')
  const acquireCouponNumberEachTime = watch('acquireCouponNumberEachTime')
  
  useEffect(() => {
    if (location.pathname.includes('edit')) {
      return;
    }
    if (!perHeadLimit) {
      setValue('perHeadLimit', 1, { shouldDirty: true });
    }
  }, [])

  return (
    <>
      <label className="create-section-title">limit settings</label>

      <BaseMultipleSelectorDropDown
        disabled
        fortunebagShowCouponLimitSection
        value={selectedCouponSets}
        hasMarginTop={false}
        fullSummaryTitle="No. of coupons available per coupon set"
        summarySubTitle={"Quantity - [Coupon set ID] Coupon set name"}
      />
      <InputFieldControl
        name={'overallLimit'}
        title={'Overall limit (Optional)'}
        tips={'Max. number of fortune bags to be given for this campaign.'}
        value={overallLimit}
        setValue={(value) => {
          setValue('overallLimit', value, { shouldDirty: true });
        }}
        type='number'
        unit='number'
        className='coupon-campaign-section-input'
        disabled={disabled}
      />

      <CustomTitleLabel title="Overall period limit (Optional)" />
        <label className="tips-message">
          {'Max. number of fortune bags to be given within a period for this campaign.'}
        </label>
        <CustomNumberMutipleInputWithUnit
          per="number per"
          unit="days"
          setFirstValue={(value) => {
            setValue("periodicLimit", value, { shouldDirty: true})
          }}
          firstDefaultValue={periodicLimit}
          setSecondValue={(value) => {
            setValue("periodicLimitDays", value, { shouldDirty: true })
          }}
          secondDefaultValue={periodicLimitDays}
          disabled={disabled}
        />
      <ReactHookFormErrorMessage errors={errors} id={'periodicLimit'} />

      <InputFieldControl
        name={'perHeadLimit'}
        title={'Per head limit (Optional)'}
        tips={'Number of times each customer can get the fortune bag.'}
        value={perHeadLimit}
        setValue={(value) => {
          setValue('perHeadLimit', value, { shouldDirty: true });
        }}
        type='number'
        unit='times'
        className='coupon-campaign-section-input'
        disabled={disabled}
      />

      <CustomTitleLabel title="Per head periodic limit (Optional)" />
        <label className="tips-message">
          {'Number of times each customer can get the fortune bag within a period.'}
        </label>
        <CustomNumberMutipleInputWithUnit
          per="times per"
          unit="days"
          setFirstValue={(value) => {
            setValue("perHeadPeriodicLimit", value, { shouldDirty: true})
          }}
          firstDefaultValue={perHeadPeriodicLimit}
          setSecondValue={(value) => {
            setValue("perHeadPeriodicLimitDays", value, { shouldDirty: true })
          }}
          secondDefaultValue={perHeadPeriodicLimitDays}
          disabled={disabled}
        />
      <ReactHookFormErrorMessage errors={errors} id={'perHeadPeriodicLimit'} />

      <InputFieldControl
        tips={`You have linked ${selectedCouponSets.length} coupon sets.`}
        name={'acquireCouponNumberEachTime'}
        title={'No. of coupons can be acquired each time'}
        value={acquireCouponNumberEachTime}
        setValue={(value) => {
          setValue('acquireCouponNumberEachTime', value, { shouldDirty: true });
        }}
        type='number'
        className='coupon-campaign-section-input'
        shortDescription={`It should not be greater than ${selectedCouponSets.length}, the number of linked coupon sets.`}
        disabled={disabled}
      />
    </>
  );
}

export default FortuneBagCampaignSection;
