import PreviewMall from '../../assets/images/mall/preview_mall.svg';
import PreviewMallOne from '../../assets/images/mall/preview_mall_panoramic_view.svg';

const mallPreview = {
  panoramicViewUrl: PreviewMallOne,
};

export const getPreviewLayoutImages = () => {
  return mallPreview;
};

export const defaultPreviewImage = () => {
  return PreviewMall;
};
