import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { createAction } from '../../../utils';
import BaseForm from '../../../components/base/v2/BaseForm';
import Loading from '../../../components/base/Loading';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CreateThemedSectionStepOne from '../../../components/themedSection/CreateThemedSectionStepOne';

function CreateThemedSection({
  themeSections,
  formHasSubmitted,
  hasUpdatedDefaultValues,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;
  const themeSection = themeSections?.[0]?.node || {};

  useEffect(() => {
    dispatch(createAction('themeSection/getAllList')({}));
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
  }, [dispatch]);

  if (id && !themeSection?.id) {
    return <Loading customClass={'common-full-height'} />;
  }
  const stepSet = [<CreateThemedSectionStepOne />];
  return (
    <BaseForm
      defaultValues={themeSection}
      formHasSubmitted={formHasSubmitted}
      hasUpdatedDefaultValues={hasUpdatedDefaultValues}
      tempSave={() => {}}
      content={stepSet}
      breadcrumb={<CustomBreadcrumb name={''} />}
      currentStep={0}
      caution={{
        detail: '',
        title: 'Hub',
      }}
    />
  );
}

const mapPropsToState = (state) => ({
  themeSections: state.themeSection.notPagedAllList,
  hasUpdatedDefaultValues: state.themeSection.hasUpdatedDefaultValues,
  formHasSubmitted: state.themeSection.formHasSubmitted,
});

export default connect(mapPropsToState)(CreateThemedSection);
