import React from 'react';
import CreateCampaign from '../containers/engagement/campaign/CreateCampaign';
import CreateCoupon from '../containers/engagement/coupon/CreateCoupon';
import Dashboard from '../containers/dashboard/Dashboard';
import CampaignItemOverview from '../containers/engagement/campaign/CampaignItemOverview';
import CouponSetDetail from '../containers/engagement/coupon/CouponSetDetail';
import SingleCouponDetail from '../containers/engagement/coupon/SingleCouponDetail';
import CreateEarningRule from '../containers/engagement/earningRule/CreateEarningRule';
import CreateSegment from '../containers/customers/segments/CreateSegment';
import SegmentDetail from '../containers/customers/segments/SegmentDetail';
import CustomerOverviewDetail from '../containers/customers/customer/CustomerOverviewDetail';
import CustomerEditForm from '../containers/customers/customer/CustomerEditForm';
import TransactionOverView from '../containers/record/records/TransactionOverview';
import CreateAdminGroup from '../containers/admin/adminGroups/CreateAdminGroup';
import CreateAdmin from '../containers/admin/admins/CreateAdmin';
import CreateBrand from '../containers/merchants/brands/CreateBrand';
import BrandDetail from '../containers/merchants/brands/BrandDetail';
import CreateMessage from '../containers/engagement/message/CreateMessage';
import MessageOverview from '../containers/engagement/message/MessageOverview';
import AdminDetail from '../containers/admin/admins/AdminDetail';
import CampaignCategoryDetail from '../containers/engagement/campaignCategory/CampaignCategoryDetail';
import CreateCampaignCategory from '../containers/engagement/campaignCategory/CreateCampaignCategory';
import StoreDetail from '../containers/merchants/stores/StoreDetail';
import CreateStore from '../containers/merchants/stores/CreateStore';
import StoreCategoryDetail from '../containers/merchants/storeCategory/StoreCategoryDetail';
import CreateStoreCategory from '../containers/merchants/storeCategory/CreateStoreCategory';
import CreateCustomerGroup from '../containers/customers/customerGroup/CreateCustomerGroup';
import CustomerGroupDetail from '../containers/customers/customerGroup/CustomerGroupDetail';
import { PermissionCodes } from './PermissionCodes';
import CreateBanner from '../containers/homeManage/banners/CreateBanner';
import BannerDetail from '../containers/homeManage/banners/BannerDetail';
import FeaturedCampaignDetail from '../containers/homeManage/featuredCampaigns/FeaturedCampaignDetail';
import CreateLangauge from '../containers/engagement/language/CreateLanguage';
import LanguageDetail from '../containers/engagement/language/LanguageDetail';
import CampaignDetail from '../containers/engagement/campaign/CampaignDetail';
import CreateStoreCategoryLv3 from '../containers/merchants/storeCategoryLv3/CreateStoreCategoryLv3';
import StoreCategoryLv3Detail from '../containers/merchants/storeCategoryLv3/StoreCategoryLv3Detail';
import StoreCategoryLv4Detail from '../containers/merchants/storeCategoryLv4/StoreCategoryLv4Detail';
import CreateStoreCategoryLv4 from '../containers/merchants/storeCategoryLv4/CreateStoreCategoryLv4';
import CampaignTagDetail from '../containers/engagement/campaignTag/CampaignTagDetail';
import CreateCampaignTag from '../containers/engagement/campaignTag/CreateCampaignTag';
import CreateEStampCampaign from '../containers/engagement/campaign/CreateEStampCampaign';
import EStampCampaignDetail from '../containers/engagement/campaign/EStampCampaignDetail';
import CreateEMissionCampaign from '../containers/engagement/campaign/CreateEMissionCampaign';
import EMissionCampaignDetail from '../containers/engagement/campaign/EMissionCampaignDetail';
import CreateTransactionRecord from '../containers/conciergeApp/transactionRecord/CreateTransactionRecord';
import TransactionRecordOverview from '../containers/conciergeApp/transactionRecord/TransactionRecordOverview';
import CarParkDetail from '../containers/merchants/carparks/CarParkDetail';
import CreateCarpark from '../containers/merchants/carparks/CreateCarPark';
import EvChargerVendorDetail from '../containers/merchants/vendors/EvChargerVendorDetail';
import MallDetail from '../containers/merchants/mall/MallDetail';
import CreateMall from '../containers/merchants/mall/CreateMall';
import FreshMarketDetail from '../containers/merchants/freshMarkets/FreshMarketDetail';
import CouponTransactionRecordOverview from '../containers/conciergeApp/couponRecord/CouponTransactionRecordOverview';
import StampRecordOverview from '../containers/record/stampRecords/StampRecordOverview';
import BadgeRecordOverview from '../containers/record/badgeRecords/BadgeRecordOverview';
import CreateWebviewContent from '../containers/others/webviewContent/CreateWebviewContent';
import { CampaignDisplayIn, CampaignType, EarningRuleRewardType, EarningRuleType } from './CustomEnums';
import CreateAppVersion from '../containers/others/AppVersion/CreateAppVersion';
import CreateThemedSection from '../containers/homeManage/themedSection/CreateThemedSection';
import CreateDistrictMallMapping from '../containers/others/districtMallMapping/CreateDistrictMallMapping';
import DistrictMallMappingDetail from '../containers/others/districtMallMapping/DistrictMallMappingDetail';

export const pageRoutes = [
  {
    path: '/',
    name: 'Home',
    exact: true,
    conciergemode: true,
    main: () => <Dashboard />,
  },
  {
    path: ['/campaigns/create', '/campaigns/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeCampaign,
    main: () => <CreateCampaign />,
  },
  {
    path: ['/campaigns/create?step=coupon_couponcampaign'],
    exact: true,
    codename: PermissionCodes.changeCampaign,
    main: () => <CreateCampaign createType={{
      displayIn: CampaignDisplayIn.eCoupon,
      campaignType: CampaignType.couponsCampaign,
    }}/>,
  },
  {
    path: ['/campaigns/create?step=stamp_couponcampaign'],
    exact: true,
    codename: PermissionCodes.changeCampaign,
    main: () => <CreateCampaign createType={{
      displayIn: CampaignDisplayIn.stamp,
      campaignType: CampaignType.couponsCampaign,
    }}/>,
  },
  {
    path: ['/estamp_campaigns/create?step=stamp_stampcampaign'],
    exact: true,
    codename: PermissionCodes.changeCampaign,
    main: () => <CreateEStampCampaign createType={{
      displayIn: CampaignDisplayIn.estamp,
      campaignType: CampaignType.estampCampaign,
    }}/>,
  },
  {
    path: ['/campaigns/create?step=mission_earncampaign'],
    exact: true,
    codename: PermissionCodes.changeCampaign,
    main: () => <CreateCampaign createType={{
      displayIn: CampaignDisplayIn.mission,
      campaignType: CampaignType.earningRulesCampaign,
    }}/>,
  },
  {
    path: ['/emission_campaigns/create?step=mission_missioncampaign'],
    exact: true,
    codename: PermissionCodes.changeCampaign,
    main: () => <CreateEMissionCampaign createType={{
      displayIn: CampaignDisplayIn.emission,
      campaignType: CampaignType.emissionCampaign,
    }}/>,
  },
  {
    path: ['/campaigns/create?step=notlisting_campaign'],
    exact: true,
    codename: PermissionCodes.changeCampaign,
    main: () => <CreateCampaign createType={{
      displayIn: CampaignDisplayIn.notListing,
    }}/>,
  },
  {
    path: ['/campaigns/create?step=interestclub_campaign'],
    exact: true,
    codename: PermissionCodes.changeCampaign,
    main: () => <CreateCampaign createType={{
      displayIn: CampaignDisplayIn.interestClub,
    }}/>,
  },
  {
    path: ['/campaigns/:id/overview'],
    exact: true,
    name: 'Overview',
    main: () => <CampaignItemOverview />,
  },
  {
    path: ['/campaigns/:id'],
    exact: true,
    codename: PermissionCodes.viewCampaign,
    main: () => <CampaignDetail />,
  },
  {
    path: ['/estamp_campaigns/create', '/estamp_campaigns/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeCampaign,
    main: () => <CreateEStampCampaign />,
  },
  {
    path: ['/estamp_campaigns/:id'],
    exact: true,
    codename: PermissionCodes.viewCampaign,
    main: () => <EStampCampaignDetail />,
  },
  {
    path: ['/emission_campaigns/create', '/emission_campaigns/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeCampaign,
    main: () => <CreateEMissionCampaign />,
  },
  {
    path: ['/emission_campaigns/:id'],
    exact: true,
    codename: PermissionCodes.viewCampaign,
    main: () => <EMissionCampaignDetail />,
  },
  {
    path: ['/coupons/create', '/coupons/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeCouponTemplate,
    main: () => <CreateCoupon />,
  },
  {
    path: ['/coupons/:id/'],
    exact: true,
    codename: PermissionCodes.viewCouponTemplate,
    main: () => <CouponSetDetail />,
  },
  {
    path: [
      '/coupons/couponSet/:couponSetId/singleCoupon/:singleCouponId',
      '/coupons/:id/:singleCouponId/edit',
    ],
    exact: true,
    codename: PermissionCodes.viewCoupon,
    main: () => <SingleCouponDetail />,
  },
  {
    name: 'Create Earning Rule',
    path: '/earns/create',
    exact: true,
    codename: PermissionCodes.addEarningRule,
    main: () => <CreateEarningRule />,
  },
  {
    name: 'Create Earning Rule',
    path: ['/earns/create?step=stamp_earnrule'],
    exact: true,
    codename: PermissionCodes.addEarningRule,
    main: () => <CreateEarningRule createType={{
      type: EarningRuleType.generalPurchase,
      rewardType: EarningRuleRewardType.stamp
    }}/>,
  },
  {
    name: 'Edit Earning Rule',
    path: ['/earns/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeEarningRule,
    main: () => <CreateEarningRule />,
  },
  {
    name: 'Create Segment',
    path: ['/segments/create/'],
    exact: true,
    codename: PermissionCodes.addSegment,
    main: () => <CreateSegment />,
  },
  {
    path: ['/segments/:id/'],
    exact: true,
    codename: PermissionCodes.viewSegment,
    main: () => <SegmentDetail />,
  },
  {
    path: ['/segments/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeSegment,
    main: () => <CreateSegment />,
  },

  {
    path: ['/customers/:id/'],
    exact: true,
    codename: PermissionCodes.viewCustomer,
    main: () => <CustomerOverviewDetail />,
  },
  {
    path: ['/customers/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeCustomer,
    main: () => <CustomerEditForm />,
  },
  {
    name: 'Create Transactions and Distribute Rewards',
    path: ['/transaction_records/create'],
    exact: true,
    codename: PermissionCodes.addTransaction,
    main: () => <CreateTransactionRecord />,
  },
  {
    name: 'View transaction',
    path: ['/transaction_records/:id/'],
    exact: true,
    codename: PermissionCodes.viewTransaction,
    main: () => <TransactionRecordOverview />,
  },
  {
    name: 'View Coupon records',
    path: ['/coupon_records/:id/'],
    exact: true,
    codename: PermissionCodes.viewCouponRecord,
    main: () => <CouponTransactionRecordOverview />,
  },
  {
    name: 'View Stamp Record',
    path: ['/stamp_records/:id/'],
    exact: true,
    codename: PermissionCodes.viewStampRecord,
    main: () => <StampRecordOverview />,
  },
  {
    name: 'View Badge Record',
    path: ['/badge_records/:id/'],
    exact: true,
    codename: PermissionCodes.viewBadgeRecord,
    main: () => <BadgeRecordOverview />,
  },
  {
    name: 'Create Administrators group',
    path: ['/administrator_groups/create'],
    exact: true,
    codename: PermissionCodes.addAdministratorGroup,
    main: () => <CreateAdminGroup />,
  },
  {
    path: ['/administrator_groups/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeAdministratorGroup,
    main: () => <CreateAdminGroup />,
  },
  {
    name: 'Create Administrators',
    path: ['/administrators/create'],
    exact: true,
    codename: PermissionCodes.addAdministrator,
    main: () => <CreateAdmin />,
  },
  {
    path: ['/administrators/:id/'],
    exact: true,
    codename: PermissionCodes.viewAdministrator,
    main: () => <AdminDetail />,
  },

  {
    path: ['/administrators/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeAdministrator,
    main: () => <CreateAdmin />,
  },
  {
    name: 'Create Brand',
    path: ['/brands/create/'],
    exact: true,
    codename: PermissionCodes.changeBrand,
    main: () => <CreateBrand />,
  },
  {
    path: ['/brands/:id/'],
    exact: true,
    codename: PermissionCodes.brand,
    main: () => <BrandDetail />,
  },
  {
    path: ['/brands/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeBrand,
    main: () => <CreateBrand />,
  },
  {
    name: 'Create Message',
    path: ['/messages/create'],
    exact: true,
    codename: PermissionCodes.addMessage,
    main: () => <CreateMessage />,
  },
  {
    path: ['/messages/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeMessage,
    main: () => <CreateMessage />,
  },
  {
    name: 'View Message',
    path: ['/messages/:id/'],
    exact: true,
    codename: PermissionCodes.viewMessage,
    main: () => <MessageOverview />,
  },
  {
    name: 'Create campaign category',
    path: ['/campaign_category/create'],
    exact: true,
    codename: PermissionCodes.addCampaignCategory,
    main: () => <CreateCampaignCategory />,
  },
  {
    path: ['/campaign_category/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeCampaignCategory,
    main: () => <CreateCampaignCategory />,
  },
  {
    path: ['/campaign_category/:id/'],
    exact: true,
    codename: PermissionCodes.viewCampaignCategory,
    main: () => <CampaignCategoryDetail />,
  },
  {
    name: 'Create campaign category',
    path: ['/campaign_tag/create'],
    exact: true,
    codename: PermissionCodes.addCampaignCategory,
    main: () => <CreateCampaignTag />,
  },
  {
    path: ['/campaign_tag/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeCampaignCategory,
    main: () => <CreateCampaignTag />,
  },
  {
    path: ['/campaign_tag/:id/'],
    exact: true,
    codename: PermissionCodes.viewCampaignCategory,
    main: () => <CampaignTagDetail />,
  },

  {
    path: ['/ev_charger_vendors/:id/'],
    exact: true,
    codename: PermissionCodes.viewCarPark,
    main: () => <EvChargerVendorDetail />,
  },
  {
    path: ['/carparks/:id/'],
    exact: true,
    codename: PermissionCodes.viewCarPark,
    main: () => <CarParkDetail />,
  },
  {
    path: ['/carparks/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeCarPark,
    main: () => <CreateCarpark />,
  },
  {
    path: ['/malls/:id/'],
    exact: true,
    codename: PermissionCodes.viewMall,
    main: () => <MallDetail />,
  },
  {
    path: ['/malls/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeMall,
    main: () => <CreateMall />,
  },
  {
    path: ['/fresh_markets/:id/'],
    exact: true,
    codename: PermissionCodes.viewFreshMarket,
    main: () => <FreshMarketDetail />,
  },
  // {
  //   name: 'Create store',
  //   path: ['/stores/create'],
  //   exact: true,
  //   codename: PermissionCodes.addStore,
  //   main: () => <CreateStore />,
  // },
  {
    path: ['/stores/:id/'],
    exact: true,
    codename: PermissionCodes.viewStore,
    main: () => <StoreDetail />,
  },
  // {
  //   path: ['/stores/:id/edit/'],
  //   exact: true,
  //   codename: PermissionCodes.changeStore,
  //   main: () => <CreateStore />,
  // },
  // {
  //   name: 'Create store category',
  //   path: ['/store_category_lv1_lv2/create'],
  //   exact: true,
  //   codename: PermissionCodes.addStorecategory,
  //   main: () => <CreateStoreCategory />,
  // },
  {
    path: ['/store_category_lv1_lv2/:id/'],
    exact: true,
    codename: PermissionCodes.viewSorecategory,
    main: () => <StoreCategoryDetail />,
  },
  // {
  //   path: ['/store_category_lv1_lv2/:id/edit/'],
  //   exact: true,
  //   codename: PermissionCodes.changeStorecategory,
  //   main: () => <CreateStoreCategory />,
  // },
  // {
  //   name: 'Create store category',
  //   path: ['/store_category_lv3/create'],
  //   exact: true,
  //   codename: PermissionCodes.addStorecategoryLv3,
  //   main: () => <CreateStoreCategoryLv3 />,
  // },
  {
    path: ['/store_category_lv3/:id/'],
    exact: true,
    codename: PermissionCodes.viewStorecategoryLv3,
    main: () => <StoreCategoryLv3Detail />,
  },
  // {
  //   path: ['/store_category_lv3/:id/edit/'],
  //   exact: true,
  //   codename: PermissionCodes.changeStorecategoryLv3,
  //   main: () => <CreateStoreCategoryLv3 />,
  // },
  // {
  //   name: 'Create store category',
  //   path: ['/store_category_lv4/create'],
  //   exact: true,
  //   codename: PermissionCodes.addStorecategoryLv4,
  //   main: () => <CreateStoreCategoryLv4 />,
  // },
  {
    path: ['/store_category_lv4/:id/'],
    exact: true,
    codename: PermissionCodes.viewStorecategoryLv4,
    main: () => <StoreCategoryLv4Detail />,
  },
  // {
  //   path: ['/store_category_lv4/:id/edit/'],
  //   exact: true,
  //   codename: PermissionCodes.changeStorecategoryLv4,
  //   main: () => <CreateStoreCategoryLv4 />,
  // },
  {
    name: 'Create Customer Group',
    path: ['/customer_group/create'],
    exact: true,
    codename: PermissionCodes.addCustomerGroup,
    main: () => <CreateCustomerGroup />,
  },
  {
    path: ['/customer_group/:id/'],
    exact: true,
    codename: PermissionCodes.customergroup,
    main: () => <CustomerGroupDetail />,
  },
  {
    path: ['/customer_group/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeCustomerGroup,
    main: () => <CreateCustomerGroup />,
  },
  {
    name: "Create Banner",
    path: ['/banners/create/'],
    exact: true,
    codename: PermissionCodes.changeBanner,
    main: () => <CreateBanner />,
  },
  {
    path: ['/banners/:id/'],
    exact: true,
    codename: PermissionCodes.viewBanner,
    main: () => <BannerDetail />,
  },
  {
    name: "Edit Banner",
    path: ['/banners/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeBanner,
    main: () => <CreateBanner />,
  },
  {
    path: ['/district_mall_mappings/:id/'],
    exact: true,
    codename: PermissionCodes.viewDistrictMallMapping,
    main: () => <DistrictMallMappingDetail />,

  },
  {
    name: "Edit Mapping",
    path: ['/district_mall_mappings/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeDistrictMallMapping,
    main: () => <CreateDistrictMallMapping />,
  },
  {
    path: ['/home_campaigns/:id/edit'],
    exact: true,
    codename: PermissionCodes.changeCampaign,
    main: () => <FeaturedCampaignDetail />,
  },
  {
    name: "Edit Hub",
    path: ['/themed_section/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeThemedSection,
    main: () => <CreateThemedSection />,
  },
  {
    path: ['/languages/create', '/languages/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeLanguage,
    main: () => <CreateLangauge />,
  },
  {
    path: ['/languages/:id/'],
    exact: true,
    codename: PermissionCodes.viewLanguage,
    main: () => <LanguageDetail />,
  },
  {
    path: ['/concierge_customers/:id/'],
    exact: true,
    conciergemode: true,
    codename: PermissionCodes.viewCustomer,
    main: () => <CustomerOverviewDetail />,
  },
  {
    path: ['/concierge_customers/:id/edit/'],
    exact: true,
    conciergemode: true,
    codename: PermissionCodes.changeCustomer,
    main: () => <CustomerEditForm />,
  },
  {
    name: '創建交易 及 發放獎賞',
    path: ['/concierge_transaction_records/create'],
    exact: true,
    conciergemode: true,
    codename: PermissionCodes.addTransactionRecord,
    main: () => <CreateTransactionRecord />,
  },
  // {
  //   name: 'Edit Transaction Record',
  //   path: ['/transaction_records/:id/edit'],
  //   exact: true,
  //   codename: PermissionCodes.changeTransactionRecord,
  //   main: () => <CreateTransactionRecord />,
  // },
  {
    name: '交易紀錄詳細',
    path: ['/concierge_transaction_records/:id/'],
    exact: true,
    conciergemode: true,
    codename: PermissionCodes.viewConciergetransaction,
    main: () => <TransactionRecordOverview />,
  },
  {
    name: '印花紀錄詳細',
    path: ['/concierge_stamp_records/:id/'],
    exact: true,
    conciergemode: true,
    codename: PermissionCodes.viewStampRecord,
    main: () => <StampRecordOverview />,
  },
  {
    name: '獎章紀錄詳細',
    path: ['/concierge_badge_records/:id/'],
    exact: true,
    conciergemode: true,
    codename: PermissionCodes.viewBadgeRecord,
    main: () => <BadgeRecordOverview />,
  },
  {
    name: '優惠券紀錄詳細',
    path: ['/concierge_coupon_records/:id/'],
    exact: true,
    conciergemode: true,
    codename: PermissionCodes.viewConciergecoupontransaction,
    main: () => <CouponTransactionRecordOverview />,
  },
  {
    path: ['/webview/create', '/webview/:id/edit/'],
    exact: true,
    codename: PermissionCodes.changeWebContent,
    main: () => <CreateWebviewContent />,
  },
  {
    path: ['/app_version/create'],
    exact: true,
    codename: PermissionCodes.changeAppVersion,
    main: () => <CreateAppVersion />,
  },
];
