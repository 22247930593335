import React from 'react';
import './CreateCarparkSection.scss';
import { useFormContext } from 'react-hook-form';
import SpecificCustomSwitchButton from '../../containers/merchants/stores/SpecificCustomSwitchButton';
import CustomRadios from '../base/CustomRadios';
import { Collapse, Row } from 'react-bootstrap';
import CheckboxItem from '../coupon/couponList/CheckboxItem';

export default function CarparkVendorSection() {

  const { watch, setValue } = useFormContext();
  const watchFormatedVendors = watch("formatedVendors");
  
  const radioOptions = [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ]

  const chargerVendor = (item, index) => {
    return (
      <>
        <input type="hidden" name="action" value={item.vendorVacancyId} />
        <label className='vendor-info'>{item.name}</label>
        <div className='vendor-item-container'>
        <label className='vendor-info vendor-header'>{'Show this EV vendor on App?'}</label>
        <SpecificCustomSwitchButton
          checked={item.showVendorOnApp}
          onChange={(value) => {
            watchFormatedVendors[index] = {...watchFormatedVendors[index], showVendorOnApp: value}
            setValue("formatedVendors", watchFormatedVendors, {shouldDirty:true})
          }}
        />
        <Collapse in={item.showVendorOnApp}>
          <div>
            <label className='vendor-info'>{'Show charger availability of this vendor?'}</label>
            <Row className="featured-exclusive-row">
            <CustomRadios
              onChange={(value) => {
                watchFormatedVendors[index] = {...watchFormatedVendors[index], showVendorVacancyOnApp: value}
                setValue("formatedVendors", watchFormatedVendors, {shouldDirty:true})
              }}
              default={item.showVendorVacancyOnApp}
              options={radioOptions}
            />
            </Row>
            <label className='vendor-info'>{'DC (Quick Charge)'}</label>
            <Row className="featured-exclusive-row">
              {item?.dcChargers?.map((item1, index1) => {
                return (
                <CheckboxItem
                  title={item1.label}
                  checked={item1.selected}
                  onChange={() => {
                    item.dcChargers[index1] = {...item.dcChargers[index1], selected: !item.dcChargers[index1].selected}
                    watchFormatedVendors[index] = {...watchFormatedVendors[index], dcChargers: item.dcChargers}
                    setValue("formatedVendors", watchFormatedVendors, {shouldDirty:true})  
                  }}
                />
                );
              })}
            </Row>
            <label className='vendor-info'>{'AC (Medium Charge)'}</label>
            <Row className="featured-exclusive-row">
              {item?.acChargers?.map((item1, index1) => {
                return (
                <CheckboxItem
                  title={item1.label}
                  checked={item1.selected}
                  onChange={() => {
                    item.acChargers[index1] = {...item.acChargers[index1], selected: !item.acChargers[index1].selected}
                    watchFormatedVendors[index] = {...watchFormatedVendors[index], acChargers: item.acChargers}
                    setValue("formatedVendors", watchFormatedVendors, {shouldDirty:true})  
                  }}
                />
                );
              })}
            </Row>
          </div>
        </Collapse>
        </div>
      </>
    )
  }

  return (
    <>
    <div className="campaign-category-detail">
      <label className="create-section-title">{'Charger Vendor'}</label>
      {watchFormatedVendors?.map((item, index) => chargerVendor(item, index))}
    </div>
    </>
  );
}
