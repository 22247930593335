import React, { useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { ArrayStringData } from '../../../utils';
import locale from 'antd/lib/date-picker/locale/zh_TW';
import { getTransactionLanguageAndIsTCTransaction } from './TransactionRecordList';
import i18n from '../../../I18n';

function FormTypeFilter({ backAction = () => {} }) {
  const location = useLocation();
  const { language, isTCTransaction } =
    getTransactionLanguageAndIsTCTransaction(location);

  const parsedSearch = queryString.parse(location.search);
  const searchMall = parsedSearch['transaction_type'] || '';
  const searchTransactionDate = parsedSearch['transaction_date'] || '';

  const [transactionType, setTransactionType] = useState(
    ArrayStringData(searchMall),
  );
  const [transactionDate, setTransactionDate] = useState(searchTransactionDate);

  const filterText = i18n.t('transaction.filter', { locale: language });
  const resetAllText = i18n.t('transaction.reset_all', { locale: language });
  const transactionTypeDataList = [
    {
      name: i18n.t('transaction.transaction', { locale: language }),
      pk: 'TRADE_FORM',
    },
    {
      name: i18n.t('transaction.non_transaction', { locale: language }),
      pk: 'NOT_TRADE_FORM',
    },
  ];

  const content = [
    {
      title: 'Transaction type',
      displayName: i18n.t('transaction.transaction_type', { locale: language }),
      data: transactionTypeDataList,
      value: transactionType,
      setValue: setTransactionType,
      componment: FilterTypeEnum.choice,
    },
    {
      title: 'Transaction date',
      displayName: i18n.t('transaction.transaction_date', { locale: language }),
      value: transactionDate,
      setValue: setTransactionDate,
      componment: FilterTypeEnum.dateRange,
      locale: isTCTransaction ? locale : '',
    },
  ];

  return (
    <BaseFilter
      content={content}
      backAction={backAction}
      filterText={filterText}
      resetAllText={resetAllText}
    />
  );
}

export default FormTypeFilter;
