import React from 'react';
import { useFormContext } from 'react-hook-form';
import CustomTitleWithInput from '../base/CustomTitleWithInput';
import {
  LanguageConfig,
} from '../../config/CustomEnums';
import i18n from '../../I18n';
import { hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import TargetStoreCategorySection from './TargetStoreCategorySection';

const CreateStoreCategoryLv3Content = ({ language, targetSectionConfig }) => {
  const { watch, setValue, control, formState, clearErrors } = useFormContext();
  const { errors } = formState;

  const nameField = `translations.${language}.name`;
  const orderField = `order`;
  const parentCategoriesField = 'parentCategories';
  const watchName = watch(nameField);
  const watchOrder = watch(orderField);
  const selectedParentCategoriesList = watch(parentCategoriesField);

  console.log(
    '@@49: ',
    language,
    watchName,
    watchOrder,
    selectedParentCategoriesList,
  );
  const isEnglish = language === LanguageConfig.english;
  return [
    <>
      <div className="create-section-title">
        {i18n.t('content', { locale: language })}
      </div>
      <CustomTitleWithInput
        title={i18n.t('name', { locale: language })}
        type={'text'}
        setValue={(value) => {
          setValue(nameField, value, { shouldDirty: true, });
        }}
        extra={{
          maxLength: 50,
        }}
        defaultValue={watchName}
        customClass={`custom-markdown-area-title `}
        error={{ error: hasError(errors, nameField) }}
      />
      <ReactHookFormErrorMessage errors={errors} id={nameField} />

      {isEnglish ? (
        <>
          <TargetStoreCategorySection
            title={targetSectionConfig.title}
            selectedCategoryList={targetSectionConfig.selectedCategoryList}
            targetData={selectedParentCategoriesList}
            onChange={(options) => {
              setValue(parentCategoriesField, options, { shouldDirty: true, });
            }}
          />
          <ReactHookFormErrorMessage errors={errors} id={parentCategoriesField} />
          <CustomTitleWithInput
            title={'Display order'}
            type={'number'}
            setValue={(value) => {
              setValue(orderField, value, { shouldDirty: true, });
            }}
            defaultValue={watchOrder}
            customClass={`coupon-campaign-section-input`}
            error={{ error: hasError(errors, orderField) }}
          />
          <ReactHookFormErrorMessage errors={errors} id={orderField} />
          <label className="coupon-campaign-section-caution-description">
            Minimum value is 1. The smaller value, the higher priority.
          </label>
        </>
      ) : null}
    </>
  ];
};

export default CreateStoreCategoryLv3Content;
