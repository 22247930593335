import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ContentSections from '../../../components/base/ContentSections';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import { createAction, capitalizeFirstLetter } from '../../../utils';
import {
  TRANSACTION_RECORD_TYPE,
  TRANSACTION_EVENT_TYPE,
} from './TransactionList';
import {
  SaveAndBackAndWithoutBorderButtons,
  BackAndWithoutBorderButton,
  OnlyBackButton,
  SaveAndBackButtons,
  SaveAndBackWithOutTempButtons,
} from '../../../components/base/BottomStepComponent';
import { transactionQueryKey } from '../../../components/pointRecord/RecordsUrlConfig';
import TransactionActionPrompt from '../../../components/transactions/TransactionActionPrompt';
import RedirectToShopifyPrompt from '../../../components/transactions/RedirectToShopifyPrompt';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';


function TransactionOverView({
  selectedTransaction
}) {
  const queryString = require('query-string');
  const dispatch = useDispatch();
  const history = useHistory();
  const transactionPK = useParams().id;

  const [showActionPrompt, setShowActionPrompt] = useState(false);
  const [promptActionTitle, setPromptActionTitle] = useState();
  const [showRedirectPrompt, setShowRedirectPrompt] = useState(false);

  useEffect(() => {
    dispatch(createAction('transactions/getTransaction')({ transactionPK }));
  }, [dispatch, transactionPK]);

  const getFieldsWithTransactionType = () => {
    const transactionType = selectedTransaction.transactionDisplayType;
    let eventType = [];
    let extraData = [];
    switch (transactionType) {
      case TRANSACTION_RECORD_TYPE.TYPE_ONLINE:
        eventType = [{ title: 'Online event type', field: 'onlineEventType' }];
        extraData = [
          [{ title: 'Shipping fee', field: 'shippingFee' }],
          [{ title: 'Other charges', field: 'otherCharge' }],
          [{ title: 'Total value', field: 'displayTotalValue' }],
        ];
        break;
      case TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM:
        eventType = [
          { title: 'Offline event type', field: 'displayOfflineEventType' },
        ];
        extraData = [
          [{ title: 'Total Value', field: 'displayTotalValue' }],
          [{ title: 'Receipt image', field: 'receiptImage' }],
          [{ title: 'Credit card slip image', field: 'creditCardSlipImage' }],
        ];
        break;
      case TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS:
        eventType = [
          { title: 'Offline event type', field: 'displayOfflineEventType' },
        ];
        break;
      default:
        break;
    }
    const data = [
      [{ title: 'Customer', field: 'name' }],
      [{ title: 'Creation date', field: 'transactionDetailCreationDate' }],
      [{ title: 'Transaction date', field: 'transactionDetailDisplayDate' }],
      [{ title: 'Store', field: 'storeName' }],
      [
        {
          title: 'Invoice id',
          field:
            selectedTransaction.transactionDisplayType ===
            TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS
              ? 'posInvoiceId'
              : 'invoiceId',
        },
      ],
      eventType,
      [{ title: 'Staff name', field: 'staffName' }],
      [{ title: 'Remarks (optional)', field: 'remarks' }],
      ...extraData,
    ];
    return data;
  };

  const getContentSections = () => {
    let sections = [
      <MembershipInfoCard
        title="TRANSACTION DETAILS"
        data={selectedTransaction}
        fields={getFieldsWithTransactionType(
          selectedTransaction.transactionDisplayType,
        )}
      />,
    ];
    let purchaseItemSectionList = [];
    if (
      selectedTransaction.transactionDisplayType !==
      TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM
    ) {
      purchaseItemSectionList = selectedTransaction.purchasedItems?.map(
        (item, index) => {
          return (
            <MembershipInfoCard
              title={`PURCHASED ITEM #${index + 1}`}
              data={item}
              fields={[
                [{ title: 'Product name', field: 'productName' }],
                [{ title: 'Sku (optional)', field: 'sku' }],
                [{ title: 'Category (optional)', field: 'category' }],
                [{ title: 'Brand (optional)', field: 'brand' }],
                [{ title: 'Quantity', field: 'quantity' }],
                [{ title: 'Value', field: 'displayValue' }],
              ]}
            />
          );
        },
      );
    }
    sections = sections.concat(purchaseItemSectionList);
    return sections;
  };

  const tabs = [
    {
      name: '',
      content: (
        <div className="scroll-container-common">
          <ContentSections
            sections={getContentSections()}
            hidePreview={true}
            // sectionStyle="transaction-record-detail-section"
          />
        </div>
      ),
    },
  ];

  const buttons = () => {
    if (
      selectedTransaction.transactionDisplayType ===
      TRANSACTION_RECORD_TYPE.TYPE_ONLINE
    ) {
      return [
        <AuthButton
          title="Edit"
          action={() => {
            setShowRedirectPrompt(true);
          }}
          requires={PermissionCodes.changeTransaction}
        />,
      ];
    }
    if (
      selectedTransaction.transactionDisplayType ===
      TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_POS
    ) {
      return [
        <AuthButton
          title="Edit"
          action={() => {
            history.push(`/transactions/${selectedTransaction.pk}/edit`);
          }}
          requires={PermissionCodes.changeTransaction}
        />,
      ];
    }
  };

  const goBackToList = () => {
    return history.push({
      pathname: '/transactions',
      search: queryString.stringify({
        [transactionQueryKey.searchKey]: '',
        [transactionQueryKey.page]: 1,
        [transactionQueryKey.rank]: true,
        [transactionQueryKey.tab]: 'all',
      }),
    });
  };

  const backAction = () => {
    dispatch(createAction('transactions/clearSelectedTransaction')());
    goBackToList();
  };

  const getBottomSection = () => {
    if (
      selectedTransaction.transactionDisplayType ===
      TRANSACTION_RECORD_TYPE.TYPE_OFFLINE_REWARD_CLAIM
    ) {
      if (
        selectedTransaction.displayOfflineEventType ===
        TRANSACTION_EVENT_TYPE.TYPE_WAITING
      ) {
        return (
          <SaveAndBackButtons
            backAction={backAction}
            saveTempText={'Reject'}
            saveTempAction={() => {
              setPromptActionTitle('reject');
              setShowActionPrompt(true);
            }}
            saveText={'Approve'}
            saveAction={() => {
              setPromptActionTitle('approve');
              setShowActionPrompt(true);
            }}
            tempRequires={PermissionCodes.changeTransaction}
            saveRequires={PermissionCodes.changeTransaction}
          />
        );
      }
      if (
        selectedTransaction.displayOfflineEventType ===
        TRANSACTION_EVENT_TYPE.TYPE_APPROVED
      ) {
        return (
          <SaveAndBackWithOutTempButtons
            backAction={backAction}
            saveText={'Recall'}
            actionText={'Recall'}
            saveAction={() => {
              setPromptActionTitle('recall');
              setShowActionPrompt(true);
            }}
            saveRequires={PermissionCodes.changeTransaction}
          />
        );
      }
    }

    return (
      <OnlyBackButton
        backAction={() => {
          goBackToList();
        }}
      />
    );
  };

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '', //view transaction
          title: 'View transaction',
        }}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
        buttons={buttons()}
      />
      {getBottomSection()}
      <TransactionActionPrompt
        event={promptActionTitle}
        item={selectedTransaction}
        show={showActionPrompt}
        onHide={() => setShowActionPrompt(false)}
        action={() => {
          dispatch(
            createAction(`transactions/${promptActionTitle}Transaction`)({
              transactionPK: selectedTransaction.pk,
              afterActions: backAction(),
            }),
          );
        }}
      />
      <RedirectToShopifyPrompt
        show={showRedirectPrompt}
        onHide={() => setShowRedirectPrompt(false)}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  selectedTransaction: state.transactions.selectedTransaction,
});

export default connect(mapPropsToState)(TransactionOverView);
