import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import ContentSections from '../base/ContentSections';
import { validate } from '../../containers/conciergeApp/transactionRecord/CreateTransactionValidate';
import { ContinueWithBackButtons } from '../base/BottomStepComponent';
import MembershipInfoCard from '../customer/MembershipInfoCard';
import './CreateTransactionRecordStepFour.scss';
import {
  CAMPAIGN_REWARD_TYPE,
  COUPON_RECEIVER,
  FORM_TYPE,
  TRANSACTION_ERROR_KEYS,
} from '../../config/CustomEnums';
import { getCampaignTypeConfig } from './TransactionCampaignSection';
import i18n from '../../I18n';
import { getInFilterValue } from '../../utils';
import moment from 'moment';
import { getReceiptTitle } from './PurchaseReceiptSection';

export const getExtraInfo = (rewards = [], rewardType, language) => {
  let extraInfo = '';
  rewards.forEach((reward) => {
    if (rewardType === 'stamp') {
      extraInfo += `\n${reward.receiptTitle} (+${reward.rewards || 0} ${i18n.t(
        reward.withBonus ? 'transaction.stamp_with_bonus' : 'transaction.stamp',
        { locale: language },
      )})`;
    } else if (rewardType === 'badge') {
      extraInfo += `\n${reward.receiptTitle} (+${reward.rewards || 0} ${i18n.t(
        'transaction.badge',
        { locale: language },
      )})`;
    }
  });
  return extraInfo;
};

function CreateTransactionRecordStepFour({ language, isTCTransaction }) {
  const dispatch = useDispatch();

  const { getValues, clearErrors, setError } = useFormContext();

  const { simulateTransactionReward } = useSelector((state) => ({
    simulateTransactionReward:
      state.createTransactionRecord.simulateTransactionReward,
  }));

  const [disabledContinue, setDisabledContinue] = useState(true);

  const customerIDField = 'customerID';
  const campaignTypeField = 'campaignType';
  const stampCampaignField = 'stampCampaign';
  const missionCampaignField = 'missionCampaign';
  const otherCampaignField = 'otherCampaign';
  const carParkCampaignField = 'carParkCampaign';
  const [
    customerID,
    campaignType,
    stampCampaign,
    missionCampaign,
    otherCampaign,
    carParkCampaign,
  ] = getValues([
    customerIDField,
    campaignTypeField,
    stampCampaignField,
    missionCampaignField,
    otherCampaignField,
    carParkCampaignField,
  ]);

  const {
    missionCampaignRewardBadgeData,
    displayMissionCampaignReward,
    missionCampaignRewardError,
    stampCampaignRewardData,
    displayStampCampaignReward,
    stampCampaignRewardError,
    displayOtherCampaignReward,
    otherCampaignRewardError,
    displayCarParkCampaignReward,
    carParkCampaignRewardError,
  } = simulateTransactionReward || {};

  const getReceiptsExtraInfo = (rewards = [], rewardType, language) => {
    const receiptsExtraInfo = rewards.map((reward) => {
      let extraInfo = '';
      if (rewardType === 'stamp') {
        extraInfo += `\n${reward.receiptTitle} (+${
          reward.rewards || 0
        } ${i18n.t(
          reward.withBonus
            ? 'transaction.stamp_with_bonus'
            : 'transaction.stamp',
          { locale: language },
        )})`;
      } else if (rewardType === 'badge') {
        extraInfo += `\n${reward.receiptTitle} (+${
          reward.rewards || 0
        } ${i18n.t('transaction.badge', { locale: language })})`;
      }
      if (reward?.rejectReason) {
        const rejectReason = reward?.rejectReason;
        extraInfo += ` ${rejectReason}`;
      }
      return (
        <span
          className={`${
            reward?.rejectReason ? 'campaign-transaction-error-field' : ''
          }`}
        >
          {extraInfo}
        </span>
      );
    });
    return receiptsExtraInfo;
  };

  const getDisplayCampaign = (
    campaign,
    displayReword,
    campaignError,
    receiptData = {},
  ) => {
    if (!campaign) return '';
    let displayCampaign = `${campaign?.name} ${displayReword}`;
    if (campaignError) {
      displayCampaign += ` ${campaignError}`;
    }

    const { rewards, rewardType } = receiptData;
    const displayReceipts =
      rewards?.length > 0 &&
      getReceiptsExtraInfo(rewards, rewardType, language);
    return (
      <>
        <span
          className={`${
            campaignError ? 'campaign-transaction-error-field' : ''
          }`}
        >
          {displayCampaign}
        </span>
        {displayReceipts ? displayReceipts : null}
      </>
    );
  };

  const needDisabledContinue = () => {
    const hasStampReceiptError = stampCampaignRewardData?.some(
      (item) => !!item?.rejectReason,
    );
    const hasMissionReceiptError = missionCampaignRewardBadgeData?.some(
      (item) => !!item?.rejectReason,
    );
    return (
      hasStampReceiptError ||
      hasMissionReceiptError ||
      stampCampaignRewardError ||
      missionCampaignRewardError ||
      otherCampaignRewardError ||
      carParkCampaignRewardError
    );
  };

  const displayMissionCampaign = getDisplayCampaign(
    missionCampaign,
    displayMissionCampaignReward,
    missionCampaignRewardError,
    { rewards: missionCampaignRewardBadgeData, rewardType: 'badge' },
  );
  const displayStampCampaign = getDisplayCampaign(
    stampCampaign,
    displayStampCampaignReward,
    stampCampaignRewardError,
    { rewards: stampCampaignRewardData, rewardType: 'stamp' },
  );
  const displayOtherCampaign = getDisplayCampaign(
    otherCampaign,
    displayOtherCampaignReward,
    otherCampaignRewardError,
  );
  const displayCarParkCampaign = getDisplayCampaign(
    carParkCampaign,
    displayCarParkCampaignReward,
    carParkCampaignRewardError,
  );

  const data = {
    customerID,
    campaignType: getCampaignTypeConfig(language).filter(
      (type) => type.value === campaignType,
    )[0]?.label,
    stampCampaign: displayStampCampaign,
    missionCampaign: displayMissionCampaign,
    otherCampaign: displayOtherCampaign,
    carParkCampaign: displayCarParkCampaign,
  };
  const fields = [
    [
      {
        title: i18n.t('transaction.memberid/mobile_number', {
          locale: language,
        }),
        field: customerIDField,
      },
    ],
    [
      {
        title: i18n.t('transaction.transaction_reward_type', {
          locale: language,
        }),
        field: campaignTypeField,
      },
    ],
    campaignType === CAMPAIGN_REWARD_TYPE.stamp
      ? [
          {
            title: i18n.t('transaction.stamp_campaign', { locale: language }),
            field: stampCampaignField,
          },
        ]
      : [],
    campaignType === CAMPAIGN_REWARD_TYPE.mission
      ? [
          {
            title: i18n.t('transaction.mission_campaign', { locale: language }),
            field: missionCampaignField,
          },
        ]
      : [],
    campaignType === CAMPAIGN_REWARD_TYPE.other
      ? [
          {
            title: i18n.t('transaction.other_campaign', { locale: language }),
            field: otherCampaignField,
          },
        ]
      : [],
    [
      {
        title: i18n.t('transaction.carpark_campaign', { locale: language }),
        field: carParkCampaignField,
      },
    ],
  ];

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 3,
      isBack: isBack,
    });
    dispatch({
      type: 'createTransactionRecord/stepChange',
      payload: {
        isBack: isBack,
        step: 3,
        isValid,
        isTCTransaction,
      },
    });
    if (!isBack && isValid) {
      dispatch({
        type: 'createTransactionRecord/createTransaction',
        payload: {
          values: getValues(),
          isTCTransaction,
        },
      });
    }
  };

  useEffect(() => {
    dispatch({
      type: 'createTransactionRecord/simulateTransaction',
      payload: {
        values: getValues(),
        language,
      },
    });
  }, []);

  useEffect(() => {
    const simulateValues =
      simulateTransactionReward && Object.values(simulateTransactionReward);
    const hasSimulateData = simulateValues?.some(
      (item) =>
        (!Array.isArray(item) && !!item) || item?.length > 0 || item === 0,
    );
    if (hasSimulateData) {
      setDisabledContinue(needDisabledContinue());
    }
  }, [simulateTransactionReward]);

  return (
    <>
      <ContentSections
        sections={[
          <MembershipInfoCard title={''} data={data} fields={fields} />,
        ]}
        hidePreview={true}
      />
      <ContinueWithBackButtons
        backText={i18n.t('transaction.back', { locale: language })}
        backAction={() => stepChangeAction(true)}
        continueText={i18n.t('transaction.confirm', { locale: language })}
        continueAction={() => {
          stepChangeAction(false);
        }}
        disabledContinue={disabledContinue}
      />
    </>
  );
}

export default CreateTransactionRecordStepFour;
