import React, { useState, useEffect } from 'react';
import './NextStepPrompt.scss';
import { Image, Modal } from 'react-bootstrap';
import PromptButton from './BaseButtons';
import { BUTTON_TYPES } from './BaseButtons';
import ScanSuccess from '../../../assets/images/scan_success.svg';
import ScanFailed from '../../../assets/images/scan_failed.svg';
import CloseIcon from '../../../assets/images/prompt_close_icon.svg';
import './UseCouponPrompt.scss';

function UseCouponPrompt(props) {
  const buttons = props.buttons || [];
  const show = props.show;
  const coupon = props.coupon;
  const isSuccess = props.scanCouponStatus;
  const closeAction = props.closeAction || (() => {});

  const buttonAction = (button) => {
    if (button?.action) {
      button.action();
    }
  };

  const getButton = (button, defaultType, extraClass) => {
    if (!button) {
      return null;
    }
    button.class = `${extraClass}`;
    return (
      <PromptButton
        title={button.text}
        action={() => buttonAction(button)}
        requires={button.requires}
        className={button.class}
        type={button.type || defaultType}
      />
    );
  };

  const successBody = () => {
    return (
      <>
        <Image style={{width: '112px', height: '112px'}} src={ScanSuccess} />
        <div className="use-coupon-prompt-header">{'掃描成功'}</div>
        {coupon ? (
          <div className="use-coupon-container">
            <label className="use-coupon-prompt-description">{'優惠券名字:'}</label>
            <label className="use-coupon-prompt-description">{coupon?.couponName}</label>
            <label className="use-coupon-prompt-description">{'優惠券編號:'}</label>
            <label className="use-coupon-prompt-description">{coupon?.couponID}</label>
          </div>
        ) : null}
        <div className="use-coupon-prompt-buttons">
          {buttons.map((button, index) =>
            getButton(
              button,
              index === 0 ? BUTTON_TYPES.main : BUTTON_TYPES.link,
              'use-coupon-prompt-button',
            ),
          )}
        </div>
      </>
    );
  };

  const failedBody = () => {
    return (
      <>
        <Image style={{width: '112px', height: '112px'}} src={ScanFailed} />
        <label className="use-coupon-prompt-header">
          {'二維碼無效, 再次掃描或嘗試另一個二維碼。'}
        </label>
        <div className="use-coupon-prompt-buttons">
          {buttons.map((button, index) =>
            getButton(
              button,
              index === 0 ? BUTTON_TYPES.main : BUTTON_TYPES.link,
              'use-coupon-prompt-button',
            ),
          )}
        </div>
      </>
    );
  };

  return (
    <Modal
      show={show}
      onHide={closeAction}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="use-coupon-prompt-container" style={{alignItems: 'center'}}>
        <Image
          src={CloseIcon}
          className="base-prompt-close-button"
          onClick={closeAction}
        />
        {isSuccess ? successBody() : failedBody()}
      </Modal.Body>
    </Modal>
  );
}

export default UseCouponPrompt;
