import React from 'react';
import { connect } from 'react-redux';
import { LanguageConfig } from '../../config/CustomEnums';
import i18n from '../../I18n';
import { ReactHookFormMarkDown } from '../base/CustomMarkDownArea';
import { useFormContext } from 'react-hook-form';
import {
  errorMessage,
  ReactHookFormErrorMessage,
} from '../base/ErrorFieldMessage';
import { ApplySwitch } from '../base/LabelWithSwitch';
import ImageUploader from '../base/ImageUploader';
import { insertKeyToAllLanguages } from '../../utils';

function GeneralSection({ language, languages }) {
  const { watch, setValue, formState, getValues } = useFormContext();
  const isEnglish = language === LanguageConfig.english;
  const languagesList = languages?.map((item) => item.code);

  const coverPhoto = watch(isEnglish ? 'coverPhoto' : `translations.${language}.coverPhoto`) || '';
  const applyCoverPhotoToAllLanguage = watch('applyCoverPhotoToAllLanguage');

  const themedSectionCoverPhoto = [coverPhoto]?.filter((item) => item);
  const errors = formState.errors;

  return (
    <>
      <label className="create-section-label create-section-label-bottom-space">
        {i18n.t('cover_photo', { locale: language })}
      </label>
      <label className="create-campaign-cover-photo-reminder">
        {i18n.t('themed_section_cover_photo_suggest', { locale: language })}
      </label>
      <ImageUploader
        minWidth={900}
        minHeight={346}
        disabled={
          language !== LanguageConfig.english && applyCoverPhotoToAllLanguage
        }
        images={themedSectionCoverPhoto}
        onImageStateChange={(newState) => {
          const applyLanguages = applyCoverPhotoToAllLanguage
            ? languagesList
            : [language];
          applyLanguages.forEach((item) => {
            setValue(isEnglish ? 'coverPhoto' : `translations.${item}.coverPhoto`, newState[0], {
              shouldDirty: true,
            });
          });
        }}
        aspect={450 / 173}
        maxImageNum={1}
        language={language}
        photoSectionTitle={'cover_photo'}
        errorMessage={errorMessage(
          errors,
          isEnglish ? 'coverPhoto' : `translations.${language}.coverPhoto`,
        )}
      />
      <ApplySwitch
        disabled={false}
        show={language === LanguageConfig.english}
        checked={applyCoverPhotoToAllLanguage}
        onChange={(value) => {
          setValue('applyCoverPhotoToAllLanguage', value, {
            shouldDirty: true,
          });
          if (value) {
            insertKeyToAllLanguages({
              languages,
              key: 'coverPhoto',
              value: coverPhoto,
              setValue,
              getValues,
            });
          }
        }}
      />

      <ReactHookFormMarkDown
        content={{
          label: i18n.t('description', { locale: language }),
          key: isEnglish ? 'description' : `translations.${language}.description`,
          focus: () => {}
        }}
      />
      <ReactHookFormErrorMessage
        errors={errors}
        id={isEnglish ? 'description' : `translations.${language}.description`}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  languages: state.language.allList,
});

export default connect(mapPropsToState)(GeneralSection);
