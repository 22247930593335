import React, { useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import ContentSections from '../base/ContentSections';
import { SaveAndBackButtons } from '../base/BottomStepComponent';

import { useFormContext } from 'react-hook-form';
import GeneralSection from './GeneralSection';
import BaseMultipleSelectorV2 from '../base/BaseMultipleSelectorV2';
import { useHistory, useParams } from 'react-router-dom';
import { createAction } from '../../utils';
import { validate } from '../../containers/homeManage/themedSection/createThemedSectionValidate';

function CreateThemedSectionStepOne({ languages, campaignTagsList }) {
  const { watch, formState, setValue, getValues, setError, clearErrors } = useFormContext();
  const errors = formState.errors;
  const fieldError = Object.keys(errors || []).length;
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const campaignTags = watch('campaignTags');

  const getTabs = (language) => {
    return [
      <GeneralSection language={language} />,
      <BaseMultipleSelectorV2
        title={'Campaign tag (Optional)'}
        namespace="campaignTagList"
        data={{
          sourceData: campaignTagsList,
          targetData: campaignTags,
          targetChange: (options) => {
            setValue('campaignTags', options, {shouldDirty: true});
          },
        }}
        groupKey="tagKey"
      />,
    ];
  };

  useEffect(() => {
    dispatch({
      type: 'campaignTag/getAllList', 
      payload: { status: true }
    });
  }, [dispatch])

  return (
    <>
      <ContentSections
        fieldsError={fieldError}
        languageTabContent={{
          containers: languages?.map((language) => {
            return {
              container: getTabs(language.code),
              key: language.code,
              title: language.sourceName,
            };
          }),
        }}
        hidePreview={true}
      />
      <SaveAndBackButtons
        backAction={() => history.goBack()}
        saveText={params.id ? 'Update' : 'Save'}
        saveAction={() => {
          const isValid = validate({
            getValues,
            setError,
            clearErrors,
            isBack: false,
          })
          if (isValid) {
            dispatch(
              createAction('themeSection/updateThemeSection')({
                ...getValues(),
                afterActions: () => history.push({ pathname: '/themed_section' }),
              }),
            );
          }
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  languages: state.language.allList,
  campaignTagsList: state.campaignTag.notPagedAllList,
});

export default connect(mapPropsToState)(CreateThemedSectionStepOne);
