import { getSearchKey } from '../utils';
import { BaseHelper } from './BaseGraphQLAPIHelper';


const CARPARK_LIST_NODE = `{
  id
  pk
  name
  photo
}`

const CARPARK_NODE = `{
  id
  pk
  name
  photo
  detailPhoto1
  detailPhoto2
  detailPhoto3
  detailPhoto4
  detailPhoto5
  detailPhoto6
  detailPhoto7
  detailPhoto8
  detailPhoto9
  address
  latitude
  longitude
  description
  phoneNumber
  privileges {
    edges {
      node {
        name
        icon
        translations{
          edges{
            node{
              language
              name
            }
          }
        }
      }
    }
  }
  payments {
    edges {
      node {
        name
        image
        translations {
          edges {
            node {
              name
              language
            }
          }
        }
      }
    }
  }
  charges
  remarks
  serviceTnc
  carParkPromotion
  mall {
    pk
    name
    translations {
      edges {
        node {
          language
          name
        }
      }
    }
  }
  fullChargerChoices{
    code
    name
    chargerType
  }
  chargerLocation
  vendors{
    edges{
        node{
            pk
            showVendorOnApp
            showVendorVacancyOnApp
            chargerChoices{
                edges{
                    node{
                        name
                        code
                    }
                }
            }
            vendor{
                name
                translations{
                    edges{
                        node{
                            language
                            name
                        }
                    }
                }
            }
        }
    }
  }
  district {
    name
    translations {
      edges {
        node {
          language
          name
        }
      }
    }
  }
  translations {
    edges {
      node {
        pk
        name
        language
        description
        address
        charges
        remarks
        serviceTnc
        carParkPromotion
        chargerLocation
      }
    }
  }
}`;

export const getCarParkList = (afterCursor, payload) => {
  const { moreSearch, searchKey } = payload;
  let searchString = payload?.isAll ? '' : `first: ${payload?.pageSize || 20}`;
  if (afterCursor) {
    searchString += `, after: "${afterCursor}"`;
  }
  if (moreSearch?.sort) {
    searchString += `, orderBy: "${moreSearch.sort}"`;
  } else {
    searchString += `, orderBy: "name"`;
  }
  if (searchKey) {
    searchString += `, nameIcontains:"${getSearchKey(searchKey)}"`;
  }

  const query = `{
    carParks(${searchString}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node ${CARPARK_LIST_NODE}
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCarPark = (id) => {
  const query = `{
    carPark(id: "${id}") ${CARPARK_NODE}
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

// update charger location and vendor display settings
export const updateCarPark = (values) => {
  const query = `mutation UpdateCarpark($input: UpdateCarParkInput!) {
    updateCarPark(input: $input) {
      success
      node ${CARPARK_NODE}
      errors {
        field
        messages
      }
    }
  }`;
  const variables = { input: values };
  return BaseHelper.callGraphQLAPI({ query, variables });
};