import React, { useState, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import i18n from '../../I18n';
import { CampaignCategoryType, LanguageConfig } from '../../config/CustomEnums';
import { hasError, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import CustomSwitchButton from '../base/CustomSwitchButton';
import CustomTitleWithInput from '../../components/base/CustomTitleWithInput';
import CustomRadios from '../base/CustomRadios';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';

export const CAMPAIGN_CATEGORY_TYPE_CONFIG = [
  {
    label: 'General',
    value: CampaignCategoryType.GENERAL,
  },
  {
    label: 'Interest club',
    value: CampaignCategoryType.INTEREST_CLUB,
  },
  // {
  //   label: 'Fresh market',
  //   value: CampaignCategoryType.FRESH_MARKET,
  // },
];

const CampaignCategoryContentSection = ({ language }) => {
  const isEnglish = language === LanguageConfig.english;
  const { register, watch, setValue, control, formState, clearErrors } =
    useFormContext();
  const { errors } = formState;
  const watchName = isEnglish
    ? watch('name')
    : watch(`translations.${language}.name`);
  const watchOrder = watch('order');
  const watchIsForcedInactive = watch('isForcedInactive');
  const watchCampaignCategoryType = watch('campaignCategoryType') || setValue('campaignCategoryType', CampaignCategoryType.GENERAL, {shouldDirty: true});;

  return (
    <>
      <div className="create-section-title">
        {i18n.t('content', { locale: 'en' })}
      </div>
      <Controller
        control={control}
        name={isEnglish ? 'name' : `translations.${language}.name`}
        rules={isEnglish ? { required: 'Please provide a name' } : {}}
        render={() => (
          <CustomTitleWithInput
            title={i18n.t('name', { locale: 'en' })}
            defaultValue={watchName}
            error={{
              error: hasError(
                errors,
                isEnglish ? 'name' : `translations.${language}.name`,
              ),
            }}
            setValue={(value) => {
              setValue(
                isEnglish ? 'name' : `translations.${language}.name`,
                value,
                {
                  shouldDirty: true,
                },
              );
            }}
          />
        )}
      />
      <ReactHookFormErrorMessage
        errors={errors}
        id={isEnglish ? 'name' : `translations.${language}.name`}
      />

      {isEnglish ? (
        <>
          <Controller
            control={control}
            name={'campaignCategoryType'}
            rules={{
              required: 'Please provide a category type',
            }}
            render={() => (
              <>
                <CustomTitleLabel title={'Category type'}/>
                <div style={{ display: 'flex', marginTop: 8 }}>
                  <CustomRadios
                    onChange={(value) => {
                      setValue('campaignCategoryType', value, {
                        shouldDirty: true,
                      });
                    }}
                    default={watchCampaignCategoryType}
                    options={CAMPAIGN_CATEGORY_TYPE_CONFIG}
                  />
                </div>
              </>
            )}
          />
          <ReactHookFormErrorMessage errors={errors} id={'campaignCategoryType'} />
          <Controller
            control={control}
            name={'order'}
            rules={{
              min: { value: 1, message: 'Minimum value is 1' },
              required: 'Please provide order',
            }}
            render={() => (
              <CustomTitleWithInput
                title={'Display order'}
                error={{ error: hasError(errors, 'order') }}
                defaultValue={watchOrder}
                setValue={(value) => {
                  setValue('order', value, {
                    shouldDirty: true,
                  });
                }}
                type={'number'}
                // extra={{
                //   min: 1,
                // }}
              />
            )}
          />
          <ReactHookFormErrorMessage errors={errors} id={'order'} />
          <label className="coupon-campaign-section-caution-description">
            Minimum value is 1. The smaller value, the higher priority.
          </label>
          <label className="create-section-label create-section-label-bottom-space">
            Is forced inactive
          </label>
          <Controller
            control={control}
            name="isForcedInactive"
            render={() => (
              <CustomSwitchButton
                defaultChecked={watchIsForcedInactive}
                onChange={(value) => {
                  setValue('isForcedInactive', value, {
                    shouldDirty: true,
                  });
                }}
              />
            )}
          />
          <ReactHookFormErrorMessage errors={errors} id="isForcedInactive" />
        </>
      ) : null}
    </>
  );
};

export default CampaignCategoryContentSection;
