import {
  getTransactions,
  getTransaction,
  approveTransaction,
  rejectTransaction,
  recallTransaction,
  deleteTransactions,
  getTransactionStaffNames,
} from '../services/TransactionRecordAPIHelper';
import {
  TRANSACTION_EVENT_TYPE,
  TRANSACTION_RECORD_FORM_TYPE,
} from '../containers/conciergeApp/transactionRecord/TransactionRecordList';
import {
  getDisplayDate,
  getCouponExpiryDate,
  formatDate,
  TimeFormater,
} from '../utils/TimeFormatUtil';
import {
  createAction,
  convertCursorToNumber,
  formatNumberWithCommas,
  convertNumberToCursor,
} from '../utils';
import { apiWithResponseHandle, loading } from './LoadingUtil';
import { getCampaignTypeConfig } from '../components/transactionRecord/TransactionCampaignSection';
import { FORM_TYPE, LanguageConfig } from '../config/CustomEnums';
import i18n from '../I18n';

const getInitialState = () => ({
  transactionList: [],
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk', linked: true },
    { displayName: '合單ID', fieldName: 'combinedTransactionId'},
    {
      displayName: '名字',
      fieldName: 'displayFirstName',
      orderField: 'customerFirstName',
    },
    {
      displayName: '姓氏',
      fieldName: 'displayLastName',
      orderField: 'customerLastName',
    },
    {
      displayName: '會員編碼',
      fieldName: 'membershipId',
      orderField: 'membershipId',
    },
    {
      displayName: '創建日期',
      fieldName: 'displayCreationDate',
      orderField: 'creationDate',
    },
    {
      displayName: '表格類型',
      fieldName: 'transactionDisplayFormType',
      orderField: 'transactionFormType',
    },
    {
      displayName: '購物廣場',
      fieldName: 'mallTCName',
      orderField: 'mall',
    },
    { displayName: '店鋪', fieldName: 'storeName', orderField: 'store' },
    {
      displayName: '付款方式',
      fieldName: 'displayTCPaymentMethod',
      orderField: 'paymentMethod',
    },
    {
      displayName: '交易日期',
      fieldName: 'displayTransactionDate',
      orderField: 'date',
    },
    {
      displayName: '支出總金額 (HK$)',
      fieldName: 'totalValue',
      orderField: 'totalValue',
    },
    {
      displayName: '發票編號',
      fieldName: 'invoiceId',
      orderField: 'invoiceId',
    },
    { displayName: '員工名字', fieldName: 'staffName' },

    { displayName: '狀態', fieldName: 'displayOfflineEventType' },
  ],
  enListDisplayFields: [
    { displayName: 'ID', fieldName: 'pk', linked: true },
    { displayName: 'Combined Txn ID', fieldName: 'combinedTransactionId'},
    {
      displayName: 'First name',
      fieldName: 'displayFirstName',
      orderField: 'customerFirstName',
    },
    {
      displayName: 'Last name',
      fieldName: 'displayLastName',
      orderField: 'customerLastName',
    },
    {
      displayName: 'Member ID',
      fieldName: 'membershipId',
      orderField: 'membershipId',
    },
    {
      displayName: 'Create at',
      fieldName: 'displayCreationDate',
      orderField: 'creationDate',
    },
    {
      displayName: 'Transaction type',
      fieldName: 'transactionEnDisplayFormType',
      orderField: 'transactionFormType',
    },
    {
      displayName: 'Mall',
      fieldName: 'mallName',
      orderField: 'mall',
    },
    { displayName: 'Store', fieldName: 'displayStoreName', orderField: 'store' },
    {
      displayName: 'Payment method',
      fieldName: 'displayPaymentMethod',
      orderField: 'paymentMethod',
    },
    {
      displayName: 'Transaction date',
      fieldName: 'displayTransactionDate',
      orderField: 'date',
    },
    {
      displayName: 'Total value',
      fieldName: 'totalValue',
      orderField: 'totalValue',
    },
    {
      displayName: 'Invoice ID',
      fieldName: 'invoiceId',
      orderField: 'invoiceId',
    },
    {
      displayName: 'Staff name',
      fieldName: 'staffName',
      orderField: 'staffName',
    },
    { displayName: 'Status', fieldName: 'displayEnOfflineEventType' },
  ],
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
  },
  currentLastCursor: '',
  currentPage: 0,
  totalPage: 0,
  totalCount: 0,
  checkedList: [],
  currentPageTransactionList: [],
  selectedTransaction: {},
  staffNameList: [],
});

const parseTransactionDisplayFormType = (type, language) => {
  let displayType = '';
  switch (type) {
    case FORM_TYPE.trade:
      displayType = TRANSACTION_RECORD_FORM_TYPE.TRADE_FORM_TC;
      if (language === LanguageConfig.english) {
        displayType = TRANSACTION_RECORD_FORM_TYPE.TRADE_FORM;
      }
      break;
    case FORM_TYPE.notTrade:
      displayType = TRANSACTION_RECORD_FORM_TYPE.NOT_TRADE_FORM_TC;
      if (language === LanguageConfig.english) {
        displayType = TRANSACTION_RECORD_FORM_TYPE.NOT_TRADE_FORM;
      }
      break;
    default:
      break;
  }
  return displayType;
};

const parseEventType = (eventType, language) => {
  let displayEventType = '';
  switch (eventType) {
    case 'APPROVED':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_APPROVED_TC;
      if (language === LanguageConfig.english) {
        displayEventType = TRANSACTION_EVENT_TYPE.TYPE_APPROVED_EN;
      }
      break;
    case 'RECALLED':
      displayEventType = TRANSACTION_EVENT_TYPE.TYPE_RECALLED_TC;
      if (language === LanguageConfig.english) {
        displayEventType = TRANSACTION_EVENT_TYPE.TYPE_RECALLED_EN;
      }
      break;
    default:
      break;
  }
  return displayEventType;
};

const parsePurchaseItems = (purchasedItems) => {
  const parsedPurchaseList = purchasedItems.map((item) => {
    const data = item.node;
    return {
      productName: data.name,
      sku: data.sku,
      category: data.category,
      brand: data.brand,
      quantity: data.quantity,
      value: data.value,
      displayValue: data.value
        ? `HK$ ${formatNumberWithCommas(data.value)}`
        : '',
      id: data.id,
      pk: data.pk,
    };
  });
  return parsedPurchaseList;
};

const parseTranslations = (data) => {
  if (!data) {
    return {};
  }
  const translation = {};
  translation[LanguageConfig.english] = {
    name: data.name,
  }
  data.translations.edges.forEach((item) => {
    let language = item.node.language;
    translation[language] = {
      id: item.node.pk,
      language: item.node.language,
      name: item.node.name,
    };
  })
  return translation;
}

const parseCoupons = (coupons) => {
  return coupons.map((coupon) => {
    const data = coupon.node?.coupon;
    const translations = parseTranslations(data.template);
    return {
      pk: data.pk,
      name: data.template?.name,
      isUsed: data.isUsed,
      isExpired: data.isExpired,
      isActive: data.isActive,
      canConciergeUse: data.canConciergeUse,
      effectiveDate: data.effectiveDate,
      applicableStores: data.template?.applicableStores.edges.map((item) => item?.node?.pk),
      owner: data?.owner,
      translations,
    }
  })
}

const parseCouponName = (coupons, customer, language, isRecall) => {
  return coupons.map((coupon) => {
    const data = coupon.node?.coupon;
    const isOwner = data?.owner?.pk === customer?.pk;
    const owner = data?.owner?.membershipId ? `(${i18n.t('transaction.owner', { locale: language })}: ${data.owner.membershipId})` : '';
    const remark = isRecall || !data?.owner ? `(${i18n.t('transaction.Revoked', { locale: language })})` : owner;
    return data.template?.name ? `[${i18n.t('transaction.coupon_id', { locale: language })}${data.pk}] ${data.template?.name} ${remark}` : '';
  })
}

export const getTCName = (data) => {
  let TCName = '';
  if (data) {
    const TCTranslations = data.translations?.edges?.filter(
      (translation) => translation?.node.language === 'zh-Hant'
    );
    if (TCTranslations) {
      TCName = TCTranslations[0]?.node?.name;
    }
  }
  return TCName || data?.name;
}

export const parseTransaction = (data) => {
  const purchasedItems = data.purchasedItems?.edges || [];
  const coupons = data.rewardCouponTransactions?.edges || [];
  const isRecall = data.offlineEventType === "RECALLED";

  return {
    combinedTransactionId: data.combinedTransactionId,
    pk: data.pk,
    // id: data.id,
    membershipId: data.customer.membershipId,
    customerID: data.customer
      ? data.customer.mobilePhoneNumberSubscriberNumber
        ? `${data.customer.membershipId} / +${data.customer.mobilePhoneNumberCountryCode} ${data.customer.mobilePhoneNumberSubscriberNumber}`
        : data.customer.membershipId
      : null,
    ssoUid: data.customer.ssoUid,
    name: data.customer
      ? data.customer.nickname
        ? `${data.customer.firstName} ${data.customer.lastName} (${data.customer.nickname})`
        : `${data.customer.firstName} ${data.customer.lastName}`
      : null,
    transactionFormType: data.transactionFormType,
    transactionDisplayFormType: parseTransactionDisplayFormType(data.transactionFormType),
    transactionEnDisplayFormType: parseTransactionDisplayFormType(data.transactionFormType, LanguageConfig.english),
    storeName: getTCName(data.store),
    mallName: data.mall?.name,
    mallTCName: getTCName(data.mall),
    displayPaymentMethod: data.paymentMethod?.name,
    displayTCPaymentMethod: getTCName(data.paymentMethod),
    creationDate: data.creationDate,
    lastModifiedDate: data.lastModifiedDate,
    displayCreationDate: getDisplayDate(data.creationDate),
    transactionDate: data.date,
    displayTransactionDate: getDisplayDate(data.date),
    transactionDetailDisplayDate: formatDate(
      data.date,
      TimeFormater.dayMonthYearWeekTimeA,
    ),
    transactionDetailCreationDate: formatDate(
      data.creationDate,
      TimeFormater.dayMonthYearWeekTimeA,
    ),
    transactionDetailLastModifiedDate: formatDate(
      data.lastModifiedDate,
      TimeFormater.dayMonthYearWeekTimeA,
    ),
    totalValue: data.totalValue,
    displayTotalValue: data.totalValue
      ? `HK$ ${formatNumberWithCommas(data.totalValue)}`
      : '',
    onlineEventType: parseEventType(data.onlineEventType),
    offlineEventType: data.offlineEventType,
    displayOfflineEventType: parseEventType(data.offlineEventType),
    displayEnOfflineEventType: parseEventType(data.offlineEventType, LanguageConfig.english),
    staffName: data.staffName,
    shippingFee: data.shippingFee
      ? `HK$ ${formatNumberWithCommas(data.shippingFee)}`
      : '',
    otherCharge: data.otherCharge
      ? `HK$ ${formatNumberWithCommas(data.otherCharge)}`
      : '',
    remarks: data.remarks,
    posInvoiceId: data.posInvoiceId,
    invoiceId: data.invoiceId,
    receiptImage: data.receiptImage,
    creditCardSlipImage: data.creditCardSlipImage,
    purchasedItems: purchasedItems.length > 0
      ? parsePurchaseItems(purchasedItems)
      : [],
    deleteDisplayName: `${getCouponExpiryDate(data.creationDate)} ${
      data.customer?.firstName
    } ${data.customer?.lastName} $${data.totalValue}`,
    customer: {
      pk: data.customer?.pk,
      ssoUid: data.customer?.ssoUid,
      firstName: data.customer?.firstName,
      lastName: data.customer?.lastName,
      nickname: data.customer?.nickname,
      owner: `${data.customer?.firstName} ${data.customer?.lastName}`,
      membershipId: data.customer?.membershipId,
    },
    displayFirstName: data.customer?.firstName,
    displayLastName: data.customer?.lastName,
    store: {
      storeID: data.store?.id,
      storePK: data.store?.pk,
      storeName: data.store?.name,
    },
    mall: {
      mallID: data.mall?.id,
      mallPK: data.mall?.pk,
      mallName: data.mall?.name,
    },
    paymentMethod: {
      paymentMethodID: data.paymentMethod?.id,
      paymentMethodPK: data.paymentMethod?.pk,
      paymentMethodName: data.paymentMethod?.name,
    },
    createdDate: formatDate(data.creationDate),
    eventType:
      parseEventType(data.onlineEventType) ||
      parseEventType(data.offlineEventType) ||
      '-',
    displayStoreName: data.store?.name,
    transactionValue: data.totalValue,
    brand: data.store?.brand?.name,
    campaignType: data.transactionRewardType,
    displayTransactionRewardTypeName: getCampaignTypeConfig(LanguageConfig.traditionalChinese).filter(
      (type) => type.value === data.transactionRewardType,
    )[0]?.label,
    displayTransactionRewardTypeEnName: getCampaignTypeConfig(LanguageConfig.english).filter(
      (type) => type.value === data.transactionRewardType,
    )[0]?.label,
    stampCampaign: data.stampCampaign,
    missionCampaign: data.missionCampaign,
    missionDetailCampaign: data.missionDetailCampaign,
    otherCampaign: data.otherCampaign,
    carParkCampaign: data.carParkCampaign,
    displayStampCampaignName: getTCName(data.stampCampaign),
    displayMissionCampaignName: getTCName(data.missionDetailCampaign),
    displayOtherCampaignName: getTCName(data.otherCampaign),
    displayCarParkCampaignName: getTCName(data.carParkCampaign),
    recallRemarks: data.recallRemarks,
    stampCampaignReward: data.stampCampaignRewardStamp,
    stampCampaignRewardBonus: data.stampCampaignRewardBonus,
    totalStampCampaignReward: data.totalStampCampaignReward,
    totalStampCampaignRewardBonus: data.totalStampCampaignRewardBonus,
    totalMissionCampaignRewardBadge: data.totalMissionCampaignRewardBadge,
    stampCampaignRewardData: data.stampCampaignRewardData ? JSON.parse(data.stampCampaignRewardData)?.map((item) => {
      return {
        receiptTitle: item?.[0],
        rewards: item?.[1],
        withBonus: item?.[2],
      }
    }) : [],
    missionCampaignRewardBadgeData: data.missionCampaignRewardBadgeData ? JSON.parse(data.missionCampaignRewardBadgeData)?.map((item) => {
      return {
        receiptTitle: item?.[0],
        rewards: item?.[1],
      }
    }) : [],
    missionCampaignRewardBadge: data.missionCampaignRewardBadge,
    missionCampaignRewardCoupon: data.missionCampaignRewardCoupon,
    otherCampaignReward: data.otherCampaignRewardCoupon,
    carParkCampaignReward: data.carParkCampaignRewardCoupon,
    coupons: coupons.length > 0 ? parseCoupons(coupons) : [],
    couponNameList: coupons.length > 0 ? parseCouponName(coupons, data.customer, LanguageConfig.traditionalChinese, isRecall) : [],
    couponEnNameList: coupons.length > 0 ? parseCouponName(coupons, data.customer, LanguageConfig.english, isRecall) : [],
    couponReceiver: data?.missionCampaignRewardCouponReceiver,
  };
};

export default {
  namespace: 'transactionRecord',
  state: getInitialState(),

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },

    clearSelectedTransaction(state, { payload }) {
      return {
        ...state,
        selectedTransaction: {},
      };
    },

    clearData(state, { payload }) {
      return { ...state, ...getInitialState() };
    },

    updateStaffName(state, { payload }) {
      const page = payload?.page || 1;
      const data = payload.data;
      let newPayload = { staffNameList: data };
      if (payload.isSelectorLoad) {
        newPayload = {
          staffNameList: page > 1 ? [...state.allList, ...data] : data,
        };
      }
      return { ...state, ...newPayload };
    }
  },

  effects: {
    getCurrentPageTransactions: [
      function* ({ payload }, { call, put }) {
        const { page } = payload;
        let afterCursor = undefined;
        if (page > 1) {
          afterCursor = btoa(`arrayconnection:${(page - 1) * 20 - 1}`);
        }
        const serviceArgs = [getTransactions, afterCursor, payload];
        function* onSuccess(data) {
          const transactionData = data.transactions.edges;
          const pageInfo = data.transactions.pageInfo;
          const totalCount = data.transactions.totalCount;
          const currentLastCursor = pageInfo.endCursor;
          const transactionList = transactionData.map((item) => {
            return parseTransaction(item.node);
          });
          yield put(
            createAction('updateState')({
              currentPageTransactionList: transactionList,
              pageInfo: {
                startCursor: convertCursorToNumber(pageInfo?.startCursor) + 1 || 0,
                endCursor: convertCursorToNumber(pageInfo?.endCursor) + 1 || 0,
              },
              currentLastCursor,
              totalCount,
              totalPage: Math.ceil(totalCount / 20),
            }),
          );
        }
        yield loading(serviceArgs, onSuccess);
      },
      {
        type: 'takeLatest',
      },
    ],
    getTransaction: [
      function* ({ payload }, { call, put }) {
        const { transactionPK, updateCouponOnly } = payload;
        const afterActions = payload.afterActions || (() => {});
        const transactionID = btoa(`TransactionNode:${transactionPK}`);
        const serviceArgs = [getTransaction, transactionID];
        function* onSuccess(data) {
          const transactionData = data.transaction;
          const action = updateCouponOnly ? 'createTransactionRecord/updateCouponState' : 'createTransactionRecord/updateState';
          yield put(
            createAction('updateState')({
              selectedTransaction: parseTransaction(transactionData),
            }),
          );
          yield put(
            createAction(action)({
              transaction: parseTransaction(transactionData),
              hasUpdatedDefaultValues: true,
            }),
          );
          afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    approveTransaction: [
      function* ({ payload }, { put }) {
        const { transactionPK } = payload;
        const serviceArgs = [approveTransaction, transactionPK];
        function* onSuccess() {
          const afterActions = payload.afterActions || (() => {});
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      {
        type: 'takeLatest',
      },
    ],
    rejectTransaction: [
      function* ({ payload }, { put }) {
        const { transactionPK } = payload;
        const serviceArgs = [rejectTransaction, transactionPK];
        function* onSuccess() {
          const afterActions = payload.afterActions || (() => {});
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      {
        type: 'takeLatest',
      },
    ],
    recallTransaction: [
      function* ({ payload }, { put }) {
        const { transactionPK, recallRemarks, language } = payload;
        const serviceArgs = [recallTransaction, transactionPK, recallRemarks];
        function* onSuccess() {
          const afterActions = payload.afterActions || (() => {});
          yield afterActions();
        }
        function* onFailed(data) {
          yield put({
            type: 'navBars/updateState',
            payload: {
              saveDiscardToastShowing: {
                value: true,
                type: i18n.t('transaction.recall_failed', { locale: language }),
              },
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed, onFailed);
      },
      { type: 'takeLatest' },
    ],
    deleteTransaction: [
      function* ({ payload }, { put }) {
        const { ids } = payload;
        const serviceArgs = [deleteTransactions, ids];
        function* onSuccess() {
          const afterActions = payload.afterActions || (() => {});
          yield afterActions();
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],

    getTransactionStaffNames:[
      function* ({ payload }, { put, select }) {
        const page = payload?.page || 1;
        const pageCursor = convertNumberToCursor((page - 1) * 20 - 1);
        const serviceArgs = [getTransactionStaffNames, pageCursor, payload];
        function* onSuccess(data) {
          yield put({
            type: 'updateStaffName',
            payload: {
              ...payload,
              data: data?.transactionStaffNames?.edges?.map((item) => {
                return {
                  pk: Number(item.node?.staffName) || item.node?.staffName,
                  name: item.node?.staffName,
                }
              }),
            },
          });
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
};
