import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import DeletePrompt from '../../../components/base/DeletePrompt';
import { useDispatch, connect } from 'react-redux';
import { APIStatus } from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';
import AppVersionSection from '../../../components/appVersion/AppVersionSection';

const CreateAppVersionStepOne = ({ appVersion, createStatus }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const { getValues, trigger } = useFormContext();

  return (
    <>
      {createStatus === APIStatus.calling ? (
        <Loading />
      ) : (
        <ContentSections
          sections={[<AppVersionSection />]}
          hidePreview={true}
        />
      )}
      <SaveAndBackButtons
        saveText={'Save'}
        backAction={() => history.goBack()}
        saveAction={async () => {
          let isValid = await trigger(
            ['iosAppVersionName', 'androidAppVersionName'],
            {
              shouldFocus: true,
            },
          );
          if (isValid) {
            let uploadData = {
              values: getValues(),
            };
            if (params.id) {
              uploadData = {
                values: getValues(),
                appVersionID: params.id,
              };
            }
            dispatch({
              type: 'appVersion/createOrUpdate',
              payload: uploadData,
            });
          }
        }}
        saveTempAction={() => setShowDeletePrompt(true)}
      />
      <DeletePrompt
        message={{
          title: 'Delete this App Version',
          content: 'Did you confirm to delete this App Version?',
        }}
        title={'App Version'}
        data={appVersion}
        relatedSections={[]}
        show={showDeletePrompt}
        onClose={() => {
          setShowDeletePrompt(false);
        }}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: 'appVersion/updateState',
            payload: { checkedList: [appVersion] },
          });
          dispatch({ type: 'appVersion/delete', payload: {} });
        }}
      />
    </>
  );
};

const mapPropsToState = (state) => ({
  appVersion: state.appVersion.detail,
  createStatus: state.appVersion.createStatus,
});

export default connect(mapPropsToState)(CreateAppVersionStepOne);
