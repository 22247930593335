import { getInFilterValue, getSearchKey } from '../utils';
import { BaseHelper } from './BaseGraphQLAPIHelper';

export const getTransactions = (afterCursor = '', search) => {
  let searchString = `, orderBy: "${search.rank ? '-pk' : 'pk'}"`;
  if (search?.sort) {
    searchString = `, orderBy: "${search?.sort}"`;
  }
  if (search.searchKey) {
    searchString += `, membershipPhone: "${getSearchKey(search.searchKey)}"`;
  }
  if (search.mall) {
    searchString += `, mallIn: ${getInFilterValue(search.mall)}`;
  }
  if (search.brand) {
    searchString += `, brandIn: ${getInFilterValue(search.brand)}`;
  }
  if (search.store) {
    searchString += `, storeIn: ${getInFilterValue(search.store)}`;
  }
  if (search.staff_name) {
    searchString += `, staffNameIn: ${getInFilterValue(search.staff_name)}`;
  }
  if (search.transaction_date) {
    const transactionDate = search.transaction_date.split(',');
    searchString += `, dateGte: "${transactionDate[0]}", dateLte: "${transactionDate[1]}"`;
  }
  if (search.create_date) {
    const createDate = search.transaction_date.split(',');
    searchString += `, creationDateGte: "${createDate[0]}", creationDateLte: "${createDate[1]}"`;
  }
  if (search.transaction_type) {
    searchString += `, transactionFormTypeIn: ${getInFilterValue(search.transaction_type)}`;
  }

  const query = `
  {
    transactions(
      first: 20,
      after: "${afterCursor}"${searchString}
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          date
          staffName
          transactionFormType
          combinedTransactionId
          customer {
            pk
            ssoUid
            firstName
            lastName
            nickname
            membershipId
            mobilePhoneNumberCountryCode
            mobilePhoneNumberSubscriberNumber
          }
          store {
            pk
            name
            id
            translations {
              edges {
                node {
                  language
                  name
                  pk
                }
              }
            }
          }
          mall {
            pk
            name
            id
            translations {
              edges {
                node {
                  language
                  name
                  pk
                }
              }
            }
          }
          paymentMethod {
            pk
            name
            id
            translations {
              edges {
                node {
                  language
                  name
                  pk
                }
              }
            }
          }
          onlineEventType
          offlineEventType
          creationDate
          invoiceId
          totalValue
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getTransaction = (id) => {
  const query = `
  {
    transaction(id: "${id}") {
      id
      pk
      date
      transactionFormType
      creationDate
      lastModifiedDate
      combinedTransactionId
      customer {
        pk
        ssoUid
        firstName
        lastName
        nickname
        membershipId
        mobilePhoneNumberCountryCode
        mobilePhoneNumberSubscriberNumber
      }
      store {
        pk
        id
        name
        translations {
          edges {
            node {
              language
              name
            }
          }
        }
      }
      paymentMethod {
        pk
        name
        id
        translations {
          edges {
            node {
              language
              name
              pk
            }
          }
        }
      }
      staffName
      onlineEventType
      offlineEventType
      shippingFee
      otherCharge
      totalValue
      remarks
      shopifyOrderId
      shopifyOrderPayload
      posInvoiceId
      invoiceId
      receiptImage
      creditCardSlipImage
      transactionRewardType
      recallRemarks
      stampCampaignRewardStamp
      stampCampaignRewardBonus
      missionCampaignRewardBadge
      missionCampaignRewardCoupon
      otherCampaignRewardCoupon
      carParkCampaignRewardCoupon
      missionCampaignRewardCouponReceiver
      stampCampaign {
        id
        pk
        name
        translations {
          edges {
            node {
              language
              name
            }
          }
        }
      }
      missionCampaign {
        id
        pk
        name
        translations {
          edges {
            node {
              language
              name
            }
          }
        }
      }
      missionDetailCampaign{
        id
        pk
        name
        translations {
          edges {
            node {
              language
              name
            }
          }
        }
      }
      otherCampaign {
        id
        pk
        name
        translations {
          edges {
            node {
              language
              name
            }
          }
        }
      }
      carParkCampaign {
        id
        pk
        name
        translations {
          edges {
            node {
              language
              name
            }
          }
        }
      }
      rewardCouponTransactions {
        edges {
          node {
            id
            pk
            coupon {
              pk
              isUsed
              isExpired
              isActive
              canConciergeUse
              effectiveDate
              owner {
                id
                pk
                membershipId
              }
              template {
                name
                translations {
                  edges {
                    node {
                      language
                      name
                    }
                  }
                }
                applicableStores {
                  edges {
                    node {
                      pk
                    }
                  }
                }
              }
              campaign {
                name
              }
            }
          }
        }
      }
      purchasedItems {
        edges {
          node {
            id
            pk
            name
            sku
            category
            brand
            quantity
            value
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const approveTransaction = (id) => {
  const query = `
  mutation ApproveTransaction($input: ApproveTransactionInput!) {
    approveTransaction(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      id: id,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const rejectTransaction = (id) => {
  const query = `
  mutation RejectTransaction($input: RejectTransactionInput!) {
    rejectTransaction(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      id: id,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const recallTransaction = (id, recallRemarks) => {
  const query = `
  mutation RecallTransaction($input: RecallTransactionInput!) {
    recallTransaction(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      id: id,
      recallRemarks: recallRemarks,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteTransactions = (ids) => {
  const query = `
  mutation DeleteTransactions($input: DeleteTransactionsInput!) {
    deleteTransactions(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids: ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createTransaction = (inputBody) => {
  const query = `
  mutation CreateTransaction($input: CreateTransactionInput!) {
    createTransaction(input: $input) {
      missionCampaignRewardBadge
      missionCampaignRewardData
      stampCampaignRewardStamp
      stampCampaignRewardData
      stampCampaignRewardBonus
      node {
        id
        pk
        date
        transactionFormType
        creationDate
        customer {
          pk
          ssoUid
          firstName
          lastName
          nickname
          membershipId
          mobilePhoneNumberCountryCode
          mobilePhoneNumberSubscriberNumber
        }
        staffName
        shippingFee
        otherCharge
        totalValue
        remarks
        posInvoiceId
        invoiceId
        receiptImage
        creditCardSlipImage
        transactionRewardType
        recallRemarks
        stampCampaignRewardStamp
        stampCampaignRewardBonus
        missionCampaignRewardBadge
        missionCampaignRewardCoupon
        otherCampaignRewardCoupon
        carParkCampaignRewardCoupon
        missionCampaignRewardCouponReceiver
        stampCampaign {
          id
          pk
          name
          translations {
            edges {
              node {
                language
                name
              }
            }
          }
        }
        missionCampaign {
          id
          pk
          name
          translations {
            edges {
              node {
                language
                name
              }
            }
          }
        }
        missionDetailCampaign{
          id
          pk
          name
          translations {
            edges {
              node {
                language
                name
              }
            }
          }
        }
        otherCampaign {
          id
          pk
          name
          translations {
            edges {
              node {
                language
                name
              }
            }
          }
        }
        carParkCampaign {
          id
          pk
          name
          translations {
            edges {
              node {
                language
                name
              }
            }
          }
        }
        rewardCouponTransactions {
          edges {
            node {
              id
              pk
              coupon {
                pk
                isUsed
                isExpired
                isActive
                canConciergeUse
                effectiveDate
                owner {
                  id
                  pk
                  membershipId
                }
                template {
                  name
                  translations {
                    edges {
                      node {
                        language
                        name
                      }
                    }
                  }
                  applicableStores {
                    edges {
                      node {
                        pk
                      }
                    }
                  }
                }
                campaign {
                  name
                }
              }
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateTransaction = (inputBody) => {
  const query = `
  mutation UpdateTransaction($input: UpdateTransactionInput!) {
    updateTransaction(input: $input) {
      node {
        id
        pk
        date
        transactionFormType
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getPaymentMethed = (payload) => {
  const query = `
  {
    paymentMethods(first: 100) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          name
          translations {
            edges {
              node {
                pk
                name
                language
              }
            }
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const simulateTransaction = (inputBody) => {
  const query = `
  mutation SimulateTransaction($input: SimulateTransactionInput!) {
    simulateTransaction(input: $input) {
      success
      node {
        stampCampaignRewardStamp
        stampCampaignRewardBonus
        stampCampaignRewardData
        stampCampaignRewardError
        missionCampaignRewardBadge
        missionCampaignRewardData
        missionCampaignRewardCoupon
        missionCampaignRewardError
        otherCampaignRewardCoupon
        otherCampaignRewardError
        carParkCampaignRewardCoupon
        carParkCampaignRewardError
        missionCampaignRewardCouponReceiver
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getTransactionStaffNames = (afterCursor = '', payload) => {
  let searchString = payload?.isAll ? '' : `first: ${payload?.pageSize || 20}`;
  if (afterCursor) {
    searchString += `, after: "${afterCursor}"`;
  }
  if (payload?.searchKey) {
    searchString += `, name: "${getSearchKey(payload.searchKey)}"`;
  }
  const query = `
  {
    transactionStaffNames(${searchString}) {
      edges {
        cursor
        node {
          id
          staffName
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const UseRewardCoupon = (inputBody) => {
  const query = `
  mutation UseCoupon($input: UseCouponInput!) {
    useCoupon(input: $input) {
      success
      node {
        success
        coupon {
          id
          pk
          owner {
            id
            pk
            firstName
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
