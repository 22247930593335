import React, { useState, useEffect } from 'react';
import i18n from '../../../I18n.js';
import { useSelector, useDispatch, connect } from 'react-redux';
import CustomMarkDownArea, { ReactHookFormMarkDown } from '../../base/CustomMarkDownArea';
import { useCompare } from '../../../utils';
import { useFormContext } from 'react-hook-form';
import { InputFieldControl2 } from '../../base/InputFieldControl.js';


function InstructionSection({
  language,
  focusChange,
  titleTips,
  contentTips
}) {
  // const language = props.language;
  // const focusChange = props.focusChange;
  // const onSubmit = props.onSubmit;
  // const onSubmitAction = props.onSubmitAction || (() => {});

  // const dispatch = useDispatch();
  // const { watch, setValue, formState, control, getValues } = useFormContext();
  // const errors = formState.errors;

  // const instructionSectionTitle = watch(`translations.${language}.instructionSectionTitle`) || '';
  // const instructionSectionContent = watch(`translations.${language}.instructionSectionContent`) || '';

  // useEffect(() => {
  //   if (hasValChanged) {
  //     // console.log(
  //     //   '@@36: ',
  //     //   instructionSectionContent,
  //     //   vals.instructionSectionContent,
  //     // );
  //     dispatch({
  //       type: valueChangedAction,
  //       payload: { vals, language },
  //     });
  //   }
  // });

  // useEffect(() => {
  //   if (onSubmit) {
  //     onSubmitAction({
  //       instructionSectionTitle,
  //       instructionSectionContent,
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [onSubmit]);

  return (
    <>
    <InputFieldControl2
      areaTitle={i18n.t('instruction', { locale: language })}
      name={`translations.${language}.instructionSectionTitle`}
      title={i18n.t('instruction_section_title', { locale: language })}
      onFocus={() => {
        focusChange('instructionTitle');
      }}
      imagesTips={ titleTips }
    />
    <ReactHookFormMarkDown
      content={{
        label: i18n.t('instruction_section_content', { locale: language }),
        key: `translations.${language}.instructionSectionContent`,
        focus: () => {
          focusChange('instructionContent')
        },
        tips: { ...contentTips }
      }}
    />
  </>
    // <CustomMarkDownArea
    //   areaTitle={i18n.t('instruction', { locale: language })}
    //   title={{
    //     label: i18n.t('instruction_section_title', { locale: language }),
    //     value: instructionSectionTitle,
    //     valueChange: (value) => {
    //       setValue(`translations.${language}.instructionSectionTitle`, value, {shouldDirty: true})
    //     },
    //     tips: { ...titleTips },
    //     focus: () => focusChange('instructionTitle'),
    //   }}
    //   content={{
    //     label: i18n.t('instruction_section_content', { locale: language }),
    //     value: instructionSectionContent,
    //     valueChange: (value) => {
    //       setValue(`translations.${language}.instructionSectionContent`, value, {shouldDirty: true})
    //     },
    //     tips: { ...contentTips },
    //     focus: () => focusChange('instructionContent'),
    //   }}
    // />
  );
}

const mapPropsToState = (state) => ({

})

export default connect(mapPropsToState)(InstructionSection);
