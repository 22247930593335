// import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { CAMPAIGN_REWARD_TYPE, FORM_TYPE, MissionCampaignMode } from '../../config/CustomEnums';
import { CreateTransactionError } from '../../config/ErrorHandle';
import i18n from '../../I18n';
import { parseNameByLanguage, getTransactionError } from '../../models/CreateTransactionRecordModel';
// import { getInFilterValue } from '../../utils';
import CustomRadios from '../base/CustomRadios';
import { ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
// import { getReceiptTitle } from './PurchaseReceiptSection';
import BaseRecentlySelector from '../base/BaseRecentlySelector';
import './TransactionCampaignSection.scss';

export const getCampaignTypeConfig = (language) => [
  {
    label: i18n.t('transaction.stamp_park_campaign', { locale: language }),
    value: CAMPAIGN_REWARD_TYPE.stamp,
  },
  {
    label: i18n.t('transaction.mission_park_campaign', { locale: language }),
    value: CAMPAIGN_REWARD_TYPE.mission,
  },
  {
    label: i18n.t('transaction.other_park_campaign', { locale: language }),
    value: CAMPAIGN_REWARD_TYPE.other,
  },
  {
    label: i18n.t('transaction.park_campaign', { locale: language }),
    value: CAMPAIGN_REWARD_TYPE.carPark,
  },
];

function TransactionCampaignSection({ language }) {
  const dispatch = useDispatch();
  const { watch, setValue, getValues, formState, setError, clearErrors } = useFormContext();
  const { errors } = formState;
  const errorFields = Object.keys(errors);

  // const customerIDField = 'customerID';
  const customerField = 'customer';
  const campaignTypeField = 'campaignType';
  const stampCampaignField = 'stampCampaign';
  const missionCampaignField = 'missionCampaign';
  const otherCampaignField = 'otherCampaign';
  const carParkCampaignField = 'carParkCampaign';
  const recentlyDataField = 'recentlyData';
  const mallField = 'mall';

  // const customerID = watch(customerIDField);
  const customer = watch(customerField);
  const campaignType = watch(campaignTypeField);
  const stampCampaign = watch(stampCampaignField);
  const missionCampaign = watch(missionCampaignField);
  const otherCampaign = watch(otherCampaignField);
  const carParkCampaign = watch(carParkCampaignField);
  const recentlyData = watch(recentlyDataField);
  const mall = watch(mallField);
  const values = getValues();
  const isMultipleReceipts = values?.receipts?.length > 1;
  const isTrade = values.formType === FORM_TYPE.trade;
  const stampCampaignTitle = i18n.t('transaction.stamp_campaign', { locale: language });
  const missionCampaignTitle = i18n.t('transaction.mission_campaign', { locale: language });
  const otherCampaignTitle = i18n.t('transaction.other_campaign', { locale: language });
  const carCampaignTitle = i18n.t('transaction.carpark_campaign', { locale: language });
  const errorFilter = {
    membershipId: values.customer?.membershipId,
    receipts:[""],
    // receipts: values?.receipts?.map((receipt, index) => {
    //   return getInFilterValue([
    //     receipt?.store?.pk,
    //     receipt?.paymentMethod?.pk,
    //     moment(receipt.transactionDate).format(),
    //     isTrade ? receipt.totalValue : receipt.submitNumber,
    //     receipt.invoiceId,
    //     getReceiptTitle(index, receipt, language, isTrade),
    //   ])
    // }),
  };
  const defaultFilter = [
    // values.customer?.membershipId,
    mall?.pk
  ];

  const getCampaignFilter = (campaignType) => {
    let campaignFilterKey = '';
    switch (campaignType){
      case 'MISSION':
        campaignFilterKey = 'isMissionCampaign';
        break
      case 'OTHER':
        campaignFilterKey = 'isOtherCampaign';
        break
      case 'CAR_PARK':
        campaignFilterKey = 'isCarParkCampaign';
        break
      default:
        campaignFilterKey = 'isStampCampaign';
          break
    }
    return {
      [campaignFilterKey]:true,
      transactionCampaigns: [campaignType, ...defaultFilter],
      allDisplayIn: true,
      isSelectorLoad: true,
      sort: 'name',
      transactionErrorFilter: { ...errorFilter, campaignType: campaignType }
    }
  };

  const stampFilter = getCampaignFilter('STAMP');

  const missionFilter = getCampaignFilter('MISSION');

  const otherFilter = getCampaignFilter('OTHER');

  const carParkFilter = getCampaignFilter('CAR_PARK');

  const [disableDropDown, setDisableDropDown] = useState(true);
  const { stampCampaignList, missionCampaignList, otherCampaignList, carParkCampaignList } = useSelector((state) => ({
    stampCampaignList: state.campaignList.stampCampaignList,
    missionCampaignList: state.campaignList.missionCampaignList,
    otherCampaignList: state.campaignList.otherCampaignList,
    carParkCampaignList: state.campaignList.carParkCampaignList,
  }));

  const parseStampCampaignList = customer ? parseNameByLanguage(stampCampaignList, language) : [];
  const parseMissionCampaignList = customer ? parseNameByLanguage(missionCampaignList, language) : [];
  const parseOtherCampaignList = customer ? parseNameByLanguage(otherCampaignList, language) : [];
  const parseCarParkCampaignList = customer ? parseNameByLanguage(carParkCampaignList, language) : [];

  const handleDefaultValue = (defaultValue, fieldName, campaignList) => {
    const filterCampagin = campaignList?.filter((campaign) => campaign?.pk === defaultValue?.pk)[0];
    if (campaignList.length) {
      setSelectValue(fieldName, filterCampagin, { shouldDirty: true });
    }
  }

  const getCampaign = (type) => {
    if (type) {
      dispatch({
        type: 'campaignList/getCampaignListForTransaction',
        payload: {
          isStampCampaign: type === 'STAMP',
          isMissionCampaign: type === 'MISSION',
          isOtherCampaign: type === 'OTHER',
          allDisplayIn: true,
          isSelectorLoad: true,
          sort: 'name',
          transactionCampaigns: [
            type,
            ...defaultFilter,
          ],
          transactionErrorFilter: {
            ...errorFilter,
            campaignType: type,
          }
        },
      });
    }
    dispatch({
      type: 'campaignList/getCampaignListForTransaction',
      payload: {
        isCarParkCampaign: true,
        allDisplayIn: true,
        isSelectorLoad: true,
        sort: 'name',
        transactionCampaigns: [
          'CAR_PARK',
          ...defaultFilter,
        ],
        transactionErrorFilter: {
          ...errorFilter,
          campaignType: 'CAR_PARK',
        }
      },
    });
  }

  const setSelectValue = (field, value) => {
    setValue(field, value, { shouldDirty: true });
    if (value?.campaignTransactionError?.length) {
      const errorMessages = [];
      value.campaignTransactionError.forEach((item) => {
        let errorMessage = getTransactionError(item?.errorKey, language, value);
        if (item?.receiptTitle) {
          errorMessage = `${item.receiptTitle} ${errorMessage}`
        }
        errorMessages.push(errorMessage);
      });
      setError(field, {
        type: 'require',
        message: errorMessages.join('\n'),
      });
    } else {
      clearErrors(field);
    }
  };

  const getRecentlyCampaigns = (title, recentlyCampaigns, campaigns, defaultValue, field) => {
    const campaignPKs = campaigns?.map((item) => item?.pk);
    const source = recentlyCampaigns?.map((item) => {
      const campaignTransactionError = campaigns.find((campaign) => campaign.pk === item.pk)?.campaignTransactionError || []
      return {
        ...item,
        campaignTransactionError,
        disabled: !campaignPKs.includes(item.pk),
      }
    });
    return recentlyCampaigns?.length ? (
      <BaseRecentlySelector
        title={title}
        defaultValue={defaultValue}
        source={source}
        titleStyle={'recently-title'}
        optionStyle={'recently-options'}
        setValue={(item) => {
          setSelectValue(field, item.value);
        }}
      />
    ) : null;
  }

  const clearSelectedCampaign = () => {
    setValue(stampCampaignField, '', { shouldDirty: true });
    setValue(missionCampaignField, '', { shouldDirty: true });
    setValue(otherCampaignField, '', { shouldDirty: true });
    setValue(carParkCampaignField, '', { shouldDirty: true });
    clearErrors();
  }

  useEffect(() => {
    if (!campaignType) {
      setValue(campaignTypeField, CAMPAIGN_REWARD_TYPE.stamp, {
        shouldDirty: true,
      });
    }
    // let type = 'STAMP'
    // if (campaignType === CAMPAIGN_REWARD_TYPE.mission) {
    //   type = 'MISSION'
    // } else if (campaignType === CAMPAIGN_REWARD_TYPE.other) {
    //   type = 'OTHER'
    // } else if (campaignType === CAMPAIGN_REWARD_TYPE.carPark) {
    //   type = ''
    // }
    // getCampaign(type);
  }, [dispatch, setValue, campaignType]);

  useEffect(()=>{
    if(customer?.membershipId && mall){
      // defaultFilter.splice(0,1,customer?.membershipId)
      let type = 'STAMP'
      if (campaignType === CAMPAIGN_REWARD_TYPE.mission) {
        type = 'MISSION'
      } else if (campaignType === CAMPAIGN_REWARD_TYPE.other) {
        type = 'OTHER'
      } else if (campaignType === CAMPAIGN_REWARD_TYPE.carPark) {
        type = ''
      }
      getCampaign(type);
    }
    if (!customer || !mall) {
      clearSelectedCampaign();
    }
  },[customer, mall])

  useEffect(() => {
    handleDefaultValue(stampCampaign, stampCampaignField, parseStampCampaignList);
    handleDefaultValue(missionCampaign, missionCampaignField, parseMissionCampaignList);
    handleDefaultValue(otherCampaign, otherCampaignField, parseOtherCampaignList);
    handleDefaultValue(carParkCampaign, carParkCampaignField, parseCarParkCampaignList);
    if (stampCampaign || missionCampaign || otherCampaign) {
      setDisableDropDown(false);
    }
  }, [stampCampaignList, missionCampaignList, carParkCampaignList, otherCampaignList])

  useEffect(() => {
    let disabled = true;
    if (
      (campaignType === CAMPAIGN_REWARD_TYPE.stamp && stampCampaign) ||
      (campaignType === CAMPAIGN_REWARD_TYPE.mission && missionCampaign) ||
      (campaignType === CAMPAIGN_REWARD_TYPE.other && otherCampaign) ||
      campaignType === CAMPAIGN_REWARD_TYPE.carPark
    ) {
      disabled = false;
    }
    if (
      isMultipleReceipts &&
      (campaignType === CAMPAIGN_REWARD_TYPE.stamp ||
        (campaignType === CAMPAIGN_REWARD_TYPE.mission &&
          missionCampaign && missionCampaign.missionCampaignMode === MissionCampaignMode.fixedRewards))
    ) {
      disabled = true;
    }
    setDisableDropDown(disabled);
  }, [campaignType, stampCampaign, missionCampaign, otherCampaign]);

  return (
    <>
      {/* <div>
        <CustomTitleLabel title={i18n.t('transaction.memberid/mobile_number', { locale: language })}/>
        <div className="second-section-content">{customerID}</div>
      </div> */}
      <div>
        <CustomTitleLabel title={i18n.t('transaction.select_types_of_campaigns', { locale: language })} />
        <div style={{ display: 'flex', marginTop: 8 }}>
          <CustomRadios
            onChange={(value) => {
              setValue(campaignTypeField, value, { shouldDirty: true });
              clearSelectedCampaign();
            }}
            default={campaignType}
            options={getCampaignTypeConfig(language)}
          />
        </div>
        <ReactHookFormErrorMessage errors={errors} id={campaignTypeField} />
      </div>

      {campaignType === CAMPAIGN_REWARD_TYPE.stamp ? (
        <>
          <CustomTitleWithDropDown
            title={stampCampaignTitle}
            placeholder={i18n.t('transaction.search_for', { locale: language })}
            labelPlaceholder={i18n.t('transaction.select_stamp_campaign', { locale: language })}
            defaultValue={{
              value: stampCampaign,
              label: stampCampaign?.name,
            }}
            source={parseStampCampaignList}
            setValue={(item) => setSelectValue(stampCampaignField, item.value)}
            errors={errorFields}
            errorName={CreateTransactionError.stampCampaign.name}
            errorMessage={errors?.[stampCampaignField]?.message}
            loadMoreAction={'campaignList/getCampaignListForTransaction'}
            filterAction={'campaignList/getCampaignListForTransaction'}
            defaultFilter={stampFilter}
            disableAutoFocus={true}
          />
          {getRecentlyCampaigns(
            i18n.t(`transaction.recently_campaign`, {
              locale: language,
              campaign: stampCampaignTitle,
            }),
            recentlyData?.stampCampaigns,
            parseStampCampaignList,
            stampCampaign,
            stampCampaignField,
          )}
        </>
      ) : null}

      {campaignType === CAMPAIGN_REWARD_TYPE.mission ? (
        <>
          <CustomTitleWithDropDown
            title={missionCampaignTitle}
            placeholder={i18n.t('transaction.search_for', { locale: language })}
            labelPlaceholder={i18n.t('transaction.select_mission_campaign', { locale: language })}
            defaultValue={{
              value: missionCampaign,
              label: missionCampaign?.name,
            }}
            source={parseMissionCampaignList}
            setValue={(item) => setSelectValue(missionCampaignField, item.value)}
            errors={errorFields}
            errorName={CreateTransactionError.missionCampaign.name}
            errorMessage={errors?.[missionCampaignField]?.message}
            loadMoreAction={'campaignList/getCampaignListForTransaction'}
            filterAction={'campaignList/getCampaignListForTransaction'}
            defaultFilter={missionFilter}
            disableAutoFocus={true}
          />
          {getRecentlyCampaigns(
            i18n.t(`transaction.recently_campaign`, {
              locale: language,
              campaign: missionCampaignTitle,
            }),
            recentlyData?.missionCampaigns,
            parseMissionCampaignList,
            missionCampaign,
            missionCampaignField,
          )}
        </>
      ) : null}

      {campaignType === CAMPAIGN_REWARD_TYPE.other ? (
        <>
          <CustomTitleWithDropDown
            title={otherCampaignTitle}
            placeholder={i18n.t('transaction.search_for', { locale: language })}
            labelPlaceholder={i18n.t('transaction.select_other_campaign', { locale: language })}
            defaultValue={{
              value: otherCampaign,
              label: otherCampaign?.name,
            }}
            source={parseOtherCampaignList}
            setValue={(item) => setSelectValue(otherCampaignField, item.value)}
            errors={errorFields}
            errorName={CreateTransactionError.otherCampaign.name}
            errorMessage={errors?.[otherCampaignField]?.message}
            loadMoreAction={'campaignList/getCampaignListForTransaction'}
            filterAction={'campaignList/getCampaignListForTransaction'}
            defaultFilter={otherFilter}
            disableAutoFocus={true}
          />
          {getRecentlyCampaigns(
            i18n.t(`transaction.recently_campaign`, {
              locale: language,
              campaign: otherCampaignTitle,
            }),
            recentlyData?.otherCampaigns,
            parseOtherCampaignList,
            otherCampaign,
            otherCampaignField,
          )}
        </>
      ) : null}

      <CustomTitleWithDropDown
        title={
          campaignType === CAMPAIGN_REWARD_TYPE.carPark
            ? carCampaignTitle
            : i18n.t('transaction.carpark_campaign_optional', { locale: language })
        }
        placeholder={i18n.t('transaction.search_for', { locale: language })}
        labelPlaceholder={i18n.t('transaction.select_carpark_campaign', { locale: language })}
        defaultValue={{
          value: carParkCampaign,
          label: carParkCampaign?.name,
        }}
        source={parseCarParkCampaignList}
        setValue={(item) => setSelectValue(carParkCampaignField, item.value)}
        errors={errorFields}
        errorName={CreateTransactionError.carParkCampaign.name}
        errorMessage={errors?.[carParkCampaignField]?.message}
        loadMoreAction={'campaignList/getCampaignListForTransaction'}
        filterAction={'campaignList/getCampaignListForTransaction'}
        defaultFilter={carParkFilter}
        disableDropDown={disableDropDown}
        disableAutoFocus={true}
      />
      {getRecentlyCampaigns(
        i18n.t(`transaction.recently_campaign`, {
          locale: language,
          campaign: carCampaignTitle,
        }),
        recentlyData?.carParkCampaigns,
        parseCarParkCampaignList,
        carParkCampaign,
        carParkCampaignField,
      )}
    </>
  );
}

export default TransactionCampaignSection;
