import React, { useEffect, useState } from 'react';
import { Button, Image, ButtonGroup, Text } from 'react-bootstrap';
import addImage from '../../assets/images/Addclose.svg';
import openImage from '../../assets/images/Addopen.svg';

export const ImageButton = ({ action, image, customClass }) => {
  //   const [buttonImage, setButtonImage] = useState(addImage);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <button
      className={`custom-image-button create-section-label-bottom-space filter-open-button-margin ${customClass}`}
      onClick={() => {
        action();
        // setButtonImage();
        setIsOpen(!isOpen);
      }}
    >
      <Image src={image ? image : isOpen ? openImage : addImage} />
    </button>
  );
};

export const ImageTextButton = ({ action, image, title }) => {
  return (
    <button className="custom-image-button" onClick={action}>
      <Image src={image} className="filter-button-area" />
      <label className="filter-title">{title}</label>
    </button>
  );
};
