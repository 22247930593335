import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import { ImportResource } from '../../../models/DownloadImportModel';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import Filter from './Filter';
import ListButtonsGroup from '../../../components/base/ListButtonsGroup';
import { ActionsDropdownForItem } from '../../../components/base/ActionsDropdown';
import i18n from '../../../I18n';
import { getConciergeLanguage } from '../../conciergeApp/couponRecord/CouponTransactionRecord';

function StampRecordList({
  listDisplayFields,
  pagedList,
  totalCount,
  pageInfo,
  totalPage,
}) {
  const queryString = require('query-string');
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const searchDict = queryString.parse(location.search);
  const currentPage = searchDict?.page || 1;
  const searchKey = searchDict?.search;

  const language = getConciergeLanguage(location);

  const fetchData = () => {
    dispatch({
      type: 'stampRecord/getCurrentPageStampRecords',
      payload: {
        page: currentPage,
        searchKey,
        ...searchDict,
      },
    });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const exportCSVAction = () => {
    dispatch({
      type: 'downloadAndImport/createDownloadTask',
      payload: {
        from: ImportResource.stampRecord,
        related: {
          eventTypeIn: searchDict?.event_type?.split(','),
          recordTypeIn: searchDict?.record_type?.split(','),
          recordDateGte: searchDict?.create_date?.split(',')?.[0],
          recordDateLte: searchDict?.create_date?.split(',')?.[1],
          valueGte: searchDict?.quantity?.split(',')?.[0],
          valueLte: searchDict?.quantity?.split(',')?.[1],
        },
      }
    });
  };

  const buttons = [
    <ListButtonsGroup
      hideExtraButtonWidth={600}
      hideAllButtonWidth={530}
      primaryButton={[
        <AuthButton
          title={i18n.t('concierge.export_csv', { locale: language })}
          // customClass="btn-back-button-common btn-download"
          action={exportCSVAction}
          requires={PermissionCodes.addExportjob}
        />,
      ]}
      primaryPopContent={[
        {
          requires: PermissionCodes.addExportjob,
          action: exportCSVAction,
          content: i18n.t('concierge.export_csv', { locale: language }),
        },
      ]}
    />,
  ];

  const typeTabs = [
    { key: 'all', name: i18n.t('concierge.all', { locale: language }) },
    { key: 'ADD_STAMP', name: i18n.t('concierge.add_stamp', { locale: language }) },
    { key: 'REMOVE_STAMP', name: i18n.t('concierge.remove_stamp', { locale: language }) },
  ];

  const tabs = [
    {
      name: '',
      content: (
        <BaseTabListContainer
          hideTab={false}
          maxTabWidth={300}
          tabs={typeTabs}
          tabSearchKey={'event_type'}
          groupActions={[]}
          pageInfo={pageInfo}
          totalCount={totalCount}
          searchPlaceholder={i18n.t('transaction.search_membership_id/mobile_number', { locale: language })}
          searchMoreStyle={{ width: '350px' }}
          filter={{
            hasFilter: true,
            componment: Filter,
            filterTitle: i18n.t('transaction.filter', { locale: language }),
            moreStyle: { right: '417px' },
          }}
          componentLanguage={language}
          listContent={
            <BaseListContainer
              actionFieldDisplayName={i18n.t('transaction.action', { locale: language })}
              fields={listDisplayFields[language]}
              dataList={pagedList}
              totalPage={totalPage ? totalPage : 0}
              deleteInfo={{
                data: [],
                title: '',
                relatedName: '',
                onComfirm: {},
              }}
              model={'stampRecord'}
              permissionGroup={PermissionCodes.stamprecord}
              hideActions={false}
              customClassName="coupon-record-table"
              useCustomCommonActions
              customCommonActions={(item) => {
                const actions = [
                  {
                    name: i18n.t('transaction.view_detail', { locale: language }),
                    action: () => {
                      return history.push(`${location.pathname}/${item.pk}/`);
                    },
                    requires: PermissionCodes.viewStampRecord,
                  },
                ];
                return (
                  <ActionsDropdownForItem
                    object={item}
                    actions={actions}
                    actionTitle={i18n.t('transaction.action', { locale: language })}
                  />
                );
              }}
            />
          }
        />
      ),
    },
  ];

  return (
    <CustomListComponent
      caution={{
        // detail:
        //   'All stamp records are listed here, you can view the stamp transactions of each customer.',
        title: i18n.t('concierge.stamp_records', { locale: language }),
      }}
      buttons={buttons}
      breadcrumb={<CustomBreadcrumb />}
      hideTab={true}
      tabs={tabs}
    />
  );
}

const mapPropsToState = (state) => ({
  listDisplayFields: state.stampRecord.listDisplayFields,
  pagedList: state.stampRecord.pagedList,
  totalCount: state.stampRecord.totalCount,
  pageInfo: state.stampRecord.pageInfo,
  totalPage: state.stampRecord.totalPage,
});

export default connect(mapPropsToState)(StampRecordList);
