import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ContentSections from '../../../components/base/ContentSections';
import {
  listItem,
  listMultiItem,
  verticalListMultiImageWithItem,
  listMultiImage,
  listStores,
} from '../../../components/banners/DisplayItems';
import { APIStatus, LanguageConfig } from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';
import i18n from '../../../I18n';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';

function MallDetail({ mall, status, languages }) {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'mall/getOneDetail',
      payload: { id: params.id },
    });
    dispatch({
      type: 'language/getList',
      payload: { isSelectorLoad: true },
    });
    return () => {
      dispatch({ type: 'mall/clearData' });
    };
  }, [dispatch, params.id]);

  const renderTabForLanguage = (language) => {
    const isEnglish = language === LanguageConfig.english;
    return [
      <>
        {listItem(
          i18n.t('name', { locale: language }),
          mall.translations?.[language]?.name,
        )}
        {isEnglish
          ? mall.detailPhotos
            ? listMultiImage('Photos', mall.detailPhotos)
            : listItem('Photos', '')
          : null}
        {listItem('Sub-district', mall.translations?.[language]?.district)}
        {listItem('Address', mall.translations?.[language]?.address)}
        {isEnglish ? (
          <>
            {listItem('Latitude', mall.latitude)}
            {listItem('Longitude', mall.longitude)}
          </>
        ) : null}
        {listMultiItem('Panoramic view URL', mall.displayPanoramicViewUrl)}
        {isEnglish ? (
          <>
            {listItem(
              'Customer Service Hotline Number',
              mall.customerServiceHotlinePhoneNumber,
            )}
            {listItem('Carpark Enquiry Number', mall.carparkEnquiryPhoneNumber)}
            {listItem('Leasing Enquiry Number', mall.leasingEnquiryPhoneNumber)}
          </>
        ) : null}
        {listItem(
          i18n.t('description', { locale: language }),
          mall.translations?.[language]?.description,
        )}
        {verticalListMultiImageWithItem(
          'Facilities',
          mall.translations?.[language]?.facilities,
          '',
          'mall-facilitie-image',
        )}
        {listStores(
          'Transaction select stores',
          mall.translations?.[language]?.transactionStores,
        )}
        {listStores(
          'Transaction stamp campaigns',
          mall.translations?.[language]?.transactionStampCampaigns,
        )}
        {listStores(
          'Transaction mission campaigns',
          mall.translations?.[language]?.transactionMissionCampaigns,
        )}
        {listStores(
          'Transaction other campaigns',
          mall.translations?.[language]?.transactionOtherCampaigns,
        )}
        {listStores(
          'Transaction parking campaigns',
          mall.translations?.[language]?.transactionParkCampaigns,
        )}
      </>,
    ];
  };

  const tabs = [
    {
      name: '',
      content:
        status === APIStatus.calling ? (
          <Loading />
        ) : (
          <div className="scroll-container-common">
            <ContentSections
              languageTabContent={{
                containers: languages?.map((item) => ({
                  container: renderTabForLanguage(item.code),
                  key: item.code,
                  title: item.sourceName,
                })),
              }}
              hidePreview={true}
            />
          </div>
        ),
    },
  ];

  const buttons = [
    <AuthButton
      title="Edit"
      action={() => {
        history.push({
          pathname: 'edit/',
        });
      }}
      requires={PermissionCodes.changeMall}
    />,
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '', //view message
          title: mall?.name ? mall.name : '',
        }}
        breadcrumb={
          <CustomBreadcrumb name={mall?.name ? `${mall.name}` : ''} />
        }
        hideTab={true}
        tabs={tabs}
        buttons={buttons}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  mall: state.mall.detail,
  status: state.mall.createStatus,
  languages: state.language.allList,
});

export default connect(mapPropsToState)(MallDetail);
