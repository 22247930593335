import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import BaseForm from '../../../components/base/v2/BaseForm';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CreateTransactionRecordStepTwo from '../../../components/transactionRecord/CreateTransactionRecordStepTwo';
import CreateTransactionRecordStepOne from '../../../components/transactionRecord/CreateTransactionRecordStepOne';
import CreateTransactionRecordStepThree from '../../../components/transactionRecord/CreateTransactionRecordStepThree';
import CreateTransactionRecordStepFour from '../../../components/transactionRecord/CreateTransactionRecordStepFour';
import CreateTransactionRecordStepFive from '../../../components/transactionRecord/CreateTransactionRecordStepFive';
import i18n from '../../../I18n';
import { getTransactionLanguageAndIsTCTransaction } from './TransactionRecordList';

function CreateTransactionRecord({
  stepConfig,
  stepTCConfig,
  currentStep,
  transaction,
  transactionStatusChanged,
  hasUpdatedDefaultValues,
  formHasSubmitted,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const history = useHistory();
  // console.log('@@26: currentStep', currentStep);
  const { language, isTCTransaction } = getTransactionLanguageAndIsTCTransaction(location);

  useEffect(() => {
    dispatch({
      type: 'createTransactionRecord/clearData',
      payload: {}
    });
    if (location.pathname.includes('edit')) {
      const transactionPK = params.id;
      dispatch({
        type: 'transactions/getTransaction',
        payload: {
          transactionPK: transactionPK,
          afterActions: () =>
            dispatch({
              type: 'createTransactionRecord/stepChange',
              payload: {
                step: 0,
                isValid: true,
                isTCTransaction,
              }
            })
        },
      });
    } else {
      dispatch({
        type: 'createTransactionRecord/loadTransactionFromCookie',
        payload: { isTCTransaction }
      });
    }
  }, [dispatch, location.pathname, params.id]);

  const toTransactionList = () => {
    history.push('/concierge_transaction_records');
  };

  useEffect(() => {
    return function () {
      dispatch({
        type: 'createTransactionRecord/clearData',
        payload: {}
      });
      dispatch({
        type: 'customerList/clearData',
        payload: {}
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (transactionStatusChanged && location.pathname.includes('edit')) {
      toTransactionList();
    }
    console.log('@@53: ', transactionStatusChanged);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, transactionStatusChanged]);

  const stepContent = [
    <CreateTransactionRecordStepOne language={language} isTCTransaction={isTCTransaction} />,
    <CreateTransactionRecordStepTwo language={language} isTCTransaction={isTCTransaction} />,
    <CreateTransactionRecordStepThree language={language} isTCTransaction={isTCTransaction} />,
    <CreateTransactionRecordStepFour language={language} isTCTransaction={isTCTransaction} />,
    <CreateTransactionRecordStepFive language={language} isTCTransaction={isTCTransaction} />,
  ];
  const caution = {
    detail: '', //TODO: change this wording Create Transaction
    title: `${params.id ? 'Edit Transaction' : i18n.t('transaction.create_transaction', { locale: language })}`,
  };
  return (
    <div className="customer">
      <BaseForm
        defaultValues={transaction}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        tempSave={(save, getValues) => {
          dispatch({
            type: 'createTransactionRecord/saveOrRemoveTransactionFromCookie',
            payload: {
              save: save,
              values: getValues(),
              isTCTransaction,
            },
          });
        }}
        showFinishPop={transactionStatusChanged}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Transaction is successfully created.',
          steps: null,
          buttons: [
            {
              text: 'Back to transaction list',
              action: toTransactionList,
            },
          ],
        }}
        content={stepContent}
        currentStep={currentStep}
        stepsConfig={isTCTransaction ? stepTCConfig : stepConfig}
        breadcrumb={<CustomBreadcrumb />}
        caution={caution}
        language={language}
      />
    </div>
  );
}

const mapPropsToState = (state) => ({
  stepConfig: state.createTransactionRecord.stepConfig,
  stepTCConfig: state.createTransactionRecord.stepTCConfig,
  currentStep: state.createTransactionRecord.currentStep,
  transaction: state.createTransactionRecord.transaction,
  transactionStatusChanged: state.createTransactionRecord.transactionStatusChanged,
  hasUpdatedDefaultValues: state.createTransactionRecord.hasUpdatedDefaultValues,
  formHasSubmitted: state.createTransactionRecord.formHasSubmitted,
});

export default connect(mapPropsToState)(CreateTransactionRecord);
