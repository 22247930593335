import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { LanguageConfig } from '../../config/CustomEnums';
import i18n from '../../I18n';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import {
  listItem,
  listMultiImage,
  verticalListMultiImageWithItem,
} from '../banners/DisplayItems';
import { Image } from 'react-bootstrap';
import AddButton from '../../assets/images/add.svg';
import AuthButton from '../base/AuthButton';
import deleteImage from '../../assets/images/drop_down_delete_selected.png';
import InputFieldControl from '../base/InputFieldControl';
import SelectMultipleStoreBySearch from '../base/SelectMultipleStoreBySearch';
import SelectMultipleCampaignsBySearch from '../base/SelectMultipleCampaignsBySearch';

function CreateMallStepOneContentSection({ language, onFocus = () => {} }) {
  const { watch, setValue, getValues } = useFormContext();

  const [hideAddButton, setHideAddButton] = useState(false);

  const mallId = getValues('pk');
  const watchName = watch(`translations.${language}.name`);
  const watchDescription = watch(`translations.${language}.description`);
  const watchDetailPhotos = watch('detailPhotos');
  const watchDistrict = watch(`translations.${language}.district`);
  const watchAddress = watch(`translations.${language}.address`);
  const watchCustomerServiceHotlinePhoneNumber = watch(
    'customerServiceHotlinePhoneNumber',
  );
  const watchCarparkEnquiryPhoneNumber = watch('carparkEnquiryPhoneNumber');
  const watchLeasingEnquiryPhoneNumber = watch('leasingEnquiryPhoneNumber');
  const watchLatitude = watch('latitude');
  const watchLongitude = watch('longitude');
  const watchPanoramicViewUrl = watch('panoramicViewUrl');
  const watchFacilities = watch(`translations.${language}.facilities`);
  const isEnglish = language === LanguageConfig.english;
  const watchTransactionStores = watch(`selectedStores`);
  const watchTransactionStampCampaigns = watch(`selectedStampCampaigns`);
  const watchTransactionMissionCampaigns = watch(`selectedMissionCampaigns`);
  const watchTransactionOtherCampaigns = watch(`selectedOtherCampaigns`);
  const watchTransactionParkingCampaigns = watch(`selectedParkCampaigns`);

  useEffect(() => {
    if (watchPanoramicViewUrl && watchPanoramicViewUrl.length >= 3) {
      setHideAddButton(true);
    } else {
      setHideAddButton(false);
    }
  }, [watchPanoramicViewUrl]);

  const getPanoramicViewUrls = (language) => {
    return watchPanoramicViewUrl.map((panoramicViewUrl, index) => {
      const singlePanoramicViewNameField = isEnglish
        ? `panoramicViewUrl.${index}.name`
        : `panoramicViewUrl.${index}.translations.${language}.name`;
      const singlePanoramicViewUrlField = `panoramicViewUrl.${index}.url`;
      const watchSinglePanoramicViewName = watch(singlePanoramicViewNameField);
      const watchSinglePanoramicViewUrl = watch(singlePanoramicViewUrlField);
      const handleNameChange = (value) => {
        setValue(singlePanoramicViewNameField, value, {
          shouldDirty: true,
        });
      };
      const handleUrlChange = (value) => {
        setValue(singlePanoramicViewUrlField, value, { shouldDirty: true });
      };
      return (
        <div className="reward-item-container">
          <InputFieldControl
            title={'Title'}
            rules={
              isEnglish && watchSinglePanoramicViewUrl
                ? {
                    required: 'Please provide name',
                  }
                : { required: false }
            }
            name={singlePanoramicViewNameField}
            value={watchSinglePanoramicViewName || ''}
            setValue={(value) => handleNameChange(value)}
            onFocus={() => {
              onFocus('panoramicViewUrl');
            }}
          />
          <InputFieldControl
            title={'URL'}
            rules={
              isEnglish && watchSinglePanoramicViewName
                ? {
                    required: 'Please provide url',
                  }
                : isEnglish && !watchSinglePanoramicViewName
                ? { required: false }
                : {}
            }
            name={singlePanoramicViewUrlField}
            value={watchSinglePanoramicViewUrl}
            setValue={(value) => handleUrlChange(value)}
            onFocus={() => {
              onFocus('panoramicViewUrl');
            }}
            disabled={isEnglish ? false : true}
          />
          {watchPanoramicViewUrl?.length > 1 ? (
            <div className="custom-delete-btn-container">
              <AuthButton
                children={
                  <Image src={deleteImage} className="custom-delete-btn" />
                }
                customClass="custom-delete-btn"
                action={() => {
                  const tempArray = [].concat(watchPanoramicViewUrl);
                  tempArray.splice(index, 1);
                  setValue('panoramicViewUrl', tempArray, {
                    shouldDirty: true,
                  });
                }}
              />
            </div>
          ) : null}
        </div>
      );
    });
  };

  return (
    <div style={{ marginTop: '-30px' }} className="store-create-step-one">
      {listItem(i18n.t('name', { locale: language }), watchName)}
      {isEnglish
        ? watchDetailPhotos
          ? listMultiImage('Photos', watchDetailPhotos)
          : listItem('Photos', '')
        : null}
      {listItem('Sub-district', watchDistrict)}
      {listItem('Address', watchAddress)}
      {isEnglish ? (
        <>
          {listItem('Latitude', watchLatitude)}
          {listItem('Longitude', watchLongitude)}
        </>
      ) : null}
      <div className="d-flex flex-column">
        <CustomTitleLabel title="Panoramic view URL (Optional)" />
        {getPanoramicViewUrls(language)}
        {hideAddButton ? null : (
          <AuthButton
            children={<Image src={AddButton} className="custom-add-btn" />}
            customClass="custom-add-btn"
            action={() => {
              const tempArray = [].concat(watchPanoramicViewUrl);
              tempArray.push({
                url: '',
                name: '',
                translations: {},
              });
              setValue('panoramicViewUrl', tempArray, {
                shouldDirty: true,
              });
            }}
          />
        )}
      </div>
      {isEnglish ? (
        <>
          {listItem(
            'Customer Service Hotline Number',
            watchCustomerServiceHotlinePhoneNumber,
          )}
          {listItem('Carpark Enquiry Number', watchCarparkEnquiryPhoneNumber)}
          {listItem('Leasing Enquiry Number', watchLeasingEnquiryPhoneNumber)}
        </>
      ) : null}
      {listItem(i18n.t('description', { locale: language }), watchDescription)}
      {verticalListMultiImageWithItem(
        'Facilities',
        watchFacilities,
        '',
        'mall-facilitie-image',
      )}
      { language === LanguageConfig.english && 
        <>
          <SelectMultipleStoreBySearch
            mallId={mallId}
            needLoadMore={true}
            value={watchTransactionStores}
            onChange={(v) => {
              const value = Array.isArray(v) ? v : v ? [v] : [];
              setValue('selectedStores', value, { shouldDirty: true, });
            }}
          />
          <SelectMultipleCampaignsBySearch
            campaignType={'stamp'}
            needLoadMore={true}
            value={watchTransactionStampCampaigns}
            onChange={(v) => {
              const value = Array.isArray(v) ? v : v ? [v] : [];
              setValue('selectedStampCampaigns', value, { shouldDirty: true, });
            }}
          />
          <SelectMultipleCampaignsBySearch
            campaignType={'mission'}
            needLoadMore={true}
            value={watchTransactionMissionCampaigns}
            onChange={(v) => {
              const value = Array.isArray(v) ? v : v ? [v] : [];
              setValue('selectedMissionCampaigns', value, { shouldDirty: true, });
            }}
          />
          <SelectMultipleCampaignsBySearch
            campaignType={'other'}
            needLoadMore={true}
            value={watchTransactionOtherCampaigns}
            onChange={(v) => {
              const value = Array.isArray(v) ? v : v ? [v] : [];
              setValue('selectedOtherCampaigns', value, { shouldDirty: true, });
            }}
          />
          <SelectMultipleCampaignsBySearch
            campaignType={'car_park'}
            needLoadMore={true}
            value={watchTransactionParkingCampaigns}
            onChange={(v) => {
              const value = Array.isArray(v) ? v : v ? [v] : [];
              setValue('selectedParkCampaigns', value, { shouldDirty: true, });
            }}
          />
        </>
      }
    </div>
  );
}

export default CreateMallStepOneContentSection;
