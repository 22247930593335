import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import '../campaign/campaignCreation/PropertiesGeneralSection.scss';
import './RewardSection.scss';
import CustomRadios from '../base/CustomRadios';
import BasePrompt from '../base/prompt/BasePrompt';
import CouponSelectDropdown from '../campaign/campaignCreation/CouponSelectDropdown';
import {
  CustomTitleLabel,
  CustomNumberMutipleInputWithUnit,
} from './CustomBaseComponments';
import { Image } from 'react-bootstrap';
import addImage from '../../assets/images/addx1.png';
import { ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import {
  CampaignType,
  EarningRuleType,
  EarningRuleRewardType,
  COUPON_MODE,
  COUPON_ATTRIBUTE,
} from '../../config/CustomEnums';
import { getCampaignTypeSelectPromptConfig } from '../campaign/campaignCreation/CreateCampaignStepOneConfigs';
import { useCompare } from '../../utils';
import { useHistory, useParams } from 'react-router-dom';
import { PermissionCodes } from '../../config/PermissionCodes';
import InputFieldControl from '../base/InputFieldControl';
import { useFormContext } from 'react-hook-form';

function RewardSection({
  earningRule
}) {
  const { getValues, watch, formState, setValue, setError } =
  useFormContext();
  const errors = formState.errors;
  const fieldError = Object.keys(errors || []).length;
  const [earningRuleType] = getValues(['type']);
  const isNewMember = earningRuleType === EarningRuleType.newMember;

  const watchRewardType = watch('rewardType') || setValue('rewardType', EarningRuleRewardType.coupons, { shouldDirty: true})
  const watchQuantity = watch('quantity')
  const watchPoints = watch('rewardTypeX')
  const watchCoupon = watch('coupons')



  const radioOptions = [
    { label: 'Coupons', value: EarningRuleRewardType.coupons },
    { label: 'Stamp', value: EarningRuleRewardType.stamp },
    { label: 'Badge', value: EarningRuleRewardType.badge },
  ];
  const promptconfig = getCampaignTypeSelectPromptConfig(
    CampaignType.couponsCampaign,
  );

  const [showCreateNewLinkPrompt, setShowCreateNewLinkPrompt] = useState(false);
  const history = useHistory();

  const rewardQuality = () => {
    if (watchRewardType === EarningRuleRewardType.stamp) {
      return 'stamp per action';
    }
    if (watchRewardType === EarningRuleRewardType.badge) {
      return 'badge per action';
    }
    return 'coupons per action';
  };

  const rewardUnit = () => {
    return watchRewardType === EarningRuleRewardType.badge ? "badges" : "stamps"
  }

  return (
    <>
      <label className="create-section-title">REWARD</label>
      <CustomTitleLabel title="Reward type" />
      <div style={{ display: 'flex' }}>
        <CustomRadios
          onChange={(value) => {
            // setRewardType(value);
            setValue('rewardType', value, { shouldDirty: true});
            setValue('quantity', '', { shouldDirty: true });
            if (value === EarningRuleRewardType.coupons) {
              setValue('rewardTypeX', '', { shouldDirty: true });
            } else {
              setValue('coupons', '', { shouldDirty: true });
            }
          }}
          default={watchRewardType}
          options={isNewMember ? [radioOptions[0]] : radioOptions}
        />
      </div>

      {watchRewardType === EarningRuleRewardType.coupons ? (
        <>
          <CouponSelectDropdown
            title={'Coupon set'}
            defaultValue={{
              value: watchCoupon,
              label: watchCoupon?.name,
            }}
            setValue={(item) => {
              console.log('couponChange:', item);
              const coupon = item || {};
              setValue('coupons', coupon, { shouldDirty: true });
            }}
            // addButtonAction={() => setShowCreateNewLinkPrompt(true)}
            isPrompt
            addButtonInfo={{
              requires: PermissionCodes.addCouponTemplate,
              children: <Image src={addImage} />,
              action: () => setShowCreateNewLinkPrompt(true),
              customClass: 'earning-rule-add-new-link-button',
            }}
            defaultFilter={{
              mode: COUPON_MODE.COMMON,
              attribute: COUPON_ATTRIBUTE.E_CERTIFICATE_COUPON
            }}
          />
          <ReactHookFormErrorMessage errors={errors} id="coupons" />
        </>
      ) : null}
      {earningRuleType === EarningRuleType.generalPurchase &&
      watchRewardType !== EarningRuleRewardType.coupons ? (
        <>
          <CustomTitleLabel title={`Reward ${rewardUnit()}`} />
          <label className="tips-message">
            Eg. Customer earns 3 {rewardUnit()} for every 5 dollars spent. HK$ 5 = 3 {rewardUnit()}
          </label>
          <CustomNumberMutipleInputWithUnit
            numberTitle={'HK$'}
            firstDefaultValue={watchPoints}
            setFirstValue={(value) => {
              setValue('rewardTypeX', value, { shouldDirty: true })
            }}
            secondDefaultValue={watchQuantity}
            setSecondValue={(value) => {
              setValue('quantity', value, { shouldDirty: true })
            }}
            per="="
            unit={rewardUnit()}
          />
          {/* <ErrorFieldMessage
            id={`${EarningRuleErrorHandleField.quantity.name}`}
            error={isError(['rewardTypeX', 'quantity'])}
            message={`${EarningRuleErrorHandleField.quantity.message}`}
          /> */}
          <ReactHookFormErrorMessage errors={errors} id='quantity' />
        </>
      ) : (
        <InputFieldControl
          name={'quantity'}
          title={'Quantity'}
          value={watchQuantity}
          setValue={(value) => {
            setValue('quantity', value, { shouldDirty: true });
          }}
          type='number'
          unit={rewardQuality()}
          className='coupon-campaign-section-input'
        />
      )}
      <BasePrompt
        show={showCreateNewLinkPrompt}
        closeAction={() => setShowCreateNewLinkPrompt(false)}
        rightButton={{
          text: promptconfig.button,
          action: () => {
            history.push({
              pathname: '/coupons/create',
              state: {
                from: earningRule?.id ? `/earns/${earningRule?.id}/edit/` : '/earns/create',
                title: 'Continue to Create Earning Rule',
                content: 'You can continue to create the earning rule.',
              },
            });
          },
        }}
        title={promptconfig.title}
        description={
          'You will leave the campaign creation process. After you create a coupon set, you can come back to create a earning rule.'
        }
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  earningRule: state.createEarningRules.earningRule
})

export default connect(mapPropsToState)(RewardSection);
