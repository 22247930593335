import React, { Component } from 'react';
import { connect, useDispatch } from 'react-redux';
import './GenerateCouponView.scss';
import { createAction } from '../../../utils';
import {
  COUPON_CODE_TYPE,
  COUPON_SET_TYPES,
  LanguageConfig,
  APIStatus,
  COUPON_MODE,
} from '../../../config/CustomEnums';
import { withRouter } from 'react-router-dom';
import { DropDownWithDefaultSelect } from '../../base/ActionsDropdown';
import CustomFileUploader from '../../base/CustomFileUploader';
import { CouponURLFragments } from '../CouponUrlConfig';
import BasePrompt from '../../base/prompt/BasePrompt';
import { CustomTitleLabel } from '../../earning/CustomBaseComponments';
import { ImportResource } from '../../../models/DownloadImportModel';
import { useHistory } from 'react-router';
import ErrorFieldMessage from '../../base/ErrorFieldMessage';

const GenerateCouponView = ({
  toSingleCouponList = false,
  generateCoupon,
  couponTemplate,
  useGenerateCouponInfo = false,
  generateCouponInfo,
  importedFile,
}) => {
  const {
    applicableStores,
    selectedBrand: relateBrand,
    formats: couponFormat,
    couponCodeType,
    couponTemplatePk,
    couponTemplateType,
    name: couponTemplateName,
    couponTemplateMode,
  } = useGenerateCouponInfo ? generateCouponInfo : couponTemplate;


  const dispatch = useDispatch();
  const history = useHistory();
  console.log("@87", generateCoupon)

  const onlyForSystemGeneratedBody = (brand) => {
    return (<>
      <div className="second-section-title">Set the quantity of related stores</div>
        <div className="base-prompt-tips">You can set to max 100,000 if there is no specific limit.</div>
        <div className="base-prompt-store-container">
        {brand.stores?.map((item) => {
          return (
            <div className='store-item'>
                <div className="store-name">
                  {`${item?.name || ''} - ${
                    item?.mall ? item?.mall?.name : item?.freshMarket?.name || ''
                  }`}
                </div>
              <div className="store-content">
              <input
                type="number"
                className={`day-input-fields ${
                  generateCouponInfo.errorMessage?.quantity ? 'error-field' : ''
                }`}
                onChange={(e) => {
                  const { value } = e.target;
                  if (parseInt(value) > 100000) {
                    updateGenerateCouponState({ quantity: 100000, id: item.pk });
                  } else {
                    updateGenerateCouponState({ quantity: value, id: item.pk });
                  }
                }}
                value={generateCouponInfo.quantitys?.[`${item.pk}`]?.quantity}
                min={1}
                max={100000}
              />
              <label className="unit-label">numbers of coupons</label>
            </div>
            </div>
          )
        })}
        </div>
      </>)
  }

  const onlyForImportBody = (brand) => {
    if (couponCodeType === COUPON_CODE_TYPE.GENERIC) {
      return onlyForSystemGeneratedBody(brand)
    }
    return (<>
      <div className="second-section-title">Import Coupon QR code  in .csv of related stores</div>
        <div className="base-prompt-tips">
          Please select or drag and drop the .csv files which you want to import.
        </div>
        <div className="base-prompt-store-container">
        {brand.stores?.map((item) => {
          return (
            <div className='store-item'>
              <div className='store-name'>{item.name}</div>
              <div className='store-content'>
                <CustomFileUploader
                  title="Select files"
                  accept=".csv"
                  showFileName={true}
                  id={`${item.pk}`}
                />
              </div>
            </div>
          )
        })}
        </div>
      </>)
  }

  const relateBrandView = (brand) => {
    if (brand) {
      return (
        <div className="second-section">
          <div className="second-title-section">
            <div className="second-section-title">{brand.name ? 'Related brand' : 'Others (stores without brand)'}</div>
          </div>
          <div className="second-section-content">
            {brand.name}
          </div>
          { couponTemplateType === COUPON_SET_TYPES.SYSTEM_GENERATED ? onlyForSystemGeneratedBody(brand): onlyForImportBody(brand) }
        </div>
      );
    }
  };
  console.log("@23", generateCouponInfo)
  // const applicableStoreView = () => {
  //   console.log("58", applicableStores?.length)
  //   if (
  //     applicableStores?.length > 0
  //   ) {
  //     const storeList = applicableStores.map((store) => {
  //       return {
  //         name: store.storeName,
  //         action: () => updateGenerateCouponState({ currentSelectedStore: store })
  //       };
  //     });
  //     return (
  //       <div className="second-section">
  //         <div className="second-title-section">
  //           <div className="second-section-title">Applicable stores</div>
  //         </div>
  //         <DropDownWithDefaultSelect
  //           actions={storeList}
  //           className={generateCouponInfo.errorMessage?.store ? 'error-field' : null}
  //         />
  //         <ErrorFieldMessage
  //           error={generateCouponInfo.errorMessage?.store?.length > 0}
  //           message={generateCouponInfo.errorMessage?.store}
  //         />
  //       </div>
  //     );
  //   }
  // };

  const updateGenerateCouponState = (data) => {
    dispatch(
      createAction('createCoupon/updateGenerateCouponState')({...data}),
    );
  }

  const modalOnHide = () => (
    updateGenerateCouponState({generateCoupon: false,})
  )
    
    // dispatch(
    //   createAction('createCoupon/updateGenerateCouponState')({
    //     generateCoupon: false,
    //     // data: getValues(),
    //     // notSaveToSession: true,
    //   }),
    // );
  // };

  const generateCouponButtonClicked = () => {
    console.log("@126", "generate")
    const successAction = () => {
      if (
        history.location.pathname.includes('edit') ||
        toSingleCouponList
      ) {
        history.push({
          pathname: `/coupons/${couponTemplatePk}`,
          hash: CouponURLFragments.list,
        });
      } 
      dispatch({
        type: 'createCoupon/updateState',
        payload: {
          generateCouponStatus: APIStatus.success,
          couponGenerated: true,
        },
      });
      dispatch(createAction('downloadAndImport/showTasks')({}));
    };
    const importFileGenerated = () => {
      if (!importedFile?.common?.file) {
        errors['uploadfile'] = "Please upload file"
      }
      if (Object.getOwnPropertyNames(errors).length) {
        updateGenerateCouponState({
          errorMessage: errors,
        })
        return;
      }
      dispatch(
        createAction('downloadAndImport/importFile')({
          from: ImportResource.coupon,
          file: importedFile?.common?.file,
          related: {
            templateId: couponTemplatePk,
          },
          successAction,
        }),
      );
      modalOnHide();
    }
    let errors = {}
    // if (applicableStores?.length > 0 && generateCouponInfo.currentSelectedStore?.length === 0) {
    //   errors['store'] = "Please select application store"
    // }
    if (couponTemplateMode === COUPON_MODE.COMMON) {
    if (
      couponTemplateType === COUPON_SET_TYPES.SYSTEM_GENERATED ||
      (couponTemplateType ===
        COUPON_SET_TYPES.IMPORT_EXISTING_COUPON &&
        couponCodeType === COUPON_CODE_TYPE.GENERIC)
    ) {
      let from = ImportResource.generateCodeCoupon;
      if (
        couponTemplateType ===
          COUPON_SET_TYPES.IMPORT_EXISTING_COUPON &&
        couponCodeType === COUPON_CODE_TYPE.GENERIC
      ) {
        from = ImportResource.generateGenericCoupon;
      }
      if (!generateCouponInfo.quantity || generateCouponInfo.quantity === '0') {
        errors["quantity"] = 'This field is required.'
      } else if (!/^\+?\d+$/.test(generateCouponInfo.quantity) || parseInt(generateCouponInfo.quantity) < 0) {
        errors["quantity"] = 'Please enter valid quantity.'
      }
      if (Object.getOwnPropertyNames(errors).length) {
        updateGenerateCouponState({
          errorMessage: errors,
        })
        return;
      }
      dispatch(
        createAction('downloadAndImport/importFile')({
          from,
          related: {
            templateId: couponTemplatePk,
            couponQuantity: parseInt(generateCouponInfo.quantity),
            // storeId: generateCouponInfo.currentSelectedStore?.storePK
          },
          successAction,
        }),
      );
      modalOnHide();
    } else {
      importFileGenerated()
    }
  } else {
    if (
      couponTemplateType === COUPON_SET_TYPES.SYSTEM_GENERATED ||
      (couponTemplateType ===
        COUPON_SET_TYPES.IMPORT_EXISTING_COUPON &&
        couponCodeType === COUPON_CODE_TYPE.GENERIC)
    ) {
      let from = ImportResource.generateCodeCoupon;
      if (
        couponTemplateType ===
          COUPON_SET_TYPES.IMPORT_EXISTING_COUPON &&
        couponCodeType === COUPON_CODE_TYPE.GENERIC
      ) {
        from = ImportResource.generateGenericCoupon;
      }
      dispatch(
        createAction('downloadAndImport/importFile')({
          from,
          related: {
            templateId: couponTemplatePk,
            storeCouponInfo: Object.values(generateCouponInfo?.quantitys || {})
          },
          successAction,
        }),
      );
      modalOnHide();
    } else {
      importFileGenerated()
    }
  }

    // this.props.dispatch(
    //   createAction('createCoupon/updateCouponTemplate')({
    //     generateCoupon: false,
    //     notSaveToSession: true,
    //   }),
    // );
  };
  const systemGeneratedBody = () => {
    return (
      <div className="second-section quantity_section">
        <div className="second-title-section">
          <div className="second-section-title">Quantity</div>
        </div>
        <div className="d-flex">
          <input
            type="number"
            className={`day-input-fields ${
              generateCouponInfo.errorMessage?.quantity ? 'error-field' : ''
            }`}
            onChange={(e) => {
              const { value } = e.target;
              if (parseInt(value) > 100000) {
                updateGenerateCouponState({ quantity: 100000 });
              } else {
                updateGenerateCouponState({ quantity: value });
              }
            }}
            value={generateCouponInfo.quantity}
            min={1}
            max={100000}
          />
          <label className="days-label">numbers of coupons</label>
        </div>
        <ErrorFieldMessage
          error={generateCouponInfo.errorMessage?.quantity?.length > 0}
          message={generateCouponInfo.errorMessage?.quantity}
        />
        <div className="max-number-label">
          You can set to max 100,000 if there is no specific limit.
        </div>
      </div>
    );
  }

  const importBody = () => {
    if (couponCodeType === COUPON_CODE_TYPE.GENERIC) {
      return systemGeneratedBody();
    }
    return (
      <div class="d-flex flex-column">
        <CustomTitleLabel title="Import code in .csv or Import QR code in .csv." />
        <label className="create-message-suggest">
          Please select or drag and drop the .csv files which you want to
          import.
        </label>
        <CustomFileUploader
          title="Select files"
          accept=".csv"
          showFileName={true}
          id="common"
        />
        <ErrorFieldMessage
          error={generateCouponInfo.errorMessage?.uploadfile?.length > 0}
          message={generateCouponInfo.errorMessage?.uploadfile}
        />
      </div>
    );
  }
  const otherBody = () => {
    return (
      <>
        <div>

          </div>
        { couponTemplateMode === COUPON_MODE.COMMON ? 
          couponTemplateType === COUPON_SET_TYPES.SYSTEM_GENERATED
          ? systemGeneratedBody()
          : importBody()
          : couponCodeType === COUPON_CODE_TYPE.UNIQUE ? importBody() : relateBrand?.map((item) => {
            return <>
              {relateBrandView(item)}
            </>
          })
        }
        {/* // {relateBrandView()}
        // {applicableStoreView()}
        // {couponTemplateType === COUPON_SET_TYPES.SYSTEM_GENERATED
        //   ? systemGeneratedBody()
        //   : importBody()} */}
      </>
    );
  };
  const method = 
    couponTemplateType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON ? 'Import' : 'Generate' 
  

    return (
      <BasePrompt
        show={generateCoupon}
        closeAction={() => {
          modalOnHide();
          dispatch({
            type: 'uploadFiles/updateState',
            payload: { importedFile: {} },
          });
        }}
        rightButton={{
          text: `${method} Coupons`,
          action: generateCouponButtonClicked,
          // disabled:
          //   this.props.couponFormat === COUPON_TEMPLATE_TYPE.couponCodeImage &&
          //   this.props.uploading,
          // custom: <CustomFileUploader title="Import .zip" accept=".zip" />,
        }}
        title={`${method} Single Coupons`}
        tips={'If the coupon set is in inactive status, all the new generated or imported coupon will also be in inactive status.'}
        description={`${method} Single Coupons by using the coupon set "${
          couponTemplateName
        }"

        `}
        otherBodyClass={"generate-prompt-other-body"}
        otherBody={otherBody}
      />
    );

}

const mapPropsToState = (state) => ({
  generateCoupon: state.createCoupon.generateCoupon?.generateCoupon,
  couponTemplate: {
    applicableStores: state.createCoupon.couponTemplate?.selectedStores || [],
    selectedBrand: state.createCoupon.couponTemplate?.selectedBrand,
    formats: state.createCoupon.couponTemplate?.couponFormat,
    couponCodeType: state.createCoupon.couponTemplate?.couponCodeType,
    couponTemplatePk: state.createCoupon.couponTemplate?.pk,
    couponRedemptionMethod: state.createCoupon.couponTemplate?.redemptionMethod,
    couponTemplateType: state.createCoupon.couponTemplate?.couponSetType,
    couponTemplateMode: state.createCoupon.couponTemplate?.couponMode,
    name: state.createCoupon.couponTemplate?.name || '',
  },
  generateCouponInfo: state.createCoupon.generateCoupon || {},
  importedFile: state.uploadFiles.importedFile || null,
})
export default connect(mapPropsToState)(GenerateCouponView);
