import React from 'react';
import { QrReader } from 'fork-react-qr-reader';
import { useDispatch } from 'react-redux';
import { decode } from 'base-64';
import './ScannerCoupon.scss';
import closeIcon from '../../assets/images/close.png';
import { ImageButton } from '../base/CustomImageButton';

const ScannerCoupon = ({ show, handleClose, setCouponSerialNumber, handleLoading }) => {
  const dispatch = useDispatch();

  const checkQrCode = (scannerString) => {
    let object = {};
    try {
      object = JSON.parse(scannerString);
    } catch (e) {
      console.log('scannerString is not valid JSON');
    }
    if (!object || !object.code) {
      return '';
    }
    const decodedCode = decode(object.code);
    let decodedObject = decodedCode;
    try {
      decodedObject = JSON.parse(decodedCode);
    } catch (e) {
      console.log('decodedCode is not valid JSON');
    }
    const date = decodedObject?.date;
    if (!date || date < Date.now() - 5 * 60 * 1000) {
      return '';
    }
    if (decodedObject?.couponSerialNumber) {
      return decodedObject.couponSerialNumber;
    }
    return decodedCode;
  };

  return (
    <>
      <div className="scanner-modal" style={{ display: show ? 'block' : 'none' }}>
        <QrReader
          constraints={{
            facingMode: 'environment',
          }}
          videoId={'video'}
          scanDelay={1500}
          videoStyle={{
            position: 'fixed',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            display: show ? 'block' : 'none',
            objectFit: 'cover',
          }}
          onResult={(result, error) => {
            console.log('@54 scannerData: ', result?.text, error?.message);
            const codeString = result?.text || '';
            if (!!codeString) {
              const code = checkQrCode(codeString);
              console.log('@63', code, show);
              if (show && code) {
                setCouponSerialNumber(code);
                handleClose();
                handleLoading();
              }
            }
          }}
        />
        <div className="scanner-mask-container">
          <div className="scanner-vertical-mask">
            <ImageButton
              image={closeIcon}
              action={() => {
                handleClose();
              }}
            />
            <div className='scanner-text-container'>
              <label className='scanner-title'>掃描客戶優惠券二維碼</label>
              <label className='scanner-subtitle'>對齊框架內的二維碼進行掃描。</label>
              <label className='scanner-subtitle'>掃描......</label>
            </div>
          </div>
          <div className="scanner-container">
            <div className="scanner-horizontal-mask"></div>
            <div className="scanner-box"></div>
            <div className="scanner-horizontal-mask"></div>
          </div>
          <div className="scanner-vertical-mask"></div>
        </div>
      </div>
    </>
  );
};

export default ScannerCoupon;
