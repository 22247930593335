import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './CustomDateTimeSelect.scss';
import DatePicker from 'react-datepicker';
import DateIcon from '../../assets/images/date_icon.svg';
import DropdownNarrow from '../../assets/images/drop_down_not_show_narrow.svg';
import {
  formatDate,
  getCampignPeriodDate,
  getCampignPeriodFormatedTime,
  getCurrentDay,
} from '../../utils/TimeFormatUtil';
import { createNumberArray } from '../../utils';
import { Dropdown, Image } from 'react-bootstrap';
import { AmOrPm } from '../../config/CustomEnums';
const DropDownType = { hour: 'hour', minutes: 'minutes', amAndPm: 'amAndPm' };
const DropDownClasses = {
  [DropDownType.hour]: 'custom-hour-select',
  [DropDownType.minutes]: 'custom-minutes-select',
  [DropDownType.amAndPm]: 'custom-am-pm-select',
};

function CustomDateTimeSelect(props) {
  const maxDate = props.maxDate;
  const minDate = props.minDate;
  const disabled = props.disabled || false;
  const onTimeChange = props.onTimeChange || (() => {});
  const defaultTime = props.defaultTime || getCurrentDay();
  const hoursList = createNumberArray(11).map((i) => i + 1);
  hoursList.unshift(12);
  const minutesList = createNumberArray(60).filter((i) => i % 5 === 0);
  const amWithPm = [AmOrPm.am, AmOrPm.pm];
  const error = props.error;
  const {
    defaultHour,
    defaultMinute,
    defaultAmOrPm,
  } = getCampignPeriodFormatedTime(defaultTime);
  const [selectedHour, setSelectedHour] = useState(defaultHour);
  const [selectedMinute, setSelectedMinute] = useState(defaultMinute);
  const [selectedAMOrPM, setSelectedAMOrPM] = useState(defaultAmOrPm);
  const [selectedDate, setSelectedDate] = useState(defaultTime);
  const [timeError, setTimeError] = useState(error);
  const handleChange = (date) => {
    setSelectedDate(date);
  };
  const locale = props.locale;
  const customerDateFormat = props.dateFormat;

  const getDefaultDate = () => {
    if (typeof selectedDate === 'string') {
      return new Date(selectedDate);
    }
    return selectedDate;
  };

  useEffect(() => {
    if (defaultHour !== selectedHour) {
      setSelectedHour(defaultHour);
    };
    if (defaultMinute !== selectedMinute) {
      setSelectedMinute(defaultMinute);
    };
    if (defaultAmOrPm !== selectedAMOrPM) {
      setSelectedAMOrPM(defaultAmOrPm);
    };
  }, [defaultHour, defaultMinute, defaultAmOrPm]);

  useEffect(() => {
    setTimeError(error);
    const date = new Date(selectedDate);
    let aditionalHours = selectedHour;
    if (selectedHour === '12' || selectedHour === 12) {
      aditionalHours = 0;
    }
    if (selectedAMOrPM === AmOrPm.pm) {
      if (
        typeof aditionalHours === 'string' ||
        aditionalHours instanceof String
      ) {
        aditionalHours = parseInt(aditionalHours) + 12;
      } else {
        aditionalHours = aditionalHours + 12;
      }
    }
    date.setHours(aditionalHours);
    date.setMinutes(selectedMinute);
    date.setSeconds(0);
    date.setMilliseconds(0);
    onTimeChange(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, selectedAMOrPM, selectedDate, selectedHour, selectedMinute]);

  const CustomInputView = ({ value, onClick, dateFormat }) => {
    return (
      <button
        disabled={disabled}
        onClick={onClick}
        className={`custom-date-select-container select-time-text ${
          timeError ? 'error-field' : ''
        }`}
      >
        {dateFormat ? formatDate(value, dateFormat) : getCampignPeriodDate(value)}
        <Image src={DateIcon} className="custom-date-select-icon" />
      </button>
    );
  };

  const getDropDownTitles = (type) => {
    let result = selectedAMOrPM;
    if (type === DropDownType.hour) {
      result = selectedHour;
    }
    if (type === DropDownType.minutes) {
      result = selectedMinute;
    }
    return isNaN(result) || type === DropDownType.hour
      ? result
      : ('0' + result).slice(-2);
  };

  const dorpDownSelect = (type, value) => {
    if (type === DropDownType.hour) {
      setSelectedHour(value);
      return;
    }
    if (type === DropDownType.minutes) {
      setSelectedMinute(value);
      return;
    }
    setSelectedAMOrPM(value);
  };

  const customDropdownSelected = (options, type) => {
    return (
      <>
        <Dropdown className={`${DropDownClasses[type]}`}>
          <Dropdown.Toggle
            variant="link"
            disabled={disabled}
            className={`custom-hour-minutes-control dropdown-menu-sizing select-time-text ${
              timeError ? 'error-field' : ''
            }`}
          >
            {getDropDownTitles(type)}
            <Image src={DropdownNarrow} className="custom-date-select-icon" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="custom-hour-minutes-select-dropdown-menu time-select-dropdown-menu">
            {options.map((item, index) => (
              <Dropdown.Item
                className="select-time-text dropdown-item-sizing"
                key={`${index}-${item}`}
                value={item}
                onClick={() => dorpDownSelect(type, item)}
              >
                {isNaN(item) || type === DropDownType.hour
                  ? item
                  : ('0' + item).slice(-2)}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  const hoursSelect = () =>
    customDropdownSelected(hoursList, DropDownType.hour);

  const minutesSelect = () =>
    customDropdownSelected(minutesList, DropDownType.minutes);

  const amAndPmSelect = () =>
    customDropdownSelected(amWithPm, DropDownType.amAndPm);
  return (
    <div className="custom-date-time-select-container">
      <DatePicker
        selected={getDefaultDate()}
        onChange={(date) => handleChange(date)}
        maxDate={maxDate}
        minDate={minDate}
        customInput={<CustomInputView dateFormat={customerDateFormat} />}
        locale={locale}
        showMonthDropdown
      />
      {hoursSelect()}
      <label className="custom-hour-minutes-splite">:</label>
      {minutesSelect()}
      {amAndPmSelect()}
    </div>
  );
}

export default CustomDateTimeSelect;
