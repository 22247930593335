import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Collapse, Image } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { createAction } from '../../../utils';
import queryString from 'query-string';
import './CreateCampaignStepOne.scss';
import Loading from '../../../components/base/Loading';
import BasePrompt from '../../../components/base/prompt/BasePrompt';
import ContentSections from '../../../components/base/ContentSections';
import { OnlyContinueButton } from '../../../components/base/BottomStepComponent';
import { ReactHookFormErrorMessage } from '../../../components/base/ErrorFieldMessage';
import ItemTypeSet, { MIN_WIDTH, SPACE_BETWEEN } from '../../../components/base/ItemTypeSet';
import { validate } from '../../../containers/engagement/campaign/CreateCampaignValidate';
import CouponSelectDropdown from './CouponSelectDropdown';
import EarningRulesSelectDropdown from './EarningRulesSelectDropdown';
import CampaignLinkPrompt from './CampaignLinkPrompt';
import {
  getCampaignTypeSelectPromptConfig,
  CampaignTypesConfig,
  DisplayInConfig,
  getCampaignTypeConfig,
} from './CreateCampaignStepOneConfigs';
import { ContinueCreate } from '../CampaignUrlConfig';
import {
  getScrollbarWidth,
  useMainContentWidth,
} from '../../../utils/ScreenUtil';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { CampaignType, APIStatus, COUPON_MODE, ApprovalStatus } from '../../../config/CustomEnums';
import addImage from '../../../assets/images/addx1.png';
import {
  isShowEarningRuleRelated,
  isShowCouponRelated,
  isShowFortuneBagRelated,
  fieldTransfer,
} from '../../../models/CreateCampaignModel';
import BaseMultipleSelectorDropDown from '../../base/BaseMultipleSelectorDropDown';

function CreateCampaignStepOne({
  status,
  promptShowed,
  isPublished,
  couponTemplateList,
  resume,
}) {
  const mainContentWidth = useMainContentWidth();
  const fullContainerWith = mainContentWidth - 80 - 60 - getScrollbarWidth();

  const [showCreateNewLinkPrompt, setShowCreateNewLinkPrompt] = useState(false);
  const [showLinkPrompt, setShowLinkPrompt] = useState(false);
  const [linkedCouponPK, setLinkedCouponPK] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    getValues,
    watch,
    setValue,
    control,
    formState,
    clearErrors,
    setError,
  } = useFormContext();
  const { errors } = formState;

  const [approvalStatus, isTempFortuneBag] = getValues(['approvalStatus', 'isTempFortuneBag']);
  const watchDisplayIn = watch('displayIn');
  const watchCampaignType = watch('campaignType');
  const watchCoupon = watch('linkedCoupon') || {};
  const watchSelectedCouponSets = watch('selectedCouponSets') || [];
  const watchEarningRules = watch('linkedEarningRules');

  const isCouponRelated = isShowCouponRelated(watchCampaignType);
  const isEarningRuleRelated = isShowEarningRuleRelated(watchCampaignType);
  const isFortuneBagRelated = isShowFortuneBagRelated(watchCampaignType)

  const selectRef = useRef('linkDropdown');
  // const [createNewLinkTitle, setCreateNewLinkTitle] = useState();

  const promptconfig = getCampaignTypeSelectPromptConfig(watchCampaignType);
  const search = queryString.parse(history.location.search);
  const isEdit = !!(history.location.pathname.includes('edit'));

  const getLinkCouponDetail = ({couponId, campaignType, displayIn}) => {
    if (!isEdit && watchCoupon?.pk !== linkedCouponPK && !resume) {
      dispatch({
        type: `createCampaign/getAndLinkCouponDetail`,
        payload: {
          couponId: couponId,
          campaignType: campaignType,
          displayIn: displayIn,
          afterAction: (data) => {
            setLinkedCouponPK(data.linkedCoupon.pk);
          },
        }
      });
    }
  }

  useEffect(() => {
    if (history.location.search && !watchCampaignType) {
      const type = search.type;
      const id = search.id;
      const actionName = type === CampaignType.couponsCampaign
        ? 'getAndLinkCouponDetail'
        : 'getAndLinkEarningRuleDetail';
      console.log("@90")
      if (actionName === 'getAndLinkCouponDetail') {
        getLinkCouponDetail({
          couponId: id,
          campaignType: type,
        });
      } else {
        dispatch({
          type: `createCampaign/${actionName}`,
          payload: {
            couponId: id,
            earningRuleId: id,
            afterAction: (data) => {
              setValue('campaignType', type);
              setValue('linkedEarningRules', data.linkedEarningRules);
              // goToNextStep(true);
            }
          }
        });
      }
    } else {
      getLinkCouponDetail({
        couponId: watchCoupon.pk,
        campaignType: watchCampaignType,
        displayIn: watchDisplayIn,
      });
    }
  }, [watchCoupon])
  // const setCoupon = (value) => {
  //   dispatch(
  //     createAction('createCampaign/getAndLinkCouponDetail')({
  //       couponId: value?.pk,
  //       name: value.name,
  //     }),
  //   );
  // };
  // useEffect(() => {
  //   if (watchCoupon?.pk) {
  //     dispatch(
  //       createAction('createCampaign/getAndLinkCouponDetail')({
  //         couponId: watchCoupon.pk,
  //         couponGetted: (coupon) => setValue('linkedCoupon', coupon),
  //       }),
  //     );
  //   }
  // }, [watchCoupon?.pk]);

  const couponLinkSet = (
    <Controller
      control={control}
      name="linkedCoupon"
      render={() => (
        <>
          <div className="step-link-coupon-area-select">
            <CouponSelectDropdown
              title={'Link to coupon set'}
              defaultValue={{
                value: watchCoupon,
                label: watchCoupon?.name,
              }}
              setValue={(item) => {
                console.log('couponChange:', item);
                const coupon = item || {};
                setValue('linkedCoupon', coupon, { shouldDirty: true });
              }}
              // addButtonAction={() => setShowCreateNewLinkPrompt(true)}
              addButtonInfo={{
                requires: PermissionCodes.changeCouponTemplate,
                children: <Image src={addImage} />,
                action: () => setShowCreateNewLinkPrompt(true),
                customClass: 'earning-rule-add-new-link-button',
              }}
              defaultFilter={{
                status: true,
                expired: 'false',
              }}
            />
          </div>
          <ReactHookFormErrorMessage errors={errors} id="linkedCoupon" />
        </>
      )}
    />
  );

  const earningRuleLinkSet = (
    <Controller
      control={control}
      name="linkedEarningRules"
      render={() => (
        <>
          <div className="step-link-coupon-area-select">
            <EarningRulesSelectDropdown
              title={'Link to earning rule'}
              defaultValue={{
                value: watchEarningRules,
                label: watchEarningRules?.name,
              }}
              setValue={(item) => {
                console.log('earning rule Change:', item);
                const coupon = item || {};
                setValue('linkedEarningRules', coupon, { shouldDirty: true });
              }}
              // addButtonAction={() => setShowCreateNewLinkPrompt(true)}
              addButtonInfo={{
                requires: PermissionCodes.changeEarningRule,
                children: <Image src={addImage} />,
                action: () => setShowCreateNewLinkPrompt(true),
                customClass: 'earning-rule-add-new-link-button',
              }}
              defaultFilter={{
                campaignDisplayIn: watchDisplayIn,
              }}
            />
          </div>
          <ReactHookFormErrorMessage errors={errors} id="linkedEarningRules" />
        </>
      )}
    />
  );

  const couponSelectedSets = (
    <Controller
      control={control}
      name="selectedCouponSets"
      render={() => (
        <>
          <div className="step-link-coupon-area-select">
          <BaseMultipleSelectorDropDown
            disabled={ approvalStatus === ApprovalStatus.published || isTempFortuneBag }
            filterTitle={'Link to coupon sets'}
            summaryTitle={'coupon sets'}
            placeholder='Search by coupon set ID, name'
            source={couponTemplateList}
            value={watchSelectedCouponSets}
            onChange={(v) => {
              if (v?.length > 0) {
                v.sort(function(a,b){return a?.value?.pk-b?.value?.pk});
              }
              const value = Array.isArray(v) ? v : v ? [v] : [];
              setValue('selectedCouponSets', value, { shouldDirty: true });
            }}
            hasMarginTop={false}
            loadMoreAction={'couponList/getCurrentPageTemplateList'}
            filterAction={'couponList/getCurrentPageTemplateList'}
            defaultFilter={{
              isFilterNameOrId: true,
              expired: 'false',
              status: true,
              mode: COUPON_MODE.COMMON,
              isSimpleList: true
            }}
            addButtonInfo={{
              requires: PermissionCodes.changeCouponTemplate,
              children: <Image src={addImage} />,
              action: () => setShowCreateNewLinkPrompt(true),
              customClass: 'earning-rule-add-new-link-button',
            }}
          />
          </div>
          <ReactHookFormErrorMessage errors={errors} id="couponSets" />
        </>
      )}
    />
  )

  // const linkSet = () => {
  //   const linkFiledShow =
  //     !campaignType || campaignType === CampaignType.generalMessageCampaign;
  //   return (
  //     <Collapse in={!linkFiledShow}>
  //       <div className="step-link-coupon-area-select" ref={selectRef}>
  //         {campaignType === CampaignType.couponsCampaign ? (
  //           <CouponSelectDropdown
  //             title={'Link to coupon set'}
  //             defaultValue={{
  //               value: linkedCoupon,
  //               label: linkedCoupon?.name,
  //             }}
  //             setValue={setCoupon}
  //             // addButtonAction={() => setShowCreateNewLinkPrompt(true)}
  //             addButtonInfo={{
  //               requires: PermissionCodes.changeCouponTemplate,
  //               children: <Image src={addImage} />,
  //               action: () => setShowCreateNewLinkPrompt(true),
  //               customClass: 'earning-rule-add-new-link-button',
  //             }}
  //           />
  //         ) : (
  //           <EarningRulesSelectDropdown
  //             title={'Link to earning rule'}
  //             defaultValue={{
  //               value: linkedEarningRules,
  //               label: linkedEarningRules?.name,
  //             }}
  //             // addButtonAction={() => setShowCreateNewLinkPrompt(true)}
  //             addButtonInfo={{
  //               requires: PermissionCodes.changeEarningRule,
  //               children: <Image src={addImage} />,
  //               action: () => setShowCreateNewLinkPrompt(true),
  //               customClass: 'earning-rule-add-new-link-button',
  //             }}
  //           />
  //         )}
  //       </div>
  //     </Collapse>
  //   );
  // };

  const showPrompt = () => {
    if (promptShowed) {
      return;
    }
    if (!showLinkPrompt) {
      return;
    }
    return (
      <CampaignLinkPrompt
        handleContinue={() => {
          dispatch({
            type: 'createCampaign/updateState',
            payload: { promptShowed: true },
          });
          dispatch({
            type: 'createCampaign/stepChange',
            payload: {
              step: 0,
              isValid: true
            }
          });
        }}
        closePrompt={() => {
          dispatch({
            type: 'createCampaign/updateState',
            payload: { promptShowed: true },
          });
          if (!watchCoupon && !watchEarningRules) {
            window.scrollTo(0, selectRef.current.offsetTop);
          }
        }}
        campaignType={watchCampaignType}
        displayIn={watchDisplayIn}
        linkedEarningRules={watchEarningRules}
        linkedCoupon={watchCoupon}
        couponTemplateList={couponTemplateList}
      />
    );
  };

  // const campaignTypeSelectArea = () => {
  //   return (
  //     <div
  //       className="step-type-area campaign-step-one-bottom"
  //       style={{
  //         justifyContent:
  //           fullContainerWith < (MIN_WIDTH + SPACE_BETWEEN) * 2
  //             ? 'center'
  //             : 'flex-start',
  //       }}
  //     >
  //       {CampaignTypesConfig.map((typeItem) => (
  //         <ItemTypeSet
  //           key={`${typeItem.id}-${typeItem.description}`}
  //           item={typeItem}
  //           selected={campaignType}
  //           onClick={(id) => {
  //             dispatch({
  //               type: 'createCampaign/typeChange',
  //               payload: { campaignType: id },
  //             });
  //             if (id !== CampaignType.generalMessageCampaign) {
  //               if (!promptShowed) {
  //                 setShowLinkPrompt(true);
  //               }
  //             }
  //           }}
  //         />
  //       ))}
  //     </div>
  //   );
  // };

  const campaignTypeSelectArea = (
    <Controller
      control={control}
      name="campaignType"
      render={() => (
      <div
        className="step-type-area campaign-step-one-bottom"
        style={{
          justifyContent:
            fullContainerWith < (MIN_WIDTH + SPACE_BETWEEN) * 2
              ? 'center'
              : 'flex-start',
        }}
      >
        {getCampaignTypeConfig(watchDisplayIn).map((typeItem) => (
          <ItemTypeSet
            key={`${typeItem.id}-${typeItem.description}`}
            item={typeItem}
            selected={watchCampaignType}
            disable={isPublished}
            onClick={(id) => {
              if (id === watchCampaignType) {
                return;
              }
              clearErrors();
              setValue('campaignType', id, { shouldDirty: true });
              dispatch({
                type: 'createCampaign/typeChange',
                payload: { campaignType: id },
              });
              if (id !== CampaignType.generalMessageCampaign && id !== CampaignType.htmlCampaign) {
                if (!promptShowed) {
                  setShowLinkPrompt(true);
                }
              }
            }}
          />
        ))}
        </div>
      )}
    />
  )
  const displayInSelectArea = (
    <Controller
      control={control}
      name="displayIn"
      render={() => (
      <div
        className="step-type-area campaign-step-one-bottom"
        style={{
          justifyContent:
            fullContainerWith < (MIN_WIDTH + SPACE_BETWEEN) * 2
              ? 'center'
              : 'flex-start',
        }}
      >
        {DisplayInConfig.map((typeItem) => (
          <ItemTypeSet
            key={`${typeItem.id}-${typeItem.description}`}
            item={typeItem}
            selected={watchDisplayIn}
            disable={isPublished}
            className={"no-image"}
            onClick={(id) => {
              if (id === watchDisplayIn) {
                return;
              }
              clearErrors();
              setValue('displayIn', id, { shouldDirty: true });
              if (!getCampaignTypeConfig(id).map((item) => item.id).includes(watchCampaignType)) {
                setValue('campaignType', null, { shouldDirty: true });
              }
            }}
          />
        ))}
        </div>
      )}
    />
  )

  const sections = [
    <>
      <div>
      <label className="step-one-title">Display in</label>
      {displayInSelectArea}
      </div>
    </>,
    watchDisplayIn ? <>
      {status === APIStatus.calling ? (
        <Loading />
      ) : (
        <div>
          <label className="step-one-title">Campaign Type</label>
          {campaignTypeSelectArea}
          {isCouponRelated ? couponLinkSet : null}
          {isEarningRuleRelated ? earningRuleLinkSet : null}
          {showPrompt()}
          {isFortuneBagRelated ? couponSelectedSets : null}
          <BasePrompt
            show={showCreateNewLinkPrompt}
            closeAction={() => setShowCreateNewLinkPrompt(false)}
            rightButton={{
              text: promptconfig.button,
              action: () => {
                const pathname =
                  watchCampaignType === CampaignType.couponsCampaign || watchCampaignType === CampaignType.fortuneBag
                    ? '/coupons/create'
                    : '/earns/create';
                history.push({
                  pathname: pathname,
                  state: { from:
                    {...history.location,
                      state: {
                        ...history.location?.state,
                        resume: true
                      }}, ...ContinueCreate },
                });
              },
            }}
            title={promptconfig.title}
            description={promptconfig.message}
          />
        </div>   
      )}
    </> : null,
  ];

  const continueAction = () =>
    dispatch({ type: 'createCampaign/stepChange', payload: { step: 0 } });

  const goToNextStep = (assignRemain) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 0,
      isBack: false,
    });
    // setValue('assignRemain', assignRemain);
    // if (isCouponRelated || isEarningRuleRelated) {
    //   fieldTransfer({ setValue, getValues });
    // }
    dispatch({
      type: 'createCampaign/stepChange',
      payload: {
        step: 0,
        isValid,
      },
    });
  };

  return (
    <>
      <ContentSections sections={sections} hidePreview={true} />
      <OnlyContinueButton
        continueAction={async () => {
          goToNextStep(true);
        }}
        disabledContinue={
          !watchCampaignType ||
          (watchCampaignType === CampaignType.couponsCampaign &&
            !watchCoupon?.pk) ||
          (watchCampaignType === CampaignType.earningRulesCampaign &&
            !watchEarningRules?.pk) ||
          (watchCampaignType === CampaignType.fortuneBag &&
            !watchSelectedCouponSets?.length)
        }
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  status: state.createCampaign.createStatus,
  promptShowed: state.createCampaign.promptShowed,
  isPublished: state.createCampaign.campaign.isPublished,
  couponTemplateList : state.couponList.couponTemplateList,
})

export default connect(mapPropsToState)(CreateCampaignStepOne);
