import React from 'react';
import { useFormContext } from 'react-hook-form';
import i18n from '../../I18n';
import { LanguageConfig } from '../../config/CustomEnums';
import { AppVersionErrorHandleFields } from '../../config/ErrorHandle';
import InputFieldControl from '../base/InputFieldControl';

const AppVersionSection = () => {
  const { watch, setValue } = useFormContext();
  const iOSVersionField = 'iosAppVersionName';
  const androidVersionVersionField = 'androidAppVersionName';
  const watchIosVersion = watch(iOSVersionField);
  const watchAndroidVersion = watch(androidVersionVersionField);

  return (
    <>
    <div className="create-section-title">
      {i18n.t('general', { locale: LanguageConfig.english })}
    </div>

    <InputFieldControl
      name={iOSVersionField}
      title={'iOS version'}
      rules={{ required: AppVersionErrorHandleFields.iOSVersion.message }}
      value={watchIosVersion}
      setValue={(value) => {
        setValue(iOSVersionField, value, { shouldDirty: true });
      }}
    />

    <InputFieldControl
      name={androidVersionVersionField}
      title={'Android version'}
      rules={{ required: AppVersionErrorHandleFields.androidVersion.message }}
      value={watchAndroidVersion}
      setValue={(value) => {
        setValue(androidVersionVersionField, value, { shouldDirty: true });
      }}
    />
  </>
  );
};

export default AppVersionSection;
