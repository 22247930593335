import React, { useState } from 'react';
import './RejectPrompt.scss';
import BasePrompt from '../../base/prompt/BasePrompt';
import { CustomTitleLabel } from '../../earning/CustomBaseComponments';
import CustomEditor from '../../base/CustomEditor';
import { RejectErrorHandleFields } from '../../../config/ErrorHandle';

function RejectPrompt(props) {
  const show = props.show || false;
  const onHide = props.onHide || (() => {});
  const onConfirm = props.onConfirm || (() => {});

  const [rejectComment, setRejectComment] = useState();
  const [error, setError] = useState(false);

  const handleOnConfirm = () => {
    if (rejectComment.length > 200) {
      setError(true);
    } else {
      onConfirm(rejectComment);
    }
  };

  const handleOnHide = () => {
    onHide();
    setError(false);
  };

  const otherBody = () => {
    return (
      <>
        <CustomTitleLabel title={'Comment'} />
        <CustomEditor
          initialValue={rejectComment}
          onValueChange={(data) => {
            setRejectComment(data);
            setError(false);
          }}
          error={error}
          errorMessage={RejectErrorHandleFields.rejectComment.message}
        />
      </>
    );
  };

  return (
    <BasePrompt
      show={show}
      closeAction={handleOnHide}
      rightButton={{
        text: 'Confirm',
        action: handleOnConfirm,
        disabled: !rejectComment,
      }}
      leftButton={{
        text: 'Cancel',
        action: onHide,
      }}
      title={'Confirm to Reject?'}
      description={'Please input a comment.'}
      otherBody={otherBody}
      customClass={'container'}
    />
  );
}

export default RejectPrompt;
