import React, { Component } from 'react';
import PreviewImage from '../../base/prompt/PreviewImage';

class BaseOverView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: null,
    };
  }

  renderRowData(title, value) {
    if (title === 'Image') {
      return (
        <img
          alt="img"
          src={value}
          style={{ maxWidth: 191, maxHeight: 191 }}
          onClick={() => {
            this.setState({
              imageUrl: value,
            });
          }}
        />
      );
    }
    if (Array.isArray(value)) {
      let row = <div className="second-section-content">{'-'}</div>;
      if (value && value.length) {
        row = value.map((v) => (
          <div className="second-section-content">{v || '-'}</div>
        ));
      }
      return row;
    }
    return <div className="second-section-content">{value}</div>;
  }

  render() {
    return (
      <div
        className={`coupon-overview-container ${this.props.className || ''}`}
      >
        {this.props.title ? (
          <div className="first-section-title overview-section-title">
            {this.props.title}
          </div>
        ) : null}
        {this.props.itemTitleList.map((title, index) => {
          return (
            <div className="second-section">
              <div className="second-title-section">
                <div className="second-section-title">{title}</div>
              </div>
              {this.renderRowData(title, this.props.itemValueList[index])}
            </div>
          );
        })}
        <PreviewImage
          show={this.state.imageUrl ? true : false}
          onClose={() => {
            this.setState({
              imageUrl: null,
            });
          }}
          imageUrl={this.state.imageUrl}
        />
      </div>
    );
  }
}

export default BaseOverView;
