import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Accordion, Card } from 'react-bootstrap';
import './CollapseNav.scss';
import { getCurrentRouteName } from './CollapseNavUtil';
import { NavBarNames } from '../../config/NavBarNameList';

function CollapseNav(props) {
  const currentLocation = useLocation();
  const tab = props.tab;
  const [activedSideNav, setActiveSideNav] = useState('');
  const [hasSubMenu, setHasSubMenu] = useState([
    'Dashboard',
    // 'user',
    // 'Engagement',
  ]);
  const [menus, setMenus] = useState([]);

  const { user } = useSelector((state) => ({
    user: state.users,
  }));

  const setMenu = useCallback(() => {
    let hasPermissionMenus = [
      // NavBarNames.customer,
      // NavBarNames.campaigns,
      // NavBarNames.coupons,
    ];
    if (tab.list) {
      tab.list.map((item) => {
        const hasPermission =
          tab.intro ?
          item?.permissionlist?.filter((codename) => 
            user?.userPermissions?.filter(
              (val) => {
                return val === codename && (
                user.isConcierge ? item.conciergemode : true
              )}
              ).length > 0
          ).length === item?.permissionlist?.length
          : 
          user?.userPermissions?.filter(
            (val) => val.split('_')[1] === item.codename && (
                user.isConcierge ? item.conciergemode : true
              )
          ).length > 0;
        if (hasPermission) {
          setHasSubMenu([...hasSubMenu, tab.name]);
          hasPermissionMenus.push(item.name);
        }
      });
    }

    setMenus(hasPermissionMenus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    setActiveSideNav(getCurrentRouteName(currentLocation.pathname));
  }, [currentLocation]);

  useEffect(() => {
    if (!user?.isSuperuser) {
      console.log('@@54: ');
      setMenu();
    }
  }, [user, setMenu]);

  const arrowClick = (event) => {
    const arrowItem = event.currentTarget.lastChild
    const containerItem = event.currentTarget.parentElement.lastChild
    if (arrowItem.className.includes("show")) {
      arrowItem.setAttribute("class", "hidden arrow")
      const containerClass = containerItem.className.replace("show", "")
      containerItem.setAttribute("class", containerClass)
    } else {
      arrowItem.setAttribute("class", "show arrow")
      const containerClass = containerItem.className + " show"
      containerItem.setAttribute("class", containerClass)
    }
  }

  return (
    <>
      {tab.intro && (user?.isSuperuser || hasSubMenu.includes(tab.name)) ? 
      <div className="side-menu-nav-area intro">
        <Card className={`side-menu-nav-section intro`}>
            <Accordion.Toggle
              as={Card.Header}
              variant="link"
              eventKey={tab.name}
              className="side-menu-nav-header"
              onClick={(event) => arrowClick(event)}
            >
              <label className="side-menu-nav-title">{tab.name}</label>
              <span className='hidden arrow' ></span>
            </Accordion.Toggle>
            {tab.list && tab.list?.filter((sub) => menus.includes(sub.name) || user?.isSuperuser).length > 0 ? (
              <Accordion.Collapse
                eventKey={`${tab.name}-body`}
                className="side-menu-nav-header side-menu-nav-header-container"
              >
                <Card.Body className="side-menu-nav-header">
                  {tab.list?.map((sub, index) =><>
                    {menus.includes(sub.name) || user?.isSuperuser ? (
                      <Card.Body className="side-menu-nav-header  side-menu-nav-sub-header">
                        <div onClick={(event) => arrowClick(event)}>
                        <label className={`side-menu-nav-item side-menu-nav-item-second`}>
                          {sub.name}
                        </label>
                        <span className='hidden arrow'></span>
                        </div>
                        <Card.Body className="side-menu-nav-header side-menu-nav-item-container">
                          {sub.list?.map((route, index) =>
                            route.location ? (
                              <Link
                                to={route.location}
                                className={`side-menu-nav-item side-menu-nav-item-second sub ${
                                  activedSideNav === route.name
                                    ? 'side-menu-nav-header-focus'
                                    : ''
                                } ${route.needLineBreak ? 'side-menu-nav-item-line-break' : ''}`}
                              >
                                {route.name}
                              </Link>
                            ) : (
                              <label
                                className={`side-menu-nav-item side-menu-nav-item-second`}
                              >
                                {route.name}
                              </label>)
                          )}
                       </Card.Body>
                      </Card.Body>
                    ) : null}
                  </>
                  )}
                </Card.Body>
              </Accordion.Collapse>
            ) : null}
          </Card>
      </div> : 
      user?.isSuperuser || hasSubMenu.includes(tab.name) ? (
        <div className="side-menu-nav-area">
          <Card className={`side-menu-nav-section `}>
            <Accordion.Toggle
              as={Card.Header}
              variant="link"
              eventKey={tab.name}
              className="side-menu-nav-header"
            >
              {tab.location ? (
                <Link
                  to={tab.location}
                  className={`side-menu-nav-title side-menu-nav-title-link ${
                    activedSideNav === tab.name
                      ? 'side-menu-nav-header-focus'
                      : ''
                  }`}
                >
                  {tab.name}
                </Link>
              ) : (
                <label className="side-menu-nav-title">{tab.name}</label>
              )}
            </Accordion.Toggle>
            {tab.list ? (
              <Accordion.Collapse
                eventKey={`${tab.name}-body`}
                className="side-menu-nav-header show"
              >
                <Card.Body className="side-menu-nav-header">
                  {tab.list?.map((route, index) =>
                    menus.includes(route.name) || user?.isSuperuser ? (
                      route.location ? (
                        <Link
                          to={route.location}
                          className={`side-menu-nav-item side-menu-nav-item-second ${
                            activedSideNav === route.name
                              ? 'side-menu-nav-header-focus'
                              : ''
                          } ${route.needLineBreak ? 'side-menu-nav-item-line-break' : ''}`}
                        >
                          {route.name}
                        </Link>
                      ) : (
                        <label
                          className={`side-menu-nav-item side-menu-nav-item-second`}
                        >
                          {route.name}
                        </label>
                      )
                    ) : null,
                  )}
                </Card.Body>
              </Accordion.Collapse>
            ) : null}
          </Card>
        </div>
      ) : null}
    </>
  );
}
export default CollapseNav;
