import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { QrReader } from 'fork-react-qr-reader';
import { useDispatch } from 'react-redux';
import { decode } from 'base-64';
import { ImageButton } from '../base/CustomImageButton';
import closeIcon from '../../assets/images/close.png';
import '../couponTransactionRecord/ScannerCoupon.scss';

const QRScan = ({ show, handleClose, setValue }) => {
  const dispatch = useDispatch();

  const parseQrCode = (scannerString) => {
    let object = {};
    try {
      object = JSON.parse(scannerString);
    } catch (e) {
      console.log('scannerString is not valid JSON');
    }
    if (!object || !object.code) {
      return '';
    }
    const decodedCode = decode(object.code);
    let decodedObject = decodedCode;
    try {
      decodedObject = JSON.parse(decodedCode);
    } catch (e) {
      console.log('decodedCode is not valid JSON');
    }
    if (decodedObject?.memberShipID) {
      return decodedObject.memberShipID;
    }
    return '';
  };

  return (
    <div className="scanner-modal" style={{ display: show ? 'block' : 'none' }}>
      <QrReader
        constraints={{
          facingMode: 'environment',
        }}
        videoId={'video'}
        scanDelay={1500}
        videoStyle={{
          position: 'fixed',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          display: show ? 'block' : 'none',
          objectFit: 'cover',
        }}
        onResult={(result, error) => {
          console.log("@50", result?.text,  error?.message);
          if (!!result?.text) {
            const code = parseQrCode(result?.text);
            setValue(code);
            handleClose();
          }

          if (!!error) {
            if (error.message) {
              handleClose();
              dispatch({
                type: 'navBars/updateState',
                payload: {
                  saveDiscardToastShowing: {
                    value: true,
                    type: error.message,
                  },
                },
              });
            }
          }
        }}
      />
      <div className="scanner-mask-container">
          <div className="scanner-vertical-mask">
            <ImageButton
              image={closeIcon}
              action={() => {
                handleClose();
              }}
            />
            {/* <div className='scanner-text-container'>
              <label className='scanner-title'>掃描客戶優惠券二維碼</label>
              <label className='scanner-subtitle'>對齊框架內的二維碼進行掃描。</label>
              <label className='scanner-subtitle'>掃描......</label>
            </div> */}
          </div>
          <div className="scanner-container">
            <div className="scanner-horizontal-mask" style={{width: 'calc(100vw - 240px)'}}></div>
            <div className="scanner-box"></div>
            <div className="scanner-horizontal-mask" style={{width: 'calc(100vw - 240px)'}}></div>
          </div>
          <div className="scanner-vertical-mask"></div>
        </div>
    </div>
  );
};

export default QRScan;
