import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ScrollableComponent from '../../../components/base/ScrollableComponent';
import CreateEarningRuleStepOne from './CreateEarningRuleStepOne';
import CreateEarningRuleStepTwo from './CreateEarningRuleStepTwo';
import CreateEarningRuleStepThree from './CreateEarningRuleStepThree';
import { 
  createAction,
  removeFromSessionStorage,
  saveToSessionStorage,
} from '../../../utils';
import Loading from '../../../components/base/Loading';
import { SavedStatus } from '../../../config/CustomEnums';
import {
  getCmapaignCreateSucessStepBar,
  CREATE_EARNING_RULE,
} from '../../../components/base/prompt/NextStepConfig';
import { CampaignType, APIStatus } from '../../../config/CustomEnums';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { EARNING_RULES_SESSION_KEY } from '../../../models/CreateEarningRulesModel';
import BaseForm from '../../../components/base/v2/BaseForm';

function CreateEarningRule({
  stepConfig,
  currentStep,
  earningRule,
  // savedSucess,
  createStatus,
  hasUpdatedDefaultValues,
  createType
}) {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    const earningRuleId = params.id;
    if (earningRuleId) {
      dispatch(
        createAction('createEarningRules/getOneEarningRule')({
          id: earningRuleId,
        }),
      );
    } else {
      dispatch({
        type: 'createEarningRules/loadEarningRuleFromCookie',
        payload: {
          createType
        }
      });
    }
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    return () => {
      dispatch({ type: 'createEarningRules/clearData' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // const [stepSet, setStepSet] = useState([<Loading />]);

  const caution = () => {
    if (params.id) {
      return {
        detail: '', //Create a campaign to engage your custoemrs. You can create different types of campaign.
        title: earningRule.generalName,
        model: 'createEarningRules',
      };
    } else {
      return {
        detail: '', //Create a campaign to engage your custoemrs. You can create different types of campaign.
        title: 'Create Earning Rule ',
        model: 'createEarningRules',
      };
    }
  };

  const toEarningRuleList = () => {
    history.push('/earns');
  };

  const stepSet = [
    <CreateEarningRuleStepOne />,
    <CreateEarningRuleStepTwo />,
    <CreateEarningRuleStepThree />,
  ]

  return (
    <>
      <BaseForm
        defaultValues={earningRule}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={createStatus === APIStatus.success}
        hasleavePagePrompt={params.id}
        hasCancelButton={params.id}
        tempSave={(save, getValues) => {
          console.log("@92", save, getValues())
          if (save) {
            saveToSessionStorage(EARNING_RULES_SESSION_KEY, getValues())
          } else if (!params.id) {
            removeFromSessionStorage(EARNING_RULES_SESSION_KEY)
          }
        }}
        content={stepSet}
        currentStep={currentStep}
        breadcrumb={<CustomBreadcrumb name={earningRule.generalName}/>}
        stepsConfig={stepConfig}
        caution={caution()}
        showFinishPop={createStatus === APIStatus.success}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: `The earning rule is successfully created. Let's create an earning campaign to activate the earning rule.`,
          steps: {
            stepNames: getCmapaignCreateSucessStepBar(
              CampaignType.earningRulesCampaign,
              true,
            ),
            current: CREATE_EARNING_RULE,
          },
          buttons: [
            {
              text: 'Create Earning campaign',
              action: () => {
                history.push({
                  pathname: '/campaigns/create',
                  search: `type=${CampaignType.earningRulesCampaign}&id=${earningRule?.pk}`,
                });
              },
              requires: PermissionCodes.addCampaign,
            },
            {
              text: 'Maybe Later',
              action: toEarningRuleList,
              requires: PermissionCodes.changeMessage,
            },
          ],
        }}
        continueCreateConfig={{
          onConfirm: () => {
            history.push(history.location.state?.from);
          },
          onCancel: toEarningRuleList,
          onClose: toEarningRuleList,
        }}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  stepConfig: state.createEarningRules.stepConfig,
  currentStep: state.createEarningRules.currentStep,
  earningRule: state.createEarningRules.earningRule,
  // savedSucess: state.createEarningRules.saved, 
  createStatus: state.createEarningRules.createStatus,
  hasUpdatedDefaultValues: state.createEarningRules.hasUpdatedDefaultValues,
})

export default connect(mapPropsToState)(CreateEarningRule);
