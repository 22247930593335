import { getSearchKey } from '../utils';
import { BaseHelper } from './BaseGraphQLAPIHelper';

const WEB_CONTENT_NODE = `{
  id
  pk
  name
  url
  description
  creationDate
  translations {
    edges {
      node {
        pk
        language
        description
      }
    }
  }
}`;

export const getWebContentList = (afterCursor, payload) => {
  let searchString = payload?.isAll ? '' : `first: ${payload?.pageSize || 20}`;
  if (afterCursor) {
    searchString += `, after: "${afterCursor}"`;
  }
  if (payload?.sort) {
    searchString += `, orderBy: "${payload.sort}"`;
  } else {
    searchString += `, orderBy: "-pk"`;
  }
  if (payload.searchKey) {
    searchString += `, name:"${getSearchKey(payload.searchKey)}"`;
  }
  const query = `{
    webviewContents(${searchString}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node ${WEB_CONTENT_NODE}
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteWebContent = (ids) => {
  const query = `
    mutation DeleteWebviewContents($input: DeleteWebviewContentsInput!) {
      deleteWebviewContents(input: $input) {
        success
      }
    }
  `;
  const variables = {
    input: {
      ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getWebContent = (id) => {
  const query = `{
    webviewContent(id: "${id}") ${WEB_CONTENT_NODE}
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createWebContent = (values) => {
  const query = `
    mutation CreateWebviewContent($input: CreateWebviewContentInput!) {
      createWebviewContent(input: $input) {
        success
        node ${WEB_CONTENT_NODE}
        errors {
          field
          messages
        }
      }
    }
  `;
  const variables = {
    input: {
      ...values,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateWebContent = (values) => {
  const query = `
    mutation UpdateWebviewContent($input: UpdateWebviewContentInput!) {
      updateWebviewContent(input: $input) {
        success
        node ${WEB_CONTENT_NODE}
        errors {
          field
          messages
        }
      }
    }
  `;
  const variables = {
    input: {
      ...values,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
