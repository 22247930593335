import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import BaseFilter, {
  FilterTypeEnum,
} from '../../../components/base/BaseFilter';
import { ArrayStringData } from '../../../utils';

function Filter({
  subCategoryList,
  brandList,
  backAction = () => {}
}) {
  const location = useLocation();
  const dispatch = useDispatch();

  const parsedSearch = queryString.parse(location.search);
  const searchSubcategory = parsedSearch['subcategory'] || '';
  const searchBrand = parsedSearch['brand'] || '';
  const searchPropertyType = parsedSearch['property_type'] || '';

  const [subcategory, setSubcategory] = useState(
    ArrayStringData(searchSubcategory)
  );
  const [brand, setBrand] = useState(
    ArrayStringData(searchBrand)
  );
  const [propertyType, setPropertyType] = useState(ArrayStringData(searchPropertyType));

  // useEffect(() => {
  //   dispatch(createAction('storeCategoryList/getAllStoreSubcategoryList')({}));
  // }, [dispatch]);

  const content = [
    {
      title: 'Property Type',
      data: [
        { name: 'Mall stores', pk: 'MALL_STORE' },
        { name: 'FM stores', pk: 'FRESH_MARKET_STORE' },
      ],
      value: propertyType,
      setValue: setPropertyType,
      componment: FilterTypeEnum.choice,
    },
    {
      title: 'Subcategory',
      data: subCategoryList,
      value: subcategory,
      setValue: setSubcategory,
      componment: FilterTypeEnum.choice,
    },
    {
      title: 'Brand',
      data: brandList,
      value: brand,
      setValue: setBrand,
      componment: FilterTypeEnum.choiceWithSearch,
      loadMoreAction: 'brand/getSimpleBrandList',
      filterAction: 'brand/getSimpleBrandList',
    },
  ];

  return <BaseFilter content={content} backAction={backAction} />;
}

const mapPropsToState = (state) => ({
  subCategoryList: state.storeCategoryList.subCategoryList,
  brandList: state.brand.brandList,
});

export default connect(mapPropsToState)(Filter);
