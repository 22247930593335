import React from 'react';
import { connect } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import { listItem } from '../banners/DisplayItems';
import { CustomTitleWithDropDown } from '../customer/CustomTitleWithDropDown';

function CreateMappingSection({mallList}) {
    const { watch, setValue, formState } = useFormContext();
    const { errors } = formState;
  
    const nameField = 'name';

    const district = watch('districtName');

    const mall1Field = 'favouriteMall1'
    const favouriteMall1 = watch(mall1Field)

    const mall2Field = 'favouriteMall2'
    const favouriteMall2 = watch(mall2Field)

    const mall3Field = 'favouriteMall3'
    const favouriteMall3 = watch(mall3Field)

    return (
      <>

        {listItem(
          'District',
          district
        )}
        <ReactHookFormErrorMessage errors={errors} id={nameField} />
        <CustomTitleWithDropDown
          title={`Favourite Mall 1`}
          placeholder={'Search by Name'}
          setValue={(value) => {
            setValue(mall1Field, value, {shouldDirty:true})
          }}
          source={mallList}
          defaultValue={favouriteMall1}
          labelContainPk={true}
          filterAction={'mall/getFilterMallList'}
        //   defaultFilter={defaultFilter}
        />
        <CustomTitleWithDropDown
          title={`Favourite Mall 2`}
          placeholder={'Search by Name'}
          setValue={(value) => {
            setValue(mall2Field, value, {shouldDirty:true})
          }}
          source={mallList}
          defaultValue={favouriteMall2}
          labelContainPk={true}
          filterAction={'mall/getFilterMallList'}
        //   defaultFilter={defaultFilter}
        />
        <CustomTitleWithDropDown
          title={`Favourite Mall 3`}
          placeholder={'Search by Name'}
          setValue={(value) => {
            setValue(mall3Field, value, {shouldDirty:true})
          }}
          source={mallList}
          defaultValue={favouriteMall3}
          labelContainPk={true}
          filterAction={'mall/getFilterMallList'}
        //   defaultFilter={defaultFilter}
        />
      </>
    );
  }
  const mapPropsToState = (state) => ({
    mallList: state.mall.filterMallList || [],
  });

  export default connect(mapPropsToState)(CreateMappingSection);