import React from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import ContentSections from '../base/ContentSections';
import { ContinueWithBackButtons } from '../base/BottomStepComponent';
import { validate } from '../../containers/conciergeApp/transactionRecord/CreateTransactionValidate';
import PurchaseReceiptSection from './PurchaseReceiptSection';
import TransactionInfoSection from './TransactionInfoSection';
import i18n from '../../I18n';

function CreateTransactionRecordStepTwo({ language, isTCTransaction }) {
  const dispatch = useDispatch();

  const { getValues, clearErrors, setError } = useFormContext();

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 1,
      isBack: isBack,
      language,
    });
    dispatch({
      type: 'createTransactionRecord/stepChange',
      payload: {
        isBack: isBack,
        step: 1,
        isValid,
        isTCTransaction,
      },
    });
  };

  const getSections = () => {
    return [
      <TransactionInfoSection language={language} isTCTransaction={isTCTransaction} />
    ];
  };

  return (
    <>
      <ContentSections sections={getSections()} hidePreview={true} />
      <ContinueWithBackButtons
        backText={i18n.t('transaction.back', { locale: language })}
        backAction={() => stepChangeAction(true)}
        continueText={i18n.t('transaction.continue', { locale: language })}
        continueAction={() => {
          stepChangeAction(false)
        }}
      />
    </>
  );
}

export default CreateTransactionRecordStepTwo;
