import { getSearchKey } from '../utils';
import { BaseHelper } from './BaseGraphQLAPIHelper';

const SimpleListFieldNode = `
node {
  id
  pk
  name
  description
  creationDate
}
`
const ListFieldNode = `
node {
  id
  pk
  name
  description
  creationDate
  customers {
    edges {
      node {
        pk
        id
        firstName
        lastName
        ssoUid
      }
    }
  }
}
`

export const getGroups = (
  afterCursor = '',
  rank = true,
  search = '',
  isAll = false,
  moreSearch,
) => {
  let orderBy = '-pk';
  if (!rank) {
    orderBy = 'pk';
  }
  if (moreSearch?.sort) {
    orderBy = moreSearch.sort;
  }
  const node = moreSearch?.isSimpleList ? SimpleListFieldNode : ListFieldNode
  const query = `{
    customerGroups(${
      isAll ? '' : 'first: 20'
    }, after:"${afterCursor}", orderBy:"${orderBy}", name: "${getSearchKey(search)}") {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        ${node}
      }
    }
  }
  `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCustomerGroup = (id) => {
  const query = `
  {
    customerGroup(id: "${id}") {
      id
      pk
      name
      description
      members
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteCustomerGroups = (ids) => {
  const query = `
  mutation DeleteCustomerGroups($input: DeleteCustomerGroupsInput!) {
    deleteCustomerGroups(input: $input) {
      success
    }
  }`;
  const variables = {
    input: {
      ids: ids,
    },
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createCustomerGroup = (inputBody) => {
  const query = `
  mutation CreateCustomerGroup($input: CreateCustomerGroupInput!) {
    createCustomerGroup(input: $input) {
      node {
        id
        pk
        name
        description
        customers {
          edges {
            node {
              id
              pk
              firstName
              lastName
              nickname
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateCustomerGroup = (inputBody) => {
  const query = `
  mutation UpdateCustomerGroup($input: UpdateCustomerGroupInput!) {
    updateCustomerGroup(input: $input) {
      node {
        id
        pk
        name
        description
        customers {
          edges {
            node {
              id
              pk
              firstName
              lastName
              nickname
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }`;
  const variables = {
    input: inputBody,
  };
  return BaseHelper.callGraphQLAPI({ query, variables });
};
