import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ContentSections from '../../../components/base/ContentSections';
import {
  listItem,
  listMultiImage,
} from '../../../components/banners/DisplayItems';
import { APIStatus, LanguageConfig } from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';
import i18n from '../../../I18n';

function FreshMarketDetail({ freshMarket, status, languages }) {
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'freshMarket/getOneDetail',
      payload: { id: params.id },
    });
    dispatch({
      type: 'language/getList',
      payload: { isSelectorLoad: true },
    });
    return () => {
      dispatch({ type: 'freshMarket/clearData' });
    };
  }, [dispatch, params.id]);

  const renderTabForLanguage = (language) => {
    const isEnglish = language === LanguageConfig.english;
    return [
      <>
        {listItem(
          i18n.t('name', { locale: language }),
          freshMarket.translations?.[language]?.name,
        )}
        {isEnglish
          ? freshMarket.detailPhotos
            ? listMultiImage('Photos', freshMarket.detailPhotos)
            : listItem('Photos', '')
          : null}
        {listItem('Sub-district', freshMarket.translations?.[language]?.district)}
        {listItem('Opening hours', freshMarket.translations?.[language]?.openingHours, '', { whiteSpace: "pre-wrap" })}
        {listItem('Address', freshMarket.translations?.[language]?.address)}
        {isEnglish ? (
          <>
            {listItem('Latitude', freshMarket.latitude)}
            {listItem('Longitude', freshMarket.longitude)}
            {listItem('Phone Number', freshMarket.displayPhoneNumner)}
          </>
        ) : null}
        {listItem(
          i18n.t('description', { locale: language }),
          freshMarket.translations?.[language]?.description,
        )}
        {listItem('Related mall', freshMarket.translations?.[language]?.mall)}
      </>,
    ];
  };

  const tabs = [
    {
      name: '',
      content:
        status === APIStatus.calling ? (
          <Loading />
        ) : (
          <div className="scroll-container-common">
            <ContentSections
              languageTabContent={{
                containers: languages?.map((item) => ({
                  container: renderTabForLanguage(item.code),
                  key: item.code,
                  title: item.sourceName,
                })),
              }}
              hidePreview={true}
            />
          </div>
        ),
    },
  ];

  const buttons = [];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '', //view message
          title: freshMarket?.name ? freshMarket.name : '',
        }}
        breadcrumb={
          <CustomBreadcrumb
            name={freshMarket?.name ? `${freshMarket.name}` : ''}
          />
        }
        hideTab={true}
        tabs={tabs}
        buttons={buttons}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  freshMarket: state.freshMarket.detail,
  status: state.freshMarket.createStatus,
  languages: state.language.allList,
});

export default connect(mapPropsToState)(FreshMarketDetail);
