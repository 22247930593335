import React from 'react';
import BaseMultipleSelectorV2 from '../base/BaseMultipleSelectorV2';
import '../customerGroup/TargetCustomerSection.scss';

function TargetStoreCategorySection({
  selectedCategoryList,
  title,
  targetData,
  onChange,
}) {
  return (
    <div className="create-customer-group-target">
      <BaseMultipleSelectorV2
        title={title}
        namespace="customerList"
        data={{
          sourceData: selectedCategoryList,
          targetData: targetData,
          targetChange: (options) => {
            onChange(options);
          },
        }}
        groupKey="tagKey"
      />
    </div>
  );
}

export default TargetStoreCategorySection;
