import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CampaignErrorHandleField } from './CreateCampaignHandleError';
import PeriodSection from './PeriodSection';
import { compareDate } from '../../../utils/TimeFormatUtil';
import {
  campaignLimitDate,
  campaignAvaliableClue,
  disableSwitchToAllPeriod,
} from './CreateCampaignUtil';
import { useFormContext } from 'react-hook-form';
import { hasError, errorMessage } from '../../base/ErrorFieldMessage';
import PeriodAndWeekdaySection from './PeriodAndWeekdaySection';

function ActivePeriodSection({}) {
  // const {
  //   birthdayEndDate,
  //   isAlwaysActivePeriod,
  //   activeStartDate,
  //   activeEndDate,
  //   errorFields,
  //   birthdayStartDate,
  //   earningRuleType,
  // } = useSelector((state) => ({
  //   birthdayStartDate:
  //     state.createCampaign.campaign.linkedEarningRules
  //       ?.birthdayTypePeriodStartDate,
  //   isAlwaysActivePeriod: state.createCampaign.campaign.isAlwaysActivePeriod,
  //   activeStartDate: state.createCampaign.campaign.activeStartDate,
  //   activeEndDate: state.createCampaign.campaign.activeEndDate,
  //   errorFields: state.createCampaign.errorFields,
  //   birthdayEndDate:
  //     state.createCampaign.campaign.linkedEarningRules
  //       ?.birthdayTypePeriodEndDate,
  //   earningRuleType: state.createCampaign.campaign.linkedEarningRules?.type,
  // }));
  const dispatch = useDispatch();
  const { watch, setValue, formState } = useFormContext();
  const errors = formState.errors;
  const isAlwaysActivePeriod = watch('isAlwaysActivePeriod');
  const activeStartDate = watch('activeStartDate');
  const activeEndDate = watch('activeEndDate');
  const watchEarningRules = watch('linkedEarningRules')
  const periodValues = watch('activePeriodValues')
  const weekday = watch('activeWeekday')
  const birthdayStartDate = watchEarningRules?.birthdayTypePeriodStartDate
  const birthdayEndDate = watchEarningRules?.birthdayTypePeriodEndDate
  const earningRuleType = watchEarningRules?.type
  const couponRewardExpiryDate = watchEarningRules?.couponTemplate?.absoluteExpiryDate;
  // const onSubmit = props.onSubmit;
  // const onSubmitAction = props.onSubmitAction || (() => {});
  const defaultActive = () => {
    if (birthdayStartDate && birthdayEndDate) {
      return false;
    }
    return isAlwaysActivePeriod;
  };
  // const [alwaysActive, setAlwaysActive] = useState(defaultActive());
  // const [startDate, setStartDate] = useState(
  //   compareDate(activeStartDate, birthdayStartDate, false),
  // );
  // const [endDate, setEndDate] = useState(
  //   compareDate(activeEndDate, birthdayEndDate, true),
  // );

  // useEffect(() => {
  //   if (onSubmit) {
  //     onSubmitAction({
  //       isAlwaysActivePeriod: alwaysActive,
  //       activeStartDate: startDate,
  //       activeEndDate: endDate,
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [onSubmit]);

  // const activePeriodSwitch = (isChecked) => {
  //   dispatch({
  //     type: 'createCampaign/setFieldToSession',
  //     payload: { isAlwaysActivePeriod: isChecked },
  //   });
  //   setAlwaysActive(isChecked);
  // };

  // const startDateChange = (date) => {
  //   dispatch({
  //     type: 'createCampaign/setFieldToSession',
  //     payload: { activeStartDate: date },
  //   });
  //   setStartDate(date);
  // };

  // const endDateChange = (date) => {
  //   dispatch({
  //     type: 'createCampaign/setFieldToSession',
  //     payload: { activeEndDate: date },
  //   });
  //   setEndDate(date);
  // };

  return (
    <>
    <PeriodSection
      disabled={disableSwitchToAllPeriod(
        earningRuleType,
        birthdayStartDate,
        birthdayEndDate,
      )}
      title="Active Period"
      startErrorObject={{
        id: 'activeStartDate',
        error: hasError(errors, 'activeStartDate'),
        message: errorMessage(errors, 'activeStartDate'),
      }}
      endErrorObject={{
        id: 'activeEndDate',
        error: hasError(errors, 'activeEndDate'),
        message: errorMessage(errors, 'activeEndDate'),
      }}
      switchButtonError={{
        id: 'isAlwaysActivePeriod',
        error: hasError(errors, 'isAlwaysActivePeriod'),
        message: errorMessage(errors, 'isAlwaysActivePeriod'),
      }}
      endDateChange={(date) => {
        setValue('activeEndDate', date, { shouldDirty: true })
      }}
      maxDate={campaignLimitDate(earningRuleType, birthdayStartDate)}
      minDate={campaignLimitDate(earningRuleType, birthdayEndDate)}
      titleDescription={campaignAvaliableClue(earningRuleType)}
      switchButtonTitle="It is an all-time active campaign"
      isAlwaysPeriod={isAlwaysActivePeriod}
      switchOnChange={(value) => {
        setValue('isAlwaysActivePeriod', value, {shouldDirty: true})
      }}
      startDate={compareDate(activeStartDate, birthdayStartDate, false)}
      startDateChange={(date) => {
        setValue('activeStartDate', date, { shouldDirty: true })
      }}
      endDate={couponRewardExpiryDate || compareDate(activeEndDate, birthdayEndDate, true)}
      // errorFields={errorFields}
      // fieldName={CampaignErrorHandleField.activeEndDate.name}
      // fieldErrorMessage={CampaignErrorHandleField.activeEndDate.message}
    />
    <PeriodAndWeekdaySection
      switchButtonTitle='Blackout period'
      periodValuesField='activePeriodValues'
      periodValues={periodValues}
      periodValuesChange={(data) => {
        setValue('activePeriodValues', data, { shouldDirty: true })
      }}
      weekday={weekday}
      weekdayChange={(data) => {
        setValue('activeWeekday', data, { shouldDirty: true })
      }}
      maxDate={activeEndDate}
      minDate={activeStartDate}
      disabledDate={!isAlwaysActivePeriod}
    />
    </>
  );
}

export default ActivePeriodSection;
