import React, { useEffect, useState } from 'react';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { Button, Image } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import '../../record/couponRecords/CouponTransaction.scss';
import { useDispatch, useSelector, connect } from 'react-redux';
import { createAction } from '../../../utils';
import { ActionsDropdownForItem } from '../../../components/base/ActionsDropdown';
import { PermissionCodes } from '../../../config/PermissionCodes';
import Filter from './Filter';
import ListButtonsGroup from '../../../components/base/ListButtonsGroup';
import { LanguageConfig } from '../../../config/CustomEnums';
import Scanner from '../../../assets/images/white-scanner.svg';
import ScannerCoupon from '../../../components/couponTransactionRecord/ScannerCoupon';
import UseCouponPrompt from '../../../components/base/prompt/UseCouponPrompt';
import { getCurrentRouteName } from '../../../components/bars/CollapseNavUtil';
import { NavBarNames } from '../../../config/NavBarNameList';
import i18n from '../../../I18n';
import ExportCouponView, { EXPORT_BY } from '../../../components/coupon/couponList/ExportCouponView';
import AuthButton from '../../../components/base/AuthButton';
import LoadingPrompt from '../../../components/base/prompt/LoadingPrompt';

const CONCIERGE_ROUTE_NAMES = [
  NavBarNames.transactionRecord,
  NavBarNames.couponRecords,
  NavBarNames.stampRecordsTC,
  NavBarNames.badgeRecordsTC,
  NavBarNames.customerTC,
]

export const getConciergeLanguage = (location) => {
  let language = LanguageConfig.english;
  const currentRouteName = getCurrentRouteName(location.pathname);
  if (CONCIERGE_ROUTE_NAMES.indexOf(currentRouteName) > -1) {
    language = LanguageConfig.traditionalChinese;
  }
  return language;
}

function CouponTransactionRecord({
  tableFields,
  dataList,
  pageInfo,
  totalCount,
  totalPage,
  currentPage,
  useSuccessedCoupon,
}) {
  const queryString = require('query-string');
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [showExportView, setShowExportView] = useState(false);
  const [scanCouponStatus, setScanCouponStatus] = useState(false);
  const [showUseCouponPrompt, setShowUseCouponPrompt] = useState(false);
  const [couponSerialNumber, setCouponSerialNumber] = useState();
  const [loading, setLoading] = useState(false);

  const language = getConciergeLanguage(location);
  let isConcierge = false;
  if (language === LanguageConfig.traditionalChinese) {
    isConcierge = true;
  }
  const emptyPageInfo = {
    startCursor: 0,
    endCursor: 0,
    hasNextPage: false,
    hasPreviousPage: false,
  }

  // useEffect(() => {
  //   dispatch({ type: 'couponList/getCurrentPageTemplateList', payload: {} });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (couponSerialNumber) {
      dispatch({
        type: 'couponTransactionRecord/useCouponBySerialNumber',
        payload: {
          serialNumber: couponSerialNumber,
          afterAction: (success) => {
            setLoading(false);
            setCouponSerialNumber('');
            if (success) {
              setScanCouponStatus(true);
              setShowUseCouponPrompt(true);
            } else {
              setScanCouponStatus(false);
              setShowUseCouponPrompt(true);
            }
          },
        },
      });
    }
  }, [couponSerialNumber])

  useEffect(() => {
    const searchDict = queryString.parse(location.search);
    // const couponTransactionType = searchDict['type'];
    const searchKey = searchDict['search'];
    const stringRank = searchDict['rank'] || 'true';
    const stringPage = searchDict['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);
    dispatch(
      createAction('couponTransactionRecord/getCurrentPageCouponTransactions')({
        ...searchDict,
        // couponTransactionType,
        searchKey,
        rank,
        page,
      }),
    );
  }, [dispatch, location, queryString]);

  const buttons = isConcierge ? [
    <ListButtonsGroup
      hideExtraButtonWidth={600}
      hideAllButtonWidth={530}
      primaryButton={
        <Button
          onClick={() => setIsVisible(true)}
          className="btn-further"
        >
          <Image src={Scanner} style={{ width: '1.1rem', marginRight: '0.5rem', marginBottom: '0.1rem' }}/>
          <label>{i18n.t('couponRecords.use_coupon', { locale: language })}</label>
        </Button>
      }
    />,
  ] : [
    <ListButtonsGroup
      hideExtraButtonWidth={600}
      hideAllButtonWidth={530}
      extraButtons={[
        <AuthButton
          title="Export .csv"
          customClass="btn-back-button-common btn-download"
          action={() => setShowExportView(true)}
          requires={PermissionCodes.addExportjob}
        />,
      ]}
      // primaryButton={
      //   <Button
      //     onClick={() => setIsVisible(true)}
      //     className="btn-further"
      //   >
      //     <Image src={Scanner} style={{ width: '1.1rem', marginRight: '0.5rem', marginBottom: '0.1rem' }}/>
      //     <label>{i18n.t('couponRecords.use_coupon', { locale: language })}</label>
      //   </Button>
      // }
    />,
  ];

  const couponRecordTypeTabs = [
    { key: 'all', name: 'All Types' },
    { key: 'EARN', name: 'Customer earned' },
    { key: 'BUY', name: 'Customer Acquired' },
    { key: 'GRANT', name: 'Admin added' },
    { key: 'RECLAIM', name: 'Admin removed' },
  ];

  const showData = () => {
    const searchDict = queryString.parse(location.search);
    let result = false;
    if (
      searchDict?.record_type ||
      // searchDict?.coupon_name ||
      // searchDict?.create_date ||
      searchDict?.expired_date ||
      searchDict?.search
    ) {
      result = true;
    }
    return result;
  };

  const tableTabs = [
    {
      name: '',
      content: (
        <BaseTabListContainer
          hideTab={true}
          tabs={couponRecordTypeTabs}
          pageInfo={pageInfo}
          totalCount={totalCount}
          groupActions={[]}
          searchPlaceholder={i18n.t('transaction.search_membership_id/mobile_number', { locale: language })}
          searchMoreStyle={{ width: isConcierge ? '' : '350px' }}
          filter={{
            hasFilter: true,
            componment: Filter,
            filterTitle: i18n.t('transaction.filter', { locale: language }),
            moreStyle: { right: isConcierge ? '' : '417px' },
          }}
          componentLanguage={language}
          listContent={
            <BaseListContainer
              actionFieldDisplayName={i18n.t('transaction.action', { locale: language })}
              fields={tableFields[language]}
              model={'couponTransactionRecord'}
              dataList={dataList}
              totalPage={totalPage}
              deleteInfo={{
                data: [],
                title: '',
                relatedName: '',
                onComfirm: {},
              }}
              permissionGroup={isConcierge ? PermissionCodes.conciergecoupontransaction : PermissionCodes.coupontransaction}
              useCustomCommonActions={true}
              customCommonActions={(item) => {
                const actions = [
                  {
                    name: i18n.t('transaction.view_detail', { locale: language }),
                    action: () => {
                      return history.push(`${location.pathname}/${item.pk}/`);
                    },
                    requires: isConcierge ? PermissionCodes.viewConciergecoupontransaction : PermissionCodes.viewCouponTransaction,
                  },
                ];
                return (
                  <ActionsDropdownForItem object={item} actions={actions} actionTitle={i18n.t('transaction.action', { locale: language })} />
                );
              }}
              customClassName="coupon-record-table"
            />
          }
        />
      ),
    },
  ];

  return (
    <div>
      <CustomListComponent
        caution={{
          // detail:
          //   'All coupon records are listed here, you can view the coupon transactions of each customer.',
          title: i18n.t('couponRecords.coupon_records', { locale: language }),
        }}
        buttons={buttons}
        breadcrumb={<CustomBreadcrumb />}
        tabs={tableTabs}
        hideTab={true}
      />
      <ExportCouponView
        show={showExportView}
        onHide={() => {
          setShowExportView(false);
        }}
        exportBy={EXPORT_BY.campaign}
      />
      {isVisible ? (
        <ScannerCoupon
          show={isVisible}
          handleClose={() => setIsVisible(false)}
          setCouponSerialNumber={(code) => setCouponSerialNumber(code)}
          handleLoading={() => setLoading(true)}
        />
      ) : null}
      <LoadingPrompt show={loading} />
      <UseCouponPrompt
        show={showUseCouponPrompt}
        scanCouponStatus={scanCouponStatus}
        closeAction={() => setShowUseCouponPrompt(false)}
        coupon={useSuccessedCoupon}
        buttons={[
          {
            text: '完成',
            action: () => {
              setShowUseCouponPrompt(false);
            },
          },
        ]}
      />
    </div>
  );
}

const mapPropsToState = (state) => ({
  tableFields: state.couponTransactionRecord.listDisplayFields,
  dataList: state.couponTransactionRecord.currentPageCouponTransactionList,
  pageInfo: state.couponTransactionRecord.pageInfo,
  totalCount: state.couponTransactionRecord.totalCount,
  totalPage: state.couponTransactionRecord.totalPage,
  currentPage: state.couponTransactionRecord.currentPage,
  useSuccessedCoupon: state.couponTransactionRecord.useSuccessedCoupon,
});

export default connect(mapPropsToState)(CouponTransactionRecord);
