import { LanguageConfig, SESSION_KEYS } from '../config/CustomEnums';
import {
  getMalls,
  getMall,
  updatePanoramicViewUrl,
} from '../services/MallAPIHelper';
import { removeFromSessionStorage, saveToSessionStorage } from '../utils';
import { createModel } from './BaseModel';
import { getNameByLanguage } from './CarParkModel';
import { parseTranslations } from './CreateTransactionRecordModel';
import { apiWithResponseHandle } from './LoadingUtil';

const mallSessionKey = SESSION_KEYS.MALL_SESSION_KEY;

export const saveDataToSessionStorage = (data) => {
  saveToSessionStorage(mallSessionKey, data);
};

export const removeDataFromSessionStorage = () => {
  removeFromSessionStorage(mallSessionKey);
};

const getInitialState = () => ({
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    {
      displayName: 'Name',
      fieldName: 'name',
      orderField: 'name',
    },
    { displayName: 'Cover photo', fieldName: 'coverPhoto' },
  ],
});

export const getDetailPhotos = (mall) => {
  const photos = [];
  const detailPhotosLength = 9;
  if (mall.photo) {
    photos.push(mall.photo);
  }
  [...Array(detailPhotosLength)].forEach((_, index) => {
    const detailPhotoKey = 'detailPhoto' + (index + 1);
    photos.push(mall[detailPhotoKey] || null);
  });
  return photos;
};

const parsePanoramicViewUrl = (panoramicViewUrl) => {
  let result = [
    {
      url: '',
      name: '',
      translations: {},
    },
  ];
  if (panoramicViewUrl?.length) {
    const formatPanoramicViewUrl = JSON.parse(panoramicViewUrl);
    result = formatPanoramicViewUrl.map((item) => {
      let translations = {};
      if (item?.translations?.length) {
        item.translations.forEach((translation) => {
          translations[translation.language] = {
            language: translation.language,
            name: translation.name,
          };
        });
      }
      return {
        ...item,
        translations,
      };
    });
  }
  return result;
};

const getDisplayPanoramicViewUrl = (panoramicViewUrl) => {
  const result = [];
  const formatPanoramicViewUrl = parsePanoramicViewUrl(panoramicViewUrl);
  if (formatPanoramicViewUrl?.length) {
    formatPanoramicViewUrl.forEach((item) => {
      if (item.name || item.url) {
        result.push(`${item.name} - ${item.url}`)
      }
    })
  }
  return result;
};
const parseTransactionMultipleSection = (data) => {
  let result = [];
  if (data) {
    result = data.map((item) => {return {
      value: {id: item.node.id, pk: item.node.pk, name: item.node.name},
      label:`[${item.node.pk}] ${item.node.name}`,
      id: item.node.id, pk: item.node.pk, name: item.node.name
    }})
  }
  return result;
}

const parseLabel = (item) => {
  return {
    label: `[ID:${item.pk}] ${item.name}`
  };
}

const parseMall = (mall) => {
  const translationsEdges = mall.translations?.edges || [];
  const formatFacilities = parseTranslations(mall.facilities?.edges);
  const storeIdOrder = mall.transactionSelectStoreIds || [];
  const formatTransactionStores = parseTranslations(mall.transactionSelectStores?.edges
    ).sort(function(a, b){
      return storeIdOrder.indexOf(a.pk) - storeIdOrder.indexOf(b.pk)
    });
  const formatTransactionStampCampaigns = parseTranslations(mall.transactionSelectStampCampaigns?.edges );
  const formatTransactionMissionCampaigns = parseTranslations(mall.transactionSelectMissionCampaigns?.edges );
  const formatTransactionOtherCampaigns = parseTranslations(mall.transactionSelectOtherCampaigns?.edges );
  const formatTransactionParkCampaigns = parseTranslations(mall.transactionSelectParkCampaigns?.edges );

  const translations = {};
  translations[LanguageConfig.english] = {
    name: mall.name,
    description: mall.description,
    address: mall.address,
    district: mall.district?.name,
    transactionStores: formatTransactionStores?.map(parseLabel),
    transactionStampCampaigns: formatTransactionStampCampaigns?.map(parseLabel),
    transactionMissionCampaigns: formatTransactionMissionCampaigns?.map(parseLabel),
    transactionOtherCampaigns: formatTransactionOtherCampaigns?.map(parseLabel),
    transactionParkCampaigns: formatTransactionParkCampaigns?.map(parseLabel),
    facilities: formatFacilities?.map((facilitie) => {
      return {
        icon: facilitie.image,
        name: facilitie.name,
      };
    }),
  };
  if (translationsEdges?.length) {
    translationsEdges.forEach((item) => {
      let language = item.node.language;
      const districtName = getNameByLanguage(mall.district, language);
      const facilities = formatFacilities?.map((facilitie) => {
        const facilitiesTranslations = facilitie?.translations;
        return {
          icon: facilitiesTranslations?.[language]?.image,
          name: facilitiesTranslations?.[language]?.name,
        };
      });
      const parseTranslationLabel = (item) => {
        const itemTranslations = item?.translations;
        const  translationName= itemTranslations?.[language]?.name || item.name;
        return {
          label: `[ID:${item?.pk}] ${translationName}`,
        };
      }
      const transactionStores = formatTransactionStores?.map(parseTranslationLabel);
      const transactionStampCampaigns = formatTransactionStampCampaigns?.map(parseTranslationLabel);
      const transactionMissionCampaigns = formatTransactionMissionCampaigns?.map(parseTranslationLabel);
      const transactionOtherCampaigns = formatTransactionOtherCampaigns?.map(parseTranslationLabel);
      const transactionParkCampaigns = formatTransactionParkCampaigns?.map(parseTranslationLabel);
      translations[language] = {
        id: item.node.pk,
        language: item.node.language,
        name: item.node.name,
        description: item.node.description,
        address: item.node.address,
        district: districtName || '-',
        facilities,
        transactionStores,
        transactionStampCampaigns,
        transactionMissionCampaigns,
        transactionOtherCampaigns,
        transactionParkCampaigns,
      };
    });
  }
  return {
    ...mall,
    coverPhoto: mall.photo,
    detailPhotos: getDetailPhotos(mall),
    panoramicViewUrl: parsePanoramicViewUrl(mall.panoramicViewUrl),
    selectedStores: parseTransactionMultipleSection(mall.transactionSelectStores?.edges).sort(function(a, b){
      return storeIdOrder.indexOf(a.pk) - storeIdOrder.indexOf(b.pk)
    }),
    selectedStampCampaigns: parseTransactionMultipleSection(mall.transactionSelectStampCampaigns?.edges),
    selectedMissionCampaigns: parseTransactionMultipleSection(mall.transactionSelectMissionCampaigns?.edges),
    selectedOtherCampaigns: parseTransactionMultipleSection(mall.transactionSelectOtherCampaigns?.edges),
    selectedParkCampaigns: parseTransactionMultipleSection(mall.transactionSelectParkCampaigns?.edges),
    displayPanoramicViewUrl: getDisplayPanoramicViewUrl(mall.panoramicViewUrl),
    translations,
  };
};

const parseInputBody = (values) => {
  const { 
    pk,
    panoramicViewUrl,
    selectedStores,
    selectedStampCampaigns,
    selectedMissionCampaigns,
    selectedOtherCampaigns,
    selectedParkCampaigns
   } = values;

  const newPanoramicViewUrl = [];
  if (panoramicViewUrl?.length) {
    panoramicViewUrl.forEach((item) => {
      const translations = [];
      const translationArray = Object.keys(item.translations || {});
      translationArray.forEach((language) => {
        if (language === LanguageConfig.english) {
          return;
        }
        translations.push({
          ...item.translations[language],
          language: language
        });
      });
      if (item.name || item.url) {
        newPanoramicViewUrl.push({
          ...item,
          translations,
        });
      }
    });
  }
  return {
    id: pk,
    panoramicViewUrl: newPanoramicViewUrl,
    transactionSelectStores: selectedStores.map((item)=>item?.value?.pk) || [],
    transactionSelectStampCampaigns: selectedStampCampaigns.map((item)=>item?.value?.pk) || [],
    transactionSelectMissionCampaigns: selectedMissionCampaigns.map((item)=>item?.value?.pk) || [],
    transactionSelectOtherCampaigns: selectedOtherCampaigns.map((item)=>item?.value?.pk) || [],
    transactionSelectParkCampaigns: selectedParkCampaigns.map((item)=>item?.value?.pk) || []
  };
};

export default createModel({
  namespace: 'mall',
  states: getInitialState(),
  params: {
    listAPI: getMalls,
    parse: (data) => data?.malls?.edges?.map((item) => parseMall(item.node)),
    objectKey: 'malls',
    pkNode: 'MallNode',
    detailAPI: getMall,
    parseDetail: (data) => parseMall(data?.mall),
    initState: getInitialState(),
    sessionKey: mallSessionKey,
  },
  reducers: {},
  effects: {
    // update transaction stores as well transactionSelectStores    
    updatePanoramicViewUrl: [
      function* ({ payload }, { call, put, select }) {
        const { afterActions } = payload;
        const input = parseInputBody(payload);
        const serviceArgs = [updatePanoramicViewUrl, input];
        yield put({
          type: 'responseCreateOrUpdate',
          payload: { serviceArgs, isCreate: true, afterActions },
        });
      },
      {
        type: 'takeLatest',
      },
    ],
    getFilterMallList: [
      function* ({ payload }, { call, select, put }) {
        const serviceArgs = [getMalls, "", {...payload, isAll: true, isSimpleFilter: true,}];
        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: {
              filterMallList: data?.malls?.edges?.map((item) => parseMall(item.node)),
            },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
});
