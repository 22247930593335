import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { NavBarNames } from '../../../config/NavBarNameList';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import { createAction } from '../../../utils';

import DeletePrompt from '../../../components/base/DeletePrompt';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import Filter from './Filter';
import { DELETE_RELATED_SECTIONS } from '../../../config/CustomEnums';
// import BaseTest from '../../base/BaseTest';

function AdminList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    listFields,
    dataList,
    pageInfo,
    totalPage,
    totalCount,
    checkedList,
  } = useSelector((state) => ({
    listFields: state.admin.listDisplayFields,
    dataList: state.admin.adminList,
    pageInfo: state.admin.pageInfo,
    totalPage: state.admin.totalPage,
    totalCount: state.admin.totalCount,
    checkedList: state.admin.checkedList,
  }));

  const buttons = [
    <AuthButton
      title="Create Tenant App Administrators"
      action={() => history.push('/administrators/create')}
      requires={PermissionCodes.addAdministrator}
    />,
  ];

  const groupActions = [
    {
      name: 'Delete',
      action: () => {
        // setShowPrompt(true);
        setShowDeletePrompt(true);
      },
      requires: PermissionCodes.deleteAdministrator,
    },
  ];
  const tabs = [
    {
      name: '',
      content: (
        <BaseTabListContainer
          hideTab={false}
          tabs={[
            { key: 'all', name: 'All Types' },
            { key: 'false', name: 'LINK Admin' },
            { key: 'true', name: 'Tenant Admin' }
          ]}
          groupActions={groupActions}
          tabSearchKey={'account_type'}
          pageInfo={pageInfo}
          totalCount={totalCount}
          filter={{
            hasFilter: true,
            componment: Filter,
          }}
          listContent={
            <BaseListContainer
              fields={listFields}
              dataList={dataList}
              totalPage={totalPage}
              model={'admin'}
              permissionGroup={PermissionCodes.administrator}
              deleteInfo={{
                data: [],
                title: 'administrator',
                relatedName: '',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.ADMIN,
              }}
              hasOtherActions={true}
              actions={['Edit', 'Detail', 'Delete']}
              customActions={{
                deactive: (item) => {
                  dispatch(
                    createAction('admin/updateAdminStatus')({
                      data: {
                        isActive: !item.isActive,
                        id: item.pk,
                      },
                      afterAction: () => history.push(location),
                    }),
                  );
                },
              }}
            />
          }
        />
      ),
    },
  ];

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  // const [activeTab, setActiveTab] = useState(tabs[0].name);

  // useEffect(() => {
  //   dispatch(
  //     createAction('admin/getPagedAdmins')({
  //       page: 0,
  //       reverse: true,
  //       type: 'all',
  //       search: '',
  //     }),
  //   );
  // }, [dispatch]);

  useEffect(() => {
    dispatch({ type: 'admin/clearData' });

    const queryString = require('query-string');
    const querySearch = queryString.parse(location.search);
    const searchKey = querySearch['search'];
    const stringRank = querySearch['rank'] || 'true';
    const stringPage = querySearch['page'] || 1;
    const rank = stringRank === 'true';
    const page = parseInt(stringPage);

    dispatch(
      createAction('admin/getPagedAdmins')({
        ...querySearch,
        page,
        reverse: rank,
        search: searchKey,
      }),
    );
  }, [dispatch, location]);

  return (
    <>
      <CustomListComponent
        caution={{
          detail:
            'The administrator can be used to manage registered users or other administrators with certain permissions',
          title: NavBarNames.admin,
        }}
        breadcrumb={<CustomBreadcrumb />}
        buttons={buttons}
        tabs={tabs}
        hideTab={true}
      />
      <DeletePrompt
        data={checkedList}
        title={'administrators'}
        relatedName={''}
        relatedSections={DELETE_RELATED_SECTIONS.ADMIN}
        show={showDeletePrompt}
        onClose={() => setShowDeletePrompt(false)}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch(
            createAction('admin/delete')({
              afterAction: () => {
                history.push(location);
              },
            }),
          );
        }}
      />
    </>
  );
}

export default AdminList;
