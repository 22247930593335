import {
  getStores,
  getStoresOfBrand,
  createOrUpdateStore,
  deleteStores,
  getOneStore,
  getPagedStores,
  getDistricts,
  getDownloadStoreCodesZip,
  getStoreGroupByMall,
  getAllStoreList,
  getRegions,
} from '../services/StoreAPIHelper';
import {
  createAction,
  convertNumberToCursor,
  convertPKToId,
  convertCursorToNumber,
  getObjectFromSessionStorage,
  saveToSessionStorage,
  removeUndefinedFieldFromDict,
  removeFromSessionStorage,
  getFileNameFromUrl,
  delay,
} from '../utils';
import { apiWithResponseHandle, loading } from './LoadingUtil';
import {
  SavedStatus,
  CheckStatus,
  LanguageConfig,
  SESSION_KEYS,
  StatusTag,
  LanguageUpperConfig,
} from '../config/CustomEnums';
import { StoreErrorHandleFields } from '../containers/merchants/stores/StoreErrorHandleFields';
import sessionStorage from 'redux-persist/es/storage/session';
import { downloadZipFiles } from '../containers/merchants/stores/QRCodeDownloadUtil';
import { createModel } from './BaseModel';
import { defaultStep } from './StepBarUtil';
import { getDetailPhotos } from './MallModel';

const storeSessionKey = SESSION_KEYS.STORE_SESSION_KEY;;

export const saveDataToSessionStorage = (data) => {
  saveToSessionStorage(storeSessionKey, data);
};

export const removeDataFromSessionStorage = () => {
  removeFromSessionStorage(storeSessionKey);
};

function* brandApplicableStore(payload, select, put) {
  function* loadResult(data = {}, prePayload = {}) {
    const endCursor = data.endCursor || 0;
    const differCursor = endCursor - data.startCursor || 0;
    const pagedStoreList = data.stores;
    const newList = [];
    let tempKey = '';
    let finalKey = '';
    let oldTempList = [];
    console.log('prePayload:', prePayload);
    const notBrandRelate = payload.notBrandRelate;
    if (notBrandRelate) {
      oldTempList = yield select(
        (state) => state.storeModel.tempAppliableStoreList || [],
      );
      tempKey = 'tempAppliableStoreList';
      finalKey = 'appliableStoreList';
    } else {
      oldTempList = yield select(
        (state) => state.storeModel.tempBrandStoreList || [],
      );
      tempKey = 'tempBrandStoreList';
      finalKey = 'brandStoreList';
    }
    console.log("@72", oldTempList, pagedStoreList)
    const combineList = [...oldTempList, ...pagedStoreList];
    combineList.forEach((item) => {
      const exist = newList.some((newItem) => newItem.pk === item.pk);
      if (!exist) {
        newList.push(item);
      }
    });

    yield put({
      type: 'updateState',
      payload: {
        [tempKey]: newList,
      },
    });
    console.log('endCursor:', endCursor);
    if (differCursor >= 99) {
      const currentPage = (endCursor + 1) / 100 + 1;
      yield put({
        type: notBrandRelate ? 'getAppliableStores' : 'getBrandStores',
        payload: { ...prePayload, page: currentPage },
      });
      return;
    }
    yield put({
      type: 'updateState',
      payload: {
        [finalKey]: newList,
        [tempKey]: [],
      },
    });
    if (!notBrandRelate) {
      yield put({
        type: 'updateState',
        payload: {
          allListLoading: false,
        },
      });
    }
  }

  const page = payload.page || 1;
  const pageCursor = payload.page
    ? convertNumberToCursor((page - 1) * 100 - 1)
    : '';

  function* onSuccess(data) {
    const pageInfo = data?.stores?.pageInfo;
    const storeList = data?.stores?.edges.map((item) =>
      parseStore(item.node, payload?.minimalist),
    );
    const startCursor = convertCursorToNumber(pageInfo.startCursor);
    const endCursor = convertCursorToNumber(pageInfo.endCursor);
    yield loadResult(
      {
        stores: storeList,
        startCursor: startCursor,
        endCursor: endCursor,
      },
      payload,
    );
  }

  function* onError(data) {
    console.log('onErrordata:', data);
    yield loadResult({
      stores: [],
      startCursor: 0,
      endCursor: 0,
    });
  }
  console.log("@143", payload)
  const serviceArgs = [getPagedStores, pageCursor, {...payload, isSelectorLoad: true}];
  yield loading(serviceArgs, onSuccess, onError, onError);
}

const parseTranslations = (data) => {
  let result;
  if (data) {
    const translationsEdges = data.translations?.edges || [];
    let translations = {};
    if (translationsEdges.length) {
      translationsEdges.forEach((translation) => {
        const translationNode = translation.node;
        translations[translationNode.language] = { ...translationNode };
      });
    }
    translations[LanguageConfig.english] = {
      pk: data.pk,
      name: data.name,
      language: LanguageConfig.english,
    };
    result = {
      ...data,
      translations,
    };
  }
  return result;
};

const parseStoreCategoryTranslations = (
  parentCategory,
  subCategoryNode,
  storeRelatedSubCategories,
) => {
  let result;
  if (parentCategory) {
    const translationsEdges = parentCategory.translations?.edges || [];
    let translations = {};
    let subCategories = [];
    parentCategory[subCategoryNode].edges.forEach((subCategory) => {
      if (storeRelatedSubCategories) {
        if (storeRelatedSubCategories.includes(subCategory.node?.pk)) {
          subCategories.push(parseTranslations(subCategory.node));
        }
      } else {
        subCategories.push(parseTranslations(subCategory.node));
      }
    });
    if (translationsEdges.length) {
      translationsEdges.forEach((translation) => {
        const translationNode = translation.node;
        translations[translationNode.language] = {
          ...translationNode,
          name: `[ID:${translationNode.pk}] ${translationNode.name}`,
          subNames: subCategories.map((subCategory) =>
            subCategory?.translations[translationNode.language]?.name
              ? `[ID:${subCategory.pk}] ${
                  subCategory?.translations[translationNode.language]?.name
                }`
              : '',
          ),
        };
      });
    }
    translations[LanguageConfig.english] = {
      pk: parentCategory.pk,
      name: `[ID:${parentCategory.pk}] ${parentCategory.name}`,
      language: LanguageConfig.english,
      subNames: subCategories.map((subCategory) =>
        subCategory?.name ? `[ID:${subCategory.pk}] ${subCategory.name}` : '',
      ),
    };
    result = {
      ...parentCategory,
      translations,
    };
  }
  return result;
};

const getStoreCategories = (store) => {
  const storeRelatedLv2Categories = store.level2Categories?.edges.map((item) => item.node?.pk);
  const level1Categories = store.level1Categories?.edges?.map((item) =>
    parseStoreCategoryTranslations(item.node, 'lv2categories', storeRelatedLv2Categories)
  );
  const level3Categories = store.level3Categories?.edges?.map((item) =>
    parseStoreCategoryTranslations(item.node, 'lv4categories')
  );
  return {
    level1Categories,
    level3Categories,
  }
};

const getStoreByPropertyType = (store) => {
  const propertyType = store.propertyType;
  let district;
  let mallOrFreshMarket;
  let latitude;
  let longitude;
  if (propertyType === 'MALL_STORE') {
    district = parseTranslations(store.mall?.district);
    mallOrFreshMarket = parseTranslations(store.mall);
    latitude = store.mall?.latitude;
    longitude = store.mall?.longitude;
  } else if (propertyType === 'FRESH_MARKET_STORE') {
    district = parseTranslations(store.freshMarket?.mall?.district);
    mallOrFreshMarket = parseTranslations(store.freshMarket);
    latitude = store.freshMarket?.mall?.latitude;
    longitude = store.freshMarket.mall?.longitude;
    longitude = store.freshMarket.mall?.longitude;
  }
  return {
    ...store,
    district,
    mallOrFreshMarket,
    latitude,
    longitude,
  };
}

const getTranslations = (data) => {
  const translationList = data.translations?.edges || [];
  const { district, mallOrFreshMarket } = getStoreByPropertyType(data);
  const brand = parseTranslations(data.brand);
  const translation = {};
  translationList.forEach((item) => {
    const language = item.node.language;
    translation[language] = {
      ...item.node,
      name: item.node.name,
      districtName: district?.translations[language]?.name,
      brandName: brand ? `[ID:${brand.pk}] ${brand?.translations[language]?.name}` : '',
      mallOrFreshMarketName: mallOrFreshMarket ? `[ID:${mallOrFreshMarket.pk}] ${mallOrFreshMarket?.translations[language]?.name}` : '',
    };
  });
  translation[LanguageConfig.english] = {
    name: data.name,
    address: data.address,
    description: data.description,
    pk: data.pk,
    openingHours: data.openingHours,
    districtName: district?.name,
    brandName: brand ? `[ID:${brand.pk}] ${brand?.name}` : '',
    mallOrFreshMarketName: mallOrFreshMarket ? `[ID:${mallOrFreshMarket.pk}] ${mallOrFreshMarket?.name}` : '',
  };
  return translation;
};

const postTranslations = (data) => {
  if (!data) {
    return [];
  }
  const languageList = Object.keys(data).filter(
    (language) => language !== LanguageConfig.english,
  );
  const translations = languageList.map((language) => {
    const newData = {
      language,
      name: data[language].name,
      address: data[language].address,
      description: data[language].description,
    };
    const id = data[language].pk;
    if (id) {
      newData.id = id;
    }
    return newData;
  });
  return translations;
};

const mergeOldData = (origin, old) => {
  const translationLang = Object.keys(origin?.storeTranslations || {}) || [];
  const storeTranslations = {};
  translationLang.forEach((translation) => {
    storeTranslations[translation] = {
      ...old?.storeTranslations?.[translation],
      ...origin?.storeTranslations?.[translation],
    };
    if (!origin.storePK) {
      delete storeTranslations[translation].pk;
    }
  });
  return {
    ...origin,
    storeTranslations: storeTranslations,
  };
};

const postData = (origin, old) => {
  const name =
    origin.storeTranslations?.[LanguageConfig.english]?.name || origin.name;
  if (!name) {
    return {
      ...origin,
    };
  }
  const data = mergeOldData(origin, old);
  const newData = {
    name: name,
    displayPriority: data.displayPriority,
    photo: getFileNameFromUrl(
      data.coverPhoto?.value || data.coverPhoto,
    ),
    district: data.district?.value?.pk || null,
    address: data.storeTranslations[LanguageConfig.english]?.address,
    // phoneNumberCountryCode: data.phoneNumberCountryCode?.value,
    phoneNumber: data.phoneNumber,
    emailAddress: data.emailAddress,
    description: data.storeTranslations[LanguageConfig.english]?.description,
    brand: data.brand?.pk,
    subcategories: data.selectedSubcategories?.map((item) => item.pk),
    latitude: data.latitude,
    longitude: data.longitude,
    staffCode: data.staffCode,
    translations: postTranslations(data.storeTranslations),
  };
  if (data.pk) {
    newData.id = data.pk;
    if (old.brand) {
      delete newData.brand
    }
  }
  return newData;
};

const parseStore = (store, minimalist) => {
  if (!store) {
    return {};
  }
  if (minimalist) {
    return {
      pk: store.pk,
      storePK: store.pk,
      storeName: store.name,
      name: store.name,
    };
  }
  const { district, latitude, longitude, mallOrFreshMarket } = getStoreByPropertyType(store);
  const { level1Categories, level3Categories } = getStoreCategories(store);
  return {
    ...store,
    storeID: store.id,
    storePK: store.pk,
    storeName: store.name,
    // storePhoto: store.photo,
    storeAddress: store.address,
    storeTranslations: getTranslations(store),
    coverPhoto: store.photo,
    detailPhotos: getDetailPhotos(store),
    phoneNumber: store.phoneNumber,
    displayPhoneNumner: store.phoneNumber,
    displaySubcategory: store.level2Categories?.edges
      .map((item) => item.node.name)
      .join(','),
    selectedSubcategories: store.subcategories?.edges.map((item) => item.node) || [],
    // displaySubcategory:
    //   'Western, Chinese, German, French, Hunan, Cantonese, Northeastern, Jiangsu and Zhejiang cuisine, Sichuan cuisine...',
    // order: store.displayPriority,
    displayDistrict: district?.name,
    displayBrand: store.brand?.name,
    displayMallOrFM: mallOrFreshMarket?.name,
    district: store.district
      ? { label: district?.name, value: district }
      : null,
    // phoneNumberCountryCode: {
    //   label: store.phoneNumberCountryCode,
    //   value: store.phoneNumberCountryCode,
    // },
    displayName: store.name,
    codeDisplayImage: store.codeDisplayImage,
    codeDownloadImage: store.codeDownloadImage,
    latitude,
    longitude,
    level1Categories,
    level3Categories,
  };
};

const getInitState = () => {
  return {
    storeList: [],
    allListLoading: false,
    selectedAllListLoading: false,
    storeEditMode: false,
    tempBrandStoreList: [],
    tempAppliableStoreList: [],
    appliableStoreList: [],
    brandStoreList: [],
    listDisplayFields: [
      { displayName: 'ID', fieldName: 'pk' },
      { displayName: 'Name', fieldName: 'name', orderField: 'name' },
      { displayName: 'Cover photo', fieldName: 'coverPhoto' },
      { displayName: 'Staff code', fieldName: 'staffCode' },
      // {
      //   displayName: 'Display order',
      //   fieldName: 'order',
      //   orderField: 'displayPriority',
      // },
      { displayName: 'Brand', fieldName: 'displayBrand' },
      { displayName: 'Mall / FM', fieldName: 'displayMallOrFM' },
      { displayName: 'Phone number', fieldName: 'phoneNumber' },
      // { displayName: 'Email', fieldName: 'emailAddress' },
      { displayName: 'Subcategory', fieldName: 'displaySubcategory', lines: 5 },
    ],
    codeListDisplayFields: [
      { displayName: 'ID', fieldName: 'pk', orderField: 'pk' },
      { displayName: 'Cover Photo', fieldName: 'codeDisplayImage' },
      { displayName: 'Related Store', fieldName: 'displayName' },
    ],
    pagedStoreList: [],
    pageInfo: {
      startCursor: '',
      endCursor: '',
      hasNextPage: false,
      hasPreviousPage: false,
    },
    totalPage: 0,
    totalCount: 0,
    currentPage: 0,
    checkedList: [],
    oneStore: {},
    checked: CheckStatus.initOrNotChecked,
    errorFields: {},
    saved: SavedStatus.init,
    formChanged: false,
    districtList: [],
    formHasSubmitted: false,
    hasUpdatedDefaultValues: false,
    regionList: [],
  };
};

export default createModel({
  namespace: 'storeModel',
  state: getInitState(),
  params: {
    sessionKey: SESSION_KEYS.STORE_SESSION_KEY,
    listAPI: getPagedStores,
    parse: (data) => data?.stores?.edges?.map((item) => item.node),
    objectKey: 'storeList',
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    loadDataFromCookie(state, { payload }) {
      const sessionStore = getObjectFromSessionStorage(storeSessionKey) || {};
      let tempStore = payload?.data ? payload?.data : {};
      console.log('@@122: session', sessionStore.description);
      console.log('@@122-2: server', tempStore.description);

      // if (!payload?.isView) {
      tempStore.name = {
        [LanguageConfig.english]: tempStore.name || null,
        [LanguageConfig.traditionalChinese]: tempStore.storeTranslations
          ? tempStore.storeTranslations[LanguageConfig.traditionalChinese]?.name
          : null,
        [LanguageConfig.simplifiedChinese]: tempStore.storeTranslations
          ? tempStore.storeTranslations[LanguageConfig.simplifiedChinese]?.name
          : null,
      };
      // console.log('@@132: ', tempStore.name, tempStore.storeTranslations);
      // console.log('@@133: ', tempStore.name);
      tempStore.address = {
        [LanguageConfig.english]: tempStore.address || null,
        [LanguageConfig.traditionalChinese]: tempStore.storeTranslations
          ? tempStore.storeTranslations[LanguageConfig.traditionalChinese]
              ?.address
          : null,
        [LanguageConfig.simplifiedChinese]: tempStore.storeTranslations
          ? tempStore.storeTranslations[LanguageConfig.simplifiedChinese]
              ?.address
          : null,
      };
      tempStore.description = {
        [LanguageConfig.english]: tempStore.description || null,
        [LanguageConfig.traditionalChinese]: tempStore.storeTranslations
          ? tempStore.storeTranslations[LanguageConfig.traditionalChinese]
              ?.description
          : null,
        [LanguageConfig.simplifiedChinese]: tempStore.storeTranslations
          ? tempStore.storeTranslations[LanguageConfig.simplifiedChinese]
              ?.description
          : null,
      };
      console.log('@@122-3: server', tempStore.description);
      // console.log(
      //   '@@128-2: ',
      //   sessionStore,
      //   removeUndefinedFieldFromDict(sessionStore),
      // );
      console.log(
        '@@122------session: ',
        sessionStore.description,
        removeUndefinedFieldFromDict(sessionStore).description,
      );
      let data = { ...tempStore };
      if (!payload?.isView) {
        data = { ...tempStore, ...removeUndefinedFieldFromDict(sessionStore) };
      }

      // console.log('@@128-3: ', data);
      // }
      console.log(
        '@@122-4: ',
        removeUndefinedFieldFromDict(sessionStore).description,
        data.description,
      );
      return {
        ...state,
        oneStore: data,
        hasUpdatedDefaultValues: true,
      };
    },
    changeVals(state, { payload }) {
      const sessionStore = getObjectFromSessionStorage(storeSessionKey) || {};
      console.log('@@122vals changed: ', payload.vals);
      let data = {};
      if (payload.vals) {
        data = payload.vals;
      }
      // console.log('@@180-1: ', sessionStore);
      // console.log('@@180-2: ', data);
      // console.log('@@180-3:', {
      //   ...sessionStore,
      //   ...removeUndefinedFieldFromDict(data),
      //   name: {
      //     ...sessionStore.name,
      //     ...removeUndefinedFieldFromDict(data.name),
      //   },
      //   address: {
      //     ...sessionStore.address,
      //     ...removeUndefinedFieldFromDict(data.address),
      //   },
      //   description: {
      //     ...sessionStore.description,
      //     ...removeUndefinedFieldFromDict(data.description),
      //   },
      // });
      let changedData = {
        ...sessionStore,
        ...removeUndefinedFieldFromDict(data),
      };

      const changedName = removeUndefinedFieldFromDict(data.name);
      if (Object.keys(changedName).length > 0) {
        console.log('@@220: ', changedName);
        changedData = {
          ...changedData,
          name: {
            ...sessionStore.name,
            ...changedName,
          },
        };
      }

      const changedAddress = removeUndefinedFieldFromDict(data.address);
      if (Object.keys(changedAddress).length > 0) {
        console.log('@@220: ', changedAddress);
        changedData = {
          ...changedData,
          address: {
            ...sessionStore.address,
            ...changedAddress,
          },
        };
      }

      const changedDescription = removeUndefinedFieldFromDict(data.description);
      if (Object.keys(changedDescription).length > 0) {
        console.log('@@220: ', changedDescription);
        changedData = {
          ...changedData,
          description: {
            ...sessionStore.description,
            ...changedDescription,
          },
        };
      }
      console.log('@@250: ', changedData, data.description, changedDescription);
      saveToSessionStorage(storeSessionKey, changedData);
      return {
        ...state,
        formChanged: true,
      };
    },
    checkValsValid(state, { payload }) {
      const sessionStore = getObjectFromSessionStorage(storeSessionKey) || {};

      const store = {
        ...state.oneStore,
        ...removeUndefinedFieldFromDict(sessionStore),
      };

      let errorFields = { fields: [], messages: [] };
      let checked = CheckStatus.initOrNotChecked;

      Object.keys(StoreErrorHandleFields).map((field) => {
        const emailValid = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (['name', 'address', 'description'].includes(field)) {
          if (!store[field].en) {
            errorFields.fields.push(field);
            errorFields.messages.push({ field, errorType: 'required' });
          }
        } else if (
          field !== 'emailAddress' &&
          (!store[field] || store[field].length <= 0)
        ) {
          errorFields.fields.push(field);
          errorFields.messages.push({ field, errorType: 'required' });
        } else if (field === 'staffCode' && store[field].length !== 6) {
          errorFields.fields.push(field);
          errorFields.messages.push({ field, errorType: 'length' });
        } else if (
          field === 'emailAddress' &&
          store[field]?.length > 0 &&
          !emailValid.test(store[field])
        ) {
          // console.log('@@266: ', store[field]?.length);
          errorFields.fields.push(field);
          errorFields.messages.push({ field, errorType: 'valid' });
        }
      });

      if (errorFields.fields.length > 0) {
        checked = CheckStatus.checkedWithFail;
      } else {
        checked = CheckStatus.checkedWithSuccess;
      }
      console.log('@@errorFields: ', errorFields);
      return {
        ...state,
        checked,
        errorFields,
      };
    },
    removeFromCookie(state, { payload }) {
      removeFromSessionStorage(storeSessionKey);
      return { ...state, formChanged: false };
    },
    clearData(state, { payload }) {
      return { ...state, ...getInitState() };
    },
  },
  effects: {
    *getAllStores({ payload }, { call, put, select }) {
      const response = yield call(getStores);
      const storeInfo = response.data.data.stores;
      if (storeInfo) {
        const storeList = storeInfo.edges;
        yield put(
          createAction('updateState')({
            storeList: storeList.map((item) => parseStore(item.node)),
          }),
        );
      }
    },
    *getAllStoresOfBrand({ payload }, { call, put, select }) {
      const { brandID, afterCursor } = payload;
      const response = yield call(getStoresOfBrand, brandID, afterCursor);
      if (!response || response.status >= 300 || response.data.errors) {
        return;
      }
      const storeInfo = response.data?.data?.brand?.stores;
      if (storeInfo) {
        const storeList = storeInfo.edges;
        yield put(
          createAction('updateState')({
            storeList: storeList.map((item) => parseStore(item.node)),
          }),
        );
        const pageInfo = storeInfo.pageInfo;
        if (pageInfo.hasNextPage) {
          yield put(
            createAction('getAllStoresOfBrand')({
              brandID: brandID,
              afterCursor: pageInfo.endCursor,
            }),
          );
        }
      }
    },
    getPagedStoreList: [
      function* ({ payload }, { put, select }) {
        const page = payload.page || 1;
        const pageSize = payload.pageSize || 20;
        const pageCursor = payload.page
          ? convertNumberToCursor((page - 1) * pageSize - 1)
          : '';
        const serviceArgs = [getPagedStores, pageCursor, payload];
        function* onSuccess(data) {
          const pageInfo = data?.stores?.pageInfo;
          const currentLastCursor = pageInfo?.endCursor;
          const totalCount = data?.stores?.totalCount;

          const storeList = data?.stores?.edges.map((item) =>
            parseStore(item.node),
          );
          if (payload.isSelectorLoad) {
            const stateStoreList = yield select(
              (state) => state.storeModel.storeList || []
            );
            yield put({
              type: 'updateState',
              payload: {
                storeList:
                  page > 1 ? [...stateStoreList, ...storeList] : storeList,
              },
            });
          } else {
            yield put({
              type: 'updateState',
              payload: {
                pagedStoreList: storeList,
                pageInfo: {
                  startCursor: convertCursorToNumber(pageInfo.startCursor) + 1 || 0,
                  endCursor: convertCursorToNumber(pageInfo.endCursor) + 1 || 0,
                },
                currentLastCursor,
                totalCount,
                totalPage: Math.ceil(totalCount / 20),
              },
            });
          }
        }
        if (!payload?.isLoading) {
          yield apiWithResponseHandle(serviceArgs, onSuccess);
        } else {
          yield loading(serviceArgs, onSuccess);
        }
      },
      { type: 'takeLatest' },
    ],
    doStoreRequest: [
      function* ({ payload }, { put }) {
        yield put({ type: 'updateState', payload: { allListLoading: true } });
        const newPayload = {
          ...payload,
          pageSize: 100,
          minimalist: true,
          isSelectorLoad: true,
        };
        console.log('doStoreRequest:', payload);
        const notBrandRelate = payload.notBrandRelate;

        yield put({
          type: 'updateState',
          payload: notBrandRelate
            ? { appliableStoreList: [] }
            : { brandStoreList: [] },
        });
        yield put({
          type: notBrandRelate ? 'getAppliableStores' : 'getBrandStores',
          payload: newPayload,
        });
      },
      { type: 'takeLatest' },
    ],

    getBrandStores: [
      function* ({ payload }, { put, select }) {
        yield brandApplicableStore(payload, select, put);
      },
      { type: 'takeLatest' },
    ],

    getAppliableStores: [
      function* ({ payload }, { put, select }) {
        yield brandApplicableStore(payload, select, put);
      },
      { type: 'takeLatest' },
    ],

    delete: [
      function* ({ payload }, { select, put, all }) {
        const { checkedSegments } = yield select((state) => ({
          checkedSegments: state.storeModel.checkedList,
        }));

        let pks = [];
        checkedSegments.forEach((item) => {
          pks.push(item.pk);
        });

        const serviceArgs = [deleteStores, pks];
        const afterAction = payload.afterAction || (() => {});
        function* onSuccess(data) {
          console.log('@@153: ', data);
          yield all([
            put({
              type: 'storeModel/updateState',
              payload: { checkedList: [] },
            }),
          ]);

          afterAction();
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getOneStore: [
      function* ({ payload }, { call, select, put }) {
        const serviceArgs = [
          getOneStore,
          convertPKToId('StoreNode', payload.id),
        ];
        function* onSuccess(data) {
          const store = parseStore(data.store)
          yield put({
            type: 'updateState',
            payload: {
              oneStore: store,
              isView: payload.view || false,
            },
          });
          const action = payload?.afterAction || (() => {});
          yield action(store);
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    createOrUpdate: [
      function* ({ payload }, { call, select, put }) {
        let data = payload.data;
        // let source = {};
        console.log("@534", payload, data)
        if (!data) {
          return;
        }
        // if (!data) {
        //   console.log("@536")
        //   const serverStore = yield select(
        //     (state) => state.storeModel.oneStore,
        //   );
        //   const sessionStore =
        //     getObjectFromSessionStorage(storeSessionKey) || {};
        //   source = {
        //     ...serverStore,
        //     ...removeUndefinedFieldFromDict(sessionStore),
        //   };
        //   data = {
        //     name: source.name.en,
        //     displayPriority: source.displayPriority,
        //     photo: getFileNameFromUrl(
        //       source.coverPhoto.value || source.coverPhoto,
        //     ),
        //     address: source.address.en,
        //     phoneNumberCountryCode:
        //       source.phoneNumberCountryCode?.value ||
        //       source.phoneNumberCountryCode,
        //     phoneNumberSubscriberNumber: source.phoneNumberSubscriberNumber,
        //     latitude: source.latitude,
        //     longitude: source.longitude,
        //     emailAddress: source.email || source.emailAddress,
        //     description: source.description.en,
        //     staffCode: source.staffCode,
        //     subcategories: source.subcategories.map((item) => item.pk),
        //     district: source.district?.pk || source.district?.value.pk,
        //     // brand: source.brand?.pk || source.brand?.value?.pk,
        //     translations:
        //       source.storeTranslations &&
        //       Object.keys(source.storeTranslations).length > 0
        //         ? Object.keys(source.storeTranslations).map((lang) => ({
        //             language: source.storeTranslations[lang].language,
        //             name: source.name[source.storeTranslations[lang].language],
        //             address:
        //               source.address[source.storeTranslations[lang].language],
        //             description:
        //               source.description[
        //                 source.storeTranslations[lang].language
        //               ],
        //             id: source.storeTranslations[lang].pk,
        //           }))
        //         : [
        //             LanguageConfig.traditionalChinese,
        //             LanguageConfig.simplifiedChinese,
        //           ].map((lang) => ({
        //             language: lang,
        //             name: source.name[lang],
        //             address: source.address[lang],
        //             description: source.description[lang],
        //           })),
        //     // translations: [
        //     //   LanguageConfig.traditionalChinese,
        //     //   LanguageConfig.simplifiedChinese,
        //     // ].map((lang) => ({
        //     //   language: lang,
        //     //   name: source.name[lang],
        //     //   address: source.address[lang],
        //     //   description: source.description[lang],
        //     // })),
        //   };

        //   if (!serverStore.brand) {
        //     data.brand = source.brand?.pk || source.brand?.value?.pk;
        //   }

        //   if (source.pk) {
        //     data.id = source.pk;
        //     // delete data.brand;
        //   }
        // }
        yield put({
          type: 'updateState',
          payload: { saved: SavedStatus.saving },
        });
        const oldData = yield select((state) => state.storeModel.oneStore);
        const source = postData(data, oldData);
        const afterAction = payload.afterAction || (() => {});
        const serviceArgs = [createOrUpdateStore, source];
        function* onSuccess(data) {
          if (
            ('createStore' in data && data.createStore.errors) ||
            ('updateStore' in data && data.updateStore.errors)
          ) {
            yield put({
              type: 'updateState',
              payload: {
                saved: SavedStatus.savedWithFail,
                formChanged: false,
                checked: CheckStatus.initOrNotChecked,
              },
            });
          } else {
            const updateData = {
              formChanged: false,
              saved: SavedStatus.savedWithSuccess,
              checked: CheckStatus.initOrNotChecked,
              formHasSubmitted: true,
            };
            if (data.createStore?.node?.pk) {
              updateData.oneStore = { storePK: data.createStore?.node?.pk };
            }
            yield put({
              type: 'updateState',
              payload: updateData,
            });

            removeFromSessionStorage(storeSessionKey);
          }

          afterAction();
        }

        function* onFail(data) {
          yield put({
            type: 'updateState',
            payload: {
              formChanged: false,
              saved: SavedStatus.savedWithFail,
              // formHasSubmitted: true,
              // hasUpdatedDefaultValues: true,
            },
          });
        }

        yield apiWithResponseHandle(serviceArgs, onSuccess, onFail);
      },
      { type: 'takeLatest' },
    ],
    duplicate: [
      function* ({ payload }, { call, select, put }) {
        const duplicateData = payload?.data;
        function* storeGet(store) {
          console.log("@735 store", store)
          const newStore = {
            ...store,
            storeTranslations: {
              ...store.storeTranslations,
              en: {
                ...store.storeTranslations.en,
                name: `Copy of ${store.name}`,
              },
            },
            pk: null,
            storePK: null,
          };
          yield put(
            createAction('createOrUpdate')({
              data: newStore,
              afterAction: payload.afterAction,
            }),
          );
        }
        yield put({
          type: 'getOneStore',
          payload: { id: duplicateData?.pk, afterAction: storeGet },
        });
      },
      { type: 'takeLatest' },
    ],
    getDistrictList: [
      function* ({ payload }, { select, put }) {
        const page = payload.page || 1;
        const pageCursor = payload.page
          ? convertNumberToCursor((page - 1) * 50 - 1)
          : '';
        const serviceArgs = [getDistricts, pageCursor, payload];
        function* onSuccess(data) {
          const districtList = data?.districts?.edges.map((item) => item.node);
          if (payload.isSelectorLoad) {
            const { stateDistrictList } = yield select((state) => ({
              stateDistrictList: state.storeModel?.districtList,
            }));
            yield put({
              type: 'updateState',
              payload: {
                districtList:
                  page > 1
                    ? [...stateDistrictList, ...districtList]
                    : districtList,
              },
            });
          } else {
            yield put({
              type: 'updateState',
              payload: {
                districtList: districtList,
              },
            });
          }
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    downloadStoreCodes: [
      function* ({ payload }, { select }) {
        const { checkedSegments } = yield select((state) => ({
          checkedSegments: state.storeModel.checkedList,
        }));
        const pks = [];
        checkedSegments.forEach((item) => {
          pks.push(item.pk);
        });
        const serviceArgs = [getDownloadStoreCodesZip, { ids: pks }];

        function* onSuccess(data) {
          const link = data?.downloadStores?.zipLink;
          downloadZipFiles(link);
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getStoreGroupByMall:[
      function* ({ payload }, { put, select }) {
        const serviceArgs = [getStoreGroupByMall, '', payload];
        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: {
              storeByMallList: data?.stores?.edges?.map((item) => item.node)
            },
          });
        
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getAllStoreList:[
      function* ({ payload }, { put, select }) {
        yield put({
          type: 'updateState',
          payload: {
            allListLoading: true,
          },
        });
        const serviceArgs = [getAllStoreList, '', payload];
        function* onSuccess(data) {
          yield put({
            type: 'updateState',
            payload: {
              allStoreList: data?.stores?.edges?.map((item) => item.node),
              allListLoading: false,
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getAllMallStoreList:[
      function* ({ payload }, { put, select }) {
        const storeListName = payload?.filterByFreshMarket ? 'allFreshMarketStoreList' : 'allMallStoreList'
        payload['propertyTypeIn'] = payload?.filterByFreshMarket ? ['FRESH_MARKET_STORE'] : ['MALL_STORE']
        yield put({
          type: 'updateState',
          payload: {
            allListLoading: true,
          },
        });
        const serviceArgs = [getAllStoreList, '', payload];
        function* onSuccess(data) {
          console.log("@1016 mallStoreList", data?.stores?.edges?.map((item) => item.node))
          const storeList = data?.stores?.edges?.map((item) => item.node);
          yield put({
            type: 'updateState',
            payload: {
              [storeListName]: storeList,
              allListLoading: false,
            },
          });
          if (payload?.afterAction) {
            payload.afterAction(storeList);
          }
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
    getRegionsList:[
      function* ({ payload }, { put }) {
        const serviceArgs = [getRegions, '', payload];
        function* onSuccess(data) {
          const regionList = data?.regions?.edges.map((item) => item.node);
          yield put({
            type: 'updateState',
            payload: {
              regionList,
            },
          });
        }
        yield loading(serviceArgs, onSuccess);
      },
      { type: 'takeLatest' },
    ],
  },
});
