import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ContentSections from '../base/ContentSections';
import MembershipInfoCard from '../customer/MembershipInfoCard';
import MessageOverviewTableSection from './MessageOverviewTableSection';
import {
  BackAndMultipleButtons,
  SaveTempButton,
  SaveButton,
} from '../base/BottomStepComponent';
import { formatDate, TimeFormater } from '../../utils/TimeFormatUtil';
import TestAccountPrompt from './TestAccountPrompt';
import CreateMessageSuccessPrompt from './CreateMessageSuccessPrompt';
import { PermissionCodes } from '../../config/PermissionCodes';
import BasePrompt from '../base/prompt/BasePrompt';
import ListButtonsGroup from '../base/ListButtonsGroup';
import { validate } from '../../containers/engagement/message/CreateMessageValidate';
import { ActionType, ApprovalStatus, MessageChannel } from '../../config/CustomEnums';
import { createAction } from '../../utils';
import RejectPrompt from '../campaign/campaignCreation/RejectPrompt';


function CreateMessageStepFour({
  languages,
  isSuperuser,
  userPermissions,
  formHasSubmitted,
}) {
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const { watch, getValues, setValue, control, formState, setError, clearErrors } = useFormContext();
  const { errors } = formState;

  const message = getValues();
  message.displayChannels = message.channels?.toString();
  message.relatedCampaignName = message.relatedCampaign.name;
  message.displayTargetCustomer = (message.targetCustomerGroup || []).map((item) => item.name).toString();
  message.displayTargetedSegments = (message.targetedSegments || []).map((item) => item.name).toString();
  message.deliveryDate = formatDate(message.scheduledDate, TimeFormater.dayMonthYearWeekTimeA);
  const isSelectFullScreenAd = message.channels?.indexOf(MessageChannel.fullScreenAd) !== -1;
  if (isSelectFullScreenAd) {
    const startDate = formatDate(message.scheduledPeriodStartDate, TimeFormater.dayMonthYearWeek);
    const endDate = formatDate(message.scheduledPeriodEndDate, TimeFormater.dayMonthYearWeek);
    message.deliveryDate = `${startDate} - ${endDate}`;
  }
  if (location.pathname.includes('edit')) {
    message.relatedCampaignName = message.relatedCampaign.pk ? `[ID:${message.relatedCampaign.pk}] ${message.relatedCampaign.name}` : '-';
  }

  const [showRejectPrompt, setShowRejectPrompt] = useState(false);
  const [showTestPrompt, setShowTestPrompt] = useState(false);
  const [showSentPrompt, setShowSentPrompt] = useState(false);
  const [isTestSend, setIsTestSend] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [showTimeoutPrompt, setShowTimeoutPrompt] = useState(false);

  const [actionType, setActionType] = useState(ActionType.none);
  const approvalStatus = getValues('approvalStatus');
  let saveActionType = null;
  let tempActionType = null;
  const hasPermission = (requires) => {
    if (isSuperuser) return true;
    if (userPermissions.includes(requires) || !requires) return true;

    return false;
  };

  const stepChangeAction = (isBack) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 3,
      isBack,
    });
    dispatch({
      type: 'createMessage/stepChange',
      payload: {
        isBack: isBack,
        step: 3,
        isValid
      },
    });
  };


  const renderTabForLanguage = (language) => {
    return [
      <MessageOverviewTableSection
        message={message}
        language={language}
      />,
      <MembershipInfoCard
        title={'PROPERTIES'}
        data={message}
        fields={[
          [{ title: 'Message channel', field: 'displayChannels' }],
          [{ title: 'Related campaign', field: 'relatedCampaignName' }],
          [{ title: 'Target customers', field: 'displayTargetCustomer' }],
          [{ title: 'Target segments', field: 'displayTargetedSegments' }],
          [{ title: 'Delivery Time', field: 'deliveryDate' }],
        ]}
      />,
    ];
  };

  const saveButtonAction = (isSaveOnly, buttonAction = null) => {
    if (isSelectFullScreenAd) {
      const nowDate = new Date(new Date().setHours(0,0,0,0));
      if (new Date(message.scheduledPeriodStartDate) < nowDate) {
        setShowTimeoutPrompt(true);
        return;
      }
    } else {
      if (new Date(message.scheduledDate) < new Date()) {
        setShowTimeoutPrompt(true);
        return;
      }
    }
    // setIsDraft(isSaveOnly);
    // if (location.pathname.includes('edit')) {
    //   dispatch({
    //     type: 'createMessage/updateMessage',
    //     payload: {
    //       isDraft: isSaveOnly,
    //       values: getValues(),
    //       languages: languages,
    //       afterActions: () => {
    //         setIsTestSend(false);
    //         setShowSentPrompt(true);
    //       },
    //     },
    //   });
    // } else {
    //   dispatch({
    //     type: 'createMessage/createMessage',
    //     payload: {
    //       isDraft: isSaveOnly,
    //       values: getValues(),
    //       languages: languages,
    //       afterActions: () => {
    //         setIsTestSend(false);
    //         setShowSentPrompt(isSaveOnly);
    //       },
    //     },
    //   });
    // }
    setActionType(buttonAction)
    if (buttonAction === ActionType.withdraw || buttonAction === ActionType.unpublish) {
      dispatch(
        createAction('createMessage/updateApprovalStatus')({
          data: getValues(),
          languages: languages,
          actionType: buttonAction,
          afterActions: () => {
            setIsTestSend(false);
            setShowSentPrompt(true);
          },
        }),
      )
    } else {
      dispatch(
        createAction('createMessage/createOrUpdateMessage')({
          values: getValues(),
          languages: languages,
          actionType: buttonAction,
          afterActions: () => {
            setIsTestSend(false);
            setShowSentPrompt(true);
          },
        }),
      );
    }
  };

  const getTempText = () => {
    switch (approvalStatus) {
      case ApprovalStatus.draft:
        tempActionType = ActionType.update
        return 'Update'
      case ApprovalStatus.pendingForApproval:
        if (hasPermission(PermissionCodes.approvalMessage)) {
          tempActionType = ActionType.update
          return 'Update'
        }
        tempActionType = ActionType.withdraw
        return 'Withdraw'
      case ApprovalStatus.pendingForPublish:
        if (hasPermission(PermissionCodes.publishMessageForS3)){
          tempActionType = ActionType.update
          return 'Update'
        }
        tempActionType = ActionType.withdraw
        return 'Withdraw'
      case ApprovalStatus.published:
        tempActionType = ActionType.tempForPulished
        return 'Update'
      default:
        tempActionType = ActionType.save
        return 'Save as a darft'
    }
  }
  
  const hasTempPermission = () => {
    switch (approvalStatus) {
      case ApprovalStatus.draft:
        return hasPermission(PermissionCodes.changeMessageForS1)
      case ApprovalStatus.pendingForApproval:
        return hasPermission(PermissionCodes.withdrawMessageForS2) ||
              hasPermission(PermissionCodes.changeMessageForS2)
      case ApprovalStatus.pendingForPublish:
        return hasPermission(PermissionCodes.withdrawMessageForS3ToS2) ||
              hasPermission(PermissionCodes.withdrawMessageForS3ToS1) ||
              hasPermission(PermissionCodes.changeMessageForS3)
      case ApprovalStatus.published:
        return hasPermission(PermissionCodes.tempForMessagePulished)
      default:
        return hasPermission(PermissionCodes.addMessage)
    }
  }
  
  const getSaveText = () => {
    switch (approvalStatus) {
      case ApprovalStatus.published:
        saveActionType = ActionType.unpublish
        return 'Unpublish'
      case ApprovalStatus.pendingForApproval:
        if (hasPermission(PermissionCodes.approvalMessage)) {
          saveActionType = ActionType.approval
          return 'Approve'
        }
        saveActionType = ActionType.pendingForApproval
        return 'Pending for approval'
      case ApprovalStatus.pendingForPublish:
        if (hasPermission(PermissionCodes.publishMessageForS3)) {
          saveActionType = ActionType.publish
          return 'Publish'
        }
        saveActionType = ActionType.pendingForPublish
        return 'Pending for publish'
      default:
        saveActionType = ActionType.submitForApproval
        return 'Submit for approval'
    }
  }
  const hasSavePermission = () => {
    switch (approvalStatus) {
      case ApprovalStatus.draft:
        return hasPermission(PermissionCodes.peddingMessage)
      case ApprovalStatus.pendingForApproval:
        return hasPermission(PermissionCodes.withdrawMessageForS2) ||
              hasPermission(PermissionCodes.approvalMessage)
      case ApprovalStatus.pendingForPublish:
        return hasPermission(PermissionCodes.withdrawMessageForS3ToS2) ||
              hasPermission(PermissionCodes.withdrawMessageForS3ToS1) ||
              hasPermission(PermissionCodes.publishMessageForS3)
      case ApprovalStatus.published:
        return hasPermission(PermissionCodes.unPublishMessageToS3) ||
              hasPermission(PermissionCodes.unPublishMessageToS2) ||
              hasPermission(PermissionCodes.unPublishMessageToS1)
      default:
        return hasPermission(PermissionCodes.addMessage)
    }
  }

  const primaryText = getSaveText();

  return (
    <>
      <ContentSections
        languageTabContent={{
          containers: languages?.map((item) => ({
            container: renderTabForLanguage(item.code),
            key: item.code,
            title: item.sourceName,
          })),
        }}
        hidePreview={true}
      />
      <BackAndMultipleButtons
        backAction={() => {
          stepChangeAction(true)
        }}
        multipleButtons={[
          <ListButtonsGroup
            hideExtraButtonWidth={600}
            hideAllButtonWidth={400}
            hideExtraPopPosition="top"
            hideAllPopPosition="topRight"
            extraButtons={[
              isSelectFullScreenAd ? null : <SaveTempButton
                text="Test Channel"
                action={() => {
                  setShowTestPrompt(true);
                }}
              />,
              hasTempPermission() ? <SaveTempButton
                text={getTempText()}
                action={() => {
                  saveButtonAction(true, tempActionType);
                }}
              /> : null,
              approvalStatus === ApprovalStatus.pendingForApproval || approvalStatus === ApprovalStatus.pendingForPublish ?
                <SaveTempButton
                  text="Reject"
                  action={() => {
                    setShowRejectPrompt(true);
                  }}
                  disabled={saveActionType === ActionType.pendingForPublish || saveActionType === ActionType.pendingForApproval}
                /> : null,
            ]}
            extraPopContent={[
              {
                content: 'Test Channel',
                action: () => {
                  setShowTestPrompt(true);
                },
                requires: PermissionCodes.testMessage,
              },
              {
                content: getTempText(),
                action: () => saveButtonAction(true, tempActionType),
              },
              {
                content: 'Reject',
                action: () => {
                  setShowRejectPrompt(true);
                },
              },
            ]}
            primaryButton={
              hasSavePermission() ?
              <SaveButton
                text={primaryText}
                action={() => saveButtonAction(false, saveActionType)}
                disabled={saveActionType === ActionType.pendingForPublish || saveActionType === ActionType.pendingForApproval}
              /> : null
            }
            primaryPopContent={{
              requires: PermissionCodes.addAdministratorGroup,
              action: () => saveButtonAction(false, saveActionType),
              content: 'Create Administrator group',
            }}
          />,
          // {
          //   text: 'Test Channel',
          //   action: () => {
          //     setShowTestPrompt(true);
          //   },
          //   requires: PermissionCodes.testMessage,
          // },
          // {
          //   text: 'Save Only',
          //   action: () => saveButtonAction(true),
          // },
          // {
          //   text: 'Schedule',
          //   action: () => saveButtonAction(false),
          // },
        ]}
      />
      <TestAccountPrompt
        show={showTestPrompt}
        onHide={() => setShowTestPrompt(false)}
        onConfirm={(selectedAccount) => {
          setShowTestPrompt(false);
          dispatch({
            type: 'createMessage/testMessageSend',
            payload: {
              isEdit: location.pathname.includes('edit'),
              values: getValues(),
              languages: languages,
              customer: selectedAccount,
              afterActions: () => {
                setIsTestSend(true);
                setShowSentPrompt(true);
              },
            },
          });
        }}
      />

      <RejectPrompt
        show={showRejectPrompt}
        onHide={() => setShowRejectPrompt(false)}
        onConfirm={(comment) => {
          setShowRejectPrompt(false);
          dispatch(
            createAction('createMessage/rejectMessage')({
              id: params.id,
              message: comment,
              isPendingForApprovalMessage: approvalStatus === ApprovalStatus.pendingForApproval,
              afterAction: () => {
                const pathname = location.pathname.split('/')[1];
                history.push({
                  pathname: `/${pathname}`
                })
              },
            }),
          )
        }}
      />

      <CreateMessageSuccessPrompt
        isTest={isTestSend}
        // isDraft={isDraft}
        actionType={actionType}
        show={showSentPrompt}
        onClose={() => setShowSentPrompt(false)}
      />

      <BasePrompt
        show={showTimeoutPrompt}
        closeAction={() => setShowTimeoutPrompt(false)}
        rightButton={{
          text: 'Reschedule',
          action: () => {
            setShowTimeoutPrompt(false);
            dispatch({
              type: 'createMessage/updateState',
              payload: {
                currentStep: 2,
              }
            });
          },
        }}
        leftButton={{
          text: 'Cancel',
          action: () => setShowTimeoutPrompt(false),
        }}
        title={'Schedule timeout'}
        description={
          'Your schecule time is expried, please update delivery time.'
        }
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  languages: state.language.allList,
  isSuperuser: state.users.isSuperuser,
  userPermissions: state.users.userPermissions,
  formHasSubmitted: state.createMessage.formHasSubmitted,
})

export default connect(mapPropsToState)(CreateMessageStepFour);
