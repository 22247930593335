import axios from 'axios';
const SHOPIFY_DOMAIN = process.env.REACT_APP_SHOPIFY_DOMAIN;
const SHOPIFY_TOKEN = process.env.REACT_APP_SHOPIFY_TOKEN;
const SHOPIFY_GRAPHQA_URL = `${SHOPIFY_DOMAIN}/api/2020-04/graphql.json`;
const Token = SHOPIFY_TOKEN;
console.log('process.env:', process.env, SHOPIFY_GRAPHQA_URL, SHOPIFY_DOMAIN);
const shopifyAPIInstance = axios.create({
  baseURL: SHOPIFY_GRAPHQA_URL,
  timeout: 5000,
  headers: {
    'X-Shopify-Storefront-Access-Token': `${Token}`,
    'Content-Type': 'application/json',
  },
});

class APIHelper {
  filterProducts = (searchKey) => {
    //totalInventory
    const query = searchKey ? `query: "title: ${searchKey}", ` : '';

    const data = JSON.stringify({
      query: `
      {
        products(${query}first: 250, sortKey: ID) {
          edges {
            node {
              id
              title
              totalInventory
              images(first: 1) {
                edges {
                    node {
                        originalSrc
                    }
                }
              }
              collections(first: 10) {
                edges {
                    cursor
                    node {
                        id
                        handle
                        title
                    }
                }
              }
              variants(first: 100) {
                edges {
                    cursor
                    node {
                        id
                        availableForSale
                        currentlyNotInStock
                        image {
                            originalSrc
                        }
                        priceV2 {
                            currencyCode
                            amount
                        }
                        compareAtPriceV2 {
                            currencyCode
                            amount
                        }
                        quantityAvailable
                        selectedOptions {
                            name
                            value
                        }
                        sku
                    }
                }
              }
            }
          }
        }
      }`,
    });
    return shopifyAPIInstance.post('', data).catch((error) => error);
  };
}

const ShopifyAPIHelper = new APIHelper();
export default ShopifyAPIHelper;
