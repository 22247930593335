import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import ImageUploader, { ReactHookFormImageUploader } from '../../base/ImageUploader';
import './KeyVisualSection.scss';
import CustomRadios from '../../base/CustomRadios';
import { CampaignDisplayIn, CampaignType, LanguageConfig } from '../../../config/CustomEnums';
import i18n from '../../../I18n';
import { Controller, useFormContext } from 'react-hook-form';
import { ApplySwitch } from '../../base/LabelWithSwitch';
import {
  errorMessage,
} from '../../base/ErrorFieldMessage';

function MissionBadgeIconsSection({
  language,
  onFocus = () => {},
}) {
  const dispatch = useDispatch();
  const { watch, setValue, formState, control, getValues } = useFormContext();
  const errors = formState.errors;
  const emptySlotBadgePhoto = watch('emptySlotBadgePhoto') || ''
  const acquiredBadgePhoto = watch('acquiredBadgePhoto') || ''
  const emptyGiftSlotBadgePhoto = watch('emptyGiftSlotBadgePhoto') || ''
  const collectedGiftBadgePhoto = watch('collectedGiftBadgePhoto') || ''
  const redeemedGiftBadgePhoto = watch(`translations.${language}.redeemedGiftBadgePhoto`) || ''
  const emptyFinalGiftSlotBadgePhoto = watch('emptyFinalGiftSlotBadgePhoto') || ''
  const redeemedFinalGiftBadgePhoto = watch(`translations.${language}.redeemedFinalGiftBadgePhoto`) || ''
  const collectedFinalGiftBadgePhoto = watch('collectedFinalGiftBadgePhoto') || ''


  const emptySlotBadgePhotoList = [emptySlotBadgePhoto]?.filter((item) => item);
  const acquiredBadgePhotoList = [acquiredBadgePhoto]?.filter((item) => item);
  const emptyGiftSlotBadgePhotoList = [emptyGiftSlotBadgePhoto]?.filter((item) => item);
  const collectedGiftBadgePhotoList = [collectedGiftBadgePhoto]?.filter((item) => item);
  const redeemedGiftBadgePhotoList = [redeemedGiftBadgePhoto]?.filter((item) => item);
  const emptyFinalGiftSlotBadgePhotoList = [emptyFinalGiftSlotBadgePhoto]?.filter((item) => item);
  const redeemedFinalGiftBadgePhotoList = [redeemedFinalGiftBadgePhoto]?.filter((item) => item);
  const collectedFinalGiftBadgePhotoList = [collectedFinalGiftBadgePhoto]?.filter((item) => item);

  const watchUploadBadgeIcons = watch('uploadBadgeIcons')

  const badgeIconItem = ({
    title,
    imageReminder = 'Suggested Image Size: 192 px * 216 px  (Less than 2MB, support JPG, PNG and GIF only.)',
    jsonReminder = 'Please fill in the JSON file code. Suggested aspect ratio of the animation is align with the image asset: 192px * 216px',
    hidden = false,
    images,
    keyword,
    minWidth = 192,
    minHeight = 216,
    allowJson = false,
  }) => {
    return hidden ? null : <>
      <label className="create-section-label create-section-label-bottom-space">
        {title}
      </label>
      <label className="create-campaign-cover-photo-reminder">
        {imageReminder}
      </label>
      {allowJson ? 
      <label className="create-campaign-cover-photo-reminder">
        {jsonReminder}
      </label> : null}
      <ImageUploader
        minWidth={minWidth}
        minHeight={minHeight}
        images={images}
        onImageStateChange={(newState) => {
          setValue(keyword, newState[0], {
            shouldDirty: true,
          });
        }}
        aspect={minWidth / minHeight}
        maxImageNum={1}
        uploadImageClicked={() => onFocus('badgeCard')}
        language={language}
        allowJson={allowJson}
        errorMessage={errorMessage(
          errors,
          keyword,
        )}
      />
    </>
  }


  return (
    <>
    {language === LanguageConfig.english ? 
    <>
    <ApplySwitch
      checked={watchUploadBadgeIcons}
      title={'Upload badge icons'}
      show={true}
      onChange={(value) => {
        setValue('uploadBadgeIcons', value, {shouldDirty: true});
      }}
    />
    <label className="create-campaign-cover-photo-reminder">
      If off, the default animation will be applied.  
    </label>
    </>
    : null }
    { watchUploadBadgeIcons ? <>
      {badgeIconItem({
        title: 'Empty badge slot',
        images: emptySlotBadgePhotoList,
        keyword: 'emptySlotBadgePhoto',
        hidden: language !== LanguageConfig.english
      })}
      {badgeIconItem({
        title: 'Collected badge',
        images: acquiredBadgePhotoList,
        keyword: 'acquiredBadgePhoto',
        hidden: language !== LanguageConfig.english,
        allowJson: true,
      })}
      {badgeIconItem({
        title: 'Empty gift badge slot',
        images: emptyGiftSlotBadgePhotoList,
        keyword: 'emptyGiftSlotBadgePhoto',
        hidden: language !== LanguageConfig.english
      })}
      {badgeIconItem({
        title: 'Collected gift',
        images: collectedGiftBadgePhotoList,
        keyword: 'collectedGiftBadgePhoto',
        hidden: language !== LanguageConfig.english,
        allowJson: true,
      })}
      {badgeIconItem({
        title: i18n.t('campaign.reddemed_gift', {locale: language}),
        imageReminder: i18n.t('campaign.badge_image_format_reminder_with_language', {locale: language}),
        jsonReminder: i18n.t('campaign.badge_json_format_reminder_with_language', {locale: language}),
        images: redeemedGiftBadgePhotoList,
        keyword: `translations.${language}.redeemedGiftBadgePhoto`,
        allowJson: true,
      })}
      {badgeIconItem({
        title: 'Empty final gift slot',
        images: emptyFinalGiftSlotBadgePhotoList,
        keyword: 'emptyFinalGiftSlotBadgePhoto',
        hidden: language !== LanguageConfig.english
      })}
      {badgeIconItem({
        title: 'Collected final gift',
        images: collectedFinalGiftBadgePhotoList,
        keyword: 'collectedFinalGiftBadgePhoto',
        hidden: language !== LanguageConfig.english,
        allowJson: true,
      })}
      {badgeIconItem({
        title: i18n.t('campaign.reddemed_final_gift', {locale: language}),
        imageReminder: i18n.t('campaign.badge_image_format_reminder_with_language', {locale: language}),
        jsonReminder: i18n.t('campaign.badge_json_format_reminder_with_language', {locale: language}),
        images: redeemedFinalGiftBadgePhotoList,
        keyword: `translations.${language}.redeemedFinalGiftBadgePhoto`,
        allowJson: true,
      })}
    </> : null}
    </>
  );
}

const mapPropsToState = (state) => ({
})

export default connect(mapPropsToState)(MissionBadgeIconsSection);
