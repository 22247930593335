import { CampaignDisplayIn } from '../config/CustomEnums';
import { getInFilterValue, getSearchKey } from '../utils';
import { BaseHelper } from './BaseGraphQLAPIHelper';

const CONTNET = `
id
pk
type
name
generalPurchaseTypeMinimumSpending
generalPurchaseTypeMaximumSpending
generalPurchaseTypeLimitedToStores {
  edges {
    node {
      id
      pk
      name
      propertyType
      staffCode
      mall {
        pk
        name
      }
      freshMarket {
        pk
        name
      }
    }
  }
}
generalPurchaseTypeLimitedToPaymentMethods {
  edges {
    node {
      id
      pk
      name
    }
  }
}
generalPurchaseTypeStampRewardTypeBonus
generalPurchaseTypeStampRewardTypeBonusType
generalPurchaseTypeStampRewardTypeBonusMultiplier
generalPurchaseTypeStampRewardTypeBonusAdditional
generalPurchaseTypeStampRewardTypeBonusCriteriaRelationship
generalPurchaseTypeStampRewardTypeBonusPaymentMethods {
  edges {
    node {
      id
      pk
      name
    }
  }
}
generalPurchaseTypeStampRewardTypeBonusStores {
  edges {
    node {
      id
      pk
      name
      propertyType
      staffCode
      mall {
        pk
        name
      }
      freshMarket {
        pk
        name
      }
    }
  }
}
generalPurchaseTypeStampRewardTypeBonusTransactionStartDatetime
generalPurchaseTypeStampRewardTypeBonusTransactionEndDatetime
generalPurchaseTypeStampRewardTypeBonusTargetCustomerGroups {
  edges {
    node {
      id
      pk
      name
    }
  }
}
generalPurchaseTypeStampRewardTypeBonusTargetCustomerSegments {
  edges {
    node {
      id
      pk
      name
    }
  }
}
generalPurchaseTypeEligibleNumberOfDaysSincePurchase
generalPurchaseTypeExcludedProducts
generalPurchaseTypeOverallLimit
generalPurchaseTypePerHeadLimit
generalPurchaseTypePeriodicLimit
generalPurchaseTypePeriodicLimitEffectiveNumberOfDays
generalPurchaseTypePerHeadPeriodicLimit
generalPurchaseTypePerHeadPeriodicLimitEffectiveNumberOfDays
generalPurchaseTypeRewardType
generalPurchaseTypePointsRewardTypePointsPerXDollarsSpent
generalPurchaseTypePointsRewardTypeX
generalPurchaseTypeBadgeRewardTypeBadgesPerXDollarsSpent
generalPurchaseTypeBadgeRewardTypeX
generalPurchaseTypeStampRewardTypeStampsPerXDollarsSpent
generalPurchaseTypeStampRewardTypeX
generalPurchaseTypeCouponRewardTypeCouponTemplate {
  pk
  id
  name
  absoluteExpiryDate
}
generalPurchaseTypeCouponRewardTypeQuantity
generalPurchaseTypeLimitedToSkus {
  edges {
    node {
      id
      pk
      skuId
      sku
      categoryName
      categoryId
    }
  }
}

memberReferralTypeBeneficiary
memberReferralTypeLimit
memberReferralTypeRewardType
memberReferralTypePointsRewardTypePoints
memberReferralTypeCouponRewardTypeCouponTemplate {
  pk
  id
  name
  absoluteExpiryDate
}
memberReferralTypeCouponRewardTypeQuantity
memberReferralTypeBadgeRewardTypeQuantity
memberReferralTypeStampRewardTypeQuantity
newMemberTypeRewardType
newMemberTypePointsRewardTypePoints
newMemberTypePromotionCodes
newMemberTypeCouponRewardTypeCouponTemplate {
  pk
  id
  name
  absoluteExpiryDate
}
newMemberTypeCouponRewardTypeQuantity
newMemberTypeBadgeRewardTypeQuantity
newMemberTypeStampRewardTypeQuantity
birthdayTypePeriodStartDate
birthdayTypePeriodEndDate
birthdayTypeRewardType
birthdayTypePointsRewardTypePoints
birthdayTypeCouponRewardTypeCouponTemplate {
  pk
  id
  name
  absoluteExpiryDate
}
birthdayTypeCouponRewardTypeQuantity
birthdayTypeBadgeRewardTypeQuantity
birthdayTypeStampRewardTypeQuantity
qrCodeScanningTypeQrCodes
qrCodeScanningTypeOverallLimit
qrCodeScanningTypePerHeadLimit
qrCodeScanningTypePeriodicLimit
qrCodeScanningTypePeriodicLimitEffectiveNumberOfDays
qrCodeScanningTypePerHeadPeriodicLimit
qrCodeScanningTypePerHeadPeriodicLimitEffectiveNumberOfDays
qrCodeScanningTypeRewardType
qrCodeScanningTypePointsRewardTypePoints
qrCodeScanningTypeBadgeRewardTypeQuantity
qrCodeScanningTypeStampRewardTypeQuantity
qrCodeScanningTypeCouponRewardTypeCouponTemplate {
  pk
  id
  name
  absoluteExpiryDate
}
qrCodeScanningTypeCouponRewardTypeQuantity
gpsCheckInTypeLatitude
gpsCheckInTypeLongitude
gpsCheckInTypeRadiusInMeter
gpsCheckInTypeOverallLimit
gpsCheckInTypePerHeadLimit
gpsCheckInTypePeriodicLimit
gpsCheckInTypePeriodicLimitEffectiveNumberOfDays
gpsCheckInTypePerHeadPeriodicLimit
gpsCheckInTypePerHeadPeriodicLimitEffectiveNumberOfDays
gpsCheckInTypeRewardType
gpsCheckInTypePointsRewardTypePoints
gpsCheckInTypeCouponRewardTypeCouponTemplate {
  pk
  id
  name
  absoluteExpiryDate
}
gpsCheckInTypeCouponRewardTypeQuantity
gpsCheckInTypeBadgeRewardTypeQuantity
gpsCheckInTypeStampRewardTypeQuantity
fillingFormTypeForm {
  pk
  id
  name
}
fillingFormTypeDefinitionType
fillingFormTypeOverallLimit
fillingFormTypePerHeadLimit
fillingFormTypePeriodicLimit
fillingFormTypePeriodicLimitEffectiveNumberOfDays
fillingFormTypePerHeadPeriodicLimit
fillingFormTypePerHeadPeriodicLimitEffectiveNumberOfDays
fillingFormTypeRewardType
fillingFormTypePointsRewardTypePoints
fillingFormTypeCouponRewardTypeCouponTemplate {
  pk
  id
  name
  absoluteExpiryDate
}
fillingFormTypeCouponRewardTypeQuantity
creationDate
lastModifiedDate
campaigns {
  edges {
    node {
      id
      pk
      name
    }
  }
}
`;

const LIST_CONTNET = `
id
pk
type
name
generalPurchaseTypeRewardType
generalPurchaseTypePointsRewardTypePointsPerXDollarsSpent
generalPurchaseTypePointsRewardTypeX
generalPurchaseTypeBadgeRewardTypeBadgesPerXDollarsSpent
generalPurchaseTypeBadgeRewardTypeX
generalPurchaseTypeStampRewardTypeStampsPerXDollarsSpent
generalPurchaseTypeStampRewardTypeX
generalPurchaseTypeStampRewardTypeBonus
generalPurchaseTypeStampRewardTypeBonusType
generalPurchaseTypeStampRewardTypeBonusMultiplier
generalPurchaseTypeStampRewardTypeBonusAdditional
generalPurchaseTypeCouponRewardTypeCouponTemplate {
  pk
  id
  name
  absoluteExpiryDate
}
generalPurchaseTypeCouponRewardTypeQuantity

memberReferralTypeRewardType
memberReferralTypePointsRewardTypePoints
memberReferralTypeCouponRewardTypeCouponTemplate {
  pk
  id
  name
  absoluteExpiryDate
}
memberReferralTypeCouponRewardTypeQuantity
memberReferralTypeBadgeRewardTypeQuantity
memberReferralTypeStampRewardTypeQuantity
newMemberTypeRewardType
newMemberTypePointsRewardTypePoints
newMemberTypeCouponRewardTypeCouponTemplate {
  pk
  id
  name
  absoluteExpiryDate
}
newMemberTypeCouponRewardTypeQuantity
newMemberTypeBadgeRewardTypeQuantity
newMemberTypeStampRewardTypeQuantity

birthdayTypeRewardType
birthdayTypePeriodStartDate
birthdayTypePeriodEndDate
birthdayTypePointsRewardTypePoints
birthdayTypeCouponRewardTypeCouponTemplate {
  pk
  id
  name
  absoluteExpiryDate
}
birthdayTypeCouponRewardTypeQuantity
birthdayTypeBadgeRewardTypeQuantity
birthdayTypeStampRewardTypeQuantity

qrCodeScanningTypeRewardType
qrCodeScanningTypePointsRewardTypePoints
qrCodeScanningTypeBadgeRewardTypeQuantity
qrCodeScanningTypeStampRewardTypeQuantity
qrCodeScanningTypeCouponRewardTypeCouponTemplate {
  pk
  id
  name
  absoluteExpiryDate
}
qrCodeScanningTypeCouponRewardTypeQuantity

gpsCheckInTypeRewardType
gpsCheckInTypePointsRewardTypePoints
gpsCheckInTypeCouponRewardTypeCouponTemplate {
  pk
  id
  name
  absoluteExpiryDate
}
gpsCheckInTypeCouponRewardTypeQuantity
gpsCheckInTypeBadgeRewardTypeQuantity
gpsCheckInTypeStampRewardTypeQuantity
fillingFormTypeForm {
  pk
  id
  name
}

fillingFormTypeRewardType
fillingFormTypePointsRewardTypePoints
fillingFormTypeCouponRewardTypeCouponTemplate {
  pk
  id
  name
  absoluteExpiryDate
}
fillingFormTypeCouponRewardTypeQuantity
creationDate
lastModifiedDate
campaigns {
  edges {
    node {
      id
      pk
      name
    }
  }
}
`;

export const getEarningRulesWithType = (
  afterCursor,
  rank,
  type,
  nameContains = '',
  otherSearch,
) => {
  let order = rank ? '-pk' : 'pk';
  if (otherSearch?.sort) {
    order = otherSearch.sort;
  }

  let moreSearch = '';
  if (otherSearch.reward_type) {
    moreSearch += `, rewardTypeIn: ${getInFilterValue(otherSearch.reward_type)}`;
  }

  if (otherSearch.campaignDisplayIn) {
    if (otherSearch.campaignDisplayIn === CampaignDisplayIn.mission) {
      moreSearch += `, rewardTypeIn: ["BADGE", "COUPON"], couponRewardAttribute:"E_CERTIFICATE_COUPON"`;
    } else if (otherSearch.campaignDisplayIn === CampaignDisplayIn.notListing) {
      moreSearch += `, rewardTypeIn: ["COUPON"], couponRewardAttribute:"E_CERTIFICATE_COUPON"`;
    }
  }

  const query = `{
    earningRules(first: 20, after: "${afterCursor}", nameIcontains: "${getSearchKey(nameContains)}", type: "${
    type === 'all' ? '' : type
  }", orderBy: "${order}"${moreSearch}) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ${LIST_CONTNET}
        }
      }
    }
  }
  `;
  const variables = {
    input: {},
  };

  // console.log('@@338: ', query);

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteEarningRules = (ids) => {
  const query = `mutation DeleteEarningRules($input: DeleteEarningRulesInput!) {
    deleteEarningRules(input: $input) {
      success
    }
  }
  `;
  const variables = {
    input: {
      ids: ids,
    },
  };

  // console.log('@@338: ', query);

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneEarningRule = (id) => {
  const query = `
  {
    earningRule(id: "${id}") {
      ${CONTNET}
      instructionSectionTitle
      instructionSectionContent
      detailSectionTitle
      detailSectionContent
      translations {
        edges {
          node {
            pk
            language
            instructionSectionTitle
            instructionSectionContent
            detailSectionTitle
            detailSectionContent
          }
        }
      }
    }
  }
  `;
  const variables = {
    input: {},
  };

  // console.log('@@338: ', query);

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const createOrUpdateEarningRule = (values) => {
  const action = values.id ? 'Update' : 'Create';
  let query = `mutation ${action}EarningRule($input: ${action}EarningRuleInput!) {
    ${action.toLowerCase()}EarningRule(input: $input) {
      success
      node {
        id
        pk
          type
          name
          instructionSectionTitle
          instructionSectionContent
          detailSectionTitle
          detailSectionContent
          generalPurchaseTypeMinimumSpending
          generalPurchaseTypeMaximumSpending
          generalPurchaseTypeLimitedToStores {
            edges {
              node {
                id
              }
            }
          }
          generalPurchaseTypeLimitedToPaymentMethods {
            edges {
              node {
                id
                pk
                name
              }
            }
          }
          generalPurchaseTypeStampRewardTypeBonus
          generalPurchaseTypeStampRewardTypeBonusType
          generalPurchaseTypeStampRewardTypeBonusMultiplier
          generalPurchaseTypeStampRewardTypeBonusAdditional
          generalPurchaseTypeStampRewardTypeBonusCriteriaRelationship
          generalPurchaseTypeStampRewardTypeBonusPaymentMethods {
            edges {
              node {
                id
                pk
                name
              }
            }
          }
          generalPurchaseTypeStampRewardTypeBonusStores {
            edges {
              node {
                id
              }
            }
          }
          generalPurchaseTypeStampRewardTypeBonusTransactionStartDatetime
          generalPurchaseTypeStampRewardTypeBonusTransactionEndDatetime
          generalPurchaseTypeStampRewardTypeBonusTargetCustomerGroups {
            edges {
              node {
                id
                pk
                name
              }
            }
          }
          generalPurchaseTypeStampRewardTypeBonusTargetCustomerSegments {
            edges {
              node {
                id
                pk
                name
              }
            }
          }
          generalPurchaseTypeEligibleNumberOfDaysSincePurchase
          generalPurchaseTypeExcludedProducts
          generalPurchaseTypeRewardType
          generalPurchaseTypePointsRewardTypePointsPerXDollarsSpent
          generalPurchaseTypePointsRewardTypeX
          generalPurchaseTypeCouponRewardTypeCouponTemplate {
            id
          }
          generalPurchaseTypeCouponRewardTypeQuantity
          generalPurchaseTypeOverallLimit
          generalPurchaseTypePerHeadLimit
          generalPurchaseTypePeriodicLimit
          generalPurchaseTypePeriodicLimitEffectiveNumberOfDays
          generalPurchaseTypePerHeadPeriodicLimit
          generalPurchaseTypePerHeadPeriodicLimitEffectiveNumberOfDays
          memberReferralTypeBeneficiary
          memberReferralTypeLimit
          memberReferralTypeRewardType
          memberReferralTypePointsRewardTypePoints
          memberReferralTypeCouponRewardTypeCouponTemplate {
            id
          }
          memberReferralTypeCouponRewardTypeQuantity
          newMemberTypeRewardType
          newMemberTypePointsRewardTypePoints
          newMemberTypePromotionCodes
          newMemberTypeCouponRewardTypeCouponTemplate {
            id
          }
          newMemberTypeCouponRewardTypeQuantity
          birthdayTypePeriodStartDate
          birthdayTypePeriodEndDate
          birthdayTypeRewardType
          birthdayTypePointsRewardTypePoints
          birthdayTypeCouponRewardTypeCouponTemplate {
            id
          }
          birthdayTypeCouponRewardTypeQuantity
          qrCodeScanningTypeQrCodes
          qrCodeScanningTypeOverallLimit
          qrCodeScanningTypePerHeadLimit
          qrCodeScanningTypePeriodicLimit
          qrCodeScanningTypePeriodicLimitEffectiveNumberOfDays
          qrCodeScanningTypePerHeadPeriodicLimit
          qrCodeScanningTypePerHeadPeriodicLimitEffectiveNumberOfDays
          qrCodeScanningTypeRewardType
          qrCodeScanningTypePointsRewardTypePoints
          qrCodeScanningTypeCouponRewardTypeCouponTemplate {
            id
          }
          qrCodeScanningTypeCouponRewardTypeQuantity
          gpsCheckInTypeLatitude
          gpsCheckInTypeLongitude
          gpsCheckInTypeRadiusInMeter
          gpsCheckInTypeOverallLimit
          gpsCheckInTypePerHeadLimit
          gpsCheckInTypePeriodicLimit
          gpsCheckInTypePeriodicLimitEffectiveNumberOfDays
          gpsCheckInTypePerHeadPeriodicLimit
          gpsCheckInTypePerHeadPeriodicLimitEffectiveNumberOfDays
          gpsCheckInTypeRewardType
          gpsCheckInTypePointsRewardTypePoints
          gpsCheckInTypeCouponRewardTypeCouponTemplate {
            id
          }
          gpsCheckInTypeCouponRewardTypeQuantity
          fillingFormTypeForm {
            id
          }
          fillingFormTypeDefinitionType
          fillingFormTypeOverallLimit
          fillingFormTypePerHeadLimit
          fillingFormTypePeriodicLimit
          fillingFormTypePeriodicLimitEffectiveNumberOfDays
          fillingFormTypePerHeadPeriodicLimit
          fillingFormTypePerHeadPeriodicLimitEffectiveNumberOfDays
          fillingFormTypeRewardType
          fillingFormTypePointsRewardTypePoints
          fillingFormTypeCouponRewardTypeCouponTemplate {
            id
          }
          fillingFormTypeCouponRewardTypeQuantity
      }
      errors {
        field
        messages
      }
    }
  }
  `;

  const variables = { input: values };
  return BaseHelper.callGraphQLAPI({ query, variables });
};

