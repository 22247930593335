export const StoreCategoryErrorHandleField = {
  name: {
    name: 'name',
    message: 'Please provide a name.',
  },
  order: {
    name: 'order',
    message: 'Minimum value is 1.',
  },
  parentCategories: {
    name: 'parentCategories',
    message: 'Please provide a parent category.',
  },
};

export const CreateTransactionError = {
  formType: {
    name: 'formType',
    message: 'Please provide a form type.',
  },
  customerID: {
    name: 'customerID',
    message: '請輸入會員編號/手機號碼。',
  },
  customer: {
    name: 'customer',
    message: '會員不存在。',
  },
  transactionDate: {
    name: 'transactionDate',
    message: 'Please provide a transaction date.',
  },
  region: {
    name: 'region',
    message: '請選擇地區。',
  },
  district: {
    name: 'district',
    message: '請選擇分區。',
  },
  mall: {
    name: 'mall',
    message: '請選擇商場。',
  },
  store: {
    name: 'store',
    message: '請選擇店鋪。',
  },
  paymentMethod: {
    name: 'paymentMethod',
    message: '請選擇付款方式。',
  },
  posInvoiceId: {
    name: 'posInvoiceID',
    message: 'Please provide a pos invoice id.',
  },
  invoiceId: {
    name: 'invoiceID',
    message: '請輸入發票編號。',
  },
  offlineEventType: {
    name: 'offlineEventType',
    message: 'Please provide a offline event type.',
  },
  staffName: {
    name: 'staffName',
    message: '請輸入職員名字。',
  },
  totalValue: {
    name: 'totalValue',
    message: '請輸入金額或數量。',
  },
  totalValueFormat: {
    name: 'totalValueFormat',
    message: 'Please enter total value in digits.',
  },
  submitNumber: {
    name: 'submitNumber',
    message: '請輸入金額或數量。',
  },
  submitNumberFormat: {
    name: 'submitNumberFormat',
    message: 'Please enter submit number in digits.',
  },
  purchasedItemName: {
    name: 'purchasedItemName',
    message: 'Please provide a product name.',
  },
  purchasedItemQuantity: {
    name: 'purchasedItemQuanitity',
    message: 'Please provide a quantity.',
  },
  purchasedItemQuantityFormat: {
    name: 'purchasedItemQuantityFormat',
    message: 'Please enter quantity in digits.',
  },
  purchasedItemValue: {
    name: 'purchasedItemValue',
    message: 'Please provide a value.',
  },
  purchasedItemValueFormat: {
    name: 'purchasedItemValueFormat',
    message: 'Please enter value in digits.',
  },
  receiptImage: {
    name: 'receiptImage',
    message: 'Please provide a receipt image.',
  },
  creditCardSlipImage: {
    name: 'creditCardSlipImage',
    message: 'Please provide a credit card slip image.',
  },
  campaignType: {
    name: 'campaignType',
    message: 'Please provide a campaign type.',
  },
  stampCampaign: {
    name: 'stampCampaign',
    message: 'Please provide a stamp campaign.',
  },
  missionCampaign: {
    name: 'missionCampaign',
    message: 'Please provide a mission campaign.',
  },
  otherCampaign: {
    name: 'otherCampaign',
    message: 'Please provide a other campaign.',
  },
  carParkCampaign: {
    name: 'carParkCampaign',
    message: 'Please provide a carPark campaign.',
  },
};

export const AppVersionErrorHandleFields = {
  androidVersion: {
    name: 'androidAppVersionName',
    message: 'Please provide android app Vesion',
  },
  iOSVersion: {
    name: 'iosAppVersionName',
    message: 'Please provide iOS app Version',
  },
};

export const RejectErrorHandleFields = {
  rejectComment: {
    name: 'rejectComment',
    message: 'Please provide reject comment and ensure the length is not more than 200',
  }
};
