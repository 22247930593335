import React, { useState } from 'react';
import { Collapse, Image } from 'react-bootstrap';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import closeIcon from '../../assets/images/close.svg';
import arrowBottomIcon from '../../assets/images/arrowBottom.svg';
import './SelectMultipleStoreV2.scss';
import { ImageButton } from './CustomImageButton';
import { Tree } from 'antd';

export function CustomTitleWithCollapse({
  title,
  body,
  customClass,
  customButton = {},
  isToggle = true,
}) {
  const [open, setOpen] = useState(true);
  return (
    <div>
      <div className={`title-containerV2 ${customClass?.titleClass}`}>
        <CustomTitleLabel title={title} className={'title'} />
        {customButton ? (
          <button className="custom-button" onClick={customButton.action}>
            {customButton.title}
          </button>
        ) : null}
        {isToggle ? (
          <ImageButton
            action={() => {
              setOpen(!open);
            }}
            image={arrowBottomIcon}
            customClass={
              customButton ? `image-button-margin-left ${open ? 'rtoate180' : ''}` : `image-button ${open ? 'rtoate180' : ''}`
            }
          />
        ) : null}
      </div>
      {body ? (
        <Collapse in={open}>
          <div className={`list-container ${customClass?.contentClass}`}>
            {body}
          </div>
        </Collapse>
      ) : null}
    </div>
  );
}

function DisplaySelectedData({
  title,
  data = [],
  rightButton,
  customClass,
  customButton,
  disabled = false,
  isToggle,
  groupKey,
  onChange = () => {},
  showLabel = () => {},
  showPK = true,
  customSort = () => {},
}) {
  const RenderSingleChild = ({ child }) => {
    return (
      <div className="store-list-item-container">
        <label className="store-list-item">{showLabel(child)}</label>
        {disabled ? null : (
          <Image
            src={closeIcon}
            className="list-item-delete"
            onClick={(e) => {
              e.stopPropagation();
              const newValue = data?.filter(
                (filterItem) => filterItem.pk !== child.pk,
              );
              onChange(newValue);
            }}
          />
        )}
      </div>
    );
  };

  const groupTree = (data = [], key, showPK) => {
    const treeData = [];
    data.forEach((item) => {
      let parents = item[key];
      if (!parents && !parents?.length) {
        return;
      }
      if (!Array.isArray(parents)) {
        parents = [parents];
      }
      parents.forEach((parent) => {
        const parentPK = parent?.pk;
        const title = item[key].name
          ? `[ID:${item[key].pk}] ${item[key].name}`
          : `[ID:${parent.pk}] ${parent.name}`;
        const exsitGroup = treeData.find(
          (group) => group.key === `group-${parentPK}`,
        );
        if (exsitGroup) {
          exsitGroup.children.push({
            title: <RenderSingleChild child={item} />,
            ...item,
          });
          return;
        }
        treeData.push({
          ...item[key],
          key: `group-${parentPK}`,
          title: title,
          name: item[key].name ? item[key].name : parent.name,
          children: [{ title: <RenderSingleChild child={item} />, ...item }],
        });
      });
    });
    return treeData;
  };

  const contentBody = () => {
    const groupSourceData = groupKey ? groupTree(data, groupKey, showPK) : [];
    if (customSort) {
      groupSourceData.sort(customSort);
    }
    return (
      <Tree defaultExpandAll={true} height={400} treeData={groupSourceData} />
    );
  };
  return (
    <CustomTitleWithCollapse
      title={title}
      body={contentBody()}
      customClass={customClass}
      customButton={customButton}
      isToggle={isToggle}
    />
  );
}

export default DisplaySelectedData;
