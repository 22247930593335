import { APIStatus, LanguageConfig, SESSION_KEYS } from '../config/CustomEnums';
import {
  createStoreCategoryLv3,
  getStoreCategoryLv3,
  updateStoreCategoryLv3,
  deleteStoreCategoriesLv3,
  getStoreCategoryListLv3,
} from '../services/StoreCategoryLv3APIHelper';
import {
  convertPKToId,
  getObjectFromSessionStorage,
  removeFromSessionStorage,
} from '../utils';
import { createModel } from './BaseModel';
import { loading, apiWithResponseHandle } from './LoadingUtil';

const getInitialState = () => ({
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    {
      displayName: 'Store category Lv3',
      fieldName: 'name',
      orderField: 'name',
    },
    // {
    //   displayName: 'Display order',
    //   fieldName: 'order',
    //   orderField: 'displayPriority',
    // },
    {
      displayName: 'Store category Lv2',
      fieldName: 'formattedParentCategoryName',
    },
  ],
  detail: {
    name: null,
    order: null,
    parentCategories: [],
    translations: [],
    tagKey: {},
  },
  formChanged: false,
  saved: -1,
});

export const STORE_CATEGORY_LV3_ORDER_LAST = 'STORE_CATEGORY_LV3_ORDER_LAST';

export const getCreateStoreCategory = (originData) => {
  const { order, parentCategories, translations } = originData;
  const name = translations?.[LanguageConfig.english]?.name;

  let newTranslations = [];
  for (const [key, value] of Object.entries(translations)) {
    console.log(key, value);
    if (key === LanguageConfig.english) {
      continue;
    }
    if (value?.id == null && value?.name == null) {
      continue;
    }
    const translationData = {
      name: value?.name,
      language: key,
    };
    if (value?.id) {
      translationData.id = value?.id;
    }
    newTranslations.push(translationData);
  }

  const uploadParentCategories = parentCategories.map(
    (parentCategory) => parentCategory.pk,
  );

  const input = {
    name,
    displayPriority: parseInt(order),
    translations: newTranslations,
    parentCategories: uploadParentCategories,
  };
  return input;
};

const assembleParentCategories = (parentCategory) => {
  const translations = {};
  translations[LanguageConfig.english] = {
    name: parentCategory.name,
  };
  const apiTranslations = parentCategory.translations.edges || [];
  apiTranslations.forEach((item) => {
    let language = item.node.language;
    translations[language] = {
      name: item.node.name,
      id: item.node.pk,
    };
  });
  return {
    pk: parentCategory.pk,
    name: parentCategory.name,
    order: parentCategory.displayPriority,
    translations,
    tagKey: {
      ...parentCategory.parentCategory,
    },
  };
};

export const fixTranslations = (translations) => {
  const translationsToDelete = [];
  const fixedTranslations = translations.filter((translation) => {
    const { name, id } = translation;
    if (id && (name === '' || name === null || name === undefined)) {
      translationsToDelete.push(id);
      return false;
    }
    return true;
  });
  return [translationsToDelete, fixedTranslations];
};

export const assembleStoreCategory = (category) => {
  const translations = {};
  translations[LanguageConfig.english] = {
    name: category.name,
  };
  const apiTranslations = category.translations.edges || [];
  apiTranslations.forEach((item) => {
    let language = item.node.language;
    translations[language] = {
      name: item.node.name,
      id: item.node.pk,
    };
  });
  const parentCategories = category.parentCategories.edges.map(({ node }) =>
    assembleParentCategories(node),
  );
  return {
    pk: category.pk,
    name: category.name,
    order: category.displayPriority,
    parentCategories,
    translations,
  };
};

export const assembleStoreCategoryList = (storeCategories) => {
  const categoryList = storeCategories.map(
    ({ node }, index) => {
      const parentCategories = node?.parentCategories ? node?.parentCategories?.edges?.map(
        (parentCategoryEdge) => parentCategoryEdge.node,
      ) : null;
      return {
        id: node.id,
        pk: node.pk,
        name: node.name,
        order: node.displayPriority,
        parentCategories,
        formattedParentCategoryName: parentCategories ? parentCategories?.map((parentCategory) => parentCategory.name).join(', ') : null,
      };
    },
  );
  return categoryList;
};

export default createModel({
  namespace: 'createStoreCategoryLv3',
  states: getInitialState(),
  params: {
    listAPI: getStoreCategoryListLv3,
    parse: (data) => assembleStoreCategoryList(data.storeLevel3Categories.edges),
    pkNode: 'StoreLevel3CategoryNode',
    detailAPI: getStoreCategoryLv3,
    parseDetail: (data) => assembleStoreCategory(data.storeLevel3Category),
    deleteAPI: deleteStoreCategoriesLv3,
    sessionKey: SESSION_KEYS.STORE_CATEGORY_LV3_SESSION_KEY,
    objectKey: 'storeLevel3Categories',
    initState: getInitialState(),
  },
  reducers: {},
  effects: {
    createOrUpdate: [
      function* ({ payload }, { put }) {
        console.log('@202', payload);
        const { values, categoryID } = payload;
        yield put({
          type: 'updateState',
          payload: {
            createStatus: APIStatus.calling,
          },
        });
        const input = getCreateStoreCategory(values);
        let serviceArgs = [createStoreCategoryLv3, input];
        if (categoryID) {
          const id = parseInt(categoryID);
          if (isNaN(id)) {
            return;
          }
          const [translationsToDelete, fixedTranslations] = fixTranslations(
            input.translations,
          );

          const uploadData = {
            id,
            name: input.name,
            displayPriority: input.displayPriority,
            translations: fixedTranslations,
            translationsToDelete: translationsToDelete,
            parentCategories: input.parentCategories,
          };
          serviceArgs = [updateStoreCategoryLv3, uploadData];
        }
        yield put({
          type: 'responseCreateOrUpdate',
          payload: { serviceArgs, isCreate: true },
        });
      },
      { type: 'takeLatest' },
    ],
    updateCategoryOrder: [
      function* ({ payload }, { call, put, select }) {
        const { id, order, afterAction } = payload;
        const input = {
          id,
        };
        if (order === STORE_CATEGORY_LV3_ORDER_LAST) {
          const countResponse = yield call(getStoreCategoryListLv3, '', '');
          const countResponseData = countResponse.data;
          if (countResponseData?.data?.error) {
            return;
          }
          input.displayPriority =
            countResponseData.data.storeLevel3Categories.totalCount;
        } else {
          input.displayPriority = order;
        }
        const response = yield call(updateStoreCategoryLv3, input);
        const responseData = response.data;
        if (
          responseData.errors ||
          responseData.data.updateStoreLevel3Category.errors
        ) {
          return;
        }
        if (afterAction) {
          afterAction();
        }
      },
      {
        type: 'takeEvery',
      },
    ],
    duplicateCategory: [
      function* ({ payload }, { call, put, race, take }) {
        const { pk, afterAction } = payload;
        const id = convertPKToId('StoreLevel3CategoryNode', pk);
        const response = yield call(getStoreCategoryLv3, id);
        const responseData = response.data;
        if (
          responseData === undefined ||
          responseData.data?.storeLevel3Category.pk === null
        ) {
          return;
        }
        const category = assembleStoreCategory(responseData.data.storeLevel3Category);
        const input = getCreateStoreCategory(category);
        const uploadData = {
          name: `copy of ${input.name}`,
          displayPriority: input.displayPriority + 1,
          translations: input.translations.map((translation) => ({
            name: translation.name,
            language: translation.language,
          })),
          parentCategories: input.parentCategories,
        };
        function* onFailed(data) {
          console.log('success', data);
        }
        const serviceArgs = [createStoreCategoryLv3, uploadData];
        function* onSuccess(data) {
          if (afterAction) {
            yield afterAction();
          }
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      {
        type: 'takeEvery',
      },
    ],
  },
});
