import React, { useState, useEffect } from 'react';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { useHistory, useLocation } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import BaseTabListContainer from '../../base/BaseTabListContainer';
import BaseListContainer from '../../base/BaseListContainer';
import { useSelector, useDispatch } from 'react-redux';
import { ActionsDropdownForItem } from '../../../components/base/ActionsDropdown';
import { createAction } from '../../../utils';
import TransactionActionPrompt from '../../../components/transactionRecord/TransactionActionPrompt';
import { PermissionCodes } from '../../../config/PermissionCodes';
import AuthButton from '../../../components/base/AuthButton';
// import Filter from './Filter';
import {
  DELETE_RELATED_SECTIONS,
  LanguageConfig,
} from '../../../config/CustomEnums';
import ListButtonsGroup from '../../../components/base/ListButtonsGroup';
import BasePrompt from '../../../components/base/prompt/BasePrompt';
import { getCurrentRouteName } from '../../../components/bars/CollapseNavUtil';
import { NavBarNames } from '../../../config/NavBarNameList';
import { ImportResource } from '../../../models/DownloadImportModel';
import FormTypeFilter from './FormTypeFilter'
import i18n from '../../../I18n';

export const TRANSACTION_RECORD_TYPE = {
  TYPE_ONLINE: 'Online',
  TYPE_OFFLINE_POS: 'Offline (POS)',
  TYPE_OFFLINE_REWARD_CLAIM: 'Offline (Reward Claims)',
};

export const TRANSACTION_RECORD_FORM_TYPE = {
  TRADE_FORM: 'Transaction',
  NOT_TRADE_FORM: 'Non-transaction',
  TRADE_FORM_TC: '交易表格',
  NOT_TRADE_FORM_TC: '非交易表格',
};

export const TRANSACTION_EVENT_TYPE = {
  TYPE_APPROVED_TC: '已確認',
  TYPE_RECALLED_TC: '已撤销',
  TYPE_APPROVED_EN: 'Approved',
  TYPE_RECALLED_EN: 'Recalled',
};

export const getTransactionLanguageAndIsTCTransaction = (location) => {
  let isTCTransaction = false;
  let language = LanguageConfig.english;
  const currentRouteName = getCurrentRouteName(location.pathname);
  if (currentRouteName === NavBarNames.transactionRecord) {
    isTCTransaction = true;
    language = LanguageConfig.traditionalChinese;
  }
  return { language, isTCTransaction };
}

function TransactionList() {
  const queryString = require('query-string');
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { language, isTCTransaction } = getTransactionLanguageAndIsTCTransaction(location);

  const [showActionPrompt, setShowActionPrompt] = useState(false);
  const [promptActionTitle, setPromptActionTitle] = useState();
  const [selectedItem, setSelectedItem] = useState();

  const {
    tableFields,
    enTableFields,
    dataList,
    pageInfo,
    totalCount,
    totalPage,
  } = useSelector((state) => ({
    tableFields: state.transactionRecord.listDisplayFields,
    enTableFields: state.transactionRecord.enListDisplayFields,
    dataList: state.transactionRecord.currentPageTransactionList,
    pageInfo: state.transactionRecord.pageInfo,
    totalCount: state.transactionRecord.totalCount,
    totalPage: state.transactionRecord.totalPage,
  }));

  // useEffect(() => {
  //   dispatch({ type: 'mall/getList', payload: { isSelectorLoad: true } });
  //   dispatch({ type: 'storeModel/getPagedStoreList', payload: { isSelectorLoad: true, isLoading: false } });
  //   dispatch({ type: 'transactionRecord/getTransactionStaffNames', payload: { isSelectorLoad: true } });

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (showData()){
      const searchDict = queryString.parse(location.search);
      // const transactionType = searchDict['type'];
      const searchKey = searchDict['search'];
      const stringRank = searchDict['rank'] || 'true';
      const stringPage = searchDict['page'] || 1;
      const rank = stringRank === 'true';
      const page = parseInt(stringPage);
      if (
        searchDict?.mall ||
        searchDict?.staff_name ||
        searchDict?.store ||
        searchDict?.create_date ||
        searchDict?.transaction_date ||
        searchDict?.transaction_type ||
        searchKey
      ) {
        dispatch(
          createAction('transactionRecord/getCurrentPageTransactions')({
            ...searchDict,
            // transactionType,
            searchKey,
            rank,
            page,
          }),
        );
      }
    } else {
      dispatch(createAction('transactionRecord/clearData')());
    }
  }, [dispatch, location, queryString]);

  const showData = () => {
    const searchDict = queryString.parse(location.search);
    let result = false;
    if (
      searchDict?.mall ||
      searchDict?.staff_name ||
      searchDict?.store ||
      searchDict?.create_date ||
      searchDict?.transaction_date ||
      searchDict?.transaction_type ||
      searchDict?.search
    ) {
      result = true;
    }
    return result;
  };
  const exportCSVAction = () => {
    const searchDict = queryString.parse(location.search);
    dispatch(
      createAction('downloadAndImport/createDownloadTask')({
        from: ImportResource.transaction,
        related: {
          transactionFormTypeIn: searchDict?.transaction_type?.split(','),
          dateGte: searchDict?.transaction_date?.split(',')?.[0],
          dateLte: searchDict?.transaction_date?.split(',')?.[1],
        },
      }),
    );
  };
  const createTransactionAction = () => {
    dispatch(createAction('createTransactionRecord/clearData')());
    if (isTCTransaction) {
      history.push('/concierge_transaction_records/create');
    } else {
      history.push('/transaction_records/create');
    }
  };
  const buttons = isTCTransaction
    ? [
        <ListButtonsGroup
          primaryButton={
            <AuthButton
              title="創建交易"
              action={createTransactionAction}
              requires={PermissionCodes.addTransaction}
            />
          }
          primaryPopContent={{
            requires: PermissionCodes.addTransaction,
            action: createTransactionAction,
            content: 'Create Transactions',
          }}
        />,
      ]
    : [
        <ListButtonsGroup
          extraButtons={[
            <AuthButton
              customClass="btn-back-button-common btn-download"
              title="Export .csv"
              action={exportCSVAction}
              requires={PermissionCodes.addExportjob}
            />,
          ]}
          extraPopContent={[
            {
              requires: PermissionCodes.addExportjob,
              action: exportCSVAction,
              content: 'Export .csv',
            },
          ]}
          primaryButton={
            <AuthButton
              title="Create Transaction"
              action={createTransactionAction}
              requires={PermissionCodes.addTransaction}
            />
          }
          primaryPopContent={{
            requires: PermissionCodes.addTransaction,
            action: createTransactionAction,
            content: 'Create Transaction',
          }}
        />,
      ];

  const setActionPrompt = (event, item) => {
    setPromptActionTitle(event);
    setSelectedItem(item);
    setShowActionPrompt(true);
  };

  const tabs = [
    {
      name: '',
      content: (
        <BaseTabListContainer
          hideTab={true}
          tabs={[]}
          groupActions={[]}
          pageInfo={pageInfo}
          totalCount={totalCount}
          searchPlaceholder={i18n.t('transaction.search_membership_id/mobile_number', { locale: language })}
          searchMoreStyle={{ width: isTCTransaction ? '' : '350px' }}
          filter={{
            hasFilter: true,
            componment: FormTypeFilter,
            filterTitle: i18n.t('transaction.filter', { locale: language }),
            moreStyle: { right: isTCTransaction ? '' : '417px' },
          }}
          componentLanguage={language}
          listContent={
            <BaseListContainer
              actionFieldDisplayName={i18n.t('transaction.action', { locale: language })}
              fields={isTCTransaction ? tableFields : enTableFields}
              model={'transactionRecord'}
              permissionGroup={isTCTransaction ? PermissionCodes.conciergetransaction : PermissionCodes.transaction}
              dataList={dataList}
              totalPage={totalPage ? totalPage : 0}
              deleteInfo={{
                data: [],
                title: 'transaction',
                relatedName: '',
                onComfirm: {},
                relatedSections: DELETE_RELATED_SECTIONS.TRANSACTION,
              }}
              useCustomCommonActions={true}
              customCommonActions={(item) => {
                let actions = [
                  {
                    name: i18n.t('transaction.view_detail', { locale: language }),
                    action: () => {
                      return history.push(`${location.pathname}/${item.pk}/`);
                    },
                    requires: isTCTransaction ? PermissionCodes.viewConciergetransaction : PermissionCodes.viewTransaction,
                  },
                ];
                let otherAction = [];
                if (
                  item.displayOfflineEventType ===
                    TRANSACTION_EVENT_TYPE.TYPE_APPROVED_TC ||
                  item.displayEnOfflineEventType ===
                    TRANSACTION_EVENT_TYPE.TYPE_APPROVED_EN
                ) {
                  otherAction = [
                    {
                      name: i18n.t('transaction.recall', { locale: language }),
                      action: () => {
                        setActionPrompt('recall', item);
                      },
                      requires: isTCTransaction ? PermissionCodes.changeConciergetransaction : PermissionCodes.changeTransaction,
                    },
                  ];
                }
                return (
                  <ActionsDropdownForItem
                    object={item}
                    actions={actions}
                    otherActions={otherAction}
                    actionTitle={i18n.t('transaction.action', { locale: language })}
                    otherActionTitle={i18n.t('transaction.others', { locale: language })}
                  />
                );
              }}
              customClassName="coupon-record-table"
            />
          }
        />
      ),
    },
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          // detail:
          //   'All transactions are listed here, you can view both  online and offline transactions of each customer.',
          title: i18n.t('transaction.transactionRecord', { locale: language }),
        }}
        buttons={buttons}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
      />
      <TransactionActionPrompt
        language={language}
        event={promptActionTitle}
        item={selectedItem}
        show={showActionPrompt}
        onHide={() => {
          setShowActionPrompt(false);
        }}
        action={(recallRemarks, setError, onHide) => {
          if (!recallRemarks) {
            setError(true);
          } else {
            onHide();
            dispatch({
              type: `transactionRecord/${promptActionTitle}Transaction`,
              payload: {
                transactionPK: selectedItem.pk,
                recallRemarks,
                language,
                afterActions: () => {
                  history.push(location);
                  dispatch({
                    type: 'navBars/updateState',
                    payload: {
                      saveDiscardToastShowing: {
                        value: true,
                        type: i18n.t('transaction.recall_success', { locale: language }),
                      },
                    },
                  });
                },
              },
            });
          }
        }}
      />
    </>
  );
}

export default TransactionList;
