import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Controller, useFormContext } from 'react-hook-form';
import CustomRadios from '../../base/CustomRadios';
import { Row } from 'react-bootstrap';
import './CouponDateTimeSelector.scss';
import CustomDateTimeSelect from '../../base/CustomDateTimeSelect';
import { connect } from 'react-redux';
import { createAction } from '../../../utils';
import { isShowError } from '../../campaign/campaignCreation/CreateCampaignHandleError';
import { CouponErrorFields } from './CouponHandleError';
import { LanguageConfig } from '../../../config/CustomEnums';
import ErrorFieldMessage from '../../base/ErrorFieldMessage';
import {
  DISCOUNT_CODE_FORMAT,
  VALID_PERIOD_TYPE,
} from '../../../models/CreateCouponModel';
import {
  COUPON_SET_TYPES,
  COUPON_FORMATS,
  COUPON_CODE_TYPE,
  COUPON_REDEMPTION_METHOD,
} from '../../../config/CustomEnums';
import {
  hasError,
  ReactHookFormErrorMessage,
} from '../../base/ErrorFieldMessage';
import InputFieldControl from '../../base/InputFieldControl';
import { CustomTitleLabel } from '../../earning/CustomBaseComponments';
import CustomTitleWithInput from '../../base/CustomTitleWithInput';
import CouponBarcodeSelectDropdown from './CouponBarcodeSelectDropdown';
import PeriodAndWeekdaySection from '../../campaign/campaignCreation/PeriodAndWeekdaySection';

const couponDatetimeOptions = [
  { label: 'All the time', value: VALID_PERIOD_TYPE.ALL_TIME },
  { label: 'In a period', value: VALID_PERIOD_TYPE.ABSOLUTE },
  {
    label: 'Numbers of day since acquiring',
    value: VALID_PERIOD_TYPE.RELATIVE,
  },
];
const CouponDateTimeSelector = ({}) => {
  const {
    watch,
    register,
    formState: { errors },
    setValue,
    control,
  } = useFormContext();
  const history = useHistory();
  const watchValidPeriodType = watch('validPeriodType');
  const watchCouponSetType = watch('couponSetType');
  const watchFormats = watch('formats');
  const watchCouponBarcode = watch('barcodeFormat');
  const watchCouponCodeType = watch('couponCodeType');
  const watchRedemptionMethod = watch('redemptionMethod');

  const watchDiscountCodeFormat =
    watch(`discountCodeFormat`) || DISCOUNT_CODE_FORMAT.CODE;
  const watchShowLandingPage = watch(`showLandingPage`);
  const watchLandingPageButtonName = watch(
    `translations.en.landingPageButtonName`,
  );
  const watchLandingPageUrl = watch(`translations.en.landingPageUrl`);
  const watchNumberOfDays = watch('numberOfDaysToExpireAfterAcquisition');
  const watchAbsoluteEffectiveDate = watch('absoluteEffectiveDate');
  const watchAbsoluteExpiryDate = watch('absoluteExpiryDate');
  const periodValues = watch('validPeriodValues');
  const weekday = watch('validWeekday');


  const daySinceNowView = (
    <div>
      <InputFieldControl
        name={'numberOfDaysToExpireAfterAcquisition'}
        title={'Required days'}
        value={watchNumberOfDays}
        setValue={(value) => {
          setValue('numberOfDaysToExpireAfterAcquisition', value, { shouldDirty: true });
        }}
        type='number'
        unit='days'
        className='coupon-campaign-section-input'
      />
    </div>
  );


  const periodView = (
    <div>
      <div className="second-section">
        <label className="create-section-label">Start date</label>
        <CustomDateTimeSelect
          defaultTime={watchAbsoluteEffectiveDate}
          onTimeChange={(datetime) => {
            setValue('absoluteEffectiveDate', datetime, {
              shouldDirty: true,
            });
          }}
        />
      </div>
      <div className="second-section">
        <label className="create-section-label">End date</label>
        <CustomDateTimeSelect
          defaultTime={watchAbsoluteExpiryDate}
          error={hasError(errors, 'absoluteExpiryDate')}
          onTimeChange={(datetime) => {
            setValue('absoluteExpiryDate', datetime, { shouldDirty: true });
          }}
          seconds={59}
          // hideTime={true}
        />
        <ReactHookFormErrorMessage errors={errors} id="absoluteExpiryDate" />
      </div>
    </div>
  );


    return (
      <div>
        <label className="create-section-label">Valid period</label>
        <Row className="featured-exclusive-row">
          <CustomRadios
            disable={false}
            options={couponDatetimeOptions}
            default={watchValidPeriodType}
            onChange={(value) => {
              setValue('validPeriodType', value, { shouldDirty: true });
            }}
          />
        </Row>
        <div
          className="second-section"
          hidden={
            watchValidPeriodType === VALID_PERIOD_TYPE.RELATIVE
              ? false
              : true
          }
        >
          {daySinceNowView}
        </div>
        <div
          hidden={
            watchValidPeriodType === VALID_PERIOD_TYPE.ABSOLUTE
              ? false
              : true
          }
        >
          {periodView}
        </div>
        <div className="error-field-message-style"></div>
        {watchRedemptionMethod ===
          COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE ? (
            <>
              <CustomTitleLabel title={'Discount code format'} />
              <div className="second-section-content" key={watchDiscountCodeFormat}>
                {watchDiscountCodeFormat === DISCOUNT_CODE_FORMAT.URL
                  ? 'Coupon URL'
                  : 'Coupon code'}
              </div>
            </>
          ) : null}

        {watchDiscountCodeFormat === DISCOUNT_CODE_FORMAT.URL ||
        (watchShowLandingPage &&
          watchDiscountCodeFormat != DISCOUNT_CODE_FORMAT.URL) ? (
          <>
            <CustomTitleLabel title={'Landing page button name'} />
            <div className="second-section-content">
              {watchLandingPageButtonName}
            </div>
          </>
        ) : null}

        {watchShowLandingPage &&
        watchDiscountCodeFormat != DISCOUNT_CODE_FORMAT.URL ? (
          <>
            <CustomTitleLabel title={'Landing page URL'} />
            <div className="second-section-content">{watchLandingPageUrl}</div>
          </>
        ) : null}

        {watchCouponSetType === COUPON_SET_TYPES.IMPORT_EXISTING_COUPON ? (
        <>
          {watchRedemptionMethod !==
          COUPON_REDEMPTION_METHOD.USERS_COPY_THE_DISCOUNT_CODE ? (
            <div className="second-section">
              <label className="create-section-label">Code format</label>
              <div style={{ display: 'flex' }}>
                <Controller
                  control={control}
                  name="formats"
                  render={() => (
                    <CustomRadios
                      onChange={(value) => {
                        setValue('formats', value, { shouldDirty: true });
                      }}
                      default={watchFormats}
                      options={[
                        {
                          label: 'Coupon QR code',
                          value: COUPON_FORMATS.COUPON_QR_CODE,
                        },
                        {
                          label: 'Coupon Bar code',
                          value: COUPON_FORMATS.COUPON_BAR_CODE,
                        },
                      ]}
                    />
                  )}
                />
              </div>
              <ReactHookFormErrorMessage errors={errors} id="formats" />
            </div>
          ) : null}

          {watchFormats === COUPON_FORMATS.COUPON_BAR_CODE ? (
            <CouponBarcodeSelectDropdown
              title={'Coupon Barcode'}
              defaultValue={{
                label: watchCouponBarcode?.name,
                value: watchCouponBarcode,
              }}
              onSelectChange={(value) => {
                setValue('barcodeFormat', value, { shouldDirty: true });
              }}
            />
          ) : null}

          <div className="second-section">
            <label className="create-section-label">
              {watchDiscountCodeFormat === DISCOUNT_CODE_FORMAT.URL
                ? 'Generic URL or Unique URL'
                : 'Generic code or Unique code'}
            </label>
            <div style={{ display: 'flex' }}>
              <Controller
                control={control}
                name="couponCodeType"
                render={() => (
                  <CustomRadios
                    onChange={(value) => {
                      setValue('couponCodeType', value, { shouldDirty: true });
                    }}
                    default={watchCouponCodeType}
                    options={[
                      {
                        label: 'Generic',
                        value: COUPON_CODE_TYPE.GENERIC,
                      },
                      {
                        label: 'Unique',
                        value: COUPON_CODE_TYPE.UNIQUE,
                      },
                    ]}
                  />
                )}
              />
            </div>
            <ReactHookFormErrorMessage errors={errors} id="couponCodeType" />
          </div>

          {watchCouponCodeType === COUPON_CODE_TYPE.GENERIC ? (
            <Controller
              control={control}
              name="genericCode"
              render={() => (
                <>
                  <CustomTitleWithInput
                    title={
                      watchDiscountCodeFormat === DISCOUNT_CODE_FORMAT.URL
                        ? 'Generic URL'
                        : 'Generic code'
                    }
                    defaultValue={watch('genericCode')}
                    error={{ error: hasError(errors, 'genericCode') }}
                    setValue={(value) => {
                      setValue('genericCode', value);
                    }}
                    multipleLines={
                      watchDiscountCodeFormat === DISCOUNT_CODE_FORMAT.URL
                    }
                    customClass={
                      watchDiscountCodeFormat === DISCOUNT_CODE_FORMAT.URL
                        ? 'muiltpleLinesInput'
                        : ''
                    }
                  />
                  <ReactHookFormErrorMessage errors={errors} id="genericCode" />
                </>
              )} 
            />
          ) : null}
        </>
        ) : null}

        <PeriodAndWeekdaySection
          switchButtonTitle='Blackout period'
          periodValuesField='validPeriodValues'
          periodValues={periodValues}
          periodValuesChange={(data) => {
            setValue('validPeriodValues', data, { shouldDirty: true })
          }}
          weekday={weekday}
          weekdayChange={(data) => {
            setValue('validWeekday', data, { shouldDirty: true })
          }}
          maxDate={watchAbsoluteExpiryDate}
          minDate={watchAbsoluteEffectiveDate}
          disabledDate={watchValidPeriodType===VALID_PERIOD_TYPE.ABSOLUTE}
        />
      </div>
    );
}

export default CouponDateTimeSelector;
