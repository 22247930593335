import { createModel } from './BaseModel';
import {
  createCampaignTag,
  deleteCampaignTags,
  getCampaignTag,
  getCampaignTagList,
  updateCampaignTag,
} from '../services/CampaignTagAPIHelper';
import { APIStatus, LanguageConfig, SESSION_KEYS, StatusTag } from '../config/CustomEnums';
import { fixTranslations } from './CreateStoreCategoryLv3Model';
import { convertPKToId } from '../utils';
import { apiWithResponseHandle } from './LoadingUtil';

const getInitialState = () => ({
  listDisplayFields: [
    { displayName: 'ID', fieldName: 'pk' },
    { displayName: 'Name', fieldName: 'name', orderField: 'name' },
    {
      displayName: 'Display order',
      fieldName: 'order',
      orderField: 'displayPriority',
    },
    { displayName: 'Status', fieldName: 'status' },
  ],
});

export const CAMPAIGN_TAG_ORDER_LAST = SESSION_KEYS.CAMPAIGN_TAG_ORDER_LAST;

const assembleCampaignTag = (tag) => {
  const translations = {};
  translations[LanguageConfig.english] = {
    name: tag.name,
  };
  const apiTranslations = tag.translations.edges || [];
  apiTranslations.forEach((item) => {
    let language = item.node.language;
    translations[language] = {
      name: item.node.name,
      id: item.node.pk,
    };
  });

  return {
    pk: tag.pk,
    name: tag.name,
    order: tag.displayPriority,
    isForcedInactive: tag.isForcedInactive,
    status: tag.isForcedInactive ? StatusTag.inactive : StatusTag.active,
    translations,
  };
};

const getCreateStoreTag = (originData) => {
  const { order, isForcedInactive, name, translations } = originData;

  let newTranslations = [];
  let enName = '';
  for (const [key, value] of Object.entries(translations)) {
    if (key === LanguageConfig.english) {
      enName = value?.name;
      continue;
    }
    if (value?.id == null && value?.name == null) {
      continue;
    }
    const translationData = {
      name: value?.name,
      language: key,
    };
    if (value?.id) {
      translationData.id = value?.id;
    }
    newTranslations.push(translationData);
  }
  return {
    name: name,
    displayPriority: parseInt(order),
    isForcedInactive: isForcedInactive || false,
    translations: newTranslations,
  };
};

export default createModel({
  namespace: 'campaignTag',
  states: getInitialState(),
  params: {
    listAPI: getCampaignTagList,
    parse: (data) =>
      data.campaignTags.edges.map(({ node }, index) => ({
        id: node.id,
        ...assembleCampaignTag(node),
      })),
    pkNode: 'CampaignTagNode',
    detailAPI: getCampaignTag,
    parseDetail: (data) => assembleCampaignTag(data.campaignTag),
    deleteAPI: deleteCampaignTags,
    objectKey: 'campaignTags',
  },
  reducers: {},
  effects: {
    createOrUpdate: [
      function* ({ payload }, { put }) {
        const { values, tagID } = payload;
        yield put({
          type: 'updateState',
          payload: {
            createStatus: APIStatus.calling,
          },
        });
        const input = getCreateStoreTag(values);
        let serviceArgs = [createCampaignTag, input];
        if (tagID) {
          const id = parseInt(tagID);
          if (isNaN(id)) {
            return;
          }
          const [translationsToDelete, fixedTranslations] = fixTranslations(
            input.translations,
          );

          const uploadData = {
            id,
            name: input.name,
            displayPriority: input.displayPriority,
            isForcedInactive: input.isForcedInactive,
            translations: fixedTranslations,
            translationsToDelete: translationsToDelete,
          };
          serviceArgs = [updateCampaignTag, uploadData];
        }
        yield put({
          type: 'responseCreateOrUpdate',
          payload: { serviceArgs, isCreate: !tagID },
        });
      },
      { type: 'takeLatest' },
    ],
    updateTagActiveStatus: [
      function* ({ payload }, { call, put, all }) {
        const { id, isForcedInactive, afterAction } = payload;
        const input = {
          id,
          isForcedInactive,
        };
        const response = yield call(updateCampaignTag, input);
        const responseData = response.data;
        if (
          responseData.errors ||
          responseData.data.updateCampaignTag.errors
        ) {
          return;
        }
        if (afterAction) {
          afterAction();
        }
      },
      {
        type: 'takeEvery',
      },
    ],
    updateTagOrder: [
      function* ({ payload }, { call, put, select }) {
        const { id, order, afterAction } = payload;
        const input = {
          id,
        };
        if (order === CAMPAIGN_TAG_ORDER_LAST) {
          const countResponse = yield call(getCampaignTagList, '', '');
          const countResponseData = countResponse.data;
          if (countResponseData?.data?.error) {
            return;
          }
          input.displayPriority =
            countResponseData.data.campaignTags.totalCount;
        } else {
          input.displayPriority = order;
        }
        const response = yield call(updateCampaignTag, input);
        const responseData = response.data;
        if (
          responseData.errors ||
          responseData.data.updateCampaignTag.errors
        ) {
          return;
        }
        if (afterAction) {
          afterAction();
        }
      },
      {
        type: 'takeEvery',
      },
    ],
    duplicateTag: [
      function* ({ payload }, { call, put, race, take }) {
        const { pk, afterAction } = payload;
        const id = convertPKToId('CampaignTagNode', pk);
        const response = yield call(getCampaignTag, id);
        const responseData = response.data;
        if (
          responseData === undefined ||
          responseData.data?.campaignTag.pk === null
        ) {
          return;
        }
        const tag = assembleCampaignTag(responseData.data.campaignTag);
        const input = getCreateStoreTag(tag);
        const uploadData = {
          name: `copy of ${input.name}`,
          displayPriority: input.displayPriority + 1,
          isForcedInactive: input.isForcedInactive,
          translations: input.translations.map((translation) => ({
            name: translation.name,
            language: translation.language,
          })),
        };
        function* onFailed(data) {
          console.log('success', data);
        }
        const serviceArgs = [createCampaignTag, uploadData];
        function* onSuccess(data) {
          if (afterAction) {
            yield afterAction();
          }
        }
        yield apiWithResponseHandle(serviceArgs, onSuccess, onFailed);
      },
      {
        type: 'takeEvery',
      },
    ],
  },
});
