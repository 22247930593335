import React, { useState, useEffect } from 'react';
import { Row, Collapse } from 'react-bootstrap';
import { useSelector, useDispatch, connect } from 'react-redux';
import ImageUploader, { ReactHookFormImageUploader } from '../../base/ImageUploader';
import './KeyVisualSection.scss';
import CustomRadios from '../../base/CustomRadios';
import { CampaignDisplayIn, CampaignType, LanguageConfig } from '../../../config/CustomEnums';
import i18n from '../../../I18n';
import { Controller, useFormContext } from 'react-hook-form';
import { ApplySwitch } from '../../base/LabelWithSwitch';
import {
  errorMessage,
} from '../../base/ErrorFieldMessage';

function StampIconSection({
  language,
  onFocus = (() => {}),
}) {
  const dispatch = useDispatch();
  const { watch, setValue, formState, control, getValues } = useFormContext();
  const errors = formState.errors;
  const emptyStampPhoto = watch('emptyStampPhoto') || ''
  const acquiredStampPhoto = watch('acquiredStampPhoto') || ''

  const campaignEmptyStampPhoto = [emptyStampPhoto]?.filter((item) => item);
  const campaignAcquiredStampPhoto = [acquiredStampPhoto]?.filter((item) => item);

  const watchUploadStampIcons = watch('uploadStampIcons')

  return (
    <>
    {language === LanguageConfig.english ? 
    <>
    <ApplySwitch
      checked={watchUploadStampIcons}
      title={'Upload stamp icons'}
      show={true}
      onChange={(value) => {
        setValue('uploadStampIcons', value, {shouldDirty: true});
      }}
    />
    <label className="create-campaign-cover-photo-reminder">
      If off, the default animation will be applied.  
    </label>
    </> : null }
    { watchUploadStampIcons ? <>
      <label className="create-section-label create-section-label-bottom-space">
        Empty stamp slot
      </label>
      <label className="create-campaign-cover-photo-reminder">
        Suggested Image Size: 168 px * 168 px  (Less than 2MB, support JPG, PNG and GIF only.)
      </label>
      <ImageUploader
        minWidth={168}
        minHeight={168}
        disabled={language !== LanguageConfig.english}
        images={campaignEmptyStampPhoto}
        onImageStateChange={(newState) => {
          setValue('emptyStampPhoto', newState[0], {
            shouldDirty: true,
          });
        }}
        aspect={1}
        maxImageNum={1}
        uploadImageClicked={() => onFocus('stampIcons')}
        language={language}
        errorMessage={errorMessage(
          errors,
          `emptyStampPhoto`,
        )}
      />
      <label className="create-section-label create-section-label-bottom-space">
        Collected stamp
      </label>
      <label className="create-campaign-cover-photo-reminder">
        {'Suggested Image Size: 168 px * 168 px  (Less than 2MB, support JPG, PNG and GIF only.)'}
      </label>
      <label className="create-campaign-cover-photo-reminder">
        {'Please fill in the JSON file code. Suggested aspect ratio of the animation is align with the image asset: 192px * 216px'}
      </label>
      <ImageUploader
        minWidth={168}
        minHeight={168}
        disabled={language !== LanguageConfig.english}
        images={campaignAcquiredStampPhoto}
        onImageStateChange={(newState) => {
          setValue('acquiredStampPhoto', newState[0], {
            shouldDirty: true,
          });
        }}
        aspect={1}
        maxImageNum={1}
        uploadImageClicked={() => onFocus('stampIcons')}
        language={language}
        allowJson={true}
        errorMessage={errorMessage(
          errors,
          `acquiredStampPhoto`,
        )}
      />
    </> : null}
    </>
  );
}

const mapPropsToState = (state) => ({
})

export default connect(mapPropsToState)(StampIconSection);
