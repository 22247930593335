import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import ScrollableComponent from '../../../components/base/ScrollableComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ContentSections from '../../../components/base/ContentSections';
import { OnlyContinueButton } from '../../../components/base/BottomStepComponent';
import { LanguageConfig, APIStatus } from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';
import { createAction, firstError } from '../../../utils';
import { getReadablePeriod } from '../../../utils/TimeFormatUtil';
import DisplayOrder from '../../../components/banners/DisplayOrder';
import { ErrorFields } from '../../../models/HomeFeaturedModel';
import { listImage, listItem, listMultiItem } from '../../../components/banners/DisplayItems';

const FeaturedCampaignDetail = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { errorFields, campaign, createStatus } = useSelector((state) => ({
    errorFields: state.featuredCampaignList.errorFields,
    campaign: state.createCampaign.campaign,
    createStatus: state.featuredCampaignList.createStatus,
  }));
  const [order, setOrder] = useState();
  const relatedBrands = campaign ? campaign.selectedBrand?.map((item) => item?.name) : [];
  useEffect(() => {
    const campaignId = params.id;
    dispatch(createAction('createCampaign/getOneCampaign')({ id: campaignId }));
  }, [dispatch, params.id]);

  useEffect(() => {
    return () => {
      dispatch({ type: 'createCampaign/clearData' });
      dispatch({ type: 'featuredCampaignList/clearData' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setOrder(campaign?.order);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign?.order]);

  useEffect(() => {
    if (createStatus === APIStatus.success) {
      history.push('/home_campaigns');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus]);

  useEffect(() => {
    firstError(`${errorFields[0]}-${LanguageConfig.english}`, errorFields);
  }, [errorFields]);

  const campaignSection = () => {
    return (
      <>
        {listItem('Campaign ID', campaign?.pk)}
        {listImage(
          'Cover photo',
          campaign?.translations?.[LanguageConfig.english]?.coverPhoto?.value,
        )}
        {listItem('Campaign type', campaign?.typeDisplay)}
        {listMultiItem('Related brand', relatedBrands)}
        {listItem(
          'Campaign’s active period',
          getReadablePeriod(
            campaign?.activeStartDate,
            campaign?.activeEndDate,
            campaign?.isAlwaysActivePeriod,
          ),
        )}
        {listItem(
          'Campaign’s visible period',
          getReadablePeriod(
            campaign?.visibleStartDate,
            campaign?.visibleEndDate,
            campaign?.isAlwaysVisiblePeriod,
          ),
        )}
      </>
    );
  };

  const getContent = () => {
    if (createStatus === APIStatus.calling) {
      return [<Loading />];
    }
    return [
      <>
        {campaignSection()}
        <DisplayOrder
          order={order}
          onChange={(item) => setOrder(item)}
          errors={errorFields}
          field={ErrorFields.displayPriority}
        />
      </>,
    ];
  };

  const save = () => {
    dispatch({
      type: 'featuredCampaignList/updateFeaturedCampaign',
      payload: {
        data: {
          id: params.id,
          displayPriority: order,
        },
      },
    });
  };

  const renderForm = () => (
    <>
      <ContentSections
        fieldsError={errorFields && errorFields.length > 0}
        sections={getContent()}
        hidePreview
      />
      <OnlyContinueButton text={'Save'} continueAction={() => save()} />
    </>
  );

  return (
    <ScrollableComponent
      content={[renderForm()]}
      currentStep={0}
      breadcrumb={
        <CustomBreadcrumb
          name={campaign?.translations?.[LanguageConfig.english]?.generalName}
        />
      }
      caution={{
        title: campaign?.translations?.[LanguageConfig.english]?.generalName,
      }}
    />
  );
};

export default FeaturedCampaignDetail;
