import React, { useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import CustomRadios from '../base/CustomRadios';
import { Row } from 'react-bootstrap';
import { GENERAL_TYPE } from '../../models/CreateCampaignModel';
import { PermissionCodes } from '../../config/PermissionCodes';
import { useFormContext } from 'react-hook-form';
import { hasError, errorMessage } from '../base/ErrorFieldMessage';
import BaseMultipleSelectorV2 from '../base/BaseMultipleSelectorV2';

const getRadioOptions = () => [
  {
    label: GENERAL_TYPE.customerGroup,
    value: GENERAL_TYPE.customerGroup,
  },
  {
    label: GENERAL_TYPE.segment,
    value: GENERAL_TYPE.segment,
  },
];

function TargetCustomerGroupSegmentSection({ groups, segments }) {
  const dispatch = useDispatch();
  const { watch, setValue, formState } = useFormContext();
  const errors = formState.errors;
  const campaignSegments = watch('segments') || [];
  const customerGroup = watch('customerGroup') || [];
  const generalType = watch('generalType') || setValue('generalType', GENERAL_TYPE.customerGroup, { shouldDirty: true });
  const watchEarningRules = watch('linkedEarningRules');
  const birthdayStartDate = watchEarningRules?.birthdayTypePeriodStartDate;
  const birthdayEndDate = watchEarningRules?.birthdayTypePeriodEndDate;

  useEffect(() => {
    dispatch({
      type: 'segments/getSegmentsList',
      payload: {
        status: true,
        ...segmentConfig.defaultFilter,
        isSelectorLoad: true,
        isAll: true,
      },
    });
    dispatch({
      type: 'customerGroup/getGroupList',
      payload: {
        isSimpleList: true,
      },
    });
  }, [dispatch]);

  const segmentConfig = {
    title: 'Target segments',
    data: segments,
    selectedList: campaignSegments,
    setValue: (option) => {
      setValue('segments', option, { shouldDirty: true });
    },
    requires: PermissionCodes.addSegment,
    loadMoreAction: 'segments/getSegmentsList',
    errorIdKey: 'segments',
    defaultFilter:
      birthdayStartDate && birthdayEndDate
        ? {
            dateOfBirthRange: [birthdayStartDate, birthdayEndDate],
          }
        : {},
  };

  const customerGroupConfig = {
    title: 'Target customer groups',
    namespace: 'customerGroupsList',
    data: groups,
    selectedList: customerGroup,
    setValue: (option) => {
      setValue('customerGroup', option, { shouldDirty: true });
    },
    requires: PermissionCodes.addCustomerGroup,
    loadMoreAction: 'customerGroup/getCurrentPageList',
    errorIdKey: 'customerGroup',
  };

  const getSelectorWithConfig = (config) => {
    return (
      <BaseMultipleSelectorV2
        title={config.title}
        namespace={config.namespace}
        data={{
          sourceData: config.data,
          targetData: config.selectedList,
          targetChange: (value) => {
            config.setValue(value);
          },
        }}
        addButton={config.addButton}
        error={{
          error: hasError(errors, config.errorIdKey),
          id: config.errorIdKey,
          message: errorMessage(errors, config.errorIdKey),
        }}
        requires={config.requires}
        groupKey={config.groupKey}
        groupKeyChoice={config.groupKeyChoice}
      />
    );
  };

  return (
    <>
      <>
        <div className={'category-select-title-top'}>
          <label className="create-section-label create-section-label-bottom-space">
            Target customer
          </label>
          <Row className="featured-exclusive-row">
            <CustomRadios
              onChange={(value) => {
                setValue('generalType', value, { shouldDirty: true });
              }}
              default={generalType}
              options={getRadioOptions()}
            />
          </Row>
        </div>
        {generalType === GENERAL_TYPE.customerGroup
          ? getSelectorWithConfig(customerGroupConfig)
          : null}
        {generalType === GENERAL_TYPE.segment
          ? getSelectorWithConfig(segmentConfig)
          : null}
      </>
    </>
  );
}

const mapPropsToState = (state) => ({
  groups: state.customerGroup.groupList,
  segments: state.segments.segmentList,
});

export default connect(mapPropsToState)(TargetCustomerGroupSegmentSection);
