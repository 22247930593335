import React, { useEffect } from 'react';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ContentSections from '../../../components/base/ContentSections';
import MembershipInfoCard from '../../../components/customer/MembershipInfoCard';
import '../../record/couponRecords/CouponTransactionOverview.scss';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '../../../utils';
import { OnlyBackButton } from '../../../components/base/BottomStepComponent';
import { couponRecordQueryKey } from '../../../components/pointRecord/RecordsUrlConfig';
import { getConciergeLanguage } from './CouponTransactionRecord';
import { LanguageConfig } from '../../../config/CustomEnums';
import i18n from '../../../I18n';

function CouponTransactionRecordOverview() {
  const queryString = require('query-string');
  const dispatch = useDispatch();
  const history = useHistory();
  const currentLocation = useLocation();

  const couponTransactionPK = useParams().id;
  const language = getConciergeLanguage(currentLocation);
  let isConcierge = false;
  if (language === LanguageConfig.traditionalChinese) {
    isConcierge = true;
  }

  useEffect(() => {
    dispatch(
      createAction('couponTransactionRecord/getSingleCouponTransactionRecord')({
        couponTransactionPK,
      }),
    );
  }, [couponTransactionPK, dispatch]);

  const { record } = useSelector((state) => ({
    record: state.couponTransactionRecord.selectedCouponTransaction,
  }));

  const dataWithType = (couponTransactionType) => {
    let differentItems = [];
    switch (couponTransactionType) {
      case 'Customer earned':
        differentItems = [
          [{ title: i18n.t('couponRecords.earning_rule_id_name', { locale: language }), field: isConcierge ? 'displayTCEarningRule' : 'displayEarningRule' }],
        ];
        break;
      case 'Customer acquired':
        differentItems = [
          // [{ title: 'Points spent', field: 'pointsSpent' }],
          [{ title: i18n.t('couponRecords.campaign_id_name', { locale: language }), field: isConcierge ? 'displayTCCampaign' : 'displayCampaign' }],
        ];
        break;
      case 'Admin added':
      case 'Admin removed':
        break;
      case 'Customer use':
        differentItems = [
          [{ title: i18n.t('couponRecords.used_date', { locale: language }), field: 'usedDate' }],
          [{ title: i18n.t('couponRecords.redeemed_at', { locale: language }), field: isConcierge ? 'usedStoreTC' : 'usedStore' }],
        ];
        break;
      case 'Coupon expired':
        if (record.earningRuleName) {
          differentItems = [
            [{ title: i18n.t('couponRecords.earning_rule_id_name', { locale: language }), field: isConcierge ? 'displayTCEarningRule' : 'displayEarningRule' }],
          ];
        } else if (record.pointsSpent) {
          differentItems = [
            // [{ title: 'Points spent', field: 'pointsSpent' }],
            [{ title: i18n.t('couponRecords.campaign_id_name', { locale: language }), field: isConcierge ? 'displayTCCampaign' : 'displayCampaign' }],
          ];
        } else if (record.usedDate) {
          differentItems = [[{ title: i18n.t('couponRecords.used_date', { locale: language }), field: 'usedDate' }]];
        }
        break;
      default:
        break;
    }
    let data = [
      // [{ title: 'Name (preferred name)', field: 'name' }],
      [{ title: i18n.t('couponRecords.membership_id/phone_number', { locale: language }), field: 'membershipIdWithPhoneNumber' }],
      [{ title: i18n.t('couponRecords.record_type', { locale: language }), field: isConcierge ? 'displayTCType' : 'displayType' }],
      ...differentItems,
      [{ title: i18n.t('couponRecords.coupon_id_name', { locale: language }), field: isConcierge ? 'displayTCCoupon' : 'displayCoupon'}],
      [{ title: i18n.t('couponRecords.expiry_date', { locale: language }), field: 'couponExpiryDate' }],
      [{ title: i18n.t('couponRecords.creation_date', { locale: language }), field: 'creationDate' }],
    ];
    if (record.administratorName) {
      data = [
        ...data,
        [({ title: i18n.t('couponRecords.administrator', { locale: language }), field: 'administratorName'})]
      ]
    }
    return data;
  };

  const tabs = [
    {
      name: '',
      content: (
        <div className="scroll-container-common">
          <ContentSections
            sections={[
              <MembershipInfoCard
                title={''}
                data={record}
                fields={dataWithType(record.displayType)}
              />,
            ]}
            hidePreview={true}
            sectionStyle="coupon-transaction-record-detail-section"
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '', //view coupon records
          title: i18n.t('couponRecords.view_coupon_records', { locale: language }),
        }}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
      />
      <OnlyBackButton
        text={i18n.t('transaction.back', { locale: language })}
        backAction={() => {
          const pathname = isConcierge ? '/concierge_coupon_records' :'/coupon_records';
          history.push({
            pathname: pathname,
            search: queryString.stringify({
              [couponRecordQueryKey.searchKey]: '',
              [couponRecordQueryKey.page]: 1,
              [couponRecordQueryKey.rank]: true,
              [couponRecordQueryKey.tab]: 'all',
            }),
          });
        }}
      />
    </>
  );
}

export default CouponTransactionRecordOverview;
