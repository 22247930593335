import { getInFilterValue, getSearchKey } from '../utils';
import { BaseHelper } from './BaseGraphQLAPIHelper';

const CUSTOMER_CONTENT = `
id
pk
ssoUid
firstName
lastName
nickname
membershipId
mobilePhoneNumberCountryCode
mobilePhoneNumberSubscriberNumber
emailAddress
isAssignedAsTestingCustomer
gender {
  label
  value
}
dateOfBirth
address
company
signUpChannel
referrer {
  id
}
referredByCampaign {
  id
}
promotionCode
isDeleted
isForcedInactive
preferredMessageLanguage
creationDate
lastModifiedDate
hasAgreedDirectMarketing
groups {
  edges {
    node {
      id
      name
    }
  }
}
customerOctopusCards {
  edges {
      node {
          octopusCardNumber
          octopusCardCheckDigit
      }
  }
}
segments {
  edges {
    node {
      id
      name
    }
  }
}
`;

const LIST_CONTENT = `
id
pk
firstName
lastName
membershipId
mobilePhoneNumberCountryCode
mobilePhoneNumberSubscriberNumber
isAssignedAsTestingCustomer
dateOfBirth
isDeleted
isForcedInactive
`;

const SimpleListFieldNode = `
  pk
  id
  firstName
  lastName
  nickname
  membershipId
`;

const CUSTOMER_SIMPLE_CONTENT = `
pk
membershipId
customerOctopusCards {
  edges {
      node {
          octopusCardNumber
          octopusCardCheckDigit
      }
  }
}
`;

export const getCustomerGroups = () => {
  const query = `{
    customerGroups(first: 100) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          name
        }
      }
    }
  }
    `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({
    query,
    variables,
  });
};

export const getAllCustomers = (afterCursor, search = {}, moreSearch) => {
  let searchValue = '';
  if (search && Object.keys(search).length > 0) {
    searchValue =
      ', ' +
      Object.keys(search)
        .map((key) => `${key}: ${search[key]}`)
        .join(', ');
  }
  let orderBy = 'pk';
  if (moreSearch?.sort) {
    orderBy = moreSearch.sort;
  }
  let pageItemCount = 100;
  if (moreSearch?.pageItemCount) {
    pageItemCount = moreSearch.pageItemCount;
  }
  const query = `{
    customers(first: ${pageItemCount}, after:"${afterCursor}", orderBy:"${orderBy}", ${searchValue}) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          ssoUid
          firstName
          lastName
          nickname
          membershipId
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCustomersByPage = (
  afterCursor,
  rank = 'true',
  search,
  groups,
  segments,
  levels,
  age,
  genders,
  startDate,
  endDate,
  moreSearch,
  sort,
  keyword,
  isFilterNameOrId,
  isFilterMembershipIDOrPhoneNumber,
  isSimpleNode,
  payload,
) => {
  // firstName_Icontains: "", groups_Name: "", gender_In: "", dateOfBirth_Month: "", mobilePhoneNumberCountryCode_In: "852,853,86", ageBetween:"0,2",signUpAnniversaryBetween: "0,2", level: "Level 2"

  let order = rank ? '-pk' : 'pk';

  if (sort) {
    order = sort;
  }

  let searches = [];
  if (isFilterNameOrId && search) {
    searches.push(`nameOrId: "${getSearchKey(search)}"`);
  } else if (isFilterMembershipIDOrPhoneNumber && search) {
    searches.push(`keyword: "${getSearchKey(search)}"`);
  } else if (payload.isFilterMembershipId && search) {
    searches.push(`membershipId: "${getSearchKey(search)}"`);
  } else if (search) {
    searches.push(`customerName: "${getSearchKey(search)}"`);
  }
  if (groups) {
    searches.push(`groupsIn: ${getInFilterValue(groups)}`);
  }
  if (segments) {
    searches.push(`segmentsIn: ${getInFilterValue(segments)}`);
  }
  if (levels) {
    searches.push(`levelIn: ${getInFilterValue(levels)}`);
  }
  if (age) {
    searches.push(`ageRange: ${getInFilterValue(age)}`);
  }
  if (genders) {
    searches.push(`genderIn: ${getInFilterValue(genders)}`);
  }
  // if (startDate && endDate) {
  //   searches.push(`dateOfBirthRange: "${startDate},${endDate}"`);
  // } else if (startDate) {
  //   searches.push(`dateOfBirth: "${startDate}"`);
  // } else if (endDate) {
  //   searches.push(`dateOfBirth: "${endDate}"`);
  // }
  if (startDate) {
    searches.push(`dateOfBirthGte: "${startDate}"`);
  }
  if (endDate) {
    searches.push(`dateOfBirthLte: "${endDate}"`);
  }
  if (payload?.create_date) {
    const createDatePeriod = payload.create_date.split(',');
    searches.push(
      `creationDateGte: "${createDatePeriod[0]}", creationDateLte: "${createDatePeriod[1]}"`,
    );
  }
  if (keyword) {
    searches.push(`keyword: "${keyword}"`);
  }

  // console.log('@@127: ', searches);
  if (moreSearch) {
    searches = [...searches, ...moreSearch];
  }

  const customerNode = payload?.isDropdownNode
    ? SimpleListFieldNode
    : isSimpleNode
    ? CUSTOMER_SIMPLE_CONTENT
    : payload?.isListNode
    ? LIST_CONTENT
    : CUSTOMER_CONTENT;

  // console.log('@@104: ', searches.join(', '));
  const query = `{
    customers(first: 20, after:"${afterCursor}", orderBy:"${order}"${
    searches.length > 0 ? ',' + searches.join(', ') : ''
  }) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ${customerNode}
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deleteCustomers = (ids) => {
  const query = `
    mutation DeleteCustomers($input: DeleteCustomersInput!) {
      deleteCustomers(input: $input) {
        success
        deleteFail
        errors {
          messages
        }
      }
    }
  `;
  const variables = {
    input: {
      ids: ids,
    },
  };

  // console.log('@@338: ', query);

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const updateCustomer = (values) => {
  const query = `
  mutation UpdateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      node {
        id
        pk
        firstName
        lastName
        nickname
        membershipId
        ssoUid
        mobilePhoneNumberCountryCode
        mobilePhoneNumberSubscriberNumber
        emailAddress
        gender {
          label
          value
        }
        dateOfBirth
        address
        company
        signUpChannel
        referrer {
          id
        }
        referredByCampaign {
          id
        }
        promotionCode
        isForcedInactive
        preferredMessageLanguage
        creationDate
        lastModifiedDate
      }
      errors {
        field
        messages
      }
    }
  }
  `;
  const variables = {
    input: values,
  };

  // console.log('@@338: ', query);

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getOneCustomer = (id) => {
  const query = `{
    customer(id: "${id}") {
      id
      pk
      firstName
      lastName
      nickname
      ssoUid
      membershipId
      mobilePhoneNumberCountryCode
      mobilePhoneNumberSubscriberNumber
      emailAddress
      isAssignedAsTestingCustomer
      gender {
        label
        value
      }
      dateOfBirth
      address
      company
      signUpChannel
      referrer {
        id
        pk
        firstName
        lastName
        membershipId
      }
      referredByCampaign {
        id
        pk
        name
      }
      promotionCode
      isForcedInactive
      preferredMessageLanguage
      creationDate
      lastModifiedDate
      totalSpending
      averageOrderValue
      totalNumberOfOrders
      daysFromLastOrder
      hasAgreedDirectMarketing
      residentialDistrict {
        name
        pk
        translations {
          edges {
            node {
              language
              name
              pk
            }
          }
        }
      }
      workingDistrict {
        name
        pk
        translations {
          edges {
            node {
              language
              name
              pk
            }
          }
        }
      }
      maritalStatus
      householdIncome
      haveChildren
      ageOfChildren
      carInfo {
        isMonthlyParkingUser
        carType
        licenseNumber
      }
      customerOctopusCards {
        edges {
          node {
            octopusCardNumber
            octopusCardCheckDigit
          }
        }
      }
      groups {
        edges {
          node {
            id
            pk
            name
          }
        }
      }
      favouriteContentTypes {
        edges{
          node{
            name
            pk
          }
        }
      }
      joinedInterestClubs {
        edges {
          node {
            club {
              name
              pk
            }
          }
        }
      }
      favouritedMalls{
        edges{
          node{
            name
            pk
          }
        }
      }
      segments {
        edges {
          node {
            id
            pk
            name
          }
        }
      }
      coupons(isExpired: false, isUsed: false orderBy: "-pk") {
        edges {
          node {
            id
            pk
            template {
              id
              pk
              name
            }
          }
        }
      }
    }
  }
  `;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCustomerActivityLog = (ssoUid) => {
  // activityTypeIn: "CUSTOMER_POINTS_EARNED,CUSTOMER_POINTS_USED,CUSTOMER_POINTS_EXPIRED,CUSTOMER_LEVEL_CHANGED,CUSTOMER_LEVEL_RETAINED"
  const query = `{
    customerActivityLogs(first: 50, customerSsoUid: "${ssoUid}", orderBy: "-pk") {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          activityType
          customerSsoUid
          customerPointAndLevelLogTitle
          logContentId
          logContentType {
            appLabel
            model
          }
          creationDate
        }
      }
    }
  }
  `;

  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const activeCustomer = (values) => {
  const query = `
  mutation ActivateCustomer($input: ActivateCustomerInput!) {
    activateCustomer(input: $input) {
      node {
        id
        pk
        isForcedInactive
      }
    }
  }
  `;
  const variables = {
    input: values,
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const deactiveCustomer = (values) => {
  console.log(values);
  const query = `
  mutation DeactivateCustomer($input: DeactivateCustomerInput!) {
    deactivateCustomer(input: $input) {
      node {
        id
        pk
        isForcedInactive
      }
    }
  }
  `;
  const variables = {
    input: values,
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getResidentialDistricts = (afterCursor, payload = {}) => {
  let searchString = payload?.isAll ? '' : `first: ${payload?.pageSize || 20}`;
  if (afterCursor) {
    searchString += `, after: "${afterCursor}"`;
  }
  if (payload?.searchKey) {
    searchString += `, name: "${payload.searchKey}"`;
  }
  const query = `{
    residentialDistricts${searchString ? `(${searchString})` : ''} {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          name
          translations {
            edges {
              node {
                language
                name
                pk
              }
            }
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getWorkingDistricts = (afterCursor, payload = {}) => {
  let searchString = payload?.isAll ? '' : `first: ${payload?.pageSize || 20}`;
  if (afterCursor) {
    searchString += `, after: "${afterCursor}"`;
  }
  if (payload?.searchKey) {
    searchString += `, name: "${payload.searchKey}"`;
  }
  const query = `{
    workingDistricts${searchString ? `(${searchString})` : ''} {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          name
          translations {
            edges {
              node {
                language
                name
                pk
              }
            }
          }
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const generatePLL = () => {
  const query = `
  mutation GeneratePll($input: GeneratePllInput!) {
    generatePll(input: $input) {
      node {
        file
      }
    }
  }
  `;
  const variables = {
    input: {},
  };

  return BaseHelper.callGraphQLAPI({ query, variables });
};

export const getCustomerPLLFile = () => {
  const query = `{
    customerPllFiles{
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          pk
          file
          fileName
          status
        }
      }
    }
  }`;
  const variables = {};
  return BaseHelper.callGraphQLAPI({ query, variables });
};
