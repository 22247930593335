import React, { useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import { APIStatus, SESSION_KEYS } from '../../../config/CustomEnums';
import { removeFromSessionStorage, saveToSessionStorage } from '../../../utils';
import BaseForm from '../../../components/base/v2/BaseForm';
import CreateWebviewContentStepOne from './CreateWebviewContentStepOne';

const CreateWebviewContent = ({
  webContent,
  createStatus,
  apiStatus,
  hasUpdatedDefaultValues,
  formHasSubmitted,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const caution = {
    detail: '',
    title: params.id ? webContent.name : 'Create Webview Content',
    model: 'webContent',
  };

  useEffect(() => {
    const id = params.id;
    if (id) {
      dispatch({ type: 'webContent/getOneDetail', payload: { id: id } });
    } else {
      dispatch({ type: 'webContent/loadFromCookie' });
    }
    return () => {
      dispatch({ type: 'webContent/clearData' });
    };
  }, [dispatch, params.id]);

  useEffect(() => {
    if (
      createStatus === APIStatus.success &&
      formHasSubmitted &&
      history.location.pathname.includes('edit')
    ) {
      history.push('/webview');
      return;
    }
  }, [formHasSubmitted, createStatus, webContent, history]);

  const stepSet = [<CreateWebviewContentStepOne />];
  return (
    <div>
      <BaseForm
        defaultValues={webContent}
        hasUpdatedDefaultValues={hasUpdatedDefaultValues}
        formHasSubmitted={formHasSubmitted}
        tempSave={(save, getValues) => {
          if (save) {
            saveToSessionStorage(
              SESSION_KEYS.WEBVIEW_CONTENT_SESSION_KEY,
              getValues(),
            );
          } else {
            removeFromSessionStorage(SESSION_KEYS.WEBVIEW_CONTENT_SESSION_KEY);
          }
        }}
        showFinishPop={createStatus === APIStatus.success}
        nextStepConfig={{
          title: 'Successfully Created!',
          description: 'Webview Content is successfully created.',
          steps: null,
          buttons: [
            {
              text: 'Back to Webview Content list',
              action: () => {
                history.push({
                  pathname: '/webview',
                });
              },
            },
          ],
        }}
        continueCreateConfig={{
          onConfirm: () => {
            history.push(history.location.state?.from);
          },
          onCancel: () => history.push('/webview'),
          onClose: () => history.push('/webview'),
        }}
        content={stepSet}
        currentStep={0}
        breadcrumb={<CustomBreadcrumb name={webContent.name} />}
        caution={caution}
      />
    </div>
  );
};

const mapPropsToState = (state) => ({
  webContent: state.webContent.detail,
  createStatus: state.webContent.createStatus,
  apiStatus: state.webContent.apiStatus,
  hasUpdatedDefaultValues: state.webContent.hasUpdatedDefaultValues,
  formHasSubmitted: state.webContent.formHasSubmitted,
});

export default connect(mapPropsToState)(CreateWebviewContent);
