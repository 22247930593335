import { LanguageConfig } from '../../../config/CustomEnums';
import { StoreCategoryErrorHandleField } from '../../../config/ErrorHandle';

function firstStepValidate({ getValues, setError }) {
  const nameField = `translations.${LanguageConfig.english}.name`;
  const orderField = 'order';
  const parentCategoriesField = 'parentCategories';
  const [
    name,
    order,
    parentCategories,
  ] = getValues([
    nameField,
    orderField,
    parentCategoriesField,
  ]);
  const errorList = [];
  if (!name || name === '') {
    errorList.push({
      name: nameField,
      message: StoreCategoryErrorHandleField.name.message,
    });
  }
  const displayPriority = parseInt(order);
  if (isNaN(displayPriority) || displayPriority < 1) {
    errorList.push({
      name: orderField,
      message: StoreCategoryErrorHandleField.order.message,
    });
  }

  if (parentCategories.length < 1) {
    errorList.push({
      name: `parentCategories`,
      message: StoreCategoryErrorHandleField.parentCategories.message,
    });
  }
  errorList.forEach((error) => {
    setError(
      error.name,
      {
        type: 'require',
        message: error.message,
      }
    );
  });
  return !errorList.length;
}

export function validate({ getValues, setError, clearErrors, step }) {
  console.log('@71 CreateStoreCategoryValidate:', getValues());
  clearErrors();
  switch (step) {
    default:
    case 0:
      const validStepOne = firstStepValidate({ getValues, setError });
      return validStepOne;
  }
}
