import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createAction } from '../../../utils';
import { CustomTitleWithDropDown } from '../../customer/CustomTitleWithDropDown';

function CouponSelectDropdown(props) {
  const dispatch = useDispatch();
  const title = props.title;
  const defaultValue = props.defaultValue || null;
  const setValue = props.setValue || (() => {});
  const addButtonInfo = props.addButtonInfo || {};
  const defaultFilter = props.defaultFilter || {}

  const { couponTemplateList } = useSelector((state) => ({
    couponTemplateList: state.couponList.couponTemplateList,
  }));

  useEffect(() => {
    dispatch(
      createAction('couponList/getCurrentPageTemplateList')({
        isSelectorLoad: true,
        isSimpleList: true,
        ...defaultFilter
      }),
    );
  }, [dispatch]);

  const onSelectChange = (value) => {
    setValue(value?.value);
  };
  return (
    <CustomTitleWithDropDown
      title={title}
      titleStyle={props.isPrompt ? '' : 'create-section-label-no-top-space'}
      defaultValue={defaultValue}
      setValue={onSelectChange}
      source={couponTemplateList}
      loadMoreAction={'couponList/getCurrentPageTemplateList'}
      filterAction={'couponList/getCurrentPageTemplateList'}
      errors={props.errors}
      errorName={props.errorName}
      addButtonInfo={addButtonInfo}
      defaultFilter={{
        isSimpleList: true,
        ...defaultFilter
      }}
    />
  );
}

export default CouponSelectDropdown;
