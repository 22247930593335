import React, { useState, useEffect } from 'react';
import './CreateCampaignStepFour.scss';
import { useDispatch, useSelector, connect } from 'react-redux';
import ContentSections from '../../base/ContentSections';
import ProfileSection from './ProfileSection';
import ContentSection from './ContentSection';
import PublishCampaignPrompt from './PublishCampaignPrompt';
import { BackAndMultipleButtons, SaveAndBackButtons, SaveButton, SaveTempButton } from '../../base/BottomStepComponent';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { checkHasPermission, createAction } from '../../../utils';
import { ActionType, APIStatus, ApprovalStatus, CampaignDisplayIn, CampaignType } from '../../../config/CustomEnums';
import Loading from '../../base/Loading';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { validate } from '../../../containers/engagement/campaign/CreateCampaignValidate';
import { useFormContext } from 'react-hook-form';
import ApprovalCheckList from './ApprovalCheckList';
import ListButtonsGroup from '../../base/ListButtonsGroup';
import RejectPrompt from './RejectPrompt';


function CreateCampaignStepFour({
  createStatus,
  isPublished,
  campaignPublish,
  listpath,
  isSuperuser,
  userPermissions,
}) {
  const params = useParams();
  const { getValues, clearErrors, setError } = useFormContext();
  const location = useLocation();

  const [showRejectPrompt, setShowRejectPrompt] = useState(false);
  const [showPublishPrompt, setShowPublishPrompt] = useState(false);
  const [actionType, setActionType] = useState(ActionType.none);
  const approvalStatus = getValues('approvalStatus');
  let saveActionType = null;
  let tempActionType = null;
  const dispatch = useDispatch();
  const history = useHistory();
  const displayIn = getValues('displayIn')
  const campaignType = getValues('campaignType')
  const sections = () => {
    let section = [<ProfileSection />];
    if (!(displayIn === CampaignDisplayIn.mission ||
      displayIn === CampaignDisplayIn.stamp) &&
      !(campaignType === CampaignType.generalMessageCampaign ||
      campaignType === CampaignType.htmlCampaign)) {
      section.push(<ApprovalCheckList />)
    }
    return section
  } 
  // const { createStatus, isPublished } = useSelector((state) => ({
  //   createStatus: state.createCampaign.createStatus,
  //   isPublished: state.createCampaign.campaign.isPublished,
  // }));
  const hasPermission = (requires) => {
    if (isSuperuser) return true;
    if (userPermissions.includes(requires) || !requires) return true;

    return false;
  };

  useEffect(() => {
    if (createStatus === APIStatus.success) {
      setShowPublishPrompt(
        actionType !== ActionType.publish ||
          history.location.pathname.includes('edit'),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus]);

  const stepChangeAction = (checkCheckList, isBack, buttonAction = null) => {
    const isValid = validate({
      getValues: getValues,
      setError,
      clearErrors,
      step: 3,
      isBack,
      checkCheckList,
    });
    if (!isValid || isBack) {
      dispatch({
        type: 'createCampaign/stepChange',
        payload: { isBack: isBack, step: 3, isValid },
      });
    } else {
      setActionType(buttonAction)
      if (buttonAction === ActionType.withdraw || buttonAction === ActionType.unpublish) {
        dispatch(
          createAction('createCampaign/updateApprovalStatus')({
            data: getValues(),
            actionType: buttonAction,
          }),
        )
      } else {
        dispatch(
          createAction('createCampaign/createOrUpdateCampaign')({
            data: getValues(),
            actionType: buttonAction,
            resume: location.state?.resume
          }),
        );
      }
    }
  };

  const getTempText = () => {
    if (displayIn === CampaignDisplayIn.mission || displayIn === CampaignDisplayIn.stamp) {
      const isPublished = approvalStatus === ApprovalStatus.published
      tempActionType = params.id ? ActionType.update : ActionType.save
      return params.id ? 'Update' : 'Save as a darft'
    }
    switch (approvalStatus) {
      case ApprovalStatus.draft:
        tempActionType = ActionType.update
        return 'Update'
      case ApprovalStatus.pendingForApproval:
        if (hasPermission(PermissionCodes.approvalCampaign)) {
          tempActionType = ActionType.update
          return 'Update'
        }
        tempActionType = ActionType.withdraw
        return 'Withdraw'
      case ApprovalStatus.pendingForPublish:
        if (hasPermission(PermissionCodes.publishCampaignForS3)){
          tempActionType = ActionType.update
          return 'Update'
        }
        tempActionType = ActionType.withdraw
        return 'Withdraw'
      case ApprovalStatus.published:
        tempActionType = ActionType.tempForPulished
        return 'Update'
      default:
        tempActionType = ActionType.save
        return 'Save as a darft'
    }
  }

  const tempRequires = () => {
    if (displayIn === CampaignDisplayIn.mission || displayIn === CampaignDisplayIn.stamp) {
      return params.id ? PermissionCodes.changeCampaign : PermissionCodes.addCampaign
    }
    switch (approvalStatus) {
      case ApprovalStatus.draft:
        return PermissionCodes.changeCampaignForS1
      case ApprovalStatus.pendingForApproval:
        if (hasPermission(PermissionCodes.withdrawCampaignForS2)) {
          return PermissionCodes.withdrawCampaignForS2
        }
        return PermissionCodes.changeCampaignForS2
      case ApprovalStatus.pendingForPublish:
        if (hasPermission(PermissionCodes.withdrawCampaignForS3ToS2)) {
          return PermissionCodes.withdrawCampaignForS3ToS2
        } else if (hasPermission(PermissionCodes.withdrawCampaignForS3ToS1)) {
          return PermissionCodes.withdrawCampaignForS3ToS1
        }
        return PermissionCodes.changeCampaignForS3
      case ApprovalStatus.published:
        return PermissionCodes.tempForPulished
      default:
        return PermissionCodes.addCampaign
    }
  }

  const rejectRequires = () => {
    if (displayIn === CampaignDisplayIn.mission || displayIn === CampaignDisplayIn.stamp) {
      return PermissionCodes.publishCampaign
    }
    switch (approvalStatus) {
      case ApprovalStatus.pendingForApproval:
        return PermissionCodes.approvalCampaign
      case ApprovalStatus.pendingForPublish:
        return PermissionCodes.publishCampaignForS3
      default:
        return PermissionCodes.changeCampaign
    }
  }

  const getSaveText = () => {
    if (displayIn === CampaignDisplayIn.mission || displayIn === CampaignDisplayIn.stamp) {
      const isPublished = approvalStatus === ApprovalStatus.published
      saveActionType = isPublished ? ActionType.unpublish : ActionType.publish
      return isPublished ? 'Unpublish' : 'Publish'
    }
    switch (approvalStatus) {
      case ApprovalStatus.published:
        saveActionType = ActionType.unpublish
        return 'Unpublish'
      case ApprovalStatus.pendingForApproval:
        if (hasPermission(PermissionCodes.approvalCampaign)) {
          saveActionType = ActionType.approval
          return 'Approve'
        }
        saveActionType = ActionType.pendingForApproval
        return 'Pending for approval'
      case ApprovalStatus.pendingForPublish:
        if (hasPermission(PermissionCodes.publishCampaignForS3)) {
          saveActionType = ActionType.publish
          return 'Publish'
        }
        saveActionType = ActionType.pendingForPublish
        return 'Pending for publish'
      default:
        saveActionType = ActionType.submitForApproval
        return 'Submit for approval'
    }
  }
  const saveRequires = () => {
    if (displayIn === CampaignDisplayIn.mission || displayIn === CampaignDisplayIn.stamp) {
      return PermissionCodes.publishCampaign
    }
    switch (approvalStatus) {
      case ApprovalStatus.draft:
        return PermissionCodes.peddingCampaign
      case ApprovalStatus.pendingForApproval:
        if (hasPermission(PermissionCodes.withdrawCampaignForS2)) {
          return PermissionCodes.withdrawCampaignForS2
        }
        return PermissionCodes.approvalCampaign
      case ApprovalStatus.pendingForPublish:
        if (hasPermission(PermissionCodes.withdrawCampaignForS3ToS2)) {
          return PermissionCodes.withdrawCampaignForS3ToS2
        } else if (hasPermission(PermissionCodes.withdrawCampaignForS3ToS1)) {
          return PermissionCodes.withdrawCampaignForS3ToS1
        }
        return PermissionCodes.publishCampaignForS3
      case ApprovalStatus.published:
        if (hasPermission(PermissionCodes.unPublishCampaignToS3)) {
          return PermissionCodes.unPublishCampaignToS3
        } else if (hasPermission(PermissionCodes.unPublishCampaignToS2)) {
          return PermissionCodes.unPublishCampaignToS2
        }
        return PermissionCodes.unPublishCampaignToS1
      default:
        return PermissionCodes.addCampaign
    }
  }

  const primaryText = getSaveText();

  return (
    <>
      {createStatus !== APIStatus.calling ? (
        <>
          <ContentSection />
          <ContentSections sections={sections()} hidePreview={true} />
        </>
      ) : (
        <ContentSections sections={[<Loading />]} hidePreview={true} />
      )}

      <BackAndMultipleButtons
        backAction={() => stepChangeAction(false, true)}
        multipleButtons={[
          <ListButtonsGroup
            hideExtraButtonWidth={600}
            hideAllButtonWidth={400}
            hideExtraPopPosition="top"
            hideAllPopPosition="topRight"
            extraButtons={[
              hasPermission(tempRequires()) ?
                <SaveTempButton
                  text={getTempText()}
                  action={() => {
                    stepChangeAction(false, false, tempActionType);
                  }}
                /> : null,
              approvalStatus === ApprovalStatus.pendingForApproval || approvalStatus === ApprovalStatus.pendingForPublish ?
                <SaveTempButton
                  text="Reject"
                  action={() => {
                    setShowRejectPrompt(true);
                  }}
                  disabled={saveActionType === ActionType.pendingForPublish || saveActionType === ActionType.pendingForApproval ? true : false}
                /> : null,
            ]}
            extraPopContent={[
              {
                content: getTempText(),
                action: () => stepChangeAction(false, false, tempActionType),
              },
              {
                content: 'Reject',
                action: () => {
                  setShowRejectPrompt(true);
                },
                requires: rejectRequires(),
              },
            ]}
            primaryButton={
              hasPermission(saveRequires()) ?
                <SaveButton
                  text={primaryText}
                  action={() =>
                    stepChangeAction(approvalStatus === ApprovalStatus.published ? false : true, false, saveActionType)
                  }
                  disabled={saveActionType === ActionType.pendingForPublish || saveActionType === ActionType.pendingForApproval ? true : false}
                /> : null
            }
            primaryPopContent={{
              requires: saveRequires(),
              action: () => stepChangeAction(approvalStatus === ApprovalStatus.published ? false : true, false, saveActionType),
              content: primaryText,
            }}
          />
        ]}
      />

      <RejectPrompt
        show={showRejectPrompt}
        onHide={() => setShowRejectPrompt(false)}
        onConfirm={(comment) => {
          setShowRejectPrompt(false);
          dispatch(
            createAction('createCampaign/rejectCampaign')({
              id: params.id,
              message: comment,
              isPendingForApprovalCampaign: approvalStatus === ApprovalStatus.pendingForApproval,
              afterAction: () => {
                const pathname = location.pathname.split('/')[1];
                history.push({
                  pathname: `/${pathname}`
                })
              },
            }),
          )
        }}
      />

      <PublishCampaignPrompt
        // isSave={actionType === ActionType.save}
        // isPublish={!isPublished}
        actionType={actionType}
        show={showPublishPrompt}
        pathname={listpath}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  createStatus: state.createCampaign.createStatus,
  isPublished: state.createCampaign.campaign.isPublished,
  campaignPublish: state.createCampaign.campaignPublish,
  isSuperuser: state.users.isSuperuser,
  userPermissions: state.users.userPermissions,
})

export default connect(mapPropsToState)(CreateCampaignStepFour);
