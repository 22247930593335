import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import CustomListComponent from '../../../components/base/CustomListComponent';
import { createAction } from '../../../utils';
import ContentSections from '../../../components/base/ContentSections';
import { listItem, listMultiItem } from '../../../components/banners/DisplayItems';
import { APIStatus, LanguageConfig } from '../../../config/CustomEnums';
import Loading from '../../../components/base/Loading';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';


const StoreCategoryLv3Detail = ({
  category,
  apiStatus,
  languages,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const renderTabForLanguage = (language) => {
    const parentCategories = category.parentCategories.map(
      (parentCategoryData) => {
        const lv2Name = parentCategoryData?.translations?.[language]?.name || '-';
        // const lv2Order = `(${parentCategoryData.order})`;
        return lv2Name
      }
    );
    const name = category?.translations?.[language]?.name || '-';
    // const order = `(${category?.order})`;
    return [
      <>
        {listItem('Name', name)}
        {listMultiItem('Store category lv2', parentCategories)}
      </>
    ];
  };

  const tabs = [
    {
      name: 'Detail',
      content: apiStatus === APIStatus.calling
      ? <Loading />
      : (
        <ContentSections
          hidePreview
          languageTabContent={{
            containers: languages?.map((item) => ({
              container: [renderTabForLanguage(item.code)],
              key: item.code,
              title: item.sourceName,
            })),
          }}
          activeSection={LanguageConfig.english}
        />
      ),
    },
  ];

  const buttons = [
    // <AuthButton
    //   title="Edit"
    //   action={() => {
    //     history.push({
    //       pathname: 'edit/',
    //     });
    //   }}
    //   requires={PermissionCodes.changeStorecategoryLv3}
    // />,
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  useEffect(() => {
    dispatch(createAction('language/getList')({ isSelectorLoad: true }));
    const categoryID = params.id;
    if (categoryID) {
      dispatch(
        createAction('createStoreCategoryLv3/getOneDetail')({ id: categoryID }),
      );
    }
    return () => {
      dispatch({ type: 'createStoreCategoryLv3/clearData' });
    };
  }, []);

  return (
    <div className="campaign-category-detail">
      <CustomListComponent
        caution={{
          detail: '',
          title: category.name,
        }}
        defaultActiveKey={activeTab}
        breadcrumb={<CustomBreadcrumb name={category.name} />}
        buttons={buttons}
        tabs={tabs}
        onTabChange={(key) => {
          setActiveTab(key);
        }}
      />
    </div>
  );
};

const mapPropsToState = (state) => ({
  category: state.createStoreCategoryLv3.detail,
  apiStatus: state.createStoreCategoryLv3.apiStatus,
  languages: state.language.allList,
});

export default connect(mapPropsToState)(StoreCategoryLv3Detail);
