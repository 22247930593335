import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import CustomListComponent from '../../../components/base/CustomListComponent';
import CustomBreadcrumb from '../../../components/base/CustomBreadcrumb';
import ContentSections from '../../../components/base/ContentSections';
import { createAction } from '../../../utils';
import {
  TRANSACTION_RECORD_TYPE,
  TRANSACTION_EVENT_TYPE,
  getTransactionLanguageAndIsTCTransaction,
} from './TransactionRecordList';
import {
  OnlyBackButton,
  SaveAndBackButtons,
  SaveAndBackWithOutTempButtons,
} from '../../../components/base/BottomStepComponent';
import { transactionQueryKey } from '../../../components/pointRecord/RecordsUrlConfig';
import TransactionActionPrompt from '../../../components/transactionRecord/TransactionActionPrompt';
import RedirectToShopifyPrompt from '../../../components/transactions/RedirectToShopifyPrompt';
import AuthButton from '../../../components/base/AuthButton';
import { PermissionCodes } from '../../../config/PermissionCodes';
import { CAMPAIGN_REWARD_TYPE, FORM_TYPE, LanguageConfig, COUPON_RECEIVER } from '../../../config/CustomEnums';
import { listImage, listItem, listMultiItem } from '../../../components/banners/DisplayItems';
import { getCurrentRouteName } from '../../../components/bars/CollapseNavUtil';
import i18n from '../../../I18n';
import { NavBarNames } from '../../../config/NavBarNameList';

function TransactionRecordOverview({ selectedTransaction }) {
  const queryString = require('query-string');
  const dispatch = useDispatch();
  const history = useHistory();
  const transactionPK = useParams().id;
  const currentLocation = useLocation();
  const isTradeForm = selectedTransaction.transactionFormType === FORM_TYPE.trade;

  let displayStore = selectedTransaction.displayStoreName;
  let displayPaymentMethod = selectedTransaction.displayPaymentMethod;
  let displayEventType = selectedTransaction.displayEnOfflineEventType;
  let displayTransactionRewardTypeName = selectedTransaction.displayTransactionRewardTypeEnName;
  let displayStampCampaignName = selectedTransaction.stampCampaign?.name;
  let displayMissionCampaignName = selectedTransaction.missionDetailCampaign?.name;
  let displayOtherCampaignName = selectedTransaction.otherCampaign?.name;
  let displayCarParkCampaignName = selectedTransaction.carParkCampaign?.name;
  let displayCouponNameList = selectedTransaction.couponEnNameList;
  const { language, isTCTransaction } = getTransactionLanguageAndIsTCTransaction(currentLocation);
  if (isTCTransaction) {
    displayStore = selectedTransaction.storeName;
    displayPaymentMethod = selectedTransaction.displayTCPaymentMethod;
    displayEventType = selectedTransaction.displayOfflineEventType;
    displayTransactionRewardTypeName = selectedTransaction.displayTransactionRewardTypeName;
    displayStampCampaignName = selectedTransaction.displayStampCampaignName;
    displayMissionCampaignName = selectedTransaction.displayMissionCampaignName;
    displayOtherCampaignName = selectedTransaction.displayOtherCampaignName;
    displayCarParkCampaignName = selectedTransaction.displayCarParkCampaignName;
    displayCouponNameList = selectedTransaction.couponNameList;
  }

  const [showActionPrompt, setShowActionPrompt] = useState(false);
  const [promptActionTitle, setPromptActionTitle] = useState();
  const [showRedirectPrompt, setShowRedirectPrompt] = useState(false);

  useEffect(() => {
    dispatch(
      createAction('transactionRecord/getTransaction')({ transactionPK }),
    );
  }, [dispatch, transactionPK]);

  const getContentSections = () => {
    const transactionRewardType = selectedTransaction.campaignType;
    displayStampCampaignName ? displayStampCampaignName += ` (+${selectedTransaction.stampCampaignReward} ${i18n.t(selectedTransaction.stampCampaignRewardBonus ? 'transaction.stamp_with_bonus' : 'campaign.stamp', { locale: language })})` : displayStampCampaignName = i18n.t('deleted', { locale: language });
    displayOtherCampaignName ? displayOtherCampaignName += ` (+${selectedTransaction.otherCampaignReward} ${i18n.t('campaign.coupon', { locale: language })})` : displayOtherCampaignName = i18n.t('deleted', { locale: language });
    displayCarParkCampaignName ? displayCarParkCampaignName += ` (+${selectedTransaction.carParkCampaignReward} ${i18n.t('campaign.coupon', { locale: language })})` : displayCarParkCampaignName = '';
    if (selectedTransaction.missionCampaignRewardCoupon !== null && displayMissionCampaignName) {
      displayMissionCampaignName += ` (+${selectedTransaction.missionCampaignRewardCoupon} ${i18n.t(selectedTransaction.couponReceiver ? `transaction.${COUPON_RECEIVER[selectedTransaction.couponReceiver]}` : 'transaction.coupon', { locale: language })})`;
    } else if (selectedTransaction.missionCampaignRewardBadge !== null) {
      displayMissionCampaignName += ` (+${selectedTransaction.missionCampaignRewardBadge} ${i18n.t('campaign.badge', { locale: language })})`;
    } else {
      displayMissionCampaignName = i18n.t('deleted', { locale: language });
    }
    let extraData = [];
    switch (transactionRewardType) {
      case CAMPAIGN_REWARD_TYPE.stamp:
        extraData = [
          listItem(i18n.t('transaction.stamp_campaign', { locale: language }), displayStampCampaignName),
          listItem(i18n.t('transaction.carpark_campaign', { locale: language }), displayCarParkCampaignName),
        ];
        break;
      case CAMPAIGN_REWARD_TYPE.mission:
        extraData = [
          listItem(i18n.t('transaction.mission_campaign', { locale: language }), displayMissionCampaignName),
          listItem(i18n.t('transaction.carpark_campaign', { locale: language }), displayCarParkCampaignName),
        ];
        break;
        case CAMPAIGN_REWARD_TYPE.other:
          extraData = [
            listItem(i18n.t('transaction.other_campaign', { locale: language }), displayOtherCampaignName),
            listItem(i18n.t('transaction.carpark_campaign', { locale: language }), displayCarParkCampaignName),
          ];
          break;
      case CAMPAIGN_REWARD_TYPE.carPark:
        extraData = [
          listItem(i18n.t('transaction.carpark_campaign', { locale: language }), displayCarParkCampaignName),
        ];
        break;
      default:
        break;
    }
    return [
      <>
        {listItem(i18n.t('transaction.transaction_id', { locale: language }), selectedTransaction.pk)}
        {listItem(i18n.t('transaction.combined_transaction_id', { locale: language }), selectedTransaction.combinedTransactionId)}
        {listItem(i18n.t('transaction.memberid/mobile_number', { locale: language }), selectedTransaction.customerID)}
        {listItem(i18n.t('transaction.transaction_date', { locale: language }), selectedTransaction.transactionDetailDisplayDate)}
        {listItem(i18n.t('transaction.event_type', { locale: language }), displayEventType)}
        {listItem(i18n.t('transaction.store', { locale: language }), displayStore)}
        {isTradeForm
          ? [
              listItem(i18n.t('transaction.total_value', { locale: language }), selectedTransaction.displayTotalValue),
              listItem(i18n.t('transaction.invoice_id', { locale: language }), selectedTransaction.invoiceId)
            ]
          : listItem(i18n.t('transaction.quantity', { locale: language }), selectedTransaction.totalValue)}
        {listItem(i18n.t('transaction.payment_method', { locale: language }), displayPaymentMethod)}
        {selectedTransaction.receiptImage
          ? listImage(i18n.t('transaction.receipt_image', { locale: language }), selectedTransaction.receiptImage)
          : listItem(i18n.t('transaction.receipt_image', { locale: language }), '-')
        }
        {selectedTransaction.creditCardSlipImage
          ? listImage(i18n.t('transaction.credit_card_slip_image', { locale: language }), selectedTransaction.creditCardSlipImage)
          : listItem(i18n.t('transaction.credit_card_slip_image', { locale: language }), '-')
        }
        {listItem(i18n.t('transaction.transaction_reward_type', { locale: language }), displayTransactionRewardTypeName)}
        {extraData}
        {listItem(i18n.t('transaction.staff_name', { locale: language }), selectedTransaction.staffName)}
        {listItem(i18n.t('transaction.remarks', { locale: language }), selectedTransaction.remarks)}
        {selectedTransaction.recallRemarks ? listItem(i18n.t('transaction.recall_remarks', { locale: language }), selectedTransaction.recallRemarks) : null}
        {listItem(i18n.t('transaction.create_at', { locale: language }), selectedTransaction.transactionDetailCreationDate)}
        {selectedTransaction.recallRemarks ? listItem(i18n.t('transaction.revocation_time', { locale: language }), selectedTransaction.transactionDetailLastModifiedDate) : null}
        {listMultiItem(i18n.t('transaction.coupon_earned', { locale: language }), displayCouponNameList)}
      </>,
    ];
  };

  const tabs = [
    {
      name: '',
      content: (
        <div className="scroll-container-common">
          <ContentSections
            sections={getContentSections()}
            hidePreview={true}
            // sectionStyle="transaction-record-detail-section"
          />
        </div>
      ),
    },
  ];

  const buttons = () => {
    if (
      selectedTransaction.transactionDisplayType ===
      TRANSACTION_RECORD_TYPE.TYPE_ONLINE
    ) {
      return [
        <AuthButton
          title="Edit"
          action={() => {
            setShowRedirectPrompt(true);
          }}
          requires={isTCTransaction ? PermissionCodes.changeConciergetransaction : PermissionCodes.changeTransaction}
        />,
      ];
    }
  };

  const goBackToList = () => {
    const pathname = isTCTransaction ? '/concierge_transaction_records' : '/transaction_records';
    return history.push({
      pathname: pathname,
      search: queryString.stringify({
        [transactionQueryKey.searchKey]: '',
        [transactionQueryKey.page]: 1,
        [transactionQueryKey.rank]: true,
        [transactionQueryKey.tab]: 'all',
      }),
    });
  };

  const backAction = () => {
    dispatch(createAction('transactionRecord/clearSelectedTransaction')());
    dispatch(createAction('createTransactionRecord/clearData')());
    history.goBack();
  };

  const getBottomSection = () => {
    if (
      selectedTransaction.displayOfflineEventType ===
        TRANSACTION_EVENT_TYPE.TYPE_APPROVED_TC ||
      selectedTransaction.displayEnOfflineEventType === 
        TRANSACTION_EVENT_TYPE.TYPE_APPROVED_EN
    ) {
      return (
        <SaveAndBackWithOutTempButtons
          backText={i18n.t('transaction.back', { locale: language })}
          backAction={backAction}
          saveText={i18n.t('transaction.recall', { locale: language })}
          actionText={i18n.t('transaction.recall', { locale: language })}
          saveAction={() => {
            setPromptActionTitle('recall');
            setShowActionPrompt(true);
          }}
          saveRequires={isTCTransaction ? PermissionCodes.changeConciergetransaction : PermissionCodes.changeTransaction}
        />
      );
    }

    return <OnlyBackButton text={i18n.t('transaction.back', { locale: language })} backAction={backAction} />;
  };

  return (
    <>
      <CustomListComponent
        caution={{
          detail: '', //view transaction
          title: i18n.t('transaction.view_transaction', { locale: language }),
        }}
        breadcrumb={<CustomBreadcrumb />}
        hideTab={true}
        tabs={tabs}
        buttons={[]}
      />
      {getBottomSection()}
      <TransactionActionPrompt
        language={language}
        event={promptActionTitle}
        item={selectedTransaction}
        show={showActionPrompt}
        onHide={() => setShowActionPrompt(false)}
        action={(recallRemarks, setError, onHide) => {
          if (!recallRemarks) {
            setError(true);
          } else {
            onHide();
            dispatch({
              type: `transactionRecord/${promptActionTitle}Transaction`,
              payload: {
                transactionPK: selectedTransaction.pk,
                recallRemarks,
                afterActions: () => {
                  backAction();
                  dispatch({
                    type: 'navBars/updateState',
                    payload: {
                      saveDiscardToastShowing: {
                        value: true,
                        type: i18n.t('transaction.recall_success', { locale: language }),
                      },
                    },
                  });
                },
              },
            });
          }
        }}
      />
      <RedirectToShopifyPrompt
        show={showRedirectPrompt}
        onHide={() => setShowRedirectPrompt(false)}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  selectedTransaction: state.transactionRecord.selectedTransaction,
});

export default connect(mapPropsToState)(TransactionRecordOverview);
