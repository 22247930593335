export const NavBarNames = {
  customer: 'Member',
  segments: 'Segment',
  customerGroup: 'Customer group',
  levels: 'Levels',
  records: 'Records',
  campaigns: 'Campaigns',
  campaignCategory: 'Campaign categories',
  campaignTag: 'Campaign tag',
  eStampCampaign: 'Stamp Campaigns',
  eMissionCampaign: 'Mission Campaigns',
  message: 'Messages',
  earnRules: 'Earning rules',
  coupons: 'Coupons',
  rewards: 'Rewards',
  brands: 'Brands',
  carparks: 'Carparks',
  evChargerVendors: 'Ev Charger vendors',
  malls: 'Malls',
  freshMarkets: 'FreshMarkets',
  stores: 'Stores',
  storeCodes: 'Store code List',
  storeCategory: 'Store category Lv 1, 2',
  storeCategoryLv3: 'Store category Lv3',
  storeCategoryLv4: 'Store category Lv4',
  translations: 'Translations',
  transactions: 'Transaction records',
  pointRecords: 'Point records',
  stampRecords: 'Stamp Records',
  badgeRecords: 'Badge records',
  couponRecordsEn: 'Coupon records',
  webviewContent: 'Webview content',
  appversion: 'App version',
  admin: 'Administrators',
  adminGroup: 'Administrators group',
  dashboard: 'Dashboard',
  banners: 'Banners',
  productCategory: 'Product categories',
  featuredCampaign: 'Happenings &\r\nPromotions',
  themedSection: 'Hub',
  featuredProduct: 'Featured products',
  langauge: 'Languages',
  customerTC: '客戶',
  transactionRecord: '交易紀錄',
  stampRecordsTC: '印花紀錄',
  badgeRecordsTC: '獎章紀錄',
  couponRecords: '優惠券紀錄',
  couponStep1: '1 - Coupon',
  couponStep2: '2 - Coupon campaign',
  stampStep1: '1 - Coupon',
  stampStep2: '2 - Coupon campaign',
  stampStep3: '3 - Earning rules',
  stampStep4: '4 - Stamp campaign',
  missionStep1: '1 - Coupon',
  missionStep2: '2 - Earning rules',
  missionStep3: '3 - Earning campaign',
  missionStep4: '4 - Mission campaign',
  notlistStep1: '1 - Coupon',
  notlistStep2: '2 - Earning rule',
  notlistStep3: '3 - Campaign',
  interestClubStep1: '1 - Campaign',
  districtMallMappings: 'Favourite malls mapping',
};
