import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { CustomTitleLabel } from '../earning/CustomBaseComponments';
import { formatDate, TimeFormater } from '../../utils/TimeFormatUtil';
import { PermissionCodes } from '../../config/PermissionCodes';
import CustomDateTimeSelect from '../base/CustomDateTimeSelect';
import BaseMultipleSelectorV2 from '../base/BaseMultipleSelectorV2';
import { hasError, errorMessage, ReactHookFormErrorMessage } from '../base/ErrorFieldMessage';
import BasePrompt from '../base/prompt/BasePrompt';
import { DatePicker } from 'antd';
import moment from 'moment';
import { MessageChannel } from '../../config/CustomEnums';
import CustomRangePicker from '../base/CustomRangePicker';
const { RangePicker } = DatePicker;

const MessageNewPromptType = {
  segments: 'segments',
  customerGroup: 'customer groups',
  none: 'none',
};

function MessageSettingSection({ customerGroupList, segmentList }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [createNewType, setCreateNewType] = useState(MessageNewPromptType.none);

  const { watch, setValue, formState, getValues } = useFormContext();
  const { errors } = formState;

  const targetCustomerGroup = watch('targetCustomerGroup') || [];
  const targetedSegments = watch('targetedSegments') || [];
  const scheduledDate = watch('scheduledDate');
  const scheduledPeriodStartDate = watch('scheduledPeriodStartDate');
  const scheduledPeriodEndDate = watch('scheduledPeriodEndDate');
  const [channels] = getValues(['channels']);
  const isSelectFullScreenAd = channels.indexOf(MessageChannel.fullScreenAd) !== -1;

  const onChange = (dates, dateStrings) => {
    const [start, end] = dates || [];
    setValue('scheduledPeriodStartDate', start?.format('yyyy-MM-DD'), {
      shouldDirty: true,
    });
    setValue('scheduledPeriodEndDate', end?.format('yyyy-MM-DD'), {
      shouldDirty: true,
    });
  };

  useEffect(() => {
    dispatch({
      type: 'customerGroup/getGroupList',
      payload: { status: true, isSimpleList: true },
    });
    dispatch({
      type: 'segments/getSegmentsList',
      payload: { status: true, isSelectorLoad: true, isAll: true },
    });
  }, [dispatch]);

  const getCreateNewPromptContent = () => {
    let title = 'segment';
    let button = 'Segment';
    let pathname = '/segments/create/';
    if (createNewType === MessageNewPromptType.customerGroup) {
      title = 'customer group';
      button = 'Customer Group';
      pathname = '/customer_group/create';
    }
    return {
      title: `Go to create ${title} ?`,
      description: `You will leave message creation process.`,
      button: `Go to Create ${button}`,
      action: () => {
        history.push({
          pathname: pathname,
          state: {
            from: history.location,
            title: 'Continue to Create Message',
            content: 'You can continue to create the Message.',
          },
        });
      },
    };
  };

  return (
    <>
      <label className="create-section-title">SETTING</label>

      <BaseMultipleSelectorV2
        title={'Target customer groups'}
        data={{
          sourceData: customerGroupList,
          targetData: targetCustomerGroup,
          targetChange: (options) => {
            setValue('targetCustomerGroup', options, { shouldDirty: true });
          },
        }}
        addButton={{
          title: 'Add Customer Group',
          action: () => setCreateNewType(MessageNewPromptType.customerGroup),
        }}
        requires={PermissionCodes.addCustomerGroup}
        loadMoreAction={'customerGroup/getCurrentPageList'}
      />

      <BaseMultipleSelectorV2
        title={'Target segments'}
        data={{
          sourceData: segmentList,
          targetData: targetedSegments,
          targetChange: (options) => {
            setValue('targetedSegments', options, { shouldDirty: true });
          },
        }}
        addButton={{
          title: 'Add Segments',
          action: () => setCreateNewType(MessageNewPromptType.segments),
        }}
        requires={PermissionCodes.addSegment}
        loadMoreAction={'segments/getSegmentsList'}
      />

      {isSelectFullScreenAd ? (
        <>
          <CustomTitleLabel title="Schedule period" />
          <CustomRangePicker
            defaultValue={[
              scheduledPeriodStartDate
                ? moment(scheduledPeriodStartDate, TimeFormater.yearMonthDay)
                : null,
              scheduledPeriodEndDate
                ? moment(scheduledPeriodEndDate, TimeFormater.yearMonthDay)
                : null,
            ]}
            dateFormat={TimeFormater.dayMonthYearWeek}
            onChange={onChange}
          />
          <ReactHookFormErrorMessage errors={errors} id="scheduledPeriodDate" />
        </>
      ) : (
        <>
          <CustomTitleLabel title="Schedule time" />
          <CustomDateTimeSelect
            defaultTime={scheduledDate ? new Date(scheduledDate) : new Date()}
            onTimeChange={(datetime) => {
              setValue('scheduledDate', datetime, { shouldDirty: true });
            }}
            error={hasError(errors, 'scheduledDate')}
          />
          <ReactHookFormErrorMessage errors={errors} id="scheduledDate" />
        </>
      )}

      <BasePrompt
        show={createNewType !== MessageNewPromptType.none}
        closeAction={() => setCreateNewType(MessageNewPromptType.none)}
        rightButton={{
          text: getCreateNewPromptContent().button,
          action: getCreateNewPromptContent().action,
        }}
        title={getCreateNewPromptContent().title}
        description={getCreateNewPromptContent().description}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  customerGroupList: state.customerGroup.groupList,
  segmentList: state.segments.segmentList,
});

export default connect(mapPropsToState)(MessageSettingSection);
