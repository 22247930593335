import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import { createAction } from '../../../utils';
import {
  DELETE_RELATED_SECTIONS,
  SavedStatus,
} from '../../../config/CustomEnums';
import ContentSections from '../../../components/base/ContentSections';
import { SaveAndBackButtons } from '../../../components/base/BottomStepComponent';
import DeletePrompt from '../../../components/base/DeletePrompt';
import { PermissionCodes } from '../../../config/PermissionCodes';
import BasePrompt from '../../../components/base/prompt/BasePrompt';
import GeneralSections from '../../../components/admin/CreateAdminStepOneGeneralSecion';
import PersonalInfoSections from '../../../components/admin/CreateAdminStepOnePersonalInfoSection';
import StatusSections from '../../../components/admin/CreateAdminStepOneStatusSection';
import PermissionSections from '../../../components/admin/CreateAminStepOnePermissionSection';
import RelatedStore from '../../../components/admin/CreateAdminStepOneRelatedStore';

const PERMISSION_TYPE = {
  GROUP: 'GROUP',
  PERMISSION: 'PERMISSION',
};

const adminPermission = [
  PermissionCodes.changeAdministrator,
  PermissionCodes.changeAdministratorGroup,
];
function CreateAdminStepOne({
  saved,
  admin,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;
  const { watch, getValues, trigger, formState } = useFormContext();
  const { errors } = formState;
  const watchSuperAdmin = watch('isSuperuser');
  const watchTenantApp = watch('isTenantApp');
  // const watchAdminType = watch('adminType') || ADMIN_TYPE.HKTB_ADMIN;
  const watchPermissionGroups = watch('groups');
  const watchPermissions = watch('permissions');
  // const watchMerchantPermissionGroups = watch('merchantGroups');
  // const watchMerchantPermissions = watch('merchantPermissions');
  const [saveAlertConfig, setSaveAlertConfig] = useState(null);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  // const isMerchant = watchAdminType === ADMIN_TYPE.MERCHANT_ADMIN;
  const permissions = watchPermissions;
  const groups = watchPermissionGroups;
  const patchData = () => {
    const data = getValues();
    if (!id) {
      delete data.pk;
    }
    dispatch({
      type: 'admin/updateOrCreate',
      payload: { data },
    });
  };

  const tenantSections = [
    <GeneralSections />,
    <StatusSections hiddenSuper={true}/>,
    <RelatedStore />
  ];

  const adminSections = [
    <GeneralSections disabled={true}/>,
    <StatusSections />,
  ];

  if (!watchSuperAdmin) {
    adminSections.push(<PermissionSections />);
  }
  return (
    <>
      <ContentSections sections={!watchTenantApp && id? adminSections : tenantSections} hidePreview={true} />
      <SaveAndBackButtons
        saveTempText={id && watchTenantApp ? 'Delete' : null}
        saveText={id ? 'Update' : 'Save'}
        backAction={() => {
          history.push('/administrators');
        }}
        saveAction={async () => {
          if (saved === SavedStatus.saving) {
            return;
          }
          const triggerFields = [
            'name',
            'email',
            'password',
            'confirmPassword',
            'selectedStore',
          ];
          // const [password, confirmPassword] = getValues(['password', 'confirmPassword']);
          const isValid = await trigger(triggerFields);
          if (!isValid) {
            return;
          }

          if (watchSuperAdmin) {
            setSaveAlertConfig({
              title: 'Confirm to give this admin permission',
              content:
                'As this a superadmin, then the admin will gain full system permission by doing so. ',
            });
            return;
          }
          console.log("groups:", groups)
          const selectedAdminPermissionGroups = groups?.filter(
            (group) =>
              (group.permissions?.edges || group.permissions || []).filter((val) =>
                adminPermission.includes(val?.node?.codename || val?.codename),
              ).length > 0,
          );
            
          if (selectedAdminPermissionGroups?.length > 0) {
            setSaveAlertConfig({
              title: 'Confirm to give this admin permission',
              content: `As this admin belong to ${selectedAdminPermissionGroups
                .map((group) => group.name)
                .join(
                  ',',
                )}, then the admin will gain full system permission by doing so. `,
            });
            return;
          }

          const selectedAdminPermissions = permissions?.filter((permission) =>
            adminPermission.includes(permission.codename),
          );
          if (selectedAdminPermissions?.length > 0) {
            setSaveAlertConfig({
              title: 'Confirm to give this admin permission',
              content: `As "change admin permissions" is set, then the admin will gain full system permission by doing so. `,
            });
            return;
          }
          patchData();
        }}
        saveTempAction={() => setShowDeletePrompt(true)}
        tempRequires={PermissionCodes.deleteAdministrator}
      />
      <DeletePrompt
        message={{
          title: 'Delete this Admin',
          content: `Are you sure you want to delete the administrator "${admin.name}"? All of the following related items will be deleted:`,
        }}
        title={'Admin'}
        data={admin}
        relatedSections={DELETE_RELATED_SECTIONS.ADMIN}
        show={showDeletePrompt}
        onClose={() => {
          setShowDeletePrompt(false);
        }}
        onConfirm={() => {
          setShowDeletePrompt(false);
          dispatch({
            type: `admin/updateState`,
            payload: { checkedList: [admin], formChanged: false },
          });
          dispatch(
            createAction('admin/delete')({
              afterAction: () => {
                history.push('/administrators');
              },
            }),
          );
        }}
      />
      <BasePrompt
        show={!!saveAlertConfig}
        closeAction={() => setSaveAlertConfig(null)}
        rightButton={{
          text: 'Confirm',
          action: () => {
            setSaveAlertConfig(null);
            patchData();
          },
        }}
        title={saveAlertConfig?.title}
        description={saveAlertConfig?.content}
      />
    </>
  );
}

const mapPropsToState = (state) => ({
  admin: state.admin.oneAdmin || {},
  saved: state.admin.saved,
});

export default connect(mapPropsToState)(CreateAdminStepOne);
